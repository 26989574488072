export const READ_ACCOUNTS = 'READ_ACCOUNTS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

// eslint-disable-next-line no-shadow
export const readAccountsAction = (accounts, queryId = 'default') => ({
  type: READ_ACCOUNTS,
  payload: {
    accounts,
    queryId,
  },
});

export const createAccountAction = (account, queryId = 'default') => ({
  type: CREATE_ACCOUNT,
  payload: {
    account,
    queryId,
  },
});

export const updateAccountAction = (account, queryId = 'default') => ({
  type: UPDATE_ACCOUNT,
  payload: {
    account,
    queryId,
  },
});

export const deleteAccountAction = (accountId, queryId = 'default') => ({
  type: DELETE_ACCOUNT,
  payload: {
    accountId,
    queryId,
  },
});

const initialAccounts = { default: {} };

export function accounts(state = initialAccounts, action) {
  switch (action.type) {
    case READ_ACCOUNTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.accounts.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ACCOUNT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.account._id]: action.payload.account,
        },
      };
    case UPDATE_ACCOUNT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.account._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.account._id]: {
            ...current,
            ...action.payload.account,
          },
        },
      };
    }
    case DELETE_ACCOUNT: {
      const { accountId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [accountId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
