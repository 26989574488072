import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { gql } from '@apollo/client';
import { Select } from 'antd';
import React, { useMemo, useRef } from 'react';
import settings from '../../settings';
import { extractHolisticProjectsForAdmin } from './constants';

const selectHolisticProjectQuery = gql`
  query SelectHolisticProject(
    $first: Int
    $after: String
    $filters: HolisticProjectFiltersForAdmin
  ) {
    allHolisticProjectsForAdmin(
      first: $first
      after: $after
      filters: $filters
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          projectNo
          peopleGroupIds
          projectName
          typeOfProject
          stage
        }
      }
    }
  }
`;

function SelectHolisticProject({ value, onChange, filters, disabled, style }) {
  const sortBy = useRef([
    { key: 'updatedAt', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: holisticProjects,
    search: holisticProjectSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectHolisticProjectQuery,
    extract: extractHolisticProjectsForAdmin,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'select-holistic-project',
  });

  const options = useMemo(() => {
    if (holisticProjects) {
      return holisticProjects.map((item) => {
        const typeLabel =
          HolisticProjectTypes[item.typeOfProject] &&
          HolisticProjectTypes[item.typeOfProject].label;
        let nameLabel;
        if (item.projectNo) {
          nameLabel = `${item.projectName} (#${item.projectNo})`;
        } else {
          nameLabel = item.projectName;
        }
        const title = `${item.projectName} - ${typeLabel}`;
        const upgs = item.peopleGroupIds.join(', ');
        return {
          value: item._id,
          label: nameLabel,
          typeLabel: typeLabel,
          nameLabel: nameLabel,
          title,
          upgs,
        };
      });
    }
    return [];
  }, [holisticProjects]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a project"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => holisticProjectSearch(v)}
      loading={loading}
      disabled={disabled}
      optionRender={(option) => (
        <div>
          <div
            style={{ color: sharedSettings.colors.primary, fontWeight: 600 }}
          >
            {option.data.nameLabel}
          </div>
          {option.data.typeLabel && (
            <div style={{ color: sharedSettings.colors.textGray }}>
              {option.data.typeLabel}
            </div>
          )}
          <div>{option.data.upgs}</div>
        </div>
      )}
    />
  );
}

export default SelectHolisticProject;
