import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { useCountries } from '../shared/use-countries';

const { Text } = Typography;

function DisplayAddress({ address, type }) {
  const { countries } = useCountries();
  const countryName = useMemo(() => {
    if (countries && countries.length && address.country) {
      const country = countries.find((c) => c.shortCode === address.country);
      return country && country.name;
    }
    return undefined;
  }, [countries, address]);
  return (
    <div className="address">
      <Text strong>{address.label}</Text>
      <br />
      <>
        <Text type={type}>{address.address1 || 'No address line 1'}</Text>
        <br />
      </>
      {address.address2 && (
        <>
          <Text type={type}>{address.address2}</Text>
          <br />
        </>
      )}
      <Text type={type}>
        {[
          address.city || 'No city',
          address.state || 'No state or province',
        ].join(', ')}
      </Text>
      <br />
      {address.zipCode && (
        <>
          <Text type={type}>{address.zipCode}</Text>
          <br />
        </>
      )}
      <Text type={type}>{countryName || 'No country'}</Text>
      <style jsx>{`
        .address {
          text-align: left;
        }
      `}</style>
    </div>
  );
}

export default DisplayAddress;
