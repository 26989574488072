import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneNumberFormItem from './PhoneNumberFormItem';

function PhoneNumbersFormItem({ form, contact, loading, onSubmit }) {
  const [primary, setPrimary] = useState();

  useEffect(() => {
    if (contact) {
      if (contact.primaryPhoneNumberId) {
        const phoneNumberIndex = (contact.phoneNumbers || []).findIndex(
          (e) => e._id === contact.primaryPhoneNumberId,
        );
        setPrimary(phoneNumberIndex);
      }
    }
  }, [contact, form]);

  onSubmit.current = useCallback(
    (phoneNumbers) => {
      let primaryPhoneNumberId = null;
      let phoneNumbersToSave = [];
      if (phoneNumbers && phoneNumbers.length) {
        let primaryIndex = primary;
        if (primaryIndex === undefined || primaryIndex >= phoneNumbers.length) {
          primaryIndex = 0;
        }
        primaryPhoneNumberId = phoneNumbers[primaryIndex]._id;
        phoneNumbersToSave = phoneNumbers.map((e) => ({
          _id: e._id,
          label: e.label,
          phone: e.phone,
        }));
      }
      return {
        primaryPhoneNumberId,
        phoneNumbersToSave,
      };
    },
    [primary],
  );
  return (
    <Form.Item>
      <Form.List name="phoneNumbers">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <PhoneNumberFormItem
                    field={field}
                    loading={loading}
                    primary={primary}
                    setPrimary={setPrimary}
                  />
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      if (primary === index) {
                        setPrimary(0);
                      }
                      remove(field.name);
                    }}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              </Form.Item>
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add phone number
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
}

export default PhoneNumbersFormItem;
