import React, { useCallback, useState } from 'react';
import settings from '../../../settings';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TransferTypes } from './constants';
import { Link } from 'react-router-dom';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import DeleteThingDropdown from '../../../components/DeleteThingDropdown';

function TransfersTable({
  transfers,
  loading,
  onEdit,
  accountId,
  hideActions = false,
  onDelete,
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <Table
      dataSource={transfers}
      loading={!transfers?.length && loading}
      pagination={{ ...pagination, total: transfers?.length }}
      onChange={handleTableChange}
      style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 1200 }}
    >
      <Table.Column
        title="Description"
        dataIndex="description"
        key="description"
      />
      <Table.Column
        title="Transfer Type"
        dataIndex="type"
        render={(text, record) => TransferTypes[record.type]?.label}
      />
      <Table.Column
        title="From"
        dataIndex="fromAccountId"
        render={(text, record) => {
          return (
            record.fromAccount && (
              <Link to={`/accounting/accounts/view/${record.fromAccount._id}`}>
                {record.fromAccount.name}
              </Link>
            )
          );
        }}
      />
      <Table.Column
        title="To"
        dataIndex="toAccountId"
        render={(text, record) => {
          return (
            record.toAccount && (
              <Link to={`/accounting/accounts/view/${record.toAccount._id}`}>
                {record.toAccount.name}
              </Link>
            )
          );
        }}
      />
      {accountId && (
        <Table.Column
          title="In/Out"
          dataIndex="inOut"
          render={(text, record) => {
            if (record.toAccount && record.toAccount._id === accountId) {
              return <Tag color="green">IN</Tag>;
            }
            return <Tag color="red">OUT</Tag>;
          }}
        />
      )}
      <Table.Column
        title="Amount"
        dataIndex="amount"
        render={(text, record) =>
          displayMoney(getNumberValue(record.amount) / 10000)
        }
      />
      <Table.Column
        title="Pending"
        dataIndex="pending"
        render={(text, record) => {
          return record.pending ? 'Yes' : 'No';
        }}
      />
      <Table.Column
        title="Original Date"
        dataIndex="originalDate"
        render={(text, record) => {
          return new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(new Date(record.originalDate));
        }}
      />
      <Table.Column
        title="Created"
        dataIndex="createdAt"
        render={(text, record) => {
          return new Intl.DateTimeFormat(locale, {
            dateStyle: 'full',
          }).format(new Date(record.createdAt));
        }}
      />
      {!hideActions && (
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="Edit">
                <Button
                  onClick={() => onEdit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              {onDelete && (
                <DeleteThingDropdown
                  thing={record}
                  label="Delete Transfer"
                  handleDelete={onDelete}
                  btnProps={{ type: 'default' }}
                />
              )}
            </Space>
          )}
        />
      )}
    </Table>
  );
}

export default TransfersTable;
