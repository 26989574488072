import React from 'react';
import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

function getProjectStatus(record) {
  if (record.stage === HolisticProjectStages.PREPARING.key) {
    return 'See checklist';
  }
  if (
    [
      HolisticProjectStages.FUNDING.key,
      HolisticProjectStages.FUNDED.key,
    ].includes(record.stage)
  ) {
    const a = displayMoney(getNumberValue(record.fundingBalance) / 10000);
    const b = displayMoney(getNumberValue(record.fundingTarget) / 10000);
    const c = Math.round(getNumberValue(record.fundingPercent) / 100);

    return (
      <>
        <div>{`${a} of ${b} (${c}%) raised`}</div>
        {record.stage === HolisticProjectStages.FUNDING.key && (
          <>
            {record.showInCatalog ? (
              <div>Visible in catalog</div>
            ) : (
              <div>NOT visible in catalog</div>
            )}
          </>
        )}
      </>
    );
  }
  if (record.stage === HolisticProjectStages.ACTIVE.key) {
    const a = displayMoney(getNumberValue(record.paybackBalance) / 10000);
    const b = displayMoney(getNumberValue(record.paybackTotalX4) / 10000);
    const c = Math.round(getNumberValue(record.paybackPercent) / 100);
    return `${a} of ${b} (${c}%) paid back`;
  }
  if (record.stage === HolisticProjectStages.COMPLETE.key) {
    return 'This project is complete.';
  }
  if (record.stage === HolisticProjectStages.COMPLETE.key) {
    return 'This project has failed.';
  }

  return null;
}

export default getProjectStatus;
