import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Input, Radio } from 'antd';
import React from 'react';
import { generateUuid } from '../shared/utils';

function PhoneNumberFormItem({ field, loading, primary, setPrimary }) {
  return (
    <div className="phone">
      <Form.Item
        name={[field.name, '_id']}
        initialValue={generateUuid()}
        noStyle
      >
        <Input hidden />
      </Form.Item>
      <Form.Item
        name={[field.name, 'label']}
        label="Label"
        initialValue={`Phone Number ${field.name + 1}`}
        rules={[
          { required: true, message: 'Please enter a label for this email' },
        ]}
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        name={[field.name, 'phone']}
        label="Phone Number"
        extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
        rules={[
          { required: true, message: 'Please enter a phone number' },
          {
            pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            message: 'Please enter a valid phone number (ex. 5124026225).',
          },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Radio.Group value={primary} onChange={(e) => setPrimary(field.key)}>
        <Radio
          disabled={loading}
          value={field.key}
          style={{ marginBottom: 16 }}
        >
          Primary Phone Number
        </Radio>
      </Radio.Group>
      <style jsx>{`
        .phone {
          border: 1px solid ${sharedSettings.colors.borderGray};
          border-radius: 4px;
          padding 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default PhoneNumberFormItem;
