import sharedSettings from '@aims/shared/sharedSettings';
import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportPeopleGroupAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../reports/constants';

const reportFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    adminNotes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    peopleGroupIds
    peopleGroups ${ReportPeopleGroupAttributes}
    onBehalfOfName
    onBehalfOfId
    holisticProjectId
    projectName
    typeOfProject
    desc
    vision
    goals
    currency
    exchangeRate
    loanTotalUsd
    loanTotalForeign
    loanTerm
    setupExpensesDesc
    recurringMonthlyExpensesForeign
    recurringMonthlyExpensesDesc
    recurringMonthlyRevenueForeign
    recurringMonthlyRevenueDesc
    supportedFieldWorkers {
      contactId
      contact {
        _id
        fullName
      }
      fieldWorkerName
      amountForeign
    }
    otherProfitUses
    loanDetails
    marketAnalysis
    riskAnalysis
    locationRisk
    responsiblePersons {
      contactId
      contact {
        _id
        fullName
      }
      name
      role
      projectedHrsPerWeek
      priorExperience
    }
    humanReadableId
    paymentPlan {
      amount
      dueAt
    }
    paymentMethod
  }
`;

export const deleteHolisticProposalForAdminMutation = gql`
  mutation DeleteHolisticProposalForAdmin($_id: ID!) {
    deleteHolisticProposalForAdmin(_id: $_id) {
      deletedId
    }
  }
`;

export const holisticProposalForAdminQuery = gql`
  query HolisticProposalForAdmin($_id: ID!) {
    holisticProposalForAdmin(_id: $_id) ${reportFullAttributes}
  }
`;

const HolisticProposalUpdateAttributes = gql`
{
  crud
  id
  new ${reportFullAttributes}
}
`;

export const holisticProposalUpdatesSubscription = gql`
  subscription HolisticProposalUpdatesForAdmin($_id: ID!) {
    holisticProposalUpdatesForAdmin(_id: $_id) ${HolisticProposalUpdateAttributes}
  }
`;

export const createHolisticProposalForAdminMutation = gql`
  mutation CreateHolisticProposalForAdmin(
    $report: HolisticProposalCreateInputForAdmin!
  ) {
    createHolisticProposalForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateHolisticProposalForAdminMutation = gql`
  mutation UpdateHolisticProposalForAdmin(
    $report: HolisticProposalUpdateInputForAdmin!
  ) {
    updateHolisticProposalForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitHolisticProposalForAdminMutation = gql`
  mutation SubmitHolisticProposalForAdmin($reportId: ID!) {
    submitHolisticProposalForAdmin(reportId: $reportId)
  }
`;

export const unsubmitHolisticProposalForAdminMutation = gql`
  mutation unsubmitHolisticProposalForAdmin($reportId: ID!) {
    unsubmitHolisticProposalForAdmin(reportId: $reportId)
  }
`;

export const sendHolisticProposalFeedbackMutation = gql`
  mutation SendHolisticProposalFeedback($reportId: ID!, $feedback: String!) {
    sendHolisticProposalFeedback(reportId: $reportId, feedback: $feedback)
  }
`;

export const reviewHolisticProposalMutation = gql`
  mutation reviewHolisticProposal(
    $reportId: ID!
    $status: ReportApprovalStatus!
  ) {
    reviewHolisticProposal(reportId: $reportId, status: $status)
  }
`;

export const extractHolisticProjectProposal = (data) =>
  data && data.holisticProposalForAdmin;
