import {
  createReportAction,
  deleteReportAction,
  updateReportAction,
} from '@aims/shared/redux-store/reports-store';
import ViewHolisticProjectRequest from '@aims/shared/reports/holistic-proposal/ViewHolisticProjectRequest';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useQueryParams from '../../shared/use-query-params';
import ProposalAdminSection from './ProposalAdminSection';
import {
  extractHolisticProjectProposal,
  holisticProposalForAdminQuery,
  holisticProposalUpdatesSubscription,
} from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';

const queryId = 'viewHolisticProposal';

function ViewHolisticProposal() {
  const history = useHistory();
  const [error, setError] = useState(null);

  const { reportId } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id: reportId },
    subscription: holisticProposalUpdatesSubscription,
    createAction: createReportAction,
    updateAction: updateReportAction,
    deleteAction: deleteReportAction,
    extractSomething: (resp) => resp?.data?.holisticProposalUpdatesForAdmin,
    queryId,
    skip: !reportId,
  });
  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: holisticProposalForAdminQuery,
    extract: extractHolisticProjectProposal,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: true,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/holistic-proposals`;
    }
  }, [query]);

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="View Proposal"
      >
        <CSPageHeader
          titleComponent={null}
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          {report && (
            <>
              <ReviewButtons
                report={report}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={() => {}}
                onDelete={() => history.push(backLink)}
              />
              <ViewHolisticProjectRequest
                colors={sharedSettings.colors}
                report={report}
                isAdmin
                adminSection={
                  <ProposalAdminSection report={report} queryId={queryId} />
                }
              />
            </>
          )}
          {reportLoading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <style jsx global>{`
        @media print {
          * {
            visibility: hidden;
          }
          #holisticProjectProposal {
            visibility: visible;
            box-shadow: none !important;
          }
          #holisticProjectProposal * {
            visibility: visible;
          }
          aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.main-nav-menu-slider {
            display: none;
          }
          section > div.page {
            padding: 0px !important;
          }
          section > div.page > div.container > div.header {
            display: none;
          }
        }
      `}</style>
    </>
  );
}

export default ViewHolisticProposal;
