import { useCountries } from '@aims/shared/shared/use-countries';
import { formatPhoneNumberForDisplay } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { useMutation } from '@apollo/client';
import { Divider, message, notification, Skeleton, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import triangleLogo from '../../assets/white-square-logo.png';
import AttachmentsElement from '../loan-agreements/AttachmentsElement';
import { AgreementStatuses } from '../loan-agreements/constants';
import EditableTextElement from '../loan-agreements/EditableTextElement';
import PartiesElement from '../loan-agreements/PartiesElement';
import SectionsElement from '../loan-agreements/SectionsElement';
import { MouRoles, updateMouMutation } from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';

const { Text, Title } = Typography;

function ContactDetails({ contact }) {
  const { countries } = useCountries();
  const getCountry = useCallback(
    (shortCode) => {
      if (countries) {
        const country = countries.find((c) => c.shortCode === shortCode);
        return country && country.name;
      }
      return '';
    },
    [countries],
  );
  const address = contact?.primaryAddress;
  const email = contact?.primaryEmail;
  const phoneNumber = contact?.primaryPhoneNumber;
  const extraAddressData =
    address &&
    [address.city, address.state, address.zipCode].filter((i) => !!i);
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {contact && (
        <>
          <div style={{ fontSize: 16, color: sharedSettings.colors.textGray }}>
            Contact
          </div>
          <Text style={{ fontWeight: 600, fontSize: 18 }}>
            {contact.fullName}
          </Text>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ marginBottom: 8, marginRight: 16 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Address
              </Title>
              {address ? (
                <>
                  {address.address1 && (
                    <div>
                      <Text>{address.address1}</Text>
                    </div>
                  )}
                  {address.address2 && (
                    <div>
                      <Text>{address.address2}</Text>
                    </div>
                  )}
                  {extraAddressData.length ? (
                    <div>
                      <Text>{extraAddressData.join(', ')}</Text>
                    </div>
                  ) : null}
                  <div style={{ marginBottom: 8 }}>
                    <Text>{getCountry(address.country)}</Text>
                  </div>
                </>
              ) : (
                <div>
                  <Text>Not Specified</Text>
                </div>
              )}
            </div>
            <div style={{ marginBottom: 8, marginRight: 16 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Phone Number
              </Title>
              <div>
                <Text>
                  {phoneNumber
                    ? formatPhoneNumberForDisplay(phoneNumber)
                    : 'Not Specified'}
                </Text>
              </div>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Title level={5} style={{ margin: 0 }}>
                Primary Email
              </Title>
              <div>
                <Text>{email || 'Not Specified'}</Text>
              </div>
            </div>
          </div>
        </>
      )}
      {!contact && <Skeleton active />}
    </div>
  );
}

function PeopleGroupDetails({ peopleGroup }) {
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {peopleGroup && (
        <>
          <div style={{ fontSize: 16, color: sharedSettings.colors.textGray }}>
            People Group
          </div>
          <Text style={{ fontWeight: 600, fontSize: 18 }}>
            {`${peopleGroup.nameAcrossCountries} / ${peopleGroup.country} (${peopleGroup._id})`}
          </Text>
        </>
      )}
      {!peopleGroup && <Skeleton active />}
    </div>
  );
}

function ViewMou({ mou, queryId, backTo }) {
  const saveQueue$ = useRef();
  const handleSave = useCallback((values) => {
    saveQueue$.current.next(values);
  }, []);
  const [error, setError] = useState();

  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = mou;
  }, [mou]);
  const [updateMou] = useMutation(updateMouMutation);
  useEffect(() => {
    async function doSave(values) {
      if (values) {
        try {
          if (currentAgreement.current) {
            await updateMou({
              variables: {
                mou: {
                  _id: currentAgreement.current._id,
                  ...values,
                },
              },
            });
            notification.success({
              message: 'Success',
              description: 'Partnership Commitment Form Updated',
            });
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [updateMou, queryId]);

  const status = AgreementStatuses[mou.status];
  const locale = useSelector((store) => store.locale, shallowEqual);
  const history = useHistory();

  return (
    <>
      <ReviewButtons
        mou={mou}
        setError={setError}
        error={error}
        queryId={queryId}
        onSuccess={() => {}}
        onDelete={() => history.push(backTo)}
      />
      <div
        id="mou"
        style={{
          backgroundColor: 'white',
          padding: '32px 16px',
          boxShadow: `0px 0px 4px ${sharedSettings.colors.primary}`,
          borderRadius: 2,
        }}
      >
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div
            style={{
              background: sharedSettings.colors.primary,
              width: 180,
              marginRight: 32,
              marginLeft: -16,
              marginTop: -32,
              padding: 16,
            }}
          >
            <img
              style={{ width: '100%' }}
              src={triangleLogo}
              alt="Triangle Logo"
            />
          </div>
          <Title>Memorandum of Understanding</Title>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <div style={{ fontSize: 18, color: sharedSettings.colors.textGray }}>
            Status
          </div>
          {status && (
            <div
              style={{
                color: sharedSettings.colors[status.color],
                fontSize: 24,
                margin: 0,
                fontWeight: 600,
              }}
            >
              {status.label}
            </div>
          )}
          <div style={{ fontSize: 16 }}>{`Version ${mou.version}`}</div>
        </div>
        {mou && mou.status !== AgreementStatuses.PREVIEW.key && (
          <EditableTextElement
            name="privateNotes"
            label="Admin Notes"
            noLabel="No Notes"
            text={mou && mou.privateNotes}
            isTextArea
            handleSave={handleSave}
            parties={mou && mou.parties}
            attachments={mou && mou.attachments}
            titleLevel={4}
          />
        )}

        <div style={{ textAlign: 'left' }}>
          <Divider />
          {MouRoles[mou.role] && (
            <>
              <div
                style={{ fontSize: 16, color: sharedSettings.colors.textGray }}
              >
                Role
              </div>
              <Text style={{ fontWeight: 600, fontSize: 18 }}>
                {MouRoles[mou.role].label}
              </Text>
            </>
          )}
        </div>
        <ContactDetails contact={mou.contact} />
        <PeopleGroupDetails peopleGroup={mou.peopleGroup} />
        <Divider />
        <PartiesElement loanAgreement={mou} handleSave={handleSave} />
        <SectionsElement loanAgreement={mou} handleSave={handleSave} />
        <AttachmentsElement
          loanAgreementId={mou._id}
          loanAgreement={mou}
          handleSave={handleSave}
        />
        {mou && mou.status !== AgreementStatuses.PREPARING.key && (
          <PartiesElement loanAgreement={mou} handleSave={handleSave} signing />
        )}
      </div>
    </>
  );
}

export default ViewMou;
