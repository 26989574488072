import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import {
  ContactsOutlined,
  CopyOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../../settings';
import { ContactTypes } from '../../../contacts/constants';
import AssociatePayerModalA10 from '../../a10-associate-payers/AssociatePayerModalA10';
import { allPpPayersQuery, extractAllPayers } from '../constants';

const { Title, Text } = Typography;

function AdminListPayers() {
  const [form] = Form.useForm();
  const [numShown, setNumShown] = useState(settings.pageSize);

  const filters = useRef({});
  const sortBy = useRef([
    { key: 'ppCreatedOn', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: payers,
    search: payerSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPpPayersQuery,
    extract: extractAllPayers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'pushpayPayers',
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        setNumShown(settings.pageSize);
        payerSearch(changed.search);
      }
    },
    [payerSearch],
  );

  const handleLoadMore = useCallback(() => {
    setNumShown(numShown + settings.pageSize);
    if (numShown + settings.pageSize > payers.length) {
      next();
    }
  }, [next, numShown, payers]);

  const [assoc, setAssoc] = useState();
  const showAssocModal = useCallback((payer) => {
    setAssoc({ payer });
  }, []);
  const handleCancelAssoc = useCallback(() => {
    setAssoc(undefined);
  }, []);
  const handleAssocFinish = useCallback(() => {
    setAssoc(undefined);
    refetch();
  }, [refetch]);

  return (
    <>
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onSearchChange}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
      </div>
      <Table
        dataSource={payers.slice(0, numShown)}
        loading={!payers.length && loading}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {
              try {
                console.log({
                  record,
                });
              } catch (err) {
                console.log({ record });
              }
            }, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      >
        <Column
          title="Pushpay ID"
          dataIndex="_id"
          render={(text, record) => {
            return (
              <Space>
                <div>
                  <Text
                    style={{ width: 100 }}
                    ellipsis={{ tooltip: record._id }}
                  >
                    {record._id}
                  </Text>
                </div>
                <CopyToClipboard
                  text={record._id}
                  onCopy={() => message.success('Copied!')}
                >
                  <Button icon={<CopyOutlined />} type="text" />
                </CopyToClipboard>
              </Space>
            );
          }}
        />
        <Column title="Name" dataIndex="fullName" key="fullName" width={200} />
        <Column title="Email" dataIndex="emailAddress" key="emailAddress" />
        <Column
          title="Mobile Phone"
          dataIndex="mobileNumber"
          key="mobileNumber"
        />
        <Column
          title="Registered"
          dataIndex="payerType"
          render={(text, record) => {
            return record.payerType === 'Registered' ? 'Yes' : 'No';
          }}
        />
        <Column
          title="Contact"
          dataIndex="contact"
          render={(text, record) => {
            return (
              record.contact && (
                <Link to={`/contact/view/${record.contactId}/dashboard`}>{`${
                  record.contact.fullName
                } (${ContactTypes[record.contact.contactType]?.label})`}</Link>
              )
            );
          }}
        />
        <Column
          title="Updated"
          dataIndex="ppUpdatedOn"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.ppUpdatedOn));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="Associate with Contact">
                <Button
                  onClick={() => showAssocModal(record)}
                  icon={<ContactsOutlined />}
                />
              </Tooltip>
              {/* <Tooltip title="View">
                <Button
                  onClick={() => history.push(`/payer/view/${record._id}`)}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <Button
                  onClick={handleUpdate(record._id)}
                  icon={<EditOutlined />}
                />
              </Tooltip> */}
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {(numShown < payers.length || hasNextPage) && (
          <Button
            style={{ minWidth: 200 }}
            onClick={handleLoadMore}
            loading={loading}
          >
            Load More
          </Button>
        )}
      </div>
      <AssociatePayerModalA10
        visible={assoc}
        onCancel={handleCancelAssoc}
        onDone={handleAssocFinish}
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
      `}</style>
    </>
  );
}

export default AdminListPayers;
