import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../../../tasks/ViewTaskModal/TaskSomethingForm';

const { Text, Title } = Typography;

export const updateContactXOrgMutation = gql`
  mutation UpdateContactXOrg($_id: ID!, $role: String!) {
    updateContactXOrg(_id: $_id, role: $role) {
      contactXOrg {
        _id
      }
    }
  }
`;

function UpdateContactXOrgModal({ visible, onDone, onCancel }) {
  const { contactXOrg } = visible || {};

  const [saving, setSaving] = useState(false);
  const [updateContactXOrg] = useMutation(updateContactXOrgMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { role } = values;
        await updateContactXOrg({
          variables: {
            _id: contactXOrg._id,
            role,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Contact successfully updated',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error updating contact',
        });
      }
      setSaving(false);
    },
    [contactXOrg, updateContactXOrg, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    if (contactXOrg) {
      form.setFieldsValue({
        role: contactXOrg.role,
      });
    }
  }, [form, contactXOrg]);

  return (
    <Modal
      open={!!visible}
      closable={false}
      destroyOnClose={true}
      maskClosable
      cancelText="Cancel"
      footer={null}
      onCancel={onCancel}
      styles={{
        body: {
          paddingTop: 32,
        },
      }}
    >
      <TaskSomethingForm
        title="Update Contact in Organization"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
      >
        <Title level={3}>Update Contact in Organization</Title>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          extra="e.g., CEO, Founder, Sales ..."
        >
          <Input disabled={saving} />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
}

export default UpdateContactXOrgModal;
