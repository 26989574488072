import React from 'react';
import { TrainingQuarterlyReportQuestions } from './questions';

function ViewTrainingQuarterlyReport({ adminSection, report }) {
  return (
    <div className="container">
      <TrainingQuarterlyReportQuestions.title.Display report={report} />
      <TrainingQuarterlyReportQuestions.submittedBy.Display report={report} />
      <TrainingQuarterlyReportQuestions.approvedBy.Display report={report} />
      {adminSection && (
        <>
          <div style={{ height: 8 }} />
          {adminSection}
        </>
      )}
      <TrainingQuarterlyReportQuestions.events.Display report={report} />
      <TrainingQuarterlyReportQuestions.prayerRequests.Display
        report={report}
      />
      <TrainingQuarterlyReportQuestions.miracles.Display report={report} />
      <TrainingQuarterlyReportQuestions.publicFiles.Display report={report} />
      <TrainingQuarterlyReportQuestions.privateFiles.Display report={report} />
      <TrainingQuarterlyReportQuestions.notes.Display report={report} />
      <style jsx>{``}</style>
    </div>
  );
}

export default ViewTrainingQuarterlyReport;
