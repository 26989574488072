export const TagColors = [
  '#cf1322',
  '#d4380d',
  '#d46b08',
  '#d48806',
  '#d4b106',
  '#7cb305',
  '#389e0d',
  '#08979c',
  '#096dd9',
  '#1d39c4',
  '#531dab',
  '#c41d7f',
  '#8c8c8c',
  '#303030',
];
