import { Divider } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { HolisticProjectRequestQuestions } from './questions';

function HolisticProjectRequestItems({
  _id,
  report,
  loading,
  form,
  finishSubForm,
  showTitle,
  fieldsToSet,
  showOnBehalfOf,
  pgConnections = [],
  regionalCoordId,
  isAdmin,
}) {
  useEffect(() => {
    if (report) {
      const values = {
        ...report,
        ...HolisticProjectRequestQuestions.myPeopleGroups.before(report),
        ...HolisticProjectRequestQuestions.onBehalfOf.before(report),
        ...HolisticProjectRequestQuestions.notes.before(report),
      };
      form.setFieldsValue(values);
    } else {
      form.setFieldsValue({
        affectedPeopleGroups: [{}],
        responsiblePersons: [{}],
        supportedFieldWorkers: [{}],
      });
    }
  }, [report, form]);

  finishSubForm.current = useCallback(
    (values) => {
      const modified = { ...values };
      Object.keys(HolisticProjectRequestQuestions).forEach((k) => {
        if (HolisticProjectRequestQuestions[k].finish) {
          const result = HolisticProjectRequestQuestions[k].finish(values);
          Object.entries(result).map(([key, value]) => {
            modified[key] = value;
          });
        }
      });
      return {
        ...modified,
        ...fieldsToSet,
      };
    },
    [fieldsToSet],
  );

  return (
    <>
      {showTitle && (
        <HolisticProjectRequestQuestions.title.Display
          loading={loading}
          fieldsToSet={fieldsToSet}
          report={report}
        />
      )}
      {report && report.isSubmitted && (
        <>
          <HolisticProjectRequestQuestions.submittedBy.Display
            report={report}
          />
          <div style={{ marginBottom: 8 }} />
        </>
      )}
      {showOnBehalfOf && (
        <>
          <HolisticProjectRequestQuestions.onBehalfOf.FormItem
            loading={loading}
            fieldsToSet={fieldsToSet}
          />
          <HolisticProjectRequestQuestions.onBehalfOfName.FormItem
            loading={loading}
            fieldsToSet={fieldsToSet}
          />
        </>
      )}
      <Divider>Project Details</Divider>
      <HolisticProjectRequestQuestions.projectName.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.typeOfProject.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.desc.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.myPeopleGroups.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        pgConnections={pgConnections}
        isAdmin={isAdmin}
      />
      <HolisticProjectRequestQuestions.vision.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.goals.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <Divider>Financial Details</Divider>
      <HolisticProjectRequestQuestions.costAnalysis.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <HolisticProjectRequestQuestions.supportedFieldWorkers.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        regionalCoordId={regionalCoordId}
      />
      <HolisticProjectRequestQuestions.costAnalysisSummary.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.otherProfitUses.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      {/* <HolisticProjectRequestQuestions.loanDetails.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      /> */}
      <HolisticProjectRequestQuestions.holisticPaymentPlan.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.marketAnalysis.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.riskAnalysis.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.locationRisk.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <HolisticProjectRequestQuestions.responsiblePersons.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        regionalCoordId={regionalCoordId}
      />
      <HolisticProjectRequestQuestions.privateFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <HolisticProjectRequestQuestions.notes.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <style jsx>{``}</style>
      <style jsx global>{`
        .form-item-no-input .ant-form-item-control-input {
          min-height: 16px;
        }
      `}</style>
    </>
  );
}

export default HolisticProjectRequestItems;
