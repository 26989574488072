import { Form, Skeleton, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import EditNote from './EditNote';
import ExistingNote from './ExistingNote';
import useProfile from '../../../../shared/use-profile';
import useActivities from '../../../activity/use-activities';

const { Title } = Typography;

function EditTaskNotes({ task }) {
  const [editing, setEditing] = useState(false);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const profile = useProfile();

  const { loading, data: activities } = useActivities({
    filters: { taskId: task._id },
    queryId: task._id,
    skip: !task?._id,
    sortByFunc: (a, b) => {
      return a.createdAt?.localeCompare(b.createdAt);
    },
  });

  return (
    <>
      <Title level={4}>Notes &amp; Discussion</Title>
      {loading && <Skeleton active />}
      {activities.map((item) => (
        <ExistingNote
          key={item._id}
          task={task}
          activity={item}
          locale={locale}
          profile={profile}
        />
      ))}
      {!editing ? (
        <Form layout="vertical">
          <Form.Item>
            <TextArea
              placeholder="What do you think?"
              disabled={loading}
              onFocus={() => {
                setEditing(true);
              }}
              style={{ height: 100 }}
            />
          </Form.Item>
        </Form>
      ) : (
        <EditNote task={task} setEditing={setEditing} />
      )}
    </>
  );
}

export default EditTaskNotes;
