export const READ_TRANSFERS = 'READ_TRANSFERS';
export const CREATE_TRANSFER = 'CREATE_TRANSFER';
export const UPDATE_TRANSFER = 'UPDATE_TRANSFER';
export const DELETE_TRANSFER = 'DELETE_TRANSFER';

// eslint-disable-next-line no-shadow
export const readTransfersAction = (transfers, queryId = 'default') => ({
  type: READ_TRANSFERS,
  payload: {
    transfers,
    queryId,
  },
});

export const createTransferAction = (transfer, queryId = 'default') => ({
  type: CREATE_TRANSFER,
  payload: {
    transfer,
    queryId,
  },
});

export const updateTransferAction = (transfer, queryId = 'default') => ({
  type: UPDATE_TRANSFER,
  payload: {
    transfer,
    queryId,
  },
});

export const deleteTransferAction = (transferId, queryId = 'default') => ({
  type: DELETE_TRANSFER,
  payload: {
    transferId,
    queryId,
  },
});

const initialTransfers = { default: {} };

export function transfers(state = initialTransfers, action) {
  switch (action.type) {
    case READ_TRANSFERS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.transfers.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_TRANSFER:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.transfer._id]: action.payload.transfer,
        },
      };
    case UPDATE_TRANSFER: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.transfer._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.transfer._id]: {
            ...current,
            ...action.payload.transfer,
          },
        },
      };
    }
    case DELETE_TRANSFER: {
      const { transferId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [transferId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
