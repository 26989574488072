import { useApolloClient } from '@apollo/client';
import { notification } from 'antd';
import React, { useEffect } from 'react';
import { constantContactCheckCredentialsQuery } from './constants';

function useCheckCCCreds(permissions) {
  const client = useApolloClient();
  useEffect(() => {
    async function doStuff() {
      try {
        const response = await client.query({
          query: constantContactCheckCredentialsQuery,
          variables: {},
          fetchPolicy: 'network-only',
        });
        const valid =
          response &&
          response.data &&
          response.data.constantContactCheckCredentials;
        if (!valid) {
          notification.warn({
            message: 'Your Constant Contact Credentials Have Expired',
            description: (
              <>
                <div>Sign in and renew them here:</div>
                <div>
                  <a href={`/settings`}>Sign into Constant Contact</a>
                </div>
              </>
            ),
            duration: 0,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
    // if (permissions && permissions.includes('create-cccreds:all')) {
    //   doStuff();
    // }
  }, [permissions, client]);
}

export default useCheckCCCreds;
