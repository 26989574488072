import { EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateLoanAgreementAction } from '../../../redux-store/loan-agreements-store';
import { AgreementStatuses, sendLoanAgreementMutation } from '../constants';

const { Title, Text, Paragraph } = Typography;

const MarkLoanAgreementSigningBtn = ({
  loanAgreement,
  setError,
  error,
  queryId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [sendLoanAgreement] = useMutation(sendLoanAgreementMutation);
  const dispatch = useDispatch();

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [precheck, setPrecheck] = useState();
  useEffect(() => {
    let err;
    if (loanAgreement) {
      loanAgreement.parties &&
        loanAgreement.parties.forEach((party) => {
          if (!party.contactId) {
            err = 'You must select a contact for each party before sending';
          }
        });
    }
    setPrecheck(err);
  }, [loanAgreement]);

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await sendLoanAgreement({
        variables: {
          loanAgreementId: loanAgreement._id,
          status: AgreementStatuses.PREVIEW.key,
        },
      });
      dispatch(
        updateLoanAgreementAction(
          result.data.sendLoanAgreement.loanAgreement,
          queryId,
        ),
      );
      notification.success({
        message: 'Saved',
        description: 'Agreement updated successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: err.message,
      });
      setError(err.message);
    }
    setLoading(false);
  }, [
    setError,
    dispatch,
    queryId,
    sendLoanAgreement,
    loanAgreement,
    onSuccess,
  ]);

  return (
    <>
      <Button
        loading={loading}
        icon={<EyeOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Send Agreement
      </Button>

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Send Loan Agreement
        </Title>
        {precheck ? (
          <div style={{ textAlign: 'center' }}>
            <Paragraph style={{ marginBottom: 24 }} type="danger">
              {precheck}
            </Paragraph>
            <div>
              <Button type="primary" onClick={() => setShowingModal(false)}>
                OK
              </Button>
            </div>
          </div>
        ) : (
          <Form onFinish={handleFinish} layout="vertical">
            <Form.Item>
              <Paragraph style={{ textAlign: 'center' }}>
                {`You are about to send this agreement to each of the parties listed in the agreement.  You will not be able to unsend or edit this agreement after sending.  If you desire to make a change, you'll have to cancel and re-create the agreement.`}
              </Paragraph>
              <Paragraph style={{ textAlign: 'center' }}>
                {`Each party will be asked to sign the agreement.  All parties will be notified when new signatures are applied.`}
              </Paragraph>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <div
                style={{
                  marginRight: -8,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => setShowingModal(null)}
                  htmlType="button"
                  disabled={loading}
                  style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  icon={<SendOutlined />}
                >
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default MarkLoanAgreementSigningBtn;
