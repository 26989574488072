import { SearchOutlined } from '@ant-design/icons';
import { Form, Input, Table } from 'antd';
import Fuse from 'fuse.js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BehaviorSubject, of } from 'rxjs';
import settings from '../../../../settings';
import autocomplete from '../../../../shared/autocomplete';
import { AdoptionLevels, AdoptionStatuses } from '../../../adoptions/constants';
import { PayoutRowStates, PayoutStates } from '../constants';
import ConfirmTransitionRowModalV2 from '../modals/ConfirmTransitionRowModalV2';
import { myLocaleCompare } from '../utils';
import PayoutSelectedRows from './PayoutSelectedRows';
import PayoutActionColumn from './table-columns/PayoutActionColumn';
import PayoutAdoptionStatusColumn from './table-columns/PayoutAdoptionStatusColumn';
import PayoutCanBeCompletedColumn from './table-columns/PayoutCanBeCompletedColumn';
import PayoutDetailsColumn from './table-columns/PayoutDetailsColumn';
import PayoutHasReportColumn from './table-columns/PayoutHasReportColumn';
import PayoutNoteColumn from './table-columns/PayoutNoteColumn';
import PayoutPeopleGroupColumn from './table-columns/PayoutPeopleGroupColumn';
import PayoutPhotoColumn from './table-columns/PayoutPhotoColumn';
import PayoutRowStateColumn from './table-columns/PayoutRowStateColumn';
import PayoutWillRecieveColumn from './table-columns/PayoutWillRecieveColumn';
import PayoutFromPoolColumn from './table-columns/PayoutFromPoolColumn';
import RefreshRowColumn from './table-columns/RefreshRowColumn';

const searchOptions = {
  includeScore: true,
  keys: [
    '_id',
    'adoptionCopy.adopterName',
    'adoptionCopy.fieldWorkerName',
    'adoptionCopy.regionalCoordName',
    'adoptionCopy.peopleGroup._id',
    'adoptionCopy.peopleGroup.nameAcrossCountries',
    'adoptionCopy.peopleGroup.country',
    'notes',
  ],
};

function PayoutRowsTable({
  payout,
  savePayoutRow,
  transitionRows,
  rows,
  hideSearch = false,
  loading,
}) {
  const [form] = Form.useForm();

  const term$ = useRef(null);
  const [search, setSearch] = useState();
  useEffect(() => {
    term$.current = new BehaviorSubject('');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          return of(setSearch(term));
        }),
      )
      .subscribe();
  }, []);

  const onSearchChange = useCallback((changed) => {
    if (changed && changed.search !== undefined) {
      term$.current.next(changed.search);
    }
  }, []);

  const filteredRwos = useMemo(() => {
    if (!rows) {
      return [];
    }
    let _rows = rows.filter((o) => {
      if (
        [PayoutStates.IN_PROGRESS.key, PayoutStates.COMPLETE.key].includes(
          payout.state,
        )
      ) {
        return o.willReceiveFunds;
      }
      return true;
    });
    if (search) {
      const fuse = new Fuse(_rows, searchOptions);
      _rows = fuse
        .search(search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return _rows;
    }
    return _rows.sort((a, b) => a._id.localeCompare(b._id));
  }, [rows, search, payout.state]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  const [selected, setSelected] = useState([]);

  const [transitioning, setTransitioning] = useState();
  const handleTransition = useCallback((_rows, newState) => {
    setTransitioning({
      payoutRows: _rows,
      newState,
    });
  }, []);
  const handleFinishTransition = useCallback(
    async (rowIds, newState) => {
      await transitionRows(rowIds, newState);
      setTransitioning(undefined);
      setSelected([]);
    },
    [transitionRows],
  );
  const handleCancelTransition = useCallback(() => {
    setTransitioning(undefined);
    setSelected([]);
  }, []);
  return (
    <>
      {!hideSearch && (
        <div className="top-actions">
          <Form
            layout="inline"
            form={form}
            onValuesChange={onSearchChange}
            style={{ marginBottom: 16 }}
          >
            <Form.Item name="search">
              <Input
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ maxWidth: 500, minWidth: 300 }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
      <PayoutSelectedRows
        payout={payout}
        selected={selected}
        handleTransition={handleTransition}
      />
      <Table
        size="small"
        loading={loading}
        dataSource={filteredRwos}
        pagination={{ ...pagination, total: filteredRwos.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1300 }}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selected.map((r) => r._id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRows);
          },
          getCheckboxProps: (record) => ({
            disabled: false,
            name: record._id,
          }),
        }}
      >
        <Table.Column
          title="Photo"
          dataIndex="photo"
          fixed="left"
          width={80}
          render={(text, record) => <PayoutPhotoColumn record={record} />}
        />
        <Table.Column
          title="Sponsors"
          dataIndex="adopter"
          width={200}
          fixed="left"
          sorter={{
            compare: (a, b) => {
              const aComp = a?.adoptionCopy?.sponsorNames?.[0];
              const bComp = b?.adoptionCopy?.sponsorNames?.[0];
              return myLocaleCompare(aComp, bComp);
            },
            multiple: 1,
          }}
          sortDirections={['ascend', 'descend']}
          render={(text, record) => {
            return record?.adoptionCopy?.sponsorNames?.join(', ');
          }}
        />
        <Table.Column
          title="Status"
          dataIndex="adoptionStatus"
          width={136}
          filters={Object.values(AdoptionStatuses).map((s) => ({
            text: s.label,
            value: s.key,
          }))}
          onFilter={(value, record) =>
            record.adoptionCopy && record.adoptionCopy.status === value
          }
          render={(text, record) => (
            <PayoutAdoptionStatusColumn record={record} />
          )}
        />
        <Table.Column
          title="Level"
          dataIndex="level"
          width={100}
          filters={Object.values(AdoptionLevels)
            .filter((a) => a.defaultMonthlyAmount > 0)
            .map((a) => ({
              text: a.label,
              value: a.key,
            }))}
          onFilter={(value, record) =>
            record.adoptionCopy && record.adoptionCopy.level === value
          }
          render={(text, record) => {
            return (
              record.adoptionCopy &&
              record.adoptionCopy.level &&
              AdoptionLevels[record.adoptionCopy.level] &&
              AdoptionLevels[record.adoptionCopy.level].label
            );
          }}
        />
        <Table.Column
          title="People Group"
          dataIndex="peopleGroup"
          width={200}
          sorter={{
            compare: (a, b) => {
              const aComp =
                a.adoptionCopy &&
                a.adoptionCopy.peopleGroup &&
                a.adoptionCopy.peopleGroup._id;
              const bComp =
                b.adoptionCopy &&
                b.adoptionCopy.peopleGroup &&
                b.adoptionCopy.peopleGroup._id;
              return myLocaleCompare(aComp, bComp);
            },
            multiple: 2,
          }}
          sortDirections={['ascend', 'descend']}
          render={(text, record) => <PayoutPeopleGroupColumn record={record} />}
        />
        <Table.Column
          title="Regional Coordinator"
          dataIndex="regionalCoord"
          width={200}
          sorter={{
            compare: (a, b) => {
              const aComp = a.adoptionCopy && a.adoptionCopy.regionalCoordName;
              const bComp = b.adoptionCopy && b.adoptionCopy.regionalCoordName;
              return myLocaleCompare(aComp, bComp);
            },
            multiple: 3,
          }}
          sortDirections={['ascend', 'descend']}
          render={(text, record) => {
            return record.adoptionCopy && record.adoptionCopy.regionalCoordName;
          }}
        />
        <Table.Column
          title="Field Worker"
          dataIndex="fieldWorker"
          width={200}
          sorter={{
            compare: (a, b) => {
              const aComp = a.adoptionCopy && a.adoptionCopy.fieldWorkerName;
              const bComp = b.adoptionCopy && b.adoptionCopy.fieldWorkerName;
              return myLocaleCompare(aComp, bComp);
            },
            multiple: 4,
          }}
          sortDirections={['ascend', 'descend']}
          render={(text, record) => {
            return record.adoptionCopy && record.adoptionCopy.fieldWorkerName;
          }}
        />
        <Table.Column
          title="Has Report"
          dataIndex="hasReport"
          width={80}
          filters={[
            {
              text: 'Yes',
              value: true,
            },
            {
              text: 'No',
              value: false,
            },
          ]}
          onFilter={(value, record) => record.hasReport === value}
          render={(text, record) => <PayoutHasReportColumn record={record} />}
        />
        <Table.Column
          title="Amount"
          dataIndex="amount"
          width={200}
          render={(text, record) => <PayoutDetailsColumn record={record} />}
        />
        {payout.state !== PayoutStates.COMPLETE.key && (
          <Table.Column
            title="Can be Completed"
            dataIndex="canBeCompleted"
            width={120}
            filters={[
              {
                text: 'Yes',
                value: 'YES',
              },
              {
                text: 'No',
                value: 'NO',
              },
              {
                text: 'Warning',
                value: 'MAYBE',
              },
            ]}
            onFilter={(value, record) =>
              (record.completable && record.completable.completable) === value
            }
            render={(text, record) => (
              <PayoutCanBeCompletedColumn record={record} />
            )}
          />
        )}
        {payout.state !== PayoutStates.COMPLETE.key && (
          <Table.Column
            title="Reason"
            dataIndex="reason"
            width={200}
            render={(text, record) => {
              return record.state !== PayoutRowStates.COMPLETE.key
                ? record.completable && record.completable.reason
                : null;
            }}
          />
        )}
        <Table.Column
          title="Notes"
          dataIndex="notes"
          width={200}
          render={(text, record) => {
            return (
              <PayoutNoteColumn record={record} savePayoutRow={savePayoutRow} />
            );
          }}
        />
        {payout.state === PayoutStates.DRAFT.key && (
          <>
            {/* <Table.Column
              title="From Pool"
              key="fromPool"
              width={100}
              render={(text, record) => (
                <PayoutFromPoolColumn record={record} />
              )}
            /> */}
            <Table.Column
              title="Refresh"
              key="refreshRow"
              width={100}
              render={(text, record) => <RefreshRowColumn record={record} />}
            />
          </>
        )}
        {payout.state !== PayoutStates.COMPLETE.key && (
          <Table.Column
            title="Do Payout"
            key="doPayout"
            width={80}
            fixed={
              payout.state === PayoutStates.DRAFT.key ? 'right' : undefined
            }
            render={(text, record) => (
              <PayoutWillRecieveColumn
                editable={payout.state === PayoutStates.DRAFT.key}
                record={record}
                savePayoutRow={savePayoutRow}
              />
            )}
          />
        )}
        {payout.state !== PayoutStates.DRAFT.key && (
          <Table.Column
            title="State"
            key="state"
            width={100}
            fixed="right"
            render={(text, record) => <PayoutRowStateColumn record={record} />}
          />
        )}
        {payout.state === PayoutStates.IN_PROGRESS.key && (
          <Table.Column
            title="Action"
            key="action"
            width={190}
            fixed="right"
            render={(text, record) => (
              <PayoutActionColumn
                editable={payout.state === PayoutStates.IN_PROGRESS.key}
                record={record}
                handleTransition={handleTransition}
              />
            )}
          />
        )}
      </Table>
      <ConfirmTransitionRowModalV2
        confirming={transitioning}
        onCancel={handleCancelTransition}
        onFinish={handleFinishTransition}
      />
    </>
  );
}

export default PayoutRowsTable;
