import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AssigneeFormItem from '../../../components/AssigneeFormItem';
import ContentEditableInput, {
  serializeText,
} from '../../../components/ContentEditableInput';
import DateFormItem from '@aims/shared/components/DateFormItem';
import SelectHolisticProject from '../../../pages/holistic-projects/SelectHolisticProject';
import SelectContact from '../../contacts/SelectContact';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import TagsFormItem from '../../tags/TagsFormItem';
import {
  createTaskMutation,
  Statuses,
  TaskPriorities,
  updateTaskMutation,
} from './constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Text } = Typography;

function EditTaskForm({
  _id,
  handleCancel,
  handleSuccess,
  taskResult,
  loading,
  setLoading,
  style,
  fieldsToSet = {},
}) {
  const [error, setError] = useState(null);
  const [updateTask] = useMutation(updateTaskMutation);
  const [createTask] = useMutation(createTaskMutation);
  const [task, setTask] = useState();

  const [form] = Form.useForm();

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (!taskResult.loading && !onlyOnce.current) {
      if (taskResult.task) {
        setTask(taskResult.task);
        onlyOnce.current = true;
      }
    }
  }, [taskResult]);

  useEffect(() => {
    if (task) {
      const { assigneeIds, windowStart, windowStop, ...otherFields } = task;
      otherFields.assigneeIds = assigneeIds || [];
      form.setFieldsValue({
        windowStart: windowStart || new Date().toISOString(),
        windowStop: windowStop || new Date().toISOString(),
        ...otherFields,
      });
    } else {
      form.setFieldsValue({
        windowStart: new Date().toISOString(),
        windowStop: new Date().toISOString(),
      });
    }
  }, [task, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      try {
        const { desc, ...values } = _values;

        if (task) {
          await updateTask({
            variables: {
              task: {
                _id,
                ...values,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Task updated successfully',
          });
        } else {
          await createTask({
            variables: {
              task: {
                _id,
                ...values,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Task created successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [task, _id, createTask, updateTask, setLoading, fieldsToSet, handleSuccess],
  );

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="editTaskForm"
        form={form}
        style={style}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Please enter an task title' }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        {!fieldsToSet.peopleGroupId && !fieldsToSet.contactId && (
          <Form.Item
            label="Contact, Household, Organization"
            extra="Associate this task with a contact, household or organization"
            name="contactId"
          >
            <SelectContact disabled={loading} filters={{}} />
          </Form.Item>
        )}
        {!fieldsToSet.peopleGroupId && !fieldsToSet.contactId && (
          <Form.Item
            label="PeopleGroup"
            extra="Associate this task with a people group"
            name="peopleGroupId"
          >
            <SelectPeopleGroup disabled={loading} />
          </Form.Item>
        )}
        <Form.Item label="Select a Project" name="holisticProjectId">
          <SelectHolisticProject
            disabled={loading || fieldsToSet.holisticProjectId}
            id={fieldsToSet.holisticProjectId}
          />
        </Form.Item>
        <AssigneeFormItem loading={loading} form={form} />
        <Form.Item label="Status" name="status" initialValue="TODO">
          <Select style={{ width: '100%' }}>
            {Statuses.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Importance" name="importance" initialValue="MEDIUM">
          <Select style={{ width: '100%' }}>
            {TaskPriorities.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <TagsFormItem
          name="tags"
          label="Tags"
          saving={taskResult.loading && loading}
        />
        <DateFormItem
          label="Scheduled Start"
          extra="Change this for tasks that shouldn't be started or completed right away"
          name="windowStart"
          rules={[{ required: true, message: 'Please select a date' }]}
          showTime
        />
        <DateFormItem
          label="Finish by"
          name="windowStop"
          rules={[{ required: true, message: 'Please select a date' }]}
          showTime
        />
        <Form.Item label="Description" name="desc">
          <ContentEditableInput disabled={loading} style={{ minHeight: 100 }} />
        </Form.Item>
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              key="cancel"
              onClick={handleCancel}
              htmlType="button"
              type="text"
              size="small"
              disabled={loading}
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: -7 }}
            >
              Cancel
            </Button>
            <Button
              key="send"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Save Task
            </Button>
          </Space>
        </Form.Item>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Form>

      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
        .send-invite-checkbox {
          flex-shrink: 0;
          margin-left: 8px;
        }
        .note {
          margin-bottom: 16px;
          display: flex;
          width: 100%;
        }
        .inner-note {
          margin-right: 8px;
          flex: 1;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditTaskForm;
