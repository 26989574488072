import { gql } from '@apollo/client';

export const HistoryV2Attributes = gql`
  {
    _id
    contactId
    activityId
    taskId
    eventId
    userId
    holisticProjectId
    adoptionId
    projectSponsorshipId
    typename
    changedAt
    changedBy
    changedByName
    changedById
    changes
    updatedAt
    updatedBy
    createdAt
    createdBy
  }
`;

export const createHistoryV2Mutation = gql`
  mutation createHistoryV2($history: HistoryV2CreateInput!) {
    createHistoryV2(history: $history) {
      history ${HistoryV2Attributes}
    }
  }
`;

export const updateHistoryV2Mutation = gql`
  mutation updateHistoryV2($history: HistoryV2UpdateInput!) {
    updateHistoryV2(history: $history) {
      history ${HistoryV2Attributes}
    }
  }
`;

export const HistoryV2UpdateAttributes = gql`
{
  crud
  id
  new ${HistoryV2Attributes}
}
`;

export const historyV2UpdatesForAdminSubscription = gql`
  subscription HistoryV2UpdatesForAdmin($filters: HistoryV2UpdateFiltersForAdmin!) {
    historyV2UpdatesForAdmin(filters: $filters) ${HistoryV2UpdateAttributes}
  }
`;

export const allHistoryV2ForAdminQuery = gql`
  query AllHistoryV2ForAdmin($first: Int, $after: String, $filters: HistoryV2FiltersForAdmin, $sortBy: [SortBy]) {
    allHistoryV2ForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${HistoryV2Attributes}
      }
    }
  }
`;

export const extractAllHistoryV2ForAdmin = (data) => data.allHistoryV2ForAdmin;
