import React from 'react';
import { displayMoney } from '../../shared/utils';

function LoanStatusSummary({ loanPaymentsTotalUsd, loanTotal, loanTerm }) {
  const loanBalance = loanTotal - loanPaymentsTotalUsd;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          fontSize: 16,
          textAlign: 'left',
          marginBottom: 16,
          marginRight: 32,
        }}
      >
        <div>{`Loan Amount: ${displayMoney(loanTotal)}`}</div>
        <div>{`Loan Term: ${loanTerm} Months`}</div>
      </div>
      <div
        style={{
          fontSize: 16,
          textAlign: 'left',
          marginBottom: 16,
        }}
      >
        <div>{`Total Payments: ${displayMoney(loanPaymentsTotalUsd)}`}</div>
        <div>{`Loan Balance: ${displayMoney(loanBalance)}`}</div>
      </div>
    </div>
  );
}

export default LoanStatusSummary;
