import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import {
  createActivityAction,
  deleteActivityAction,
  readActivitiesAction,
  updateActivityAction,
} from '../../../../../redux-store/activity-v2-store';
import settings from '../../../../../settings';

const FileAttributes = gql`
  {
    _id
    desc
    s3Key
    size
    filename
    contentType
    tags
    url
    uploaded
    processed
    thumbnail {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    images {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    type
    attachedTo
    createdAt
    updatedAt
  }
`;

export const FileUpdateAttributes = gql`
{
  crud
  id
  new ${FileAttributes}
}
`;

export const fileUpdatesForAdminSubscription = gql`
  subscription FileUpdatesForAdmin($filters: FileUpdateFiltersForAdmin!) {
    fileUpdatesForAdmin(filters: $filters) ${FileUpdateAttributes}
  }
`;

export const allFilesForAdminQuery = gql`
  query AllFilesForAdmin($first: Int, $after: String, $filters: FileFiltersForAdmin, $sortBy: [SortBy]) {
    allFilesForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${FileAttributes}
      }
    }
  }
`;

export const extractAllFilesForAdmin = (data) => data.allFilesForAdmin;

function useHolisticPhotos({ filters, skip, sortByFunc, projectId }) {
  const queryId = useMemo(
    () => `${projectId}.${filters.type}.photos`,
    [projectId, filters.type],
  );
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters },
    subscription: fileUpdatesForAdminSubscription,
    createAction: createActivityAction,
    updateAction: updateActivityAction,
    deleteAction: deleteActivityAction,
    extractSomething: (resp) => resp?.data?.fileUpdatesForAdmin,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allFilesForAdminQuery,
    extract: extractAllFilesForAdmin,
    readAction: readActivitiesAction,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, [sortByFunc]);
  const { data, search } = useManySomethingsM20({
    table: 'activities',
    sortByFunc: defaultSort,
    searchKeys: ['tags', '_id', 'filename', 'desc'],
    queryId,
  });
  return {
    loading,
    search,
    data,
  };
}

export default useHolisticPhotos;
