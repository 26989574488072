import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import UserEvents from './UserEvents';
import UserFeed from './UserFeed';
import UserRecents from './UserRecents';
import UserTasks from './UserTasks';

function Dashboard() {
  return (
    <CSPage title="Dashboard">
      <CSPageHeader title="Dashboard" />
      <UserRecents />
      <div style={{ height: 32 }} />
      <UserTasks />
      <div style={{ height: 32 }} />
      <UserEvents />
      <div style={{ height: 32 }} />
      <UserFeed />
      <style jsx>{`
        .body {
          display: flex;
        }
      `}</style>
    </CSPage>
  );
}

export default Dashboard;
