import { gql } from '@apollo/client';

export const SavedImageListAttributes = gql`
  {
    _id
    fileId
    file {
      _id
      url
      filename
      contentType
      uploaded
      processed
      thumbnail {
        size
        url
        height
        width
        sizeCode
      }
    }
    desc
    reportId
    peoplGroupId
    peopleGroup {
      _id
      nameAcrossCountries
      country
    }
    holisticProjectId
    holisticProject {
      projectName
      typeOfProject
    }
    tags
    createdAt
    updatedAt
  }
`;

export const allSavedImagesQuery = gql`
  query AllSavedImages($first: Int, $after: String, $filters: SavedImageFilters, $sortBy: [SortBy]) {
    allSavedImages(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${SavedImageListAttributes}
      }
    }
  }
`;

export const extractAllSavedImagesFromQuery = (data) =>
  data && data.allSavedImages;
