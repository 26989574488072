import { gql } from '@apollo/client';

export const trainingFileFields = gql`
  {
    _id
    fileName
    desc
    courseId
    moduleId
    file {
      _id
      desc
      s3Key
      size
      filename
      contentType
      tags
      url
      uploaded
      processed
    }
    fileId
    language
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allTrainingFilesQuery = gql`
  query AllTrainingFilesForAdmin(
    $first: Int
    $after: String
    $filters: TrainingFileFiltersForAdmin
  ) {
    allTrainingFilesForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${trainingFileFields}
      }
    }
  }
`;

export const extractAllTrainingFiles = (data) => data.allTrainingFilesForAdmin;

const TrainingFileUpdateAttributes = gql`
{
  crud
  id
  new ${trainingFileFields}
}
`;

export const trainingFileUpdatesSubscription = gql`
  subscription TrainingFileUpdatesForAdmin($filters: TrainingFileFiltersForAdmin!) {
    trainingFileUpdatesForAdmin(filters: $filters) ${TrainingFileUpdateAttributes}
  }
`;

export const createTrainingFileMutation = gql`
  mutation createTrainingFile($trainingFile: TrainingFileCreateInput!) {
    createTrainingFile(trainingFile: $trainingFile) {
      trainingFile ${trainingFileFields}
    }
  }
`;

export const updateTrainingFileMutation = gql`
  mutation updateTrainingFile($trainingFile: TrainingFileUpdateInput!) {
    updateTrainingFile(trainingFile: $trainingFile) {
      trainingFile ${trainingFileFields}
    }
  }
`;

export const deleteTrainingFileMutation = gql`
  mutation deleteTrainingFile($_id: ID!) {
    deleteTrainingFile(_id: $_id) {
      deletedId
    }
  }
`;
