import { Tabs } from 'antd';
import React, { useMemo, useState } from 'react';
import { StatusColors } from './EditTask/constants';
import TasksList from './TasksList';

function TaskTabs({ tasks: parentTasks, loading }) {
  const [tab, setTab] = useState(StatusColors.TODO.key);

  const taskBuckets = useMemo(() => {
    const _taskBuckets = Object.values(StatusColors).reduce((prev, curr) => {
      prev[curr.key] = [];
      return prev;
    }, {});
    parentTasks.forEach((task) => {
      if (
        new Date(task.windowStart) >= new Date() &&
        task.status === StatusColors.TODO.key
      ) {
        _taskBuckets[StatusColors.SCHEDULED.key].push(task);
      } else {
        _taskBuckets[task.status].push(task);
      }
    });
    return _taskBuckets;
  }, [parentTasks]);

  const tasks = useMemo(() => taskBuckets[tab], [taskBuckets, tab]);

  return (
    <Tabs
      defaultActiveKey="accounts"
      activeKey={tab}
      onChange={(key) => setTab(key)}
    >
      {Object.values(StatusColors)
        .sort((a, b) => a.sort - b.sort)
        .map((color) => (
          <Tabs.TabPane tab={color.label} key={color.key}>
            <TasksList tasks={tasks} loading={loading} />
          </Tabs.TabPane>
        ))}
    </Tabs>
  );
}

export default TaskTabs;
