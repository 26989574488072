import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import { generateUuid } from '../../shared/utils';
import ListMous from './ListMous';
import { allMouTemplatesForAdminQuery, extractMousForAdmin } from './constants';

function ListMouTemplatesPage() {
  const createId = useMemo(() => {
    return generateUuid();
  }, []);

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: true,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: mous,
    search: mousSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allMouTemplatesForAdminQuery,
    extract: extractMousForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'listMouTemplatesPage',
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        mousSearch(search);
      } else {
        filters.current = {
          isTemplate: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [mousSearch, refetch, sortedBy, sortOrder],
  );

  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Partnership Commitment Form Templates"
      >
        <CSPageHeader
          title="Partnership Commitment Form Templates"
          topActions={[
            <Link
              key="add"
              to={{
                pathname: `/mou-template/view/${createId}`,
                state: {
                  mode: 'create',
                },
              }}
            >
              <Button type="link" icon={<PlusCircleOutlined />} size="large">
                Add a Partnership Commitment Form Template
              </Button>
            </Link>,
          ]}
        />
        <ListMous
          loading={loading}
          error={error}
          mous={mous}
          next={next}
          hasNextPage={hasNextPage}
          refetch={refetch}
          isTemplate
          onFiltersChanged={onFiltersChanged}
          onSortedByChange={onSortedByChange}
          onSortOrderChange={onSortOrderChange}
        />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default ListMouTemplatesPage;
