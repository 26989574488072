import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { deletePublicFormMutation, PublicFormTypes } from './constants';

const { Text } = Typography;

function DeletePublicFormModal({ deleting, onFinish, onCancel }) {
  const { publicForm } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deletePublicForm] = useMutation(deletePublicFormMutation);

  const formLabel =
    publicForm &&
    PublicFormTypes[publicForm.formType] &&
    PublicFormTypes[publicForm.formType].label;

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await deletePublicForm({
        variables: {
          _id: publicForm._id,
        },
      });

      notification.success({
        message: 'Deleted',
        description: `Submitted ${formLabel} deleted successfully`,
      });
      onFinish();
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [deletePublicForm, publicForm, onFinish, formLabel]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete Submitted Form"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text>{`Are you sure you want to delete this submitted ${formLabel}?`}</Text>
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!publicForm}
      loading={loading}
      error={error}
    />
  );
}

export default DeletePublicFormModal;
