import { gql } from '@apollo/client';

export const PpFundListAttributes = gql`
  {
    _id
    name
    code
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const allPpFundsQuery = gql`
  query AllPpFundsForAdmin($first: Int, $after: String, $filters: FundFiltersForAdmin, $sortBy: [SortBy]) {
    allPpFundsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PpFundListAttributes}
      }
    }
  }
`;

export const extractAllFunds = (data) => data.allPpFundsForAdmin;

export const ppFundQuery = gql`
  query PpFund($_id: ID!) {
    ppFund(_id: $_id)  ${PpFundListAttributes}
  }
`;
