import sharedSettings from '@aims/shared/sharedSettings';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../../components/PhotoCircle';
import AddContactToOrgModal from './AddContactToOrgModal';
import ContactChangeSomethingModalA10 from './ContactChangeSomethingModalA10';
import ContactMakePrimaryModalA10 from './ContactMakePrimaryModalA10';
import DeleteContactXOrgModal from './DeleteContactXOrgModal';
import UpdateContactXOrgModal from './UpdateContactXOrgModal';

const { Title, Text } = Typography;

function OrgMembers({ parent }) {
  const [makingPrimary, setMakingPrimary] = useState();
  const handleMakePrimary = (contact) => setMakingPrimary(contact.contact);
  const onFinishMakePrimary = () => setMakingPrimary(undefined);
  const onCancelMakePrimary = () => setMakingPrimary(undefined);

  const [removing, setRemoving] = useState();
  const handleRemove = (contactXOrg) =>
    setRemoving({ org: parent, contactXOrg, contact: contactXOrg.contact });
  const onFinishRemove = () => setRemoving(undefined);
  const onCancelRemove = () => setRemoving(undefined);

  const [adding, setAdding] = useState();
  const handleAdd = () => setAdding(true);
  const onAddDone = () => setAdding(false);
  const onAddCancel = () => setAdding(false);

  const [updating, setUpdating] = useState();
  const handleUpdate = (contactXOrg) => setUpdating({ contactXOrg });
  const onFinishUpdate = () => setUpdating(false);
  const onCancelUpdate = () => setUpdating(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 40, marginRight: 8 }}>
            <PhotoCircle
              photoUrl={record.contact?.primaryThumbnailUrl}
              name={record.contact?.fullName}
              customSize={40}
              color={sharedSettings.colors.organizationColor}
              style={{
                padding: 0,
                height: 40,
                width: 40,
              }}
            />
          </div>
          <Text>{record.contact?.fullName}</Text>
        </div>
      ),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
    },
    {
      title: 'Primary?',
      key: 'primary',
      render: (text, record) =>
        record.contact?._id === parent.primaryContactId ? (
          <Typography>
            <StarFilled style={{ marginRight: 4 }} />
            Yes
          </Typography>
        ) : (
          'No'
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {parent.primaryContactId !== record.contact?._id && (
            <Tooltip title="Make Primary Contact">
              <Button
                onClick={() => handleMakePrimary(record)}
                icon={<StarOutlined />}
              />
            </Tooltip>
          )}
          <Tooltip title="Change Member Role">
            <Button
              onClick={() => handleUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="View Contact Page">
            <Link to={`/contact/view/${record.contact?._id}/dashboard`}>
              <Button icon={<EyeOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Remove from Organization">
            <Button
              onClick={() => handleRemove(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  console.log('parent', parent);
  return (
    <div className="container">
      <Title level={2} style={{ marginBottom: 0 }}>
        Organization Members
      </Title>
      <Button
        type="text"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        style={{ marginBottom: 16, marginLeft: -16 }}
      >
        Add an Organization Member
      </Button>
      <Table
        columns={columns}
        dataSource={parent?.orgMembers}
        pagination={false}
        rowKey="_id"
      />
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={makingPrimary}
        onDone={onFinishMakePrimary}
        onCancel={onCancelMakePrimary}
      >
        <ContactMakePrimaryModalA10 child={makingPrimary} />
      </ContactChangeSomethingModalA10>
      <DeleteContactXOrgModal
        visible={removing}
        onDone={onFinishRemove}
        onCancel={onCancelRemove}
      />
      <AddContactToOrgModal
        org={parent}
        adding={adding}
        onDone={onAddDone}
        onCancel={onAddCancel}
      />
      <UpdateContactXOrgModal
        visible={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      />
      <style jsx>{`
        .container {
          margin-bottom: 32px;
        }
      `}</style>
    </div>
  );
}

export default OrgMembers;
