export const READ_UPG_PRAYER_ADOPTIONS = 'READ_UPG_PRAYER_ADOPTIONS';
export const CREATE_UPG_PRAYER_ADOPTION = 'CREATE_UPG_PRAYER_ADOPTION';
export const UPDATE_UPG_PRAYER_ADOPTION = 'UPDATE_UPG_PRAYER_ADOPTION';
export const DELETE_UPG_PRAYER_ADOPTION = 'DELETE_UPG_PRAYER_ADOPTION';

export const readUpgPrayerAdoptionsAction = (
  // eslint-disable-next-line no-shadow
  upgPrayerAdoptions,
  queryId = 'default',
) => ({
  type: READ_UPG_PRAYER_ADOPTIONS,
  payload: {
    upgPrayerAdoptions,
    queryId,
  },
});

export const createUpgPrayerAdoptionAction = (
  upgPrayerAdoption,
  queryId = 'default',
) => ({
  type: CREATE_UPG_PRAYER_ADOPTION,
  payload: {
    upgPrayerAdoption,
    queryId,
  },
});

export const updateUpgPrayerAdoptionAction = (
  upgPrayerAdoption,
  queryId = 'default',
) => ({
  type: UPDATE_UPG_PRAYER_ADOPTION,
  payload: {
    upgPrayerAdoption,
    queryId,
  },
});

export const deleteUpgPrayerAdoptionAction = (
  upgPrayerAdoptionId,
  queryId = 'default',
) => ({
  type: DELETE_UPG_PRAYER_ADOPTION,
  payload: {
    upgPrayerAdoptionId,
    queryId,
  },
});

const initialUpgPrayerAdoptions = { default: {} };

export function upgPrayerAdoptions(state = initialUpgPrayerAdoptions, action) {
  switch (action.type) {
    case READ_UPG_PRAYER_ADOPTIONS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.upgPrayerAdoptions.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_UPG_PRAYER_ADOPTION:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.upgPrayerAdoption._id]:
            action.payload.upgPrayerAdoption,
        },
      };
    case UPDATE_UPG_PRAYER_ADOPTION: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][
            action.payload.upgPrayerAdoption._id
          ]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.upgPrayerAdoption._id]: {
            ...current,
            ...action.payload.upgPrayerAdoption,
          },
        },
      };
    }
    case DELETE_UPG_PRAYER_ADOPTION: {
      const { upgPrayerAdoptionId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [upgPrayerAdoptionId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
