import { getFileIcon } from '@aims/shared/shared/icons';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Empty, Space, Spin, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import settings from '../../../settings';
import { titleCase } from '../../../shared/utils';
import useCanEditTraining from '../training_courses/use-can-edit-training';

const { Text, Title } = Typography;

const TrainingFilesTable = ({
  trainingFiles,
  loading,
  onEdit,
  onDownload,
  onDelete,
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  const showCreate = useCanEditTraining();

  return (
    <Table
      dataSource={trainingFiles}
      pagination={{ ...pagination, total: trainingFiles?.length }}
      onChange={handleTableChange}
      style={{ width: '100%' }}
      rowKey="_id"
      locale={{
        emptyText:
          !trainingFiles?.length && loading ? (
            <Spin spinning />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Files"
            />
          ),
      }}
    >
      <Column
        title="File Name"
        dataIndex="fileName"
        render={(text, record) => {
          const icon = getFileIcon(record.file.filename, {
            fontSize: 18,
            marginRight: 8,
          });
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {icon}
              <Text>{record.fileName}</Text>
            </div>
          );
        }}
      />
      <Column
        title="Language"
        dataIndex="language"
        render={(text, record) => {
          return record.language && titleCase(record.language);
        }}
      />
      <Column
        title="Description"
        dataIndex="desc"
        render={(text, record) => {
          return record.desc;
        }}
      />
      <Column
        title="Action"
        key="action"
        width="200px"
        render={(text, record) => (
          <Space>
            {showCreate && (
              <Tooltip title="Edit">
                <Button
                  onClick={() => onEdit(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {record.file && record.file.url && (
              <Tooltip title="Download">
                <Button
                  onClick={() => onDownload(record)}
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
            )}
            {showCreate && (
              <Tooltip title="Delete">
                <Button
                  onClick={() => onDelete(record)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        )}
      />
    </Table>
  );
};

export default TrainingFilesTable;
