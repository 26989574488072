import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { Form, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function HProjectChangeTypeInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Project Type',
  label = 'Project Type',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        typeOfProject: holisticProject.typeOfProject,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { typeOfProject, ...values } = _values;
      handleFinish({
        typeOfProject,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label={label}
        name="typeOfProject"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select disabled={saving}>
          {Object.entries(HolisticProjectTypes).map(([key, obj]) => (
            <Select.Option key={key} value={key}>
              {obj.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeTypeInner;
