import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { generateUuid } from '../../shared/utils';
import sharedSettings from '../../sharedSettings';
import {
  reportApprovedBy,
  reportMiracles,
  reportNotes,
  reportPrayerRequests,
  reportPrivateFiles,
  reportPublicFiles,
  reportQuarter,
  reportSubmittedBy,
} from '../common/ReportItems';
import { YearlyQuarters } from '../quarters';

const { Title, Text } = Typography;

export const TrainingQuarterlyReportQuestions = {
  title: {
    Display: function Display({ report }) {
      const title = useMemo(() => {
        let _title = 'Training Quarterly Report';
        if (report) {
          const thisYear = new Date().getFullYear();
          const [year, qx] =
            report && report.quarter
              ? report.quarter.split('.')
              : [thisYear, 'Q1'];
          const quarterLabel = `${YearlyQuarters[qx].label} ${year}`;
          _title = (
            <>
              <span style={{ fontSize: 24, lineHeight: 1.5 }}>
                Training Quarterly Report
              </span>
              <br />
              <span>{quarterLabel}</span>
            </>
          );
        }
        return _title;
      }, [report]);
      return (
        <Title style={{ textAlign: 'center', marginBottom: 32 }}>{title}</Title>
      );
    },
  },
  submittedBy: reportSubmittedBy,
  approvedBy: reportApprovedBy,
  quarter: reportQuarter,
  events: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item label="Training Events">
          <div style={{ height: 8 }} />
          <Form.List name="events">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} noStyle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        border: `1px solid ${sharedSettings.colors.gray}`,
                        boxShadow: `0px 0px 8px ${sharedSettings.colors.borderGray}`,
                        padding: 16,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            label="Event Date"
                            name={[field.name, 'date']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <DatePicker
                              disabled={loading}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Number of people trained"
                            name={[field.name, 'numPeopleTrained']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <InputNumber
                              disabled={loading}
                              min={0}
                              step={1}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="City"
                            name={[field.name, 'city']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="Country"
                            name={[field.name, 'country']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <Form.Item
                          label="Hosting church or ministry"
                          name={[field.name, 'host']}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                        >
                          <Input disabled={loading} />
                        </Form.Item>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={loading}
                  >
                    Add Training Event
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    finish: (values) => {
      return {
        events: values.events
          ? values.events.map((c) => {
              return {
                _id: c._id || generateUuid(),
                city: c.city,
                country: c.country,
                host: c.host,
                date: c.date,
                numPeopleTrained: c.numPeopleTrained,
              };
            })
          : [],
      };
    },
    before: (report) => {
      return {
        events: (report.events || []).map((e) => ({
          ...e,
          date: e.date ? moment(e.date) : undefined,
        })),
      };
    },
    Display: function Display({ report }) {
      const locale = useSelector((store) => store.locale, shallowEqual);
      return (
        <>
          <Title level={2} style={{ marginTop: 32 }}>
            Training Events
          </Title>
          {report.events &&
            report.events.map((event, index) => {
              return (
                <div key={index} style={{ marginBottom: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                      borderRadius: 8,
                      padding: 12,
                      fontSize: 14,
                      backgroundColor: sharedSettings.colors.borderGray,
                    }}
                  >
                    <Text strong>
                      {new Intl.DateTimeFormat(locale, {
                        dateStyle: 'full',
                      }).format(new Date(event.date))}
                    </Text>
                    <br />
                    {`People trained: ${event.numPeopleTrained}`}
                    <br />
                    {`Host: ${event.host}`}
                    <br />
                    {`${event.city}, ${event.country}`}
                  </div>
                </div>
              );
            })}
          {(!report.events || !report.events.length) && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No events"
            />
          )}
        </>
      );
    },
  },
  miracles: reportMiracles,
  prayerRequests: reportPrayerRequests,
  publicFiles: {
    ...reportPublicFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'TRAIN_QUARTERLY',
      });
    },
  },
  privateFiles: {
    ...reportPrivateFiles,
    FormItem: function FormItem(props) {
      return reportPublicFiles.FormItem({
        ...props,
        reportType: 'TRAIN_QUARTERLY',
      });
    },
  },
  notes: reportNotes,
};
