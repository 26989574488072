import { Button, Checkbox, Form, Typography } from 'antd';
import React, { useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactCorrespondenceInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        shouldReceiveEmail: contact.shouldReceiveEmail,
        shouldReceivePhysicalMail: contact.shouldReceivePhysicalMail,
      });
    }
  }, [contact, form]);
  return (
    <ContactSomethingForm
      title="Change Correspondence Settings"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="shouldReceiveEmail"
        valuePropName="checked"
        style={{ marginBottom: 0 }}
      >
        <Checkbox>Should receive email</Checkbox>
      </Form.Item>
      <Form.Item name="shouldReceivePhysicalMail" valuePropName="checked">
        <Checkbox>Should receive physical mail</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactCorrespondenceInnerModalA10;
