import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { BankOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Steps, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import MoveProjectStageModal from './MoveProjectStageModal';
import ProjectChecklist from './ProjectChecklist';
import ProjectCatalogStatus from '../AccountingTab/ProjectCatalogStatus';
import ProjectFundingStatus from '../AccountingTab/ProjectFundingStatus';
import { Link } from 'react-router-dom';
import useProjectChecklistItems from './use-project-checklist-items';
import InitiateLoanSection from './InitiateLoanSection';
import ProjectPaybackStatus from '../AccountingTab/ProjectPaybackStatus';
import ProjectReportStatus from '../PaymentPlanTab/ProjectReportStatus';
import ChangeStageDropdown from './ChangeStageDropdown';
import ProjectDebriefSection from './ProjectDebriefSection';

const CompletableSteps = [
  {
    ...HolisticProjectStages.PREPARING,
  },
  {
    ...HolisticProjectStages.FUNDING,
  },
  {
    ...HolisticProjectStages.FUNDED,
  },
  {
    ...HolisticProjectStages.ACTIVE,
  },
  {
    ...HolisticProjectStages.COMPLETE,
  },
];

const FailedSteps = [
  {
    ...HolisticProjectStages.PREPARING,
  },
  {
    ...HolisticProjectStages.FUNDING,
  },
  {
    ...HolisticProjectStages.FUNDED,
  },
  {
    ...HolisticProjectStages.ACTIVE,
  },
  {
    ...HolisticProjectStages.FAILED,
  },
];

const { Title } = Typography;

function HolisticStatusTab({ project }) {
  const [current, steps, next, prev] = useMemo(() => {
    let _steps;
    if (project.stage === HolisticProjectStages.FAILED.key) {
      _steps = FailedSteps;
    } else {
      _steps = CompletableSteps;
    }
    const c = _steps.findIndex((step) => step.key === project.stage);
    let _prev;
    if (c > 0) {
      _prev = _steps[c - 1];
    }
    let _next;
    if (c < CompletableSteps.length - 1) {
      _next = _steps[c + 1];
    }
    return [c, _steps, _next, _prev];
  }, [project]);

  const [moveTo, setMoveTo] = useState();
  const onMoveTo = (stage) => {
    setMoveTo({ stage });
  };
  const onFinishMoveTo = () => setMoveTo(undefined);
  const onCancelMoveTo = () => setMoveTo(undefined);

  const { items, allDone } = useProjectChecklistItems(project);

  return (
    <>
      <Steps current={current} style={{ marginBottom: 16 }}>
        {steps.map((step) => (
          <Steps.Step
            key={step.key}
            title={step.label}
            description={step.desc}
            icon={step.icon}
          />
        ))}
      </Steps>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        {prev && (
          <Button
            icon={prev.icon || <LeftOutlined />}
            type="dashed"
            onClick={() => onMoveTo(prev)}
          >{`Move to ${prev.label} stage`}</Button>
        )}
        <div style={{ flex: 1 }} />
        {next && (
          <Button
            type="primary"
            disabled={!allDone}
            icon={next.icon || <RightOutlined />}
            onClick={() => onMoveTo(next)}
            style={{ marginRight: 8 }}
          >{`Move to ${next.label} stage`}</Button>
        )}
        <ChangeStageDropdown project={project} />
      </div>
      <Title level={2}>
        {steps[current]?.icon} {`${steps[current]?.label}`}
      </Title>
      <ProjectChecklist
        project={project}
        onMoveTo={onMoveTo}
        next={next}
        items={items}
        allDone={allDone}
      />
      <ProjectDebriefSection project={project} />
      <ProjectCatalogStatus project={project} />
      <ProjectFundingStatus project={project} />
      <ProjectPaybackStatus project={project} />
      <div style={{ height: 32 }} />
      <InitiateLoanSection project={project} />
      {/* <CumulativeProjectStats holisticProject={project} /> */}
      <ProjectReportStatus project={project} />
      <MoveProjectStageModal
        project={project}
        visible={moveTo}
        onCancel={onCancelMoveTo}
        onFinish={onFinishMoveTo}
      />
    </>
  );
}

export default HolisticStatusTab;
