import { passwordStrength } from 'check-password-strength';

/**
 * @type {import('check-password-strength').DiversityType[]}
 */
const requirements = ['lowercase', 'uppercase', 'number', 'symbol'];

const errorMsgTmpl = 'Weak password, ';

const requirementHumanReadable = {
  lowercase:
    'must contain minimum of 10 characters, at least 1 uppercase letter, 1 number, and 1 symbol or special character.',
  uppercase:
    'must contain minimum of 10 characters, at least 1 uppercase letter, 1 number, and 1 symbol or special character.',
  symbol:
    'must contain minimum of 10 characters, at least 1 uppercase letter, 1 number, and 1 symbol or special character.',
  number:
    'must contain minimum of 10 characters, at least 1 uppercase letter, 1 number, and 1 symbol or special character.',
};

/**
 * @param {string} value
 */
export default (value) => {
  const result = passwordStrength(value);
  const missing = requirements.filter((req) => !result.contains.includes(req));
  let errorMsg = null;
  if (result.id < 3) {
    errorMsg = errorMsgTmpl;
    if (missing.length) {
      errorMsg += requirementHumanReadable[missing[0]];
    } else {
      errorMsg += requirementHumanReadable['lowercase'];
    }
  }
  return { ...result, missing, errorMsg };
};
