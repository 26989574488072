import { ReportTypes } from '@aims/shared/reports/constants';
import { useMemo } from 'react';

function usePrayerReportLink(prayerRequest) {
  const link = useMemo(() => {
    if (prayerRequest.reportId && prayerRequest.reportType) {
      if (prayerRequest.reportType === ReportTypes.PG_QUARTERLY.key) {
        return `/upg-quarterly-reports/review/${prayerRequest.reportId}?from=${location.pathname}`;
      }
      if (prayerRequest.reportType === ReportTypes.HOLISTIC_PROGRESS.key) {
        return `/holistic-quarterly-reports/review/${prayerRequest.reportId}?from=${location.pathname}`;
      }
      if (prayerRequest.reportType === ReportTypes.TRAIN_QUARTERLY.key) {
        return `/training-quarterly-reports/review/${prayerRequest.reportId}?from=${location.pathname}`;
      }
    }
    return null;
  }, [prayerRequest]);
  return link;
}

export default usePrayerReportLink;
