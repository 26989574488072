import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip, Upload, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useCallback, useRef, useState } from 'react';
import {
  getAttachmentUploadUrlForAdminMutation,
  saveAttachmentForAdminMutation,
} from '../../../components/AnyAttachments/constants';
import { doFileUpload, generateUuid, toBase64 } from '../../../shared/utils';
import { updatePeopleGroupPhotosMutation } from '../constants';

function UploadPhotoButton({ peopleGroup, queryId, refetch }) {
  const _fileList = useRef([]);
  const [fileList, _setFileList] = useState(_fileList.current);
  const setFileList = useCallback((value) => {
    _fileList.current = value;
    _setFileList(value);
  }, []);
  const [uploadLoading, setUploadLoading] = useState(false);
  const _uploadProgress = useRef({});
  const [uploadProgress, _setUploadProgress] = useState(
    _uploadProgress.current,
  );
  const setUploadProgress = useCallback((value) => {
    _uploadProgress.current = value;
    _setUploadProgress(value);
  }, []);
  const [error, setError] = useState(null);
  const [getAttachmentUploadUrlForAdmin] = useMutation(
    getAttachmentUploadUrlForAdminMutation,
  );
  const [updatePeopleGroup] = useMutation(updatePeopleGroupPhotosMutation);
  const [saveAttachmentForAdmin] = useMutation(saveAttachmentForAdminMutation);

  const doUpload = useCallback(
    async (obj) => {
      try {
        setError(null);
        setUploadLoading(true);
        const photoId = generateUuid();
        const s3Key = `people-group:${peopleGroup._id}:photo:${photoId}`;

        let base64Url;
        if (obj.file.type.startsWith('image')) {
          base64Url = await toBase64(obj.file);
        }
        setFileList([
          ..._fileList.current,
          {
            _id: photoId,
            s3Key,
            size: obj.file.size,
            filename: obj.file.name,
            contentType: obj.file.type,
            base64Url,
          },
        ]);

        let response;
        response = await getAttachmentUploadUrlForAdmin({
          variables: {
            attachment: {
              _id: photoId,
              s3Key,
            },
          },
        });
        if (
          !response ||
          !response.data ||
          !response.data.getAttachmentUploadUrlForAdmin
        ) {
          throw new Error('Bad upload URL');
        }

        setUploadProgress(0);
        await doFileUpload({
          url: response.data.getAttachmentUploadUrlForAdmin.url,
          fields: response.data.getAttachmentUploadUrlForAdmin.fields,
          file: obj.file,
          onProgress: (event) => {
            setUploadProgress(Math.round((event.loaded / event.total) * 100));
          },
        });
        setTimeout(() => {
          setUploadProgress(undefined);
        }, 1000);

        response = await saveAttachmentForAdmin({
          variables: {
            attachment: {
              _id: photoId,
              filename: obj.file.name,
              s3Key,
              attachedTo: peopleGroup._id,
              tags: ['peopleGroupPhoto'],
              uploaded: true,
            },
          },
        });

        if (
          !response ||
          !response.data ||
          !response.data.saveAttachmentForAdmin
        ) {
          throw new Error('Failed to save photo');
        }

        await updatePeopleGroup({
          variables: {
            peopleGroup: {
              _id: peopleGroup._id,
              photoIds: [...(peopleGroup.photoIds || []), photoId].sort(),
            },
          },
        });
        refetch();

        notification.success({
          message: 'Saved',
          description: 'Photo uploaded successfully',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your people group.',
        });
      }
      setUploadLoading(false);
    },
    [
      saveAttachmentForAdmin,
      updatePeopleGroup,
      getAttachmentUploadUrlForAdmin,
      peopleGroup,
      setUploadProgress,
      setFileList,
      refetch,
    ],
  );
  return (
    <ImgCrop>
      <Upload
        className="profile-pic-upload"
        showUploadList={false}
        listType="picture"
        customRequest={doUpload}
      >
        {error && (
          <Tooltip title={error}>
            <Button danger icon={<ExclamationCircleOutlined />}>
              Failed
            </Button>
          </Tooltip>
        )}
        {!error && (
          <Button
            disabled={!peopleGroup}
            loading={uploadLoading}
            icon={<PlusOutlined />}
          >
            {uploadLoading
              ? uploadProgress !== undefined
                ? `Uploading ... ${uploadProgress} %`
                : 'Saving ...'
              : 'Upload Photo'}
          </Button>
        )}
      </Upload>
    </ImgCrop>
  );
}

export default UploadPhotoButton;
