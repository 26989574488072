import { FileTypes } from '@aims/shared/files/constants';
import { useMutation } from '@apollo/client';
import { Empty, List, Typography, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { updateHolisticProjectPhotosMutation } from '../../../constants';
import DeletePhotoModal from './DeletePhotoModal';
import HpPhotoCard from './HpPhotoCard';
import UploadPhotoButton from './UploadPhotoButton';
import useHolisticPhotos from './use-holistic-photos';

const { Text } = Typography;

function HolisticProjectPhotos({
  project,
  isSummary,
  fileType = FileTypes.H_PHOTO.key,
  aspect = 1,
  singular = 'Photo',
  plural = 'Photos',
  primaryName = 'primaryPhotoId',
}) {
  const [deleting, setDeleting] = useState(null);

  const { loading, data: rawPhotos } = useHolisticPhotos({
    filters: { type: fileType, attachedTo: project._id },
    skip: !project._id,
    projectId: project._id,
  });

  const photos = useMemo(() => {
    if (rawPhotos) {
      const _photos = [];
      rawPhotos.forEach((p) => {
        const photo = p.images && p.images.find((i) => i.sizeCode === 'SM');
        let url = photo && photo.url;
        if (!url) {
          url = p && p.thumbnail && p.thumbnail.url;
        }
        if (!url) {
          url = p && p.url;
        }
        _photos.push({
          _id: p._id,
          isPrimary: project[primaryName] === p._id,
          photoUrl: url,
          name: project.projectName,
        });
      });
      if (isSummary) {
        return _photos.slice(0, 4);
      }
      return _photos;
    }
    return [];
  }, [project, isSummary, rawPhotos, primaryName]);

  const [updateHolisticProject] = useMutation(
    updateHolisticProjectPhotosMutation,
  );
  const [savingPrimary, setSavingPrimary] = useState({});

  const handleMakePrimary = useCallback(
    async (photoId) => {
      setSavingPrimary({ ...loading, [photoId]: true });
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              [primaryName]: photoId,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: `Primary saved successfully`,
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: `There was an error saving primary`,
        });
      }
      setSavingPrimary({ ...loading, [photoId]: false });
    },
    [updateHolisticProject, loading, project, primaryName],
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginRight: -8,
          marginLeft: -8,
        }}
      >
        {photos.map((photo) => (
          <HpPhotoCard
            key={photo._id}
            photo={photo}
            makePrimary={handleMakePrimary}
            loading={savingPrimary[photo._id]}
            setDeleting={setDeleting}
            holisticProject={project}
            aspect={aspect}
          />
        ))}
      </div>
      {photos.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`No ${plural}`}
        />
      )}
      {!isSummary && (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <UploadPhotoButton
              holisticProject={project}
              fileType={fileType}
              title={`Upload ${singular}`}
              aspect={aspect}
            />
          </div>
        </>
      )}
      <DeletePhotoModal
        setPhoto={setDeleting}
        photo={deleting}
        singular={singular}
      />
    </div>
  );
}

export default HolisticProjectPhotos;
