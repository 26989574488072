import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';

const { Paragraph } = Typography;

const deleteHolisticProjectMutation = gql`
  mutation deleteHolisticProject($_id: ID!) {
    deleteHolisticProject(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteHolisticProjectModal({ visible, onCancel, onFinish }) {
  const { holisticProject } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteHolisticProject] = useMutation(deleteHolisticProjectMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteHolisticProject({
        variables: {
          _id: holisticProject._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Holistic project deleted successfully',
      });
      onFinish();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error deleting your holistic project',
      });
    }
    setLoading(false);
  }, [holisticProject, deleteHolisticProject, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Holistic Project"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>
        Are you sure you want to delete this holistic project?
      </Paragraph>
      <Paragraph style={{ fontWeight: 600 }}>
        {holisticProject?.projectName}
      </Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteHolisticProjectModal;
