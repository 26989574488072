export const READ_HISTORY_V2 = 'READ_HISTORY_V2';
export const CREATE_HISTORY_V2 = 'CREATE_HISTORY_V2';
export const UPDATE_HISTORY_V2 = 'UPDATE_HISTORY_V2';
export const DELETE_HISTORY_V2 = 'DELETE_HISTORY_V2';

export const readHistoryV2Action = (
  // eslint-disable-next-line no-shadow
  history,
  queryId = 'default',
) => ({
  type: READ_HISTORY_V2,
  payload: {
    history,
    queryId,
  },
});

export const createHistoryV2Action = (history, queryId = 'default') => ({
  type: CREATE_HISTORY_V2,
  payload: {
    history,
    queryId,
  },
});

export const updateHistoryV2Action = (history, queryId = 'default') => ({
  type: UPDATE_HISTORY_V2,
  payload: {
    history,
    queryId,
  },
});

export const deleteHistoryV2Action = (historyId, queryId = 'default') => ({
  type: DELETE_HISTORY_V2,
  payload: {
    historyId,
    queryId,
  },
});

const initialHistoryV2 = { default: {} };

export function historyV2(state = initialHistoryV2, action) {
  switch (action.type) {
    case READ_HISTORY_V2: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.history.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_HISTORY_V2:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.history._id]: action.payload.history,
        },
      };
    case UPDATE_HISTORY_V2: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.history._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.history._id]: {
            ...current,
            ...action.payload.history,
          },
        },
      };
    }
    case DELETE_HISTORY_V2: {
      const { historyId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [historyId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
