import { ArrowLeftOutlined } from '@ant-design/icons';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button, Form, Input, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import SelectContact from '../../contacts/SelectContact';
import TagsFormItem from '../../tags/TagsFormItem';
import { createAdoptionMutation } from '../constants';
import SelectAdoptionLevelA10 from './SelectAdoptionLevelA10';
import SelectAdoptionStatus from './SelectAdoptionStatusA10';
import SelectFromContactPeopleGroupsA10 from './SelectFromContactPeopleGroupsA10';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Text } = Typography;

export const createAdoptionUpgQuery = gql`
  query CreateAdoptionUpg($_id: ID!) {
    peopleGroupForAdmin(_id: $_id) {
      _id
      regionCode
    }
  }
`;

function CreateAdoptionPageA10() {
  const { _id } = useParams();
  const [form] = Form.useForm();
  const [saving, setSaving] = useState();
  const [createAdoption] = useMutation(createAdoptionMutation);
  const client = useApolloClient();
  const history = useHistory();

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { peopleGroupId, ...values } = _values;
        const resp = await client.query({
          query: createAdoptionUpgQuery,
          variables: {
            _id: peopleGroupId,
          },
        });
        const regionCode = resp?.data?.peopleGroupForAdmin?.regionCode;
        await createAdoption({
          variables: {
            adoption: {
              _id,
              peopleGroupId,
              regionCode,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Sponsorship created successfully',
        });
        history.push('/adoptions');
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error creating a sponsorship.',
        });
      }
      setSaving(false);
    },
    [createAdoption, history, client, _id],
  );
  return (
    <CSPage title="Create a Sponsorship" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="Create a Sponsorship"
        topActions={[
          <Link key="org" to={`/adoptions`}>
            <Button
              key="add"
              type="link"
              icon={<ArrowLeftOutlined />}
              size="large"
            >
              Back to UPG Sponsorships
            </Button>
          </Link>,
        ]}
      />
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="level"
          label="Sponsorship Level"
          rules={[{ required: true, message: 'Please select a level' }]}
        >
          <SelectAdoptionLevelA10 placeholder="Select ..." disabled={saving} />
        </Form.Item>
        <Form.Item
          label="Monthly Amount (USD)"
          name="amount"
          initialValue={0}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DollarX4InputNumber disabled={saving} />
        </Form.Item>
        <Form.Item
          name="regionalCoordId"
          label="Regional Coordinator"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectContact
            placeholder="Select ..."
            disabled={saving}
            filters={{
              isRegionalCoord: true,
            }}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.regionalCoordId !== curValues.regionalCoordId
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('regionalCoordId') && (
              <Form.Item
                name="fieldWorkerId"
                label="Field Worker"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectContact
                  placeholder="Select ..."
                  disabled={saving}
                  filters={{
                    isFieldWorker: true,
                    regionalCoordId: getFieldValue('regionalCoordId'),
                  }}
                />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.fieldWorkerId !== curValues.fieldWorkerId
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('fieldWorkerId') && (
              <Form.Item
                label="People Group"
                name="peopleGroupId"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectFromContactPeopleGroupsA10
                  contactId={getFieldValue('fieldWorkerId')}
                />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          name="status"
          label="Sponsorship Status"
          rules={[{ required: true, message: 'Please select a status' }]}
        >
          <SelectAdoptionStatus placeholder="Select ..." disabled={saving} />
        </Form.Item>
        <TagsFormItem name="tags" label="Tags" />
        <Form.Item label="Notes" name="notes">
          <Input.TextArea disabled={saving} rows={4} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={saving}>
              Save Sponsorship
            </Button>
          </div>
        </Form.Item>
      </Form>
    </CSPage>
  );
}

export default CreateAdoptionPageA10;
