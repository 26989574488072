import sharedSettings from '@aims/shared/sharedSettings';
import { gql, useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import useProfile from '../../shared/use-profile';

export const subscribeToMutation = gql`
  mutation SubscribeToTask($taskId: ID!) {
    subscribeToTask(taskId: $taskId)
  }
`;

export const unsubscribeFromMutation = gql`
  mutation UnsubscribeFromTask($taskId: ID!) {
    unsubscribeFromTask(taskId: $taskId)
  }
`;

function SubscribeToTaskButton({ taskId }) {
  const [subscribeTo] = useMutation(subscribeToMutation);
  const [unsubscribeFrom] = useMutation(unsubscribeFromMutation);
  const profile = useProfile();
  const [loading, setLoading] = useState(false);

  const subscribed = useMemo(() => {
    if (profile.subscribedTo) {
      return profile.subscribedTo.includes(`task:${taskId}`);
    }
  }, [profile, taskId]);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      if (subscribed) {
        await unsubscribeFrom({
          variables: {
            taskId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Unsubscribed',
        });
      } else {
        await subscribeTo({
          variables: {
            taskId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Subscribed',
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'There was an error subscribing to task',
      });
    }
    setLoading(false);
  }, [taskId, unsubscribeFrom, subscribeTo, subscribed]);

  return (
    <Button
      type="text"
      onClick={handleClick}
      style={{ color: sharedSettings.colors.textGray }}
      loading={loading}
    >
      {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  );
}

export default SubscribeToTaskButton;
