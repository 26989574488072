import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Paragraph } = Typography;

const deleteContactMutation = gql`
  mutation deleteContact($_id: ID!) {
    deleteContact(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteContactModalA14({ visible, onCancel, onFinish }) {
  const contact = visible;
  const [loading, setLoading] = useState(false);
  const [deleteContact] = useMutation(deleteContactMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteContact({
        variables: {
          _id: contact._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Contact deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [contact, deleteContact, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Contact"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>Are you sure you want to delete this contact?</Paragraph>
      <Paragraph style={{ fontWeight: 600 }}>{contact?.fullName}</Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteContactModalA14;
