import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import React, { useRef, useState } from 'react';
import settings from '../../../settings';
import TransfersTabInner from './TransfersTabInner';
import { allTransfersQuery, extractAllTransfers } from './constants';

const queryId = 'transactionsTab';

function TransfersTab({ filters: parentFilters, hideActions }) {
  const [sortedBy, setSortedBy] = useState('originalDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    ...parentFilters,
  });

  const {
    error,
    loading,
    data: transfers,
    search: transferSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTransfersQuery,
    extract: extractAllTransfers,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  return (
    <TransfersTabInner
      parentFilters={parentFilters}
      hideActions={hideActions}
      refetch={refetch}
      filters={filters}
      transferSearch={transferSearch}
      sortBy={sortBy}
      sortedBy={sortedBy}
      sortOrder={sortOrder}
      transfers={transfers}
      loading={loading}
      hasNextPage={hasNextPage}
      next={next}
    />
  );
}

export default TransfersTab;
