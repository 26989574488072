import React from 'react';
import useProfile from '../../shared/use-profile';
import AnyTasks from '../tasks/AnyTasks';

const UserTasks = () => {
  const profile = useProfile();

  const currentUserId = profile._id;

  return (
    <AnyTasks
      filters={{ assigneeId: currentUserId }}
      fieldsToSet={{ assigneeId: currentUserId }}
      title={'My Current Tasks'}
      subtitle="The tasks assigned to you."
      hideAdd={true}
      hideDone={true}
    />
  );
};

export default UserTasks;
