import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import { Form, Input, InputNumber, Radio, Typography } from 'antd';
import React from 'react';
import SimpleSelectStringTagsA10 from '../../../../components/SimpleSelectStringTagsA10';
import SelectContact from '../../../contacts/SelectContact';
import SelectRegionForAdmin from '../../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import SelectFund from '../../../pushpay/funds/SelectFund';
import { AccountTypesJ25 } from '../constants';
import SelectAccountType from './SelectAccountType';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';

const { Text } = Typography;

function EditAccountFormItems({ account, saving }) {
  return (
    <>
      <Form.Item
        label="Account Name"
        name="name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item label="Tags" name="tags">
        <SimpleSelectStringTagsA10 disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Internal or External"
        name="external"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Radio.Group disabled={saving || account} buttonStyle="solid">
          <Radio.Button value={false}>Internal</Radio.Button>
          <Radio.Button value={true}>External</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.external !== curValues.external
        }
        noStyle
      >
        {({ getFieldValue }) => (
          <>
            <Form.Item
              label="Account Type"
              name="type"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <SelectAccountType
                disabled={saving || account}
                filterFunc={(a) => a.external === getFieldValue('external')}
              />
            </Form.Item>
            {getFieldValue('external') === false && (
              <Form.Item
                label="Fund"
                name="fundId"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectFund disabled={saving || account} />
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type
        }
        noStyle
      >
        {({ getFieldValue }) => (
          <Form.Item
            label="Region"
            name="regionCode"
            rules={[
              {
                required: [
                  AccountTypesJ25.REGIONAL_COORD.key,
                  AccountTypesJ25.TRAINING.key,
                  AccountTypesJ25.HOLISTIC.key,
                  AccountTypesJ25.EX_RC.key,
                  AccountTypesJ25.EX_TRAINING.key,
                ].includes(getFieldValue('type')),
                message: 'This field is required',
              },
            ]}
          >
            <SelectRegionForAdmin disabled={saving || account} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type
        }
        noStyle
      >
        {({ getFieldValue }) =>
          [AccountTypesJ25.FIELD_WORKER.key, AccountTypesJ25.EX_FW].includes(
            getFieldValue('type'),
          ) && (
            <SelectPeopleGroup loading={saving} disabled={saving || account} />
          )
        }
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type ||
          prevValues.peopleGroupId !== curValues.peopleGroupId
        }
        noStyle
      >
        {({ getFieldValue }) =>
          [
            AccountTypesJ25.FIELD_WORKER.key,
            AccountTypesJ25.EX_FW.key,
          ].includes(getFieldValue('type')) &&
          !!getFieldValue('peopleGroupId') && (
            <Form.Item
              label="Field Worker"
              name="fieldWorkerId"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <SelectContact
                loading={saving}
                disabled={saving || account}
                filters={{
                  peopleGroupId: getFieldValue('peopleGroupId'),
                  isFieldWorker: true,
                }}
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.type !== curValues.type ||
          prevValues.regionCode !== curValues.regionCode
        }
        noStyle
      >
        {({ getFieldValue }) =>
          [
            AccountTypesJ25.REGIONAL_COORD.key,
            AccountTypesJ25.EX_RC.key,
          ].includes(getFieldValue('type')) &&
          !!getFieldValue('regionCode') && (
            <Form.Item
              label="Regional Coordinator"
              name="regionalCoordId"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <SelectContact
                loading={saving}
                disabled={saving || account}
                filters={{
                  regionCode: getFieldValue('regionCode'),
                  isRegionalCoord: true,
                }}
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item
        label="Starting Balance (USD)"
        name="startingBalance"
        initialValue={0}
      >
        <DollarX4InputNumber disabled={saving || account} />
      </Form.Item>
    </>
  );
}

export default EditAccountFormItems;
