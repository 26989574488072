import React, { useRef } from 'react';
import PrayerRequestListJ15 from '../../prayer-requests/PrayerRequestListJ15';

function PeopleGroupPrayerRequests({ peopleGroupId }) {
  const filtersRef = useRef({ peopleGroupId });
  return (
    <PrayerRequestListJ15 filtersRef={filtersRef} queryId={peopleGroupId} />
  );
}

export default PeopleGroupPrayerRequests;
