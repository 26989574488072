import { generateUuid } from '@aims/shared/shared/utils';
import { Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import EditTrainingFileForm from './EditTrainingFileForm';

const TrainingFileModal = ({ visible, onCancel, onFinish }) => {
  const { trainingFile, fieldsToSet = {} } = visible || {};

  const [loading, setLoading] = useState();

  const title = useMemo(() => {
    return trainingFile ? 'Edit Training File' : 'Add a Training File';
  }, [trainingFile]);

  const _id = useMemo(() => {
    return visible?.trainingFile ? visible?.trainingFile._id : generateUuid();
  }, [visible]);

  return (
    <>
      <Modal
        title={title}
        open={visible}
        destroyOnClose={true}
        closable={!loading}
        maskClosable
        onCancel={onCancel}
        footer={null}
      >
        <div style={{ textAlign: 'left' }}>
          {visible && (
            <EditTrainingFileForm
              _id={_id}
              handleCancel={onCancel}
              handleSuccess={onFinish}
              trainingFile={trainingFile}
              loading={loading}
              setLoading={setLoading}
              fieldsToSet={fieldsToSet}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default TrainingFileModal;
