import sharedSettings from '@aims/shared/sharedSettings';
import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../../../components/PhotoCircle';

const { Text } = Typography;

const ConnectedContact = ({ contact, role }) => {
  if (contact) {
    return (
      <Link to={`/contact/view/${contact._id}/dashboard`}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}
        >
          <PhotoCircle
            url={contact.primaryPhotoUrl}
            name={contact.fullName}
            size="sm"
            style={{
              height: 36,
              width: 36,
              padding: 'unset',
              marginRight: 8,
            }}
          />
          <div>
            <Text style={{ color: sharedSettings.colors.primary }}>
              {role === undefined
                ? contact.fullName
                : `${contact.fullName} - ${role}`}
            </Text>
          </div>
        </div>
      </Link>
    );
  }
  return null;
};

export default ConnectedContact;
