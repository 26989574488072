import { gql } from '@apollo/client';

export const allPrayerRequestsForAdminQuery = gql`
  query AllPrayerRequestsForAdmin(
    $first: Int
    $after: String
    $filters: PrayerRequestFilters
  ) {
    allPrayerRequestsForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          desc
          miracleIds
          reportId
          reportType
          peopleGroupId
          peopleGroup {
            _id
            country
            regionCode
            nameAcrossCountries
            primaryThumbnailUrl
          }
          holisticProjectId
          holisticProject {
            _id
            projectName
            typeOfProject
            peopleGroupIds
          }
          result
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const extractPrayerRequestsForAdmin = (data) =>
  data.allPrayerRequestsForAdmin;
