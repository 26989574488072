import sharedSettings from '@aims/shared/sharedSettings';
import {
  EditOutlined,
  GlobalOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Skeleton, Tag, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DeleteThingDropdown from '../../../components/DeleteThingDropdown';
import PhotoCircle from '../../../components/PhotoCircle';
import AccountUpdateModal from './AccountUpdateModal';
import { AccountTypesJ25 } from './constants';
import DeleteAccountModal from './DeleteAccountModal';

const { Title, Text } = Typography;

const lineStyle = { marginBottom: 8 };

function AccountTitle({ account }) {
  const photoUrl = useMemo(() => {
    if (
      account &&
      account.fieldWorker &&
      account.fieldWorker.primaryThumbnailUrl
    ) {
      return account.fieldWorker.primaryThumbnailUrl;
    }
    if (
      account &&
      account.regionalCoord &&
      account.regionalCoord.primaryThumbnailUrl
    ) {
      return account.regionalCoord.primaryThumbnailUrl;
    }
    if (
      account &&
      account.peopleGroup &&
      account.peopleGroup.primaryThumbnailUrl
    ) {
      return account.peopleGroup.primaryThumbnailUrl;
    }
    return undefined;
  }, [account]);

  const fieldWorkerName = useMemo(() => {
    if (
      account &&
      [AccountTypesJ25.FIELD_WORKER.key, AccountTypesJ25.EX_FW.key].includes(
        account.type,
      ) &&
      account.fieldWorker
    ) {
      return account.fieldWorker.name;
    }
    return undefined;
  }, [account]);

  const regionalCoordName = useMemo(() => {
    if (
      account &&
      [AccountTypesJ25.REGIONAL_COORD.key, AccountTypesJ25.EX_RC.key].includes(
        account.type,
      ) &&
      account.regionalCoord
    ) {
      return account.regionalCoord.name;
    }
    return undefined;
  }, [account]);

  const adopterName = useMemo(() => {
    if (
      account &&
      [AccountTypesJ25.EX_ADOPTER.key].includes(account.type) &&
      account.adopter
    ) {
      return account.adopter.name;
    }
    return undefined;
  }, [account]);

  const regionName = useMemo(() => {
    return account && account.regionName;
  }, [account]);

  const pgName = useMemo(() => {
    if (
      account &&
      [AccountTypesJ25.FIELD_WORKER.key, AccountTypesJ25.EX_FW.key].includes(
        account.type,
      ) &&
      account.peopleGroup
    ) {
      return `${account.peopleGroup.nameAcrossCountries} / ${account.peopleGroup.country} (${account.peopleGroup._id})`;
    }
    return undefined;
  }, [account]);

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const onDelete = () => setDeleting(account);
  const onCancelDelete = () => setDeleting();
  const onFinishDelete = () => {
    setDeleting(undefined);
    history.push('/accounting/accounts');
  };

  const [editing, setEditing] = useState();
  const onEdit = () => setEditing({ account });
  const onCancelEdit = () => setEditing();
  const onFinishEdit = () => {
    setEditing(undefined);
  };

  return (
    <div>
      {account ? (
        <div style={{ display: 'flex', position: 'relative' }}>
          <PhotoCircle
            photoUrl={photoUrl}
            name={account.name}
            size="lg"
            customSize={148}
            style={{ padding: 0, height: 148, width: 148, marginRight: 24 }}
          />
          <div>
            <Title level={2} style={{ marginBottom: 0 }}>
              {account.name}
            </Title>
            <div style={lineStyle}>
              <Text
                style={{ fontSize: 16, color: sharedSettings.colors.textGray }}
              >
                {account.fund
                  ? `${account.fund.name} - ${
                      AccountTypesJ25[account.type].label
                    } Account`
                  : `${AccountTypesJ25[account.type].label} Account`}
              </Text>
            </div>

            {regionName && (
              <div style={lineStyle}>
                <Text
                  style={{ maxWidth: 300 }}
                  ellipsis={{ tooltip: regionName }}
                >
                  <GlobalOutlined style={{ marginRight: 8 }} />
                  {regionName}
                </Text>
              </div>
            )}
            {pgName && (
              <div style={lineStyle}>
                <Text style={{ maxWidth: 300 }} ellipsis={{ tooltip: pgName }}>
                  <TranslationOutlined style={{ marginRight: 8 }} />
                  {pgName}
                </Text>
              </div>
            )}
            {fieldWorkerName && (
              <div style={lineStyle}>
                <Text
                  style={{ maxWidth: 300 }}
                  ellipsis={{ tooltip: fieldWorkerName }}
                >
                  <UserOutlined style={{ marginRight: 8 }} />
                  {fieldWorkerName}
                </Text>
              </div>
            )}
            {adopterName && (
              <div style={lineStyle}>
                <Text
                  style={{ maxWidth: 300 }}
                  ellipsis={{ tooltip: adopterName }}
                >
                  <UserOutlined style={{ marginRight: 8 }} />
                  {adopterName}
                </Text>
              </div>
            )}
            {regionalCoordName && (
              <div style={lineStyle}>
                <Text
                  style={{ maxWidth: 300 }}
                  ellipsis={{ tooltip: regionalCoordName }}
                >
                  <UserOutlined style={{ marginRight: 8 }} />
                  {regionalCoordName}
                </Text>
              </div>
            )}
            {account.holisticProject && (
              <div style={lineStyle}>
                <Link
                  to={`/holistic-project/view/${account.holisticProject._id}/status`}
                >
                  {`View ${account.holisticProject.projectName} Project Page`}
                </Link>
              </div>
            )}
            {account.tags && (
              <div style={lineStyle}>
                {account.tags.map((tag, i) => (
                  <Tag
                    style={{ marginBottom: 4, marginRight: 4 }}
                    key={i}
                    color={sharedSettings.colors.primary}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            )}
          </div>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <DeleteThingDropdown
              thing={account}
              label="Delete Account"
              handleDelete={onDelete}
              moreItems={[
                {
                  key: 'edit',
                  label: 'Edit Account Metadata',
                  icon: <EditOutlined />,
                },
              ]}
              moreCases={(key) => {
                if (key === 'edit') {
                  onEdit();
                }
              }}
            />
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <AccountUpdateModal
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeleteAccountModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </div>
  );
}

export default AccountTitle;
