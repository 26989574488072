import { gql, useMutation } from '@apollo/client';
import { Button, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text, Title, Paragraph } = Typography;

export const deletePrayerRequestMutation = gql`
  mutation DeletePrayerRequestForAdmin($_id: ID!) {
    deletePrayerRequestForAdmin(_id: $_id) {
      deletedId
    }
  }
`;

const DeletePrayerRequestModal = ({ visible, onFinish, onCancel }) => {
  const { prayerRequest } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deletePrayerRequest] = useMutation(deletePrayerRequestMutation);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      if (prayerRequest) {
        await deletePrayerRequest({
          variables: {
            _id: prayerRequest._id,
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Prayer request deleted successfully',
        });
      }
      onFinish();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error deleting your prayer request',
      });
    }
    setLoading(false);
  }, [prayerRequest, deletePrayerRequest, onFinish]);

  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable={false}
        destroyOnClose={true}
        maskClosable={!loading}
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 16,
            paddingBottom: 16,
          },
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Title>Delete Prayer Request</Title>
          <Paragraph>
            {`Are you sure you want to delete the following prayer request?`}
          </Paragraph>
          <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
            {prayerRequest?.desc}
          </Paragraph>
          <div style={{ height: 16 }} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={loading}
              style={{ marginRight: 16 }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button loading={loading} type="primary" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeletePrayerRequestModal;
