import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactUpdateEmailInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  email: oldEmail,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (oldEmail) {
      form.setFieldsValue({
        email: oldEmail.email,
        label: oldEmail.label,
      });
    }
  }, [oldEmail, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingEmails = (contact.emails || [])
        .filter((e) => e._id !== oldEmail._id)
        .reduce((prev, curr) => {
          const { email, _id, label } = curr;
          prev.push({ email, _id, label });
          return prev;
        }, []);
      const { email, label, makePrimary } = values;
      const existingPrimaryEmailid = contact.primaryEmailId;
      await handleFinish({
        emails: [
          ...existingEmails,
          {
            _id: oldEmail._id,
            email,
            label,
          },
        ].sort((a, b) => a.email.localeCompare(b.email)),
        primaryEmailId: makePrimary ? oldEmail._id : existingPrimaryEmailid,
      });
    },
    [handleFinish, contact, oldEmail],
  );
  return (
    <ContactSomethingForm
      title="Update Email"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
        extra="e.g., Work, Home ..."
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactUpdateEmailInnerModalA10;
