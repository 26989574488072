import { gql, useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateCountryRegionsAction } from '@aims/shared/redux-store/country-regions-store';

export const countryRegionsQuery = gql`
  query CountryRegions($countryShortCode: String!) {
    countryRegions(countryShortCode: $countryShortCode) {
      shortCode
      name
    }
  }
`;

export function useCountryRegions(countryShortCode) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);

  const apolloClient = useApolloClient();

  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await apolloClient.query({
          query: countryRegionsQuery,
          variables: {
            countryShortCode,
          },
          fetchPolicy: 'network-only',
        });
        if (result && result.data && result.data.countryRegions) {
          // const bas = [];
          // for (let i = 0; i < 1000; i += 1) {
          //   const b = { ...result.data.countryRegion.edges[0].node, id: i };
          //   bas.push(b);
          // }
          dispatch(
            updateCountryRegionsAction({
              queryId: 'use-country-regions',
              countryShortCode,
              regions: result.data.countryRegions,
            }),
          );
          // dispatch(readCountryRegionsAction(bas));
        }
      } catch (err) {
        setError(err.message);
      }
      _refetches.current = refetches;
      setLoading(false);
    }
    if (countryShortCode && _refetches.current !== refetches) {
      doAsyncStuff();
    }
  }, [dispatch, refetches, countryShortCode, apolloClient]);

  useEffect(() => {
    setRefetches(_refetches.current + 1);
  }, [countryShortCode]);

  const countryRegions = useSelector((store) => {
    const _countryRegions = store.countryRegions[countryShortCode];
    if (!_countryRegions) return [];
    return _countryRegions.regions;
  }, shallowEqual);

  return {
    countryRegions,
    loading,
    error,
    refetch: () => setRefetches(refetches + 1),
  };
}
