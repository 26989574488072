import { gql } from '@apollo/client';

export const UpgPrayerAdoptionDetailedAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      contactType
      fullName
      primaryEmail
      primaryPhoneNumber
      primaryAddress {
        _id
        label
        address1
        address2
        city
        state
        zipCode
        country
      }
      primaryThumbnailUrl
    }
    peopleGroupId
    peopleGroup {
      _id
      population
      continent
      region
      regionCode
      country
      affinityBloc
      peopleCluster
      nameInCountry
      nameAcrossCountries
      primaryLanguage
      officialLanguage
      bibleStatus
      primaryReligion
      religionSubdivision
      jpScale
      primaryThumbnailUrl
    }
    adoptionId
    adoption {
      _id
      level
      fieldWorkerId
      fieldWorkerName
      regionalCoordId
      regionalCoordName
      level
      status
    }
    tags
    notes
    startDate
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const upgPrayerAdoptionListAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      contactType
      fullName
    }
    peopleGroupId
    peopleGroup {
      _id
      region
      regionCode
      country
      nameAcrossCountries
    }
    adoptionId
    adoption {
      _id
      level
    }
    tags
    notes
    startDate
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createUpgPrayerAdoptionMutation = gql`
  mutation CreateUpgPrayerAdoption(
    $upgPrayerAdoption: UpgPrayerAdoptionCreateInput!
  ) {
    createUpgPrayerAdoption(upgPrayerAdoption: $upgPrayerAdoption) {
      upgPrayerAdoption ${UpgPrayerAdoptionDetailedAttributes}
    }
  }
`;

export const updateUpgPrayerAdoptionMutation = gql`
  mutation UpdateUpgPrayerAdoption(
    $upgPrayerAdoption: UpgPrayerAdoptionUpdateInput!
  ) {
    updateUpgPrayerAdoption(upgPrayerAdoption: $upgPrayerAdoption) {
      upgPrayerAdoption {
        _id
      }
    }
  }
`;

export const allUpgPrayerAdoptionsQuery = gql`
  query AllUpgPrayerAdoptions(
    $first: Int
    $after: String
    $filters: UpgPrayerAdoptionFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allUpgPrayerAdoptionsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${upgPrayerAdoptionListAttributes}
      }
    }
  }
`;

export const extractUpgPrayerAdoptions = (data) =>
  data.allUpgPrayerAdoptionsForAdmin;

export const upgPrayerAdoptionQuery = gql`
  query UpgPrayerAdoptionForAdmin($_id: ID!) {
    upgPrayerAdoptionForAdmin(_id: $_id) ${UpgPrayerAdoptionDetailedAttributes}
  }
`;

export const extractUpgPrayerAdoption = (data) =>
  data && data.upgPrayerAdoptionForAdmin;

export const UpgPrayerAdoptionUpdateAttributes = gql`
{
  crud
  id
  new ${UpgPrayerAdoptionDetailedAttributes}
}
`;

export const upgPrayerAdoptionUpdatesForAdminSubscription = gql`
  subscription UpgPrayerAdoptionUpdatesForAdmin($_id: ID!) {
    upgPrayerAdoptionUpdatesForAdmin(_id: $_id) ${UpgPrayerAdoptionUpdateAttributes}
  }
`;
