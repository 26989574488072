import {
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import CreateTaskModal from './CreateTaskModal';
import React, { useState, useCallback } from 'react';

function TaskFilterBar({
  hideAdd,
  showMyTasksBtn,
  showMyTasks,
  setShowMyTasks,
  filtersRef,
  sortByRef,
  sortedBy,
  sortOrder,
  searchRef,
  refetchRef,
  fieldsToSet,
}) {
  const [creating, setCreating] = useState(null);

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortByRef.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        searchRef.current(search.toLowerCase());
      } else if (refetchRef) {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetchRef();
      }
    },
    [searchRef, refetchRef, filtersRef, sortByRef, sortedBy, sortOrder],
  );

  return (
    <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
      <div
        style={{
          display: 'flex',
          marginRight: -8,
        }}
      >
        {searchRef && (
          <Form.Item
            name="search"
            style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
          >
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        )}
        <div style={{ flex: 1 }} />
        {!hideAdd && (
          <Tooltip title="Add Task">
            <Button
              onClick={() => setCreating('__new__')}
              icon={<PlusOutlined />}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        )}
        {showMyTasksBtn && (
          <Tooltip title="Switch View">
            <Button
              onClick={() => setShowMyTasks(!showMyTasks)}
              icon={showMyTasks ? <TeamOutlined /> : <UserOutlined />}
              style={{ marginRight: 8 }}
            >
              {showMyTasks ? 'Show All Tasks' : 'Show Only My Tasks'}
            </Button>
          </Tooltip>
        )}
        {refetchRef && (
          <Button
            icon={<ReloadOutlined />}
            onClick={async () => {
              await refetchRef.current();
            }}
            style={{ marginRight: 8 }}
          >
            Refresh
          </Button>
        )}
      </div>
      <CreateTaskModal
        visible={creating}
        setVisible={setCreating}
        fieldsToSet={fieldsToSet}
      />
    </Form>
  );
}

export default TaskFilterBar;
