import { Button, Form, Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SelectContact from '../../contacts/SelectContact';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { orderAndFilterFormList } from '@aims/shared/shared/utils';

function HProjectChangeResponsiblePersonsInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Edit Responsible Persons',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        regionalCoordId: holisticProject.regionalCoordId,
        responsiblePersons: holisticProject.responsiblePersons,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { responsiblePersons, ...values } = _values;
      const _responsiblePersons = orderAndFilterFormList(
        responsiblePersons,
        (a, b) => {
          return a.contactId.localeCompare(b.contactId);
        },
        'contactId',
        ['contactId', 'role'],
      );
      handleFinish({
        responsiblePersons: _responsiblePersons,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item label="Who will be taking responsibility for the planning, management and implementation of this project?">
        <Form.List name="responsiblePersons">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div className="person" style={{ flex: 1 }}>
                      <Form.Item
                        key={field.key}
                        style={{ marginBottom: 0 }}
                        shouldUpdate={(prev, curr) =>
                          prev.regionalCoordId !== curr.regionalCoordId
                        }
                      >
                        {({ getFieldValue }) => (
                          <Form.Item
                            label="Contact"
                            name={[field.name, 'contactId']}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <SelectContact
                              disabled={saving}
                              filters={{
                                isFieldWorker: true,
                                regionalCoordId:
                                  getFieldValue('regionalCoordId'),
                              }}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Role"
                        name={[field.name, 'role']}
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add responsible person
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeResponsiblePersonsInner;
