import { shortEnglishHumanizer } from '../tasks/EditTask/constants';
import { AdoptionStatuses } from './constants';

export function getUnfundedSince(adoption) {
  let unfundedSince;
  if (
    adoption &&
    adoption.status === AdoptionStatuses.UNFUNDED.key &&
    adoption.unfundedAt
  ) {
    const timeSince =
      new Date().getTime() - new Date(adoption.unfundedAt).getTime();
    unfundedSince = `Unfunded for ${shortEnglishHumanizer(timeSince, {
      largest: 2,
      round: true,
    })}`;
  }
  return unfundedSince;
}
