export const READ_DONATIONS = 'READ_DONATIONS';
export const CREATE_DONATION = 'CREATE_DONATION';
export const UPDATE_DONATION = 'UPDATE_DONATION';
export const DELETE_DONATION = 'DELETE_DONATION';

// eslint-disable-next-line no-shadow
export const readDonationsAction = (donations) => ({
  type: READ_DONATIONS,
  payload: {
    donations,
  },
});

export const createDonationAction = (donation) => ({
  type: CREATE_DONATION,
  payload: {
    donation,
  },
});

export const updateDonationAction = (donation) => ({
  type: UPDATE_DONATION,
  payload: {
    donation,
  },
});

export const deleteDonationAction = (donationId) => ({
  type: DELETE_DONATION,
  payload: {
    donationId,
  },
});

const initialDonations = [];

export function donations(state = initialDonations, action) {
  switch (action.type) {
    case READ_DONATIONS: {
      const newState = { ...state };
      action.payload.donations.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_DONATION:
      return {
        ...state,
        [action.payload.donation._id]: action.payload.donation,
      };
    case UPDATE_DONATION: {
      const current = state[action.payload.donation._id] || undefined;
      return {
        ...state,
        [action.payload.donation._id]: {
          ...current,
          ...action.payload.donation,
        },
      };
    }
    case DELETE_DONATION: {
      const { donationId } = action.payload;
      return { ...state, [donationId]: undefined };
    }
    default:
      return state;
  }
}
