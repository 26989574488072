import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  createTransferAction,
  deleteTransferAction,
  readTransfersAction,
  updateTransferAction,
} from '../../../redux-store/transfers-store';
import {
  allTransfersQuery,
  extractAllTransfers,
  transferUpdatesSubscription,
} from './constants';
import settings from '../../../settings';

function useLocalTransfers({ filters, skip, sortByFunc, queryId = 'default' }) {
  const { error } = useSingleSomethingUpdatesA10({
    variables: {
      filters: { accountId: filters.accountId, payoutId: filters.payoutId },
    },
    subscription: transferUpdatesSubscription,
    createAction: createTransferAction,
    updateAction: updateTransferAction,
    deleteAction: deleteTransferAction,
    extractSomething: (resp) => resp?.data?.transferUpdates,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('originalDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading, refetch } = useManyLocalM20({
    query: allTransfersQuery,
    extract: extractAllTransfers,
    readAction: readTransfersAction,
    first: settings.querySize,
    filters: { accountId: filters.accountId, payoutId: filters.payoutId },
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.originalDate?.localeCompare(a.originalDate);
    };
  }, [sortByFunc]);
  const filterFunc = useCallback(
    (a) => {
      if (filters.type && filters.type !== a.type) {
        return false;
      }
      if (filters.regionCode && filters.regionCode !== a.regionCode) {
        return false;
      }
      if (
        filters.accountId &&
        ![a.fromAccountId, a.toAccountId].includes(filters.accountId)
      ) {
        return false;
      }
      if (filters.fromAccountId && filters.fromAccountId !== a.fromAccountId) {
        return false;
      }
      if (filters.toAccountId && filters.toAccountId !== a.toAccountId) {
        return false;
      }
      if (filters.from && filters.from > a.originalDate) {
        return false;
      }
      if (filters.until && filters.until <= a.originalDate) {
        return false;
      }
      return true;
    },
    [filters],
  );
  const { data, search } = useManySomethingsM20({
    table: 'transfers',
    sortByFunc: defaultSort,
    searchKeys: ['description', '_id', 'tags'],
    queryId,
    filterFunc,
  });
  return {
    loading,
    search,
    data,
    refetch,
  };
}

export default useLocalTransfers;
