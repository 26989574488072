import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { Typography } from 'antd';
import React, { useRef, useState } from 'react';
import {
  createTaskAction,
  deleteTaskAction,
  readTasksAction,
  updateTaskAction,
} from '../../redux-store/tasks-store';
import settings from '../../settings';
import useProfile from '../../shared/use-profile';
import { StatusColors } from './EditTask/constants';
import TaskFilterBar from './TaskFilterBar';
import TaskTabs from './TaskTabs';
import {
  allTasksForAdminQuery,
  extractAllTasksForAdmin,
  taskUpdatesForAdminSubscription,
} from './constants';

const { Title, Text } = Typography;

function AnyTasks({
  filters: parentFilters,
  fieldsToSet,
  title,
  hideAdd,
  showMyTasksBtn,
  showMyTasksDefault,
  subtitle = null,
  queryId,
}) {
  const [viewing, setViewing] = useState(null);
  const [showMyTasks, setShowMyTasks] = useState(showMyTasksDefault);
  const profile = useProfile();
  const [tab, setTab] = useState(StatusColors.TODO.key);

  const currentUserId = profile._id;

  const filters = useRef(parentFilters || {});
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters: filters.current },
    subscription: taskUpdatesForAdminSubscription,
    createAction: createTaskAction,
    updateAction: updateTaskAction,
    deleteAction: deleteTaskAction,
    extractSomething: (resp) => resp?.data?.taskUpdatesForAdmin,
    queryId,
  });
  const [sortedBy, setSortedBy] = useState('windowStop');
  const [sortOrder, setSortOrder] = useState('ASC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allTasksForAdminQuery,
    extract: extractAllTasksForAdmin,
    readAction: readTasksAction,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const { data: tasks, search: taskSearch } = useManySomethingsM20({
    filterFunc: (task) => {
      if (showMyTasksBtn && showMyTasks) {
        if (task.assigneeId !== currentUserId) {
          return false;
        }
      }
      return true;
    },
    table: 'tasks',
    sortByFunc: (a, b) => {
      return b.windowStop?.localeCompare(a.windowStop);
    },
    searchKeys: ['contact.fullName', 'desc', 'tags.key', '_id'],
    queryId,
  });

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <Title level={2} style={{ margin: 0 }}>
            {title}
          </Title>
          {subtitle && (
            <div style={{ marginTop: 8 }}>
              <Text style={{}}>{subtitle}</Text>
            </div>
          )}
        </div>
        <div style={{ flex: 1 }} />
        <TaskFilterBar
          hideAdd={hideAdd}
          showMyTasksBtn={showMyTasksBtn}
          showMyTasks={showMyTasks}
          setShowMyTasks={setShowMyTasks}
          filtersRef={filters}
          sortByRef={sortBy}
          sortedBy={sortedBy}
          sortOrder={sortOrder}
          fieldsToSet={fieldsToSet}
        />
      </div>
      <TaskTabs tasks={tasks} loading={loading} />
    </>
  );
}

export default AnyTasks;
