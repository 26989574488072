import React, { useMemo } from 'react';
import { ContactTypes } from '../contacts/constants';

export default function useSimplifiedContact(contact) {
  const contactDetails = useMemo(() => {
    if (contact) {
      const emailObj =
        contact.emails &&
        contact.emails.find((e) => e._id === contact.primaryEmailId);
      const phoneObj =
        contact.phoneNumbers &&
        contact.phoneNumbers.find(
          (e) => e._id === contact.primaryPhoneNumberId,
        );
      return {
        ...contact,
        type:
          contact.contactType &&
          ContactTypes[contact.contactType] &&
          ContactTypes[contact.contactType].label,
        email: emailObj && emailObj.email,
        phoneNumber: phoneObj && phoneObj.phone,
      };
    }
    return undefined;
  }, [contact]);
  return contactDetails;
}
