export const READ_ADDRESSES = 'READ_ADDRESSES';
export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';

// eslint-disable-next-line no-shadow
export const readAddressesAction = (addresses) => ({
  type: READ_ADDRESSES,
  payload: {
    addresses,
  },
});

export const createAddressAction = (address) => ({
  type: CREATE_ADDRESS,
  payload: {
    address,
  },
});

export const updateAddressAction = (address) => ({
  type: UPDATE_ADDRESS,
  payload: {
    address,
  },
});

export const deleteAddressAction = (addressId) => ({
  type: DELETE_ADDRESS,
  payload: {
    addressId,
  },
});

const initialAddresses = [];

export function addresses(state = initialAddresses, action) {
  switch (action.type) {
    case READ_ADDRESSES: {
      const newState = { ...state };
      action.payload.addresses.forEach((p) => {
        newState[p.id] = p;
      });
      return newState;
    }
    case CREATE_ADDRESS:
      return {
        ...state,
        [action.payload.address.id]: action.payload.address,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        [action.payload.address.id]: action.payload.address,
      };
    case DELETE_ADDRESS: {
      const { addressId } = action.payload;
      return { ...state, [addressId]: undefined };
    }
    default:
      return state;
  }
}
