import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

const { Title, Paragraph, Text } = Typography;

function EditableTextElement({
  name,
  label,
  noLabel,
  text,
  isTextArea,
  handleSave,
  parties,
  attachments,
  titleLevel = 2,
}) {
  const [editing, setEditing] = useState();
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const doSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      handleSave(values);
      setEditing(false);
      setLoading(false);
    },
    [handleSave],
  );

  useEffect(() => {
    form.setFieldsValue({
      [name]: text,
    });
  }, [name, text, form]);

  return (
    <>
      <div className="line-heading">
        <Title level={titleLevel} style={{ margin: editing ? undefined : 0 }}>
          {label}
        </Title>
        {!editing && (
          <Button
            onClick={() => setEditing(true)}
            icon={<EditOutlined />}
            type="text"
          >
            Edit
          </Button>
        )}
      </div>
      {!editing && (
        <div className="line-answer">
          {!text && noLabel}
          {text &&
            text.split('\n').map((p, index) => {
              let edited = p;
              const refs = p.matchAll(/{{(.*)}}/g);
              let ref = refs.next();
              while (!ref.done) {
                const party =
                  parties && parties.find((a) => a.ref === ref.value[1]);
                if (party) {
                  edited = p.replace(ref.value[0], `<Party: ${party.name}>`);
                }
                const attachment =
                  attachments &&
                  attachments.find((a) => a.ref === ref.value[1]);
                if (attachment) {
                  edited = p.replace(
                    ref.value[0],
                    `Attachment: ${attachment.name}`,
                  );
                }
                ref = refs.next();
              }
              return (
                <Paragraph className="line-answer-p" key={index}>
                  {edited}
                </Paragraph>
              );
            })}
        </div>
      )}
      {editing && (
        <Form id={name} form={form} layout="vertical" onFinish={doSave}>
          <Form.Item name={name}>
            {isTextArea ? (
              <Input.TextArea disabled={loading} rows={4} />
            ) : (
              <Input disabled={loading} />
            )}
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                htmlType="button"
                onClick={() => setEditing(false)}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.ghost};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default EditableTextElement;
