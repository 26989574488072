import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Button, Card, Empty, List, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import settings from '../../../settings';
import {
  allContactsWithPgInfoQuery,
  extractAllContactsForAdmin,
} from '../constants';

const { Title, Text } = Typography;

export function FieldWorker({ contact }) {
  return (
    <List.Item style={{ width: '100%' }}>
      <Card styles={{ body: { position: 'relative' } }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PhotoCircle
            photoUrl={contact && contact.primaryThumbnailUrl}
            name={contact && contact.fullName}
            style={{
              height: 120,
              width: 120,
              padding: 'unset',
              marginBottom: 12,
            }}
          />
        </div>
        <div
          style={{
            fontSize: 14,
            textAlign: 'center',
          }}
        >
          <Text>{contact && contact.fullName}</Text>
        </div>
        <div
          style={{
            fontSize: 14,
            textAlign: 'center',
            marginBottom: 8,
          }}
        >
          {contact.peopleGroupIds?.map((pgId) => (
            <div key={pgId} style={{ marginRight: 2, marginLeft: 2 }}>
              <Link to={`/people-group/view/${pgId}/info`}>{pgId}</Link>
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <Link to={`/contact/view/${contact._id}/dashboard`}>
            Contact Page
          </Link>
        </div>
      </Card>
    </List.Item>
  );
}

function FieldWorkers({ contact }) {
  const [numShown, setNumShown] = useState(8);

  const filters = useRef({
    regionalCoordId: contact && contact._id,
  });
  const sortBy = useRef([
    { key: 'firstName.keyword', order: 'ASC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: fieldWorkers,
    search: contactSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allContactsWithPgInfoQuery,
    extract: extractAllContactsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: contact._id,
  });

  if (!contact) {
    return null;
  }
  return (
    <div className="org">
      <Title level={2}>Field Workers</Title>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <List
        grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 5, xxl: 6, gutter: 16 }}
        dataSource={fieldWorkers
          .filter((c) => c._id !== contact._id)
          .slice(0, numShown)}
        renderItem={(item) => <FieldWorker contact={item} />}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Field Workers"
            />
          ),
        }}
        loadMore={
          fieldWorkers.length > numShown && (
            <div className="load-more">
              <Button onClick={() => setNumShown(numShown + 8)}>
                Load More
              </Button>
            </div>
          )
        }
      />
      <style jsx>{``}</style>
    </div>
  );
}

export default FieldWorkers;
