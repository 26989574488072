import {
  createReportAction,
  deleteReportAction,
  updateReportAction,
} from '@aims/shared/redux-store/reports-store';
import { ViewPgQuarterlyReport } from '@aims/shared/reports';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useQueryParams from '../../shared/use-query-params';
import UpgQuarterlyReportAdminSection from './UpgQuarterlyReportAdminSection';
import {
  extractUpgQuarterlyReport,
  upgQuarterlyReportQuery,
  upgQuarterlyReportUpdatesSubscription,
} from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';

const { Text } = Typography;

const queryId = 'viewUpgQuarterlyReport';

function ViewUpgQuarterlyReport() {
  // const [showReviewModal, setShowReviewModal] = useState(null);
  // const [reviewStatus, setReviewStatus] = useState(null);
  const history = useHistory();
  const { reportId } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id: reportId },
    subscription: upgQuarterlyReportUpdatesSubscription,
    createAction: createReportAction,
    updateAction: updateReportAction,
    deleteAction: deleteReportAction,
    extractSomething: (resp) => resp?.data?.upgQuarterlyReportUpdatesForAdmin,
    queryId,
    skip: !reportId,
  });
  const { loading, error } = useOneM1({
    variables: { _id: reportId },
    query: upgQuarterlyReportQuery,
    extract: extractUpgQuarterlyReport,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/upg-quarterly-reports/approved`;
    }
  }, [query]);

  const handleBack = useCallback(
    () => history.push(backLink),
    [history, backLink],
  );

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="View Quarterly People Group Report"
      >
        <CSPageHeader
          titleComponent={null}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Go back
            </Button>,
          ]}
        />
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          {report && (
            <>
              <ReviewButtons
                report={report}
                error={error}
                queryId={queryId}
                onSuccess={() => {}}
                onDelete={() => history.push(backLink)}
              />
              <ViewPgQuarterlyReport
                report={report}
                colors={sharedSettings.colors}
                isAdmin
                adminSection={
                  <UpgQuarterlyReportAdminSection
                    report={report}
                    queryId={queryId}
                  />
                }
              />
            </>
          )}
          {loading && (
            <div>
              <Skeleton paragraph avatar active />
              <Skeleton paragraph active />
              <Skeleton paragraph active />
            </div>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
      <style jsx global>{`
        @media print {
          * {
            visibility: hidden;
          }
          #upgQuarterlyReport {
            visibility: visible;
            box-shadow: none !important;
          }
          #upgQuarterlyReport * {
            visibility: visible;
          }
          aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.main-nav-menu-slider {
            display: none;
          }
          section > div.page {
            padding: 0px !important;
          }
          section > div.page > div.container > div.header {
            display: none;
          }
        }
      `}</style>
    </>
  );
}

export default ViewUpgQuarterlyReport;
