import { UpgXContactRoles } from '@aims/shared/people-groups/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Empty, List, Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import settings from '../../../settings';
import {
  allUpgXContactsQuery,
  extractUpgXContacts,
} from '../../upg-x-contacts/constants';

const { Title, Text } = Typography;

const ContactCard = ({ upgXContact }) => {
  const userRole = useMemo(() => {
    return UpgXContactRoles?.[upgXContact.type]?.label;
  }, [upgXContact]);

  return (
    <List.Item>
      <Link to={`/contact/view/${upgXContact.contactId}/dashboard`}>
        <Card bordered={false}>
          <div className="body">
            <div className="container">
              <PhotoCircle
                photo={upgXContact?.contact?.primaryThumbnailUrl}
                size="md"
                style={{ height: 48, paddingBottom: 'unset', marginRight: 16 }}
                name={upgXContact?.contact?.fullName}
              />
              <div className="info">
                <Title
                  level={4}
                  style={{
                    whiteSpace: 'break-spaces',
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  {upgXContact?.contact?.fullName}
                </Title>
                {userRole && (
                  <Text
                    style={{
                      color: sharedSettings.colors.darkGray,
                    }}
                  >
                    {userRole}
                  </Text>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Link>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: left;
        }
        .info {
          display: flex;
          flex-direction: column;
          align-items: left;
        }
      `}</style>
    </List.Item>
  );
};

const ConnectedUsers = ({ peopleGroupId }) => {
  const [sortedBy, setSortedBy] = useState('contactId.keyword');
  const [sortOrder, setSortOrder] = useState('ASC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ peopleGroupId });

  const {
    error,
    loading,
    data: upgXContacts,
    search: upgXContactsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgXContactsQuery,
    extract: extractUpgXContacts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'view-contact',
  });

  return (
    <>
      {!loading && (
        <List
          className="regCoords"
          dataSource={upgXContacts}
          grid={{ column: 3, sm: 1, lg: 3, gutter: 20 }}
          rowKey="_id"
          pagination={
            upgXContacts && upgXContacts.length > 9
              ? {
                  defaultCurrent: 1,
                  pageSize: 9,
                  total: upgXContacts.length,
                }
              : false
          }
          renderItem={(upgXContact) => (
            <ContactCard upgXContact={upgXContact} />
          )}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Connected Contacts"
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default ConnectedUsers;
