import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Input, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

const { Text, Paragraph } = Typography;

function ViewEditPayoutNotesV2({ payout, savePayout }) {
  const ref = useRef();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);

  const handleFinish = useCallback(
    async (values) => {
      setEditing(false);
      if (payout.notes !== values.notes) {
        await savePayout({ _id: payout._id, notes: values.notes });
      }
    },
    [payout, savePayout],
  );

  const handleClick = useCallback(() => {
    setEditing(true);
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, 100);
  }, []);

  return (
    <>
      {!editing ? (
        <map
          onClick={handleClick}
          style={{ display: 'block', width: '100%', maxWidth: 800 }}
        >
          {payout.notes && (
            <Paragraph
              style={{ marginBottom: 0, maxWidth: 800 }}
              ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
            >
              {payout.notes}
            </Paragraph>
          )}
          <div>
            <Text style={{ color: sharedSettings.colors.textGray }}>
              {payout.notes ? 'Click to edit ...' : 'Click to add a note ...'}
            </Text>
          </div>
        </map>
      ) : (
        <Form
          form={form}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
          onFinish={handleFinish}
          style={{ maxWidth: 800 }}
        >
          <Form.Item name="notes" noStyle initialValue={payout.notes}>
            <Input.TextArea ref={ref} onBlur={() => form.submit()} />
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default ViewEditPayoutNotesV2;
