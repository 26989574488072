import { Form, Select, Tag } from 'antd';
import React, { useRef, useState } from 'react';
import settings from '../settings';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import {
  allUsersForAdminQuery,
  extractUsersForAdmin,
} from '../pages/users/constants';
import SelectUser from '../pages/users/SelectUser';

const Colors = [
  '#cf1322',
  '#d4380d',
  '#d46b08',
  '#d48806',
  '#d4b106',
  '#7cb305',
  '#389e0d',
  '#08979c',
  '#096dd9',
  '#1d39c4',
  '#531dab',
  '#c41d7f',
  '#8c8c8c',
];

function AssigneeFormItem({ loading: formLoading, form }) {
  const [sortedBy, setSortedBy] = useState('name.keyword');
  const [sortOrder, setSortOrder] = useState('ASC');

  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: users,
    search: userSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUsersForAdminQuery,
    extract: extractUsersForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'users',
  });

  const colors = useRef({});
  const colorIndex = useRef(Math.floor(Math.random() * Colors.length));

  function CustomTag(props) {
    const { label, value, closable, onClose } = props;
    let color;
    if (colors.current[value]) {
      color = colors.current[value];
    } else {
      color = Colors[colorIndex.current];
      colors.current[value] = color;
      colorIndex.current = colorIndex.current + (1 % Colors.length);
    }
    return (
      <Tag
        color={color}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  return (
    <Form.Item label="Assigned To" name="assigneeIds">
      <SelectUser mode="multiple" />
    </Form.Item>
  );
}

export default AssigneeFormItem;
