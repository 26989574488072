import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import React from 'react';
import { AdoptionLevels, AdoptionStatuses } from '../../adoptions/constants';

function DisplayAdoptionA10({ record }) {
  const pg = record.peopleGroup;
  const rc =
    record.regionalCoordName &&
    `Coordinator: ${record.regionalCoordName || 'None'}`;
  const fw =
    record.fieldWorkerName &&
    `Field Worker: ${record.fieldWorkerName || 'None'}`;
  const sponsors =
    record.sponsors && record.sponsors.length > 0
      ? `Sponsors: ${record.sponsors.map((s) => s.fullName).join(', ')}`
      : 'No Sponsors';
  const status =
    record.status &&
    AdoptionStatuses[record.status] &&
    AdoptionStatuses[record.status].label;
  const pgName = pg
    ? `${pg.nameAcrossCountries} / ${pg.country} (${pg._id})`
    : 'No People Group';
  const level =
    record &&
    AdoptionLevels[record.level] &&
    `${AdoptionLevels[record.level].label} @ ${displayMoney(
      record.amount / 10000,
    )} / mo`;
  return (
    <div>
      <div style={{ fontSize: 11 }}>{status}</div>
      <div
        style={{
          fontSize: 12,
          color: sharedSettings.colors.primary,
        }}
      >
        {pgName}
      </div>
      <div style={{ fontSize: 12, color: sharedSettings.colors.textGray }}>
        {level}
      </div>
      {rc && <div style={{ fontSize: 11 }}>{rc}</div>}
      {fw && <div style={{ fontSize: 11 }}>{fw}</div>}
      <div style={{ fontSize: 11 }}>{sponsors}</div>
    </div>
  );
}

export default DisplayAdoptionA10;
