import { useSubscription } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { exportContactsSubscription } from './constants';

function useExportContactsResult(skip) {
  const subscription = useSubscription(exportContactsSubscription, {
    skip,
  });
  const _counter = useRef(0);

  const [result, setResult] = useState();
  useEffect(() => {
    if (subscription.data && subscription.data.exportContacts) {
      _counter.current += 1;
      setResult(subscription.data.exportContacts);
    }
  }, [subscription.data]);

  useEffect(() => {
    if (subscription.error) {
      console.error(subscription.error);
    }
  }, [subscription.error]);

  return result;
}

export default useExportContactsResult;
