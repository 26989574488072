import { SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { sendUpgQuarterlyReportFeedbackMutation } from '../constants';

const { Title, Paragraph } = Typography;

const SendUpgQuarterlyReportFeedbackBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [sendPgQuarterlyReportFeedback] = useMutation(
    sendUpgQuarterlyReportFeedbackMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await sendPgQuarterlyReportFeedback({
          variables: {
            reportId: report._id,
            feedback: values.feedback,
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Feedback sent',
        });
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report',
        });
      }
      setLoading(false);
    },
    [sendPgQuarterlyReportFeedback, report, onSuccess],
  );

  return (
    <>
      <Button
        loading={loading}
        icon={<SendOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Send Feedback
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Send UPG Quarterly Report Feedback
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`This will send ${
                report.submittedByUser
                  ? report.submittedByUser.name
                  : 'the submitter'
              } an email including your feedback.  They'll be able to reply to you directly via email if they have any questions.`}
            </Paragraph>
          </Form.Item>
          <Form.Item
            label="Feedback"
            name="feedback"
            rules={[{ required: true, message: 'Please add some feedback' }]}
          >
            <Input.TextArea disable={loading} rows={4} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Send Feedback
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default SendUpgQuarterlyReportFeedbackBtn;
