import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EditUpgQuarterlyReportBtn = ({ report }) => {
  const location = useLocation();

  return (
    <>
      <Link
        to={`/upg-quarterly-reports/edit/${report._id}?from=${location.pathname}`}
      >
        <Button icon={<EditOutlined />}>Edit Report</Button>
      </Link>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default EditUpgQuarterlyReportBtn;
