import { useMemo } from 'react';
import useQueryParams from '../../shared/use-query-params';

export function useHolisticQuarterlyReportBackLink() {
  const query = useQueryParams();
  return useMemo(() => {
    if (query.from) {
      return query.from;
    }
    return '/holistic-quarterly-reports/pending';
  }, [query]);
}
