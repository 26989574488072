import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined, IdcardOutlined, RiseOutlined } from '@ant-design/icons';
import { Button, Skeleton, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import { PeopleGroupFocusLevels } from '../EditPeopleGroup/constants';
import UpdatePeopleGroupStatsButton from './UpdatePeopleGroupStatsButton';

const { Title, Text } = Typography;

function PeopleGroupTitle({ peopleGroup, queryId }) {
  const history = useHistory();

  return (
    <div className="outer-container">
      {peopleGroup ? (
        <div className="container">
          <PhotoCircle
            photoUrl={peopleGroup.primaryThumbnailUrl}
            size="lg"
            name={peopleGroup.nameAcrossCountries}
            customSize={160}
            style={{
              height: 160,
              width: 160,
              padding: 'unset',
              marginRight: 32,
            }}
          />
          <Space direction="vertical" style={{ flex: 1 }}>
            <Title
              style={{ marginBottom: 0 }}
            >{`${peopleGroup.nameAcrossCountries}`}</Title>
            <div>
              <Title level={5} style={{ marginBottom: 4 }}>
                {`${peopleGroup.country} / ${peopleGroup.region} / ${peopleGroup.continent}`}
              </Title>
            </div>
            {peopleGroup.focusLevelNo != undefined && (
              <div>
                <Text>
                  {PeopleGroupFocusLevels[peopleGroup.focusLevelNo].icon}
                  <span style={{ marginRight: 8 }} />
                  {PeopleGroupFocusLevels[peopleGroup.focusLevelNo].label}
                </Text>
              </div>
            )}
            <div>
              <Text>
                <RiseOutlined style={{ marginRight: 8 }} />
                {peopleGroup.jpScale}
              </Text>
            </div>
            <div>
              <Text>
                <IdcardOutlined style={{ marginRight: 8 }} />
                {peopleGroup._id}
              </Text>
            </div>
          </Space>
          <div style={{ display: 'flex' }}>
            <Tooltip title="Edit People Group">
              <Button
                onClick={() =>
                  history.push(`/people-group/edit/${peopleGroup._id}`, {
                    from: `/people-group/view/${peopleGroup._id}/info`,
                  })
                }
                icon={<EditOutlined />}
              />
            </Tooltip>
            <div style={{ width: 4 }} />
            <UpdatePeopleGroupStatsButton
              pgId={peopleGroup._id}
              queryId={queryId}
            />
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <style jsx>{`
        .outer-container {
          padding-top: 16px;
        }
        .container {
          display: flex;
        }
        .photo-placeholder {
          height: 128px;
          width: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${sharedSettings.colors.primary};
          color: white;
          font-size: 48px;
          border-radius: 100%;
          overflow: hidden;
        }
        .profile-photo {
          height: 128px;
          width: 128px;
          border-radius: 100%;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
}

export default PeopleGroupTitle;
