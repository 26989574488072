import Icon, {
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
} from '@ant-design/icons';
import React from 'react';

const ChurchSvg = () => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <g>
          <g>
            <path
              d="m111.83 511.768h-111.83v-161.67l117.728-76.033z"
              fill="#d6e8fd"
            />
          </g>
          <g>
            <path
              d="m512 511.768h-111.923l-5.804-237.703 117.727 76.033z"
              fill="#bcdefd"
            />
          </g>
          <g>
            <path
              d="m256.448 144.587v-1.625l-144.618 76.875v291.931h147.215z"
              fill="#edf5ff"
            />
          </g>
          <g>
            <path
              d="m400.077 219.837-143.629-76.875-.448 368.806h144.077z"
              fill="#d6e8fd"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  d="m256.732 331.159c-.187-.002-.372-.014-.559-.014-24.653 0-44.711 20.057-44.711 44.711v135.913h45.27z"
                  fill="#fabe2c"
                />
              </g>
            </g>
            <path
              d="m300.884 511.768v-135.913c0-24.638-20.033-44.685-44.665-44.71v180.622h44.665z"
              fill="#fd9025"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  d="m241.051 229.048h14.949v59.614h-14.949z"
                  fill="#5ed8d3"
                />
              </g>
            </g>
            <path d="m256 229.048h14.858v59.614h-14.858z" fill="#31bebe" />
          </g>
          <path d="m441.204 379.235h29.807v59.614h-29.807z" fill="#31bebe" />
          <g>
            <g>
              <g>
                <path
                  d="m440.267 272.129-183.819-116.081-184.715 116.081-15.887-25.22 200.602-126.089 199.706 126.089z"
                  fill="#fd657d"
                />
              </g>
            </g>
            <g>
              <path
                d="m456.154 246.909-199.706-126.089v35.228l183.819 116.081z"
                fill="#d4374e"
              />
            </g>
          </g>
        </g>
      </g>
      <path d="m40.896 379.235h29.807v59.614h-29.807z" fill="#5ed8d3" />
      <path
        d="m257.045 130.29v-130.058h-15.687v32.152h-30.892v29.807h30.892v68.099z"
        fill="#fabe2c"
      />
      <g>
        <path
          d="m271.165 32.384v-32.152h-14.717v130.058h14.996v-68.099h32.259v-29.807z"
          fill="#fd9025"
        />
      </g>
    </g>
  </svg>
);

export const ChurchIcon = (props) => <Icon component={ChurchSvg} {...props} />;

export function getFileIcon(fileName, style) {
  if (!fileName) {
    return <FileOutlined style={style} />;
  }
  if (
    fileName.endsWith('.pptx') ||
    fileName.endsWith('.pptm') ||
    fileName.endsWith('.ppt') ||
    fileName.endsWith('.key') ||
    fileName.endsWith('.odp')
  ) {
    return <FilePptOutlined style={style} />;
  }
  if (
    fileName.endsWith('.jpg') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.jfif') ||
    fileName.endsWith('.pjpeg') ||
    fileName.endsWith('.pjp')
  ) {
    return <FileJpgOutlined style={style} />;
  }
  if (
    fileName.endsWith('.png') ||
    fileName.endsWith('.svg') ||
    fileName.endsWith('.webp') ||
    fileName.endsWith('.tif') ||
    fileName.endsWith('.tiff') ||
    fileName.endsWith('.bmp') ||
    fileName.endsWith('.eps') ||
    fileName.endsWith('.raw') ||
    fileName.endsWith('.cr2') ||
    fileName.endsWith('.orf') ||
    fileName.endsWith('.sr2') ||
    fileName.endsWith('.nef')
  ) {
    return <FileImageOutlined style={style} />;
  }
  if (
    fileName.endsWith('.xlsx') ||
    fileName.endsWith('.xlsm') ||
    fileName.endsWith('.xlsb') ||
    fileName.endsWith('.xltx') ||
    fileName.endsWith('.xltx') ||
    fileName.endsWith('.xltm') ||
    fileName.endsWith('.xls') ||
    fileName.endsWith('.xlt') ||
    fileName.endsWith('.numbers') ||
    fileName.endsWith('.ods')
  ) {
    return <FileExcelOutlined style={style} />;
  }
  if (fileName.endsWith('.pdf')) {
    return <FilePdfOutlined style={style} />;
  }
  if (fileName.endsWith('.gif')) {
    return <FileGifOutlined style={style} />;
  }
  if (
    fileName.endsWith('.txt') ||
    fileName.endsWith('.rtf') ||
    fileName.endsWith('.log') ||
    fileName.endsWith('.md')
  ) {
    return <FileTextOutlined style={style} />;
  }
  if (
    fileName.endsWith('.doc') ||
    fileName.endsWith('.docx') ||
    fileName.endsWith('.odt') ||
    fileName.endsWith('.pages')
  ) {
    return <FileWordOutlined style={style} />;
  }
  return <FileOutlined style={style} />;
}
