import { PeopleGroupRoles } from '@aims/shared/people-groups/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { gql } from '@apollo/client';
import { Card, Empty, List, Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import { updateContactAction } from '../../../redux-store/contacts-store';
import settings from '../../../settings';
import { ContactTypes, extractContactForAdmin } from '../constants';
import {
  allUpgXContactsQuery,
  extractUpgXContacts,
} from '../../upg-x-contacts/constants';

const { Title, Text } = Typography;

export const pgConnectionContactQuery = gql`
  query PgConnectionContact($_id: ID!) {
    contactForAdmin(_id: $_id) {
      _id
      fullName
      contactType
    }
  }
`;

function PgConnectionCard({ item }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const pgInfo = useMemo(() => {
    const pg = item;
    if (pg) {
      const lang = pg && `${pg.primaryLanguage} / ${pg.primaryReligion}`;
      const pop =
        pg &&
        `${Math.round(pg.population).toLocaleString(locale)} / ${pg.jpScale}`;
      return {
        ...pg,
        lang,
        pop,
      };
    }
    return undefined;
  }, [item, locale]);
  return (
    <Card styles={{ body: { display: 'flex' } }}>
      <div style={{ marginRight: 16 }}>
        <PhotoCircle
          photoUrl={pgInfo && pgInfo.primaryThumbnailUrl}
          name={pgInfo && pgInfo.nameAcrossCountries}
          style={{ height: 80, width: 80, paddingBottom: 'unset' }}
          color={sharedSettings.colors.contactColor}
        />
      </div>
      {pgInfo && (
        <div>
          {item.contact && (
            <div className="right">
              <Text style={{ color: sharedSettings.colors.textGray }}>
                {`${item.contact.fullName} (${
                  ContactTypes[item.contact.contactType]?.label
                })`}
              </Text>
            </div>
          )}
          {Object.keys(item.roles).map((role, index) => (
            <Title
              level={5}
              key={index}
              style={{ margin: 0, textTransform: 'uppercase' }}
            >
              {PeopleGroupRoles[role]}
            </Title>
          ))}
          <Title
            level={5}
            style={{ margin: 0 }}
          >{`${pgInfo.nameAcrossCountries} / ${pgInfo.country}`}</Title>
          <div className="right">
            <Text style={{ color: sharedSettings.colors.textGray }}>
              {pgInfo._id}
            </Text>
          </div>
          <div>{pgInfo.lang}</div>
          <div>{pgInfo.pop}</div>
          <div>
            <Link to={`/people-group/view/${pgInfo._id}/info`}>
              People Group Page
            </Link>
          </div>
        </div>
      )}
    </Card>
  );
}

function UpgConnections({ contactId, queryId = 'default', showLink }) {
  const { loading: contactLoading } = useOneM1({
    variables: { _id: contactId },
    query: pgConnectionContactQuery,
    extract: extractContactForAdmin,
    fetchPolicy: 'cache-first',
    updateAction: updateContactAction,
    queryId,
    skip: !contactId,
  });
  const contact = useSelector(
    (store) => store.contacts[queryId]?.[contactId],
    shallowEqual,
  );

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ contactId });

  const {
    error,
    loading,
    data: upgXContacts,
    search: upgXContactsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgXContactsQuery,
    extract: extractUpgXContacts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'view-contact',
  });

  const peopleGroups = useMemo(() => {
    if (upgXContacts?.length > 0) {
      const pgcs = {};
      upgXContacts.forEach((pgc) => {
        if (!pgcs[pgc.peopleGroupId]) {
          pgcs[pgc.peopleGroupId] = {
            roles: {},
            contact: pgc.contact,
            ...pgc.peopleGroup,
          };
        }
        pgcs[pgc.peopleGroupId].roles[pgc.role] = pgc.type;
      });
      return Object.values(pgcs);
    }
    return [];
  }, [upgXContacts]);

  return (
    <>
      {contact && (
        <Title level={3} style={{ marginBottom: 0 }}>{`${contact.fullName} (${
          ContactTypes[contact.contactType]?.label
        })`}</Title>
      )}
      <div style={{ marginBottom: 16 }}>
        {showLink && (
          <Link to={`/contact/view/${contactId}/dashboard`}>
            View Contact Page
          </Link>
        )}
      </div>
      <List
        dataSource={peopleGroups}
        renderItem={(item) => (
          <List.Item>
            <PgConnectionCard item={item} />
          </List.Item>
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Details"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2, gutter: 16 }}
      />
    </>
  );
}

function UpgConnectionsTab({ contact }) {
  return (
    <>
      <UpgConnections contactId={contact._id} />
      {contact.contactType === ContactTypes.HOUSEHOLD.key && (
        <>
          <Title level={3} style={{ marginBottom: 8 }}>
            Household UPG Connections
          </Title>
          {contact.householdMembers &&
            contact.householdMembers.map((member) => (
              <UpgConnections
                key={member._id}
                contactId={member._id}
                showLink
              />
            ))}
        </>
      )}
    </>
  );
}

export default UpgConnectionsTab;
