import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  InputNumber,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import { createLoanAgreementAction } from '../../../../../redux-store/loan-agreements-store';
import settings from '../../../../../settings';
import {
  allLoanAgreementTemplatesQuery,
  createLoanAgreementFromTemplateMutation,
  extractLoanAgreements,
} from '../../../../loan-agreements/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';

const { Text, Title, Paragraph } = Typography;

function SelectLoanAgreementTemplate({ value, onChange, ...props }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: true,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const {
    error,
    loading,
    data: loanAgreements,
    search: loanAgreementsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allLoanAgreementTemplatesQuery,
    extract: extractLoanAgreements,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'loanAgreementsTab',
  });
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <Select
      value={value}
      onChange={onChange}
      {...props}
      optionLabelProp="label"
    >
      {(loanAgreements || []).map((agreement) => {
        const updated = `Updated ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'full',
        }).format(new Date(agreement.updatedAt))}`;
        return (
          <Select.Option
            key={agreement._id}
            value={agreement._id}
            label={`${agreement.name} - ${updated}`}
          >
            <div
              style={{
                fontWeight: 600,
                color: sharedSettings.colors.primary,
                fontSize: 14,
              }}
            >
              {agreement.name}
            </div>
            <div>{updated}</div>
            <div>
              {agreement.parties &&
                agreement.parties.map((p) => (
                  <div style={{ fontSize: 12 }} key={p._id}>{`${p.name}`}</div>
                ))}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
}

function SelectTemplateForm({ loading, handleSave, defaultVersionNo }) {
  const [form] = Form.useForm();
  return (
    <>
      <Title level={4}>Select a Loan Agreement Template</Title>
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={handleSave}
      >
        <Form.Item name="templateId">
          <SelectLoanAgreementTemplate
            placeholder="Select ..."
            disabled={loading}
          />
        </Form.Item>
        <Form.Item
          label="Version"
          name="versionNo"
          initialValue={defaultVersionNo}
        >
          <InputNumber disabled={loading} min={0} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
              loading={loading}
            >
              Create Loan Agreement
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

const CreateLoanAgreementFromTemplateModal = ({
  visible,
  onFinish,
  onCancel,
}) => {
  const { project, createId, defaultVersionNo } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [createLoanAgreementFromTemplate] = useMutation(
    createLoanAgreementFromTemplateMutation,
  );

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const { templateId, versionNo } = values;
        const result = await createLoanAgreementFromTemplate({
          variables: {
            templateId,
            projectId: project._id,
            newId: createId,
            versionNo,
          },
        });
        dispatch(
          createLoanAgreementAction(
            result.data.createLoanAgreementFromTemplate.loanAgreement,
            'h-project',
          ),
        );
        notification.success({
          message: 'Saved',
          description: 'Loan agreement created successfully',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, project, createId, createLoanAgreementFromTemplate, onFinish],
  );

  return (
    <>
      <Modal
        header={null}
        visible={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          Add a Loan Agreement
        </Title>
        {visible && (
          <SelectTemplateForm
            handleSave={handleSave}
            loading={loading}
            defaultVersionNo={defaultVersionNo}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreateLoanAgreementFromTemplateModal;
