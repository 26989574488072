import { EyeOutlined } from '@ant-design/icons';
import { Button, Card, List, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import PhotoCircle from '../../../../components/PhotoCircle';

const { Text, Title } = Typography;

const CourseCard = ({ course }) => {
  const history = useHistory();

  return (
    <List.Item style={{ width: '100%' }}>
      <Card bordered={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              paddingRight: 16,
            }}
          >
            <PhotoCircle
              photoUrl={course.primaryThumbnailUrl}
              size="mid"
              name={course.courseName}
              style={{
                height: 80,
                width: 80,
                padding: 'unset',
                marginRight: 16,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
              }}
            >
              <Title
                level={4}
                style={{ whiteSpace: 'break-spaces', marginBottom: 4 }}
              >
                {course.courseName}
              </Title>
              {course.desc && <Text>{ellipsis(course.desc, 100)}</Text>}
            </div>
          </div>
          <div className="right">
            <Space>
              <Tooltip title="View">
                <Button
                  onClick={() =>
                    history.push(`training-courses/view/${course._id}`)
                  }
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </Space>
          </div>
        </div>
      </Card>
    </List.Item>
  );
};

export default CourseCard;
