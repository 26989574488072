import { gql } from '@apollo/client';

export const HpInstallmentListAttributes = gql`
  {
    _id
    projectId
    amount
    dueAt
    paidAmount
    paidAt
    regionCode
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const allHpInstallmentsQuery = gql`
  query AllHpInstallmentsForAdmin($first: Int, $after: String, $filters: HpInstallmentFiltersForAdmin, $sortBy: [SortBy]) {
    allHpInstallmentsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${HpInstallmentListAttributes}
      }
    }
  }
`;

export const extractAllHpInstallments = (data) =>
  data && data.allHpInstallmentsForAdmin;

export const hpInstallmentQuery = gql`
  query HpInstallmentForAdmin($_id: ID) {
    hpInstallmentForAdmin(_id: $_id) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${HpInstallmentListAttributes}
      }
    }
  }
`;

export const extractHpInstallment = (data) =>
  data && data.hpInstallmentForAdmin;

const HpInstallmentUpdateAttributes = gql`
{
  crud
  id
  new ${HpInstallmentListAttributes}
}
`;

export const hpInstallmentUpdatesSubscription = gql`
  subscription HpInstallmentUpdatesForAdmin($filters: HpInstallmentUpdateFiltersForAdmin!) {
    hpInstallmentUpdatesForAdmin(filters: $filters) ${HpInstallmentUpdateAttributes}
  }
`;
