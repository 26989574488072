import { useQuery } from '@apollo/client';
import { Select, Typography, notification } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { allRolesV2ForAdminQuery } from './constants';

const { Text } = Typography;

function SelectRolesV2({ onChange, value, disabled, multiple = true, style }) {
  const { loading, error, data } = useQuery(allRolesV2ForAdminQuery, {
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  }, [error]);

  const options = useMemo(() => {
    if (data) {
      return data.allRolesV2ForAdmin.map((role) => ({
        value: role._id,
        label: role.label,
        desc: role.desc,
      }));
    }
    return [];
  }, [data]);

  return (
    <Select
      mode={multiple ? 'multiple' : undefined}
      style={{ width: '100%', ...style }}
      placeholder={multiple ? 'Select one or more roles' : 'Select a role'}
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      loading={loading}
      disabled={disabled}
      showSearch
      optionFilterProp="label"
      optionRender={(option) => (
        <div>
          <div>
            <Text>{option.data.label}</Text>
          </div>
          <div>
            <Text>{option.data.desc}</Text>
          </div>
        </div>
      )}
    />
  );
}

export default SelectRolesV2;
