import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import CreateEventModal from './CreateEventModal';

function AddEventButton({ fieldsToSet }) {
  const [creating, setCreating] = useState();
  const onAdd = () => setCreating(true);
  const onFinishAdd = () => setCreating(false);
  const onCancelAdd = () => setCreating(false);
  return (
    <>
      <Tooltip title="Add Event">
        <Button onClick={onAdd} icon={<PlusOutlined />} />
      </Tooltip>
      <CreateEventModal
        visible={creating}
        onFinish={onFinishAdd}
        onCancel={onCancelAdd}
        fieldsToSet={fieldsToSet}
      />
    </>
  );
}

export default AddEventButton;
