import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SimpleSelectStringTagsA10 from '../../../../components/SimpleSelectStringTagsA10';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactAliasesInnerModal({ contact, saving, handleFinish, onCancel }) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        aliases: contact.aliases,
      });
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (_values) => {
      const { aliases, ...values } = _values;
      handleFinish({
        aliases,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Edit Aliases"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item label="Aliases" name="aliases">
        <SimpleSelectStringTagsA10 disabled={saving} />
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactAliasesInnerModal;
