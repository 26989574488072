import { Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../pages/tasks/ViewTaskModal/TaskSomethingForm';
import SelectRegionForAdmin from '../pages/people_groups/ListPeopleGroups/SelectRegionForAdmin';

function ThingChangeRegionInner({
  thing,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Region',
  label = 'Region',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (thing) {
      form.setFieldsValue({
        regionCode: thing.regionCode,
      });
    }
  }, [thing, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { regionCode, ...values } = _values;
      handleFinish({
        regionCode,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label={label}
        name="regionCode"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <SelectRegionForAdmin disabled={saving} />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default ThingChangeRegionInner;
