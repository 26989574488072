import { MinusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../../components/YesNoModal';
import { reviewHolisticQuarterlyReportMutation } from '../constants';

const MarkReportPendingBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewReport] = useMutation(reviewHolisticQuarterlyReportMutation);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await reviewReport({
        variables: {
          reportId: report._id,
          status: 'PENDING',
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Progress report marked pending.',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your report.',
      });
    }
    setLoading(false);
  }, [reviewReport, report, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<MinusOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Mark Pending
      </Button>

      <YesNoModal
        title="Mark Progress Report Pending"
        question={`Are you sure you want to indicate that this progress report is pending review?`}
        yesText="Mark Pending"
        noText="Cancel"
        onYes={handleFinish}
        onNo={() => setShowingModal(null)}
        visible={showingModal}
        loading={loading}
        closable={!loading}
      />
    </>
  );
};

export default MarkReportPendingBtn;
