import { Space, Typography } from 'antd';
import React from 'react';
import { AgreementStatuses } from '../constants';
import DeleteLoanAgreementBtn from './DeleteLoanAgreementBtn';
import MarkLoanAgreementCancelledBtn from './MarkLoanAgreementCancelledBtn';
import MarkLoanAgreementPrepareBtn from './MarkLoanAgreementPrepareBtn';
import MarkLoanAgreementPreviewBtn from './MarkLoanAgreementPreviewBtn';
import MarkLoanAgreementSignBtn from './MarkLoanAgreementSignBtn';
import MarkLoanAgreementSigningBtn from './MarkLoanAgreementSigningBtn';
import PrintLoanAgreementBtn from './PrintLoanAgreementBtn';
import SendLoanAgreementReminderBtn from './SendLoanAgreementReminderBtn';

const { Title, Text, Paragraph } = Typography;

const ReviewButtons = ({
  loanAgreement,
  setError,
  error,
  queryId,
  onSuccess,
  onDelete,
}) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {loanAgreement.status === AgreementStatuses.PREPARING.key && (
            <>
              <MarkLoanAgreementPreviewBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <DeleteLoanAgreementBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onDelete}
              />
            </>
          )}
          {loanAgreement.status === AgreementStatuses.PREVIEW.key && (
            <>
              <MarkLoanAgreementSigningBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <MarkLoanAgreementPrepareBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <DeleteLoanAgreementBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onDelete}
              />
            </>
          )}
          {loanAgreement.status === AgreementStatuses.SIGNING.key && (
            <>
              <MarkLoanAgreementSignBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />

              <SendLoanAgreementReminderBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <MarkLoanAgreementCancelledBtn
                loanAgreement={loanAgreement}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
            </>
          )}
          <PrintLoanAgreementBtn />
        </Space>
      </div>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ReviewButtons;
