import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deletePeopleGroupAction } from '../../redux-store/people-groups-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const deletePeopleGroupMutation = gql`
  mutation deletePeopleGroup($_id: ID!) {
    deletePeopleGroup(_id: $_id) {
      deletedId
    }
  }
`;

function DeletePeopleGroupModal({ peopleGroup, setPeopleGroup, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deletePeopleGroup] = useMutation(deletePeopleGroupMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deletePeopleGroup({
        variables: {
          _id: peopleGroup._id,
        },
      });
      dispatch(deletePeopleGroupAction(peopleGroup._id));
      notification.success({
        message: 'Deleted',
        description: 'People group deleted successfully',
      });
      setTimeout(() => {
        setPeopleGroup(null);
        if (onComplete) {
          onComplete();
        } else {
          history.goBack();
        }
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [
    peopleGroup,
    deletePeopleGroup,
    setPeopleGroup,
    dispatch,
    history,
    onComplete,
  ]);

  const onNo = useCallback(() => {
    if (!loading) {
      setPeopleGroup(null);
    }
  }, [setPeopleGroup, loading]);

  return (
    <YesNoModal
      title="Delete PeopleGroup"
      question={`Are you sure you want to delete this peopleGroup (${
        peopleGroup && peopleGroup.nameAcrossCountries
      } / ${peopleGroup && peopleGroup.country})?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!peopleGroup}
      loading={loading}
      error={error}
    />
  );
}

export default DeletePeopleGroupModal;
