import sharedSettings from '@aims/shared/sharedSettings';
import { GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Space, Typography } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from '../assets/long-logo-md.png';
import settings from '../settings';
import { AuthContext } from '../shared/auth-context';
import useProfile from '../shared/use-profile';
import useQueryParams from '../shared/use-query-params';
import useScript from '../shared/use-script';

const { Title, Text } = Typography;

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    setServerError(null);
    let error;
    try {
      error = await authContext.signIn(values);
    } catch (err) {
      error = err.message;
    }
    if (error) {
      setServerError(error);
      setLoading(false);
    }
  };

  const query = useQueryParams();
  const once = useRef(false);
  useEffect(() => {
    async function doAsyncStuff(code) {
      setLoading(true);
      setServerError(null);
      let error;
      try {
        error = await authContext.signInWithMagicLink(code);
      } catch (err) {
        error = err.message;
      }
      if (error) {
        setServerError(error);
        setLoading(false);
      }
    }
    const { c } = query;
    if (c && !once.current) {
      once.current = true;
      doAsyncStuff(c);
    }
  }, [authContext, query]);

  const profile = useProfile();
  useEffect(() => {
    if (profile) {
      history.push('/home');
    }
  }, [profile, history]);

  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const handleGoogleResponse = useCallback(
    async (response) => {
      // console.log('response', response);
      setLoading(true);
      let error;
      try {
        error = await authContext.signIntoAdminWithGoogle(response.code);
      } catch (err) {
        error = err.message;
      }
      if (error) {
        console.error(error);
        setServerError(error);
        setLoading(false);
      }
    },
    [authContext],
  );

  const [googleClient, setGoogleClient] = useState();
  const [_, googleClientLoading] = useScript(
    'https://accounts.google.com/gsi/client',
    () => {
      const client = window.google.accounts.oauth2.initCodeClient({
        client_id: settings.googleOAuthClientId,
        scope:
          'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        ux_mode: 'popup',
        callback: handleGoogleResponse,
      });
      setGoogleClient(client);
    },
  );

  return (
    <div className="page qr-gen">
      <img src={logo} alt="AIMS.org" className="logo" />
      <Title
        level={3}
        style={{
          textAlign: 'center',
          marginBottom: 16,
          color: sharedSettings.colors.text,
        }}
      >
        Staff Portal
      </Title>
      <div
        style={{
          background: sharedSettings.colors.white,
          padding: 32,
          paddingTop: 48,
          width: '90vw',
          maxWidth: 500,
          borderRadius: 8,
          boxShadow: `0px 0px 8px ${sharedSettings.colors.borderGray}`,
        }}
      >
        <Form name="basic" layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please enter your username' }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center', paddingTop: 16 }}>
            {/* <Button
              type="link"
              htmlType="button"
              onClick={() => history.push('/sign-up')}
            >
              Sign Up
            </Button> */}
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ minWidth: 200 }}
            >
              Sign In
            </Button>
          </Form.Item>
          {serverError && (
            <div style={{ marginTop: 16, textAlign: 'center' }}>
              <Text type="danger">{serverError}</Text>
            </div>
          )}
          <Divider>Or</Divider>
          <div>
            <Space
              align="center"
              style={{
                flexWrap: 'wrap',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  googleClient.requestCode();
                }}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={googleClientLoading}
                disabled={loading}
              >
                Sign in with Google
              </Button>
              {/* <Button
                onClick={() => authContext.signInWithApple()}
                icon={<AppleOutlined />}
                style={{ marginBottom: 8 }}
              >
                Sign in with Apple
              </Button> */}
            </Space>
          </div>
        </Form>
      </div>
      <style jsx>{`
        .page {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 10vh;
          padding-bottom: 10vh;
        }
        .subtitle {
          font-size: 24px;
        }
        .server-error {
          text-align: center;
        }
        .logo {
          width: 300px;
        }
      `}</style>
      <style jsx global>{`
        body {
          background: #efefef;
        }
        .qr-gen-title {
          text-align: center;
        }
        .ant-form-item-explain,
        .ant-form-item-extra {
          padding-bottom: 8px;
        }
        @media screen and (max-width: 992px) {
        }
        @media screen and (max-width: 600px) {
        }
      `}</style>
    </div>
  );
};

export default SignIn;
