import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { BankOutlined } from '@ant-design/icons';
import { Button, Progress, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useProfile from '../../../../../shared/use-profile';

const { Text, Title, Paragraph } = Typography;

function ProjectFundingStatus({ project, showIt }) {
  const profile = useProfile();
  const permissions = profile?.permissions;
  const hasPermission = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'get-admin-account');
  }, [permissions]);
  const balance = getNumberValue(project.fundingBalance);
  const partner = getNumberValue(project.totalPartnerFunding);
  return (
    <>
      {project.fundingTarget !== undefined &&
        project.fundingBalance !== undefined &&
        ([
          HolisticProjectStages.PREPARING.key,
          HolisticProjectStages.FUNDED.key,
          HolisticProjectStages.FUNDING.key,
          HolisticProjectStages.COMPLETE.key,
          HolisticProjectStages.FAILED.key,
        ].includes(project.stage) ||
          showIt) && (
          <>
            <Title level={3}>Project Funding</Title>
            <Progress percent={Math.round(project.fundingPercent / 100)} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
                marginBottom: 16,
              }}
            >
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(balance / 10000)}
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 14, fontWeight: 600 }}>
                  {displayMoney(getNumberValue(project.fundingTarget) / 10000)}
                </Text>
              </div>
            </div>
            {showIt && (
              <div>
                <Paragraph
                  style={{ marginBottom: 0 }}
                >{`From Project Sponsorships: ${displayMoney(
                  partner / 10000,
                )}`}</Paragraph>
                <Paragraph>{`From Pool or Other Accounts: ${displayMoney(
                  (balance - partner) / 10000,
                )}`}</Paragraph>
              </div>
            )}
            {project.fundingAccountId && hasPermission && (
              <div style={{ fontSize: 18, marginRight: 16 }}>
                <Link
                  to={`/accounting/accounts/view/${project.fundingAccountId}`}
                >
                  <Button icon={<BankOutlined />}>
                    View Project Funding Account
                  </Button>
                </Link>
              </div>
            )}
          </>
        )}
    </>
  );
}

export default ProjectFundingStatus;
