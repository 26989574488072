import {
  EyeInvisibleOutlined,
  EyeOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';

import useEvents from './use-events';
import AddEventButton from './AddEventButton';
import EventsList from './EventsList';
import useProfile from '../../shared/use-profile';

const { Title, Text } = Typography;

function ContactEvents({
  contactId,
  hideAddButton,
  showMyEventsBtn = false,
  showMyEventsDefault = false,
  showPreviousButton = true,
  showPreviousDefault = false,
  queryId,
}) {
  const [showMyEvents, setShowMyEvents] = useState(showMyEventsDefault);
  const [showPreviousEvents, setShowPreviousEvents] =
    useState(showPreviousDefault);
  const profile = useProfile();
  const currentUserId = profile._id;

  const filters = useMemo(() => {
    return { contactId };
  }, [contactId]);
  const fieldsToSet = useMemo(() => {
    return { contactIds: [contactId] };
  }, [contactId]);

  const { loading, data: events } = useEvents({
    filters,
    queryId,
    sortByFunc: (a, b) => {
      return b.startTime?.localeCompare(a.startTime);
    },
    filterFunc: (a) => {
      let ret = true;
      if (showMyEvents && ret && a.subscriberId !== currentUserId) {
        ret = false;
      }
      let d = new Date();
      d.setDate(d.getDate() - 1);
      d = d.toISOString();
      if (!showPreviousEvents && ret && a.startTime < d) {
        ret = false;
      }
      if (!a.contactIds.includes(contactId)) {
        ret = false;
      }
      return ret;
    },
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <Title level={2} style={{ margin: 0 }}>
          Events
        </Title>
        <Space>
          {!hideAddButton && <AddEventButton fieldsToSet={fieldsToSet} />}
          {showPreviousButton && (
            <Button
              onClick={() => setShowPreviousEvents(!showPreviousEvents)}
              icon={
                showPreviousEvents ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
            >
              {showPreviousEvents
                ? 'Hide Previous Events'
                : 'Show Previous Events'}
            </Button>
          )}
          {showMyEventsBtn && (
            <Button
              onClick={() => setShowMyEvents(!showMyEvents)}
              icon={showMyEvents ? <TeamOutlined /> : <UserOutlined />}
            >
              {showMyEvents ? 'Show All Events' : 'Show Only My Events'}
            </Button>
          )}
        </Space>
      </div>
      <EventsList events={events} loading={loading} />
    </>
  );
}

export default ContactEvents;
