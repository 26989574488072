import { EyeOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../settings';

const { Title, Text } = Typography;

function ListLoanAgreements({
  loading,
  error,
  loanAgreements,
  next,
  hasNextPage,
  refetch,
  onFiltersChanged,
  onSortedByChange,
  onSortOrderChange,
}) {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onFiltersChanged}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={loanAgreements}
        loading={!loanAgreements.length && loading}
        pagination={{ ...pagination, total: loanAgreements.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="Name" dataIndex="name" key="name" />
        <Column
          title="Parties"
          dataIndex="parties"
          render={(text, record) => {
            return (
              <>
                {record.parties &&
                  record.parties.map((p) => (
                    <div key={p._id}>{`${p.name}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Sections"
          dataIndex="sections"
          render={(text, record) => {
            return (
              <>
                {record.sections &&
                  record.sections.map((s) => (
                    <div key={s._id}>{`${s.title}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Attachments"
          dataIndex="attachments"
          render={(text, record) => {
            return (
              <>
                {record.attachments &&
                  record.attachments.map((s) => (
                    <div key={s._id}>{`${s.name}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Updated"
          dataIndex="updatedAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.updatedAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link to={`/loan-agreement-template/view/${record._id}`}>
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Edit">
                <Button
                  onClick={handleUpdate(record._id)}
                  icon={<EditOutlined />}
                />
              </Tooltip> */}
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {loanAgreements && hasNextPage && (
          <Button onClick={next}>Load More</Button>
        )}
      </div>
      <style jsx>{`
        .body {
          display: flex;
        }
        .bottom-actions {
          text-align: center;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

export default ListLoanAgreements;
