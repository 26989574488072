import sharedSettings from '@aims/shared/sharedSettings';
import { EyeOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import settings from '../../settings';
import { AdoptionLevels } from '../adoptions/constants';
import { ContactTypes } from '../contacts/constants';

const { Title, Text } = Typography;

function ListUpgPrayerAdoptions({
  loading,
  error,
  upgPrayerAdoptions,
  next,
  hasNextPage,
  refetch,
  onFiltersChanged,
  onSortedByChange,
  onSortOrderChange,
}) {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const history = useHistory();
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
  });

  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form layout="inline" form={form} onValuesChange={onFiltersChanged}>
          <Form.Item name="search" style={{ maxWidth: 500, minWidth: 300 }}>
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={upgPrayerAdoptions}
        loading={!upgPrayerAdoptions.length && loading}
        pagination={{ ...pagination, total: upgPrayerAdoptions.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 900 }}
      >
        <Column
          title="Contact"
          dataIndex="contact"
          render={(text, record) => {
            return (
              record.contact && (
                <Link to={`/contact/view/${record.contactId}/dashboard`}>{`${
                  record.contact.fullName
                } (${ContactTypes[record.contact.contactType]?.label})`}</Link>
              )
            );
          }}
        />
        <Table.Column
          title="Tags"
          dataIndex="tags"
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag
                  key={tag}
                  color={sharedSettings.colors.primary}
                  style={{ marginTop: 10 }}
                >
                  {tag}
                </Tag>
              ))
            );
          }}
        />
        <Table.Column
          title="People Group"
          dataIndex="peopleGroup"
          render={(text, record) => {
            const pg = record.peopleGroup;
            return (
              pg && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to={`/people-group/view/${pg._id}/info`}>
                    <div className="row1" style={{ whiteSpace: 'nowrap' }}>
                      <Text
                        style={{
                          marginBottom: 0,
                          width: '100%',
                        }}
                      >{`${pg.nameAcrossCountries} / ${pg.country}`}</Text>
                    </div>
                    <div className="right">
                      <Text style={{ color: sharedSettings.colors.textGray }}>
                        {pg._id}
                      </Text>
                    </div>
                  </Link>
                </div>
              )
            );
          }}
        />
        <Column
          title="Adoption"
          dataIndex="adoption"
          key="adoption"
          render={(text, record) => {
            return (
              record.adoption && (
                <Link to={`/adoption/view/${record.adoptionId}`}>{`${
                  AdoptionLevels[record.adoption.level]?.label
                }`}</Link>
              )
            );
          }}
        />
        <Column
          title="Start Date"
          dataIndex="startDate"
          render={(text, record) => {
            return (
              record.startDate && dateFormat.format(new Date(record.startDate))
            );
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link to={`/upg-prayer-adoptions/view/${record._id}`}>
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {upgPrayerAdoptions && hasNextPage && (
          <Button onClick={next}>Load More</Button>
        )}
      </div>
      <style jsx>{`
        .body {
          display: flex;
        }
        .bottom-actions {
          text-align: center;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

export default ListUpgPrayerAdoptions;
