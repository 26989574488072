import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { useMutation } from '@apollo/client';
import { updateTransferMutation } from '../../../../accounting/accounts-j25/TransferUpdateModal';
import { notification, Typography } from 'antd';

const { Paragraph } = Typography;

function CompletePayoutTxModal({ visible, onCancel, onFinish }) {
  const { txId, name } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updateTransfer] = useMutation(updateTransferMutation);

  const onSubmit = useCallback(async () => {
    setSaving(true);
    try {
      await updateTransfer({
        variables: {
          transfer: {
            _id: txId,
            pending: false,
          },
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Transaction updated successfully',
      });
      if (onFinish) {
        onFinish(txId);
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setSaving(false);
  }, [updateTransfer, onFinish, txId]);
  return (
    <YesNoModalJ10
      title="Mark Transfer Complete"
      yesText="Yes"
      noText="Cancel"
      onYes={onSubmit}
      onNo={onCancel}
      visible={visible}
      loading={saving}
    >
      <Paragraph
        style={{ marginBottom: 8 }}
      >{`Are you sure you want to mark this payout transfer complete?`}</Paragraph>
      <Paragraph style={{ marginBottom: 8, fontWeight: 600 }}>{name}</Paragraph>
    </YesNoModalJ10>
  );
}

export default CompletePayoutTxModal;
