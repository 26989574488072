import { Tag, Typography } from 'antd';
import React from 'react';
import { PayoutRowStates } from '../../constants';

const { Title, Text } = Typography;

function PayoutRowStateColumn({ record }) {
  return (
    <>
      {PayoutRowStates[record.state] && (
        <Tag>{PayoutRowStates[record.state].label}</Tag>
      )}
    </>
  );
}

export default PayoutRowStateColumn;
