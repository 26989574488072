import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings.js';
import { Card, Empty, List, Skeleton, Typography } from 'antd';
import React, { useMemo, useRef } from 'react';
import settings from '../../settings';
import useProfile from '../../shared/use-profile';
import ContactCard from '../contacts/ListContacts/ContactCard.js';
import {
  allContactsForAdminQwuery,
  extractAllContactsForAdmin,
} from '../contacts/constants.js';

const { Title, Text } = Typography;

const UserRecents = () => {
  const profile = useProfile();
  const currentUserId = profile._id;
  const filters = useRef({
    myRecents: currentUserId,
  });
  const sortBy = useRef([
    { key: 'updatedAt', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);
  const {
    loading,
    data: contacts,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allContactsForAdminQwuery,
    extract: extractAllContactsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'recents',
  });

  const recents = useMemo(() => {
    return [
      ...(contacts.map((c) => ({
        ...c,
        type: 'contact',
        url: `/contact/view/${c._id}/dashboard`,
      })) || []),
    ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  }, [contacts]);

  return (
    <div>
      <div className="top-actions">
        <Title level={2} style={{ margin: 0 }}>
          My People &amp; Organizations
          <br />
          <Text
            style={{
              fontSize: 13,
              fontWeight: 'normal',
              color: sharedSettings.colors.textGray,
            }}
          >
            The recently updated contacts, households and organizations that you
            are subscribed to.
          </Text>
        </Title>
      </div>
      {loading && !recents.length ? (
        <List
          itemLayout="horizontal"
          grid={{ column: 2, sm: 1, lg: 2, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={() => (
            <List.Item>
              <Card
                bordered={false}
                style={{
                  // width: 350,
                  height: 200,
                  marginTop: 25,
                }}
              >
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <List
          className="recentList"
          dataSource={recents}
          grid={{ column: 2, sm: 1, lg: 2, gutter: 20 }}
          rowKey="_id"
          pagination={
            recents.length <= 4
              ? false
              : {
                  pageSize: 4,
                  total: `${recents.length < 20 ? recents.length : 20}`,
                  size: 'small',
                  style: { textAlign: 'center' },
                }
          }
          renderItem={(item) => <ContactCard contact={item} hideSelect />}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Activity"
              />
            ),
          }}
        />
      )}
      <style jsx>{`
        .top-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
        }
      `}</style>
    </div>
  );
};

export default UserRecents;
