import { gql } from '@apollo/client';
import { contactFullAttributes } from '../contacts/constants';

export const addContactToHouseholdMutation = gql`
  mutation AddContactToHousehold($member: HouseholdMemberInput!) {
    addContactToHousehold(member: $member) {
      contact ${contactFullAttributes}
      household ${contactFullAttributes}
    }
  }
`;

export const removeContactFromHouseholdMutation = gql`
  mutation RemoveContactFromHousehold($contactId: ID!, $householdId: ID!) {
    removeContactFromHousehold(
      contactId: $contactId
      householdId: $householdId
    ) {
      contact ${contactFullAttributes}
      household ${contactFullAttributes}
    }
  }
`;
