import {
  createReportAction,
  deleteReportAction,
  updateReportAction,
} from '@aims/shared/redux-store/reports-store';
import ViewTrainingQuarterlyReport from '@aims/shared/reports/training-quarterly/ViewTrainingQuarterlyReport';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space } from 'antd';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import TrainQuarterlyAdminSection from './TrainQuarterlyAdminSection';
import {
  extractTrainingQuarterlyReport,
  trainingQuarterlyReportQuery,
  trainingQuarterlyReportUpdatesSubscription,
} from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';
import useQueryParams from '../../../shared/use-query-params';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';

const queryId = 'viewTrainingQuarterlyReport';

function ViewTrainingQuarterlyReportPage() {
  const { reportId } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id: reportId },
    subscription: trainingQuarterlyReportUpdatesSubscription,
    createAction: createReportAction,
    updateAction: updateReportAction,
    deleteAction: deleteReportAction,
    extractSomething: (resp) =>
      resp?.data?.trainingQuarterlyReportUpdatesForAdmin,
    queryId,
    skip: !reportId,
  });
  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: trainingQuarterlyReportQuery,
    extract: extractTrainingQuarterlyReport,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: true,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/training-quarterly-reports`;
    }
  }, [query]);

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Training Quarterly Report"
      >
        <CSPageHeader
          titleComponent={null}
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          {report && (
            <>
              <ReviewButtons report={report} queryId={queryId} />
              <ViewTrainingQuarterlyReport
                colors={sharedSettings.colors}
                report={report}
                isAdmin
                adminSection={
                  <TrainQuarterlyAdminSection
                    report={report}
                    queryId={queryId}
                  />
                }
              />
            </>
          )}
          {reportLoading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <style jsx global>{`
        @media print {
        }
      `}</style>
    </>
  );
}

export default ViewTrainingQuarterlyReportPage;
