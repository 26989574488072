import { gql } from '@apollo/client';

const trainingCourseFullAttributes = gql`
  {
    _id
    courseName
    desc
    profilePhotoId
    primaryThumbnailUrl
    modules {
      _id
      name
      desc
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const trainingCourseQuery = gql`
  query TrainingCourseForAdmin($_id: ID!) {
    trainingCourseForAdmin(_id: $_id) ${trainingCourseFullAttributes}
  }
`;

export const extractTrainingCourse = (data) =>
  data && data.trainingCourseForAdmin;

const TrainingCourseUpdateAttributes = gql`
{
  crud
  id
  new ${trainingCourseFullAttributes}
}
`;

export const trainingCourseUpdatesSubscription = gql`
  subscription TrainingCourseUpdatesForAdmin($_id: ID!) {
    trainingCourseUpdatesForAdmin(_id: $_id) ${TrainingCourseUpdateAttributes}
  }
`;

export const createTrainingCourseMutation = gql`
  mutation CreateTrainingCourse($trainingCourse: TrainingCourseCreateInput!) {
    createTrainingCourse(trainingCourse: $trainingCourse) {
      trainingCourse {
        _id
      }
    }
  }
`;

export const updateTrainingCourseMutation = gql`
  mutation UpdateTrainingCourse($trainingCourse: TrainingCourseUpdateInput!) {
    updateTrainingCourse(trainingCourse: $trainingCourse) {
      trainingCourse {
        _id
      }
    }
  }
`;

export const deleteTrainingCourseMutation = gql`
  mutation DeleteTrainingCourse($_id: ID!) {
    deleteTrainingCourse(_id: $_id) {
      deletedId
    }
  }
`;
