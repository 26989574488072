import { generateUuid } from '@aims/shared/shared/utils';
import { Button, Checkbox, Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SingleAddressFormItem from './SingleAddressFormItem';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactAddAddressInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.resetFields();
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingAddresses = (contact.addresses || []).reduce(
        (prev, curr) => {
          const {
            _id,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          } = curr;
          prev.push({
            _id,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          });
          return prev;
        },
        [],
      );
      const {
        label,
        address1,
        address2,
        city,
        state,
        zipCode,
        country,
        makePrimary,
      } = values;
      const existingPrimaryId = contact.primaryAddressId;
      const newId = generateUuid();
      await handleFinish({
        addresses: [
          ...existingAddresses,
          {
            _id: newId,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          },
        ].sort((a, b) => a._id.localeCompare(b._id)),
        primaryAddressId: makePrimary ? newId : existingPrimaryId,
      });
    },
    [handleFinish, contact],
  );
  return (
    <ContactSomethingForm
      title="Add Addresses"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <SingleAddressFormItem
        form={form}
        loading={saving}
        num={(contact.addresses || []).length + 1}
      />
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactAddAddressInnerModalA10;
