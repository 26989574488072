import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import TrainingEventReportItems from '@aims/shared/reports/training-event/TrainingEventReportItems';
import {
  ReportApprovalStatuses,
  ReportTypes,
} from '@aims/shared/reports/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  createTrainingEventReportForAdminMutation,
  extractTrainingEventReport,
  submitTrainingEventReportForAdminMutation,
  trainingEventReportQuery,
  unsubmitTrainingEventReportForAdminMutation,
  updateTrainingEventReportForAdminMutation,
} from './constants';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import useProfile from '../../../shared/use-profile';
import useQueryParams from '../../../shared/use-query-params';
import DeleteTrainingEventReportModal from './DeleteTrainingEventReportModal';

const { Title, Text } = Typography;

const queryId = 'editTrainingEventReport';

function EditTrainingEventReport() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);

  const { reportId } = useParams();

  const { loading: reportLoading, refetch } = useOneM1({
    variables: { _id: reportId },
    query: trainingEventReportQuery,
    extract: extractTrainingEventReport,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: false,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/training-event-reports`;
    }
  }, [query]);

  const [form] = Form.useForm();
  const finishSubForm = useRef();
  const shouldSubmitReport = useRef(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateReport] = useMutation(updateTrainingEventReportForAdminMutation);
  const [createReport] = useMutation(createTrainingEventReportForAdminMutation);
  const [submitReport] = useMutation(submitTrainingEventReportForAdminMutation);
  const [unsubmitReport] = useMutation(
    unsubmitTrainingEventReportForAdminMutation,
  );
  const profile = useProfile();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Report ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Report ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      setError(null);
      const { reportType, notes, ..._values } = __values;
      try {
        const finished = finishSubForm.current(_values);
        if (report && shouldSubmitReport.current === 'unsubmit') {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report unsubmitted successfully',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: ReportTypes.TRAIN_EVENT.key,
                notes,
                ...finished,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report updated successfully',
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: profile.contactId,
                reportType: ReportTypes.TRAIN_EVENT.key,
                notes,
                ...finished,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report created successfully',
          });
        }
        if (shouldSubmitReport.current === 'submit') {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report submitted successfully',
          });
        }
        history.push(
          `/training-event-reports/review/${reportId}?from=${location.pathname}`,
        );
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error has occurred.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      profile,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
    ],
  );

  const saveDraft = useCallback(async () => {
    const __values = form.getFieldsValue();
    setSaveLoading('Saving Draft ...');
    setError(null);
    const { notes, ..._values } = __values;
    try {
      const finished = finishSubForm.current(_values);
      if (report) {
        await updateReport({
          variables: {
            report: {
              _id: reportId,
              reportType: ReportTypes.TRAIN_EVENT.key,
              ...finished,
              notes,
            },
          },
        });
        refetch();
        notification.success({
          message: 'Saved',
          description: 'Report updated successfully',
        });
      } else {
        await createReport({
          variables: {
            report: {
              _id: reportId,
              contactId: profile.contactId,
              reportType: ReportTypes.TRAIN_EVENT.key,
              ...finished,
              notes,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Report created successfully',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error has occurred.',
      });
    }
    setSaveLoading(false);
  }, [reportId, createReport, profile, report, updateReport, form, refetch]);

  const title = useMemo(() => {
    if (reportLoading) {
      return <Title style={{ marginBottom: 0 }}>Event Training Report</Title>;
    }
    if (report) {
      const status = ReportApprovalStatuses[report.status];
      return (
        <>
          <Title style={{ marginBottom: 16 }}>Edit Event Training Report</Title>
          {status && (
            <div style={{ color: status.color, fontWeight: 600, fontSize: 18 }}>
              {status.label}
            </div>
          )}
        </>
      );
    }
    return (
      <Title style={{ marginBottom: 0 }}>Create Event Training Report</Title>
    );
  }, [reportLoading, report]);

  const loading = reportLoading || saveLoading;
  const showDelete = report && !report.submittedAt;

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Edit Report"
      >
        <CSPageHeader
          titleComponent={
            <div style={{ textAlign: 'center', marginBottom: 32 }}>{title}</div>
          }
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
        >
          {reportLoading && (
            <>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
            </>
          )}
          {!reportLoading && (
            <TrainingEventReportItems
              _id={reportId}
              report={report}
              loading={loading}
              form={form}
              finishSubForm={finishSubForm}
            />
          )}
          <ShowErrorsFormItem />
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {(!report || (report && !report.submittedAt)) && (
                <Button
                  key="draft"
                  type="dashed"
                  loading={loading}
                  htmlType="button"
                  onClick={saveDraft}
                >
                  Save Draft
                </Button>
              )}
              {(!report || (report && !report.submittedAt)) && (
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = 'submit';
                    form.submit();
                  }}
                >
                  Submit Report
                </Button>
              )}
              {report &&
                report.submittedAt &&
                (!report.approval ||
                  (report.approval &&
                    report.approval.status === 'PENDING')) && (
                  <Button
                    key="unsubmit"
                    type="dashed"
                    loading={loading}
                    htmlType="button"
                    onClick={() => {
                      shouldSubmitReport.current = 'unsubmit';
                      form.submit();
                    }}
                  >
                    Unsubmit Report
                  </Button>
                )}
            </Space>
          </Form.Item>
          {error && (
            <Text type="danger" style={{ marginTop: 16 }}>
              {error}
            </Text>
          )}
        </Form>
        {showDelete && (
          <div>
            <Divider />
            <div className="delete-box">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => setDeleting(report)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete Report
                </Button>
              </div>
            </div>
          </div>
        )}
        <Modal open={saveLoading} footer={null} closable={false} width={300}>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
            <Title level={4}>{saveLoading}</Title>
          </div>
        </Modal>
        <DeleteTrainingEventReportModal
          report={deleting}
          setReport={setDeleting}
        />
      </CSPage>
      <style jsx>{`
        .title {
          display: flex;
          padding-top: 32px;
        }
        .left {
          padding-right: 32px;
        }
        .subtitle {
          margin-bottom: 16px;
        }
      `}</style>
      <style jsx global>{`
        .church {
          border: 1px solid ${sharedSettings.colors.borderGray};
        }
      `}</style>
    </>
  );
}

export default EditTrainingEventReport;
