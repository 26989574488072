export const READ_USERS = 'READ_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const readUsersAction = (
  // eslint-disable-next-line no-shadow
  users,
  queryId = 'default',
) => ({
  type: READ_USERS,
  payload: {
    users,
    queryId,
  },
});

export const createUserAction = (user, queryId = 'default') => ({
  type: CREATE_USER,
  payload: {
    user,
    queryId,
  },
});

export const updateUserAction = (user, queryId = 'default') => ({
  type: UPDATE_USER,
  payload: {
    user,
    queryId,
  },
});

export const deleteUserAction = (userId, queryId = 'default') => ({
  type: DELETE_USER,
  payload: {
    userId,
    queryId,
  },
});

const initialUsers = { default: {} };

export function users(state = initialUsers, action) {
  switch (action.type) {
    case READ_USERS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.users.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_USER:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.user._id]: action.payload.user,
        },
      };
    case UPDATE_USER: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.user._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.user._id]: {
            ...current,
            ...action.payload.user,
          },
        },
      };
    }
    case DELETE_USER: {
      const { userId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [userId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
