import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { BarChartOutlined, ExportOutlined } from '@ant-design/icons';
import { gql, useApolloClient } from '@apollo/client';
import { Button, Form, Typography, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';
import AccountsTable from './AccountsTable';
import { exportTransfersToCsv } from './components/ExportTransfersBtn';

const { Title, Text, Paragraph } = Typography;

function BalanceReportingTab({ selected }) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [form] = Form.useForm();
  const [report, setReport] = useState();
  const apolloClient = useApolloClient();

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      const { until, from } = values;
      try {
        if (selected.length === 0) {
          throw Error('At least one account must be selected.');
        }
        const total = selected.reduce((prev, curr) => {
          return prev + curr.balance.balance;
        }, 0.0);
        setReport({
          date: new Intl.DateTimeFormat(locale, {
            dateStyle: 'medium',
          }).format(new Date()),
          accounts: selected.sort((a, b) => a.name.localeCompare(b.name)),
          total,
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [selected, apolloClient],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState();
  const handleExportCsv = useCallback(() => {
    if (report) {
      message.info('Exporting ...');
      setExportError(null);
      setExporting(true);
      try {
        const title = `Account balances on ${report.date}`;
        exportTransfersToCsv(report.accounts, locale, title);
        message.success('Success');
      } catch (err) {
        console.error(err);
        setExportError(err.message);
      }
      setExporting(false);
    }
  }, [locale, report]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#c6cfd3',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          alignItems: 'center',
          borderRadius: 8,
          marginBottom: 32,
        }}
      >
        <Title level={5} style={{ margin: 8 }}>
          {`${selected.length} Accounts Selected`}
        </Title>
      </div>
      <div style={{ marginBottom: 32 }}>
        <Button
          icon={<BarChartOutlined />}
          type="primary"
          shape="round"
          style={{ marginLeft: 'auto' }}
          onClick={handleFinish}
          loading={loading}
          disabled={!selected?.length}
        >
          Generate Report
        </Button>
      </div>
      {error && (
        <Paragraph
          type="danger"
          style={{ padding: 32, textAlign: 'center', fontSize: 14 }}
        >
          {error}
        </Paragraph>
      )}
      {!error && !report && (
        <div
          style={{
            padding: 32,
            textAlign: 'center',
            fontSize: 72,
            fontWeight: 600,
            color: sharedSettings.colors.borderGray,
          }}
        >
          Get Ready
        </div>
      )}
      {!error && report && (
        <>
          <Title level={2}>Account Balance Report</Title>
          <div className="totals-container">
            <div className="totals-section">
              <div>
                <Text
                  style={{
                    fontSize: 14,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  Report Date
                </Text>
              </div>
              <Title level={3}>{report.date}</Title>
            </div>
            <div className="totals-section">
              <div>
                <Text
                  style={{
                    fontSize: 18,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  Total
                </Text>
              </div>
              <Title>
                {report.total != null && displayMoney(report.total / 10000)}
              </Title>
            </div>
          </div>
          <Title level={2}>Accounts</Title>
          {exportError && (
            <Paragraph
              type="danger"
              style={{ padding: 16, textAlign: 'center', fontSize: 14 }}
            >
              {exportError}
            </Paragraph>
          )}
          <div style={{ marginBottom: 16, textAlign: 'right' }}>
            <Button
              icon={<ExportOutlined />}
              onClick={handleExportCsv}
              loading={exporting}
              shape="round"
            >
              Export CSV
            </Button>
          </div>
          <AccountsTable accounts={report.accounts} loading={loading} />
        </>
      )}
      <style jsx>{`
        .totals-container {
          display: flex;
          justify-content: center;
          padding-left: 10vw;
          padding-right: 10vw;
          align-items: center;
        }
        .totals-section {
          text-align: center;
          margin-left: 16px;
          margin-right: 16px;
          flex: 1;
        }
      `}</style>
    </>
  );
}

export default BalanceReportingTab;
