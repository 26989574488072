import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';
import React, { useCallback, useEffect } from 'react';
import EditAttachments from './EditAttachments';
import { Form } from 'antd';

function MyAttachmentsFormItemInner({
  attachments,
  parentId,
  getUploadIds,
  onChange: parentOnChange,
}) {
  const [fileList, setFileList, _fileList] = useSafeStateA10([]);

  console.log('attachments', attachments);

  useEffect(() => {
    if (attachments) {
      setFileList([...attachments]);
    }
  }, [attachments, setFileList]);

  const onUploadComplete = useCallback(
    (attachment) => {
      const newList = [..._fileList.current, attachment];
      setFileList(newList);
      parentOnChange(newList.map((f) => f._id));
    },
    [_fileList, setFileList, parentOnChange],
  );
  const onDeleteAttachment = useCallback(
    (attachmentId) => {
      const newList = [
        ..._fileList.current.filter((f) => f._id !== attachmentId),
      ];
      setFileList(newList);
      parentOnChange(newList.map((f) => f._id));
    },
    [_fileList, setFileList, parentOnChange],
  );

  return (
    <EditAttachments
      attachments={fileList}
      parentId={parentId}
      onUploadComplete={onUploadComplete}
      onDeleteAttachment={onDeleteAttachment}
      getUploadIds={getUploadIds}
    />
  );
}

function MyAttachmentsFormItem({
  attachments,
  parentId,
  getUploadIds,
  name,
  label,
}) {
  return (
    <Form.Item name={name} label={label}>
      <MyAttachmentsFormItemInner
        attachments={attachments}
        parentId={parentId}
        getUploadIds={getUploadIds}
      />
    </Form.Item>
  );
}

export default MyAttachmentsFormItem;
