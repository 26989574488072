import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../../settings';
import { allPpFundsQuery, extractAllFunds } from '../constants';

function AdminListFunds() {
  const filters = useRef({});
  const sortBy = useRef([
    { key: 'name.keyword', order: 'ASC' },
    { key: '_score', order: 'DESC' },
  ]);
  const {
    error,
    loading,
    data: funds,
    search: fundSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPpFundsQuery,
    extract: extractAllFunds,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'pushpayFunds',
  });

  const [form] = Form.useForm();
  const [numShown, setNumShown] = useState(settings.pageSize);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          { key: 'name.keyword', order: 'ASC' },
        ];
        fundSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [fundSearch, refetch],
  );

  const handleLoadMore = useCallback(() => {
    setNumShown(numShown + settings.pageSize);
    if (numShown + settings.pageSize > funds.length) {
      next();
    }
  }, [next, numShown, funds]);

  return (
    <>
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onFiltersChanged}
        >
          <Form.Item name="search">
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
        </Form>
      </div>
      <Table
        dataSource={funds.slice(0, numShown)}
        loading={!funds.length && loading}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
      >
        <Column title="ID" dataIndex="_id" key="_id" />
        <Column title="Name" dataIndex="name" key="name" />
        <Column title="Code" dataIndex="code" key="code" />
        <Column
          title="Updated"
          dataIndex="updatedAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.updatedAt));
          }}
        />
      </Table>
      <div className="bottom-actions">
        {(numShown < funds.length || hasNextPage) && (
          <Button
            style={{ minWidth: 200 }}
            onClick={handleLoadMore}
            loading={loading}
          >
            Load More
          </Button>
        )}
      </div>
      <style jsx>{`
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
      `}</style>
    </>
  );
}

export default AdminListFunds;
