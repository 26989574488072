import useOneM1 from '@aims/shared/shared/use-one-m1';
import { shallowEqual, useSelector } from 'react-redux';
import { updateUserAction } from '../../redux-store/users-store';
import { extractUserForAdmin, userForAdminQuery } from './constants';

export default function useUser(_id, queryId = 'default') {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: userForAdminQuery,
    extract: extractUserForAdmin,
    fetchPolicy: 'cache-first',
    updateAction: updateUserAction,
    queryId,
    skip: !_id,
  });
  const user = useSelector(
    (store) => store.users[queryId]?.[_id],
    shallowEqual,
  );

  return {
    user,
    loading,
    refetch,
  };
}
