import { gql } from '@apollo/client';

export const ViewContactAttributes = gql`
  {
    _id
    contactType
    firstName
    middleNames
    lastName
    fullName
    birthday
    relationship
    tags {
      key
      color
    }
    aliases
    relationship
    primaryEmailId
    primaryPhoneNumberId
    primaryAddressId
    phoneNumbers {
      _id
      label
      phone
    }
    emails {
      _id
      label
      email
    }
    addresses {
      _id
      label
      address1
      address2
      city
      state
      zipCode
      country
    }
    userId
    primaryContactId
    otherDetails
    otherDetailsText
    primaryThumbnailUrl
    orgIds
    organizations {
      _id
      role
      org {
        _id
        primaryThumbnailUrl
        fullName
      }
    }
    orgMembers {
      _id
      role
      contact {
        _id
        primaryThumbnailUrl
        fullName
      }
    }
    householdId
    household {
      _id
      primaryThumbnailUrl
      fullName
    }
    householdMembers {
      _id
      primaryThumbnailUrl
      fullName
    }
    shouldReceiveEmail
    shouldReceivePhysicalMail
    isSubscribed
    isFieldWorker
    isRegionalCoord
    isTrainer
    isPrayerAdopter
    isDonor
    isProjectPartner
    isUpgSponsor
    isAdopted
    isSupportedByProject
    isSustainedByProject
    isSustainedByLocalChurch
    peopleGroupIds
    regionCode
    regionName
    regionalCoordId
    regionalCoord {
      _id
      fullName
    }
    pushPayPayerKeys
    userInvite {
      _id
      roleIds
      roles {
        _id
        name
        label
      }
      regionCode
      contactId
      createdAt
      updatedAt
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const ContactUpdateAttributes = gql`
{
  crud
  id
  new ${ViewContactAttributes}
}
`;

export const contactUpdatesForAdminSubscription = gql`
  subscription contactUpdatesForAdmin($_id: ID!) {
    contactUpdatesForAdmin(_id: $_id) ${ContactUpdateAttributes}
  }
`;

export const contactForAdminQuery = gql`
  query ViewContactForAdmin($_id: ID!) {
    contactForAdmin(_id: $_id) ${ViewContactAttributes}
  }
`;

export const extractContactForAdmin = (data) => data.contactForAdmin;
