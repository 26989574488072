import { ViewHolisticQuarterlyReport as ViewHolisticQuarterlyReportInner } from '@aims/shared/reports';
import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';

import {
  createReportAction,
  deleteReportAction,
  updateReportAction,
} from '@aims/shared/redux-store/reports-store';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { updateHolisticProjectAction } from '../../redux-store/holistic-projects-store';
import {
  extractHolisticProjectForAdmin,
  holisticProjectForAdminQuery,
} from '../holistic-projects/constants';
import {
  extractHolisticQuarterlyReportForAdmin,
  holisticQuarterlyReportForAdminQuery,
  holisticQuarterlyReportUpdatesSubscription,
} from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';
import { useHolisticQuarterlyReportBackLink } from './utils';

const { Text } = Typography;

const queryId = 'viewProgressReport';

function ViewHolisticQuarterlyReport() {
  const history = useHistory();
  const { reportId } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id: reportId },
    subscription: holisticQuarterlyReportUpdatesSubscription,
    createAction: createReportAction,
    updateAction: updateReportAction,
    deleteAction: deleteReportAction,
    extractSomething: (resp) =>
      resp?.data?.holisticQuarterlyReportUpdatesForAdmin,
    queryId,
    skip: !reportId,
  });
  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: holisticQuarterlyReportForAdminQuery,
    extract: extractHolisticQuarterlyReportForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: true,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const { loading: projectLoading } = useOneM1({
    variables: { _id: report?.holisticProjectId },
    query: holisticProjectForAdminQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !report?.holisticProjectId,
    showError: true,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    report?.holisticProjectId,
    queryId,
  );

  const loading = reportLoading || projectLoading;
  const backLink = useHolisticQuarterlyReportBackLink();

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="View Quarterly Holistic Project Report"
      >
        <CSPageHeader
          titleComponent={null}
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
          {report && (
            <>
              <ReviewButtons
                report={report}
                holisticProject={holisticProject}
              />
              <ViewHolisticQuarterlyReportInner
                report={report}
                holisticProject={holisticProject}
              />
            </>
          )}
          {loading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default ViewHolisticQuarterlyReport;
