import PhotoCircle from '@aims/shared/components/PhotoCircle';
import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, List, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import getProjectStatus from '../get-project-status';

const { Text } = Typography;

function SelectProjectListCardO8({ record, onSelect }) {
  const [pressed, setPressed] = useState(false);
  const status = getProjectStatus(record);
  return (
    <List.Item
      style={{ width: '100%' }}
      onClick={() => onSelect(record)}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
    >
      <Card
        bordered={false}
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }}
        styles={{
          body: {
            display: 'flex',
            backgroundColor: pressed ? 'rgba(0, 0, 0, 0.05)' : 'white',
            border: pressed
              ? `1px solid ${sharedSettings.colors.primary}`
              : undefined,
          },
        }}
      >
        <div style={{ marginRight: 32 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
              minWidth: 200,
            }}
          >
            <div style={{ width: 60, marginRight: 8 }}>
              <PhotoCircle
                photoUrl={record.primaryThumbnailUrl}
                name={record.projectName}
                styles={{
                  container: {
                    padding: 0,
                    height: 60,
                    width: 60,
                  },
                  placeholder: {
                    backgroundColor: sharedSettings.colors.contactColor,
                  },
                }}
              />
            </div>
            <div>
              {record.projectNo ? (
                <Text style={{ marginBottom: 0, width: '100%' }}>
                  {record.projectName}
                </Text>
              ) : (
                <Text style={{ marginBottom: 0, width: '100%' }}>
                  {`${record.projectName} #${record.projectNo}`}
                </Text>
              )}
              <br />
              <Text style={{ color: sharedSettings.colors.textGray }}>
                {record.peopleGroupIds.join(', ')}
              </Text>
            </div>
          </div>

          <div style={{ marginBottom: 4 }}>
            <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
              Stage:
            </Text>
            <Tag color={status.color} style={{ marginBottom: 4 }}>
              {HolisticProjectStages[record.stage]?.label}
            </Tag>
          </div>

          <div style={{ marginBottom: 0 }}>
            <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
              Status:
            </Text>
            <Text>{status}</Text>
          </div>
        </div>
        <div style={{ marginRight: 32 }}>
          {record.regionalCoordinator && (
            <>
              <Text style={{ fontWeight: 600, fontSize: 14 }}>
                Project Coordinator
              </Text>
              <br />
              <Text>{record.regionalCoordinator.fullName}</Text>
            </>
          )}
          {/* <br />
          <Text style={{ fontWeight: 600, fontSize: 14 }}>Field Worker</Text>
          <br />
          <Text>{record.fieldWorkerName}</Text>
          <br /> */}
        </div>
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text style={{ fontWeight: 600, fontSize: 14 }}>Loan Amount</Text>
            <br />
            <Text>
              {displayMoney(getNumberValue(record.loanTotalX4 || 0) / 10000)}
            </Text>
          </div>
          <div>
            <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
              Project Type:
            </Text>
            <br />
            <Text>{HolisticProjectTypes[record.typeOfProject]?.label}</Text>
          </div>
        </div>
      </Card>
    </List.Item>
  );
}

export default SelectProjectListCardO8;
