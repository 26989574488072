import sharedSettings from '@aims/shared/sharedSettings';
import { gql } from '@apollo/client';
import humanizeDuration from 'humanize-duration';

export const createTaskMutation = gql`
  mutation createTask($task: TaskCreateInput!) {
    createTask(task: $task) {
      task {
        _id
      }
    }
  }
`;

export const updateTaskMutation = gql`
  mutation updateTask($task: TaskUpdateInput!) {
    updateTask(task: $task) {
      task {
        _id
      }
    }
  }
`;

export const Statuses = [
  { label: 'Todo', value: 'TODO' },
  { label: 'Doing', value: 'DOING' },
  { label: 'Done', value: 'DONE' },
];

export const AllStatuses = [
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Todo', value: 'TODO' },
  { label: 'Doing', value: 'DOING' },
  { label: 'Done', value: 'DONE' },
];

export const StatusColors = {
  SCHEDULED: {
    key: 'SCHEDULED',
    label: 'Scheduled',
    color: sharedSettings.colors.primary,
    sort: 3,
    defaultShown: false,
  },
  TODO: {
    key: 'TODO',
    label: 'Todo',
    color: sharedSettings.colors.primary,
    sort: 2,
    defaultShown: true,
  },
  DOING: {
    key: 'DOING',
    label: 'Doing',
    color: sharedSettings.colors.primary,
    sort: 1,
    defaultShown: true,
  },
  DONE: {
    key: 'DONE',
    label: 'Done',
    color: sharedSettings.colors.primary,
    sort: 4,
    defaultShown: false,
  },
};

export const TaskPriorities = [
  { label: 'Forgetable', value: 'FORGETABLE' },
  { label: 'Low', value: 'LOW' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'High', value: 'HIGH' },
  { label: 'Critical', value: 'CRITICAL' },
];

export const TaskPriorityColors = {
  FORGETABLE: {
    label: 'Forgetable',
    color: '#40a9ff',
  },
  LOW: {
    label: 'Low',
    color: '#36cfc9',
  },
  MEDIUM: {
    label: 'Medium',
    color: '#d4b106',
  },
  HIGH: {
    label: 'High',
    color: '#ffc53d',
  },
  CRITICAL: {
    label: 'Critical',
    color: '#ff7a45',
  },
};

const ONE_DAY = 86400000;

export const UrgencyColors = [
  {
    timeLeft: ONE_DAY * 7,
    color: '#002766',
  },
  {
    timeLeft: ONE_DAY * 4,
    color: '#135200',
  },
  {
    timeLeft: ONE_DAY * 2,
    color: '#d48806',
  },
  {
    timeLeft: ONE_DAY * 1,
    color: '#d46b08',
  },
  {
    timeLeft: 0,
    color: '#a8071a',
  },
];

export function getUrgencyColor(timeLeftMs) {
  for (const element of UrgencyColors) {
    if (timeLeftMs >= element.timeLeft) {
      return element.color;
    }
  }
  return UrgencyColors[UrgencyColors.length - 1].color;
}

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
});

export function getHumanTimeLeft(timeLeftMs) {
  let desc;
  if (timeLeftMs > 0) {
    desc = 'left';
  } else {
    desc = 'overdue';
  }
  return `${shortEnglishHumanizer(timeLeftMs, {
    largest: 2,
    round: true,
  })} ${desc}`;
}

export function getHumanTimeUntil(timeUntilMs) {
  let desc;
  if (timeUntilMs > 0) {
    desc = 'until';
  } else {
    desc = 'ago';
  }
  return `${shortEnglishHumanizer(timeUntilMs, {
    largest: 2,
    round: true,
  })} ${desc}`;
}
