import { InputNumber } from 'antd';
import React, { useCallback, useMemo } from 'react';

function DollarX4InputNumber({
  onChange: parentOnChange,
  value: parentValue,
  ...props
}) {
  const onChange = useCallback(
    (v) => {
      parentOnChange(v * 10000);
    },
    [parentOnChange],
  );
  const value = useMemo(() => {
    return (parentValue || 0) / 10000;
  }, [parentValue]);
  return (
    <InputNumber
      min={0}
      step={1}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

export default DollarX4InputNumber;
