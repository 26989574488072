import { getQuarterLabel } from '@aims/shared/reports/quarters';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { getNumberValue } from '@aims/shared/shared/utils';
import {
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import settings from '../../settings';
import SelectQuarter from '../adoption-stats/SelectQuarter';
import SelectContact from '../contacts/SelectContact';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import sharedSettings from '@aims/shared/sharedSettings';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

const allUpgQuarterlyReportsQuery = gql`
  query AllUpgQuarterlyReports(
    $first: Int
    $after: String
    $filters: UpgQuarterlyReportFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allUpgQuarterlyReportsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          quarter
          peopleGroupId
          peopleGroup {
            _id
            nameAcrossCountries
            country
          }
          regionCode
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser {
            _id
            name
          }
          submittedByUser {
            _id
            name
          }
          updatedBy
          createdAt
          updatedAt
          updatedByUser {
            _id
            name
          }
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          numChurchesPlanted
          numHeardForFirstTime
          numSalvations
          numWaterBaptisms
          miracles {
            _id
            desc
          }
          prayerRequests {
            _id
            desc
          }
        }
      }
    }
  }
`;

const extractUpgQuarterlyReports = (data) =>
  data && data.allUpgQuarterlyReportsForAdmin;

const { Text, Title } = Typography;
const queryId = 'upgQuarterlyTab';

const keyMap = {
  quarter: 'quarter.keyword',
  updatedAt: 'updatedAt',
  peopleGroupId: 'peopleGroupId.keyword',
  numChurchesPlanted: 'numChurchesPlanted',
  numHeardForFirstTime: 'numHeardForFirstTime',
  numSalvations: 'numSalvations',
  numWaterBaptisms: 'numWaterBaptisms',
};

function UpgQuarterlyReportTypeTab({ reportStatus, peopleGroupId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const location = useLocation();

  const [showFilters, setShowFilters] = useState(false);
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({
    status: reportStatus,
    peopleGroupId,
  });

  const {
    error,
    loading,
    data: reports,
    search: reportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgQuarterlyReportsQuery,
    extract: extractUpgQuarterlyReports,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((pages, _, sorters) => {
    setPagination({
      ...pages,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleSortByChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[value], order: sortOrder },
        { key: '_score', order: 'DESC' },
      ];
      setSortedBy(value);
      refetch();
    },
    [sortOrder, refetch],
  );
  const handleSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[sortedBy], order: value },
        { key: '_score', order: 'DESC' },
      ];
      setSortOrder(value);
      refetch();
    },
    [sortedBy, refetch],
  );
  const handleSearch = useCallback(
    (event) => {
      sortBy.current = [
        { key: '_score', order: 'DESC' },
        { key: keyMap[sortedBy], order: sortOrder },
      ];
      reportSearch(event.currentTarget.value);
    },
    [reportSearch, sortOrder, sortedBy],
  );
  const handleQuarterChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        quarter: value,
      };
      refetch();
    },
    [refetch],
  );
  const handlePeopleGroupChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        peopleGroupId: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleFieldWorkerChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        contactId: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);
  return (
    <>
      <div>
        <Form layout="vertical">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            {showFilters && (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                Hide Filters
              </Button>
            )}
            {!showFilters && (
              <Button
                icon={<FilterOutlined />}
                onClick={() => setShowFilters(true)}
              >
                Show Filters
              </Button>
            )}
            <Button icon={<RedoOutlined />}>Refresh</Button>
          </div>
          {showFilters && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Search"
                  style={{ flex: 3, marginRight: 16, minWidth: 200 }}
                >
                  <Input onChange={handleSearch} />
                </Form.Item>
                <Form.Item
                  label="Sort By"
                  style={{ flex: 2, marginRight: 16, minWidth: 200 }}
                >
                  <Select
                    placeholder="Select ..."
                    value={sortedBy}
                    onChange={handleSortByChange}
                  >
                    <Select.Option value="quarter">Quarter</Select.Option>
                    <Select.Option value="updatedAt">
                      Last Updated
                    </Select.Option>
                    <Select.Option value="peopleGroupId">
                      People Group ID
                    </Select.Option>
                    <Select.Option value="numChurchesPlanted">
                      Churches Planted
                    </Select.Option>
                    <Select.Option value="numHeardForFirstTime">
                      Heard the Gospel for the First Time
                    </Select.Option>
                    <Select.Option value="numSalvations">
                      Salvations
                    </Select.Option>
                    <Select.Option value="numWaterBaptisms">
                      Water Baptisms
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Sort Order"
                  style={{ flex: 1, minWidth: 100 }}
                >
                  <Select
                    placeholder="Select ..."
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                  >
                    <Select.Option value="ASC">Ascending</Select.Option>
                    <Select.Option value="DESC">Descending</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <SelectQuarter
                  formItemAttrs={{
                    style: { flex: 1, marginRight: 16, minWidth: 200 },
                    label: 'Quarter',
                  }}
                  loading={loading}
                  required={false}
                  selectAttrs={{
                    allowClear: true,
                    onChange: handleQuarterChange,
                  }}
                />
                {!peopleGroupId && (
                  <Form.Item
                    label="People Group"
                    style={{ flex: 1, minWidth: 200, marginRight: 16 }}
                  >
                    <SelectPeopleGroup
                      disabled={loading}
                      onChange={handlePeopleGroupChange}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label="Field Worker"
                  style={{ flex: 1, minWidth: 200 }}
                >
                  <SelectContact
                    disabled={loading}
                    onChange={handleFieldWorkerChange}
                    filters={{
                      isFieldWorker: true,
                      contactType: 'CONTACT',
                    }}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </div>
      <Table
        dataSource={reports}
        loading={!reports.length && loading}
        pagination={{ ...pagination, total: reports.length }}
        onChange={handleTableChange}
        // style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1000 }}
      >
        <Table.Column
          title="Quarter"
          key="quarter"
          render={(text, record) => {
            return getQuarterLabel(record.quarter);
          }}
        />
        <Table.Column
          title="Field Worker"
          key="contactId"
          render={(text, record) => {
            return (
              record.contact && (
                <Link to={`contact/view/${record.contact._id}`}>
                  {record.contact.fullName}
                </Link>
              )
            );
          }}
        />
        <Table.Column
          title="People Group"
          key="peopleGroupId"
          render={(text, record) => {
            return (
              <Link to={`/people-group/view/${record.peopleGroupId}/info`}>
                {record.peopleGroup && (
                  <>
                    <Text
                      style={{
                        color: sharedSettings.colors.primary,
                        fontWeight: 600,
                      }}
                    >{`${record.peopleGroup.nameAcrossCountries} / ${record.peopleGroup.country}`}</Text>
                    <br />
                  </>
                )}
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  {record.peopleGroupId}
                </Text>
              </Link>
            );
          }}
        />
        <Table.Column
          title="Status"
          key="status"
          render={(text, record) => {
            const status = ReportApprovalStatuses[record.status];
            return (
              status && (
                <Text
                  style={{
                    color: status.color,
                    fontWeight: 600,
                  }}
                >
                  {status.label}
                </Text>
              )
            );
          }}
        />
        <Table.Column
          title="Last Update"
          key="lastUpdate"
          render={(text, record) => {
            if (
              record.status === ReportApprovalStatuses.APPROVED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Approved on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.DRAFT.key) {
              return (
                <>
                  {`Updated on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.updatedAt))}`}
                  {record.updatedByUser && (
                    <>
                      <br />
                      {`by ${record.updatedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.REJECTED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Rejected on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.PENDING.key) {
              return (
                <>
                  {`Submitted on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.submittedAt))}`}
                  {record.submittedByUser && (
                    <>
                      <br />
                      {`by ${record.submittedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.HOLD.key &&
              record.approval
            ) {
              return (
                <>
                  {`Put on hold ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval && (
                    <>
                      <br />
                      {`by ${record.approval.approver?.name}`}
                    </>
                  )}
                </>
              );
            }
            return null;
          }}
        />
        <Table.Column
          title="Report"
          key="report"
          render={(text, record) => {
            return (
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 16 }}>
                  <div>{`Churches Planted: ${getNumberValue(
                    record.numChurchesPlanted,
                  )}`}</div>
                  <div>{`Heard the gospel: ${getNumberValue(
                    record.numHeardForFirstTime,
                  )}`}</div>
                  <div>{`Salvations: ${getNumberValue(
                    record.numSalvations,
                  )}`}</div>
                </div>
                <div>
                  <div>{`Water Baptisms: ${getNumberValue(
                    record.numWaterBaptisms,
                  )}`}</div>
                  <div>{`Miracles: ${getNumberValue(
                    record.miracles && record.miracles.length,
                  )}`}</div>
                  <div>{`Salvations: ${getNumberValue(
                    record.prayerRequests && record.prayerRequests.length,
                  )}`}</div>
                </div>
              </div>
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`/upg-quarterly-reports/review/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="Edit">
                <Link
                  to={`/upg-quarterly-reports/edit/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <style jsx>{`
        .bottom-actions {
          text-align: center;
        }
      `}</style>
    </>
  );
}

export default UpgQuarterlyReportTypeTab;
