import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { useMutation } from '@apollo/client';
import { Form, Modal, notification, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';
import { updateHolisticProjectMutation } from '../../../constants';

function ChangeProjectStageModal({ project, visible, onFinish, onCancel }) {
  const [saving, setSaving] = useState(false);
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              stage: values.stage,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project accounting initiated',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setSaving(false);
    },
    [updateHolisticProject, project, onFinish],
  );

  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        stage: project.stage,
      });
    }
  }, [project, form]);

  return (
    <Modal
      header={null}
      footer={null}
      open={visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={!saving}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 16 } }}
    >
      <TaskSomethingForm
        title="Change Project Stage"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
        saveText="Yes"
      >
        <Form.Item
          label="Project Stage"
          name="stage"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select style={{ width: '100%' }} disabled={saving}>
            {Object.entries(HolisticProjectStages).map(([key, obj]) => (
              <Select.Option key={obj.key} value={obj.key}>
                {obj.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
}

export default ChangeProjectStageModal;
