import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

function useApprovedBy(report) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const _approvedBy = useMemo(() => {
    if (report && report.approval && report.approval.status === 'APPROVED') {
      if (report.approval.approver) {
        return `Approved by ${
          report.approval.approver.name
        } on ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
        }).format(new Date(report.approval.when))}`;
      }
      return `Approved on ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'long',
      }).format(new Date(report.approval.when))}`;
    } else if (
      report &&
      report.approval &&
      report.approval.status === 'REJECTED'
    ) {
      if (report.approval.approver) {
        return `Rejected by ${
          report.approval.approver.name
        } on ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
        }).format(new Date(report.approval.when))}`;
      }
      return `Rejected on ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'long',
      }).format(new Date(report.approval.when))}`;
    }
    return null;
  }, [report, locale]);
  return _approvedBy;
}

export default useApprovedBy;
