import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';

const reportFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    adminNotes
    events {
      _id
      city
      country
      host
      date
      numPeopleTrained
    }
    miracles {
      _id
      desc
    }
    prayerRequests {
      _id
      desc
    }
  }
`;

export const trainingQuarterlyReportQuery = gql`
  query TrainingQuarterlyReportForAdmin($_id: ID!) {
    trainingQuarterlyReportForAdmin(_id: $_id) ${reportFullAttributes}
  }
`;

export const extractTrainingQuarterlyReport = (data) =>
  data && data.trainingQuarterlyReportForAdmin;

const TrainingQuarterlyReportUpdateAttributes = gql`
{
  crud
  id
  new ${reportFullAttributes}
}
`;

export const trainingQuarterlyReportUpdatesSubscription = gql`
  subscription TrainingQuarterlyReportUpdatesForAdmin($_id: ID!) {
    trainingQuarterlyReportUpdatesForAdmin(_id: $_id) ${TrainingQuarterlyReportUpdateAttributes}
  }
`;

export const createTrainingQuarterlyReportForAdminMutation = gql`
  mutation CreateTrainingQuarterlyReportForAdmin(
    $report: TrainingQuarterlyReportCreateInputForAdmin!
  ) {
    createTrainingQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateTrainingQuarterlyReportForAdminMutation = gql`
  mutation UpdateTrainingQuarterlyReportForAdmin(
    $report: TrainingQuarterlyReportUpdateInputForAdmin!
  ) {
    updateTrainingQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitTrainingQuarterlyReportForAdminMutation = gql`
  mutation SubmitTrainingQuarterlyReportForAdmin($reportId: ID!) {
    submitTrainingQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const unsubmitTrainingQuarterlyReportForAdminMutation = gql`
  mutation unsubmitTrainingQuarterlyReportForAdmin($reportId: ID!) {
    unsubmitTrainingQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const reviewTrainingQuarterlyReportMutation = gql`
  mutation reviewTrainingQuarterlyReport(
    $reportId: ID!
    $status: ReportApprovalStatus!
  ) {
    reviewTrainingQuarterlyReport(reportId: $reportId, status: $status)
  }
`;

export const deleteTrainingQuarterlyReportForAdminMutation = gql`
  mutation DeleteTrainingQuarterlyReportForAdmin($_id: ID!) {
    deleteTrainingQuarterlyReportForAdmin(_id: $_id) {
      deletedId
    }
  }
`;
