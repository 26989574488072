export const READ_PRAYER_REQUESTS = 'READ_PRAYER_REQUESTS';
export const CREATE_PRAYER_REQUEST = 'CREATE_PRAYER_REQUEST';
export const UPDATE_PRAYER_REQUEST = 'UPDATE_PRAYER_REQUEST';
export const DELETE_PRAYER_REQUEST = 'DELETE_PRAYER_REQUEST';

// eslint-disable-next-line no-shadow
export const readPrayerRequestsAction = (prayerRequests) => ({
  type: READ_PRAYER_REQUESTS,
  payload: {
    prayerRequests,
  },
});

export const createPrayerRequestAction = (prayerRequest) => ({
  type: CREATE_PRAYER_REQUEST,
  payload: {
    prayerRequest,
  },
});

export const updatePrayerRequestAction = (prayerRequest) => ({
  type: UPDATE_PRAYER_REQUEST,
  payload: {
    prayerRequest,
  },
});

export const deletePrayerRequestAction = (prayerRequestId) => ({
  type: DELETE_PRAYER_REQUEST,
  payload: {
    prayerRequestId,
  },
});

const initialPrayerRequests = [];

export function prayerRequests(state = initialPrayerRequests, action) {
  switch (action.type) {
    case READ_PRAYER_REQUESTS: {
      const newState = { ...state };
      action.payload.prayerRequests.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_PRAYER_REQUEST:
      return {
        ...state,
        [action.payload.prayerRequest._id]: action.payload.prayerRequest,
      };
    case UPDATE_PRAYER_REQUEST: {
      const current = state[action.payload.prayerRequest._id] || undefined;
      return {
        ...state,
        [action.payload.prayerRequest._id]: {
          ...current,
          ...action.payload.prayerRequest,
        },
      };
    }
    case DELETE_PRAYER_REQUEST: {
      const { prayerRequestId } = action.payload;
      return { ...state, [prayerRequestId]: undefined };
    }
    default:
      return state;
  }
}
