import {
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import settings from '../../settings';

import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import ReportInfoItem from '@aims/shared/reports/common/ReportInfoItem';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import { extractAllHolisticQuarterlyReportsForAdmin } from './constants';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

const allHolisticQuarterlyReportsForAdminQuery = gql`
  query AllHolisticQuarterlyReportsForAdmin(
    $first: Int
    $after: String
    $filters: HolisticQuarterlyReportFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allHolisticQuarterlyReportsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          quarter
          regionCode
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser {
            _id
            name
          }
          submittedByUser {
            _id
            name
          }
          updatedBy
          createdAt
          updatedAt
          updatedByUser {
            _id
            name
          }
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          holisticProjectId
          holisticProject {
            _id
            projectName
            typeOfProject
          }
          projectCoordId
          projectCoord {
            _id
            fullName
          }
          currency
          exchangeRate
          projectState
          hasReceivedFunds
          loadPaymentsThisQuarterUsd
          loanPaymentsTotalUsd
          loanTotal
          loanTerm
          expensesThisQuarterForeign
          revenueThisQuarterForeign
          supportedFieldWorkers {
            amountForeign
            contactId
            name
          }
          miracles {
            _id
            desc
          }
          prayerRequests {
            _id
            desc
          }
        }
      }
    }
  }
`;

const { Text } = Typography;

const keyMap = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  submittedAt: 'submittedAt',
  typeOfProject: 'typeOfProject.keyword',
  projectName: 'projectName.keyword',
  loanTotalUsd: 'loanTotalUsd',
};

const queryId = 'holisticQuarterlyReportTabs';

function HolisticQuarterlyReportsTypeTab({ reportStatus, holisticProjectId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const location = useLocation();

  const [showFilters, setShowFilters] = useState(false);
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    status: reportStatus,
    holisticProjectId,
  });

  const {
    error,
    loading,
    data: reports,
    search: reportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allHolisticQuarterlyReportsForAdminQuery,
    extract: extractAllHolisticQuarterlyReportsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((pages, _, sorters) => {
    setPagination({
      ...pages,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const getFinances = (report) => {
    const exchangeRate = getNumberValue(report && report.exchangeRate);
    if (exchangeRate > 0) {
      const supportToFieldWorkers = (report.supportedFieldWorkers || []).reduce(
        (prev, curr) => {
          return prev + getNumberValue(curr && curr.amountForeign);
        },
        0,
      );
      return {
        expenses:
          getNumberValue(report.expensesThisQuarterForeign) / exchangeRate,
        revenue:
          getNumberValue(report.revenueThisQuarterForeign) / exchangeRate,
        loanPayments: getNumberValue(report.loadPaymentsThisQuarterUsd),
        toFieldWorkers: supportToFieldWorkers / exchangeRate,
        received: report.hasReceivedFunds,
      };
    }
    return {};
  };

  const handleSortByChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[value], order: sortOrder },
        { key: '_score', order: 'DESC' },
      ];
      setSortedBy(value);
      refetch();
    },
    [sortOrder, refetch],
  );
  const handleSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[sortedBy], order: value },
        { key: '_score', order: 'DESC' },
      ];
      setSortOrder(value);
      refetch();
    },
    [sortedBy, refetch],
  );
  const handleSearch = useCallback(
    (event) => {
      sortBy.current = [
        { key: '_score', order: 'DESC' },
        { key: keyMap[sortedBy], order: sortOrder },
      ];
      reportSearch(event.currentTarget.value);
    },
    [reportSearch, sortOrder, sortedBy],
  );
  const handlePeopleGroupChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        peopleGroupId: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleIsApprovedChange = useCallback(
    (e) => {
      filters.current = {
        ...filters.current,
        isApproved: e.target.checked ? true : null,
      };
      refetch();
    },
    [refetch],
  );
  const handleQuarterChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        quarter: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);
  return (
    <>
      <div>
        <Form layout="vertical">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            {showFilters && (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setShowFilters(false);
                }}
              >
                Hide Filters
              </Button>
            )}
            {!showFilters && (
              <Button
                icon={<FilterOutlined />}
                onClick={() => setShowFilters(true)}
              >
                Show Filters
              </Button>
            )}
            <Button icon={<RedoOutlined />}>Refresh</Button>
          </div>
          {showFilters && (
            <>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Search"
                  style={{ flex: 3, marginRight: 16, minWidth: 200 }}
                >
                  <Input onChange={handleSearch} />
                </Form.Item>
                <Form.Item
                  label="People Group"
                  style={{ flex: 1, minWidth: 200, marginRight: 16 }}
                >
                  <SelectPeopleGroup
                    disabled={loading}
                    onChange={handlePeopleGroupChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Sort By"
                  style={{ flex: 2, marginRight: 16, minWidth: 200 }}
                >
                  <Select
                    placeholder="Select ..."
                    value={sortedBy}
                    onChange={handleSortByChange}
                    disabled={loading}
                  >
                    <Select.Option value="updatedAt">
                      Last Updated
                    </Select.Option>
                    <Select.Option value="createdAt">
                      Created Date
                    </Select.Option>
                    <Select.Option value="submittedAt">
                      Submitted Date
                    </Select.Option>
                    <Select.Option value="typeOfProject">
                      Type of Project
                    </Select.Option>
                    <Select.Option value="projectName">
                      Project Name
                    </Select.Option>
                    <Select.Option value="loanTotalUsd">
                      Loan Total
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Sort Order"
                  style={{ flex: 1, minWidth: 100 }}
                >
                  <Select
                    placeholder="Select ..."
                    value={sortOrder}
                    onChange={handleSortOrderChange}
                    disabled={loading}
                  >
                    <Select.Option value="ASC">Ascending</Select.Option>
                    <Select.Option value="DESC">Descending</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Quarter"
                  style={{ flex: 1, minWidth: 200, marginRight: 16 }}
                >
                  <Select
                    placeholder="Select ..."
                    onChange={handleQuarterChange}
                    disabled={loading}
                    allowClear
                  >
                    <Select.Option value="Q1">Q1</Select.Option>
                    <Select.Option value="Q2">Q2</Select.Option>
                    <Select.Option value="Q3">Q3</Select.Option>
                    <Select.Option value="Q4">Q4</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Is Approved?"
                  style={{ flex: 1, minWidth: 200, marginRight: 16 }}
                >
                  <Checkbox
                    disabled={loading}
                    onChange={handleIsApprovedChange}
                  >
                    Is Approved
                  </Checkbox>
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </div>
      <Table
        dataSource={reports}
        loading={!reports.length && loading}
        pagination={{ ...pagination, total: reports.length }}
        onChange={handleTableChange}
        // style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
      >
        <Table.Column
          title="Quarter"
          key="quarter"
          render={(text, record) => getQuarterLabel(record.quarter)}
        />
        <Table.Column
          title="Project Name"
          key="projectName"
          render={(text, record) => {
            return record.holisticProject && record.holisticProject.projectName;
          }}
        />
        <Table.Column
          title="Type of Project"
          key="typeOfProject"
          render={(text, record) => {
            const type =
              record.holisticProject &&
              record.holisticProject.typeOfProject &&
              HolisticProjectTypes[record.holisticProject.typeOfProject];
            return type && type.label;
          }}
        />
        <Table.Column
          title="Status"
          key="status"
          render={(text, record) => {
            const status = ReportApprovalStatuses[record.status];
            return (
              status && (
                <Text
                  style={{
                    color: status.color,
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                  }}
                >
                  {status.label}
                </Text>
              )
            );
          }}
        />
        <Table.Column
          title="Last Update"
          key="lastUpdate"
          render={(text, record) => {
            if (
              record.status === ReportApprovalStatuses.APPROVED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Approved on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.DRAFT.key) {
              return (
                <>
                  {`Updated on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.updatedAt))}`}
                  {record.updatedByUser && (
                    <>
                      <br />
                      {`by ${record.updatedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.REJECTED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Rejected on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.PENDING.key) {
              return (
                <>
                  {`Submitted on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.submittedAt))}`}
                  {record.submittedByUser && (
                    <>
                      <br />
                      {`by ${record.submittedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.HOLD.key &&
              record.approval
            ) {
              return (
                <>
                  {`Put on hold ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.createdByUser && (
                    <>
                      <br />
                      {`by ${record.createdByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            return null;
          }}
        />
        <Table.Column
          title="Loan Funds Received"
          key="loanFundsReceived"
          render={(text, record) => {
            const finances = getFinances(record);
            return finances.received ? 'Yes' : 'No';
          }}
        />
        <Table.Column
          title="Financial Update"
          key="financialUpdate"
          width={300}
          render={(text, record) => {
            const finances = getFinances(record);
            return (
              <div>
                <ReportInfoItem
                  label="Loan funds received"
                  value={finances.received ? 'Yes' : 'No'}
                />
                <ReportInfoItem
                  label="Loan payments made"
                  value={displayMoney(finances.loanPayments)}
                />
                <ReportInfoItem
                  label="Quarterly Revenue"
                  value={displayMoney(finances.revenue)}
                />
                <ReportInfoItem
                  label="Quarterly Expenses"
                  value={displayMoney(finances.expenses)}
                />
                <ReportInfoItem
                  label="Support to Field Workers"
                  value={displayMoney(finances.toFieldWorkers)}
                />
              </div>
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`/holistic-quarterly-reports/review/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="Edit">
                <Link
                  to={`/holistic-quarterly-reports/edit/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <style jsx>{`
        .bottom-actions {
          text-align: center;
        }
        .info {
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
          max-width: 600px;
        }
      `}</style>
    </>
  );
}

export default HolisticQuarterlyReportsTypeTab;
