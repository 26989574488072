import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { generateUuid } from '../../shared/utils';
import HolisticProposalTabs from './HolisticProposalTabs';

function ListHolisticProjectProposalsPage() {
  const holisticProjectId = useMemo(() => {
    return generateUuid();
  }, []);
  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Holistic Project Proposals"
      >
        <CSPageHeader
          title="Holistic Project Proposals"
          topActions={[
            <Link
              key="add"
              to={{
                pathname: `/holistic-proposals/edit/${holisticProjectId}`,
                state: {
                  mode: 'create',
                },
              }}
            >
              <Button type="link" icon={<PlusCircleOutlined />} size="large">
                Create a holistic project proposal
              </Button>
            </Link>,
          ]}
        />
        <HolisticProposalTabs />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default ListHolisticProjectProposalsPage;
