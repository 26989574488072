import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Skeleton, Typography } from 'antd';
import React from 'react';

const { Text, Title } = Typography;

function BalanceDisplay({ account, style = {} }) {
  if (!account) {
    return (
      <div
        style={{
          display: 'flex',
          paddingLeft: '10vw',
          paddingRight: '10vw',
          marginRight: -16,
          marginLeft: -16,
          marginBottom: 16,
        }}
      >
        <div style={{ marginRight: 16, marginLeft: 16, flex: 1 }}>
          <Skeleton active />
        </div>
        <div style={{ marginRight: 16, marginLeft: 16, flex: 1 }}>
          <Skeleton active />
        </div>
        <div style={{ marginRight: 16, marginLeft: 16, flex: 1 }}>
          <Skeleton active />
        </div>
      </div>
    );
  }
  return (
    <div className="container" style={style}>
      <div className="section">
        <div>
          <Text style={{ fontSize: 14, color: sharedSettings.colors.textGray }}>
            Total in
          </Text>
        </div>
        <Title level={3}>
          {account.balance && displayMoney(account.balance.incoming / 10000)}
        </Title>
      </div>
      <div className="section">
        <div>
          <Text style={{ fontSize: 18, color: sharedSettings.colors.textGray }}>
            Balance
          </Text>
        </div>
        <Title>
          {account.balance && displayMoney(account.balance.balance / 10000)}
        </Title>
      </div>
      <div className="section">
        <div>
          <Text style={{ fontSize: 14, color: sharedSettings.colors.textGray }}>
            Total out
          </Text>
        </div>
        <Title level={3}>
          {account.balance && displayMoney(account.balance.outgoing / 10000)}
        </Title>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          margin-bottom: 16px;
          justify-content: center;
          padding-left: 10vw;
          padding-right: 10vw;
          align-items: center;
        }
        .section {
          text-align: center;
          margin-left: 16px;
          margin-right: 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default BalanceDisplay;
