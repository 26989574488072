import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, List, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { AdoptionLevels, AdoptionStatuses } from '../constants';
import PhotoCircle from '@aims/shared/components/PhotoCircle';

const { Text } = Typography;

function SelectAdoptionListCardA10({ record, onSelect }) {
  const pg = record.peopleGroup;
  const status = record.status && AdoptionStatuses[record.status];
  const sponsors =
    record.sponsors && record.sponsors.map((s) => s.fullName).join(', ');
  const level =
    record &&
    AdoptionLevels[record.level] &&
    `${AdoptionLevels[record.level].label} @ ${displayMoney(
      record.amount / 10000,
    )} / mo`;
  const [pressed, setPressed] = useState(false);
  return (
    <List.Item
      style={{ width: '100%' }}
      onClick={() => onSelect(record)}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onMouseLeave={() => setPressed(false)}
    >
      <Card
        bordered={false}
        style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)' }}
        styles={{
          body: {
            display: 'flex',
            backgroundColor: pressed ? 'rgba(0, 0, 0, 0.05)' : 'white',
            border: pressed
              ? `1px solid ${sharedSettings.colors.primary}`
              : undefined,
          },
        }}
      >
        <div style={{ marginRight: 32 }}>
          {pg && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 8,
                minWidth: 200,
              }}
            >
              <div style={{ width: 60, marginRight: 8 }}>
                <PhotoCircle
                  photoUrl={pg && pg.primaryThumbnailUrl}
                  name={pg ? pg.nameAcrossCountries : 'PG'}
                  styles={{
                    container: {
                      padding: 0,
                      height: 60,
                      width: 60,
                    },
                    placeholder: {
                      backgroundColor: sharedSettings.colors.yellow,
                    },
                  }}
                />
              </div>
              <div>
                <Text
                  style={{ marginBottom: 0, width: '100%' }}
                >{`${pg.nameAcrossCountries} / ${pg.country}`}</Text>
                <br />
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  {pg._id}
                </Text>
              </div>
            </div>
          )}
          {status && (
            <div style={{ marginBottom: 4 }}>
              <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
                Status:
              </Text>
              <Tag color={status.color} style={{ marginBottom: 4 }}>
                {status.label}
              </Tag>
            </div>
          )}
          {level && (
            <div style={{ marginBottom: 0 }}>
              <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
                Level:
              </Text>
              <Text>{level}</Text>
            </div>
          )}
        </div>
        <div style={{ marginRight: 32 }}>
          <Text style={{ fontWeight: 600, fontSize: 14 }}>
            Regional Coordinator
          </Text>
          <br />
          <Text>{record.regionalCoordName}</Text>
          <br />
          <Text style={{ fontWeight: 600, fontSize: 14 }}>Field Worker</Text>
          <br />
          <Text>{record.fieldWorkerName}</Text>
          <br />
        </div>
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text style={{ fontWeight: 600, fontSize: 14 }}>Sponsors</Text>
            <br />
            {sponsors && sponsors.length ? (
              <Text>{sponsors}</Text>
            ) : (
              <Text>No Sponsors</Text>
            )}
          </div>
          <div>
            <Text style={{ fontWeight: 600, fontSize: 14, marginRight: 8 }}>
              Tags:
            </Text>
            {record.tags &&
              record.tags.map((tag) => (
                <Tag
                  key={tag.key}
                  color={sharedSettings.colors.primary}
                  style={{ marginBottom: 4 }}
                >
                  {tag.key}
                </Tag>
              ))}
          </div>
        </div>
      </Card>
    </List.Item>
  );
}

export default SelectAdoptionListCardA10;
