import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactFormItems from '../../EditContact/form-items';
import { generateUuid } from '@aims/shared/shared/utils';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactAddEmailInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.resetFields();
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingEmails = (contact.emails || []).reduce((prev, curr) => {
        const { email, _id, label } = curr;
        prev.push({ email, _id, label });
        return prev;
      }, []);
      const { email, label, makePrimary } = values;
      const existingPrimaryEmailid = contact.primaryEmailId;
      const newId = generateUuid();
      await handleFinish({
        emails: [
          ...existingEmails,
          {
            _id: newId,
            email,
            label,
          },
        ].sort((a, b) => a.email.localeCompare(b.email)),
        primaryEmailId: makePrimary ? newId : existingPrimaryEmailid,
      });
    },
    [handleFinish, contact],
  );
  return (
    <ContactSomethingForm
      title="Add Email"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
        extra="e.g., Work, Home ..."
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactAddEmailInnerModalA10;
