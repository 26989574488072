import { shallowEqual, useSelector } from 'react-redux';

function useSingleSomethingA10(table, _id, queryId = 'default') {
  const something = useSelector(
    (store) =>
      store[table] && store[table][queryId] && store[table][queryId][_id],
    shallowEqual,
  );
  return something;
}

export default useSingleSomethingA10;
