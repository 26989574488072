import { generateUuid } from '@aims/shared/shared/utils';
import { gql, useMutation } from '@apollo/client';
import { Form, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import EditAccountFormItems from './components/EditAccountFormItems';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

export const createAccountMutation = gql`
  mutation CreateAccount($account: AccountCreateInput!) {
    createAccount(account: $account) {
      account {
        _id
      }
    }
  }
`;

const AccountCreateModal = ({ visible, onFinish, onCancel }) => {
  const [saving, setSaving] = useState(false);
  const [createAccount] = useMutation(createAccountMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await createAccount({
          variables: {
            account: {
              _id: generateUuid(),
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Account created successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setSaving(false);
    },
    [createAccount, onFinish],
  );

  const [form] = Form.useForm();

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={oncancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Create Account"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <EditAccountFormItems />
      </TaskSomethingForm>
    </Modal>
  );
};

export default AccountCreateModal;
