import { gql } from '@apollo/client';

export const extractPeopleGroupForAdmin = (data) =>
  data && data.peopleGroupForAdmin;

export const privatePeopleGroupListAttributes = gql`
  {
    _id
    population
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    affinityBloc
    affinityBlocId
    peopleCluster
    peopleClusterId
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    primaryLanguageId
    officialLanguage
    officialLanguageId
    bibleStatus
    primaryReligion
    primaryReligionId
    religionSubdivision
    religionSubdivisionId
    jpScaleId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    latlon
    summary
    summaryText
    primaryThumbnailUrl
    focusLevelNo
    aimsStatusAdopted
    aimsStatusCoordinator
    aimsStatusFieldWorker
    aimsStatusFunding
    aimsStatusHolisticProject
    aimsStatusSalvations
    aimsStatusChurches
    aimsStatusMovements
    aimsStatusPercent
    numAdopters
    numAdoptionsAvailable
    numRegionalCoords
    numFieldWorkers
    numHolisticProjects
    suggestedAdoptionLevel
    createdAt
    updatedAt
    deletedAt
    cumulativeStats {
      receivedForHimself
      receivedForHolistic
      receivedTotal
      numChurchesPlanted
      numHeardForFirstTime
      numSalvations
      numWaterBaptisms
      numMiraclesReported
    }
  }
`;

export const allPeopleGroupsForAdminQuery = gql`
  query AllPeopleGroups(
    $first: Int
    $after: String
    $filters: PeopleGroupFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allPeopleGroupsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${privatePeopleGroupListAttributes}
      }
    }
  }
`;

export const extractAllPeopleGroupsForAdmin = (data) =>
  data && data.allPeopleGroupsForAdmin;

export const updatePeopleGroupMutation = gql`
  mutation updatePeopleGroup($peopleGroup: PeopleGroupUpdateInput!) {
    updatePeopleGroup(peopleGroup: $peopleGroup) {
      peopleGroup {
        _id
      }
    }
  }
`;

export const updatePeopleGroupPhotosMutation = gql`
  mutation UpdatePeopleGroupPhotos($peopleGroup: PeopleGroupUpdateInput!) {
    updatePeopleGroup(peopleGroup: $peopleGroup) {
      peopleGroup {
        _id
      }
    }
  }
`;
