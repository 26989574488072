import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { updateUpgPrayerAdoptionMutation } from './constants';

function ChangeSomethingModalUpgPA({
  adoption,
  children,
  btnProps,
  btnText,
  queryId,
}) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateUpgPrayerAdoption] = useMutation(
    updateUpgPrayerAdoptionMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateUpgPrayerAdoption({
          variables: {
            upgPrayerAdoption: {
              _id: adoption._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'UPG Prayer Adoption updated successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error updating your UPG Prayer Adoption',
        });
      }
      setSaving(false);
    },
    [adoption, updateUpgPrayerAdoption, onDone],
  );
  return (
    <>
      <Modal
        visible={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        {React.cloneElement(children, {
          adoption,
          handleFinish,
          saving,
          onCancel,
        })}
      </Modal>
      <Button
        type="text"
        icon={<EditOutlined />}
        onClick={handleEdit}
        {...btnProps}
      >
        {btnText}
      </Button>
    </>
  );
}

export default ChangeSomethingModalUpgPA;
