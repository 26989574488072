import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import settings from '../../../settings';
import { AdoptionLevels, AdoptionStatuses } from '../constants';
import ListAdoptionsFiltersA10 from './ListAdoptionsFiltersA10';

const { Text } = Typography;

function ListAdoptionsA10({
  loading,
  error,
  adoptions,
  next,
  hasNextPage,
  refetch,
  onFiltersChanged,
  onSortedByChange,
  onSortOrderChange,
  hideSearch,
  hideFilters,
}) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  });

  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {hideSearch ? (
          <div style={{ flex: 1, height: 64 }} />
        ) : (
          <ListAdoptionsFiltersA10
            onFiltersChanged={onFiltersChanged}
            hideFilters={hideFilters}
          />
        )}
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={adoptions}
        loading={!adoptions.length && loading}
        pagination={{ ...pagination, total: adoptions.length }}
        onChange={(params) =>
          setPagination({
            ...params.pagination,
          })
        }
        style={{ width: '100%' }}
        scroll={{ x: 1400 }}
        rowKey="_id"
      >
        <Table.Column
          title="People Group"
          dataIndex="peopleGroup"
          render={(text, record) => {
            const pg = record.peopleGroup;
            return (
              pg && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 60, marginRight: 8 }}>
                    <PhotoCircle
                      photoUrl={pg && pg.primaryThumbnailUrl}
                      name={pg ? pg.nameAcrossCountries : 'PG'}
                      customSize={60}
                      color={sharedSettings.colors.contactColor}
                      style={{
                        padding: 0,
                        height: 60,
                        width: 60,
                      }}
                    />
                  </div>
                  <Link to={`/people-group/view/${pg._id}/info`}>
                    <div className="row1" style={{ whiteSpace: 'nowrap' }}>
                      <Text
                        style={{
                          marginBottom: 0,
                          width: '100%',
                        }}
                      >{`${pg.nameAcrossCountries} / ${pg.country}`}</Text>
                    </div>
                    <div className="right">
                      <Text style={{ color: sharedSettings.colors.textGray }}>
                        {pg._id}
                      </Text>
                    </div>
                  </Link>
                </div>
              )
            );
          }}
        />
        <Table.Column
          title="Tags"
          dataIndex="tags"
          render={(text, record) => {
            return (
              record.tags &&
              record.tags.map((tag) => (
                <Tag
                  key={tag.key}
                  color={sharedSettings.colors.primary}
                  style={{ marginTop: 10 }}
                >
                  {tag.key}
                </Tag>
              ))
            );
          }}
        />
        <Table.Column
          title="Level"
          dataIndex="level"
          render={(text, record) => {
            const level =
              record &&
              AdoptionLevels[record.level] &&
              `${AdoptionLevels[record.level].label} @ ${displayMoney(
                record.amount / 10000,
              )} / mo`;
            return level;
          }}
        />
        <Table.Column
          title="Regional Coordinator"
          dataIndex="regionalCoord"
          render={(text, record) => {
            return record.regionalCoordName;
          }}
        />
        <Table.Column
          title="Field Worker"
          dataIndex="fieldWorker"
          render={(text, record) => {
            return record.fieldWorkerName;
          }}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          render={(text, record) => {
            const status = record.status && AdoptionStatuses[record.status];
            return status && <Tag color={status.color}>{status.label}</Tag>;
          }}
        />
        <Table.Column
          title="Sponsors"
          dataIndex="sponsors"
          render={(text, record) => {
            return record.sponsorNames && record.sponsorNames.join(', ');
          }}
        />
        <Table.Column
          title="First & Last Payment"
          dataIndex="payments"
          render={(text, record) => {
            return `${
              record.firstPaymentDate
                ? dateFormat.format(new Date(record.firstPaymentDate))
                : 'Not Specified'
            } / ${
              record.lastPaymentDate
                ? dateFormat.format(new Date(record.lastPaymentDate))
                : 'Not Specified'
            }`;
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Link to={`/adoption/view/${record._id}`}>
                <Tooltip title="View">
                  <Button icon={<EyeOutlined />} />
                </Tooltip>
              </Link>
            </Space>
          )}
        />
      </Table>
      {hasNextPage && (
        <div style={{ marginTop: 16 }}>
          <Button onClick={() => next()}>Load More</Button>
        </div>
      )}
    </>
  );
}

export default ListAdoptionsA10;
