export const READ_PAYOUTS = 'READ_PAYOUTS';
export const CREATE_PAYOUT = 'CREATE_PAYOUT';
export const UPDATE_PAYOUT = 'UPDATE_PAYOUT';
export const DELETE_PAYOUT = 'DELETE_PAYOUT';

// eslint-disable-next-line no-shadow
export const readPayoutsAction = (payouts) => ({
  type: READ_PAYOUTS,
  payload: {
    payouts,
  },
});

export const createPayoutAction = (payout) => ({
  type: CREATE_PAYOUT,
  payload: {
    payout,
  },
});

export const updatePayoutAction = (payout) => ({
  type: UPDATE_PAYOUT,
  payload: {
    payout,
  },
});

export const deletePayoutAction = (payoutId) => ({
  type: DELETE_PAYOUT,
  payload: {
    payoutId,
  },
});

const initialPayouts = [];

export function payouts(state = initialPayouts, action) {
  switch (action.type) {
    case READ_PAYOUTS: {
      const newState = { ...state };
      action.payload.payouts.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_PAYOUT:
      return {
        ...state,
        [action.payload.payout._id]: action.payload.payout,
      };
    case UPDATE_PAYOUT: {
      const current = state[action.payload.payout._id] || undefined;
      return {
        ...state,
        [action.payload.payout._id]: {
          ...current,
          ...action.payload.payout,
        },
      };
    }
    case DELETE_PAYOUT: {
      const { payoutId } = action.payload;
      return { ...state, [payoutId]: undefined };
    }
    default:
      return state;
  }
}
