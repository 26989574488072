import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { deleteMouAction } from '../../redux-store/mous-store';
import { deleteMouMutation } from './constants';

function DeleteMouTemplateModal({ mouTemplate, onFinish, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteMou] = useMutation(deleteMouMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteMou({
        variables: {
          _id: mouTemplate._id,
        },
      });
      dispatch(deleteMouAction(mouTemplate._id));
      notification.success({
        message: 'Deleted',
        description:
          'Partnership Commitment Form Template deleted successfully',
      });
      setTimeout(() => {
        onFinish();
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [mouTemplate, deleteMou, dispatch, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete Partnership Commitment Form Template"
      question={`Are you sure you want to delete this Partnership Commitment Form template (${
        mouTemplate && mouTemplate.name
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!mouTemplate}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteMouTemplateModal;
