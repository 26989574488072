import sharedSettings from '@aims/shared/sharedSettings';
import { gql, useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import useProfile from '../../shared/use-profile';
import useSubscribeToEvent from './use-subscribe-to-event';

export const unsubscribeFromMutation = gql`
  mutation UnsubscribeFromEvent($eventId: ID!) {
    unsubscribeFromEvent(eventId: $eventId)
  }
`;

function SubscribeToEventButton({ eventId, queryId }) {
  const subscribeToEvent = useSubscribeToEvent();
  const [unsubscribeFrom] = useMutation(unsubscribeFromMutation);
  const profile = useProfile();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const subscribed = useMemo(() => {
    if (profile.subscribedTo) {
      return profile.subscribedTo.includes(`event:${eventId}`);
    }
  }, [profile, eventId]);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      if (subscribed) {
        const response = await unsubscribeFrom({
          variables: {
            eventId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Unsubscribed',
        });
      } else {
        await subscribeToEvent(eventId, queryId);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [eventId, unsubscribeFrom, subscribed, queryId, subscribeToEvent]);

  return (
    <Button
      danger={!!error}
      type="text"
      onClick={handleClick}
      style={{ color: !error ? sharedSettings.colors.textGray : undefined }}
      loading={loading}
    >
      {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  );
}

export default SubscribeToEventButton;
