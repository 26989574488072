import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

const { Text } = Typography;

export const allJPReligionSubdivisionsQuery = gql`
  query AllJPReligionSubdivisions {
    allJPReligionSubdivisions {
      _id
      name
      primaryReligionId
    }
  }
`;

function SelectReligionSubdivision({ disabled, value, onChange, filters }) {
  const { loading, error, data } = useQuery(allJPReligionSubdivisionsQuery);
  const religionSubdivisions = useMemo(() => {
    let _data = [];
    if (data) {
      _data = data.allJPReligionSubdivisions;
      if (filters && filters.primaryReligionId) {
        _data = _data.filter(
          (d) => d.primaryReligionId === filters.primaryReligionId,
        );
      }
    }
    return _data;
  }, [data, filters]);
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a religion subdivision"
      value={value}
      allowClear
      loading={loading}
    >
      {religionSubdivisions.map((religionSubdivision) => (
        <Select.Option
          key={religionSubdivision._id}
          value={religionSubdivision._id}
          title={religionSubdivision.name}
        >
          <div className="religionSubdivision-option">
            <div>
              <Text>{religionSubdivision.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectReligionSubdivision;
