import { Button, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const { Title } = Typography;

const YesNoModalJ10 = ({
  title,
  yesText,
  yesIcon,
  noText,
  onYes,
  onNo,
  visible,
  loading = false,
  closable = true,
  destroyOnClose = true,
  maskClosable = true,
  children,
}) => {
  return (
    <Modal
      open={visible}
      closable={closable}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      onCancel={onNo}
      footer={null}
      header={null}
      styles={{ body: { textAlign: 'center' } }}
    >
      <Title style={{ marginTop: 16 }} level={2}>
        {title}
      </Title>
      {children}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 24,
          marginBottom: 16,
        }}
      >
        {noText && (
          <Button onClick={onNo} disabled={loading} style={{ marginRight: 16 }}>
            {noText}
          </Button>
        )}
        {yesText && (
          <Button
            type="primary"
            loading={loading}
            onClick={onYes}
            icon={yesIcon}
          >
            {yesText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default YesNoModalJ10;
