import { Form } from 'antd';
import React from 'react';
import { useMutation } from '@apollo/client';
import {
  extractGetAttachmentUploadUrl,
  getAttachmentUploadUrlMutation,
  saveProfilePhotoMutation,
} from './constants';
import ProfilePhotoInnerJ24 from '@aims/shared/profile-photo/ProfilePhotoInnerJ24';

/* existing = { _id, thumbnailUrl } */

function ProfilePhotoFormItemJ24({
  name,
  label,
  rules,
  styles,
  existing,
  parentType,
  parentId,
  tags,
  saving,
  setSaving,
}) {
  const [getAttachmentMutation] = useMutation(getAttachmentUploadUrlMutation);
  const [saveProfilePhotoForAdmin] = useMutation(saveProfilePhotoMutation);
  return (
    <Form.Item name={name} label={label} rules={rules} style={styles?.formItem}>
      <ProfilePhotoInnerJ24
        existing={existing}
        parentType={parentType}
        parentId={parentId}
        getAttachmentMutation={getAttachmentMutation}
        extractGetAttachment={extractGetAttachmentUploadUrl}
        savePhotoMutation={saveProfilePhotoForAdmin}
        tags={tags}
        saving={saving}
        setSaving={setSaving}
      />
    </Form.Item>
  );
}

export default ProfilePhotoFormItemJ24;
