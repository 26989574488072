import sharedSettings from '@aims/shared/sharedSettings';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, List, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generateUuid } from '../../shared/utils';
import AttachmentFormItem from './AttachmentFormItem';
import { AgreementStatuses } from './constants';

const { Paragraph, Title, Text } = Typography;

function SingleAttachment({
  editing,
  attachment,
  doSave,
  loading,
  error,
  doEdit,
  doCancel,
  doDelete,
  parentId,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      _id: attachment._id,
      ref: attachment.ref,
      name: attachment.name,
      fileIds: attachment.fileId ? [attachment.fileId] : [],
    });
  }, [attachment, form]);
  const retrieveAttachments = useRef();
  return (
    <>
      {editing ? (
        <Card>
          <Form form={form} layout="vertical" onFinish={doSave}>
            <Form.Item name="_id" initialValue={attachment._id} noStyle>
              <Input type="hidden" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="ref"
              label="Attachment Reference"
              initialValue={attachment.ref}
              rules={[{ required: 'This field is required' }]}
              extra="This is the ID used to reference an attachments name in the body of the agreement"
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Attachment Name"
              name="name"
              rules={[{ required: 'This field is required' }]}
              extra="ex: Addendum, Repayment Plan ..."
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item label="File">
              <AttachmentFormItem
                existingAttachments={attachment.file ? [attachment.file] : []}
                parentId={parentId}
                parentType={'loan-agreement'}
                retrieveAttachments={retrieveAttachments}
                readOnly={false}
                disabled={loading}
                name="fileIds"
                form={form}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  htmlType="button"
                  onClick={() => doCancel(attachment._id)}
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Form.Item>
            {error && <Paragraph type="danger">{error}</Paragraph>}
          </Form>
        </Card>
      ) : (
        <Card
          styles={{
            body: { display: 'flex', justifyContent: 'space-between' },
          }}
        >
          <div style={{ flex: 1 }}>
            <div>
              <Text style={{ color: sharedSettings.colors.primary }}>Name</Text>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 14 }}>
                {attachment.name || 'No Name'}
              </Text>
            </div>
            <div>
              <Text style={{ color: sharedSettings.colors.primary }}>
                Reference
              </Text>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 14 }}>
                {attachment.ref ? `{{${attachment.ref}}}` : 'No Reference'}
              </Text>
            </div>
            <div>
              {attachment.file ? (
                <Form layout="vertical">
                  <Form.Item label="File">
                    <AttachmentFormItem
                      existingAttachments={
                        attachment.file ? [attachment.file] : []
                      }
                      parentId={parentId}
                      parentType={'loan-agreement'}
                      retrieveAttachments={retrieveAttachments}
                      readOnly
                      name="fileIds"
                    />
                  </Form.Item>
                </Form>
              ) : (
                <Text style={{ fontSize: 14 }}>No File</Text>
              )}
            </div>
          </div>
          <div>
            <Button
              onClick={() => doEdit(attachment._id)}
              icon={<EditOutlined />}
              type="text"
            >
              Edit
            </Button>
            <Button
              onClick={() => doDelete(attachment._id)}
              icon={<DeleteOutlined />}
              type="text"
            >
              Delete
            </Button>
          </div>
        </Card>
      )}
    </>
  );
}

function ReadOnlyAttachment({ attachment, parentId }) {
  const retrieveAttachments = useRef();
  return (
    <Card>
      <div
        style={{
          fontSize: 18,
          color: sharedSettings.colors.primary,
          marginBottom: 8,
        }}
      >
        {attachment.name}
      </div>
      <div>
        {attachment.file ? (
          <Form layout="vertical">
            <Form.Item>
              <AttachmentFormItem
                existingAttachments={attachment.file ? [attachment.file] : []}
                parentId={parentId}
                parentType={'loan-agreement'}
                retrieveAttachments={retrieveAttachments}
                readOnly
                name="fileIds"
              />
            </Form.Item>
          </Form>
        ) : (
          <Text style={{ fontSize: 14 }}>No File</Text>
        )}
      </div>
    </Card>
  );
}

function AttachmentsElement({ loanAgreement, loanAgreementId, handleSave }) {
  const [editing, setEditing] = useState({});
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [attachments, setAttachments] = useState([]);
  const doSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const _attachments = [
          ...((loanAgreement && loanAgreement.attachments) || []).filter(
            (p) => p._id !== values._id,
          ),
          values,
        ].map((p) => ({
          _id: p._id,
          ref: p.ref,
          name: p.name,
          fileId:
            p.fileIds && p.fileIds.length ? p.fileIds[0] : p.fileId || null,
        }));
        const unique = [...new Set(_attachments.map((p) => p.ref))];
        if (unique.length !== _attachments.length) {
          throw new Error('Attachment references must be unique.');
        }
        handleSave({
          attachments: _attachments,
        });
        setAttachments(_attachments);
        setEditing({});
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  useEffect(() => {
    setAttachments((loanAgreement && loanAgreement.attachments) || []);
  }, [loanAgreement, form]);

  const handleAddAttachment = useCallback(() => {
    const attachmentId = generateUuid();
    setEditing({ ...editing, [attachmentId]: true });
    setAttachments([
      ...attachments,
      { _id: attachmentId, ref: `attachmentNo${attachments.length + 1}` },
    ]);
  }, [attachments, editing]);

  const handleEdit = useCallback(
    (attachmentId) => {
      setEditing({ ...editing, [attachmentId]: true });
    },
    [editing],
  );
  const handleCancelEdit = useCallback(
    (attachmentId) => {
      setEditing({ ...editing, [attachmentId]: false });
    },
    [editing],
  );
  const doDelete = useCallback(
    async (attachmentId) => {
      setLoading(true);
      setError(undefined);
      const _attachments = (loanAgreement.attachments || [])
        .filter((p) => p._id !== attachmentId)
        .map((p) => ({
          _id: p._id,
          name: p.name,
        }));
      handleSave({
        attachments: _attachments,
      });
      setAttachments(_attachments);
      setEditing({});
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  return (
    <>
      <div className="line-heading">
        <Title level={2}>Attachments</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={attachments}
        renderItem={(attachment) => {
          return (
            <List.Item>
              {(!loanAgreement ||
                loanAgreement.status === AgreementStatuses.PREPARING.key ||
                loanAgreement.isTemplate) && (
                <SingleAttachment
                  attachment={attachment}
                  editing={editing[attachment._id]}
                  doSave={doSave}
                  loading={loading}
                  doEdit={handleEdit}
                  doCancel={handleCancelEdit}
                  doDelete={doDelete}
                  error={error}
                  parentId={loanAgreementId}
                />
              )}
              {loanAgreement &&
                loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                !loanAgreement.isTemplate && (
                  <ReadOnlyAttachment
                    attachment={attachment}
                    parentId={loanAgreementId}
                  />
                )}
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Attachments can be reference in the body of this agreement`}
              </Paragraph>
              {(!loanAgreement ||
                loanAgreement.status === AgreementStatuses.PREPARING.key) && (
                <div>
                  <Button type="primary" onClick={handleAddAttachment}>
                    Add Attachment
                  </Button>
                </div>
              )}
            </>
          ),
        }}
        rowKey="_id"
      />
      {attachments &&
        attachments.length > 0 &&
        (!loanAgreement ||
          loanAgreement.status === AgreementStatuses.PREPARING.key) && (
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={handleAddAttachment}>
              Add Another Attachment
            </Button>
          </div>
        )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default AttachmentsElement;
