import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactFormItems from '../../EditContact/form-items';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactOtherDetailsInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        otherDetails: contact.otherDetails,
      });
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (_values) => {
      const otherDetails = ContactFormItems.otherDetails.finish(_values);
      handleFinish({
        ...otherDetails,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Edit Other Details"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <ContactFormItems.otherDetails.FormItem loading={saving} />
    </ContactSomethingForm>
  );
}

export default ContactOtherDetailsInnerModalA10;
