import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { publicFormQuery } from './constants';

function usePublicForm(_id) {
  const result = useQuery(publicFormQuery, {
    variables: {
      _id,
    },
  });
  const publicForm = useMemo(() => {
    if (result && result.data && result.data.publicForm) {
      let formData;
      try {
        formData =
          result.data.publicForm.formJson &&
          JSON.parse(result.data.publicForm.formJson);
      } catch (err) {
        formData = undefined;
      }
      return {
        _id: result.data.publicForm._id,
        formType: result.data.publicForm.formType,
        ...formData,
        createdAt: result.data.publicForm.createdAt,
      };
    }
    return undefined;
  }, [result]);
  return {
    loading: result.loading,
    error: result.error,
    publicForm,
  };
}

export default usePublicForm;
