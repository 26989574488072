import sharedSettings from '@aims/shared/sharedSettings';
import { gql, useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useProfile from '../../shared/use-profile';

export const subscribeToMutation = gql`
  mutation SubscribeToContact($contactId: ID!) {
    subscribeToContact(contactId: $contactId)
  }
`;

export const unsubscribeFromMutation = gql`
  mutation UnsubscribeFromContact($contactId: ID!) {
    unsubscribeFromContact(contactId: $contactId)
  }
`;

function SubscribeToContactButton({ contactId, queryId }) {
  const [subscribeTo] = useMutation(subscribeToMutation);
  const [unsubscribeFrom] = useMutation(unsubscribeFromMutation);
  const profile = useProfile();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const subscribed = useMemo(() => {
    if (profile.subscribedTo) {
      return profile.subscribedTo.includes(`contact:${contactId}`);
    }
  }, [profile, contactId]);

  const dispatch = useDispatch();

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      if (subscribed) {
        await unsubscribeFrom({
          variables: {
            contactId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Unsubscribed',
        });
      } else {
        await subscribeTo({
          variables: {
            contactId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Subscribed',
        });
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [contactId, unsubscribeFrom, subscribeTo, subscribed]);

  return (
    <Button
      danger={!!error}
      type="text"
      onClick={handleClick}
      style={{ color: !error ? sharedSettings.colors.textGray : undefined }}
      loading={loading}
    >
      {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  );
}

export default SubscribeToContactButton;
