import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useCallback, useRef } from 'react';

function PayoutV2Filters({
  filtersRef,
  refetch,
  search,
  sortByRef,
  parentFilters,
  sortedBy,
  sortOrder,
  more,
}) {
  const filterChangeTimeout = useRef();

  const onFiltersChanged = useCallback(
    (_changed, values) => {
      const { search: searchTerm, ...changed } = _changed;
      if (searchTerm != undefined) {
        sortByRef.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        search(searchTerm.toLowerCase());
      } else {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
          ...parentFilters,
        };
        sortByRef.current = [
          {
            key: sortedBy,
            order: sortOrder,
          },
          { key: '_score', order: 'DESC' },
        ];
        clearTimeout(filterChangeTimeout.current);
        filterChangeTimeout.current = setTimeout(() => {
          refetch?.();
        }, 500);
      }
    },
    [
      search,
      refetch,
      sortedBy,
      sortOrder,
      filtersRef,
      sortByRef,
      parentFilters,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        style={{ marginBottom: 16 }}
        onValuesChange={onFiltersChanged}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 16,
          }}
        >
          <Form.Item
            name="search"
            style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
          >
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
          <div style={{ flex: 1 }} />
          {more}
          {refetch && (
            <Button icon={<RedoOutlined />} onClick={refetch}>
              Refresh
            </Button>
          )}
        </div>
      </Form>
    </>
  );
}

export default PayoutV2Filters;
