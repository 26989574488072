import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import YesNoModal from '../../components/YesNoModal';
import {
  deleteContactAction,
  updateContactAction,
} from '../../redux-store/contacts-store';
import { removeContactFromHouseholdMutation } from '../households/constants';
import { removeContactFromOrganizationMutation } from '../organizations/constants';
import { ContactTypes } from './constants';

const deleteContactMutation = gql`
  mutation deleteContact($_id: ID!) {
    deleteContact(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteContactModal({ contact, setContact, queryId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteContact] = useMutation(deleteContactMutation);
  const dispatch = useDispatch();
  const history = useHistory();
  const [removeContactFromOrganization] = useMutation(
    removeContactFromOrganizationMutation,
  );
  const [removeContactFromHousehold] = useMutation(
    removeContactFromHouseholdMutation,
  );

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const contactId = contact._id;
      if (contact.organizations) {
        for (let i = 0; i < contact.organizations.length; i++) {
          const org = contact.organizations[i];
          const response = await removeContactFromOrganization({
            variables: {
              contactId,
              orgId: org.orgId,
            },
          });
          const updatedOrg =
            response.data &&
            response.data.removeContactFromOrganization &&
            response.data.removeContactFromOrganization.organization;
          notification.success({
            message: 'Success',
            description: 'Contact removed from organization',
          });
          dispatch(updateContactAction(updatedOrg));
        }
      }

      if (contact.household) {
        const response = await removeContactFromHousehold({
          variables: {
            contactId,
            householdId: contact.household.householdId,
          },
        });
        const updatedHousehold =
          response.data &&
          response.data.removeContactFromHousehold &&
          response.data.removeContactFromHousehold.household;
        notification.success({
          message: 'Success',
          description: 'Contact removed from household',
        });
        dispatch(updateContactAction(updatedHousehold));
      }

      const householdId = contact._id;
      if (contact.householdMembers) {
        for (let i = 0; i < contact.householdMembers.length; i += 1) {
          const member = contact.householdMembers[i];
          const response = await removeContactFromHousehold({
            variables: {
              contactId: member.contactId,
              householdId,
            },
          });
          const updatedContact =
            response.data &&
            response.data.removeContactFromHousehold &&
            response.data.removeContactFromHousehold.contact;
          notification.success({
            message: 'Success',
            description: 'Contact removed from household',
          });
          dispatch(
            updateContactAction({
              queryId,
              ...updatedContact,
            }),
          );
        }
      }

      const orgId = contact._id;
      if (contact.orgMembers) {
        for (let i = 0; i < contact.orgMembers.length; i += 1) {
          const member = contact.orgMembers[i];
          const response = await removeContactFromOrganization({
            variables: {
              contactId: member.contactId,
              orgId,
            },
          });
          const updatedContact =
            response.data &&
            response.data.removeContactFromOrganization &&
            response.data.removeContactFromOrganization.contact;
          notification.success({
            message: 'Success',
            description: 'Contact removed from organization',
          });
          dispatch(
            updateContactAction({
              queryId,
              ...updatedContact,
            }),
          );
        }
      }

      await deleteContact({
        variables: {
          _id: contactId,
        },
      });
      dispatch(deleteContactAction(contactId));
      notification.success({
        message: 'Deleted',
        description: `${
          ContactTypes[contact.contactType].label
        } deleted successfully`,
      });
      setTimeout(() => {
        setContact(null);
        history.push('/people');
      }, 1000);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  }, [
    contact,
    deleteContact,
    setContact,
    dispatch,
    history,
    removeContactFromHousehold,
    removeContactFromOrganization,
    queryId,
  ]);

  const onNo = useCallback(() => {
    if (!loading) {
      setContact(null);
    }
  }, [setContact, loading]);

  return (
    <YesNoModal
      title="Delete Contact"
      question={`Are you sure you want to delete this contact (${
        contact && contact.fullName
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!contact}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteContactModal;
