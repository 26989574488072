import { gql } from '@apollo/client';

export const PpPayerListAttributes = gql`
  {
    _id
    emailAddress
    emailAddressVerified
    mobileNumber
    mobileNumberVerified
    fullName
    firstName
    lastName
    address {
      country
      line1
      line2
      city
      state
      zipOrPostCode
    }
    ppUpdatedOn
    role
    payerType
    contactId
    contact {
      _id
      fullName
      contactType
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const allPpPayersQuery = gql`
  query AllPpPayersForAdmin($first: Int, $after: String, $filters: PayerFiltersForAdmin, $sortBy: [SortBy]) {
    allPpPayersForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PpPayerListAttributes}
      }
    }
  }
`;

export const extractAllPayers = (data) => data && data.allPpPayersForAdmin;

export const ppPayerQuery = gql`
  query PpPayerForAdmin($_id: ID!) {
    ppPayerForAdmin(_id: $_id)  ${PpPayerListAttributes}
  }
`;

export const extractPayer = (data) => data.ppPayerForAdmin;
