import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  Typography,
} from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import ContentEditableInput, {
  serializeText,
} from '../../../components/ContentEditableInput';
import { updatePeopleGroupAction } from '../../../redux-store/people-groups-store';
import { isValidJSON } from '../../../shared/utils';
import { PeopleGroupFocusLevels } from './constants';
import { updatePeopleGroupMutation } from '../constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Text } = Typography;

function EditPeopleGroupForm({
  _id,
  handleCancel,
  handleSuccess,
  peopleGroup,
  saving,
  setSaving,
  style,
  queryId,
  fieldsToSet = {},
}) {
  const [error, setError] = useState(null);
  const [updatePeopleGroup] = useMutation(updatePeopleGroupMutation);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (peopleGroup) {
      form.setFieldsValue(peopleGroup);
    }
  }, [peopleGroup, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      setError(null);
      try {
        const {
          summary,
          focusLevelNo,
          aimsStatusAdopted,
          aimsStatusCoordinator,
          aimsStatusFieldWorker,
          aimsStatusFunding,
          aimsStatusHolisticProject,
          aimsStatusSalvations,
          aimsStatusChurches,
          aimsStatusMovements,
        } = _values;
        if (peopleGroup) {
          const result = await updatePeopleGroup({
            variables: {
              peopleGroup: {
                _id,
                focusLevelNo,
                aimsStatusAdopted,
                aimsStatusCoordinator,
                aimsStatusFieldWorker,
                aimsStatusFunding,
                aimsStatusHolisticProject,
                aimsStatusSalvations,
                aimsStatusChurches,
                aimsStatusMovements,
                summary,
                summaryText: summary && serializeText(JSON.parse(summary)),
                ...fieldsToSet,
              },
            },
          });
          dispatch(
            updatePeopleGroupAction(
              result.data.updatePeopleGroup.peopleGroup,
              queryId,
            ),
          );
          notification.success({
            message: 'Saved',
            description: 'PeopleGroup updated successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        setError(err.message);
      }
      setSaving(false);
    },
    [
      peopleGroup,
      _id,
      updatePeopleGroup,
      dispatch,
      setSaving,
      fieldsToSet,
      handleSuccess,
      queryId,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="editPeopleGroupForm"
        form={form}
        style={style}
      >
        <Form.Item label="People Group ID" name="peopleGroupId">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Country" name="country">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Name" name="nameAcrossCountries">
          <Input disabled />
        </Form.Item>
        {peopleGroup && peopleGroup.jpSummary && (
          <Form.Item>
            <div>{peopleGroup.jpSummary}</div>
          </Form.Item>
        )}
        <Form.Item label="Summary" name="summary">
          <ContentEditableInput disabled={saving} style={{ minHeight: 100 }} />
        </Form.Item>
        <Form.Item
          label="Focus Level"
          name="focusLevelNo"
          rules={[{ required: true, message: 'Please select a focus level' }]}
        >
          <Select>
            {Object.entries(PeopleGroupFocusLevels).map(
              ([level, { label, key }]) => (
                <Select.Option key={key} value={key}>
                  {label}
                </Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Divider>AIMS Status</Divider>
        <Form.Item
          name="aimsStatusAdopted"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Is Sponsored</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusCoordinator"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Has Coordinator</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusFieldWorker"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Has Field Worker</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusFunding"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Has Sponsorship Funding</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusHolisticProject"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Has a Holistic Project</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusSalvations"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Reported Salvations &amp; Baptisms</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusChurches"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Churches Planted</Checkbox>
        </Form.Item>
        <Form.Item
          name="aimsStatusMovements"
          valuePropName="checked"
          className="my-checkbox"
        >
          <Checkbox>Movements</Checkbox>
        </Form.Item>
        <Divider />
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              key="cancel"
              onClick={handleCancel}
              htmlType="button"
              type="text"
              size="small"
              disabled={saving}
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: -7 }}
            >
              Cancel
            </Button>
            <Button key="send" type="primary" saving={saving} htmlType="submit">
              Save People Group
            </Button>
          </Space>
        </Form.Item>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Form>

      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
        .send-invite-checkbox {
          flex-shrink: 0;
          margin-left: 8px;
        }
        .note {
          margin-bottom: 16px;
          display: flex;
          width: 100%;
        }
        .inner-note {
          margin-right: 8px;
          flex: 1;
        }
      `}</style>
      <style global jsx>{`
        .my-checkbox .ant-form-item-control-input {
          min-height: unset;
        }
      `}</style>
    </>
  );
}

export default EditPeopleGroupForm;
