import { useCallback, useRef, useState } from 'react';

function useSafeStateA10(initial) {
  const _data = useRef(initial);
  const [data, _setData] = useState(_data.current);
  const setData = useCallback((value) => {
    _data.current = value;
    _setData(value);
  }, []);
  return [data, setData, _data];
}

export default useSafeStateA10;
