import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useUser from '../pages/users/use-user';
import settings from '../settings';

const { Text } = Typography;

const AssigneeColors = {};

function AssigneeDot({ _id }) {
  const { user, loading } = useUser(_id);
  const [color, setColor] = useState();
  useEffect(() => {
    if (AssigneeColors[_id]) {
      setColor(AssigneeColors[_id]);
    } else {
      const _color =
        settings.tagColors[
          Math.floor(Math.random() * settings.tagColors.length)
        ];
      AssigneeColors[_id] = _color;
      setColor(_color);
    }
  }, [_id]);
  return (
    <>
      {user &&
        user.profilePhoto &&
        user.profilePhoto.thumbnail &&
        user.profilePhoto.thumbnail.url && (
          <Tooltip title={user.name}>
            <img
              className="profile-photo"
              src={user.profilePhoto.thumbnail.url}
            />
          </Tooltip>
        )}
      {user &&
        !(
          user.profilePhoto &&
          user.profilePhoto.thumbnail &&
          user.profilePhoto.thumbnail.url
        ) && (
          <Tooltip title={user.name}>
            <div
              style={{ backgroundColor: color }}
              className="photo-placeholder"
            >
              {user.name[0].toUpperCase()}
            </div>
          </Tooltip>
        )}
      {!user && loading && (
        <div style={{ backgroundColor: color }} className="photo-placeholder">
          <LoadingOutlined />
        </div>
      )}
      <style jsx>{`
        .photo-placeholder {
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 16px;
          font-weight: 300;
          border-radius: 100%;
          overflow: hidden;
        }
        .profile-photo {
          height: 50px;
          width: 50px;
          border-radius: 100%;
          overflow: hidden;
        }
      `}</style>
    </>
  );
}

export default AssigneeDot;
