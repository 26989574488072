import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import settings from '../../../settings';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function HProjectChangeUpgsInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Edit Unreached People Groups',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        peopleGroups: (holisticProject.peopleGroupIds || []).map((id) => ({
          peopleGroupId: id,
        })),
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { peopleGroups, ...values } = _values;
      const peopleGroupIds = (peopleGroups || []).map((p) => p.peopleGroupId);
      handleFinish({
        peopleGroupIds,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item label="Which people groups will be affected by this project?">
        <Form.List name="peopleGroups">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  style={{ marginBottom: 0 }}
                  shouldUpdate={(prev, curr) =>
                    prev.onBehalfOf !== curr.onBehalfOf
                  }
                >
                  {({ getFieldValue }) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        label={`People Group ${index + 1}`}
                        name={[field.name, 'peopleGroupId']}
                        style={{ width: '100%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select a people group',
                          },
                        ]}
                      >
                        <SelectPeopleGroup
                          settings={settings}
                          disabled={saving}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  )}
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add people group
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeUpgsInner;
