import { Form, Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../pages/tasks/ViewTaskModal/TaskSomethingForm';

function ThingChangeTextAreaInner({
  thing,
  saving,
  handleFinish,
  onCancel,
  title,
  label,
  name,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (thing) {
      form.setFieldsValue({
        [name]: thing[name],
      });
    }
  }, [thing, form, name]);
  const _handleFinish = useCallback(
    (values) => {
      handleFinish({
        [name]: values[name],
        ...values,
      });
    },
    [handleFinish, name],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Input.TextArea disabled={saving} rows={4} />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default ThingChangeTextAreaInner;
