import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import { generateUuid } from '../../shared/utils';
import CreateMouFromTemplateModal from './CreateMouFromTemplateModal';
import ListMous from './ListMous';
import { allMousForAdminQuery, extractMousForAdmin } from './constants';

const queryId = 'mousPage';

function ListMousPage() {
  const createId = useMemo(() => {
    return generateUuid();
  }, []);

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: false,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: mous,
    search: mousSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allMousForAdminQuery,
    extract: extractMousForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        mousSearch(search);
      } else {
        filters.current = {
          isTemplate: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [mousSearch, refetch, sortedBy, sortOrder],
  );

  const history = useHistory();
  const [creating, setCreating] = useState();
  const handleCreate = useCallback(() => {
    setCreating({
      createId,
      defaultVersionNo: 1,
    });
  }, [createId]);
  const handleCancelCreate = useCallback(() => {
    setCreating(false);
  }, []);
  const handleCreateFinish = useCallback(() => {
    history.push(`/mous/view/${createId}`);
  }, [history, createId]);

  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Partnership Commitment Form Templates"
      >
        <CSPageHeader
          title="Partnership Commitment Forms"
          topActions={[
            <Button
              key="create"
              type="text"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={handleCreate}
            >
              Create a Partnership Commitment Form
            </Button>,
          ]}
        />
        <ListMous
          loading={loading}
          error={error}
          mous={mous}
          next={next}
          hasNextPage={hasNextPage}
          refetch={refetch}
          isTemplate={false}
          onFiltersChanged={onFiltersChanged}
          onSortedByChange={onSortedByChange}
          onSortOrderChange={onSortOrderChange}
        />
      </CSPage>
      <CreateMouFromTemplateModal
        visible={creating}
        onFinish={handleCreateFinish}
        onCancel={handleCancelCreate}
      />
      <style jsx>{``}</style>
    </>
  );
}

export default ListMousPage;
