import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Select } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import settings from '../../settings';
import {
  AdoptionLevels,
  allAdoptionsQuery,
  extractAdoptions,
} from './constants';

const queryId = 'selectAdoption';

function SelectAdoption({ disabled, value, onChange, filters, style }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: adoptions,
    search: adoptionSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allAdoptionsQuery,
    extract: extractAdoptions,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId,
  });

  const options = useMemo(() => {
    if (adoptions) {
      return adoptions.map((item) => {
        const level = AdoptionLevels[item.level];
        const sponsorNames =
          item.sponsorNames && item.sponsorNames.length > 0
            ? item.sponsorNames.join(', ')
            : 'No Sponsor';
        const title = `${sponsorNames} (${
          (level && level.label) || 'No Level'
        }) / ${item.fieldWorkerName || 'No Field Worker'} (${
          item.peopleGroupId || 'No People Group'
        })`;
        return {
          value: item._id,
          label: title,
          title,
        };
      });
    }
    return [];
  }, [adoptions]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a project"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => adoptionSearch(v)}
      loading={loading}
      disabled={disabled}
    />
  );
}

export default SelectAdoption;
