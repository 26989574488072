import { BankOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo, useState } from 'react';
import InitiateAccountingModal from './InitiateAccountingModal';
import useProfile from '../../../../../shared/use-profile';

function InitializeAccountingBtn({ project }) {
  const [showModal, setShowModal] = useState();

  const profile = useProfile();
  const permissions = profile?.permissions;
  const hasPermission = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'create-admin-account');
  }, [permissions]);

  const showInitiateBtn = useMemo(() => {
    return (
      project &&
      (!project.projectNo ||
        !project.fundingAccountId ||
        !project.paybackAccountId ||
        !project.fundingTarget ||
        !project.payoutAccountId)
    );
  }, [project]);

  return (
    <>
      {project && showInitiateBtn && hasPermission && (
        <>
          <div
            style={{
              marginBottom: 32,
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              icon={<BankOutlined />}
              onClick={() => setShowModal(true)}
            >
              Initiate Holistic Project Accounting
            </Button>
          </div>
          <InitiateAccountingModal
            showingModal={showModal}
            setShowingModal={setShowModal}
            project={project}
          />
        </>
      )}
    </>
  );
}

export default InitializeAccountingBtn;
