import React, { useMemo } from 'react';
import { PrayerRequestResults } from '@aims/shared/prayer-requests/constants';
import { Select, Tag } from 'antd';

function SelectPrayerRequestResult({ value, onChange, disabled, style }) {
  const options = useMemo(
    () =>
      Object.values(PrayerRequestResults).map((item) => ({
        ...item,
        value: item.key,
      })),
    [],
  );
  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a result"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      disabled={disabled}
      optionRender={(option) => (
        <div>
          <Tag color={option.data.color}>{option.label}</Tag>
        </div>
      )}
    />
  );
}

export default SelectPrayerRequestResult;
