import { Upload, Button, notification, Progress, Typography, Card } from 'antd';
import React, { useState, useCallback } from 'react';
import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';
import {
  getAttachmentUploadUrlForAdminMutation,
  saveAttachmentForAdminMutation,
} from './constants';
import { useMutation } from '@apollo/client';
import { doFileUpload } from '@aims/shared/shared/utils';
import { PlusOutlined } from '@ant-design/icons';
import EditAttachmentCard from './EditAttachmentCard';

const { Text } = Typography;

function EditAttachments({
  attachments,
  parentId,
  onUploadComplete,
  onDeleteAttachment,
  getUploadIds,
}) {
  const [uploadProgress, setUploadProgress] = useState();
  const [saving, setSaving] = useState(false);

  const [getAttachmentUploadUrlForAdmin] = useMutation(
    getAttachmentUploadUrlForAdminMutation,
  );
  const [saveAttachmentForAdmin] = useMutation(saveAttachmentForAdminMutation);

  const doUpload = useCallback(
    async (obj) => {
      try {
        setSaving(true);
        let [attachmentId, s3Key, tags] = getUploadIds();

        let response = await getAttachmentUploadUrlForAdmin({
          variables: {
            attachment: {
              _id: attachmentId,
              s3Key,
            },
          },
        });
        if (!response.data?.getAttachmentUploadUrlForAdmin) {
          throw new Error('Failed to get upload URL');
        }
        setUploadProgress(0);
        await doFileUpload({
          url: response.data.getAttachmentUploadUrlForAdmin.url,
          fields: response.data.getAttachmentUploadUrlForAdmin.fields,
          file: obj.file,
          onProgress: (event) => {
            setUploadProgress((event.loaded / event.total) * 100);
          },
        });
        setTimeout(() => {
          setUploadProgress(undefined);
        }, 250);

        response = await saveAttachmentForAdmin({
          variables: {
            attachment: {
              _id: attachmentId,
              filename: obj.file.name,
              s3Key,
              attachedTo: parentId,
              tags,
              uploaded: true,
            },
          },
        });
        if (onUploadComplete) {
          await onUploadComplete({
            _id: attachmentId,
            filename: obj.file.name,
            size: obj.file.size,
          });
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error uploading your attachment.',
        });
      }
      setSaving(false);
    },
    [
      parentId,
      saveAttachmentForAdmin,
      setUploadProgress,
      getAttachmentUploadUrlForAdmin,
      getUploadIds,
      onUploadComplete,
    ],
  );

  const [deleting, setDeleting, _deleting] = useSafeStateA10({});
  const deleteAttachment = useCallback(
    async (attachmentId) => {
      setDeleting({
        ..._deleting,
        [attachmentId]: true,
      });
      try {
        await onDeleteAttachment(attachmentId);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error deleting your attachment.',
        });
      }
      setDeleting({
        ..._deleting,
        [attachmentId]: undefined,
      });
    },
    [_deleting, onDeleteAttachment, setDeleting],
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {attachments.map((attachment) => (
          <EditAttachmentCard
            key={attachment._id}
            fileName={attachment.filename}
            fileSize={attachment.size}
            thumbnailUrl={
              (attachment.thumbnail && attachment.thumbnail.url) ||
              attachment.base64Url
            }
            onDelete={() => deleteAttachment(attachment._id)}
            deleting={deleting[attachment._id]}
          />
        ))}
      </div>
      <Upload
        showUploadList={false}
        listType="picture"
        customRequest={doUpload}
      >
        {saving ? (
          <Card styles={{ body: { padding: '8px 16px' } }}>
            <Progress
              type="circle"
              percent={uploadProgress?.toFixed(0)}
              size={48}
              style={{ marginRight: 8 }}
            />
            <Text>Saving ...</Text>
          </Card>
        ) : (
          <Button disabled={saving} icon={<PlusOutlined />}>
            Attach
          </Button>
        )}
      </Upload>
    </div>
  );
}

export default EditAttachments;
