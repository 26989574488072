import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const allJPReligionsQuery = gql`
  query AllJPReligions {
    allJPReligions {
      _id
      name
    }
  }
`;

function SelectReligion({ disabled, value, onChange }) {
  const { loading, error, data } = useQuery(allJPReligionsQuery);
  const religions = useMemo(() => {
    if (data) {
      return data.allJPReligions;
    }
    return [];
  }, [data]);
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a religion"
      value={value}
      allowClear
      loading={loading}
    >
      {religions.map((religion) => (
        <Select.Option
          key={religion._id}
          value={religion._id}
          title={religion.name}
        >
          <div className="religion-option">
            <div>
              <Text>{religion.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectReligion;
