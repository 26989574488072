import { useMutation } from '@apollo/client';
import {
  Checkbox,
  Form,
  InputNumber,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';
import { initiateHolisticProjectAccountingMutation } from './constants';
import { updateHolisticProjectMutation } from '../../../constants';

const { Paragraph, Title } = Typography;

function InitiateAccountingModal({ project, showingModal, setShowingModal }) {
  const [loading, setLoading] = useState(false);
  const [initiate] = useMutation(initiateHolisticProjectAccountingMutation);
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  useEffect(() => {
    if (showingModal) {
      form.setFieldsValue({
        loanTotalX4: project.loanTotalX4,
        loanTerm: project.loanTerm,
        paybackTotalX4: project.paybackTotalX4,
      });
    } else {
      form.resetFields();
    }
  }, [form, project, showingModal]);

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const {
          loanTerm,
          loanTotalX4,
          paybackTotalX4,
          projectNo,
          hasProjectNo,
        } = values;
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              loanTotalX4,
              loanTerm,
              paybackTotalX4,
            },
          },
        });
        await initiate({
          variables: {
            _id: project._id,
            projectNo: hasProjectNo ? projectNo : undefined,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project accounting initiated',
        });
        setShowingModal(false);
      } catch (err) {
        console.error(err);
        let desc = 'We apologize.  An error occurred';
        if (err.message.includes('Another project has already')) {
          desc = err.message;
        }
        notification.error({
          message: 'Error',
          description: desc,
        });
      }
      setLoading(false);
    },
    [initiate, project, setShowingModal, updateHolisticProject],
  );

  return (
    <Modal
      header={null}
      footer={null}
      open={showingModal}
      closable={!loading}
      destroyOnClose={true}
      maskClosable={!loading}
      onCancel={() => {
        setShowingModal(false);
      }}
      styles={{ body: { paddingTop: 16 } }}
    >
      <TaskSomethingForm
        title="Initiate Holistic Project Accounting"
        form={form}
        onFinish={handleFinish}
        onCancel={() => {
          setShowingModal(false);
        }}
        saving={loading}
        saveText="Yes"
        titleStyle={{ textAlign: 'center' }}
      >
        <Form.Item>
          <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
            {`Please verify the following fields`}
          </Paragraph>
        </Form.Item>
        <Form.Item
          label="Loan Total (USD)"
          name="loanTotalX4"
          initialValue={0}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DollarX4InputNumber disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Loan Term"
          name="loanTerm"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select disabled={loading}>
            {[6, 12, 18, 24, 30, 36, 42, 48].map((months) => (
              <Select.Option key={months} value={months}>
                {`${months} Months`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Payback Total (USD)"
          name="paybackTotalX4"
          extra="Normally this is the same as the loan amount."
          initialValue={0}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DollarX4InputNumber disabled={loading} />
        </Form.Item>
        <div style={{ height: 16 }} />
        {project.projectName && (
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`This action will assign a project number, calculate the total total funding target, and create an account for the holistic project ${project.projectName}.`}
            </Paragraph>
          </Form.Item>
        )}
        {!project.projectNo && (
          <>
            <Form.Item name="hasProjectNo" valuePropName="checked" noStyle>
              <div style={{ textAlign: 'center', marginBottom: 16 }}>
                <Checkbox>This project already has a project #</Checkbox>
              </div>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.hasProjectNo !== curValues.hasProjectNo
              }
              noStyle
            >
              {({ getFieldValue }) =>
                getFieldValue('hasProjectNo') && (
                  <Form.Item
                    label="Project #"
                    name="projectNo"
                    rules={[
                      { required: true, message: 'Please enter a number' },
                    ]}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <InputNumber disable={loading} min={0} step={1} />
                  </Form.Item>
                )
              }
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Paragraph style={{ textAlign: 'center', marginBottom: 16 }}>
            {`Do you want to continue?`}
          </Paragraph>
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
}

export default InitiateAccountingModal;
