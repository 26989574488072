import { ExportToCsv } from 'export-to-csv';
import { AdoptionLevels } from '../../adoptions/constants';
import { PayoutRowStates } from './constants';
import { displayMoney } from '@aims/shared/shared/utils';

export function myLocaleCompare(aComp, bComp) {
  if (aComp && bComp) {
    return aComp.localeCompare(bComp);
  }
  if (aComp) {
    return aComp.localeCompare('');
  }
  if (bComp) {
    return ''.localeCompare(bComp);
  }
  return 0;
}

export function exportPayoutToCsv(payout, payoutRows) {
  const data = payoutRows.map((r) => {
    const pg = r.adoptionCopy && r.adoptionCopy.peopleGroup;
    return {
      _id: r._id,
      aId: r.adoptionId,
      aName: r.adoptionCopy && r.adoptionCopy.adopterName,
      aLevel:
        r.adoptionCopy &&
        r.adoptionCopy.level &&
        AdoptionLevels[r.adoptionCopy.level] &&
        AdoptionLevels[r.adoptionCopy.level].label,
      aAmount:
        r.adoptionCopy.amount && displayMoney(r.adoptionCopy.amount / 10000),
      pg: pg && `${pg.nameAcrossCountries} / ${pg.country} (${pg._id})`,
      adoptionAccountId: r.adoptionCopy && r.adoptionCopy.accountId,
      rcId: r.adoptionCopy && r.adoptionCopy.regionalCoordId,
      rcName: r.adoptionCopy && r.adoptionCopy.regionalCoordName,
      toRegionalCoord: displayMoney(r.details.toRegionalCoord / 10000),
      fwId: r.adoptionCopy && r.adoptionCopy.fieldWorkerId,
      fwName: r.adoptionCopy && r.adoptionCopy.fieldWorkerName,
      toFieldWorker: displayMoney(r.details.toFieldWorker / 10000),
      toAnd: displayMoney((r.details.toAnd || 0) / 10000),
      toTraining: displayMoney((r.details.toTraining || 0) / 10000),
      toHolistic: displayMoney((r.details.toHolistic || 0) / 10000),
      total: displayMoney(r.details.totalAmount / 10000),
      notes: r.notes || '',
      state: r.state,
      willReceiveFunds: r.willReceiveFunds,
    };
  });
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: payout.name,
    useTextFile: false,
    useBom: true,
    filename: payout.name,
    headers: [
      'Row ID',
      'Sponsorship ID',
      'Sponsor Name',
      'Sponsorship Level',
      'Sponsorship Monthly Amount',
      'People Group',
      'Sponsorship Account ID',
      'Regional Coordinator ID',
      'Regional Coordinator Name',
      'To Regional Coordinator',
      'Field Worker ID',
      'Field Worker Name',
      'To Field Worker',
      'To A&D',
      'To Regional Training',
      'To Regional Holistic',
      'Total',
      'Notes',
      'State',
      'Will Receive Funds',
    ],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}
