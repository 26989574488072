import { Typography } from 'antd';
import React from 'react';
import PayoutActionColumn from './table-columns/PayoutActionColumn';
import { displayMoney } from '@aims/shared/shared/utils';
import { PayoutStates } from '../constants';

const { Title, Text } = Typography;

function PayoutSelectedRows({ payout, selected, handleTransition }) {
  return (
    <>
      {selected && selected.length > 0 && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#c6cfd3',
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            alignItems: 'center',
            borderRadius: 8,
          }}
        >
          <Title level={5} style={{ margin: 0, marginRight: 16 }}>
            Selected
          </Title>
          <div style={{ marginRight: 16 }}>
            <Text strong>{`Total to Regional Coordinator(s): ${displayMoney(
              selected.reduce((prev, curr) => {
                if (curr.details && curr.details.toRegionalCoord) {
                  return prev + curr.details.toRegionalCoord;
                }
                return prev;
              }, 0) / 10000,
            )}`}</Text>
          </div>
          <div style={{ marginRight: 16 }}>
            <Text strong>{`Total to Field Worker(s): ${displayMoney(
              selected.reduce((prev, curr) => {
                if (curr.details && curr.details.toFieldWorker) {
                  return prev + curr.details.toFieldWorker;
                }
                return prev;
              }, 0) / 10000,
            )}`}</Text>
          </div>
          <div style={{ flex: 1 }} />
          {selected.length > 0 && (
            <div>
              {payout.state === PayoutStates.IN_PROGRESS.key && (
                <PayoutActionColumn
                  record={{
                    state: selected.reduce((prev, curr) => {
                      if (prev === undefined) {
                        return curr.state;
                      }
                      if (prev === curr.state) {
                        return curr.state;
                      }
                      return null;
                    }, undefined),
                    completable: {
                      completable: selected.reduce((prev, curr) => {
                        if (prev === undefined) {
                          return (
                            curr.completable && curr.completable.completable
                          );
                        }
                        if (
                          prev ===
                          (curr.completable && curr.completable.completable)
                        ) {
                          return (
                            curr.completable && curr.completable.completable
                          );
                        }
                        return null;
                      }, undefined),
                    },
                  }}
                  editable
                  handleTransition={(rowIds, newState) =>
                    handleTransition(
                      selected.map((r) => r._id),
                      newState,
                    )
                  }
                  btnStyle={{
                    height: 32,
                    padding: '0px 8px',
                  }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PayoutSelectedRows;
