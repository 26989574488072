import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import {
  DeleteOutlined,
  EyeOutlined,
  FormOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import DeletePublicFormModal from './DeletePublicFormModal';
import {
  PublicFormTypes,
  allPublicFormsQuery,
  extractAllPublicForms,
} from './constants';

const { Title, Text } = Typography;

const queryId = 'publicFormsList';

function PublicFormsList() {
  const [numShown, setNumShown] = useState(settings.pageSize);

  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: publicForms,
    search: publicFormSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPublicFormsQuery,
    extract: extractAllPublicForms,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        publicFormSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [publicFormSearch, refetch],
  );

  const handleLoadMore = useCallback(() => {
    setNumShown(numShown + settings.pageSize);
    if (numShown + settings.pageSize > publicForms.length) {
      next();
    }
  }, [next, numShown, publicForms]);

  const [deleting, setDeleting] = useState();
  const handleDelete = useCallback((publicForm) => {
    setDeleting({ publicForm });
  }, []);
  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleFinishDelete = useCallback(() => {
    setDeleting(undefined);
    refetch();
  }, [refetch]);

  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Public Forms">
        <CSPageHeader
          titleComponent={
            <Title>
              <FormOutlined style={{ marginRight: 16 }} />
              Public Forms
            </Title>
          }
          style={{ marginBottom: 16 }}
        />
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
        <div className="top-actions">
          <Form layout="inline" form={form} onValuesChange={onFiltersChanged}>
            <Form.Item name="search">
              <Input
                placeholder="Search"
                suffix={<SearchOutlined />}
                style={{ maxWidth: 500, minWidth: 300 }}
              />
            </Form.Item>
            <Form.Item name="formType">
              <Select
                placeholder="Select a form type ..."
                style={{ maxWidth: 500, minWidth: 200 }}
                allowClear
              >
                {Object.values(PublicFormTypes).map(({ key, label }) => (
                  <Select.Option key={key} value={key}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <Table
          dataSource={publicForms.slice(0, numShown)}
          loading={!publicForms.length && loading}
          pagination={false}
          style={{ width: '100%' }}
          rowKey="_id"
        >
          <Column
            title="Created"
            dataIndex="createdAt"
            render={(text, record) => {
              return new Intl.DateTimeFormat(locale, {
                dateStyle: 'full',
              }).format(new Date(record.createdAt));
            }}
          />
          <Column
            title="Form Type"
            dataIndex="formType"
            render={(text, record) => {
              return (
                PublicFormTypes[record.formType] &&
                PublicFormTypes[record.formType].label
              );
            }}
          />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Email" dataIndex="email" key="email" />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Tooltip title="View">
                  <Link to={`/public-forms/${record._id}/review`}>
                    <Button icon={<EyeOutlined />} />
                  </Link>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    onClick={() => handleDelete(record)}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
        <div className="bottom-actions">
          {(numShown < publicForms.length || hasNextPage) && (
            <Button
              style={{ minWidth: 200 }}
              onClick={handleLoadMore}
              loading={loading}
            >
              Load More
            </Button>
          )}
        </div>
        <DeletePublicFormModal
          onCancel={handleCancelDelete}
          onFinish={handleFinishDelete}
          deleting={deleting}
        />
      </CSPage>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
      `}</style>
    </>
  );
}

export default PublicFormsList;
