import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Menu, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { StatusColors, Statuses } from '../EditTask/constants';

const { Text, Title } = Typography;

function EditTaskStatus({ onSubmit, task }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const onFinish = useCallback(
    async ({ key }) => {
      setError(null);
      setLoading(true);
      try {
        await onSubmit({
          status: key,
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <>
      <div className="status-row-title">
        <Title level={4}>Status</Title>
      </div>
      <Dropdown
        overlay={
          <Menu onClick={onFinish}>
            {Statuses.map((status) => (
              <Menu.Item key={status.value}>{status.label}</Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomLeft"
        trigger="click"
      >
        <Button
          style={{
            backgroundColor: StatusColors[task.status].color,
            color: 'white',
            width: '100%',
            borderRadius: 4,
          }}
          loading={loading}
        >
          {StatusColors[task.status].label}
          <DownOutlined />
        </Button>
      </Dropdown>
      {error && (
        <Form.Item>
          <Text type="danger">{error}</Text>
        </Form.Item>
      )}
      <style jsx>{`
        .status-row-title {
          margin-bottom: 8px;
          font-weight: 600;
        }
      `}</style>
    </>
  );
}

export default EditTaskStatus;
