import { Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactFormItems from '../../EditContact/form-items';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactNameInnerModal({ contact, saving, handleFinish, onCancel }) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        firstName: contact.firstName,
        middleNames: contact.middleNames,
        lastName: contact.lastName,
      });
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (values) => {
      handleFinish({
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Update Name"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <ContactFormItems.firstName.FormItem
        contactType={contact?.contactType}
        loading={saving}
      />
      <ContactFormItems.middleNames.FormItem
        contactType={contact?.contactType}
        loading={saving}
      />
      <ContactFormItems.lastName.FormItem
        contactType={contact?.contactType}
        loading={saving}
      />
    </ContactSomethingForm>
  );
}

export default ContactNameInnerModal;
