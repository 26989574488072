import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const BibleStatuses = [
  {
    _id: '0',
    name: 'Questionable',
  },
  {
    _id: '1',
    name: 'No Tranlations',
  },
  {
    _id: '2',
    name: 'Portions of the Bible Translated',
  },
  {
    _id: '3',
    name: 'New Testament Translated',
  },
  {
    _id: '4',
    name: 'Whole Bible Translated',
  },
  {
    _id: '5',
    name: 'Whole Bible Translated',
  },
];

export const BibleStatusLabels = {
  0: {
    label: 'Questionable',
  },
  1: {
    label: 'No Tranlations',
  },
  2: {
    label: 'Portions of the Bible Translated',
  },
  3: {
    label: 'New Testament Translated',
  },
  4: {
    label: 'Whole Bible Translated',
  },
  5: {
    label: 'Whole Bible Translated',
  },
};

function SelectBibleStatus({ disabled, value, onChange }) {
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a bible status"
      value={value}
      allowClear
    >
      {BibleStatuses.map((scale) => (
        <Select.Option key={scale._id} value={scale._id} title={scale.name}>
          <div className="scale-option">
            <div>
              <Text>{scale.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectBibleStatus;
