import useOneM1 from '@aims/shared/shared/use-one-m1';
import { gql } from '@apollo/client';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { extractPeopleGroupForAdmin } from '../constants';
import {
  createPeopleGroupAction,
  deletePeopleGroupAction,
  updatePeopleGroupAction,
} from '../../../redux-store/people-groups-store';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';

export const UpgLongAttributes = gql`
  {
    _id
    population
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    affinityBloc
    affinityBlocId
    peopleCluster
    peopleClusterId
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    primaryLanguageId
    officialLanguage
    officialLanguageId
    bibleStatus
    primaryReligion
    primaryReligionId
    religionSubdivision
    religionSubdivisionId
    jpScaleId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    latlon
    summary
    summaryText
    photoIds
    primaryPhotoId
    primaryThumbnailUrl
    focusLevelNo
    aimsStatusAdopted
    aimsStatusCoordinator
    aimsStatusFieldWorker
    aimsStatusFunding
    aimsStatusHolisticProject
    aimsStatusSalvations
    aimsStatusChurches
    aimsStatusMovements
    aimsStatusPercent
    numAdopters
    numAdoptionsAvailable
    numRegionalCoords
    numFieldWorkers
    numHolisticProjects
    suggestedAdoptionLevel
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
    cumulativeStats {
      receivedForHimself
      receivedForHolistic
      receivedTotal
      numChurchesPlanted
      numHeardForFirstTime
      numSalvations
      numWaterBaptisms
      numMiraclesReported
    }
  }
`;

const longUpgQuery = gql`
  query UseLongUpg($_id: ID!) {
    peopleGroupForAdmin(_id: $_id) ${UpgLongAttributes}
  }
`;

const UpgUpdateAttributes = gql`
{
  crud
  id
  new ${UpgLongAttributes}
}
`;

const longUpgSubscription = gql`
  subscription UseLongUpg($_id: ID!) {
    peopleGroupUpdatesForAdmin(_id: $_id) ${UpgUpdateAttributes}
  }
`;

export default function useLongUpg({
  _id,
  queryId = 'use-long-upg',
  fetchPolicy = 'network-only',
}) {
  const { loading: loading1, error: error1 } = useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: longUpgSubscription,
    createAction: createPeopleGroupAction,
    updateAction: updatePeopleGroupAction,
    deleteAction: deletePeopleGroupAction,
    extractSomething: (resp) => resp?.data?.contactUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const fetchResult = useOneM1({
    variables: { _id },
    query: longUpgQuery,
    extract: extractPeopleGroupForAdmin,
    fetchPolicy,
    updateAction: updatePeopleGroupAction,
    queryId,
  });
  const upg = useSingleSomethingA10('peopleGroups', _id, queryId);
  const { loading: loading2, error: error2 } = fetchResult;

  return {
    upg,
    loading: loading1 || loading2,
    error: error1 || error2,
  };
}
