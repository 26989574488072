import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import settings from '../../settings';
import { StatusColors } from './EditTask/constants';
import TasksList from './TasksList';
import { allTasksForAdminQuery, extractAllTasksForAdmin } from './constants';

const queryId = 'remote-tasks';

function RemoteTaskTabs({
  filters: parentFilters,
  sortBy,
  searchRef,
  refetchRef,
}) {
  const [tab, setTab] = useState(StatusColors.TODO.key);

  const filters = useRef({
    ...parentFilters.current,
    status: StatusColors.TODO.key,
  });

  const {
    error,
    loading,
    data: tasks,
    search: taskSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTasksForAdminQuery,
    extract: extractAllTasksForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy,
    fetchPolicy: 'network-only',
    queryId,
  });
  searchRef.current = taskSearch;
  refetchRef.current = refetch;

  useEffect(() => {
    switch (tab) {
      case StatusColors.DOING.key:
        filters.current = {
          ...filters.current,
          status: StatusColors.DOING.key,
          startsAfter: undefined,
        };
        refetch();
        break;
      case StatusColors.DONE.key:
        filters.current = {
          ...filters.current,
          status: StatusColors.DONE.key,
          startsAfter: undefined,
        };
        refetch();
        break;
      case StatusColors.TODO.key:
        filters.current = {
          ...filters.current,
          status: StatusColors.TODO.key,
          startsAfter: undefined,
        };
        refetch();
        break;
      case StatusColors.SCHEDULED.key: {
        let startsAfter = new Date();
        startsAfter.setMilliseconds(0);
        startsAfter.setSeconds(0);
        startsAfter = startsAfter.toISOString();
        filters.current = {
          ...filters.current,
          status: StatusColors.TODO.key,
          startsAfter,
        };
        refetch();
        break;
      }
      default:
        filters.current = {
          ...filters.current,
          status: undefined,
          startsAfter: undefined,
        };
        break;
    }
  }, [tab, refetch]);

  const prevParentFilters = useRef(JSON.stringify(parentFilters));
  useEffect(() => {
    const newFilters = JSON.stringify(parentFilters);
    if (newFilters !== prevParentFilters.current) {
      prevParentFilters.current = newFilters;
      filters.current = {
        ...filters.current,
        ...parentFilters,
      };
      refetch();
    }
  }, [parentFilters, refetch]);

  return (
    <Tabs
      defaultActiveKey="accounts"
      activeKey={tab}
      onChange={(key) => setTab(key)}
    >
      {Object.values(StatusColors)
        .sort((a, b) => a.sort - b.sort)
        .map((color) => (
          <Tabs.TabPane tab={color.label} key={color.key}>
            <TasksList
              tasks={tasks}
              loading={loading}
              refetch={refetch}
              queryId={queryId}
            />
          </Tabs.TabPane>
        ))}
    </Tabs>
  );
}

export default RemoteTaskTabs;
