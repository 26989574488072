import { LoadingOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';

const { Text, Title } = Typography;

function AdmInitPageA10() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Title level={3}>Associate Donation with Sponsorship(s)</Title>
      <Text>Loading ...</Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 32,
          fontSize: 48,
        }}
      >
        <LoadingOutlined />
      </div>
    </div>
  );
}

export default AdmInitPageA10;
