import { Select, Tag } from 'antd';
import React, { useCallback, useMemo } from 'react';
import useTags from './use-tags';

function SelectTags({
  value: parentValue,
  onChange: parentOnChange,
  disabled,
  style,
}) {
  const { tags, loading, refetch, search } = useTags();

  const value = useMemo(() => {
    if (parentValue && tags) {
      return parentValue
        .map((pValue) => tags.find((t) => t.key === pValue.key))
        .filter((t) => t)
        .map((t) => ({ key: t.key, color: t.color }));
    }
    return [];
  }, [parentValue, tags]);

  const onChange = useCallback(
    (vs) => {
      parentOnChange(
        vs.map((v) => {
          const tag = tags.find((t) => t.key === v);
          if (tag) {
            return {
              key: tag.key,
              color: tag.color,
            };
          }
          return undefined;
        }),
      );
    },
    [parentOnChange, tags],
  );

  const options = useMemo(() => {
    if (tags) {
      return tags.map((tag, index) => {
        return {
          value: tag.key,
          label: tag.key,
          color: tag.color,
        };
      });
    }
    return [];
  }, [tags]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a tag"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      showSearch
      onSearch={(term) => search(term)}
      filterOption={false}
      disabled={disabled}
      loading={loading}
      mode="multiple"
      // labelRender={(option) => <Tag color={option.color}>{option.value}</Tag>}
      optionRender={(option) => (
        <Tag color={option.data.color}>{option.data.label}</Tag>
      )}
    />
  );
}

export default SelectTags;
