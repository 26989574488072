import React from 'react';
import { Card, Typography, Tooltip, Button } from 'antd';
import ellipsis from 'text-ellipsis';
import filesize from 'filesize';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

const { Text } = Typography;

function EditAttachmentCard({
  fileName,
  fileSize,
  thumbnailUrl,
  onDelete,
  deleting,
}) {
  return (
    <Card
      styles={{
        body: { padding: '8px 16px' },
      }}
      style={{ marginBottom: 8 }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 16 }}>
          {thumbnailUrl ? (
            <img style={{ height: 48, borderRadius: 8 }} src={thumbnailUrl} />
          ) : (
            <PaperClipOutlined style={{ fontSize: 18 }} />
          )}
        </div>
        <div style={{ marginRight: 8 }}>
          <Text>
            {`${ellipsis(fileName, 20)} (${filesize(fileSize, {
              round: 1,
            })})`}
          </Text>
        </div>
        <Tooltip title="Delete">
          <Button
            type="dashed"
            icon={<DeleteOutlined />}
            onClick={onDelete}
            loading={deleting}
          />
        </Tooltip>
      </div>
    </Card>
  );
}

export default EditAttachmentCard;
