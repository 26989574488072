import { EyeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMouAction } from '../../../redux-store/mous-store';
import { previewMouMutation } from '../constants';
import { AgreementStatuses } from '../../loan-agreements/constants';

const { Title, Text, Paragraph } = Typography;

const MarkMouPreviewBtn = ({ mou, setError, error, queryId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [previewMou] = useMutation(previewMouMutation);
  const dispatch = useDispatch();

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await previewMou({
        variables: {
          mouId: mou._id,
          status: AgreementStatuses.PREVIEW.key,
        },
      });
      dispatch(updateMouAction(result.data.previewMou.mou, queryId));
      notification.success({
        message: 'Saved',
        description: 'Partnership Commitment Form updated successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [setError, dispatch, queryId, previewMou, mou, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<EyeOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Preview
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Preview Partnership Commitment Form
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              You are about to exit &quot;Editing&quot; mode.
            </Paragraph>
            <Paragraph style={{ textAlign: 'center' }}>
              Don&apos;t worry. You can still make changes later.
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Preview
              </Button>
            </div>
          </Form.Item>
        </Form>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default MarkMouPreviewBtn;
