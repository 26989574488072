import {
  DownloadOutlined,
  LoadingOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Empty, List, Space, Typography, Card, Button } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import ellipsis from 'text-ellipsis';
import filesize from 'filesize';
import ViewReportLineItem from './ViewReportLineItem';
import sharedSettings from '../sharedSettings';

const { Title } = Typography;

function CommonImage({ url, fullUrl }) {
  const [loading, setLoading] = useState(true);
  const handleLoaded = useCallback(() => {
    setLoading(false);
  }, []);
  return (
    <div className="container">
      {loading && (
        <div className="placeholder">
          <LoadingOutlined style={{ color: 'white', fontSize: 24 }} />
        </div>
      )}
      <a href={fullUrl} target="_blank" rel="noopener noreferrer">
        <img className="common-image" src={url} onLoad={handleLoaded} />
      </a>
      <style jsx>{`
        .container {
          position: relative;
          height: 300px;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .placeholder {
          background-color: ${sharedSettings.colors.darkGray};
          height: 300px;
          width: 300px;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .common-image {
          height: 100%;
        }
      `}</style>
    </div>
  );
}

function CommonFile({ file }) {
  return (
    <List.Item>
      <Card bodyStyle={{ textAlign: 'center' }}>
        <Space direction="vertical" align="center">
          <Space>
            <div>
              <PaperClipOutlined style={{ fontSize: 24 }} />
            </div>
            <Title level={5}>{`${ellipsis(file.filename, 20)}`}</Title>
          </Space>
          <Button
            type="dashed"
            icon={<DownloadOutlined />}
            target="_blank"
            href={file.url}
          >
            {filesize(file.size, {
              round: 1,
            })}
          </Button>
        </Space>
      </Card>
    </List.Item>
  );
}

export function CommonFiles({ attachments, photoTitle, fileTitle }) {
  const publicPhotos = useMemo(() => {
    if (attachments) {
      return attachments.filter((a) => a.contentType.startsWith('image'));
    }
    return [];
  }, [attachments]);
  const publicFiles = useMemo(() => {
    if (attachments) {
      return attachments.filter((a) => !a.contentType.startsWith('image'));
    }
    return [];
  }, [attachments]);
  return (
    <>
      <Title level={3}>{photoTitle}</Title>
      {publicPhotos.length ? (
        <div className="photo-list">
          {publicPhotos.map((p) => (
            <CommonImage
              key={p._id}
              url={p.thumbnail ? p.thumbnail.url : p.url}
              fullUrl={p.url}
            />
          ))}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`No ${photoTitle}`}
        />
      )}
      <div style={{ height: 24 }} />
      <Title level={3}>{fileTitle}</Title>
      <List
        dataSource={publicFiles}
        renderItem={(item) => <CommonFile file={item} />}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={`No ${fileTitle}`}
            />
          ),
        }}
        grid={{ column: 4, gutter: 16 }}
      />
      <div style={{ height: 16 }} />
      <style jsx>{`
        .photo-list {
          display: flex;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

function ViewCommonItems({ report }) {
  return (
    <>
      {report.publicAttachments && !!report.publicAttachments.length && (
        <>
          <Title level={2}>Public Attachments</Title>
          <CommonFiles
            attachments={report.publicAttachments}
            photoTitle="Public Photos"
            fileTitle="Other Public Files"
          />
        </>
      )}
      {report.privateAttachments && !!report.privateAttachments.length && (
        <>
          <Title level={2}>Private Attachments</Title>
          <CommonFiles
            attachments={report.privateAttachments}
            photoTitle="Private Photos"
            fileTitle="Other Private Files"
          />
        </>
      )}
      {report.notes && (
        <ViewReportLineItem
          question="Any other notes, stories or testimonies you'd like to share"
          answer={report.notes}
        />
      )}
    </>
  );
}

export default ViewCommonItems;
