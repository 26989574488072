export const UPDATE_COUNTRY_REGION = 'UPDATE_COUNTRY_REGION';

// eslint-disable-next-line no-shadow
export const updateCountryRegionsAction = (countryRegions) => ({
  type: UPDATE_COUNTRY_REGION,
  payload: {
    countryRegions,
  },
});

const initialCountryRegions = [];

export function countryRegions(state = initialCountryRegions, action) {
  switch (action.type) {
    case UPDATE_COUNTRY_REGION:
      return {
        ...state,
        [action.payload.countryRegions.countryShortCode]:
          action.payload.countryRegions,
      };
    default:
      return state;
  }
}
