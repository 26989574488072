export const UPDATE_QUERY = 'UPDATE_QUERY';
export const INCREMENT_QUERY = 'INCREMENT_QUERY';
export const NEXT_QUERY = 'NEXT_QUERY';

export const updateQueryAction = (query, queryId = 'default') => ({
  type: UPDATE_QUERY,
  payload: {
    query,
    queryId,
  },
});

export const incrementQueryAction = (queryCode, queryId = 'default') => ({
  type: INCREMENT_QUERY,
  payload: {
    queryCode,
    queryId,
  },
});

export const nextQueryAction = (queryCode, after, queryId = 'default') => ({
  type: NEXT_QUERY,
  payload: {
    queryCode,
    after,
    queryId,
  },
});

// TODO: Remove onces all references are deleted
export const deleteQueryAction = (queryCode, queryId = 'default') => ({
  type: INCREMENT_QUERY,
  payload: {
    queryCode,
    queryId,
  },
});

const initialQueries = {};

export function queries(state = initialQueries, action) {
  switch (action.type) {
    case UPDATE_QUERY:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.query._id]: action.payload.query,
        },
      };
    case INCREMENT_QUERY:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.queryCode]: {
            ...state[action.payload.queryId]?.[action.payload.queryCode],
            refetches:
              state[action.payload.queryId]?.[action.payload.queryCode]
                ?.refetches + 1,
          },
        },
      };
    case NEXT_QUERY: {
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.queryCode]: {
            ...state[action.payload.queryId]?.[action.payload.queryCode],
            after: action.payload.after,
          },
        },
      };
    }
    default:
      return state;
  }
}
