import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { PauseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useState } from 'react';
import {
  reviewUpgQuarterlyReportMutation,
  sendUpgQuarterlyReportFeedbackMutation,
} from '../constants';

const { Title, Paragraph } = Typography;

const PutUpgQuarterlyReportOnHoldBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewPgQuarterlyReport] = useMutation(
    reviewUpgQuarterlyReportMutation,
  );
  const [sendPgQuarterlyReportFeedback] = useMutation(
    sendUpgQuarterlyReportFeedbackMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await reviewPgQuarterlyReport({
          variables: {
            reportId: report._id,
            status: 'HOLD',
          },
        });

        notification.success({
          message: 'Saved',
          description: 'Report placed on hold',
        });
        if (values.sendFeedback) {
          await sendPgQuarterlyReportFeedback({
            variables: {
              reportId: report._id,
              feedback: values.feedback,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Feedback sent',
          });
        }
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report',
        });
      }
      setLoading(false);
    },
    [reviewPgQuarterlyReport, sendPgQuarterlyReportFeedback, report, onSuccess],
  );

  return (
    <>
      <Button
        loading={loading}
        icon={<PauseOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Hold Report
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Put UPG Quarterly Report on Hold
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`You're about to put the ${getPgDisplayName(
                report.peopleGroup,
              )} quarterly report for${' '}
                ${getQuarterLabel(report.quarter)}${' on hold. '}
                The submitter will not be notified unless you opt to send feedback below.`}
            </Paragraph>
          </Form.Item>
          <Form.Item name="sendFeedback" valuePropName="checked" noStyle>
            <div style={{ textAlign: 'center', marginBottom: 16 }}>
              <Checkbox>Send Feedback</Checkbox>
            </div>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.sendFeedback !== curValues.sendFeedback
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('sendFeedback') && (
                <Form.Item
                  label="Feedback"
                  name="feedback"
                  rules={[
                    { required: true, message: 'Please add some feedback' },
                  ]}
                >
                  <Input.TextArea disable={loading} rows={4} />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Put Report on Hold
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PutUpgQuarterlyReportOnHoldBtn;
