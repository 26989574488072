import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Input, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

const { Title, Text, Paragraph } = Typography;

function ViewEditPayoutNameV2({ payout, savePayout }) {
  const ref = useRef();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);

  const handleFinish = useCallback(
    async (values) => {
      setEditing(false);
      if (payout.name !== values.name) {
        await savePayout({ _id: payout._id, name: values.name });
      }
    },
    [payout, savePayout],
  );

  const handleClick = useCallback(() => {
    setEditing(true);
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, 100);
  }, []);

  return (
    <>
      {!editing ? (
        <map
          onClick={handleClick}
          style={{ display: 'block', width: '100%', maxWidth: 800 }}
        >
          <Title style={{ margin: 0, marginBottom: 4, marginTop: 4 }}>
            {payout ? payout.name : 'Payout'}
            {payout && (
              <Text
                style={{
                  color: sharedSettings.colors.textGray,
                  fontSize: 12,
                  marginLeft: 16,
                  fontWeight: 'normal',
                }}
              >
                Click to Edit ...
              </Text>
            )}
          </Title>
        </map>
      ) : (
        <Form
          form={form}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
          onFinish={handleFinish}
          style={{ maxWidth: 800, marginTop: 16, marginBottom: 16 }}
        >
          <Form.Item name="name" noStyle initialValue={payout.name}>
            <Input ref={ref} onBlur={() => form.submit()} />
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default ViewEditPayoutNameV2;
