import { Typography } from 'antd';
import React from 'react';
import sharedSettings from '../sharedSettings';

const { Title, Text } = Typography;

function ViewReportLineItem({ question, answer }) {
  return (
    <>
      <div className="line">
        <div className="line-question">
          <Text>{question}</Text>
        </div>
        <div className="line-answer">
          <Text>{answer}</Text>
        </div>
      </div>
      <style jsx>{`
        .container {
        }
        .line {
          margin-bottom: 16px;
        }
        .line-question {
          margin-bottom: 16px;
        }
        .line-answer {
          text-align: center;
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          background-color: ${sharedSettings.colors.borderGray};
        }
      `}</style>
    </>
  );
}

export default ViewReportLineItem;
