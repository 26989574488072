import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { useMemo, useRef, useState } from 'react';
import settings from '../../settings';
import { allUpgXContactsQuery, extractUpgXContacts } from './constants';

const queryId = 'usePgConnections';

function usePgConnections(contactId) {
  const [sortedBy, setSortedBy] = useState('contactId.keyword');
  const [sortOrder, setSortOrder] = useState('ASC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ contactId });
  const {
    error,
    loading,
    data: upgXContacts,
    search: upgXContactsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgXContactsQuery,
    extract: extractUpgXContacts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
    skip: !contactId,
  });

  const pgConnections = useMemo(() => {
    const peopleGroups = {};
    upgXContacts.forEach((upgXContact) => {
      if (peopleGroups[upgXContact.peopleGroupId] === undefined) {
        peopleGroups[upgXContact.peopleGroupId] = {
          _id: upgXContact.peopleGroupId,
          roles: [],
          peopleGroup: upgXContact.peopleGroup,
        };
      }
      peopleGroups[upgXContact.peopleGroupId].roles.push(upgXContact.type);
    });
    return Object.values(peopleGroups);
  }, [upgXContacts]);

  return pgConnections;
}

export default usePgConnections;
