import React from 'react';
import { MouRoles } from './constants';
import { Select } from 'antd';

function SelectMouRole({ value, onChange, ...props }) {
  return (
    <Select value={value} onChange={onChange} {...props}>
      {Object.values(MouRoles).map(({ key, label }) => (
        <Select.Option key={key} value={key}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectMouRole;
