import { Form, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import DateFormItem from '@aims/shared/components/DateFormItem';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';

const { Title, Text } = Typography;

function ChangeStartInnerModalUpgPA({
  adoption,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (adoption) {
      form.setFieldsValue({
        startDate: adoption.startDate,
      });
    }
  }, [adoption, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { startDate, ...values } = _values;
      handleFinish({
        startDate: startDate ? moment(startDate) : null,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
      title="Change Start Date"
    >
      <DateFormItem name="startDate" label="Start Date" rules={[]} />
    </TaskSomethingForm>
  );
}

export default ChangeStartInnerModalUpgPA;
