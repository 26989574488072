import { useCountries } from '@aims/shared/shared/use-countries';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Card, Empty, List, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import ContactAddAddressInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactAddAddressInnerModalA10';
import ContactChangeSomethingModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactChangeSomethingModalA10';
import ContactDeleteAddressInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactDeleteAddressInnerModalA10';
import ContactUpdateAddressInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactUpdateAddressInnerModalA10';

const { Title, Text } = Typography;

function AnyAddresses({ parent }) {
  const [updating, setUpdating] = useState();
  const handleUpdate = (address) => setUpdating({ address });
  const onFinishUpdate = () => setUpdating(undefined);
  const onCancelUpdate = () => setUpdating(undefined);

  const [deleting, setDeleting] = useState();
  const handleDelete = (address) => setDeleting({ address });
  const onFinishDelete = () => setDeleting(undefined);
  const onCancelDelete = () => setDeleting(undefined);

  const { countries } = useCountries();
  const getCountry = useCallback(
    (shortCode) => {
      if (countries) {
        const country = countries.find((c) => c.shortCode === shortCode);
        return country && country.name;
      }
      return '';
    },
    [countries],
  );

  return (
    <div className="container">
      <Title level={2} style={{ margin: 0 }}>
        Addresses
      </Title>
      <ContactChangeSomethingModalA10
        contact={parent}
        btnProps={{
          type: 'text',
          icon: <PlusOutlined />,
          style: { marginBottom: 16, marginLeft: -16 },
        }}
        btnText="Add an Address"
      >
        <ContactAddAddressInnerModalA10 />
      </ContactChangeSomethingModalA10>
      <List
        grid={{ gutter: 16, column: 3 }}
        dataSource={parent.addresses || []}
        renderItem={(address) => {
          const extraAddressData = [
            address.city,
            address.state,
            address.zipCode,
          ].filter((i) => !!i);
          return (
            <List.Item key={address._id}>
              <Card style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                  <Tooltip title="Edit">
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => handleUpdate(address)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(address)}
                    />
                  </Tooltip>
                </div>
                <Title level={5}>
                  {parent.primaryAddressId === address._id && (
                    <StarFilled style={{ marginRight: 4 }} />
                  )}
                  {address.label}
                </Title>
                {address.address1 && <div>{address.address1}</div>}
                {address.address2 && <div>{address.address2}</div>}
                {extraAddressData.length ? (
                  <div>{extraAddressData.join(', ')}</div>
                ) : null}
                <div>{getCountry(address.country)}</div>
              </Card>
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Addresses"
            />
          ),
        }}
      />
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      >
        <ContactUpdateAddressInnerModalA10 address={updating?.address} />
      </ContactChangeSomethingModalA10>
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={deleting}
        onDone={onFinishDelete}
        onCancel={onCancelDelete}
      >
        <ContactDeleteAddressInnerModalA10 address={deleting?.address} />
      </ContactChangeSomethingModalA10>
      <style jsx>{`
        .container {
          margin-bottom: 32px;
        }
      `}</style>
    </div>
  );
}

export default AnyAddresses;
