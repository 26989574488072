import { Form, Typography, Input } from 'antd';
import React from 'react';

import passwordStrength from '../shared/check-password-strength';

const { Text } = Typography;

const UserQuestions = {
  username: {
    FormItem: function FormItem({ loading, extra }) {
      return (
        <Form.Item
          label="Username"
          name="username"
          rules={[
            { required: true, message: 'Please enter a username' },
            {
              pattern: /(^[a-zA-Z0-9_.@$-]+$)/,
              message:
                'Please enter a valid username (letters, numbers or one of (_.@$-))',
            },
          ]}
          extra={extra}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
    Display: function Display({ colors, user }) {
      return (
        <Form.Item label="Username">
          <Input disabled value={user && user.username} />
        </Form.Item>
      );
    },
  },
  name: {
    FormItem: function FormItem({ loading, user, userId, form }) {
      return (
        <Form.Item
          label="Full Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
  },
  email: {
    FormItem: function FormItem({ loading, user, userId, form }) {
      return (
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
  },
  phone: {
    FormItem: function FormItem({ loading, user, userId, form }) {
      return (
        <Form.Item
          label="Phone Number"
          name="phone"
          rules={[
            {
              pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: 'Please enter a valid phone number (ex. 5124026225).',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
    finish: (values) => {
      let standardPhone;
      const { phone } = values;
      if (phone && !phone.startsWith('+') && phone.length === 10) {
        standardPhone = `+1${phone}`;
      } else {
        standardPhone = phone || null;
      }
      return {
        phone: standardPhone,
      };
    },
  },
  password: {
    FormItem: function FormItem({ loading, user, userId, form }) {
      return (
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please enter a password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const result = passwordStrength(value);
                if (result.id < 3) {
                  return Promise.reject(result.errorMsg);
                }
                return Promise.resolve();
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password disabled={loading} />
        </Form.Item>
      );
    },
  },
  confirmPassword: {
    FormItem: function FormItem({ loading, user, userId, form }) {
      return (
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>
      );
    },
    finish: () => {
      return {
        confirmPassword: undefined,
      };
    },
  },
};

export default UserQuestions;
