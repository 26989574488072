import React, { useCallback, useState } from 'react';
import AdmContactPageA10 from '../a10-associate-donations/AdmContactPageA10';
import { Modal, notification } from 'antd';

function AssociatePayerModalA10({ visible, onCancel, onDone }) {
  const { payer } = visible || {};

  const [contact, setContact] = useState();
  const setPage = useCallback(() => {
    notification.success({
      message: 'Success',
      description: 'Payer updated successfully.',
    });
    onDone();
  }, [onDone]);

  return (
    <Modal
      open={visible}
      closable={true}
      destroyOnClose={true}
      maskClosable
      cancelText="Cancel"
      okText="Change Password"
      footer={null}
      onCancel={onCancel}
      width={700}
      styles={{
        body: {
          paddingTop: 32,
        },
      }}
    >
      {payer && (
        <AdmContactPageA10
          payer={payer}
          contact={contact}
          setContact={setContact}
          setPage={setPage}
        />
      )}
    </Modal>
  );
}

export default AssociatePayerModalA10;
