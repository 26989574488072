import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectTagColor from './SelectTagColor';
import TagForm from './TagForm';

export const upsertSimpleListItemForAdminMutation = gql`
  mutation EditTagForAdmin($name: String!, $item: SimpleListItemInput!) {
    upsertSimpleListItemForAdmin(name: $name, item: $item)
  }
`;

const { Title, Text } = Typography;

function EditTagModal({ visible, onCancel, onFinish }) {
  const { tag } = visible || {};
  console.log('deleting', tag);

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editTag] = useMutation(upsertSimpleListItemForAdminMutation);

  useEffect(() => {
    if (tag) {
      form.setFieldsValue({
        key: tag.key,
        color: tag.color,
      });
    } else {
      form.resetFields();
    }
  }, [tag, form]);

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { key, color } = _values;
        await editTag({
          variables: {
            name: 'tags',
            item: {
              _id: tag._id,
              key: key,
              content: JSON.stringify({ color }),
            },
          },
        });
        onFinish();
        notification.success({
          message: 'Success',
          description: 'Tag saved successfully',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your tag',
        });
      }
      setSaving(false);
    },
    [editTag, onFinish, tag],
  );
  return (
    <>
      <Modal
        open={visible}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
      >
        <Title level={3} style={{ marginTop: 8 }}>
          Edit Tag
        </Title>
        <TagForm
          form={form}
          handleFinish={handleFinish}
          saving={saving}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
}

export default EditTagModal;
