import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { getQuarterLabel } from '@aims/shared/reports/quarters';

const { Paragraph } = Typography;

const deleteHolisticQuarterlyReportForAdminMutation = gql`
  mutation DeleteHolisticQuarterlyReportForAdmin($_id: ID!) {
    deleteHolisticQuarterlyReportForAdmin(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteHolisticQuarterlyReportModal({ visible, onCancel, onFinish }) {
  const { report } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteReport] = useMutation(
    deleteHolisticQuarterlyReportForAdminMutation,
  );

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteReport({
        variables: {
          _id: report._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Report deleted successfully',
      });
      onFinish();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error deleting your report',
      });
    }
    setLoading(false);
  }, [report, deleteReport, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Holistic Project Quarterly Report"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>
        Are you sure you want to delete this holistic project quarterly report?
      </Paragraph>
      <Paragraph style={{ fontWeight: 600 }}>
        {`${report?.holisticProject?.projectName} ${getQuarterLabel(
          report?.quarter,
        )}`}
      </Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteHolisticQuarterlyReportModal;
