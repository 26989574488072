import { Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import YesNoModal from '../../../../components/YesNoModal';
import { PayoutRowStates } from '../constants';

const { Text, Paragraph } = Typography;

function ConfirmTransitionRowModalV2({ confirming, onCancel, onFinish }) {
  const { payoutRows, newState } = confirming || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await onFinish(payoutRows, newState);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [payoutRows, newState, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  const { text, title, yesText } = useMemo(() => {
    if (newState === PayoutRowStates.PENDING.key) {
      return {
        title: 'Initiate Withdrawals for Row(s)',
        text: (
          <>
            <Paragraph>
              {`This action will create two pending withdrawals in the AIMS system
              for each of the selected ${
                payoutRows && payoutRows.length
              } row(s). You are responsible for marking
              this action complete once an actual wire / withdrawal has been
              executed.`}
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Initiate Withdrawals',
      };
    }
    if (newState === PayoutRowStates.COMPLETE.key) {
      return {
        title: 'Mark Row Complete',
        text: (
          <>
            <Paragraph>
              {`This action will mark the associated withdrawals complete in the AIMS system.`}
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Mark Row Complete',
      };
    }
    if (newState === PayoutRowStates.READY.key) {
      return {
        title: 'Cancel Pending Transactions',
        text: (
          <>
            <Paragraph>
              {`This action will cancel and delete the pending transactions created by clicking "Initiate".`}
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Cancel Pending Transactions',
      };
    }
    return {};
  }, [newState, payoutRows]);

  return (
    <YesNoModal
      title={title}
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>{text}</div>
        </div>
      }
      yesText={yesText}
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!confirming}
      loading={loading}
      error={error}
    />
  );
}

export default ConfirmTransitionRowModalV2;
