import { generateUuid } from '@aims/shared/shared/utils';
import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import SelectHolisticProject from '../holistic-projects/SelectHolisticProject';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';
import SelectPrayerRequestResult from './SelectPrayerRequestResult';

export const createPrayerRequestMutation = gql`
  mutation CreatePrayerRequestForAdmin(
    $prayerRequest: PrayerRequestCreateInput!
  ) {
    createPrayerRequestForAdmin(prayerRequest: $prayerRequest) {
      prayerRequest {
        _id
      }
    }
  }
`;

const CreatePrayerRequestModalJ15 = ({ visible, onFinish, onCancel }) => {
  const [saving, setSaving] = useState(false);
  const [createPrayerRequest] = useMutation(createPrayerRequestMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await createPrayerRequest({
          variables: {
            prayerRequest: {
              _id: generateUuid(),
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Prayer Request saved successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your prayer request',
        });
      }
      setSaving(false);
    },
    [createPrayerRequest, onFinish],
  );

  const [form] = Form.useForm();

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={oncancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Prayer Request"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item name="peopleGroupId" label="People Group" rules={[]}>
          <SelectPeopleGroup disabled={saving} />
        </Form.Item>
        <Form.Item name="holisticProjectId" label="Holistic Project" rules={[]}>
          <SelectHolisticProject disabled={saving} />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Prayer Request"
          rules={[
            {
              required: true,
              message: 'Please enter the prayer request description',
            },
          ]}
        >
          <Input.TextArea rows={4} disabled={saving} />
        </Form.Item>
        <Form.Item name="result" label="Result">
          <SelectPrayerRequestResult />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default CreatePrayerRequestModalJ15;
