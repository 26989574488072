import sharedSettings from '@aims/shared/sharedSettings';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';

const { Text } = Typography;

function HpPhotoCard({
  photo,
  makePrimary,
  setDeleting,
  loading,
  holisticProject,
  aspect,
}) {
  const height = useMemo(() => {
    if (aspect > 1) {
      return 120;
    }
    return 240;
  }, [aspect]);
  return (
    <div
      style={{
        marginRight: 8,
        marginLeft: 8,
        marginBottom: 16,
      }}
    >
      <div
        style={{
          height: height + 40,
          width: height * aspect,
          marginBottom: 16,
        }}
      >
        <div className="actions">
          {!photo.isJp ? (
            <Tooltip title="Delete Photo">
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => setDeleting(photo)}
                style={{ marginRight: -8, marginBottom: 0 }}
              />
            </Tooltip>
          ) : (
            <div style={{ height: 48 }} />
          )}
        </div>
        <div
          style={{
            position: 'relative',
            height,
            width: height * aspect,
          }}
        >
          <div
            style={{
              backgroundColor: sharedSettings.colors.darkGray,
              height,
              width: height * aspect,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ color: 'white' }}>
              {holisticProject.projectName &&
                holisticProject.projectName[0].toUpperCase()}
            </div>
          </div>
          <img
            src={photo.photoUrl}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height,
              width: height * aspect,
              aspectRatio: aspect,
            }}
          />
          {/* </div> */}
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        {photo.isPrimary ? (
          <Text style={{ color: sharedSettings.colors.success }}>
            <CheckOutlined style={{ marginRight: 8 }} />
            Primary
          </Text>
        ) : (
          <Button
            loading={loading}
            onClick={() => {
              makePrimary(photo._id);
            }}
          >
            Make Primary
          </Button>
        )}
      </div>

      <style jsx>{`
        .actions {
          display: flex;
          justify-content: flex-end;
        }
        .container {
          display: flex;
          justify-content: center;
          position: relative;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${sharedSettings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
        .primary {
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
      `}</style>
    </div>
  );
}

export default HpPhotoCard;
