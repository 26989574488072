import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Form,
  InputNumber,
  Modal,
  notification,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createMouAction } from '../../redux-store/mous-store';
import settings from '../../settings';
import { ContactTypes } from '../contacts/constants';
import SelectContact from '../contacts/SelectContact';
import {
  allMouTemplatesForAdminQuery,
  createMouFromTemplateMutation,
  extractMousForAdmin,
} from './constants';
import SelectRegionForAdmin from '../people_groups/ListPeopleGroups/SelectRegionForAdmin';

const { Text, Title, Paragraph } = Typography;

function SelectMouTemplate({ value, onChange, ...props }) {
  const {
    error,
    loading,
    data: mous,
    search: mousSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allMouTemplatesForAdminQuery,
    extract: extractMousForAdmin,
    first: settings.querySize,
    filters: {
      isTemplate: true,
    },
    fetchPolicy: 'network-only',
    queryId: 'selectMouTemplate',
  });
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <Select
      value={value}
      onChange={onChange}
      {...props}
      optionLabelProp="label"
    >
      {(mous || []).map((agreement) => {
        const updated = `Updated ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'full',
        }).format(new Date(agreement.updatedAt))}`;
        return (
          <Select.Option
            key={agreement._id}
            value={agreement._id}
            label={`${agreement.name} - ${updated}`}
          >
            <div
              style={{
                fontWeight: 600,
                color: sharedSettings.colors.primary,
                fontSize: 14,
              }}
            >
              {agreement.name}
            </div>
            <div>{updated}</div>
            <div>
              {agreement.parties &&
                agreement.parties.map((p) => (
                  <div style={{ fontSize: 12 }} key={p._id}>{`${p.name}`}</div>
                ))}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
}

function SelectTemplateForm({ loading, handleSave, defaultVersionNo }) {
  const [form] = Form.useForm();
  return (
    <>
      <Title level={4}>Select a Partnership Commitment Form Template</Title>
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={handleSave}
      >
        <Form.Item
          name="templateId"
          label="Template"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectMouTemplate placeholder="Select ..." disabled={loading} />
        </Form.Item>
        <Form.Item
          name="contactId"
          label="Contact"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectContact
            placeholder="Select ..."
            disabled={loading}
            filters={{ contactType: ContactTypes.CONTACT.key }}
          />
        </Form.Item>
        <Form.Item
          name="peopleGroupId"
          label="People Group"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectPeopleGroup placeholder="Select ..." disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Version"
          name="versionNo"
          initialValue={defaultVersionNo}
        >
          <InputNumber disabled={loading} min={0} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
              loading={loading}
            >
              Create Partnership Commitment Form
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

const CreateMouFromTemplateModal = ({ visible, onFinish, onCancel }) => {
  const { createId, defaultVersionNo } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [createMouFromTemplate] = useMutation(createMouFromTemplateMutation);

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const result = await createMouFromTemplate({
          variables: {
            newId: createId,
            ...values,
          },
        });
        dispatch(
          createMouAction(result.data.createMouFromTemplate.mou, 'view-mou'),
        );
        notification.success({
          message: 'Saved',
          description: 'Partnership Commitment Form created successfully',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, createId, createMouFromTemplate, onFinish],
  );

  return (
    <>
      <Modal
        header={null}
        visible={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          Create an Partnership Commitment Form
        </Title>
        {visible && (
          <SelectTemplateForm
            handleSave={handleSave}
            loading={loading}
            defaultVersionNo={defaultVersionNo}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreateMouFromTemplateModal;
