import { EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMouAction } from '../../../redux-store/mous-store';
import { sendMouMutation } from '../constants';
import { AgreementStatuses } from '../../loan-agreements/constants';

const { Title, Text, Paragraph } = Typography;

const SendMouForSigningBtn = ({ mou, setError, error, queryId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [sendMou] = useMutation(sendMouMutation);
  const dispatch = useDispatch();

  const [precheck, setPrecheck] = useState();
  useEffect(() => {
    let err;
    if (mou) {
      mou.parties &&
        mou.parties.forEach((party) => {
          if (!party.contactId) {
            err = 'You must select a contact for each party before sending';
          }
        });
    }
    setPrecheck(err);
  }, [mou]);

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await sendMou({
        variables: {
          mouId: mou._id,
          status: AgreementStatuses.PREVIEW.key,
        },
      });
      dispatch(updateMouAction(result.data.sendMou.mou, queryId));
      notification.success({
        message: 'Saved',
        description: 'Partnership Commitment Form updated successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: err.message,
      });
      setError(err.message);
    }
    setLoading(false);
  }, [setError, dispatch, queryId, sendMou, mou, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<SendOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Send Partnership Commitment Form
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Send Partnership Commitment Form
        </Title>
        {precheck ? (
          <div style={{ textAlign: 'center' }}>
            <Paragraph style={{ marginBottom: 24 }} type="danger">
              {precheck}
            </Paragraph>
            <div>
              <Button type="primary" onClick={() => setShowingModal(false)}>
                OK
              </Button>
            </div>
          </div>
        ) : (
          <Form onFinish={handleFinish} layout="vertical">
            <Form.Item>
              <Paragraph style={{ textAlign: 'center' }}>
                {`You are about to send this Partnership Commitment Form to each of the parties listed in the Partnership Commitment Form.  You will not be able to unsend or edit this Partnership Commitment Form after sending.  If you desire to make a change, you'll have to cancel and re-create the Partnership Commitment Form.`}
              </Paragraph>
              <Paragraph style={{ textAlign: 'center' }}>
                {`Each party will be asked to sign the Partnership Commitment Form.  All parties will be notified when new signatures are applied.`}
              </Paragraph>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <div
                style={{
                  marginRight: -8,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => setShowingModal(null)}
                  htmlType="button"
                  disabled={loading}
                  style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  icon={<SendOutlined />}
                >
                  Send
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default SendMouForSigningBtn;
