import React from 'react';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import SelectLanguage from './SelectLanguage';

function LanguagesFormItem({ name, label, saving, style }) {
  return (
    <Form.Item
      name={name}
      label={label}
      extra={
        <div style={{ paddingTop: 4 }}>
          <Link to="/manage-languages">Manage Languages</Link>
        </div>
      }
      style={style}
    >
      <SelectLanguage disabled={saving} />
    </Form.Item>
  );
}

export default LanguagesFormItem;
