import sharedSettings from '@aims/shared/sharedSettings';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Form, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import SelectContact from '../../contacts/SelectContact';
import { ContactTypes } from '../../contacts/constants';
import TaskSomethingForm from './TaskSomethingForm';

const { Paragraph, Title } = Typography;

function DisplayContactShort({ contact }) {
  return (
    <div style={{ display: 'flex' }}>
      <PhotoCircle
        photoUrl={contact.primaryThumbnailUrl}
        name={contact.fullName}
        style={{
          height: 60,
          width: 60,
          paddingBottom: 'unset',
          marginRight: 16,
        }}
        color={ContactTypes[contact.contactType].color}
      />
      <div style={{ marginRight: 8 }}>
        <Paragraph style={{ marginBottom: 0 }}>{contact.fullName}</Paragraph>
        <Paragraph
          style={{ color: sharedSettings.colors.textGray, marginBottom: 0 }}
        >
          {ContactTypes[contact.contactType]?.label}
        </Paragraph>
        <div>
          <Link to={`/contact/view/${contact._id}/dashboard`}>
            Contact Page
          </Link>
        </div>
      </div>
      <div>
        {contact.primaryPhoneNumber && (
          <Paragraph style={{ marginBottom: 0 }}>
            <PhoneOutlined style={{ marginRight: 8 }} />
            {contact.primaryPhoneNumber}
          </Paragraph>
        )}
        {contact.primaryEmail && (
          <Paragraph style={{ marginBottom: 0 }}>
            <MailOutlined style={{ marginRight: 8 }} />
            {contact.primaryEmail}
          </Paragraph>
        )}
      </div>
    </div>
  );
}

const AssociatedPeople = ({ onSubmit, task }) => {
  const [editing, setEditing] = useState(false);
  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onFinishEdit = () => setEditing(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const { ...values } = task;
    form.setFieldsValue({
      ...values,
    });
  }, [task, form]);

  const onFinish = useCallback(
    async ({ contactId }) => {
      setSaving(true);
      try {
        await onSubmit({ contactId: contactId || null });
        onFinishEdit();
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'There was an error saving your task.',
        });
      }
      setSaving(false);
    },
    [onSubmit],
  );

  return (
    <div style={{ marginBottom: 16 }}>
      {!editing ? (
        <map onClick={onEdit} style={{ width: '100%', cursor: 'pointer' }}>
          <Title level={4}>Contact, Household, or Organization</Title>
          {task?.contact ? (
            <DisplayContactShort contact={task.contact} />
          ) : (
            <Paragraph style={{ minHeight: '36px' }}>
              Click to add people
            </Paragraph>
          )}
        </map>
      ) : (
        <TaskSomethingForm
          title="Contact, Household, or Organization"
          form={form}
          onFinish={onFinish}
          onCancel={onCancelEdit}
          saving={saving}
        >
          <Form.Item
            label="Contact, Household, Organization"
            extra="Associate this task with a contact, household, or organization"
            name="contactId"
          >
            <SelectContact disabled={saving} filters={{}} />
          </Form.Item>
        </TaskSomethingForm>
      )}
    </div>
  );
};

export default AssociatedPeople;
