import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Button, Form, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import settings from '../../../settings';
import DeleteTrainingFileModal from './DeleteTrainingFileModal';
import FilterTrainingFiles from './FilterTrainingFiles';
import TrainingFileModal from './TrainingFileModal';
import TrainingFilesTable from './TrainingFilesTable';

const { Text } = Typography;

const queryId = 'allTrainingFiles';

const allTrainingFilesForQuery = gql`
  query AllTrainingFilesForAdmin(
    $first: Int
    $after: String
    $filters: TrainingFileFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allTrainingFilesForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          fileName
          desc
          courseId
          moduleId
          file {
            _id
            desc
            s3Key
            size
            filename
            contentType
            tags
            url
            uploaded
            processed
          }
          fileId
          language
          createdBy
          updatedBy
          deletedBy
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

const extractAllTrainingFilesForAdmin = (data) =>
  data && data.allTrainingFilesForAdmin;

function AllTrainingFiles({ refetchRef }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ courseId: 'unassigned' });

  const {
    error,
    loading,
    data: trainingFiles,
    search: fileSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTrainingFilesForQuery,
    extract: extractAllTrainingFilesForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  refetchRef.current = refetch;

  const onDownload = useCallback((file) => {
    const link = document.createElement('a');
    link.download = file.file.filename;
    link.href = file.file.url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const [updating, setUpdating] = useState();
  const onUpdate = (o) =>
    setUpdating({
      trainingFile: o,
    });
  const onCancelUpdate = () => setUpdating(undefined);
  const onFinishUpdate = () => {
    refetch();
    setUpdating(undefined);
  };

  const [deleting, setDeleting] = useState();
  const onDelete = (o) => setDeleting(o);
  const onCancelDelete = () => setDeleting(undefined);
  const onFinishDelete = () => {
    refetch();
    setDeleting(undefined);
  };

  return (
    <>
      <FilterTrainingFiles
        filters={filters}
        refetch={refetch}
        fileSearch={fileSearch}
        loading={loading}
        sortBy={sortBy}
        initialSortOrder={sortOrder}
        initialSortedBy={sortedBy}
      />
      <TrainingFilesTable
        trainingFiles={trainingFiles}
        loading={loading}
        refetchRef={refetchRef}
        onEdit={onUpdate}
        onDelete={onDelete}
        onDownload={onDownload}
      />
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && <Button onClick={next}>Load More</Button>}
      </div>
      <TrainingFileModal
        visible={updating}
        onCancel={onCancelUpdate}
        onFinish={onFinishUpdate}
      />
      <DeleteTrainingFileModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </>
  );
}

export default AllTrainingFiles;
