export const READ_TRAINING_COURSES = 'READ_TRAINING_COURSES';
export const CREATE_TRAINING_COURSE = 'CREATE_TRAINING_COURSE';
export const UPDATE_TRAINING_COURSE = 'UPDATE_TRAINING_COURSE';
export const DELETE_TRAINING_COURSE = 'DELETE_TRAINING_COURSE';

// eslint-disable-next-line no-shadow
export const readTrainingCoursesAction = (activities, queryId = 'default') => ({
  type: READ_TRAINING_COURSES,
  payload: {
    activities,
    queryId,
  },
});

export const createTrainingCourseAction = (
  trainingCourse,
  queryId = 'default',
) => ({
  type: CREATE_TRAINING_COURSE,
  payload: {
    trainingCourse,
    queryId,
  },
});

export const updateTrainingCourseAction = (
  trainingCourse,
  queryId = 'default',
) => ({
  type: UPDATE_TRAINING_COURSE,
  payload: {
    trainingCourse,
    queryId,
  },
});

export const deleteTrainingCourseAction = (
  trainingCourseId,
  queryId = 'default',
) => ({
  type: DELETE_TRAINING_COURSE,
  payload: {
    trainingCourseId,
    queryId,
  },
});

const initialTrainingCourses = { default: {} };

export function trainingCourses(state = initialTrainingCourses, action) {
  switch (action.type) {
    case READ_TRAINING_COURSES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.activities.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_TRAINING_COURSE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.trainingCourse._id]: action.payload.trainingCourse,
        },
      };
    case UPDATE_TRAINING_COURSE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.trainingCourse._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.trainingCourse._id]: {
            ...current,
            ...action.payload.trainingCourse,
          },
        },
      };
    }
    case DELETE_TRAINING_COURSE: {
      const { trainingCourseId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [trainingCourseId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
