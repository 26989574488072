import UserQuestions from '@aims/shared/users/questions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, notification, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import ProfilePhotoFormItemJ24 from '../../profile-photo/ProfilePhotoFormItemJ24';
import { editProfileMutation } from '../constants';

const { Text } = Typography;

function EditProfileForm({
  _id,
  handleCancel,
  handleSuccess,
  userResult,
  loading,
  setLoading,
  style,
  fieldsToSet = {},
}) {
  const [updateUser] = useMutation(editProfileMutation);

  const [user, setUser] = useState();
  const [form] = Form.useForm();

  const onlyOnce = useRef(false);
  useEffect(() => {
    if (!userResult.loading && !onlyOnce.current) {
      if (userResult.user) {
        setUser(userResult.user);
        onlyOnce.current = true;
      }
    }
  }, [userResult, loading]);

  useEffect(() => {
    if (user) {
      const { ...otherFields } = user;
      form.setFieldsValue(otherFields);
    }
  }, [user, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      const { confirm, ...values } = _values;
      try {
        if (user) {
          const result = await updateUser({
            variables: {
              profile: {
                _id,
                ...values,
                ...UserQuestions.phone.finish(values),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Profile updated successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [user, _id, updateUser, fieldsToSet, setLoading, handleSuccess],
  );

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      id="editUser"
      form={form}
      style={style}
    >
      <ProfilePhotoFormItemJ24
        name="profilePhotoId"
        existing={
          user && {
            _id: user.profilePhotoId,
            thumbnailUrl: user.primaryThumbnailUrl,
          }
        }
        styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
        parentType="user"
        parentId={user?._id}
        tags={['User', 'Profile Photo']}
        saving={loading}
        setSaving={setLoading}
      />
      <UserQuestions.username.Display user={user} />
      <UserQuestions.name.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <UserQuestions.email.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <UserQuestions.phone.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <ShowErrorsFormItem />
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={loading} htmlType="submit">
            Save Profile
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default EditProfileForm;
