export const ContactTypes = {
  CONTACT: {
    key: 'CONTACT',
    label: 'Contact',
    color: '#006d75',
  },
  HOUSEHOLD: {
    key: 'HOUSEHOLD',
    label: 'Household',
    color: '#cf1322',
  },
  ORG: {
    key: 'ORG',
    label: 'Organization',
    color: '#d4380d',
  },
};
