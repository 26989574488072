import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const allJPScalesQuery = gql`
  query AllJPScales {
    allJPScales {
      _id
      name
    }
  }
`;

function SelectJPScale({ disabled, value, onChange }) {
  const { loading, error, data } = useQuery(allJPScalesQuery);
  const scales = useMemo(() => {
    if (data) {
      return data.allJPScales;
    }
    return [];
  }, [data]);
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a scale"
      value={value}
      allowClear
      loading={loading}
    >
      {scales.map((scale) => (
        <Select.Option key={scale._id} value={scale._id} title={scale.name}>
          <div className="scale-option">
            <div>
              <Text>{scale.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectJPScale;
