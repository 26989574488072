import { gql } from '@apollo/client';

export const getAttachmentUploadUrlForAdminMutation = gql`
  mutation GetAttachmentUploadUrlForAdmin(
    $attachment: GetAttachmentUploadUrlRequest!
  ) {
    getAttachmentUploadUrlForAdmin(attachment: $attachment) {
      url
      fields
    }
  }
`;

export const saveAttachmentForAdminMutation = gql`
  mutation SaveAttachmentForAdmin($attachment: SaveAttachmentInput!) {
    saveAttachmentForAdmin(attachment: $attachment) {
      _id
      desc
      s3Key
      size
      filename
      contentType
      tags
      url
      uploaded
      processed
      attachedTo
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
