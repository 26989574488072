import { Button, Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SelectRegionForAdmin from '../../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactChangeRegionInnerModal({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        regionCode: contact.regionCode,
      });
    }
  }, [contact, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { regionCode, ...values } = _values;
      handleFinish({
        regionCode,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Change Region"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Contact Region"
        name="regionCode"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <SelectRegionForAdmin disabled={saving} />
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactChangeRegionInnerModal;
