import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import { PgQuarterlyReportItems } from '@aims/shared/reports';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useProfile from '../../shared/use-profile';
import useQueryParams from '../../shared/use-query-params';
import DeleteQuarterlyUpgReportModal from './DeleteQuarterlyUpgReportModal';
import {
  createUpgQuarterlyReportForAdminMutation,
  extractUpgQuarterlyReport,
  submitUpgQuarterlyReportForAdminMutation,
  unsubmitUpgQuarterlyReportForAdminMutation,
  updateUpgQuarterlyReportForAdminMutation,
  upgQuarterlyReportQuery,
} from './constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Title, Text } = Typography;

const queryId = 'editPgReport';

function EditUpgQuarterlyReport() {
  const { reportId, peopleGroupId } = useParams();

  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: upgQuarterlyReportQuery,
    extract: extractUpgQuarterlyReport,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    queryId,
    showError: false,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const title = useMemo(() => {
    if (reportLoading) {
      return 'UPG Quarterly Report';
    }
    if (report) {
      const status = ReportApprovalStatuses[report.status];
      return (
        <>
          <Title style={{ marginBottom: 16 }}>Edit UPG Quarterly Report</Title>
          {status && (
            <div style={{ color: status.color, fontWeight: 600, fontSize: 18 }}>
              {status.label}
            </div>
          )}
        </>
      );
    }
    return 'Create UPG Quarterly Report';
  }, [reportLoading, report]);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/upg-quarterly-reports/approved`;
    }
  }, [query]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [updateReport] = useMutation(updateUpgQuarterlyReportForAdminMutation);
  const [createReport] = useMutation(createUpgQuarterlyReportForAdminMutation);
  const [submitReport] = useMutation(submitUpgQuarterlyReportForAdminMutation);
  const [unsubmitReport] = useMutation(
    unsubmitUpgQuarterlyReportForAdminMutation,
  );
  const shouldSubmitReport = useRef();
  const history = useHistory();
  const finishSubForm = useRef();
  const profile = useProfile();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Report ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Report ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      const { notes, ..._values } = __values;
      try {
        const reportDetails = finishSubForm.current(_values);
        if (report && shouldSubmitReport.current === 'unsubmit') {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report unsubmitted successfully',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: 'PG_QUARTERLY',
                notes,
                peopleGroupId,
                ...reportDetails,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report updated successfully',
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: profile.contactId,
                reportType: 'PG_QUARTERLY',
                notes,
                peopleGroupId,
                ...reportDetails,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report created successfully',
          });
        }
        if (shouldSubmitReport.current === 'submit') {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report submitted successfully',
          });
        }
        history.push(
          `/upg-quarterly-reports/review/${reportId}?from=${location.pathname}`,
        );
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
      peopleGroupId,
      profile,
    ],
  );

  const [form] = Form.useForm();
  const [deleting, setDeleting] = useState(null);

  const loading = reportLoading || saveLoading;
  const showDelete = report && !report.submittedAt;
  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Edit Quarterly People Group Report"
      >
        <CSPageHeader
          titleComponent={
            <Title style={{ textAlign: 'center', marginBottom: 32 }}>
              {title}
            </Title>
          }
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          id="EditReportForm"
          form={form}
          style={{
            maxWidth: 800,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          {reportLoading && (
            <>
              <Title level={3} style={{ textAlign: 'center' }}>
                {title}
              </Title>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
            </>
          )}
          {!reportLoading && (
            <>
              <PgQuarterlyReportItems
                _id={reportId}
                report={report}
                loading={loading}
                form={form}
                finishSubForm={finishSubForm}
                fieldsToSet={{
                  peopleGroupId: report ? report.peopleGroupId : peopleGroupId,
                }}
              />
              <ShowErrorsFormItem />
              <div style={{ height: 16 }} />
              <Form.Item>
                <Space
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  {(!report ||
                    (report &&
                      report.status === ReportApprovalStatuses.DRAFT.key)) && (
                    <Button
                      key="draft"
                      type="dashed"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'draft';
                        form.submit();
                      }}
                    >
                      Save Draft
                    </Button>
                  )}
                  {(!report ||
                    (report &&
                      report.status === ReportApprovalStatuses.DRAFT.key)) && (
                    <Button
                      key="submit"
                      type="primary"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'submit';
                        form.submit();
                      }}
                    >
                      Submit Report
                    </Button>
                  )}
                  {report &&
                    report.status === ReportApprovalStatuses.PENDING.key && (
                      <Button
                        key="unsubmit"
                        type="dashed"
                        loading={saveLoading}
                        htmlType="button"
                        onClick={() => {
                          shouldSubmitReport.current = 'unsubmit';
                          form.submit();
                        }}
                      >
                        Unsubmit Report
                      </Button>
                    )}
                  {report && report.status === ReportApprovalStatuses.HOLD.key && (
                    <Button
                      key="re-submit"
                      type="primary"
                      loading={saveLoading}
                      htmlType="button"
                      onClick={() => {
                        shouldSubmitReport.current = 'submit';
                        form.submit();
                      }}
                    >
                      Re-Submit Report
                    </Button>
                  )}
                  {report &&
                    (report.status === ReportApprovalStatuses.HOLD.key ||
                      report.status === ReportApprovalStatuses.PENDING.key) && (
                      <Button
                        key="save"
                        type="primary"
                        loading={saveLoading}
                        htmlType="button"
                        onClick={() => {
                          shouldSubmitReport.current = 'save';
                          form.submit();
                        }}
                      >
                        Save Report
                      </Button>
                    )}
                </Space>
              </Form.Item>
            </>
          )}
        </Form>
        {showDelete && (
          <div>
            <Divider />
            <div className="delete-box">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => setDeleting(report)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete Report
                </Button>
              </div>
            </div>
          </div>
        )}
        <Modal open={saveLoading} footer={null} closable={false} width={300}>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
            <Title level={4}>{saveLoading}</Title>
          </div>
        </Modal>
        <DeleteQuarterlyUpgReportModal
          report={deleting}
          setReport={setDeleting}
        />
        <style jsx>{`
          .title {
            display: flex;
            padding-top: 32px;
          }
          .left {
            padding-right: 32px;
          }
          .subtitle {
            margin-bottom: 16px;
          }
        `}</style>
        <style jsx global>{`
          .church {
            border: 1px solid ${sharedSettings.colors.borderGray};
          }
        `}</style>
      </CSPage>
    </>
  );
}

export default EditUpgQuarterlyReport;
