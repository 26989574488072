import {
  doFileUpload,
  generateUuid,
  toBase64,
} from '@aims/shared/shared/utils';
import { notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ProfilePhotoDisplayJ24 from './ProfilePhotoDisplayJ24';

function ProfilePhotoInnerJ24({
  existing,
  parentType,
  parentId,
  getAttachmentMutation,
  extractGetAttachment,
  savePhotoMutation,
  tags,
  saving,
  setSaving,
  onChange,
}) {
  const [progress, setProgress] = useState();
  const [file, setFile] = useState(undefined);

  const memo = useRef('');
  useEffect(() => {
    const s = JSON.stringify(existing);
    if (s !== memo.current) {
      setFile(existing);
      memo.current = s;
    }
  }, [existing]);

  const doUpload = useCallback(
    async (obj) => {
      try {
        setSaving?.(true);
        setProgress(0);
        const profilePhotoId = generateUuid();
        const s3Key = `${parentType}:${parentId}:profile:${profilePhotoId}`;

        let base64Url;
        if (obj.file.type.startsWith('image')) {
          base64Url = await toBase64(obj.file);
        }
        setFile({
          _id: profilePhotoId,
          s3Key,
          size: obj.file.size,
          filename: obj.file.name,
          contentType: obj.file.type,
          thumbnailUrl: base64Url,
        });

        let response;
        response = await getAttachmentMutation({
          variables: {
            attachment: {
              _id: profilePhotoId,
              s3Key,
            },
          },
        });

        setProgress(0);
        const responseData = extractGetAttachment(response.data);
        await doFileUpload({
          url: responseData.url,
          fields: responseData.fields,
          file: obj.file,
          onProgress: (event) => {
            setProgress((event.loaded / event.total) * 100);
          },
        });
        setTimeout(() => {
          setProgress(undefined);
        }, 1000);

        response = await savePhotoMutation({
          variables: {
            profilePhoto: {
              _id: profilePhotoId,
              filename: obj.file.name,
              s3Key,
              profilePhotoFor: parentId,
              tags,
              uploaded: true,
            },
          },
        });
        onChange(profilePhotoId);
        notification.success({
          message: 'Success',
          description: 'Photo uploaded successfully.',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving?.(false);
    },
    [
      parentType,
      parentId,
      extractGetAttachment,
      getAttachmentMutation,
      savePhotoMutation,
      setSaving,
      tags,
      onChange,
    ],
  );

  const doDelete = useCallback(() => {
    setFile(null);
    onChange(null);
  }, [onChange]);

  return (
    <ProfilePhotoDisplayJ24
      thumbnailUrl={file?.thumbnailUrl}
      progress={progress}
      doUpload={doUpload}
      saving={saving}
      doDelete={doDelete}
    />
  );
}

export default ProfilePhotoInnerJ24;
