import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const allJPContinentsQuery = gql`
  query AllJPContinents {
    allJPContinents {
      _id
      name
    }
  }
`;

function SelectContinent({ disabled, value, onChange }) {
  const { loading, error, data } = useQuery(allJPContinentsQuery);
  // const data = null;
  // const loading = false;
  // const error = null;
  const continents = useMemo(() => {
    if (data) {
      return data.allJPContinents;
    }
    return [];
  }, [data]);
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a continent"
      value={value}
      allowClear
      loading={loading}
    >
      {continents.map((continent) => (
        <Select.Option
          key={continent._id}
          value={continent._id}
          title={continent.name}
        >
          <div className="continent-option">
            <div>
              <Text>{continent.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectContinent;
