import React, { useMemo } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import HolisticQuarterlyReportsTabs from '../holistic-quarterly-reports/HolisticQuarterlyReportsTabs';
import { Button, Typography } from 'antd';
import { FormOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { generateUuid } from '@aims/shared/shared/utils';
import useProfile from '../../shared/use-profile';
import { Link } from 'react-router-dom';

const { Title } = Typography;

function ListHolisticProjectReportsPage() {
  const createId = useMemo(() => {
    return generateUuid();
  }, []);

  const profile = useProfile();
  const permissions = profile?.permissions;
  const canCreateReport = useMemo(() => {
    return permissions?.find(
      (p) => p.prefix === 'create-admin-holistic-quarterly-report',
    );
  }, [permissions]);
  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Holistic Project Quarterly Reports"
      >
        <CSPageHeader
          titleComponent={
            <Title>
              <FormOutlined style={{ marginRight: 16 }} />
              {'Holistic Project Quarterly Reports'}
            </Title>
          }
          topActions={
            canCreateReport && [
              <Link
                key="add"
                to={`/holistic-quarterly-reports/edit/${createId}`}
              >
                <Button type="link" icon={<PlusCircleOutlined />} size="large">
                  Create a new report
                </Button>
              </Link>,
            ]
          }
        />
        <HolisticQuarterlyReportsTabs baseUrl="/holistic-quarterly-reports" />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default ListHolisticProjectReportsPage;
