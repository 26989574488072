import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ExclamationOutlined,
  MinusOutlined,
  RocketOutlined,
  StarOutlined,
} from '@ant-design/icons';
import React from 'react';

export const PeopleGroupFocusLevels = [
  {
    key: 0,
    label: 'No Focus',
    sort: 0,
    icon: <MinusOutlined />,
  },
  {
    key: 1,
    label: 'Future Focus',
    sort: 1,
    icon: <RocketOutlined />,
  },
  {
    key: 2,
    label: 'Low Focus',
    sort: 2,
    icon: <ArrowDownOutlined />,
  },
  {
    key: 3,
    label: 'Medium Focus',
    sort: 3,
    icon: <ArrowUpOutlined />,
  },
  {
    key: 4,
    label: 'High Focus',
    sort: 4,
    icons: <ExclamationOutlined />,
  },
  {
    key: 5,
    label: 'Featured',
    sort: 5,
    icon: <StarOutlined />,
  },
];

export const ReportApprovalStatuses = {
  APPROVED: {
    label: 'Approved',
    color: sharedSettings.colors.success,
  },
  REJECTED: {
    label: 'Rejected',
    color: sharedSettings.colors.error,
  },
  PENDING: {
    label: 'Pending',
    color: sharedSettings.colors.warning,
  },
  DRAFT: {
    label: 'Draft',
    color: sharedSettings.colors.error,
  },
  HOLD: {
    label: 'Hold',
    color: sharedSettings.colors.blue,
  },
};
