import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { useRef, useState, useMemo } from 'react';
import {
  createActivityAction,
  deleteActivityAction,
  readActivitiesAction,
  updateActivityAction,
} from '../../redux-store/activity-v2-store';
import settings from '../../settings';
import {
  activityUpdatesForAdminSubscription,
  allActivitiesForAdminQuery,
  extractAllActivitiesForAdmin,
} from './constants';

function useActivities({ filters, skip, sortByFunc, queryId = 'default' }) {
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters },
    subscription: activityUpdatesForAdminSubscription,
    createAction: createActivityAction,
    updateAction: updateActivityAction,
    deleteAction: deleteActivityAction,
    extractSomething: (resp) => resp?.data?.activityUpdatesForAdmin,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allActivitiesForAdminQuery,
    extract: extractAllActivitiesForAdmin,
    readAction: readActivitiesAction,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, [sortByFunc]);
  const { data, search } = useManySomethingsM20({
    table: 'activities',
    sortByFunc: defaultSort,
    searchKeys: ['descText', '_id'],
    queryId,
  });
  return {
    loading,
    search,
    data,
  };
}

export default useActivities;
