import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import React, { useRef, useState } from 'react';
import { allPayoutV2sQuery, extractPayoutV2s } from './constants';
import settings from '../../../settings';
import PayoutsV2Table from './PayoutsV2Table';
import PayoutV2Filters from './PayoutV2Filters';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import CreatePayoutV2Modal from './modals/CreatePayoutV2Modal';
import { useHistory } from 'react-router-dom';

function AllPayoutsV2() {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: payouts,
    search: payoutSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPayoutV2sQuery,
    extract: extractPayoutV2s,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'no-cache',
  });

  const history = useHistory();
  const [creating, setCreating] = useState(false);
  const onCreate = () => setCreating(true);
  const onCancelCreate = () => setCreating(false);
  const onFinishCreate = (_id) => {
    history.push(`/payout/edit/${_id}/adoptions`);
  };

  return (
    <>
      <div style={{ marginBottom: 16, marginLeft: -16 }}>
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={onCreate}
        >
          Create a New Payout
        </Button>
      </div>
      <PayoutV2Filters
        filtersRef={filters}
        refetch={refetch}
        search={payoutSearch}
        sortByRef={sortBy}
        sortedBy={sortedBy}
        sortOrder={sortOrder}
      />
      <PayoutsV2Table payouts={payouts} loading={loading} />
      {next && (
        <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
      )}
      <CreatePayoutV2Modal
        visible={creating}
        onFinish={onFinishCreate}
        onCancel={onCancelCreate}
      />
    </>
  );
}

export default AllPayoutsV2;
