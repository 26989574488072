export const READ_PAYERS = 'READ_PAYERS';
export const CREATE_PAYER = 'CREATE_PAYER';
export const UPDATE_PAYER = 'UPDATE_PAYER';
export const DELETE_PAYER = 'DELETE_PAYER';

export const readPayersAction = (
  // eslint-disable-next-line no-shadow
  payers,
  queryId = 'default',
) => ({
  type: READ_PAYERS,
  payload: {
    payers,
    queryId,
  },
});

export const createPayerAction = (payer, queryId = 'default') => ({
  type: CREATE_PAYER,
  payload: {
    payer,
    queryId,
  },
});

export const updatePayerAction = (payer, queryId = 'default') => ({
  type: UPDATE_PAYER,
  payload: {
    payer,
    queryId,
  },
});

export const deletePayerAction = (payerId, queryId = 'default') => ({
  type: DELETE_PAYER,
  payload: {
    payerId,
    queryId,
  },
});

const initialPayers = { default: {} };

export function payers(state = initialPayers, action) {
  switch (action.type) {
    case READ_PAYERS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.payers.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_PAYER:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payer._id]: action.payload.payer,
        },
      };
    case UPDATE_PAYER: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.payer._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payer._id]: {
            ...current,
            ...action.payload.payer,
          },
        },
      };
    }
    case DELETE_PAYER: {
      const { payerId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [payerId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
