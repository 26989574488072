import { Divider } from 'antd';
import React from 'react';
import { TrainingEventReportQuestions } from './questions';

function ViewTrainingEventReport({ adminSection, report }) {
  return (
    <div className="container">
      <TrainingEventReportQuestions.title.Display report={report} />
      <TrainingEventReportQuestions.submittedBy.Display report={report} />
      <TrainingEventReportQuestions.approvedBy.Display report={report} />
      {adminSection && (
        <>
          <div style={{ height: 8 }} />
          {adminSection}
        </>
      )}
      <Divider>Event Details</Divider>
      <TrainingEventReportQuestions.regionCode.Display report={report} />
      <TrainingEventReportQuestions.eventDate.Display report={report} />
      <TrainingEventReportQuestions.hostName.Display report={report} />
      <TrainingEventReportQuestions.hostWebsite.Display report={report} />
      <TrainingEventReportQuestions.eventAddress.Display report={report} />
      <Divider>Training</Divider>
      <TrainingEventReportQuestions.numPeopleTrained.Display report={report} />
      <TrainingEventReportQuestions.itsmModulesTaught.Display report={report} />
      <TrainingEventReportQuestions.trainersTrained.Display report={report} />
      <TrainingEventReportQuestions.newPartnerships.Display report={report} />
      <TrainingEventReportQuestions.numUpgAdoptions.Display report={report} />
      <TrainingEventReportQuestions.newAdoptions.Display report={report} />
      <TrainingEventReportQuestions.miracles.Display report={report} />
      <TrainingEventReportQuestions.publicFiles.Display report={report} />
      <TrainingEventReportQuestions.privateFiles.Display report={report} />
      <TrainingEventReportQuestions.notes.Display report={report} />
      <style jsx>{``}</style>
    </div>
  );
}

export default ViewTrainingEventReport;
