import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Select, Typography } from 'antd';
import React, { useMemo, useRef } from 'react';
import { extractAllAccounts } from '../constants';
import { AccountTypesJ25 } from '../constants';
import sharedSettings from '@aims/shared/sharedSettings';
import settings from '../../../../settings';

const { Text } = Typography;

export const selectAccountQuery = gql`
  query AllAccounts(
    $first: Int
    $after: String
    $filters: AccountFilters
    $sortBy: [SortBy]
  ) {
    allAccounts(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          name
          description
          type
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

function SelectAccount({ value, onChange, filters, style, ...props }) {
  const sortBy = useRef([
    { key: 'updatedAt', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: accounts,
    search: accountSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectAccountQuery,
    extract: extractAllAccounts,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'select-holistic-project',
  });

  const options = useMemo(() => {
    if (accounts) {
      return accounts.map((item) => {
        return {
          value: item._id,
          name: item.name,
          desc: item.description,
          typeLabel: AccountTypesJ25[item.type]?.label,
          title: item.name,
        };
      });
    }
    return [];
  }, [accounts]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select an account"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => accountSearch(v)}
      loading={loading}
      labelRender={(option) => {
        return (
          <div
            style={{ color: sharedSettings.colors.primary, fontWeight: 600 }}
          >
            {option.title}
          </div>
        );
      }}
      optionRender={(option) => (
        <div>
          <div
            style={{ color: sharedSettings.colors.primary, fontWeight: 600 }}
          >
            {option.data.name}
          </div>
          {option.data.typeLabel && (
            <div style={{ color: sharedSettings.colors.textGray }}>
              {option.data.typeLabel}
            </div>
          )}
          {option.data.desc && <div>{option.data.desc}</div>}
        </div>
      )}
      {...props}
    />
  );
}

export default SelectAccount;
