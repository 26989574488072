import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { useMutation } from '@apollo/client';
import { Divider, Skeleton, Typography, message, notification } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import triangleLogo from '../../assets/white-square-logo.png';
import { MyDatePicker } from '@aims/shared/components/DateFormItem';
import { updateLoanAgreementAction } from '../../redux-store/loan-agreements-store';
import SelectCountry from '../people_groups/ListPeopleGroups/SelectCountry';
import AttachmentsElement from './AttachmentsElement';
import EditableOneLineElement from './EditableOneLineElement';
import EditableTextElement from './EditableTextElement';
import PartiesElement from './PartiesElement';
import SectionsElement from './SectionsElement';
import { AgreementStatuses, updateLoanAgreementMutation } from './constants';
import ReviewButtons from './review-buttons/ReviewButtons';

const { Title, Paragraph } = Typography;

function ProjectDetails({ project }) {
  const projectType = HolisticProjectTypes[project && project.typeOfProject];
  return (
    <div style={{ textAlign: 'left' }}>
      <Divider />
      {project && (
        <>
          <div style={{ fontSize: 16, color: sharedSettings.colors.textGray }}>
            Holistic Project
          </div>
          <Title level={2} style={{ margin: 0 }}>
            {project.projectName}
          </Title>
          {projectType && <div>{projectType.label}</div>}
          <div
            style={{
              marginTop: 8,
              marginBottom: 0,
              fontSize: 16,
              color: sharedSettings.colors.textGray,
            }}
          >{`Loan Amount: ${displayMoney(project.loanTotalX4 / 10000)}`}</div>
          <div
            style={{
              marginTop: 8,
              marginBottom: 16,
              fontSize: 16,
              color: sharedSettings.colors.textGray,
              lineHeight: 1,
            }}
          >{`Loan Term: ${getNumberValue(project.loanTerm)} Months`}</div>
          <Title level={4}>Beneficiaries</Title>
          {project.supportedFieldWorkers &&
            project.supportedFieldWorkers
              .map((fw) => fw.contact && fw.contact.fullName)
              .filter((n) => n)
              .join(', ')}
        </>
      )}
      {!project && <Skeleton active />}
      <Divider />
    </div>
  );
}

function ViewLoanAgreement({ loanAgreement, queryId, backTo, refetch }) {
  const saveQueue$ = useRef();
  const handleSave = useCallback((values) => {
    saveQueue$.current.next(values);
  }, []);
  const [error, setError] = useState();

  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = loanAgreement;
  }, [loanAgreement]);
  const dispatch = useDispatch();
  const [updateLoanAgreement] = useMutation(updateLoanAgreementMutation);
  useEffect(() => {
    async function doSave(values) {
      if (values) {
        try {
          if (currentAgreement.current) {
            await updateLoanAgreement({
              variables: {
                loanAgreement: {
                  _id: currentAgreement.current._id,
                  ...values,
                },
              },
            });
            refetch();
            notification.success({
              message: 'Success',
              description: 'Loan Agreement Updated',
            });
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [updateLoanAgreement, dispatch, queryId, refetch]);

  const status = AgreementStatuses[loanAgreement.status];
  const locale = useSelector((store) => store.locale, shallowEqual);
  const history = useHistory();
  return (
    <>
      <ReviewButtons
        loanAgreement={loanAgreement}
        setError={setError}
        error={error}
        queryId={queryId}
        onSuccess={() => {}}
        onDelete={() => history.push(backTo)}
      />
      <div
        id="loanAgreement"
        style={{
          backgroundColor: 'white',
          padding: '32px 16px',
          boxShadow: `0px 0px 4px ${sharedSettings.colors.primary}`,
          borderRadius: 2,
        }}
      >
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div
            style={{
              background: sharedSettings.colors.primary,
              width: 180,
              marginRight: 32,
              marginLeft: -16,
              marginTop: -32,
              padding: 16,
            }}
          >
            <img
              style={{ width: '100%' }}
              src={triangleLogo}
              alt="Triangle Logo"
            />
          </div>
          <Title>
            Holistic Project
            <br />
            Loan Commitment
          </Title>
        </div>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <div style={{ fontSize: 18, color: sharedSettings.colors.textGray }}>
            Status
          </div>
          {status && (
            <div
              style={{
                color: sharedSettings.colors[status.color],
                fontSize: 24,
                margin: 0,
                fontWeight: 600,
              }}
            >
              {status.label}
            </div>
          )}
          <div
            style={{ fontSize: 16 }}
          >{`Version ${loanAgreement.version}`}</div>
        </div>
        {loanAgreement &&
          loanAgreement.status !== AgreementStatuses.PREVIEW.key && (
            <EditableTextElement
              name="privateNotes"
              label="Admin Notes"
              noLabel="No Notes"
              text={loanAgreement && loanAgreement.privateNotes}
              isTextArea
              handleSave={handleSave}
              parties={loanAgreement && loanAgreement.parties}
              attachments={loanAgreement && loanAgreement.attachments}
              titleLevel={4}
            />
          )}
        <ProjectDetails project={loanAgreement.project} />
        <EditableOneLineElement
          name="country"
          label="Country"
          noLabel="No Country"
          text={loanAgreement && loanAgreement.countryName}
          initialValues={{ country: loanAgreement.country }}
          handleSave={handleSave}
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
          InputElement={SelectCountry}
          readOnly={
            loanAgreement &&
            loanAgreement.status !== AgreementStatuses.PREPARING.key
          }
        />
        <EditableOneLineElement
          name="approvalDate"
          label="Approval Date"
          noLabel="Not Specified"
          text={
            loanAgreement &&
            loanAgreement.approvalDate &&
            `${new Intl.DateTimeFormat(locale, {
              dateStyle: 'long',
            }).format(new Date(loanAgreement.approvalDate))}`
          }
          initialValues={{
            approvalDate:
              loanAgreement.approvalDate &&
              moment(new Date(loanAgreement.approvalDate)),
          }}
          handleSave={handleSave}
          beforeSave={(values) => ({
            approvalDate: values.approvalDate,
          })}
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
          InputElement={MyDatePicker}
          readOnly={
            loanAgreement &&
            loanAgreement.status !== AgreementStatuses.PREPARING.key
          }
        />
        <EditableOneLineElement
          name="finalizedDate"
          label="Finalized Date"
          noLabel="Not Specified"
          text={
            loanAgreement &&
            loanAgreement.finalizedDate &&
            `${new Intl.DateTimeFormat(locale, {
              dateStyle: 'long',
            }).format(new Date(loanAgreement.finalizedDate))}`
          }
          initialValues={{
            finalizedDate:
              loanAgreement.finalizedDate &&
              moment(new Date(loanAgreement.finalizedDate)),
          }}
          handleSave={handleSave}
          beforeSave={(values) => ({
            finalizedDate: values.finalizedDate,
          })}
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
          InputElement={MyDatePicker}
          readOnly={
            loanAgreement &&
            loanAgreement.status != AgreementStatuses.PREPARING.key
          }
        />
        <Divider />
        <PartiesElement loanAgreement={loanAgreement} handleSave={handleSave} />
        <SectionsElement
          loanAgreement={loanAgreement}
          handleSave={handleSave}
        />
        <AttachmentsElement
          loanAgreementId={loanAgreement._id}
          loanAgreement={loanAgreement}
          handleSave={handleSave}
        />
        {loanAgreement &&
          loanAgreement.status !== AgreementStatuses.PREPARING.key && (
            <PartiesElement
              loanAgreement={loanAgreement}
              handleSave={handleSave}
              signing
            />
          )}
      </div>
    </>
  );
}

export default ViewLoanAgreement;
