import { useMutation } from '@apollo/client';
import { Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createTrainingCourseMutation } from '../constants';
import EditTrainingCourseForm from './EditTrainingCourseForm';

const { Title } = Typography;

const CreateTrainingCourseModal = ({ visible, onFinish, onCancel }) => {
  const { _id } = visible || {};
  const [saving, setSaving] = useState(false);
  const retrieveProfilePhoto = useRef();

  const [form] = Form.useForm();
  const [createTrainingCourse] = useMutation(createTrainingCourseMutation);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { modules, ...values } = _values;
        let _modules;
        if (modules) {
          _modules = modules.map((a) => {
            const { __typename, ...other } = a;
            return other;
          });
        }
        await createTrainingCourse({
          variables: {
            trainingCourse: {
              _id,
              modules: _modules,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Training Course created successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [createTrainingCourse, onFinish, _id],
  );

  return (
    <>
      <Modal
        open={visible}
        closable={!saving}
        destroyOnClose={true}
        maskClosable={!saving}
        onCancel={onCancel}
        footer={null}
        header={null}
      >
        <div style={{ paddingTop: 24, marginBottom: 24 }}>
          <Title level={2} style={{ textAlign: 'center' }}>
            Create a New Training Course
          </Title>
        </div>
        <div style={{ textAlign: 'left' }}>
          {visible && (
            <EditTrainingCourseForm
              onCancel={onCancel}
              saving={saving}
              form={form}
              onSubmit={onSubmit}
              _id={_id}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateTrainingCourseModal;
