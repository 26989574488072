import { Select } from 'antd';
import { TransferTypes } from '../constants';
import React from 'react';

function SelectTransferType({ value, onChange, ...props }) {
  return (
    <Select value={value} onChange={onChange} {...props}>
      {Object.values(TransferTypes).map(({ key, label }) => (
        <Select.Option key={key} value={key}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectTransferType;
