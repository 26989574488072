import { useMemo } from 'react';
import { YearlyQuarters } from '@aims/shared/reports/quarters';

export default function useQuarterLabel(payout) {
  const quarterLabel = useMemo(() => {
    const thisYear = new Date().getFullYear();
    if (payout) {
      const [year, qx] = payout.quarter
        ? payout.quarter.split('.')
        : [thisYear, 'Q1'];
      return `${YearlyQuarters[qx].label} ${year}`;
    }
    return undefined;
  }, [payout]);
  return quarterLabel;
}
