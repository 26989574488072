import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteUserAction } from '../../redux-store/users-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteUserMutation = gql`
  mutation deleteUser($_id: ID!) {
    deleteUser(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteUserModal({ user, setUser }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteUser] = useMutation(deleteUserMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteUser({
        variables: {
          _id: user._id,
        },
      });
      dispatch(deleteUserAction(user._id));
      notification.success({
        message: 'Deleted',
        description: 'User deleted successfully',
      });
      setTimeout(() => {
        setUser(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [user, deleteUser, setUser, dispatch, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setUser(null);
    }
  }, [setUser, loading]);

  return (
    <YesNoModal
      title="Delete User"
      question={`Are you sure you want to delete this user (${
        user && user.username
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!user}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteUserModal;
