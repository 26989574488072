import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import AddressFormItem from '../../components/AddressFormItem';
import DisplayAddress from '../../components/DisplayAddress';
import SelectCountry from '../../components/SelectCountry';
import SelectRegion from '../../components/SelectRegion';
import SelectPeopleGroup from '../../people-groups/SelectPeopleGroup';
import { useCountries } from '../../shared/use-countries';
import { generateUuid } from '../../shared/utils';
import sharedSettings from '../../sharedSettings';
import {
  ReportLineItem,
  reportApprovedBy,
  reportMiracles,
  reportNotes,
  reportPrivateFiles,
  reportPublicFiles,
  reportSubmittedBy,
} from '../common/ReportItems';
import { ItsmModules } from './constants';
import DateFormItem from '../../components/DateFormItem';

const { Title, Text } = Typography;

export const TrainingEventReportQuestions = {
  title: {
    Display: function Display({ report }) {
      const locale = useSelector((store) => store.locale, shallowEqual);
      const title = useMemo(() => {
        let _title = 'Training Event Report';
        if (report) {
          _title = (
            <>
              <span style={{ fontSize: 24, lineHeight: 1.5 }}>
                Training Event Report
              </span>
              <br />
              <span>{`${new Intl.DateTimeFormat(locale, {
                dateStyle: 'long',
              }).format(new Date(report.submittedAt))}`}</span>
            </>
          );
        }
        return _title;
      }, [report, locale]);
      return (
        <Title style={{ textAlign: 'center', marginBottom: 32 }}>{title}</Title>
      );
    },
  },
  submittedBy: reportSubmittedBy,
  approvedBy: reportApprovedBy,
  regionCode: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="In which region did this event occur?"
          name="regionCode"
          rules={[
            {
              required: true,
              message: 'Please select a region',
            },
          ]}
        >
          <SelectRegion disabled={loading} />
        </Form.Item>
      );
    },
    before: (report) => {
      if (report.regionCode) {
        return {
          regionCode: report.regionCode,
        };
      }
      return {
        regionCode: undefined,
      };
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="In which region did this event occur?"
          answer={report.regionName}
        />
      );
    },
  },
  eventDate: {
    FormItem: function FormItem({ loading }) {
      return (
        <DateFormItem
          label="Event Date"
          name="eventDate"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          disabled={loading}
          style={{ width: '200px' }}
        />
      );
    },
    Display: function Display({ report }) {
      const locale = useSelector((store) => store.locale, shallowEqual);
      return (
        <ReportLineItem
          question="Event Date"
          answer={
            report.eventDate != null
              ? new Intl.DateTimeFormat(locale, {
                  dateStyle: 'full',
                }).format(new Date(report.eventDate))
              : ''
          }
        />
      );
    },
  },
  hostName: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Event Host's Name"
          name="hostName"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem question="Event Host's Name" answer={report.hostName} />
      );
    },
  },
  hostWebsite: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Event Host's Website"
          name="hostWebsite"
          rules={[
            {
              pattern: /^(ftp|http|https):\/\/[^ "]+$/,
              message: 'Please enter a valid URL (http://example.com)',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="Event Host's Website"
          answer={report.hostWebsite}
        />
      );
    },
  },
  eventAddress: {
    FormItem: function FormItem({ loading, form }) {
      return (
        <Form.Item label="Event Location">
          <AddressFormItem
            loading={loading}
            name="eventAddress"
            labelName="Location Name"
            form={form}
            required={{
              label: true,
              country: true,
              city: true,
            }}
          />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 16 }}>
            <Text>Event Location</Text>
          </div>
          <div
            style={{
              textAlign: 'center',
              backgroundColor: sharedSettings.colors.borderGray,
              borderRadius: 8,
              padding: 12,
              fontSize: 14,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {report.eventAddress && (
              <DisplayAddress address={report.eventAddress} />
            )}
          </div>
        </div>
      );
    },
  },
  numPeopleTrained: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Number of people trained"
          name="numPeopleTrained"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <InputNumber
            disabled={loading}
            min={0}
            step={1}
            style={{ width: 200 }}
          />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="Number of people trained"
          answer={report.numPeopleTrained}
        />
      );
    },
  },
  itsmModulesTaught: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Which of the ITSM Foundations course modules did you finish?"
          shouldUpdate={(prevValues, curValues) =>
            prevValues.itsmModulesTaught !== curValues.itsmModulesTaught
          }
          style={{ marginBottom: 0 }}
        >
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="itsmModulesTaught"
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value.length === 0) {
                      return Promise.reject(
                        new Error('You must select at least one module'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <div style={{ height: 8 }} />
              <Table
                size="small"
                dataSource={Object.values(ItsmModules).sort(
                  (a, b) => a.sort - b.sort,
                )}
                pagination={false}
                rowKey="key"
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: getFieldValue('itsmModulesTaught'),
                  onChange: (selectedRowKeys, selectedRows) => {
                    setFieldsValue({
                      itsmModulesTaught: selectedRowKeys,
                    });
                  },
                  getCheckboxProps: (record) => ({
                    disabled: loading,
                    name: record.key,
                  }),
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      const rowKey = event.target.parentElement.dataset.rowKey;
                      const current = getFieldValue('itsmModulesTaught') || [];
                      const checked = !current.includes(rowKey);
                      setFieldsValue({
                        itsmModulesTaught: checked
                          ? [...current, rowKey].sort()
                          : current.filter((c) => c != rowKey),
                      });
                    },
                  };
                }}
                style={{
                  width: '100%',
                  boxShadow: `2px 2px 16px ${sharedSettings.colors.gray}`,
                }}
              >
                <Table.Column
                  title="Module"
                  dataIndex="key"
                  render={(text, record) => {
                    return record.sort;
                  }}
                />
                <Table.Column
                  title="Title"
                  dataIndex="label"
                  render={(text, record) => {
                    return record.label;
                  }}
                />
              </Table>
            </Form.Item>
          )}
        </Form.Item>
      );
    },
    finish: (values) => {
      return {
        itsmModulesTaught: values.itsmModulesTaught
          ? values.itsmModulesTaught.reduce((prev, curr) => {
              prev[curr] = true;
              return prev;
            }, {})
          : {},
      };
    },
    before: (report) => {
      if (report.itsmModulesTaught) {
        return {
          itsmModulesTaught: Object.keys(report.itsmModulesTaught),
        };
      }
      return {
        itsmModulesTaught: undefined,
      };
    },
    Display: function Display({ report }) {
      return (
        <div>
          <div style={{ marginTop: 32, marginBottom: 16 }}>
            <Text>
              Which of the ITSM Foundations course modules did you finish?
            </Text>
          </div>
          <Table
            size="small"
            dataSource={Object.values(ItsmModules).sort(
              (a, b) => a.sort - b.sort,
            )}
            pagination={false}
            rowKey="key"
            style={{
              width: '100%',
              boxShadow: `2px 2px 16px ${sharedSettings.colors.gray}`,
            }}
          >
            <Table.Column
              title="Module"
              dataIndex="key"
              render={(text, record) => {
                return record.sort;
              }}
            />
            <Table.Column
              title="Title"
              dataIndex="label"
              render={(text, record) => {
                return record.label;
              }}
            />
            <Table.Column
              title="Completed"
              dataIndex="completed"
              render={(text, record) => {
                return (report.itsmModulesTaught || {})[record.key]
                  ? 'Yes'
                  : 'No';
              }}
            />
          </Table>
        </div>
      );
    },
  },
  trainersTrained: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item label="Trainers Certified">
          <div style={{ height: 8 }} />
          <Form.List name="trainersTrained">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} noStyle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        border: `1px solid ${sharedSettings.colors.gray}`,
                        boxShadow: `0px 0px 8px ${sharedSettings.colors.borderGray}`,
                        padding: 16,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            label="Name"
                            name={[field.name, 'name']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="Email"
                            name={[field.name, 'email']}
                            rules={[
                              {
                                type: 'email',
                                message:
                                  'Please enter a valid email (ex. aaron@gmail.com).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Phone"
                            name={[field.name, 'phone']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="WhatsApp"
                            name={[field.name, 'whatsApp']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Country"
                            name={[field.name, 'country']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <SelectCountry />
                          </Form.Item>
                          <Form.Item
                            label="Name of Church or Organization"
                            name={[field.name, 'orgName']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={loading}
                  >
                    Add Certified Trainer
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    finish: (values) => {
      return {
        trainersTrained: values.trainersTrained
          ? values.trainersTrained.map((c) => {
              return {
                _id: c._id || generateUuid(),
                name: c.name,
                email: c.email,
                phone: c.phone,
                whatsApp: c.whatsApp,
                country: c.country,
                orgName: c.orgName,
              };
            })
          : [],
      };
    },
    Display: function Display({ report }) {
      const { countries } = useCountries();
      return (
        <>
          <Title level={2} style={{ marginTop: 32 }}>
            Trainers Certified
          </Title>
          {report.trainersTrained &&
            report.trainersTrained.map((trainer, index) => {
              let countryName;
              if (countries && countries.length && trainer.country) {
                const country = countries.find(
                  (c) => c.shortCode === trainer.country,
                );
                countryName = country && country.name;
              }
              return (
                <div key={index} style={{ marginBottom: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                      borderRadius: 8,
                      padding: 12,
                      fontSize: 14,
                      backgroundColor: sharedSettings.colors.borderGray,
                    }}
                  >
                    <Text strong>{trainer.name}</Text>
                    <br />
                    {[
                      trainer.email || 'No email',
                      trainer.phone || 'No phone',
                      trainer.whatsApp || 'No WhatsApp',
                    ].join(', ')}
                    <br />
                    {countryName || 'No country'}
                    <br />
                    {trainer.orgName || 'No organization or church name'}
                  </div>
                </div>
              );
            })}
          {(!report.trainersTrained || !report.trainersTrained.length) && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No trainers certified at this event"
            />
          )}
        </>
      );
    },
  },
  newPartnerships: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item label="New Ministry Partnerships">
          <div style={{ height: 8 }} />
          <Form.List name="newPartnerships">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} noStyle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        border: `1px solid ${sharedSettings.colors.gray}`,
                        boxShadow: `0px 0px 8px ${sharedSettings.colors.borderGray}`,
                        padding: 16,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <Form.Item name="_id" noStyle>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          label="Ministry Name"
                          name={[field.name, 'orgName']}
                          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input disabled={loading} />
                        </Form.Item>

                        <Form.Item
                          label="Ministry Description"
                          name={[field.name, 'orgDesc']}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                        >
                          <Input.TextArea disabled={loading} rows={3} />
                        </Form.Item>

                        <Form.Item
                          label="Ministry Website"
                          name={[field.name, 'orgWebsite']}
                          rules={[
                            {
                              pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                              message:
                                'Please enter a valid URL (http://example.com)',
                            },
                          ]}
                          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                        >
                          <Input disabled={loading} />
                        </Form.Item>
                        <Divider>Lead Contact</Divider>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            label="Name"
                            name={[field.name, 'contactInfo', 'name']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="Email"
                            name={[field.name, 'contactInfo', 'email']}
                            rules={[
                              {
                                type: 'email',
                                message:
                                  'Please enter a valid email (ex. aaron@gmail.com).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Phone"
                            name={[field.name, 'contactInfo', 'phone']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="WhatsApp"
                            name={[field.name, 'contactInfo', 'whatsApp']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Country"
                            name={[field.name, 'contactInfo', 'country']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <SelectCountry />
                          </Form.Item>
                        </div>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={loading}
                  >
                    Add Ministry Partnership
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    finish: (values) => {
      return {
        newPartnerships: values.newPartnerships
          ? values.newPartnerships.map((c) => {
              return {
                _id: c._id || generateUuid(),
                orgName: c.orgName,
                orgDesc: c.orgDesc,
                orgWebsite: c.orgWebsite,
                contactInfo: {
                  _id: c.contactInfo._id || generateUuid(),
                  ...c.contactInfo,
                },
              };
            })
          : [],
      };
    },
    Display: function Display({ report }) {
      const { countries } = useCountries();
      return (
        <>
          <Title level={2} style={{ marginTop: 32 }}>
            New Ministry Parterships
          </Title>
          {report.newPartnerships &&
            report.newPartnerships.map((partnership, index) => {
              const contactInfo = partnership.contactInfo || {};
              let countryName;
              if (countries && countries.length && contactInfo.country) {
                const country = countries.find(
                  (c) => c.shortCode === contactInfo.country,
                );
                countryName = country && country.name;
              }
              return (
                <div key={index} style={{ marginBottom: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                      borderRadius: 8,
                      padding: 12,
                      fontSize: 14,
                      backgroundColor: sharedSettings.colors.borderGray,
                    }}
                  >
                    <Text strong>{partnership.orgName}</Text>
                    <br />
                    {partnership.orgDesc || 'No Description'}
                    <br />
                    {partnership.orgWebsite || 'No Website'}
                    <br />
                    {contactInfo.name}
                    <br />
                    {[
                      contactInfo.email || 'No Email',
                      contactInfo.phone || 'No Phone',
                      contactInfo.whatsApp || 'No WhatsApp',
                    ].join(', ')}
                    <br />
                    {countryName || 'No Country'}
                  </div>
                </div>
              );
            })}
          {(!report.newPartnerships || !report.newPartnerships.length) && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No new ministry partnerships"
            />
          )}
        </>
      );
    },
  },
  numUpgAdoptions: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Number of UPG Adoption Commitments"
          name="numUpgAdoptions"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <InputNumber
            disabled={loading}
            min={0}
            step={1}
            style={{ width: 200 }}
          />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="Number of UPG Adoption Commitments"
          answer={report.numUpgAdoptions}
        />
      );
    },
  },
  newAdoptions: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item label="New UPG Adoptions">
          <div>
            <Text>Enter at least one for each people group</Text>
          </div>
          <div style={{ height: 8 }} />
          <Form.List name="newAdoptions">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} noStyle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        border: `1px solid ${sharedSettings.colors.gray}`,
                        boxShadow: `0px 0px 8px ${sharedSettings.colors.borderGray}`,
                        padding: 16,
                        borderRadius: 8,
                        marginBottom: 16,
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <Form.Item name="_id" noStyle>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) =>
                            prevValues.regionCode !== curValues.regionCode
                          }
                        >
                          {({ getFieldValue }) => (
                            <Form.Item
                              label="People Group"
                              name={[field.name, 'peopleGroupId']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a people group',
                                },
                              ]}
                            >
                              <SelectPeopleGroup
                                disabled={loading}
                                filters={{
                                  regionCode: getFieldValue('regionCode'),
                                }}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Divider>Lead Contact</Divider>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item name="_id" noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            label="Name"
                            name={[field.name, 'contactInfo', 'name']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="Email"
                            name={[field.name, 'contactInfo', 'email']}
                            rules={[
                              {
                                type: 'email',
                                message:
                                  'Please enter a valid email (ex. aaron@gmail.com).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Phone"
                            name={[field.name, 'contactInfo', 'phone']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                          <Form.Item
                            label="WhatsApp"
                            name={[field.name, 'contactInfo', 'whatsApp']}
                            extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
                            rules={[
                              {
                                pattern:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message:
                                  'Please enter a valid phone number (ex. 5124026225).',
                              },
                            ]}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <Input disabled={loading} />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            width: '100%',
                          }}
                        >
                          <Form.Item
                            label="Country"
                            name={[field.name, 'contactInfo', 'country']}
                            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
                          >
                            <SelectCountry />
                          </Form.Item>
                        </div>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={loading}
                  >
                    Add a UPG Adoption
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    finish: (values) => {
      return {
        newPartnerships: values.newPartnerships
          ? values.newPartnerships.map((c) => {
              return {
                _id: c._id || generateUuid(),
                orgName: c.orgName,
                orgDesc: c.orgDesc,
                orgWebsite: c.orgWebsite,
                contactInfo: {
                  _id: c.contactInfo._id || generateUuid(),
                  ...c.contactInfo,
                },
              };
            })
          : [],
      };
    },
    Display: function Display({ report }) {
      const { countries } = useCountries();
      return (
        <>
          <Title level={2} style={{ marginTop: 32 }}>
            New UPG Adoptions
          </Title>
          {report.newAdoptions &&
            report.newAdoptions.map((adoption, index) => {
              const contactInfo = adoption.contactInfo || {};
              let countryName;
              if (countries && countries.length && contactInfo.country) {
                const country = countries.find(
                  (c) => c.shortCode === contactInfo.country,
                );
                countryName = country && country.name;
              }
              return (
                <div key={index} style={{ marginBottom: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                      borderRadius: 8,
                      padding: 12,
                      fontSize: 14,
                      backgroundColor: sharedSettings.colors.borderGray,
                    }}
                  >
                    <Text strong>{adoption.peopleGroupName}</Text>
                    <br />
                    <Text strong>{`Contact: ${contactInfo.name}`}</Text>
                    <br />
                    {[
                      contactInfo.email || 'No email',
                      contactInfo.phone || 'No phone',
                      contactInfo.whatsApp || 'No WhatsApp',
                    ].join(', ')}
                    <br />
                    {countryName || 'No country'}
                    <br />
                    {contactInfo.orgName || 'No organization or church name'}
                  </div>
                </div>
              );
            })}
          {(!report.newAdoptions || !report.newAdoptions.length) && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No new UPG adoption commitments"
            />
          )}
        </>
      );
    },
  },
  miracles: reportMiracles,
  publicFiles: {
    ...reportPublicFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'TRAIN_EVENT',
      });
    },
  },
  privateFiles: {
    ...reportPrivateFiles,
    FormItem: function FormItem(props) {
      return reportPublicFiles.FormItem({
        ...props,
        reportType: 'TRAIN_EVENT',
      });
    },
  },
  notes: reportNotes,
};
