import { Button, Space, Typography } from 'antd';
import React from 'react';
import { PayoutRowStates } from '../../constants';

const { Text } = Typography;

function PayoutActionColumn({
  record,
  editable,
  handleTransition,
  btnStyle,
  disabled,
}) {
  return (
    <>
      {editable &&
        [PayoutRowStates.READY.key, PayoutRowStates.NOT_READY.key].includes(
          record.state,
        ) && (
          <Button
            type="primary"
            onClick={() =>
              handleTransition([record._id], PayoutRowStates.PENDING.key)
            }
            style={btnStyle}
            disabled={
              disabled ||
              !['YES', 'MAYBE'].includes(
                record.completable && record.completable.completable,
              )
            }
          >
            Sent
          </Button>
        )}
      {editable && record.state === PayoutRowStates.PENDING.key && (
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleTransition([record._id], PayoutRowStates.COMPLETE.key)
            }
            style={btnStyle}
            disable={disabled}
          >
            Received
          </Button>
          <Button
            onClick={() =>
              handleTransition([record._id], PayoutRowStates.READY.key)
            }
            style={btnStyle}
            disable={disabled}
          >
            Cancel
          </Button>
        </Space>
      )}
    </>
  );
}

export default PayoutActionColumn;
