// isFieldWorker
// isRegionalCoord
// isTrainer
// isPrayerAdopter
// isDonor
// isProjectPartner
// isUpgSponsor

import { UpgXContactRoles } from '@aims/shared/people-groups/constants';

const ContactRoles = {
  isFieldWorker: {
    key: 'isFieldWorker',
    label: 'Field worker',
  },
  isRegionalCoord: {
    key: 'isRegionalCoord',
    label: 'Regional coordinator',
  },
  isTrainer: {
    key: 'isTrainer',
    label: 'Trainer',
  },
  isPrayerAdopter: {
    key: 'isPrayerAdopter',
    label: 'UPG Prayer Adopter',
  },
  isDonor: {
    key: 'isDonor',
    label: 'Donor',
  },
  isProjectPartner: {
    key: 'isProjectPartner',
    label: 'Project Partner',
  },
  isUpgSponsor: {
    key: 'isUpgSponsor',
    label: 'UPG Sponsor',
  },
};

export function getContactRoles(contact) {
  return Object.values(ContactRoles).filter((cr) => {
    if (contact[cr.key]) {
      return true;
    }
    return false;
  });
}

export function getContactRoleLabels(contact) {
  let roleLabels = getContactRoles(contact).map((cr) => cr.label);
  if (roleLabels.length === 0) {
    roleLabels = ['No Contact Roles'];
  }
  return roleLabels;
}
