import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Select } from 'antd';
import React, { useCallback, useMemo, useEffect } from 'react';
import useSettings from '../shared/use-settings';
import { ContactTypes } from './constants';

const selectContactQuery = gql`
  query SelectContactQuery(
    $first: Int
    $after: String
    $filters: ContactFiltersForUser
    $sortBy: [SortBy]
  ) {
    allContactsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactType
          fullName
        }
      }
    }
  }
`;

const extractAllContactsForUser = (data) => data && data.allContactsForUser;

const sortBy = [
  { key: 'firstName.keyword', order: 'ASC' },
  { key: '_score', order: 'DESC' },
];

function SelectContact({ disabled, value, onChange, filters, style }) {
  const settings = useSettings();
  const {
    error,
    loading,
    data: contacts,
    search: contactSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectContactQuery,
    extract: extractAllContactsForUser,
    first: settings.querySize,
    filters,
    sortBy,
    fetchPolicy: 'cache-first',
    queryId: 'select-contact',
  });

  const handleChange = useCallback(
    (_value) => {
      onChange(_value);
    },
    [onChange],
  );

  const options = useMemo(() => {
    if (contacts) {
      return contacts.map((item) => {
        const contactType = item.contactType || ContactTypes.CONTACT.key;
        const typeLabel = ContactTypes[contactType]?.label;
        const label = `${item.fullName} (${typeLabel})`;
        return {
          value: item._id,
          label: label,
          title: label,
        };
      });
    }
    return [];
  }, [contacts]);

  useEffect(() => {
    if (value) {
      contactSearch(value);
    }
  }, [value, contactSearch]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a contact"
      onChange={handleChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => contactSearch(v)}
      loading={loading}
      disabled={disabled}
    />
  );
}

export default SelectContact;
