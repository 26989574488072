import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import React, { useCallback, useRef, useState } from 'react';
import settings from '../../../settings';
import ListAdoptionsA10 from '../../adoptions/a10-adoptions/ListAdoptionsA10';
import { AdoptionListAttributes } from '../../adoptions/constants';

export const AdoptionXContactListAttributes = gql`
  {
    _id
    type
    adoptionId
    adoption ${AdoptionListAttributes}
    contactId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allAdoptionXContactsQuery = gql`
  query AllAdoptionXContacts($first: Int, $after: String, $filters: AdoptionXContactFiltersForAdmin, $sortBy: [SortBy]) {
    allAdoptionXContactsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${AdoptionXContactListAttributes}
      }
    }
  }
`;

export const extractAdoptionXContacts = (data) =>
  data?.allAdoptionXContactsForAdmin;

function UpgSponsorshipsTab({ contactId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    contactId,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: adoptions,
    search: adoptionSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allAdoptionXContactsQuery,
    extract: extractAdoptionXContacts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'view-contact',
  });

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        adoptionSearch(search.toLowerCase());
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [adoptionSearch, refetch, sortedBy, sortOrder],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  return (
    <ListAdoptionsA10
      loading={loading}
      error={error}
      adoptions={adoptions.map((a) => a.adoption)}
      // next={next}
      // hasNextPage={hasNextPage}
      refetch={refetch}
      isTemplate={false}
      onFiltersChanged={onFiltersChanged}
      onSortedByChange={onSortedByChange}
      onSortOrderChange={onSortOrderChange}
      hideSearch={false}
      hideFilters={true}
    />
  );
}

export default UpgSponsorshipsTab;
