import { gql, useMutation } from '@apollo/client';
import { Button, Form, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import {
  createActivityMutation,
  deleteActivityMutation,
  updateActivityMutation,
} from '../../../activity/EditActivity/constants';
import { generateUuid } from '@aims/shared/shared/utils';
import ContentEditableInput, {
  DefaultContentEditableValue,
  serializeText,
} from '../../../../components/ContentEditableInput';
import EditAttachments from '../../../../components/AnyAttachments/EditAttachments';

export const addAttachmentToActivityMutation = gql`
  mutation AddAttachmentToActivity($activityId: ID!, $attachmentId: ID!) {
    addAttachmentToActivity(
      activityId: $activityId
      attachmentId: $attachmentId
    )
  }
`;

export const removeAttachmentFromActivityMutation = gql`
  mutation RemoveAttachmentFromActivity($activityId: ID!, $attachmentId: ID!) {
    removeAttachmentFromActivity(
      activityId: $activityId
      attachmentId: $attachmentId
    )
  }
`;

function EditNote({ task, activity, setEditing }) {
  const [loading, setLoading] = useState(false);
  const [updateActivity] = useMutation(updateActivityMutation);
  const [createActivity] = useMutation(createActivityMutation);
  const [deleteActivity] = useMutation(deleteActivityMutation);
  const [removeAttachmentFromActivity] = useMutation(
    removeAttachmentFromActivityMutation,
  );
  const [addAttachmentToActivity] = useMutation(
    addAttachmentToActivityMutation,
  );
  const nextNoteId = useMemo(() => {
    if (activity) {
      return activity._id;
    }
    return generateUuid();
  }, [activity]);

  const onFinish = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { desc } = _values;
        if (activity) {
          await updateActivity({
            variables: {
              activity: {
                _id: activity._id,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Note updated successfully',
          });
        } else {
          await createActivity({
            variables: {
              activity: {
                _id: nextNoteId,
                when: new Date().toISOString(),
                type: 'NOTE',
                taskId: task._id,
                desc,
                descText: desc && serializeText(JSON.parse(desc)),
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Note created successfully',
          });
        }
        setEditing(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your note',
        });
      }
      setLoading(false);
    },
    [task, createActivity, updateActivity, nextNoteId, setEditing, activity],
  );

  const onDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteActivity({
        variables: {
          _id: activity._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Activity deleted successfully',
      });
      setEditing(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your note',
      });
    }
  }, [activity, setEditing, deleteActivity]);

  const [form] = Form.useForm();

  const getUploadIds = useCallback(() => {
    const attachmentId = generateUuid();
    const s3Key = `activity/${nextNoteId}/attachments/${attachmentId}`;
    return [attachmentId, s3Key, ['admin', 'task', 'activity', 'attachment']];
  }, [nextNoteId]);

  const onUploadComplete = useCallback(
    async (attachment) => {
      if (!activity) {
        await onFinish({
          _id: nextNoteId,
          desc: DefaultContentEditableValue,
        });
      }
      await addAttachmentToActivity({
        variables: {
          activityId: nextNoteId,
          attachmentId: attachment._id,
        },
      });
    },
    [addAttachmentToActivity, nextNoteId, activity, onFinish],
  );

  const onDeleteAttachment = useCallback(
    async (attachmentId) => {
      await removeAttachmentFromActivity({
        variables: {
          activityId: nextNoteId,
          attachmentId,
        },
      });
    },
    [removeAttachmentFromActivity, nextNoteId],
  );

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item name="desc" initialValue={activity && activity.desc}>
        <ContentEditableInput disabled={loading} autoFocus={true} />
      </Form.Item>
      <EditAttachments
        attachments={activity?.attachments || []}
        parentId={nextNoteId}
        onUploadComplete={onUploadComplete}
        onDeleteAttachment={onDeleteAttachment}
        getUploadIds={getUploadIds}
      />
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {activity && (
            <Button
              disabled={loading}
              htmlType="button"
              onClick={onDelete}
              style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
              danger
            >
              Delete Note
            </Button>
          )}
          <Button
            disabled={loading}
            htmlType="button"
            onClick={() => setEditing(false)}
            style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 4, marginRight: 4, marginBottom: 8 }}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default EditNote;
