export const READ_MIRACLES = 'READ_MIRACLES';
export const CREATE_MIRACLE = 'CREATE_MIRACLE';
export const UPDATE_MIRACLE = 'UPDATE_MIRACLE';
export const DELETE_MIRACLE = 'DELETE_MIRACLE';

// eslint-disable-next-line no-shadow
export const readMiraclesAction = (miracles) => ({
  type: READ_MIRACLES,
  payload: {
    miracles,
  },
});

export const createMiracleAction = (miracle) => ({
  type: CREATE_MIRACLE,
  payload: {
    miracle,
  },
});

export const updateMiracleAction = (miracle) => ({
  type: UPDATE_MIRACLE,
  payload: {
    miracle,
  },
});

export const deleteMiracleAction = (miracleId) => ({
  type: DELETE_MIRACLE,
  payload: {
    miracleId,
  },
});

const initialMiracles = [];

export function miracles(state = initialMiracles, action) {
  switch (action.type) {
    case READ_MIRACLES: {
      const newState = { ...state };
      action.payload.miracles.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_MIRACLE:
      return {
        ...state,
        [action.payload.miracle._id]: action.payload.miracle,
      };
    case UPDATE_MIRACLE: {
      const current = state[action.payload.miracle._id] || undefined;
      return {
        ...state,
        [action.payload.miracle._id]: {
          ...current,
          ...action.payload.miracle,
        },
      };
    }
    case DELETE_MIRACLE: {
      const { miracleId } = action.payload;
      return { ...state, [miracleId]: undefined };
    }
    default:
      return state;
  }
}
