import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { gql } from '@apollo/client';
import {
  createPayoutV2Action,
  deletePayoutV2Action,
  updatePayoutV2Action,
} from '../../../redux-store/payouts-v2-store';
import useOneM1 from '@aims/shared/shared/use-one-m1';

const PayoutV2Attributes = gql`
  {
    _id
    name
    quarter
    state
    notes
    regionalCoordEmailsSent
    createdBy
    updatedBy
    initiatedBy
    completedBy
    createdAt
    updatedAt
    initiatedAt
    completedAt
  }
`;

const payoutV2Query = gql`
  query PayoutV2($_id: ID!) {
    payoutV2(_id: $_id) ${PayoutV2Attributes}
  }
`;

const PayoutV2UpdateAttributes = gql`
{
  crud
  id
  new ${PayoutV2Attributes}
}
`;

const payoutV2UpdatesSubscription = gql`
  subscription payoutV2Updates($_id: ID!) {
    payoutV2Updates(_id: $_id) ${PayoutV2UpdateAttributes}
  }
`;

const extractPayoutV2 = (data) => data && data.payoutV2;

function useLocalPayoutV2({ _id, queryId = 'default' }) {
  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: payoutV2UpdatesSubscription,
    createAction: createPayoutV2Action,
    updateAction: updatePayoutV2Action,
    deleteAction: deletePayoutV2Action,
    extractSomething: (resp) => resp?.data?.payoutV2Updates,
    queryId,
    skip: !_id,
  });
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: payoutV2Query,
    extract: extractPayoutV2,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updatePayoutV2Action,
    skip: !_id,
    showError: true,
  });
  const data = useSingleSomethingA10('payoutV2s', _id, queryId);
  return {
    loading,
    data,
    refetch,
  };
}

export default useLocalPayoutV2;
