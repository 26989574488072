import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { displayMoney } from '@aims/shared/shared/utils';
import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const { Paragraph, Text } = Typography;

const deleteHpInstallmentPaymentMutation = gql`
  mutation DeleteHpInstallmentPaymentPayment($paymentId: ID!) {
    deleteHpInstallmentPayment(paymentId: $paymentId)
  }
`;

function DeleteInstallmentPaymentModal({ visible, onCancel, onFinish }) {
  const { payment } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteHpInstallmentPayment] = useMutation(
    deleteHpInstallmentPaymentMutation,
  );

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteHpInstallmentPayment({
        variables: {
          paymentId: payment._id,
        },
      });

      notification.success({
        message: 'Deleted',
        description: 'Payment deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [payment, deleteHpInstallmentPayment, onFinish]);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateformat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
  });

  return (
    <YesNoModalJ10
      title="Delete Installment Payment"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>
        Are you sure you want to delete this installment payment?
      </Paragraph>
      {payment && (
        <Paragraph>
          {`${displayMoney(
            payment?.amount / 10000,
          )} paid on ${dateformat.format(new Date(payment.originalDate))}`}
        </Paragraph>
      )}
    </YesNoModalJ10>
  );
}

export default DeleteInstallmentPaymentModal;
