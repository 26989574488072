import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Divider, Form, Tooltip, notification } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateContactAction } from '../../redux-store/contacts-store';
import {
  addContactToHouseholdMutation,
  removeContactFromHouseholdMutation,
} from '../households/constants';
import { ContactTypes } from './constants';
import SelectContact from './SelectContact';

function AddToHousehold({ loading, contact, finish }) {
  const [addContactToHousehold] = useMutation(addContactToHouseholdMutation);
  const [removeContactFromHousehold] = useMutation(
    removeContactFromHouseholdMutation,
  );

  const dispatch = useDispatch();

  finish.current = useCallback(
    async (contactId, queryId, selectedHouseholds) => {
      let newHousehold;
      if (selectedHouseholds && selectedHouseholds.length) {
        newHousehold = selectedHouseholds[0];
      }
      let householdToRemove;
      let householdToAdd;
      if (contact && contact.household) {
        if (
          newHousehold &&
          newHousehold.householdId !== contact.household.householdId
        ) {
          householdToRemove = contact.household;
          householdToAdd = newHousehold;
        } else if (!newHousehold) {
          householdToRemove = contact.household;
        }
      } else if (newHousehold) {
        householdToAdd = newHousehold;
      }
      let updatedContact;
      let updatedHousehold;
      let response;
      if (householdToRemove) {
        response = await removeContactFromHousehold({
          variables: {
            contactId,
            householdId: householdToRemove.householdId,
          },
        });
        updatedContact =
          response.data &&
          response.data.removeContactFromHousehold &&
          response.data.removeContactFromHousehold.contact;
        updatedHousehold =
          response.data &&
          response.data.removeContactFromHousehold &&
          response.data.removeContactFromHousehold.household;
        notification.success({
          message: 'Success',
          description: 'Contact removed from household',
        });
      }
      if (householdToAdd) {
        response = await addContactToHousehold({
          variables: {
            member: {
              contactId,
              householdId: householdToAdd.householdId,
            },
          },
        });
        updatedContact =
          response.data &&
          response.data.addContactToHousehold &&
          response.data.addContactToHousehold.contact;
        updatedHousehold =
          response.data &&
          response.data.addContactToHousehold &&
          response.data.addContactToHousehold.household;
        notification.success({
          message: 'Success',
          description: 'Contact added to household',
        });
      }
      if (updatedContact) {
        dispatch(
          updateContactAction({
            queryId,
            ...updatedContact,
          }),
        );
      }
      if (updatedHousehold) {
        dispatch(
          updateContactAction({
            queryId,
            ...updatedHousehold,
          }),
        );
      }
    },
    [contact, addContactToHousehold, removeContactFromHousehold, dispatch],
  );
  return (
    <>
      <Divider>Household</Divider>
      <Form.Item>
        <Form.List name="households">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Form.Item
                      name={[field.name, 'householdId']}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a household',
                        },
                      ]}
                      noStyle
                    >
                      <SelectContact
                        disabled={loading}
                        filters={{ contactType: ContactTypes.HOUSEHOLD.key }}
                      />
                    </Form.Item>
                    <Tooltip title="Remove from household">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: 8 }}
                      />
                    </Tooltip>
                  </div>
                </Form.Item>
              ))}
              {fields.length === 0 && (
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add to a household
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
}

export default AddToHousehold;
