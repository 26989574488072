export const READ_PROJECT_SPONSORSHIPS = 'READ_PROJECT_SPONSORSHIPS';
export const CREATE_PROJECT_SPONSORSHIP = 'CREATE_PROJECT_SPONSORSHIP';
export const UPDATE_PROJECT_SPONSORSHIP = 'UPDATE_PROJECT_SPONSORSHIP';
export const DELETE_PROJECT_SPONSORSHIP = 'DELETE_PROJECT_SPONSORSHIP';

export const readProjectSponsorshipsAction = (
  // eslint-disable-next-line no-shadow
  projectSponsorships,
  queryId = 'default',
) => ({
  type: READ_PROJECT_SPONSORSHIPS,
  payload: {
    projectSponsorships,
    queryId,
  },
});

export const createProjectSponsorshipAction = (
  projectSponsorship,
  queryId = 'default',
) => ({
  type: CREATE_PROJECT_SPONSORSHIP,
  payload: {
    projectSponsorship,
    queryId,
  },
});

export const updateProjectSponsorshipAction = (
  projectSponsorship,
  queryId = 'default',
) => ({
  type: UPDATE_PROJECT_SPONSORSHIP,
  payload: {
    projectSponsorship,
    queryId,
  },
});

export const deleteProjectSponsorshipAction = (
  projectSponsorshipId,
  queryId = 'default',
) => ({
  type: DELETE_PROJECT_SPONSORSHIP,
  payload: {
    projectSponsorshipId,
    queryId,
  },
});

const initialProjectSponsorships = { default: {} };

export function projectSponsorships(
  state = initialProjectSponsorships,
  action,
) {
  switch (action.type) {
    case READ_PROJECT_SPONSORSHIPS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.projectSponsorships.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_PROJECT_SPONSORSHIP:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.projectSponsorship._id]:
            action.payload.projectSponsorship,
        },
      };
    case UPDATE_PROJECT_SPONSORSHIP: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][
            action.payload.projectSponsorship._id
          ]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.projectSponsorship._id]: {
            ...current,
            ...action.payload.projectSponsorship,
          },
        },
      };
    }
    case DELETE_PROJECT_SPONSORSHIP: {
      const { projectSponsorshipId } = action.payload;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [projectSponsorshipId]: undefined,
        },
      };
    }
    default:
      return state;
  }
}
