import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tabs, Typography, message, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import TransfersTabLocal from '../accounts-j25/TransfersTabLocal';
import PayoutRowsTable from './components/PayoutRowsTable';
import PayoutV2Title from './components/PayoutV2Title';
import RegionalCoordTab from './components/RegionalCoordTab';
import {
  PayoutRowStates,
  PayoutStates,
  cancelPayoutV2RowsMutation,
  completePayoutV2RowsMutation,
  initiatePayoutV2RowsMutation,
  updatePayoutV2Mutation,
  updatePayoutV2RowMutation,
} from './constants';
import ConfirmRefreshModalV2 from './modals/ConfirmRefreshModalV2';
import ConfirmTransitionModalV2 from './modals/ConfirmTransitionModalV2';
import DeletePayoutModalV2 from './modals/DeletePayoutModalV2';
import useLocalPayoutV2 from './use-local-payout';
import useLocalPayoutV2Rows from './use-local-payout-rows';
import { exportPayoutToCsv } from './utils';

const { Title, Text } = Typography;

const queryId = 'edit-payout';

function EditPayoutV2() {
  const { _id, _tab } = useParams();

  const filters = useRef({});
  const { data: payout, loading: payoutLoading } = useLocalPayoutV2({
    _id,
    queryId,
  });

  const payoutRowFilters = useMemo(() => {
    if (payout) {
      if (
        [PayoutStates.COMPLETE.key, PayoutStates.IN_PROGRESS.key].includes(
          payout.state,
        )
      ) {
        return {
          ...filters.current,
          willReceiveFunds: true,
        };
      } else {
        return filters.current;
      }
    }
    return undefined;
  }, [payout]);

  const { data: payoutRows, loading: rowsLoading } = useLocalPayoutV2Rows({
    payoutId: _id,
    filters: payoutRowFilters,
    skip: !payoutRowFilters,
    queryId,
  });
  const loading = useMemo(
    () => payoutLoading || rowsLoading,
    [payoutLoading, rowsLoading],
  );

  const loadingHide = useRef();
  useEffect(() => {
    if (loading) {
      loadingHide.current = message.loading({
        content: `Updating`,
        key: 'updated',
      });
    } else {
      loadingHide.current?.();
    }
  }, [loading]);

  const [error, setError] = useState();
  const history = useHistory();

  const [updatePayout] = useMutation(updatePayoutV2Mutation);
  const savePayout = useCallback(
    async (updating) => {
      const hide = message.info('Saving ...');
      setError(undefined);
      try {
        await updatePayout({
          variables: {
            payout: updating,
          },
        });
        hide();
        message.success('Done');
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
    },
    [updatePayout],
  );

  const [updatePayoutRpw] = useMutation(updatePayoutV2RowMutation);
  const savePayoutRow = useCallback(
    async (updating) => {
      const hide = message.info('Saving ...');
      setError(undefined);
      try {
        await updatePayoutRpw({
          variables: {
            payoutRow: updating,
          },
        });
        hide();
        message.success('Done');
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
    },
    [updatePayoutRpw],
  );

  const [initiatePayoutRows] = useMutation(initiatePayoutV2RowsMutation);
  const [completePayoutRows] = useMutation(completePayoutV2RowsMutation);
  const [cancelPayoutRows] = useMutation(cancelPayoutV2RowsMutation);
  const transitionRows = useCallback(
    async (rowIds, newState, onFinish) => {
      message.info('Saving ...');
      setError(undefined);
      try {
        if (newState === PayoutRowStates.PENDING.key) {
          await initiatePayoutRows({
            variables: {
              rowIds: rowIds,
            },
          });
        } else if (newState === PayoutRowStates.COMPLETE.key) {
          const result = await completePayoutRows({
            variables: {
              payoutId: payout._id,
              rowIds: rowIds,
            },
          });
        } else if (newState === PayoutRowStates.READY.key) {
          const result = await cancelPayoutRows({
            variables: {
              rowIds: rowIds,
            },
          });
        }
        message.success('Saved');
        if (onFinish) onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    },
    [initiatePayoutRows, completePayoutRows, cancelPayoutRows, payout],
  );

  const [deleting, setDeleting] = useState(false);
  const handleDelete = useCallback(() => {
    setDeleting({ payout });
  }, [payout]);
  const onCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteFinish = useCallback(() => {
    history.push('/payouts');
  }, [history]);

  const [refreshing, setRefreshing] = useState(false);
  const handleRefresh = useCallback(() => {
    setRefreshing({ payout });
  }, [payout]);
  const onCancelRefresh = useCallback(() => {
    setRefreshing(undefined);
  }, []);
  const onRefreshFinish = useCallback(() => {
    setRefreshing(undefined);
  }, []);

  const [transitioning, setTransitioning] = useState(false);
  const handleCancelTransition = useCallback(() => {
    setTransitioning(undefined);
  }, []);
  const handleTransitionFinish = useCallback(() => {
    setTransitioning(undefined);
  }, []);
  const handleExportCsv = useCallback(() => {
    if (payout && payoutRows && payoutRows.length > 0) {
      message.info('Exporting ...');
      setError(undefined);
      try {
        exportPayoutToCsv(payout, payoutRows);
        message.success('Success');
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    }
  }, [payout, payoutRows]);
  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }} title="Edit Payout">
      <CSPageHeader
        titleComponent={
          <PayoutV2Title
            payout={payout}
            handleDelete={handleDelete}
            handleRefreshPayout={handleRefresh}
            handleStartPayout={() => {
              setTransitioning({
                payout,
                payoutRows,
                newState: PayoutStates.IN_PROGRESS.key,
              });
            }}
            handleCompletePayout={() => {
              setTransitioning({
                payout,
                payoutRows,
                newState: PayoutStates.COMPLETE.key,
              });
            }}
            handleExportCsv={handleExportCsv}
            savePayout={savePayout}
            loading={payoutLoading}
          />
        }
        backActions={[
          <Link key="back" to="/payouts">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Payouts
            </Button>
          </Link>,
        ]}
      />
      {error && (
        <div>
          <Title type="danger" level={4}>
            Error
          </Title>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <Tabs
        defaultActiveKey="adoptions"
        activeKey={_tab}
        onChange={(key) => history.push(`/payout/edit/${_id}/${key}`)}
      >
        <Tabs.TabPane tab="By Adoption" key="adoptions">
          {payout && (
            <PayoutRowsTable
              payout={payout}
              rows={payoutRows}
              savePayoutRow={savePayoutRow}
              transitionRows={transitionRows}
              loading={loading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="By Regional Coordinator" key="regional-coord">
          <RegionalCoordTab
            rows={payoutRows}
            payout={payout}
            savePayoutRow={savePayoutRow}
            transitionRows={transitionRows}
            loading={loading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transactions" key="transfers">
          <TransfersTabLocal hideActions filters={{ payoutId: _id }} />
        </Tabs.TabPane>
      </Tabs>
      <DeletePayoutModalV2
        deleting={deleting}
        onCancel={onCancelDelete}
        onFinish={onDeleteFinish}
      />
      <ConfirmTransitionModalV2
        confirming={transitioning}
        onCancel={handleCancelTransition}
        onFinish={handleTransitionFinish}
        queryId={queryId}
      />
      <ConfirmRefreshModalV2
        confirming={refreshing}
        onCancel={onCancelRefresh}
        onFinish={onRefreshFinish}
        queryId={queryId}
      />
      <style jsx>{``}</style>
    </CSPage>
  );
}

export default EditPayoutV2;
