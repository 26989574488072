import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SingleAddressFormItem from './SingleAddressFormItem';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactUpdateAddressInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  address: oldAddress,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (oldAddress) {
      form.setFieldsValue({
        label: oldAddress.label,
        address1: oldAddress.address1,
        address2: oldAddress.address2,
        city: oldAddress.city,
        state: oldAddress.state,
        zipCode: oldAddress.zipCode,
        country: oldAddress.country,
      });
    }
  }, [oldAddress, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingAddresses = (contact.addresses || [])
        .filter((e) => e._id !== oldAddress._id)
        .reduce((prev, curr) => {
          const {
            _id,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          } = curr;
          prev.push({
            _id,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          });
          return prev;
        }, []);
      const {
        label,
        address1,
        address2,
        city,
        state,
        zipCode,
        country,
        makePrimary,
      } = values;
      const existingPrimaryId = contact.primaryAddressId;
      await handleFinish({
        addresses: [
          ...existingAddresses,
          {
            _id: oldAddress._id,
            label,
            address1,
            address2,
            city,
            state,
            zipCode,
            country,
          },
        ].sort((a, b) => a.address.localeCompare(b.address)),
        primaryAddressId: makePrimary ? oldAddress._id : existingPrimaryId,
      });
    },
    [handleFinish, contact, oldAddress],
  );
  return (
    <ContactSomethingForm
      title="Update Address"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <SingleAddressFormItem
        form={form}
        loading={saving}
        num={(contact.addresses || []).length + 1}
      />
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactUpdateAddressInnerModalA10;
