import { gql } from '@apollo/client';

export const PpRecurringPaymentListAttributes = gql`
  {
    _id
    payerId
    payerName
    payerEmail
    amount
    status
    fundId
    fundName
    adoptionId
    startDate
    frequency
    usdCentsAmount
    adoption {
      adopterName
      peopleGroupId
      fieldWorkerName
      regionalCoordName
      accountId
      level
      amount
    }
    adoptions {
      adoptionId
      adoption {
        sponsorNames
        peopleGroupId
        fieldWorkerName
        regionalCoordName
        accountId
        level
        amount
      }
      percent
      accountId
      amount
    }
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const PpRecurringPaymentStatuses = {
  Active: {
    key: 'Active',
    label: 'Active',
  },
  Cancelled: {
    key: 'Cancelled',
    label: 'Cancelled',
  },
  Completed: {
    key: 'Completed',
    label: 'Completed',
  },
  Paused: {
    key: 'Paused',
    label: 'Paused',
  },
  Pending: {
    key: 'Pending',
    label: 'Pending',
  },
};

export const allPpRecurringPaymentsQuery = gql`
  query AllPpRecurringPaymentsForAdmin($first: Int, $after: String, $filters: RecurringPaymentFiltersForAdmin, $sortBy: [SortBy]) {
    allPpRecurringPaymentsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PpRecurringPaymentListAttributes}
      }
    }
  }
`;

export const extractAllRecurringPayments = (data) =>
  data && data.allPpRecurringPaymentsForAdmin;

export const ppRecurringPaymentQuery = gql`
  query PpRecurringPaymentForAdmin($_id: ID!) {
    ppRecurringPaymentForAdmin(_id: $_id)  ${PpRecurringPaymentListAttributes}
  }
`;

export const updatePpRecurringPaymentMutation = gql`
  mutation UpdatePpRecurringPayment($recurringPayment: RecurringPaymentUpdateInput!) {
    updatePpRecurringPayment(recurringPayment: $recurringPayment)  {
      recurringPayment ${PpRecurringPaymentListAttributes}
    }
  }
`;
