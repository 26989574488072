import { BarChartOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ListAdoptionStats from './ListAdoptionStats';

const { Title, Text } = Typography;

function ListAdoptionStatsPage() {
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Sponsorship Stats">
        <CSPageHeader
          titleComponent={
            <Title>
              <BarChartOutlined style={{ marginRight: 16 }} />
              UPG Sponsorship Statistics
            </Title>
          }
        />
        <ListAdoptionStats />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default ListAdoptionStatsPage;
