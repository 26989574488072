import useOneM1 from '@aims/shared/shared/use-one-m1';
import { gql } from '@apollo/client';
import { updateContactAction } from '../../redux-store/contacts-store';
import { extractContactForAdmin } from './constants';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';

export const contactShortQuery = gql`
  query ContactShort($_id: ID!) {
    contactForAdmin(_id: $_id) {
      _id
      fullName
      contactType
      primaryThumbnailUrl
    }
  }
`;

export default function useShortContact(_id, queryId = 'use-contact') {
  const fetchResult = useOneM1({
    variables: { _id },
    query: contactShortQuery,
    extract: extractContactForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateContactAction,
    queryId,
  });
  const contact = useSingleSomethingA10('contacts', _id, queryId);
  const { loading, error } = fetchResult;

  return {
    contact,
    loading,
    error,
  };
}
