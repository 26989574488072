import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import React, { useCallback, useRef, useState } from 'react';
import {
  allPrayerRequestsForAdminQuery,
  extractPrayerRequestsForAdmin,
} from './constants';
import settings from '../../settings';
import { Button, Card, Empty, Form, Input, List, Skeleton } from 'antd';
import PrayerRequestCardJ15 from './PrayerRequestCardJ15';
import UpdatePrayerRequestModalJ15 from './UpdatePrayerRequestModalJ15';
import DeletePrayerRequestModal from './DeletePrayerRequestModal';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

function PrayerRequestListJ15({
  filtersRef,
  refetchRef,
  hideSearch,
  queryId = 'default',
}) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: prayerRequests,
    search: prayerRequestSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPrayerRequestsForAdminQuery,
    extract: extractPrayerRequestsForAdmin,
    first: settings.querySize,
    filters: filtersRef.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  if (refetchRef) {
    refetchRef.current = refetch;
  }

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  const [editing, setEditing] = useState();
  const onEditClick = (prayerRequest) => setEditing({ prayerRequest });
  const onFinishEdit = () => {
    setEditing(undefined);
    refetch();
  };
  const onCancelEdit = () => setEditing(undefined);

  const [deleting, setDeleting] = useState();
  const onDeleteClick = (prayerRequest) => setDeleting({ prayerRequest });
  const onFinishDelete = () => {
    setDeleting(undefined);
    refetch();
  };
  const onCancelDelete = () => setDeleting(undefined);

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        prayerRequestSearch(search);
      } else {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [prayerRequestSearch, filtersRef, refetch, sortedBy, sortOrder],
  );

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onFiltersChanged}
        style={{ marginBottom: 16 }}
      >
        <div style={{ display: 'flex' }}>
          {hideSearch ? (
            <div style={{ flex: 1, height: 64 }} />
          ) : (
            <div style={{ display: 'flex' }}>
              <Form.Item
                name="search"
                style={{ minWidth: 300, marginRight: 16 }}
              >
                <Input placeholder="Search" suffix={<SearchOutlined />} />
              </Form.Item>
            </div>
          )}
          <div style={{ flex: 1 }} />
          <Button icon={<ReloadOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </div>
      </Form>
      {loading && !prayerRequests.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            className="prayerRequestsList"
            dataSource={prayerRequests.slice(0, pageSize)}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(item) => (
              <List.Item style={{ border: 'none' }}>
                <PrayerRequestCardJ15
                  prayerRequest={item}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              </List.Item>
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Contacts"
                />
              ),
            }}
          />
          <div style={{ textAlign: 'center' }}>
            {prayerRequests &&
              prayerRequests.length <= pageSize &&
              hasNextPage && <Button onClick={next}>Load More</Button>}
            {prayerRequests && prayerRequests.length > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
      <UpdatePrayerRequestModalJ15
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeletePrayerRequestModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </>
  );
}

export default PrayerRequestListJ15;
