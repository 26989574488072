import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import SelectTagColor from './SelectTagColor';

function TagForm({ form, handleFinish, saving, onCancel }) {
  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Form.Item label="Tag" name="key">
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item label="Color" name="color">
        <SelectTagColor disabled={saving} />
      </Form.Item>
      <ShowErrorsFormItem />
      <Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 16,
          }}
        >
          <Button
            onClick={onCancel}
            htmlType="button"
            disabled={saving}
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default TagForm;
