import { Tabs } from 'antd';
import React from 'react';
import TrainingEventReportsList from './TrainingEventReportsList';
import { useParams, useHistory } from 'react-router-dom';

function TrainingEventReportTabs({ baseUrl = '/training-event-reports' }) {
  const { reportCategory } = useParams();
  const history = useHistory();
  return (
    <Tabs
      defaultActiveKey="approved"
      activeKey={reportCategory}
      onChange={(key) => history.push(`${baseUrl}/${key}`)}
    >
      <Tabs.TabPane tab="Pending" key="pending">
        <TrainingEventReportsList reportStatus="PENDING" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Approved" key="approved">
        <TrainingEventReportsList reportStatus="APPROVED" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="On Hold" key="hold">
        <TrainingEventReportsList reportStatus="HOLD" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Drafts" key="draft">
        <TrainingEventReportsList reportStatus="DRAFT" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rejected" key="rejected">
        <TrainingEventReportsList reportStatus="REJECTED" />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default TrainingEventReportTabs;
