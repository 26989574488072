import { CarryOutOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import AllPayoutsV2 from './AllPayoutsV2';

const { Title } = Typography;

function PayoutsV2Page() {
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Payouts">
        <CSPageHeader
          titleComponent={
            <Title>
              <CarryOutOutlined style={{ marginRight: 16 }} />
              Payouts
            </Title>
          }
          style={{ marginBottom: 16 }}
        />
        <AllPayoutsV2 />
        <div style={{ minHeight: 300 }} />
      </CSPage>
    </>
  );
}

export default PayoutsV2Page;
