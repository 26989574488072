import React, { useCallback } from 'react';
import YesNoModal from '../../../../components/YesNoModal';

function ContactDeletePhoneInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  phone: oldPhone,
}) {
  const _handleFinish = useCallback(async () => {
    const existingPhoneNumbers = (contact.phoneNumbers || [])
      .filter((e) => e._id !== oldPhone._id)
      .reduce((prev, curr) => {
        const { phone, _id, label } = curr;
        prev.push({ phone, _id, label });
        return prev;
      }, []);
    const existingPrimaryPhoneId = contact.primaryPhoneNumberId;
    const primaryPhoneNumber = existingPhoneNumbers.find(
      (e) => e._id === existingPrimaryPhoneId,
    );
    let newPrimaryId = null;
    if (primaryPhoneNumber) {
      newPrimaryId = primaryPhoneNumber._id;
    } else if (!primaryPhoneNumber && existingPhoneNumbers.length > 0) {
      newPrimaryId = existingPhoneNumbers[0]._id;
    }
    handleFinish({
      phoneNumbers: existingPhoneNumbers,
      primaryPhoneNumberId: newPrimaryId,
    });
  }, [handleFinish, contact, oldPhone]);
  return (
    <YesNoModal
      title="Delete Phone Number from Contact"
      question={`Are you sure you want to delete this phone number ${oldPhone?.phone}?`}
      yesText="Delete"
      noText="Cancel"
      onYes={_handleFinish}
      onNo={onCancel}
      visible={!!contact}
      loading={saving}
    />
  );
}

export default ContactDeletePhoneInnerModalA10;
