import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import ListAdoptionStats from '../../adoption-stats/ListAdoptionStats';
import HolisticProjectsList from '../../holistic-projects/HolisticProjectsList';
import UpgQuarterlyReportTabs from '../../upg-quarterly-reports/UpgQuarterlyReportTabs';
import PeopleGroupInfo from './PeopleGroupInfo';
import PeopleGroupMiracles from './PeopleGroupMiracles';
import PeopleGroupPhotos from './PeopleGroupPhotos';
import PeopleGroupPrayerRequests from './PeopleGroupPrayerRequests';
import PeopleGroupTitle from './PeopleGroupTitle';
import useLongUpg from './use-long-upg';

const queryId = 'viewPeopleGroup';

function ViewPeopleGroup() {
  const history = useHistory();
  const { _id, tab } = useParams();

  const { upg: peopleGroup, loading } = useLongUpg({
    _id,
    queryId,
  });

  return (
    <>
      <CSPage title="View People Group" containerStyle={{ maxWidth: 'unset' }}>
        <CSPageHeader
          titleComponent={
            <PeopleGroupTitle peopleGroup={peopleGroup} queryId={queryId} />
          }
          backActions={[
            <Link key="back" to="/people-groups">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to People Groups
              </Button>
            </Link>,
          ]}
        />
        <Tabs
          defaultActiveKey="info"
          activeKey={tab}
          onChange={(key) => history.push(`/people-group/view/${_id}/${key}`)}
        >
          <Tabs.TabPane tab="Info" key="info">
            <PeopleGroupInfo peopleGroup={peopleGroup} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Quarterly Reports" key="reports">
            <UpgQuarterlyReportTabs
              peopleGroupId={_id}
              baseUrl={`/people-group/view/${_id}/reports`}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Quarterly Stats" key="stats">
            <ListAdoptionStats defaultFilters={{ peopleGroupId: _id }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Holistic Projects" key="projects">
            <HolisticProjectsList filters={{ peopleGroupId: _id }} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Prayer Requests" key="prayer">
            <PeopleGroupPrayerRequests peopleGroupId={_id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Testimonies" key="miracles">
            <PeopleGroupMiracles peopleGroupId={_id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Photos" key="photos">
            <PeopleGroupPhotos peopleGroupId={_id} queryId={queryId} />
          </Tabs.TabPane>
        </Tabs>
      </CSPage>
    </>
  );
}

export default ViewPeopleGroup;
