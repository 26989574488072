import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Select, notification } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import settings from '../../settings';
import { extractUsersForAdmin } from './constants';

const selectUserQuery = gql`
  query SelectUser($first: Int, $after: String, $filters: UserFiltersForAdmin) {
    allUsersForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          name
          username
        }
      }
    }
  }
`;

function SelectUser({ value, onChange, filters, disabled, style, mode }) {
  const sortBy = useRef([
    { key: 'updatedAt', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: users,
    search: userSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectUserQuery,
    extract: extractUsersForAdmin,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'select-holistic-project',
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  }, [error]);

  const options = useMemo(() => {
    if (users) {
      return users.map((item) => {
        return {
          value: item._id,
          label: `${item.name} (${item.username})`,
          title: `${item.name} (${item.username})`,
        };
      });
    }
    return [];
  }, [users]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a user"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => userSearch(v)}
      loading={loading}
      disabled={disabled}
      mode={mode}
    />
  );
}

export default SelectUser;
