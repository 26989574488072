import { Button } from 'antd';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import CreatePrayerRequestModalJ15 from './CreatePrayerRequestModalJ15';
import PrayerRequestListJ15 from './PrayerRequestListJ15';

function PrayerRequestPage() {
  const refetch = useRef();
  const [creating, setCreating] = useState();
  const onCreateClick = (prayerRequest) => setCreating({ prayerRequest });
  const onFinishEdit = () => {
    setCreating(undefined);
    refetch.current?.();
  };
  const onCancelEdit = () => setCreating(undefined);

  const filtersRef = useRef({});
  return (
    <CSPage title="Prayer Requests">
      <CSPageHeader
        title="Prayer Requests"
        topActions={[
          <Button
            key="add"
            type="link"
            onClick={onCreateClick}
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add a new prayer request
          </Button>,
        ]}
      />
      <PrayerRequestListJ15
        queryId="prayerRequestsPage"
        filtersRef={filtersRef}
        refetchRef={refetch}
      />
      <CreatePrayerRequestModalJ15
        visible={creating}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
    </CSPage>
  );
}

export default PrayerRequestPage;
