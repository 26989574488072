import { Button, Checkbox, Form, Typography } from 'antd';
import React, { useEffect } from 'react';
import SelectContact from '../../SelectContact';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactRegionalCoordInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        regionalCoordId: contact.regionalCoordId,
      });
    }
  }, [contact, form]);
  return (
    <ContactSomethingForm
      title="Change Regional Coordinator"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Regional Coordinator"
        name="regionalCoordId"
        rules={[
          {
            required: true,
            message: 'Please select a regional coordinator',
          },
        ]}
      >
        <SelectContact
          loading={saving}
          disabled={saving}
          filters={{
            isRegionalCoord: true,
          }}
        />
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactRegionalCoordInnerModalA10;
