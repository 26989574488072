import { Divider } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { PgQuarterlyReportQuestions } from './questions';

function PgQuarterlyReportItems({
  _id,
  report,
  loading,
  form,
  finishSubForm,
  showTitle,
  fieldsToSet,
  isFieldWorker,
}) {
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        ...report,
        ...PgQuarterlyReportQuestions.onBehalfOf.before(report),
        ...PgQuarterlyReportQuestions.onBehalfOfContact.before(report),
        ...PgQuarterlyReportQuestions.notes.before(report),
      });
    }
  }, [report, form]);

  finishSubForm.current = useCallback(
    (values) => {
      const modified = { ...values };
      Object.keys(PgQuarterlyReportQuestions).forEach((k) => {
        if (PgQuarterlyReportQuestions[k].finish) {
          const result = PgQuarterlyReportQuestions[k].finish(values);
          Object.entries(result).map(([key, value]) => {
            modified[key] = value;
          });
        }
      });
      return {
        ...fieldsToSet,
        ...modified,
      };
    },
    [fieldsToSet],
  );
  return (
    <>
      {showTitle && (
        <PgQuarterlyReportQuestions.title.Display
          loading={loading}
          fieldsToSet={fieldsToSet}
        />
      )}
      {report && report.isSubmitted && (
        <PgQuarterlyReportQuestions.submittedBy.Display report={report} />
      )}
      <PgQuarterlyReportQuestions.peopleGroup.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      {!isFieldWorker && (
        <PgQuarterlyReportQuestions.onBehalfOf.FormItem
          loading={loading}
          fieldsToSet={fieldsToSet}
        />
      )}
      <PgQuarterlyReportQuestions.onBehalfOfContact.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.quarter.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <Divider>Finances</Divider>
      <PgQuarterlyReportQuestions.receivedFinancialSupport.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.receivedForHimself.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.receivedForHolistic.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.useOfHolisticFundsDesc.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <Divider>Results</Divider>
      <PgQuarterlyReportQuestions.churchesPlanted.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.numHeardForFirstTime.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.numSalvations.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.numWaterBaptisms.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.miracles.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.prayerRequests.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <PgQuarterlyReportQuestions.publicFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <PgQuarterlyReportQuestions.privateFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <PgQuarterlyReportQuestions.notes.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
    </>
  );
}

export default PgQuarterlyReportItems;
