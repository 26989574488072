import { Button, Form, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DateFormItem from '@aims/shared/components/DateFormItem';

const { Text, Title } = Typography;

function EditTaskSchedule({ onSubmit, task, timeLeft, timeUntil }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (task) {
      form.setFieldsValue({
        windowStart: task.windowStart,
        windowStop: task.windowStop,
      });
    }
  }, [form, task]);

  const onFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await onSubmit(values);
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <map
        className="window-row"
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        {!editing && (
          <>
            {task.windowStart && (
              <div className="window-row-left">
                <Title level={4}>Scheduled Start</Title>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    dateStyle: 'full',
                  }).format(new Date(task.windowStart))}
                </Text>
                {timeUntil && (
                  <div>
                    <Text style={{ color: timeUntil.color }}>
                      ({timeUntil.timeUntil})
                    </Text>
                  </div>
                )}
              </div>
            )}
            {task.windowStop && (
              <div className="window-row-right">
                <Title level={4}>Finish By</Title>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    dateStyle: 'full',
                  }).format(new Date(task.windowStop))}
                </Text>
                {timeLeft && (
                  <div>
                    <Text style={{ color: timeLeft.color }}>
                      ({timeLeft.timeLeft})
                    </Text>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </map>
      {editing && (
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Scheduled Start"
            extra="Change this for tasks that shouldn't be started or completed right away"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <DateFormItem
                name="windowStart"
                rules={[{ required: true, message: 'Please select a date' }]}
                showTime
              />
            </div>
          </Form.Item>
          <Form.Item label="Finish by">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <DateFormItem
                name="windowStop"
                rules={[{ required: true, message: 'Please select a date' }]}
                showTime
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <div style={{ height: 32 }} />
      <style jsx>{`
        .window-row {
          display: flex;
          justify-content: center;
          margin-left: -16px;
          margin-right: -16px;
        }
        .window-row-left {
          flex: 1;
          text-align: right;
          margin-left: 16px;
          margin-right: 16px;
        }
        .window-row-right {
          flex: 1;
          text-align: left;
          margin-left: 16px;
          margin-right: 16px;
        }
      `}</style>
    </>
  );
}

export default EditTaskSchedule;
