export const READ_PAYOUT_V2_ROWS = 'READ_PAYOUT_V2_ROWS';
export const CREATE_PAYOUT_V2_ROW = 'CREATE_PAYOUT_V2_ROW';
export const UPDATE_PAYOUT_V2_ROW = 'UPDATE_PAYOUT_V2_ROW';
export const DELETE_PAYOUT_V2_ROW = 'DELETE_PAYOUT_V2_ROW';

export const readPayoutV2RowsAction = (
  // eslint-disable-next-line no-shadow
  payoutV2Rows,
  queryId = 'default',
) => ({
  type: READ_PAYOUT_V2_ROWS,
  payload: {
    payoutV2Rows,
    queryId,
  },
});

export const createPayoutV2RowAction = (payoutV2Row, queryId = 'default') => ({
  type: CREATE_PAYOUT_V2_ROW,
  payload: {
    payoutV2Row,
    queryId,
  },
});

export const updatePayoutV2RowAction = (payoutV2Row, queryId = 'default') => ({
  type: UPDATE_PAYOUT_V2_ROW,
  payload: {
    payoutV2Row,
    queryId,
  },
});

export const deletePayoutV2RowAction = (
  payoutV2RowId,
  queryId = 'default',
) => ({
  type: DELETE_PAYOUT_V2_ROW,
  payload: {
    payoutV2RowId,
    queryId,
  },
});

const initialPayoutV2Rows = { default: {} };

export function payoutV2Rows(state = initialPayoutV2Rows, action) {
  switch (action.type) {
    case READ_PAYOUT_V2_ROWS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.payoutV2Rows.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_PAYOUT_V2_ROW:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payoutV2Row._id]: action.payload.payoutV2Row,
        },
      };
    case UPDATE_PAYOUT_V2_ROW: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.payoutV2Row._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payoutV2Row._id]: {
            ...current,
            ...action.payload.payoutV2Row,
          },
        },
      };
    }
    case DELETE_PAYOUT_V2_ROW: {
      const { payoutV2RowId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [payoutV2RowId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
