import { gql } from '@apollo/client';

export const AdoptionPeopleGroupAttributes = gql`
  {
    _id
    population
    continent
    region
    regionCode
    country
    affinityBloc
    peopleCluster
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    officialLanguage
    bibleStatus
    primaryReligion
    religionSubdivision
    jpScale
    primaryThumbnailUrl
  }
`;

export const AdoptionListAttributes = gql`
  {
    _id
    contactId
    adopterName
    peopleGroupId
    peopleGroup ${AdoptionPeopleGroupAttributes}
    sponsorNames
    firstPaymentDate
    lastPaymentDate
    regionCode
    fieldWorkerId
    fieldWorkerName
    regionalCoordId
    regionalCoordName
    level
    status
    amount
    accountId
    tags {
      key
      color
    }
    donationFrequency
    numOfPayoutQuarters
    numPriorPayouts
    notes
    startDate
    unfundedAt
    sponsors {
      _id
      contactType
      fullName
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const AdoptionViewAttributes = gql`
  {
    _id
    sponsorNames
    sponsors {
      _id
      fullName
      contactType
      primaryThumbnailUrl
      primaryEmail
      primaryPhoneNumber
      primaryAddress {
        _id
        label
        address1
        address2
        city
        state
        zipCode
        country
      }
    }
    adopterName
    peopleGroupId
    peopleGroup {
      _id
      population
      continent
      region
      regionCode
      country
      affinityBloc
      peopleCluster
      nameInCountry
      nameAcrossCountries
      primaryLanguage
      officialLanguage
      bibleStatus
      primaryReligion
      religionSubdivision
      jpScale
      primaryThumbnailUrl
    }
    regionCode
    fieldWorkerId
    fieldWorker {
      _id
      fullName
      contactType
      primaryThumbnailUrl
      primaryEmail
      primaryPhoneNumber
      primaryAddress {
        _id
        label
        address1
        address2
        city
        state
        zipCode
        country
      }
    }
    fieldWorkerName
    regionalCoordId
    regionalCoordName
    regionalCoord {
      _id
      fullName
      contactType
      primaryEmail
      primaryPhoneNumber
      primaryAddress {
        _id
        label
        address1
        address2
        city
        state
        zipCode
        country
      }
      primaryThumbnailUrl
    }
    level
    status
    amount
    accountId
    tags {
      key
      color
    }
    donationFrequency
    numOfPayoutQuarters
    payoutHistory {
      payoutId
      quarter
      completedOn
    }
    numPriorPayouts
    notes
    startDate
    endDate
    unfundedAt
    isOnline
    commitToAdopt
    recurringPaymentIds
    newAdoptionCampaignTriggeredAt
    history {
      desc
      createdAt
    }
    paymentHistory {
      desc
      paymentId
      recurringPaymentId
      createdAt
    }
    firstPaymentDate
    lastPaymentDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const AdoptionPayoutAttributes = gql`
  {
    _id
    contactId
    contact {
      primaryThumbnailUrl
    }
    adopterName
    peopleGroupId
    peopleGroup {
      _id
      population
      continent
      region
      regionCode
      country
      affinityBloc
      peopleCluster
      nameInCountry
      nameAcrossCountries
      primaryLanguage
      officialLanguage
      bibleStatus
      primaryReligion
      religionSubdivision
      jpScale
      primaryThumbnailUrl
    }
    donationFrequency
    tags {
      key
      color
    }
    regionCode
    fieldWorkerId
    fieldWorkerName
    regionalCoordId
    regionalCoordName
    level
    status
    amount
    accountId
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const allAdoptionsQuery = gql`
  query AllAdoptions($first: Int, $after: String, $filters: AdoptionFiltersForAdmin, $sortBy: [SortBy]) {
    allAdoptionsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${AdoptionListAttributes}
      }
    }
  }
`;

export const extractAdoptions = (data) => data && data.allAdoptionsForAdmin;

export const allAdoptionsPayoutQuery = gql`
  query AllAdoptionsPayout($first: Int, $after: String, $filters: AdoptionFilters) {
    allAdoptionsForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${AdoptionPayoutAttributes}
      }
    }
  }
`;

export const adoptionForAdminQuery = gql`
  query AdoptionForAdmin($_id: ID!) {
    adoptionForAdmin(_id: $_id)  ${AdoptionViewAttributes}
  }
`;

export const extractAdoptionForAdmin = (data) => data && data.adoptionForAdmin;

export const createAdoptionMutation = gql`
  mutation CreateAdoption($adoption: AdoptionCreateInput!) {
    createAdoption(adoption: $adoption) {
      adoption ${AdoptionViewAttributes}
    }
  }
`;

export const updateAdoptionMutation = gql`
  mutation UpdateAdoption($adoption: AdoptionUpdateInput!) {
    updateAdoption(adoption: $adoption) {
      adoption ${AdoptionViewAttributes}
    }
  }
`;

export const deleteAdoptionMutation = gql`
  mutation DeleteAdoption($_id: ID!) {
    deleteAdoption(_id: $_id) {
      deletedId
    }
  }
`;

export const triggerNewAdoptionEmailCampaignMutation = gql`
  mutation TriggerNewAdoptionEmailCampaign($adoptionId: ID!) {
    triggerNewAdoptionEmailCampaign(adoptionId: $adoptionId) {
      adoption ${AdoptionViewAttributes}
    }
  }
`;

export const AdoptionLevels = {
  ADOPTER_PRAY: {
    value: 0,
    key: 'ADOPTER_PRAY',
    label: 'Prayer',
    defaultMonthlyAmount: 0,
  },
  ADOPTER_LV1: {
    value: 1,
    key: 'ADOPTER_LV1',
    label: 'Level 1',
    defaultMonthlyAmount: 30,
  },
  ADOPTER_LV2: {
    value: 2,
    key: 'ADOPTER_LV2',
    label: 'Level 2',
    defaultMonthlyAmount: 100,
  },
  ADOPTER_LV3: {
    value: 3,
    key: 'ADOPTER_LV3',
    label: 'Level 3',
    defaultMonthlyAmount: 300,
  },
};

export const AdoptionStatuses = {
  NEW: {
    sort: 0,
    key: 'NEW',
    label: 'New',
    color: 'purple',
  },
  ACTIVE: {
    sort: 0,
    key: 'ACTIVE',
    label: 'Active',
    color: 'blue',
  },
  ON_HOLD: {
    sort: 1,
    key: 'ON_HOLD',
    label: 'On Hold',
    color: 'orange',
  },
  PAID_OUT: {
    sort: 2,
    key: 'PAID_OUT',
    label: 'Paid Out',
    color: 'gray',
  },
  INCOMPLETE: {
    sort: 3,
    key: 'INCOMPLETE',
    label: 'Incomplete',
    color: 'red',
  },
  REASSIGNED: {
    sort: 4,
    key: 'REASSIGNED',
    label: 'Reassigned',
    color: 'yellow',
  },
  UNFUNDED: {
    sort: 5,
    key: 'UNFUNDED',
    label: 'Unfunded',
    color: 'purple',
  },
  NOT_YET_ASSIGNED: {
    sort: 6,
    key: 'NOT_YET_ASSIGNED',
    label: 'Not Yet Assigned',
    color: 'green',
  },
  NEED_TO_REASSIGN: {
    sort: 7,
    key: 'NEED_TO_REASSIGN',
    label: 'Need to Reassign',
    color: 'black',
  },
};

export const DonationFrequency = {
  ONE_TIME: {
    key: 'ONE_TIME',
    label: 'One Time',
  },
  RECURRING: {
    key: 'RECURRING',
    label: 'Recurring',
  },
};

export const AdoptionUpdateAttributes = gql`
{
  crud
  id
  new ${AdoptionViewAttributes}
}
`;

export const singleAdoptionUpdatesSubscription = gql`
  subscription SingleAdoptionUpdates($_id: ID!) {
    adoptionUpdatesForAdmin(_id: $_id) ${AdoptionUpdateAttributes}
  }
`;
