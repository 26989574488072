import React from 'react';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import SelectTags from './SelectTags';

export function tagsBeforeProcessing(tags) {
  return tags?.map((t) => ({ key: t.key, color: t.color })) || [];
}

function TagsFormItem({ name, label, saving }) {
  return (
    <Form.Item
      name={name}
      label={label}
      extra={
        <div style={{ paddingTop: 4 }}>
          <Link to="/manage-tags">Manage Tags</Link>
        </div>
      }
    >
      <SelectTags disabled={saving} />
    </Form.Item>
  );
}

export default TagsFormItem;
