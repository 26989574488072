import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import {
  FilterOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  List,
  Radio,
  Select,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import { generateUuid } from '../../shared/utils';
import CreateHouseholdButton from './CreateHouseholdButton';
import ExportContactsBtn from './ExportContactsBtn';
import ContactCard from './ListContacts/ContactCard';
import {
  allContactsWithPgInfoQuery,
  extractAllContactsForAdmin,
} from './constants';

const { Text, Paragraph } = Typography;

function PeoplePage() {
  const [form] = Form.useForm();

  const newId = useMemo(() => generateUuid(), []);
  const history = useHistory();

  const [sortedBy, setSortedBy] = useState('firstName.keyword');
  const [sortOrder, setSortOrder] = useState('ASC');

  const [showFilters, setShowFilters] = useState(false);
  const filters = useRef({
    hasNoHousehold: true,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: contacts,
    search: contactSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allContactsWithPgInfoQuery,
    extract: extractAllContactsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'people',
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );
  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        contactSearch(search.toLowerCase());
      } else {
        filters.current = {
          hasNoHousehold: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [contactSearch, refetch, sortedBy, sortOrder],
  );

  const _selectedContacts = useRef({});
  const [selectedContacts, setSelectedContacts] = useState(
    _selectedContacts.current,
  );
  const toggleSelected = useCallback((contact) => {
    if (_selectedContacts.current[contact._id]) {
      delete _selectedContacts.current[contact._id];
    } else {
      _selectedContacts.current[contact._id] = contact;
    }
    setSelectedContacts({ ..._selectedContacts.current });
  }, []);
  const numSelectectContacts = Object.values(selectedContacts).length;

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  return (
    <CSPage title="People">
      <CSPageHeader
        title="People"
        topActions={[
          <Link key="contact" to={`/contact/edit/${newId}/contact`}>
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new contact
            </Button>
          </Link>,
          <Link key="household" to={`/contact/edit/${newId}/household`}>
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new household
            </Button>
          </Link>,
          <Link key="org" to={`/contact/edit/${newId}/org`}>
            <Button type="link" icon={<PlusCircleOutlined />} size="large">
              Add a new organization
            </Button>
          </Link>,
          <ExportContactsBtn filters={filters} key="export" />,
        ]}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="search"
              style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
            >
              <Input placeholder="Search" suffix={<SearchOutlined />} />
            </Form.Item>
            <Button
              onClick={() => setShowFilters(!showFilters)}
              icon={<FilterOutlined />}
            >
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
            <div style={{ flex: 1 }} />
            <Button
              icon={<ReloadOutlined />}
              onClick={async () => {
                await reset();
              }}
            >
              Refresh
            </Button>
          </div>
          {showFilters && (
            <>
              <div style={{ display: 'flex' }}>
                <div className="ant-form-item" style={{ marginRight: 16 }}>
                  <div className="ant-col ant-form-item-label">
                    <label style={{ marginRight: 5 }}>Sort by</label>
                  </div>
                  <Select value={sortedBy} onChange={onSortedByChange}>
                    <Select.Option value="firstName.keyword">
                      First Name
                    </Select.Option>
                    <Select.Option value="lastName.keyword">
                      Last Name
                    </Select.Option>
                    <Select.Option value="updatedAt">
                      Recently Updated
                    </Select.Option>
                  </Select>
                </div>
                <div className="ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label style={{ marginRight: 5 }}>Sort Order</label>
                  </div>
                  <Select value={sortOrder} onChange={onSortOrderChange}>
                    {['firstName.keyword', 'lastName.keyword'].includes(
                      sortedBy,
                    ) && (
                      <>
                        <Select.Option value="ASC">A-Z</Select.Option>
                        <Select.Option value="DESC">Z-A</Select.Option>
                      </>
                    )}
                    {['updatedAt'].includes(sortedBy) && (
                      <>
                        <Select.Option value="ASC">
                          Most Recent Last
                        </Select.Option>
                        <Select.Option value="DESC">
                          Most Recent First
                        </Select.Option>
                      </>
                    )}
                  </Select>
                </div>
              </div>
              <Form.Item name="contactType">
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={undefined}>All</Radio.Button>
                  <Radio.Button value="CONTACT">Contacts</Radio.Button>
                  <Radio.Button value="HOUSEHOLD">Households</Radio.Button>
                  <Radio.Button value="ORG">Organizations</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <div style={{ display: 'flex' }}>
                <Tooltip title="The following switches will filter out all persons but the selected. Selecting two switches will filter all persons who don't meet both criteria.">
                  <Button
                    size="large"
                    style={{ marginTop: 16, marginRight: 16 }}
                    type="link"
                    icon={<QuestionCircleOutlined />}
                  />
                </Tooltip>
                <Form.Item
                  name="isRegionalCoord"
                  label="Regional Coordinators"
                  valuePropName="checked"
                  initialValue={false}
                  style={{ marginRight: 16 }}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="isFieldWorker"
                  label="Field Workers"
                  valuePropName="checked"
                  initialValue={false}
                  style={{ marginRight: 16 }}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="isUpgSponsor"
                  label="Sponsors"
                  valuePropName="checked"
                  initialValue={false}
                  style={{ marginRight: 16 }}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  name="isTrainer"
                  label="Trainers"
                  valuePropName="checked"
                  initialValue={false}
                  style={{ marginRight: 16 }}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
              </div>
              <div style={{ display: 'flex' }}>
                <Form.Item
                  label="Should receive email"
                  name="shouldReceiveEmail"
                  valuePropName="checked"
                  initialValue={false}
                  style={{ marginRight: 16 }}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label="Should receive physical mail"
                  name="shouldReceivePhysicalMail"
                  valuePropName="checked"
                  initialValue={false}
                  className="switch"
                >
                  <Switch />
                </Form.Item>
              </div>
              <Form.Item
                label="Hide contacts that are members of a household"
                name="hasNoHousehold"
                valuePropName="checked"
                initialValue={true}
                className="switch"
              >
                <Switch />
              </Form.Item>
              <div style={{ height: 16 }} />
            </>
          )}
        </Form>
      </div>
      {numSelectectContacts > 0 && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#c6cfd3',
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            alignItems: 'center',
            borderRadius: 8,
          }}
        >
          <div style={{ flex: 1 }}>
            <Text>{`${numSelectectContacts} contact${
              numSelectectContacts > 1 ? 's' : ''
            } selected`}</Text>
          </div>
          <CreateHouseholdButton
            selected={Object.values(selectedContacts)}
            handleSuccess={(householdId) => {
              history.push(`/contact/edit/${householdId}/household`);
            }}
          />
        </div>
      )}
      {loading && !contacts.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            className="contactsList"
            dataSource={contacts.slice(0, pageSize)}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(contact) => (
              <ContactCard
                contact={contact}
                selected={!!selectedContacts[contact._id]}
                toggleSelected={toggleSelected}
              />
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Contacts"
                />
              ),
            }}
          />
          <div style={{ textAlign: 'center' }}>
            {contacts && contacts.length <= pageSize && hasNextPage && (
              <Button onClick={next}>Load More</Button>
            )}
            {contacts && contacts.length > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          margin-bottom: 16px;
        }
      `}</style>
      <style global jsx>{`
        .switch .ant-form-item-control-input {
          min-height: unset;
        }
      `}</style>
    </CSPage>
  );
}

export default PeoplePage;
