import { Form } from 'antd';
import React, { useEffect } from 'react';
import SelectContact from '../../contacts/SelectContact';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function AddFWRCInnerModalA10({
  adoption,
  saving,
  handleFinish,
  onCancel,
  filters,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (adoption) {
      form.setFieldsValue({
        regionalCoordId: adoption.regionalCoordId,
        fieldWorkerId: adoption.fieldWorkerId,
      });
    }
  }, [adoption, form]);

  return (
    <TaskSomethingForm
      title="Add Contact"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="regionalCoordId"
        label="Regional Coordinator"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <SelectContact
          placeholder="Select ..."
          disabled={saving}
          filters={filters}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.regionalCoordId !== curValues.regionalCoordId
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('regionalCoordId') && (
            <Form.Item
              name="fieldWorkerId"
              label="Field Worker"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <SelectContact
                placeholder="Select ..."
                disabled={saving}
                filters={{
                  isFieldWorker: true,
                  regionalCoordId: getFieldValue('regionalCoordId'),
                  peopleGroupId: adoption.peopleGroupId,
                }}
              />
            </Form.Item>
          )
        }
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default AddFWRCInnerModalA10;
