import sharedSettings from '@aims/shared/sharedSettings';
import {
  CheckCircleOutlined,
  GoogleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaskAction } from '../../redux-store/tasks-store';
import useCheckGoogleCreds from '../../shared/use-check-google-creds';
import useMyGoogleLogin from '../../shared/use-my-google-login';
import useUpdateGoogleCredentials from '../../shared/use-update-google-credentials';
import useAddTasksToGoogle from './use-add-task-to-google';
import useUpdateGoogleTask from './use-update-google-task';

const { Title, Text, Paragraph } = Typography;

const States = {
  missingGoogleCreds: 'missing-google-creds',
  missingRequiredScopes: 'missing-required-scopes',
  expiredGoogleCreds: 'expired-google-creds',
  unknownError: 'unknown-error',
  loading: 'loading',
  success: 'success',
  error: 'error',
  hidden: 'hidden',
};

const AddTaskToGoogleModal = ({
  queryId,
  handleAddToGoogle,
  handleUpdateInGoogle,
}) => {
  const addTaskToGoogle = useAddTasksToGoogle();
  const updateGoogleTask = useUpdateGoogleTask();
  const checkGoogleCreds = useCheckGoogleCreds();
  const [error, setError] = useState(false);
  const [scopes, setScopes] = useState();
  const taskId = useRef();
  const [state, setState] = useState(States.hidden);
  const updateGoogleCreds = useUpdateGoogleCredentials();
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();
  const currentAction = useRef();

  const doAddTask = useCallback(async () => {
    const result = await addTaskToGoogle({
      variables: {
        taskId: taskId.current,
      },
    });
    dispatch(
      updateTaskAction({ queryId, ...result.data.addTaskToGoogle.task }),
    );
    setState(States.success);
    setTimeout(() => {
      setState(States.hidden);
    }, 3000);
  }, [addTaskToGoogle, dispatch, queryId]);

  const doUpdateTask = useCallback(async () => {
    const result = await updateGoogleTask({
      variables: {
        taskId: taskId.current,
      },
    });
    dispatch(
      updateTaskAction({ queryId, ...result.data.updateGoogleTask.task }),
    );
    setState(States.success);
    setTimeout(() => {
      setState(States.hidden);
    }, 3000);
  }, [updateGoogleTask, dispatch, queryId]);

  const doGoogleLogin = useMyGoogleLogin(
    async (response) => {
      try {
        await updateGoogleCreds({
          variables: {
            code: response.code,
            scopes,
            origin: window.location.origin,
          },
        });
        if (currentAction.current === 'add') {
          await doAddTask();
        } else {
          await doUpdateTask();
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
        setState(States.error);
      }
    },
    (err) => {
      console.error(err);
      setError(err.message);
      setState(States.error);
    },
  );

  handleAddToGoogle.current = useCallback(
    async (_taskId) => {
      setTitle('Add Task to Google');
      setState(States.loading);
      setError(null);
      try {
        taskId.current = _taskId;
        let response;
        currentAction.current = 'add';
        response = await checkGoogleCreds('TASKS');
        if (response.success) {
          await doAddTask();
        } else {
          setScopes(response.requiredScopes.join(' '));
          setState(response.error.code);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
        setState(States.error);
      }
    },
    [checkGoogleCreds, doAddTask],
  );

  handleUpdateInGoogle.current = useCallback(
    async (_taskId) => {
      setTitle('Update Google Task');
      setState(States.loading);
      setError(null);
      try {
        taskId.current = _taskId;
        let response;
        currentAction.current = 'update';
        response = await checkGoogleCreds('TASKS');
        if (response.success) {
          await doUpdateTask();
        } else {
          setScopes(response.requiredScopes.join(' '));
          setState(response.error.code);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
        setState(States.error);
      }
    },
    [checkGoogleCreds, doUpdateTask],
  );

  const handleGoogleSignIn = useCallback(() => {
    setState(States.loading);
    doGoogleLogin(scopes);
  }, [doGoogleLogin, scopes]);

  return (
    <>
      <Modal
        visible={state !== States.hidden}
        destroyOnClose={true}
        maskClosable={state !== States.loading}
        closable={false}
        onCancel={() => setState(States.hidden)}
        footer={null}
      >
        <Title level={3} style={{ textAlign: 'center', marginTop: 16 }}>
          {title}
        </Title>
        {state === States.loading && (
          <div className="spinner">
            <LoadingOutlined />
          </div>
        )}
        {state === States.missingGoogleCreds && (
          <div className="results">
            <div style={{ marginBottom: 16 }}>
              <Text>Please sign into your Google account</Text>
            </div>
            <div>
              <Button
                onClick={handleGoogleSignIn}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={!doGoogleLogin}
              >
                Sign in with Google
              </Button>
            </div>
          </div>
        )}
        {[States.missingRequiredScopes, States.expiredGoogleCreds].includes(
          state,
        ) && (
          <div className="results">
            <div style={{ marginBottom: 16 }}>
              <Text>
                Please authorize AIMS to add tasks to your Google calendar
              </Text>
            </div>
            <div>
              <Button
                onClick={handleGoogleSignIn}
                icon={<GoogleOutlined />}
                style={{ marginBottom: 8 }}
                loading={!doGoogleLogin}
              >
                Authorize AIMS
              </Button>
            </div>
          </div>
        )}
        {state === States.unknownError && (
          <div className="results">
            <div style={{ marginBottom: 16 }}>
              <Text>
                There was an error while trying to access your Google tasks
              </Text>
            </div>
          </div>
        )}
        {state === States.success && (
          <div className="success">
            <CheckCircleOutlined style={{ fontSize: 48, marginBottom: 16 }} />
            <Text>Success!</Text>
          </div>
        )}
        {error && (
          <div>
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{`
        .spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 32px;
          font-size: 48px;
        }
        .results {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .success {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: ${sharedSettings.colors.success};
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default AddTaskToGoogleModal;
