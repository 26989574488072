import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import YesNoModal from '../../components/YesNoModal';
import { updateContactAction } from '../../redux-store/contacts-store';
import { contactFullAttributes } from '../contacts/constants';

const disassociateContactWithPpPayerMutation = gql`
  mutation disassociateContactWithPpPayer($payerId: ID!, $contactId: ID!) {
    disassociateContactWithPpPayer(payerId: $payerId, contactId: $contactId) {
      contact ${contactFullAttributes}
    }
  }
`;

const { Text } = Typography;

function UnlinkPushpayAccountModal({ deleting, setDeleting }) {
  const { queryId, contact, payer } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [disassociateContactWithPpPayer] = useMutation(
    disassociateContactWithPpPayerMutation,
  );
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      if (contact) {
        const result = await disassociateContactWithPpPayer({
          variables: {
            contactId: contact._id,
            payerId: payer._id,
          },
        });
        dispatch(
          updateContactAction(
            result.data.disassociateContactWithPpPayer.contact,
            queryId,
          ),
        );
        notification.success({
          message: 'Saved',
          description: 'Contact disassociated from payer successfully',
        });
      } else {
        throw new Error('Contact is undefined');
      }
      setDeleting(undefined);
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [
    queryId,
    contact,
    disassociateContactWithPpPayer,
    dispatch,
    payer,
    setDeleting,
  ]);

  const onNo = useCallback(() => {
    if (!loading) {
      setDeleting(undefined);
    }
  }, [setDeleting, loading]);

  return (
    <YesNoModal
      title="Unlink Pushpay Account"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text>
              {'Are you sure you want to unlink this Pushpay account?'}
            </Text>
          </div>
          <div>
            <Text>{`${payer && payer.fullName}`}</Text>
            <br />
            {payer && payer.emailAddress && (
              <>
                <Text>{`${payer && payer.emailAddress}`}</Text>
                <br />
              </>
            )}
            {payer && payer.mobileNumber && (
              <>
                <Text>{`${payer && payer.mobileNumber}`}</Text>
                <br />
              </>
            )}
          </div>
        </div>
      }
      yesText="Unlink"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!deleting}
      loading={loading}
      error={error}
    />
  );
}

export default UnlinkPushpayAccountModal;
