import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Space, Typography } from 'antd';
import React from 'react';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import { generateUuid } from '../../../../shared/utils';
import ProfilePhotoFormItemJ24 from '../../../profile-photo/ProfilePhotoFormItemJ24';

const { Text } = Typography;

function EditTrainingCourseForm({
  onCancel,
  onSubmit,
  saving,
  setSaving,
  form,
  trainingCourse,
  _id,
}) {
  return (
    <>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="editTrainingCourse"
        form={form}
      >
        <ProfilePhotoFormItemJ24
          name="profilePhotoId"
          existing={
            trainingCourse && {
              _id: trainingCourse.profilePhotoId,
              thumbnailUrl: trainingCourse.primaryThumbnailUrl,
            }
          }
          styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
          parentType="trainingFile"
          parentId={trainingCourse?._id || _id}
          tags={['Training Course']}
          saving={saving}
          setSaving={setSaving}
        />
        <Form.Item
          name="courseName"
          label="Course Name"
          rules={[{ required: true, message: 'Please enter the course name' }]}
        >
          <Input disabled={saving} />
        </Form.Item>
        <Form.Item name="desc" label="Description">
          <Input.TextArea rows={4} disabled={saving} />
        </Form.Item>
        <Form.Item>
          <Form.List name="modules" label="Modules">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          border: `1px solid ${sharedSettings.colors.borderGray}`,
                          borderRadius: 4,
                          padding: 16,
                          flex: 1,
                        }}
                      >
                        <Form.Item
                          name={[field.name, '_id']}
                          initialValue={generateUuid()}
                          noStyle
                        >
                          <Input hidden />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'name']}
                          label={`Module ${index + 1} Name`}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a name for this module',
                            },
                          ]}
                        >
                          <Input autoComplete="chrome-off" disabled={saving} />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'desc']}
                          label="Description"
                        >
                          <Input disabled={saving} />
                        </Form.Item>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Module
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              key="cancel"
              onClick={onCancel}
              htmlType="button"
              type="text"
              size="small"
              disabled={saving}
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: -7 }}
            >
              Cancel
            </Button>
            <Button
              key="send"
              type="primary"
              loading={saving}
              htmlType="submit"
            >
              Save Course
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditTrainingCourseForm;
