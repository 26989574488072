import { displayMoney } from '@aims/shared/shared/utils';
import { Card, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text } = Typography;

function DisplayRecurringPaymentA10({ payment }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const startDate =
    payment.startDate &&
    new Intl.DateTimeFormat(locale, {
      dateStyle: 'medium',
    }).format(new Date(payment.startDate));
  return (
    <>
      <Title level={4}>Recurring Donation</Title>
      <Card>
        <Title level={5}>{payment.payerName}</Title>
        <div>
          <Text>{`Fund: ${payment.fundName}`}</Text>
        </div>
        <div>
          <Text>{`Amount: ${displayMoney(payment.usdCentsAmount / 100)}`}</Text>
        </div>
        <div>
          <Text>{`Start Date: ${startDate}`}</Text>
        </div>
      </Card>
    </>
  );
}

export default DisplayRecurringPaymentA10;
