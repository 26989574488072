import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import TransferCreateModal from './TransferCreateModal';
import TransferFilters from './TransferFilters';
import TransferUpdateModal from './TransferUpdateModal';
import TransfersTable from './TransfersTable';
import ExportTransfersBtn from './components/ExportTransfersBtn';
import DeleteTransferModal from './DeleteTransferModal';

function TransfersTabInner({
  parentFilters,
  hideActions,
  refetch,
  filters,
  transferSearch,
  sortBy,
  sortedBy,
  sortOrder,
  transfers,
  loading,
  hasNextPage,
  next,
}) {
  const [creating, setCreating] = useState(false);
  const onCreate = () => setCreating(true);
  const onCancelCreate = () => setCreating(false);
  const onFinishCreate = () => {
    refetch?.();
    setCreating(false);
  };

  const [editing, setEditing] = useState();
  const onEdit = (o) => setEditing({ transfer: o });
  const onCancelEdit = () => setEditing(undefined);
  const onFinishEdit = () => {
    refetch?.();
    setEditing(undefined);
  };

  const [deleting, setDeleting] = useState();
  const onDelete = (o) => setDeleting(o);
  const onCancelDelete = () => setDeleting(undefined);
  const onFinishDelete = () => {
    refetch?.();
    setDeleting(undefined);
  };

  return (
    <>
      <div style={{ marginBottom: 16, marginLeft: -16 }}>
        <Button
          type="text"
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={onCreate}
        >
          Create a New Transaction
        </Button>
      </div>
      <TransferFilters
        filtersRef={filters}
        refetch={refetch}
        search={transferSearch}
        sortByRef={sortBy}
        parentFilters={parentFilters}
        sortedBy={sortedBy}
        sortOrder={sortOrder}
        more={<ExportTransfersBtn transfers={transfers} />}
      />
      <TransfersTable
        transfers={transfers}
        loading={loading}
        onEdit={onEdit}
        accountId={parentFilters?.accountId}
        hideActions={hideActions}
        onDelete={onDelete}
      />
      {next && (
        <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
      )}
      <TransferCreateModal
        visible={creating}
        onCancel={onCancelCreate}
        onFinish={onFinishCreate}
      />
      <TransferUpdateModal
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeleteTransferModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </>
  );
}

export default TransfersTabInner;
