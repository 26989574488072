import { DatePicker, Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

export function MyDatePicker({
  disabled,
  showTime,
  onChange: parentOnChange,
  value: parentValue,
  allowClear,
  format,
  defaultValue,
}) {
  const value = useMemo(() => {
    if (parentValue) {
      return dayjs(parentValue);
    }
    return parentValue;
  }, [parentValue]);

  const onChange = useCallback(
    (date, dateString) => {
      parentOnChange(date?.toISOString());
    },
    [parentOnChange],
  );
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      disabled={disabled}
      showTime={showTime}
      style={{ width: '100%', padding: '8px 16px', borderRadius: 2 }}
      allowClear={allowClear}
      format={format}
      defaultValue={defaultValue}
    />
  );
}

function DateFormItem({
  name,
  label,
  rules,
  disabled,
  showTime = false,
  extra,
  allowClear,
  style,
  initialValue,
  format,
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      style={{ width: '100%', ...style }}
      extra={extra}
      initialValue={initialValue}
    >
      <MyDatePicker
        disabled={disabled}
        showTime={showTime}
        allowClear={allowClear}
        format={format}
      />
    </Form.Item>
  );
}

export default DateFormItem;
