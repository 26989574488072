import { AimsStatuses } from '@aims/shared/people-groups/constants';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import { PeopleGroupFocusLevels } from '../EditPeopleGroup/constants';
import {
  allPeopleGroupsForAdminQuery,
  extractAllPeopleGroupsForAdmin,
} from '../constants';
import PeopleGroupFilters from './PeopleGroupFilters';

const { Title, Text, Link: TextLink } = Typography;

function ListPeopleGroups() {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleUpdate = useCallback(
    (peopleGroupId) => () => {
      history.push(`people-group/edit/${peopleGroupId}`, {
        mode: 'update',
        from: `/people-groups`,
      });
    },
    [history],
  );

  const filters = useRef({});
  const sortBy = useRef([
    { key: '_score', order: 'DESC' },
    { key: 'focusLevelNo', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: peopleGroups,
    search: peopleGroupSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPeopleGroupsForAdminQuery,
    extract: extractAllPeopleGroupsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'listPeopleGroups',
  });

  const handleTableChange = useCallback(
    (pages, _, sorters) => {
      const sortBys = [];
      sortBys.push({ key: '_score', order: 'DESC' });
      const keyMap = {
        _id: 'id.keyword',
        nameAcrossCountries: 'nameAcrossCountries.keyword',
        country: 'country.keyword',
        aimsStatusPercent: 'aimsStatusPercent',
        focusLevelNo: 'focusLevelNo',
      };
      const orderMap = {
        ascend: 'ASC',
        descend: 'DESC',
      };
      if (Array.isArray(sorters)) {
        sorters.forEach((sorter) => {
          if (sorter.column) {
            sortBys.push({
              key: keyMap[sorter.columnKey],
              order: orderMap[sorter.order],
            });
          }
        });
      } else {
        if (sorters.column) {
          sortBys.push({
            key: keyMap[sorters.columnKey],
            order: orderMap[sorters.order],
          });
        }
      }
      sortBy.current = sortBys;
      refetch();
      setPagination({
        ...pages,
      });
    },
    [refetch],
  );

  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);

  return (
    <CSPage
      containerStyle={{ width: '100%', maxWidth: 'unset' }}
      title="People Groups"
    >
      <CSPageHeader title="People Groups" />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <PeopleGroupFilters
          refetch={refetch}
          filters={filters}
          peopleGroupSearch={peopleGroupSearch}
          loading={loading}
        />
      </div>
      <Table
        dataSource={peopleGroups}
        loading={!peopleGroups.length && loading}
        pagination={{ ...pagination, total: peopleGroups.length }}
        onChange={handleTableChange}
        // style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1000 }}
      >
        <Column
          title="People Group ID"
          dataIndex="_id"
          key="_id"
          sorter={{
            compare: (a, b) => {
              return a._id.localeCompare(b._id);
            },
            multiple: 1,
          }}
          sortDirections={['ascend', 'descend']}
        />
        {/* <Column title="Continent" dataIndex="continent" key="continent" /> */}
        <Column
          title="Name"
          dataIndex="nameAcrossCountries"
          key="nameAcrossCountries"
          sorter={{
            compare: (a, b) => {
              return a.nameAcrossCountries.localeCompare(b.nameAcrossCountries);
            },
            multiple: 1,
          }}
        />
        <Column
          title="Country"
          key="country"
          sorter={{
            compare: (a, b) => {
              return a.country.localeCompare(b.country);
            },
            multiple: 1,
          }}
          render={(text, record) => (
            <>
              <div>
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  {record.region}
                </Text>
              </div>
              <div>
                <Text strong>{`${record.country}`}</Text>
              </div>
            </>
          )}
        />
        {/* <Column
          title="Language"
          dataIndex="primaryLanguage"
          key="primaryLanguage"
        /> */}
        <Table.Column
          title="AIMS Status"
          key="aimsStatusPercent"
          sorter={{
            compare: (a, b) => {
              return (a.aimsStatusPercent || 0) - (b.aimsStatusPercent || 0);
            },
            multiple: 1,
          }}
          width={200}
          render={(text, record) => (
            <Popover
              content={
                <>
                  {Object.entries(AimsStatuses).map(([key, val]) => (
                    <div key={key}>
                      <Text>{`${val.label}: ${
                        record[key] ? 'Yes' : 'No'
                      }`}</Text>
                    </div>
                  ))}
                </>
              }
              trigger="click"
            >
              <div className="right">
                <Text>{`${Math.round(record.aimsStatusPercent || 0)}%`}</Text>
              </div>
              <div>
                <TextLink>Details</TextLink>
              </div>
            </Popover>
          )}
        />
        <Column title="JP Scale" dataIndex="jpScale" key="jpScale" />
        <Column
          title="Focus Level"
          key="focusLevelNo"
          sorter={{
            compare: (a, b) => {
              return (a.focusLevelNo || 0) - (b.focusLevelNo || 0);
            },
            multiple: 1,
          }}
          render={(text, record) =>
            record.focusLevelNo != undefined
              ? PeopleGroupFocusLevels[record.focusLevelNo].label
              : ''
          }
        />
        <Column
          title="Connections"
          key="connections"
          render={(text, record) => (
            <>
              <div>
                <Text>{`Sponsors: ${record.numAdopters || 0}`}</Text>
              </div>
              <div>
                <Text>{`FWs: ${record.numFieldWorkers || 0}`}</Text>
              </div>
              <div>
                <Text>{`RCs: ${record.numRegionalCoords || 0}`}</Text>
              </div>
            </>
          )}
        />
        <Column
          title="Sponsorships Available"
          dataIndex="numAdoptionsAvailable"
          key="numAdoptionsAvailable"
        />
        <Column
          title="Projects"
          dataIndex="numHolisticProjects"
          key="numHolisticProjects"
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Button
                  onClick={() =>
                    history.push(`/people-group/view/${record._id}/info`)
                  }
                  icon={<EyeOutlined />}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <Button
                  onClick={handleUpdate(record._id)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </CSPage>
  );
}

export default ListPeopleGroups;
