export const READ_LOAN_AGREEMENTS = 'READ_LOAN_AGREEMENTS';
export const CREATE_LOAN_AGREEMENT = 'CREATE_LOAN_AGREEMENT';
export const UPDATE_LOAN_AGREEMENT = 'UPDATE_LOAN_AGREEMENT';
export const DELETE_LOAN_AGREEMENT = 'DELETE_LOAN_AGREEMENT';

export const readLoanAgreementsAction = (
  // eslint-disable-next-line no-shadow
  loanAgreements,
  queryId = 'default',
) => ({
  type: READ_LOAN_AGREEMENTS,
  payload: {
    loanAgreements,
    queryId,
  },
});

export const createLoanAgreementAction = (
  loanAgreement,
  queryId = 'default',
) => ({
  type: CREATE_LOAN_AGREEMENT,
  payload: {
    loanAgreement,
    queryId,
  },
});

export const updateLoanAgreementAction = (
  loanAgreement,
  queryId = 'default',
) => ({
  type: UPDATE_LOAN_AGREEMENT,
  payload: {
    loanAgreement,
    queryId,
  },
});

export const deleteLoanAgreementAction = (
  loanAgreementId,
  queryId = 'default',
) => ({
  type: DELETE_LOAN_AGREEMENT,
  payload: {
    loanAgreementId,
    queryId,
  },
});

const initialLoanAgreements = { default: {} };

export function loanAgreements(state = initialLoanAgreements, action) {
  switch (action.type) {
    case READ_LOAN_AGREEMENTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.loanAgreements.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_LOAN_AGREEMENT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.loanAgreement._id]: action.payload.loanAgreement,
        },
      };
    case UPDATE_LOAN_AGREEMENT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.loanAgreement._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.loanAgreement._id]: {
            ...current,
            ...action.payload.loanAgreement,
          },
        },
      };
    }
    case DELETE_LOAN_AGREEMENT: {
      const { loanAgreementId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [loanAgreementId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
