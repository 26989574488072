import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const allJPRegionsQuery = gql`
  query AllJPRegions {
    allJPRegions {
      _id
      name
      continentCode
    }
  }
`;

function useJpRegions() {
  const { loading, error, data } = useQuery(allJPRegionsQuery);
  const regions = useMemo(() => {
    return data?.allJPRegions?.reduce((prev, curr) => {
      prev[curr._id] = curr;
      return prev;
    }, {});
  }, [data]);
  return regions;
}

export default useJpRegions;
