import sharedSettings from '@aims/shared/sharedSettings';
import { UserOutlined } from '@ant-design/icons';
import { Button, Card, List, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { ContactTypes } from '../contacts/constants';
import {
  TaskPriorityColors,
  getHumanTimeLeft,
  getUrgencyColor,
} from './EditTask/constants';
import TaskAssigneeDot from './TaskAssigneeDot';

const { Title, Text } = Typography;

function TaskListItem({ task, setShowTaskModel }) {
  const timeLeft = useMemo(() => {
    if (task && task.windowStop) {
      const timeLeftMs = new Date(task.windowStop) - new Date();
      return {
        timeLeft: getHumanTimeLeft(timeLeftMs),
        color: getUrgencyColor(timeLeftMs),
      };
    }
    return null;
  }, [task]);
  const contactName = useMemo(() => {
    if (task.contact) {
      if (ContactTypes[task.contact.contactType]) {
        return `${task.contact.fullName} (${
          ContactTypes[task.contact.contactType].label
        })`;
      }
      return task.contact.fullName;
    }
    return null;
  }, [task]);
  return (
    <List.Item style={{ width: '100%', border: 'none' }}>
      <Button
        onClick={() => setShowTaskModel(task._id)}
        style={{ padding: 0, height: 'unset', width: '100%', borderRadius: 8 }}
      >
        <Card
          style={{ width: '100%', borderRadius: 8 }}
          bordered={false}
          styles={{
            body: { textAlign: 'left' },
          }}
        >
          {contactName && (
            <div
              to={`/contact/view/${task.contactId}/dashboard`}
              style={{ fontSize: 14, marginBottom: 8, display: 'block' }}
            >
              <UserOutlined style={{ marginRight: 8 }} />
              {contactName}
            </div>
          )}
          <Title
            level={3}
            style={{ marginBottom: 12, marginTop: 0, width: '100%' }}
            ellipsis
          >
            {task.title}
          </Title>
          <div className="task-title">
            <div
              className="importance"
              style={{
                marginRight: 16,
                borderColor: TaskPriorityColors[task.importance].color,
                color: TaskPriorityColors[task.importance].color,
              }}
            >
              {`${TaskPriorityColors[task.importance].label} Priority`}
            </div>
            {timeLeft && (
              <div
                className="importance"
                style={{
                  marginRight: 16,
                  color: timeLeft.color,
                  borderColor: timeLeft.color,
                }}
              >
                {timeLeft.timeLeft}
              </div>
            )}
            <div style={{ flex: 1 }} />
            <div style={{ display: 'flex', marginRight: -8 }}>
              {task?.assignees?.map((assignee) => (
                <TaskAssigneeDot
                  assignee={assignee}
                  key={assignee._id}
                  style={{ marginBottom: 4, marginRight: 8 }}
                />
              ))}
            </div>
          </div>
          {task.tags && (
            <div className="tags">
              {task.tags.map((tag) => (
                <Tag key={tag.key} color={sharedSettings.colors.primary}>
                  {tag.key}
                </Tag>
              ))}
            </div>
          )}
        </Card>
      </Button>
      <style jsx>{`
        .top-actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .task-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          width: 100%;
        }
        .importance {
          padding: 8px 16px;
          border: 1px solid;
          border-radius: 4px;
        }
      `}</style>
    </List.Item>
  );
}

export default TaskListItem;
