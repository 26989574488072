import {
  ArrowLeftOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import DeleteTaskModal from '../DeleteTaskModal';
import EditTaskForm from './EditTaskForm';
import useTask from './use-task';

function EditTask() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);

  const { _id } = useParams();
  const queryId = useMemo(() => {
    return Math.round(Math.random() * 10000);
  }, []);

  const taskResult = useTask(_id);
  const { task } = taskResult;

  const handleBack = useCallback(() => history.goBack(), [history]);

  const { title, showDelete } = useMemo(() => {
    let creating;
    if (loading) {
      if (
        location.state &&
        location.state.mode &&
        location.state.mode === 'create'
      ) {
        creating = true;
      } else {
        creating = false;
      }
    } else if (!loading) {
      if (task) {
        creating = false;
      } else {
        creating = true;
      }
    }
    let _title;
    let _showDelete;
    if (creating) {
      _title = 'Create an Task';
      _showDelete = false;
    } else {
      _title = 'Edit Task';
      _showDelete = true;
    }
    return { title: _title, showDelete: _showDelete };
  }, [loading, task, location]);

  return (
    <>
      <CSPage title="Edit Task">
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Tasks
            </Button>,
          ]}
        />
        <EditTaskForm
          _id={_id}
          handleCancel={handleBack}
          handleSuccess={() => history.push('/tasks')}
          taskResult={taskResult}
          loading={loading}
          setLoading={setLoading}
          style={{ maxWidth: 500 }}
          queryId={queryId}
        />
        {showDelete && (
          <div style={{ maxWidth: 500 }}>
            <Divider />
            <div className="delete-box">
              <Space align="center">
                <Button
                  onClick={() => setDeleting(task)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete Task
                </Button>
              </Space>
            </div>
          </div>
        )}
        <div style={{ minHeight: 300 }} />
        <DeleteTaskModal setTask={setDeleting} task={deleting} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
        .send-invite-checkbox {
          flex-shrink: 0;
          margin-left: 8px;
        }
        .note {
          margin-bottom: 16px;
          display: flex;
          width: 100%;
        }
        .inner-note {
          margin-right: 8px;
          flex: 1;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
}

export default EditTask;
