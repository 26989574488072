import { Tag, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

function PayoutHasReportColumn({ record }) {
  return record.hasReport === true ? (
    <Tag color="green">YES</Tag>
  ) : (
    <Tag color="red">NO</Tag>
  );
}

export default PayoutHasReportColumn;
