import { EyeOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import settings from '../../../settings';

const { Title, Text, Paragraph } = Typography;

const MarkLoanAgreementSignBtn = ({
  loanAgreement,
  setError,
  error,
  queryId,
  onSuccess,
}) => {
  return (
    <>
      <Button
        icon={<EyeOutlined />}
        href={`${settings.userAppUrl}/loan-agreement/sign/${loanAgreement._id}`}
      >
        Sign Agreement
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default MarkLoanAgreementSignBtn;
