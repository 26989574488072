import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../../../components/YesNoModal';

export const removeContactFromOrganizationMutation = gql`
  mutation RemoveContactFromOrganization($_id: ID!) {
    removeContactFromOrganization(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteContactXOrgModal({ visible, onDone, onCancel }) {
  const { contact, org, contactXOrg } = visible || {};

  const [saving, setSaving] = useState(false);
  const [removeContactFromOrganization] = useMutation(
    removeContactFromOrganizationMutation,
  );

  const handleFinish = useCallback(async () => {
    setSaving(true);
    try {
      await removeContactFromOrganization({
        variables: {
          _id: contactXOrg._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Contact successfully removed',
      });
      onDone();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error removing contact from organization',
      });
    }
    setSaving(false);
  }, [contactXOrg, removeContactFromOrganization, onDone]);

  return (
    <YesNoModal
      title="Remove Contact from Organization"
      question={`Are you sure you want to remove ${contact?.fullName} from the organization ${org?.fullName}?`}
      yesText="Remove"
      noText="Cancel"
      onYes={handleFinish}
      onNo={onCancel}
      visible={!!contact}
      loading={saving}
    />
  );
}

export default DeleteContactXOrgModal;
