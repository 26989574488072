import { gql } from '@apollo/client';

export const PpPaymentListAttributes = gql`
  {
    _id
    recurringPaymentToken
    status
    usdCentsAmount
    paymentMethodType
    refundForId
    refundedById
    payerId
    payerName
    payerEmail
    ipAddress
    fundId
    fundName
    notes
    ppCreatedOn
    ppUpdatedOn
    ppReceivedOn
    givenOn
    adoptionId
    adoption {
      _id
      adopterName
      peopleGroupId
      fieldWorkerName
      regionalCoordName
      level
      amount
      accountId
    }
    adoptions {
      adoptionId
      adoption {
        _id
        sponsorNames
        peopleGroupId
        fieldWorkerName
        regionalCoordName
        level
        amount
        accountId
      }
      percent
      accountId
      amount
    }
    sponsorships {
      sponsorshipId
      sponsorship {
        _id
        sponsorName
        projectId
        projectName
        region
      }
      accountId
      percent
      amount
    }
    directAccounts {
      accountId
      account {
        _id
        name
      }
      percent
      amount
    }
    suggestedAllocations {
      sponsorshipId
      adoptionId
      accountId
      percent
    }
    extraAccountId
    isAllocated
    transactionId
    settlementId
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

export const allPpPaymentsQuery = gql`
  query AllPpPaymentsForAdmin($first: Int, $after: String, $filters: PpPaymentFiltersForAdmin, $sortBy: [SortBy]) {
    allPpPaymentsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PpPaymentListAttributes}
      }
    }
  }
`;

export const extractAllPayments = (data) => data && data.allPpPaymentsForAdmin;
