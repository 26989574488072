export const READ_ALLOCATIONS = 'READ_ALLOCATIONS';
export const CREATE_ALLOCATION = 'CREATE_ALLOCATION';
export const UPDATE_ALLOCATION = 'UPDATE_ALLOCATION';
export const DELETE_ALLOCATION = 'DELETE_ALLOCATION';

// eslint-disable-next-line no-shadow
export const readAllocationsAction = (allocations) => ({
  type: READ_ALLOCATIONS,
  payload: {
    allocations,
  },
});

export const createAllocationAction = (allocation) => ({
  type: CREATE_ALLOCATION,
  payload: {
    allocation,
  },
});

export const updateAllocationAction = (allocation) => ({
  type: UPDATE_ALLOCATION,
  payload: {
    allocation,
  },
});

export const deleteAllocationAction = (allocationId) => ({
  type: DELETE_ALLOCATION,
  payload: {
    allocationId,
  },
});

const initialAllocations = [];

export function allocations(state = initialAllocations, action) {
  switch (action.type) {
    case READ_ALLOCATIONS: {
      const newState = { ...state };
      action.payload.allocations.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_ALLOCATION:
      return {
        ...state,
        [action.payload.allocation._id]: action.payload.allocation,
      };
    case UPDATE_ALLOCATION: {
      const current = state[action.payload.allocation._id] || undefined;
      return {
        ...state,
        [action.payload.allocation._id]: {
          ...current,
          ...action.payload.allocation,
        },
      };
    }
    case DELETE_ALLOCATION: {
      const { allocationId } = action.payload;
      return { ...state, [allocationId]: undefined };
    }
    default:
      return state;
  }
}
