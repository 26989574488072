import { DollarCircleOutlined } from '@ant-design/icons';
import { Tabs, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import AdminListDonations from './donations/ListDonations/AdminListDonations';
import AdminListPayers from './payers/ListPayers/AdminListPayers';
import AdminListFunds from './funds/ListFunds/AdminListFunds';
import AdminListRecurringPayments from './recurring-payments/RecurringPayments/AdminListRecurringPayments';

const { Title } = Typography;

const titles = {
  donations: 'Pushpay - Donations',
  people: 'Pushpay - People',
};

function PushPayItems() {
  const history = useHistory();

  const { tab } = useParams();

  useEffect(() => {
    if (!tab) {
      history.replace(`/pushpay/donations`);
    }
  }, [tab, history]);
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Pushpay">
        <CSPageHeader
          titleComponent={
            <Title>
              <DollarCircleOutlined style={{ marginRight: 16 }} />
              {titles[tab] || 'Pushpay'}
            </Title>
          }
          style={{ marginBottom: 16 }}
        />
        <Tabs
          defaultActiveKey="donations"
          activeKey={tab}
          onChange={(key) => history.push(`/pushpay/${key}`)}
        >
          <Tabs.TabPane tab="Donations" key="donations">
            <AdminListDonations />
          </Tabs.TabPane>
          <Tabs.TabPane tab="People" key="people">
            <AdminListPayers />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Funds" key="funds">
            <AdminListFunds />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Recurring Payments" key="recurring">
            <AdminListRecurringPayments />
          </Tabs.TabPane>
        </Tabs>
        <div style={{ minHeight: 300 }} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default PushPayItems;
