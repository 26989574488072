import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import ContentEditableInput, {
  serializeText,
} from '../../../components/ContentEditableInput';
import { isValidJSON } from '../../../shared/utils';

const { Text, Title } = Typography;

function EditEventDescription({ onSubmit, event }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const onFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      const { desc } = values;
      try {
        await onSubmit({
          desc,
          descText: desc && serializeText(JSON.parse(desc)),
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <>
      <map
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        {!editing && (
          <div className="container">
            <Title style={{ textAlign: 'center' }} level={4}>
              Description
            </Title>
            {event.desc && (
              <Form>
                <Form.Item noStyle>
                  <ContentEditableInput readOnly value={event.desc} />
                </Form.Item>
              </Form>
            )}
            {!event.desc && (
              <div>
                <Text type="secondary">Click to add</Text>
              </div>
            )}
          </div>
        )}
      </map>
      {editing && (
        <Form onFinish={onFinish}>
          <Title style={{ textAlign: 'center' }} level={4}>
            Description
          </Title>
          <Form.Item name="desc" initialValue={event.desc}>
            <ContentEditableInput disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <div style={{ height: 16 }} />
      <style jsx>{`
        .container {
          text-align: center;
        }
      `}</style>
    </>
  );
}

export default EditEventDescription;
