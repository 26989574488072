import { peopleGroupShortAttributes } from '@aims/shared/people-groups/constants';
import sharedSettings from '@aims/shared/sharedSettings';
import { gql } from '@apollo/client';

export const contactFullAttributes = gql`
  {
    _id
    contactType
    firstName
    middleNames
    lastName
    fullName
    birthday
    relationship
    tags {
      key
      color
    }
    relationship
    primaryEmailId
    primaryPhoneNumberId
    primaryAddressId
    phoneNumbers {
      _id
      label
      phone
    }
    emails {
      _id
      label
      email
    }
    addresses {
      _id
      label
      address1
      address2
      city
      state
      zipCode
      country
    }
    userId
    primaryContactId
    otherDetails
    otherDetailsText
    profilePhoto {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    shouldReceiveEmail
    shouldReceivePhysicalMail
    isSubscribed
    isFieldWorker
    isRegionalCoord
    isTrainer
    isPrayerAdopter
    isDonor
    isProjectPartner
    isUpgSponsor
    isAdopted
    isSupportedByProject
    isSustainedByProject
    isSustainedByLocalChurch
    peopleGroupIds
    regionCode
    regionName
    regionalCoordId
    pushPayPayerKeys
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
  }
`;

export const contactListAttributes = gql`
  {
    _id
    contactType
    firstName
    middleNames
    lastName
    fullName
    primaryEmailId
    primaryPhoneNumberId
    primaryAddressId
    phoneNumbers {
      _id
      label
      phone
    }
    emails {
      _id
      label
      email
    }
    addresses {
      _id
      label
      address1
      address2
      city
      state
      zipCode
      country
    }
    userId
    otherDetails
    primaryThumbnailUrl
    tags {
      key
      color
    }
    household {
      _id
      fullName
    }
    isFieldWorker
    isRegionalCoord
    isTrainer
    isPrayerAdopter
    isDonor
    isProjectPartner
    isUpgSponsor
    regionName
    isSubscribed
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const createContactMutation = gql`
  mutation createContact($contact: ContactCreateInput!) {
    createContact(contact: $contact) {
      contact ${contactFullAttributes}
    }
  }
`;

export const updateContactMutation = gql`
  mutation updateContact($contact: ContactUpdateInput!) {
    updateContact(contact: $contact) {
      contact ${contactFullAttributes}
    }
  }
`;

export const addPeopleGroupToContactMutation = gql`
  mutation AddPeopleGroupToContact($connection: UpgConnectionInput!) {
    addPeopleGroupToContact(connection: $connection) {
      contact ${contactFullAttributes}
    }
  }
`;

export const updatePeopleGroupConnectionMutation = gql`
  mutation UpdatePeopleGroupConnection(
    $connection: UpgConnectionInput!
  ) {
    updatePeopleGroupConnection(connection: $connection) {
      contact ${contactFullAttributes}
    }
  }
`;

export const removePeopleGroupFromContactMutation = gql`
  mutation RemovePeopleGroupFromContact($contactId: ID!, $peopleGroupId: ID!) {
    removePeopleGroupFromContact(contactId: $contactId, peopleGroupId: $peopleGroupId) {
      contact ${contactFullAttributes}
    }
  }
`;

export const allContactsForAdminQwuery = gql`
  query AllContactsForAdmin($first: Int, $after: String, $filters: ContactFiltersForAdmin, $sortBy: [SortBy]) {
    allContactsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${contactListAttributes}
      }
    }
  }
`;

export const extractAllContactsForAdmin = (data) =>
  data && data.allContactsForAdmin;

export const contactsWithPgInfoAttributes = gql`
  {
    _id
    contactType
    firstName
    middleNames
    lastName
    fullName
    peopleGroupIds
    tags {
      key
      color
    }
    userId
    primaryThumbnailUrl
    isFieldWorker
    isRegionalCoord
    isTrainer
    isPrayerAdopter
    isDonor
    isProjectPartner
    isUpgSponsor
    regionName
    isSubscribed
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allContactsWithPgInfoQuery = gql`
  query AllContactsWithPgInfo($first: Int, $after: String, $filters: ContactFiltersForAdmin, $sortBy: [SortBy]) {
    allContactsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${contactsWithPgInfoAttributes}
      }
    }
  }
`;

export const ContactTypes = {
  CONTACT: {
    key: 'CONTACT',
    label: 'Contact',
    color: sharedSettings.colors.contactColor,
  },
  HOUSEHOLD: {
    key: 'HOUSEHOLD',
    label: 'Household',
    color: sharedSettings.colors.householdColor,
  },
  ORG: {
    key: 'ORG',
    label: 'Organization',
    color: sharedSettings.colors.organizationColor,
  },
};

export const exportContactsMutation = gql`
  mutation ExportContacts($filters: ContactFiltersForAdmin!) {
    exportContacts(filters: $filters)
  }
`;

export const exportContactsSubscription = gql`
  subscription ExportContacts {
    exportContacts {
      success
      file {
        _id
        desc
        s3Key
        size
        filename
        contentType
        url
        uploaded
      }
      error
    }
  }
`;

export const extractContactForAdmin = (data) => data && data.contactForAdmin;
