import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Text } = Typography;

function MiraclePeopleGroupLine({ peopleGroup }) {
  return (
    <div>
      <Link to={`people-group/view/${peopleGroup._id}/info`}>
        <div>
          <Text style={{ fontWeight: 600 }}>People Group: </Text>
          <Text>
            {peopleGroup.nameAcrossCountries} - {peopleGroup.country}
          </Text>
        </div>
      </Link>
    </div>
  );
}

export default MiraclePeopleGroupLine;
