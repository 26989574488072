import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import YesNoModal from '../../components/YesNoModal';
import { deleteHolisticProposalForAdminMutation } from './constants';

function DeleteHolisticProposal({ report, setReport }) {
  const [loading, setLoading] = useState(false);
  const [deleteReport] = useMutation(deleteHolisticProposalForAdminMutation);
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteReport({
        variables: {
          _id: report._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Proposal deleted successfully',
      });
      setTimeout(() => {
        setReport(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setLoading(false);
  }, [report, deleteReport, setReport, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setReport(null);
    }
  }, [setReport, loading]);

  return (
    <YesNoModal
      title="Delete Proposal"
      question={`Are you sure you want to delete this holistic project proposal?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!report}
      loading={loading}
    />
  );
}

export default DeleteHolisticProposal;
