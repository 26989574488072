import { useApolloClient } from '@apollo/client';
import { Form, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { accountQuery } from '../constants';
import sharedSettings from '@aims/shared/sharedSettings';

export const TransferFlags = {
  HP_FUNDING: {
    key: 'HP_FUNDING',
    label: 'Holistic Project Funding',
    desc: 'Funding transfers must include this flag to be counted toward a projects funding balance and percentage',
  },
  HP_PAYBACK: {
    key: 'HP_PAYBACK',
    label: 'Holistic Project Payback',
    desc: 'Payback transfers must include this flag to be counted toward a projects payback balance and percentage',
  },
};

function TransferFlagsFormItem({ form, name }) {
  const apolloClient = useApolloClient();
  const options = useMemo(() => {
    return Object.values(TransferFlags).map((flag) => ({
      value: flag.key,
      label: flag.label,
      desc: flag.desc,
    }));
  }, []);
  const toAccountId = Form.useWatch(name, form);
  const [account, setAccount] = useState();
  useEffect(() => {
    async function doAsyncStuff() {
      const result = await apolloClient.query({
        query: accountQuery,
        variables: {
          _id: toAccountId,
        },
        fetchPolicy: 'network-only',
      });
      setAccount(result.data?.account);
    }
    if (toAccountId) {
      doAsyncStuff();
    }
  }, [toAccountId, apolloClient]);
  return (
    account?.projectId && (
      <Form.Item label="Flag" name="flag">
        <Select
          options={options}
          allowClear
          optionRender={(option) => (
            <div>
              <div
                style={{
                  color: sharedSettings.colors.primary,
                  fontWeight: 600,
                }}
              >
                {option.label}
              </div>
              <div style={{ whiteSpace: 'normal', wordWrap: 'normal' }}>
                {option.data.desc}
              </div>
            </div>
          )}
        />
      </Form.Item>
    )
  );
}

export default TransferFlagsFormItem;
