import React from 'react';
import { Typography } from 'antd';

const { Title, Text } = Typography;

function ReportInfoItem({ label, value }) {
  return (
    <div
      style={{
        marginBottom: 4,
      }}
    >
      <Text
        style={{
          fontWeight: 600,
          marginRight: 8,
        }}
      >
        {`${label}:`}
      </Text>
      <Text>{value || 0}</Text>
    </div>
  );
}

export default ReportInfoItem;
