export const READ_FUNDS = 'READ_FUNDS';
export const CREATE_FUND = 'CREATE_FUND';
export const UPDATE_FUND = 'UPDATE_FUND';
export const DELETE_FUND = 'DELETE_FUND';

// eslint-disable-next-line no-shadow
export const readFundsAction = (funds) => ({
  type: READ_FUNDS,
  payload: {
    funds,
  },
});

export const createFundAction = (fund) => ({
  type: CREATE_FUND,
  payload: {
    fund,
  },
});

export const updateFundAction = (fund) => ({
  type: UPDATE_FUND,
  payload: {
    fund,
  },
});

export const deleteFundAction = (fundId) => ({
  type: DELETE_FUND,
  payload: {
    fundId,
  },
});

const initialFunds = [];

export function funds(state = initialFunds, action) {
  switch (action.type) {
    case READ_FUNDS: {
      const newState = { ...state };
      action.payload.funds.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_FUND:
      return {
        ...state,
        [action.payload.fund._id]: action.payload.fund,
      };
    case UPDATE_FUND: {
      const current = state[action.payload.fund._id] || undefined;
      return {
        ...state,
        [action.payload.fund._id]: {
          ...current,
          ...action.payload.fund,
        },
      };
    }
    case DELETE_FUND: {
      const { fundId } = action.payload;
      return { ...state, [fundId]: undefined };
    }
    default:
      return state;
  }
}
