import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';

export const updateAdoptionMutation = gql`
  mutation ChangeSomethingModalA10($adoption: AdoptionUpdateInput!) {
    updateAdoption(adoption: $adoption) {
      adoption {
        _id
      }
    }
  }
`;

function ChangeSomethingModalA10({ adoption, children, btnProps, btnText }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateAdoption] = useMutation(updateAdoptionMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateAdoption({
          variables: {
            adoption: {
              _id: adoption._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'UPG Sponsorship saved successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error updating your UPG Sponsorship',
        });
      }
      setSaving(false);
    },
    [adoption, updateAdoption, onDone],
  );
  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={onCancel}
        // width={700}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        {React.cloneElement(children, {
          adoption,
          handleFinish,
          saving,
          onCancel,
        })}
      </Modal>
      <Button
        type="text"
        icon={<EditOutlined />}
        onClick={handleEdit}
        {...btnProps}
      >
        {btnText}
      </Button>
    </>
  );
}

export default ChangeSomethingModalA10;
