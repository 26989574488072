import { useApolloClient } from '@apollo/client';
import { notification } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import {
  incrementQueryAction,
  nextQueryAction,
  updateQueryAction,
} from '../redux-store/queries-store';
import autocomplete from './autocomplete';
import { hashCode } from './utils';

function useManyRemoteM1({
  query,
  extract,
  first,
  filters,
  sortBy,
  fetchPolicy = 'no-cache',
  queryId = 'default',
  skip,
}) {
  const [loading, setLoading] = useState(false);
  const term$ = useRef();
  const nextAfter = useRef();
  const hasNextPage = useRef();
  const _data = useRef([]);
  const [data, _setData] = useState(_data.current);
  const setData = useCallback((value) => {
    _data.current = value;
    _setData(value);
  }, []);

  const queryCode = useMemo(() => {
    const inputs = JSON.stringify({
      query,
      first,
      filters,
      sortBy,
    });

    const code = hashCode(inputs);
    return code;
  }, [query, first, filters, sortBy]);

  const { refetches, after } = useSelector((store) => {
    const update = store.queries[queryId]?.[queryCode] || {
      _id: queryCode,
      refetches: 0,
      after: undefined,
    };
    return update;
  }, shallowEqual);

  const search = useRef();
  const apolloClient = useApolloClient();
  const abort = useRef({});
  const last = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateQueryAction(
        {
          _id: queryCode,
          refetches,
          after,
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      if (abort.current[query]) {
        abort.current[query].abort();
      }
      abort.current[query] = new AbortController();
      try {
        let _sortBy;
        if (search.current && !sortBy?.find((s) => s.key === '_score')) {
          _sortBy = [{ key: '_score', order: 'DESC' }];
        } else {
          _sortBy = sortBy;
        }
        const result = await apolloClient.query({
          query,
          variables: {
            first,
            after,
            filters: {
              ...filters,
              search: search.current,
            },
            sortBy: _sortBy,
          },
          fetchPolicy,
          context: {
            fetchOptions: {
              signal: abort.current.signal,
            },
          },
        });
        const results = result && extract(result.data);
        if (results) {
          hasNextPage.current = results.pageInfo.hasNextPage;
          nextAfter.current = results.pageInfo.endCursor;
          if (after) {
            setData([
              ..._data.current,
              ...results.edges.map((edge) => edge.node),
            ]);
          } else {
            setData(results.edges.map((edge) => edge.node));
          }
        }
      } catch (err) {
        if (err.message.includes('abort')) {
          console.log('Fetch aborted.');
        } else {
          console.error(err);
          notification.error({
            message: 'Error',
            description: err.message,
          });
        }
      } finally {
        abort.current[query] = undefined;
        setLoading(false);
      }
    }

    const inputs = JSON.stringify({
      refetches,
      after,
      queryCode,
    });
    if (inputs !== last.current && !skip) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    filters,
    first,
    setData,
    refetches,
    apolloClient,
    query,
    sortBy,
    extract,
    fetchPolicy,
    queryCode,
    after,
    dispatch,
    skip,
  ]);

  useEffect(() => {
    term$.current = new BehaviorSubject('__init__');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          if (term !== '__init__') {
            hasNextPage.current = undefined;
            nextAfter.current = undefined;
            search.current = term || undefined;
            dispatch(incrementQueryAction(queryCode, queryId));
          }
          return of();
        }),
      )
      .subscribe();
  }, [setData, dispatch, queryCode, queryId]);

  const _search = useCallback((term) => {
    if (term !== undefined) {
      term$.current?.next(term);
    }
  }, []);

  const refetch = useCallback(() => {
    dispatch(incrementQueryAction(queryCode, queryId));
  }, [dispatch, queryCode, queryId]);

  return {
    data,
    hasNextPage: hasNextPage.current,
    loading,
    next: () => {
      if (hasNextPage.current && nextAfter.current) {
        dispatch(nextQueryAction(queryCode, nextAfter.current, queryId));
      }
    },
    search: _search,
    refetch,
    reset: async () => {
      await apolloClient.resetStore();
      dispatch(incrementQueryAction(queryCode, queryId));
    },
  };
}

export default useManyRemoteM1;
