import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import { Space } from 'antd';
import React from 'react';
import ApproveTrainEventBtn from './ApproveTrainEventBtn';
import EditTrainEventBtn from './EditTrainEventBtn';
import MarkTrainEventPendingBtn from './MarkTrainEventPendingBtn';
import PutTrainEventOnHoldBtn from './PutTrainEventOnHoldBtn';
import RejectTrainEventBtn from './RejectTrainEventBtn';
import useProfile from '../../../../shared/use-profile';

const ReviewButtons = ({ report, onSuccess }) => {
  const profile = useProfile();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {report.status === ReportApprovalStatuses.DRAFT.key && (
            <EditTrainEventBtn report={report} />
          )}
          {report.status === ReportApprovalStatuses.PENDING.key && (
            <>
              <ApproveTrainEventBtn report={report} onSuccess={onSuccess} />
              <PutTrainEventOnHoldBtn report={report} onSuccess={onSuccess} />
              <RejectTrainEventBtn report={report} onSuccess={onSuccess} />
            </>
          )}
          {report.status === ReportApprovalStatuses.REJECTED.key &&
            profile.username === 'superadmin' && (
              <MarkTrainEventPendingBtn report={report} onSuccess={onSuccess} />
            )}
          {report.status === ReportApprovalStatuses.APPROVED.key &&
            profile.username === 'superadmin' && (
              <MarkTrainEventPendingBtn report={report} onSuccess={onSuccess} />
            )}
          {report.status === ReportApprovalStatuses.HOLD.key && (
            <>
              <ApproveTrainEventBtn report={report} onSuccess={onSuccess} />
              <MarkTrainEventPendingBtn report={report} onSuccess={onSuccess} />
              <RejectTrainEventBtn report={report} onSuccess={onSuccess} />
            </>
          )}
          {/* <PrintReportBtn /> */}
        </Space>
      </div>
    </>
  );
};

export default ReviewButtons;
