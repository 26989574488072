import { gql } from '@apollo/client';

export const ReportAttachmentAttributes = gql`
  {
    _id
    desc
    s3Key
    size
    filename
    contentType
    tags
    url
    uploaded
    processed
    thumbnail {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    images {
      sizeCode
      url
    }
  }
`;

export const ReportUserShortAttributes = gql`
  {
    _id
    name
  }
`;

export const ReportUserLongAttributes = gql`
  {
    _id
    name
    primaryThumbnailUrl
  }
`;

export const ReportPeopleGroupAttributes = gql`
  {
    _id
    country
    regionCode
    nameAcrossCountries
    primaryThumbnailUrl
  }
`;
