const sharedSettings = {
  querySize: 100,
  colors: {
    primary: '#336195',
    warning: '#E8BD4E',
    ghost: '#eef1f2',
    gray: '#E5E5E5',
    error: '#ff4d4f',
    red: '#f5222d',
    text: '#333333',
    // borderGray: '#d9d9d9',
    borderGray: '#d7dfe0',
    backgroundGray: '#d7dfe0',
    textGray: '#838485',
    contactColor: '#006d75',
    householdColor: '#cf1322',
    organizationColor: '#d4380d',
    timestampColor: '#c2c2c2',
    success: '#52c41a',
    darkGray: '#95a8ad',
    darkerGray: '#5f767c',
    dark: '#333333',
    green: '#52c41a',
    yellow: '#faad14',
    blue: '#0050b3',
    white: '#ffffff',
  },
};

export default sharedSettings;
