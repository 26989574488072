import { Tabs } from 'antd';
import React from 'react';
import HolisticQuarterlyReportsTypeTab from './HolisticQuarterlyReportsTypeTab';
import { useHistory, useParams } from 'react-router-dom';

function HolisticQuarterlyReportsTabs({ holisticProjectId, baseUrl }) {
  const { reportCategory } = useParams();
  const history = useHistory();
  return (
    <Tabs
      defaultActiveKey="pending"
      activeKey={reportCategory}
      onChange={(key) => history.push(`${baseUrl}/${key}`)}
    >
      <Tabs.TabPane tab="Pending" key="pending">
        <HolisticQuarterlyReportsTypeTab
          reportStatus="PENDING"
          holisticProjectId={holisticProjectId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Approved" key="approved">
        <HolisticQuarterlyReportsTypeTab
          reportStatus="APPROVED"
          holisticProjectId={holisticProjectId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="On Hold" key="hold">
        <HolisticQuarterlyReportsTypeTab
          reportStatus="HOLD"
          holisticProjectId={holisticProjectId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Drafts" key="draft">
        <HolisticQuarterlyReportsTypeTab
          reportStatus="DRAFT"
          holisticProjectId={holisticProjectId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rejected" key="rejected">
        <HolisticQuarterlyReportsTypeTab
          reportStatus="REJECTED"
          holisticProjectId={holisticProjectId}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default HolisticQuarterlyReportsTabs;
