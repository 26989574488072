import { Tag, Typography } from 'antd';
import React from 'react';
import { PayoutRowStates } from '../../constants';

function PayoutCanBeCompletedColumn({ record }) {
  const completable = record.completable && record.completable.completable;
  return record.state !== PayoutRowStates.COMPLETE.key ? (
    <>
      {completable === 'NO' && <Tag color="red">NO</Tag>}
      {completable === 'YES' && <Tag color="green">YES</Tag>}
      {completable === 'MAYBE' && <Tag color="gold">WARNING</Tag>}
    </>
  ) : null;
}

export default PayoutCanBeCompletedColumn;
