import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EditTrainEventBtn = ({ report }) => {
  const location = useLocation();

  return (
    <>
      <Link
        to={`/training-event-reports/edit/${report._id}?from=${location.pathname}`}
      >
        <Button icon={<EditOutlined />}>Edit Report</Button>
      </Link>
    </>
  );
};

export default EditTrainEventBtn;
