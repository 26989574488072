import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Form, Typography, Input, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateHolisticProjectAction } from '../../../redux-store/holistic-projects-store';
import { updateHolisticProjectMutation } from '../constants';

const { Paragraph } = Typography;

function ProjectAdminNotes({ project }) {
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const dispatch = useDispatch();
  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const result = await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              adminNotes: values.adminNotes,
            },
          },
        });
        const updated =
          result &&
          result.data &&
          result.data.updateHolisticProject &&
          result.data.updateHolisticProject.holisticProject;
        if (!updated) {
          throw new Error('Unknown error while updating holistic project');
        }
        notification.success({
          message: 'Saved',
          description: 'Project updated successfully',
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [updateHolisticProject, project],
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        adminNotes: project.adminNotes,
      });
    }
  }, [project, form]);
  return (
    <>
      <Divider>Admin Notes</Divider>
      {editing ? (
        <Form onFinish={handleFinish} layout="vertical" form={form}>
          <Form.Item label="Admin Notes" name="adminNotes">
            <Input.TextArea rows={6} disabled={loading} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="button"
              onClick={() => setEditing(false)}
              style={{ marginRight: 16 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
          {error && (
            <Paragraph
              type="danger"
              style={{ textAlign: 'center', marginTop: 16 }}
            >
              {error}
            </Paragraph>
          )}
        </Form>
      ) : (
        <div style={{ background: 'white', padding: 24, position: 'relative' }}>
          <div>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setEditing(true)}
              style={{ position: 'absolute', top: 4, right: 16 }}
            />
          </div>
          {project.adminNotes &&
            project.adminNotes
              .split('\n')
              .map((p, index) => <Paragraph key={index}>{p}</Paragraph>)}
          {!project.adminNotes && <Paragraph>No Notes</Paragraph>}
        </div>
      )}
      <Divider />
    </>
  );
}

export default ProjectAdminNotes;
