import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { gql } from '@apollo/client';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  createPayoutV2RowAction,
  deletePayoutV2RowAction,
  readPayoutV2RowsAction,
  updatePayoutV2RowAction,
} from '../../../redux-store/payout-v2-rows-store';

const PayoutV2RowAttributes = gql`
  {
    _id
    payoutId
    adoptionId
    adoptionCopy {
      _id
      contactId
      sponsorIds
      sponsorNames
      peopleGroupId
      peopleGroup {
        _id
        region
        regionCode
        country
        nameAcrossCountries
        primaryThumbnailUrl
      }
      regionCode
      adopterName
      level
      fieldWorkerId
      fieldWorkerName
      regionalCoordId
      regionalCoordName
      amount
      accountId
      status
    }
    fromPool
    fromAccountId
    details {
      totalAmount
      balance
      toRegionalCoord
      toFieldWorker
      toHolistic
      toTraining
      toAnd
    }
    completable {
      completable
      reason
    }
    hasReport
    willReceiveFunds
    amount
    state
    transferIds
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allPayoutV2RowsQuery = gql`
  query AllPayoutV2Rows($first: Int, $after: String, $filters: PayoutV2RowFilters, $sortBy: [SortBy]) {
    allPayoutV2Rows(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PayoutV2RowAttributes}
      }
    }
  }
`;

const PayoutV2UpdateAttributes = gql`
{
  crud
  id
  new ${PayoutV2RowAttributes}
}
`;

const payoutV2RowUpdatesSubscription = gql`
  subscription PayoutV2RowUpdates($filters: PayoutV2RowUpdateFilters!) {
    payoutV2RowUpdates(filters: $filters) ${PayoutV2UpdateAttributes}
  }
`;

const extractPayoutV2Rows = (data) => data && data.allPayoutV2Rows;

function useLocalPayoutV2Rows({
  payoutId,
  filters,
  skip,
  sortByFunc,
  queryId = 'default',
}) {
  const { error } = useSingleSomethingUpdatesA10({
    variables: {
      filters: { payoutId, willReceiveFunds: filters?.willReceiveFunds },
    },
    subscription: payoutV2RowUpdatesSubscription,
    createAction: createPayoutV2RowAction,
    updateAction: updatePayoutV2RowAction,
    deleteAction: deletePayoutV2RowAction,
    extractSomething: (resp) => resp?.data?.payoutV2RowUpdates,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading, refetch } = useManyLocalM20({
    query: allPayoutV2RowsQuery,
    extract: extractPayoutV2Rows,
    readAction: readPayoutV2RowsAction,
    first: 10000,
    filters: { payoutId, willReceiveFunds: filters?.willReceiveFunds },
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
    skip,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.updatedAt?.localeCompare(a.updatedAt);
    };
  }, [sortByFunc]);
  const filterFunc = useCallback(
    (a) => {
      if (payoutId !== a.payoutId) {
        return false;
      }
      if (filters?.adopterId && filters.adopterId !== a.adopterId) {
        return false;
      }
      if (filters?.fieldWorkerId && filters.fieldWorkerId !== a.fieldWorkerId) {
        return false;
      }
      if (filters?.regionalCoord && filters.regionalCoord !== a.regionalCoord) {
        return false;
      }
      if (filters?.peopleGroupId && filters.peopleGroupId !== a.peopleGroupId) {
        return false;
      }
      if (
        filters?.willReceiveFunds !== undefined &&
        filters.willReceiveFunds !== a.willReceiveFunds
      ) {
        return false;
      }
      if (filters?.status && filters.status !== a.status) {
        return false;
      }
      return true;
    },
    [filters, payoutId],
  );
  const { data, search } = useManySomethingsM20({
    table: 'payoutV2Rows',
    sortByFunc: defaultSort,
    searchKeys: [
      '_id',
      'adoptionId',
      'adoptionCopy.contactId',
      'adoptionCopy.adopterName',
      'adoptionCopy.peopleGroupId',
      'adoptionCopy.fieldWorkerId',
      'adoptionCopy.fieldWorkerName',
      'adoptionCopy.regionalCoordId',
      'adoptionCopy.regionalCoordName',
      'adoptionCopy.sponsorNames',
      'notes',
    ],
    queryId,
    filterFunc,
  });
  return {
    loading,
    search,
    data,
    refetch,
  };
}

export default useLocalPayoutV2Rows;
