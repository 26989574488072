import sharedSettings from '@aims/shared/sharedSettings';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../../components/PhotoCircle';
import AddHouseholdBtnAndModal from './AddHouseholdBtnAndModal';
import RemoveFromHouseholdModalModal from './RemoveFromHouseholdModalModal';

const { Title, Text } = Typography;

function ContactHousehold({ contact }) {
  const [removing, setRemoving] = useState();
  const handleRemove = () =>
    setRemoving({ contact, household: contact.household });
  const onFinishRemove = () => setRemoving(undefined);
  const onCancelRemove = () => setRemoving(undefined);
  return (
    <div style={{ marginBottom: 32 }}>
      <Title level={2}>Household</Title>
      {!contact.householdId && <AddHouseholdBtnAndModal contact={contact} />}
      {contact.household && (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 60, marginRight: 8 }}>
              <PhotoCircle
                photoUrl={contact.household.primaryThumbnailUrl}
                name={contact.household.fullName}
                customSize={60}
                color={sharedSettings.colors.householdColor}
                style={{
                  padding: 0,
                  height: 60,
                  width: 60,
                }}
              />
            </div>
            <Title level={3} style={{ margin: 0 }}>
              {contact.household.fullName}
            </Title>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: 16,
              marginLeft: -8,
              marginTop: 4,
            }}
          >
            <Link to={`/contact/view/${contact.householdId}/info`}>
              <Button type="text" icon={<EyeOutlined />}>
                View Household Page
              </Button>
            </Link>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={handleRemove}
            >
              Remove from Household
            </Button>
          </div>
        </>
      )}
      {!contact.household && (
        <div>
          <Text>No household specified</Text>
        </div>
      )}
      <RemoveFromHouseholdModalModal
        visible={removing}
        onDone={onFinishRemove}
        onCancel={onCancelRemove}
      />
    </div>
  );
}

export default ContactHousehold;
