import React from 'react';
import { Button } from 'antd';

function SaveSomethingButtons({ onCancel, saving, saveText }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
      <Button
        onClick={onCancel}
        htmlType="button"
        disabled={saving}
        style={{ marginRight: 16 }}
      >
        Cancel
      </Button>
      <Button type="primary" htmlType="submit" loading={saving}>
        {saveText || 'Save'}
      </Button>
    </div>
  );
}

export default SaveSomethingButtons;
