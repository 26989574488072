import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Select, Typography } from 'antd';
import React, { useMemo, useRef } from 'react';
import settings from '../../../settings';
import { extractAllFunds } from './constants';

const { Text } = Typography;

const allPpFundsQuery = gql`
  query SelectFundForAdmin(
    $first: Int
    $after: String
    $filters: FundFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allPpFundsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          name
          code
          createdBy
          updatedBy
          createdAt
          updatedAt
          deletedAt
          deletedBy
        }
      }
    }
  }
`;

function SelectFund({ value, onChange, filters, disabled, style, ...props }) {
  const sortBy = useRef([
    { key: 'code.keyword', order: 'ASC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: funds,
    search: fundSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPpFundsQuery,
    extract: extractAllFunds,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'selectFund',
  });

  const options = useMemo(() => {
    if (funds) {
      return funds.map((item) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
    }
    return [];
  }, [funds]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a fund"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => fundSearch(v)}
      loading={loading}
      disabled={disabled}
      {...props}
    />
  );
}

export default SelectFund;
