import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { updateHolisticProjectMutation } from '../../../constants';

const { Paragraph, Title } = Typography;

function ShowInCatalogModal({ project, showingModal, setShowingModal }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [updateProject] = useMutation(updateHolisticProjectMutation);

  const handleFinish = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      await updateProject({
        variables: {
          holisticProject: {
            _id: project._id,
            showInCatalog: !project.showInCatalog,
            stage: 'FUNDING',
          },
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Holistic project successfully updated',
      });
      setShowingModal(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setLoading(false);
  }, [updateProject, project, setShowingModal]);

  return (
    <Modal
      header={null}
      footer={null}
      open={showingModal}
      closable={!loading}
      destroyOnClose={true}
      maskClosable={!loading}
      onCancel={() => {
        setShowingModal(false);
      }}
      styles={{ body: { paddingTop: 48 } }}
    >
      <Title level={3} style={{ textAlign: 'center' }}>
        {project.showInCatalog
          ? `Remove from Public Catalog`
          : `Show in Public Catalog`}
      </Title>
      <Form onFinish={handleFinish}>
        <Form.Item>
          <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
            {project.showInCatalog
              ? `Would you like to remove this project from the public catalog?`
              : `Would you like to show this project in the public catalog?`}
          </Paragraph>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div
            style={{
              marginRight: -8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => setShowingModal(null)}
              htmlType="button"
              disabled={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              style={{
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 8,
                minWidth: 100,
              }}
            >
              {project.showInCatalog ? `Remove` : `Show`}
            </Button>
          </div>
        </Form.Item>
      </Form>
      {error && (
        <Paragraph type="danger" style={{ textAlign: 'center', marginTop: 16 }}>
          {error}
        </Paragraph>
      )}
    </Modal>
  );
}

export default ShowInCatalogModal;
