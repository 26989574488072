import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';

const deleteSavedImageMutation = gql`
  mutation DeleteSavedImage($_id: ID!) {
    deleteSavedImage(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteSavedImageModel({ visible, onCancel, onFinish }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteSavedImage] = useMutation(deleteSavedImageMutation);
  const { image } = visible || {};

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await deleteSavedImage({
        variables: {
          _id: image._id,
        },
      });
      if (!response || !response.data || !response.data.deleteSavedImage) {
        throw new Error('Failed to delete image');
      }

      notification.success({
        message: 'Deleted',
        description: 'Image deleted successfully',
      });
      onFinish(image);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [image, deleteSavedImage, onFinish]);

  return (
    <YesNoModal
      title="Delete Saved Image"
      question={`Are you sure you want to delete this image?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={!!visible}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteSavedImageModel;
