import { Button, Checkbox, Form, InputNumber, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DateFormItem from '@aims/shared/components/DateFormItem';

const { Text, Title } = Typography;

function EditEventSchedule({ onSubmit, event }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();
  useEffect(() => {
    if (event) {
      const { startTime, endTime, allDay } = event;
      const _duration = Math.round(
        (new Date(endTime) - new Date(startTime)) / 1000 / 60,
      );
      form.setFieldsValue({
        startTime,
        endTime,
        allDay,
        duration: _duration,
      });
    }
  }, [event, form]);

  const onFinish = useCallback(
    async (_values) => {
      setError(null);
      setLoading(true);
      try {
        const { allDay, startTime, duration: minutes } = _values;
        let endTime = new Date(startTime);
        endTime.setMinutes(endTime.getMinutes() + minutes);
        endTime = endTime.toISOString();
        await onSubmit({
          allDay,
          startTime,
          endTime,
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <map
        className="window-row"
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        {!editing && (
          <>
            <div className="window-row-left">
              <Title level={4}>From</Title>
              <div>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    dateStyle: 'full',
                  }).format(new Date(event.startTime))}
                </Text>
              </div>
              <div>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    timeStyle: 'short',
                  }).format(new Date(event.startTime))}
                </Text>
              </div>
            </div>
            <div className="window-row-right">
              <Title level={4}>Until</Title>
              <div>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    dateStyle: 'full',
                  }).format(new Date(event.endTime))}
                </Text>
              </div>
              <div>
                <Text>
                  {new Intl.DateTimeFormat(locale, {
                    timeStyle: 'short',
                  }).format(new Date(event.endTime))}
                </Text>
              </div>
            </div>
          </>
        )}
      </map>
      {editing && (
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="allDay"
            valuePropName="checked"
            initialValue={event.allDay}
          >
            <Checkbox>All day event</Checkbox>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.allDay !== curValues.allDay
            }
          >
            {({ getFieldValue }) => (
              <>
                <Form.Item label="Start Date">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <DateFormItem
                      name="startTime"
                      rules={[
                        { required: true, message: 'Please select a date' },
                      ]}
                      showTime={!getFieldValue('allDay')}
                    />
                  </div>
                </Form.Item>
                {!getFieldValue('allDay') && (
                  <Form.Item
                    label="Duration (min)"
                    name="duration"
                    rules={[
                      { required: true, message: 'Please enter a duration' },
                    ]}
                  >
                    <InputNumber disabled={loading} min={0} step={15} />
                  </Form.Item>
                )}
              </>
            )}
          </Form.Item>
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <div style={{ height: 24 }} />
      <style jsx>{`
        .window-row {
          display: flex;
          justify-content: center;
          margin-left: -16px;
          margin-right: -16px;
        }
        .window-row-left {
          flex: 1;
          text-align: right;
          margin-left: 16px;
          margin-right: 16px;
        }
        .window-row-right {
          flex: 1;
          text-align: left;
          margin-left: 16px;
          margin-right: 16px;
        }
      `}</style>
    </>
  );
}

export default EditEventSchedule;
