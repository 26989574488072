import { Space } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { generateUuid, toBase64 } from '../shared/utils';
import AttachmentCards from './AttachmentCards';

/**
 * @typedef {Object} Props
 * @property {unknown[]} existingAttachments
 * @property {string} parentId
 * @property {string} parentType
 * @property {string=} parentSubType
 * @property {React.MutableRefObject<unknown>=} retrieveAttachments
 * @property {boolean=} readOnly
 * @property {boolean=} disabled
 */

/** @param {Props} props **/
function AddRemoveAttachments({
  existingAttachments,
  parentId,
  parentType,
  retrieveAttachments,
  ...attachmentCardsProps
}) {
  const _fileList = useRef(existingAttachments || []);
  const [fileList, _setFileList] = useState(_fileList.current);
  const setFileList = useCallback((value) => {
    _fileList.current = value;
    _setFileList(value);
  }, []);

  useEffect(() => {
    if (retrieveAttachments) {
      retrieveAttachments.current = () => {
        return _fileList.current.map((f) => ({
          _id: f._id,
          filename: f.filename,
        }));
      };
    }
  }, [retrieveAttachments]);

  /** @returns {Promise<string, string]>} **/
  const doUploadInner = async (obj) => {
    const attachmentId = generateUuid();
    const s3Key = `${parentType}:${parentId}:attachment:${attachmentId}`;

    let base64Url;
    if (obj.file.type.startsWith('image')) {
      base64Url = await toBase64(obj.file);
    }
    setFileList([
      ..._fileList.current,
      {
        _id: attachmentId,
        s3Key,
        size: obj.file.size,
        filename: obj.file.name,
        contentType: obj.file.type,
        base64Url,
      },
    ]);

    return [attachmentId, s3Key];
  };

  const onUploadDelete = useCallback(
    (file) => () => {
      const fileIndex = _fileList.current.findIndex((f) => f._id === file._id);
      if (fileIndex >= 0) {
        setFileList([
          ..._fileList.current.slice(0, fileIndex),
          ..._fileList.current.slice(fileIndex + 1),
        ]);
      }
    },
    [setFileList],
  );

  return (
    <Space direction="vertical" style={{ marginRight: 8 }}>
      <AttachmentCards
        parentId={parentId}
        parentType={parentType}
        fileList={fileList}
        onUploadDelete={onUploadDelete}
        {...attachmentCardsProps}
        doUploadInner={doUploadInner}
      />
    </Space>
  );
}

export default AddRemoveAttachments;
