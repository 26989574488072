import { Checkbox, Form } from 'antd';
import React, { useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';

function ContactFWStatusInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        isAdopted: contact.isAdopted,
        isSupportedByProject: contact.isSupportedByProject,
        isSustainedByProject: contact.isSustainedByProject,
        isSustainedByLocalChurch: contact.isSustainedByLocalChurch,
      });
    }
  }, [contact, form]);
  return (
    <ContactSomethingForm
      title="Change Field Worker Status"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="isAdopted"
        valuePropName="checked"
        className="my-checkbox"
        extra="This field is auto-populated when sponsorships are created/updated"
      >
        <Checkbox disabled={saving}>Is Sponsored</Checkbox>
      </Form.Item>
      <Form.Item
        name="isSupportedByProject"
        valuePropName="checked"
        className="my-checkbox"
        extra="This field is auto-populated when holistic projects are created/updated"
      >
        <Checkbox disabled={saving}>
          Is Supported by a Holistic Project
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="isSustainedByProject"
        valuePropName="checked"
        className="my-checkbox"
      >
        <Checkbox disabled={saving}>
          Is Sustained by a Holistic Project
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="isSustainedByLocalChurch"
        valuePropName="checked"
        className="my-checkbox"
      >
        <Checkbox disabled={saving}>Is Sustained by a Local Church</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactFWStatusInnerModalA10;
