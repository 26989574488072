import { useMutation } from '@apollo/client';
import { Button, Form, Space, Tag, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { subscribeToMutation } from '../SubscribeToTaskButton';
import AssigneeDot from '../../../components/AssigneeDot';
import AssigneeFormItem from '../../../components/AssigneeFormItem';
import TaskAssigneeDot from '../TaskAssigneeDot';

const { Text, Title } = Typography;

function EditTaskAssignees({ onSubmit, task }) {
  const [subscribeTo] = useMutation(subscribeToMutation);

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const { assigneeIds } = task;
    form.setFieldsValue({
      assigneeIds: assigneeIds || [],
    });
  }, [task, form]);

  const onFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        values.assigneeIds.forEach(async (assignee) => {
          const response = await subscribeTo({
            variables: {
              taskId: task._id,
              userId: assignee,
            },
          });
        });
        await onSubmit(values);
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <>
      <map
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        <Title level={4}>Assigned To</Title>
        {!editing && (
          <Space
            style={{
              minHeight: 36,
              flexWrap: 'wrap',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            {task.assigneeIds && task.assigneeIds.length ? (
              <>
                {task.assignees.map((assignee) => (
                  <TaskAssigneeDot
                    assignee={assignee}
                    key={assignee._id}
                    style={{ marginRight: 8, marginBottom: 4 }}
                  />
                ))}
              </>
            ) : (
              <Text>Click to assign</Text>
            )}
          </Space>
        )}
      </map>
      {editing && (
        <Form onFinish={onFinish} form={form} layout="vertical">
          <AssigneeFormItem loading={loading} form={form} />
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <style jsx>{`
        .tags {
          min-height: 36px;
        }
      `}</style>
    </>
  );
}

export default EditTaskAssignees;
