import React from 'react';
import { HolisticProjectRequestQuestions } from './questions';

function ViewHolisticProjectRequest({
  report,
  colors = {},
  isAdmin = false,
  adminSection,
}) {
  return (
    <div id="holisticProjectProposal" className="container">
      <HolisticProjectRequestQuestions.title.Display
        colors={colors}
        report={report}
        isAdmin={isAdmin}
      />
      <HolisticProjectRequestQuestions.submittedBy.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.approvedBy.Display
        colors={colors}
        report={report}
      />
      {adminSection && (
        <>
          <div style={{ height: 8 }} />
          {adminSection}
        </>
      )}
      <HolisticProjectRequestQuestions.typeOfProject.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.desc.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.myPeopleGroups.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.vision.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.goals.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.costAnalysis.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.supportedFieldWorkers.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.costAnalysisSummary.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.otherProfitUses.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.holisticPaymentPlan.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.marketAnalysis.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.riskAnalysis.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.locationRisk.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.responsiblePersons.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.publicFiles.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.privateFiles.Display
        colors={colors}
        report={report}
      />
      <HolisticProjectRequestQuestions.notes.Display
        colors={colors}
        report={report}
      />
      <style jsx>{`
        .container {
        }
        .line {
          margin-bottom: 16px;
        }
        .line-question {
          margin-bottom: 16px;
        }
        .line-sub {
          margin-botttom: 8px;
        }
        .line-answer {
          text-align: center;
          background-color: ${colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
}

export default ViewHolisticProjectRequest;
