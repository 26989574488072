import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useIsCreating from '../../../shared/use-is-creating';
import useProfile from '../../../shared/use-profile';
import ChangePasswordModal from '../ChangePasswordModal';
import DeleteUserModal from '../DeleteUserModal';
import EditProfileForm from './EditProfileForm';
import useUser from '../use-user';

function EditProfile() {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [changingPassword, setChangingPassword] = useState(null);
  const [deleting, setDeleting] = useState(null);

  const profile = useProfile();
  const queryId = useMemo(() => {
    return Math.round(Math.random() * 10000);
  }, []);
  const userResult = useUser(profile._id, queryId);
  const { user } = userResult;

  const handleBack = useCallback(() => history.goBack(), [history]);

  const { title, showDelete } = useIsCreating(
    location,
    user,
    loading,
    'Edit My Profile',
    'Edit My Profile',
  );

  return (
    <>
      <CSPage title="Edit Profile">
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Users
            </Button>,
          ]}
        />
        <EditProfileForm
          _id={profile._id}
          handleCancel={handleBack}
          handleSuccess={() => history.push('/home')}
          userResult={userResult}
          loading={loading}
          setLoading={setLoading}
          style={{ maxWidth: 500 }}
          queryId={queryId}
        />
        {showDelete && (
          <div style={{ maxWidth: 500 }}>
            <Divider />
            <div className="delete-box">
              <Space align="center">
                <Button
                  onClick={() => setDeleting(user)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete User
                </Button>
                <Button
                  onClick={() => setChangingPassword(user)}
                  icon={<LockOutlined />}
                >
                  Change Password
                </Button>
              </Space>
            </div>
          </div>
        )}
        <div style={{ minHeight: 300 }} />
        <DeleteUserModal setUser={setDeleting} user={deleting} />
        <ChangePasswordModal
          user={changingPassword}
          setUser={setChangingPassword}
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditProfile;
