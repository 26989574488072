import { EyeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateLoanAgreementAction } from '../../../redux-store/loan-agreements-store';
import {
  AgreementStatuses,
  sendLoanAgreementSignatureReminderMutation,
} from '../constants';

const { Title, Text, Paragraph } = Typography;

const SendLoanAgreementReminderBtn = ({
  loanAgreement,
  setError,
  error,
  queryId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [sendLoanAgreementSignatureReminder] = useMutation(
    sendLoanAgreementSignatureReminderMutation,
  );

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      await sendLoanAgreementSignatureReminder({
        variables: {
          loanAgreementId: loanAgreement._id,
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Reminder sent successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [setError, sendLoanAgreementSignatureReminder, loanAgreement, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<EyeOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Send Signature Reminder
      </Button>

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Send Signature Reminder
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              {`This will send a reminder to all parties who haven't yet signed the loan agreement.`}
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Send Reminder
              </Button>
            </div>
          </Form.Item>
        </Form>
        {error && (
          <Text type="danger" style={{ marginTop: 16 }}>
            {error}
          </Text>
        )}
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default SendLoanAgreementReminderBtn;
