import { useMutation } from '@apollo/client';
import { message, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import YesNoModal from '../../../../components/YesNoModal';
import { buildPayoutV2RowsMutation, PayoutStates } from '../constants';

const { Text, Paragraph } = Typography;

function ConfirmRefreshModalV2({ confirming, onCancel, onFinish, queryId }) {
  const { payout } = confirming || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [buildPayoutRows] = useMutation(buildPayoutV2RowsMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    message.info('Refreshing ...');
    try {
      if (
        [PayoutStates.NEW.key, PayoutStates.DRAFT.key].includes(payout.state)
      ) {
        await buildPayoutRows({
          variables: {
            payoutId: payout._id,
          },
        });
      }
      message.success('Done');
      onFinish();
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [buildPayoutRows, payout, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  const { text, title, yesText, noText } = useMemo(() => {
    if (
      payout &&
      [PayoutStates.NEW.key, PayoutStates.DRAFT.key].includes(payout.state)
    ) {
      return {
        title: 'Refresh Payout',
        text: (
          <>
            <Paragraph>
              This will re-build the payout rows from current sponsorship data,
              submitted reports and account balances.
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Refresh Payout',
        noText: 'Cancel',
      };
    }
    return {};
  }, [payout]);

  return (
    <YesNoModal
      title={title}
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>{text}</div>
        </div>
      }
      yesText={yesText}
      noText={noText}
      onYes={onYes}
      onNo={onNo}
      visible={!!confirming}
      loading={loading}
      error={error}
    />
  );
}

export default ConfirmRefreshModalV2;
