import { DeleteOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

function SelectedInstallmentRows({ installments, selectedKeys, onDelete }) {
  const onDeleteMany = () => {
    onDelete(
      selectedKeys.map((key) => installments.find((i) => i._id === key)),
    );
  };
  return (
    <>
      {selectedKeys && selectedKeys.length > 0 && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#c6cfd3',
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            alignItems: 'center',
            borderRadius: 8,
          }}
        >
          <Title level={5} style={{ margin: 0, marginRight: 16 }}>
            Selected
          </Title>
          <div style={{ marginRight: 16 }}>
            {selectedKeys.length === 1 ? (
              <Text strong>{`${selectedKeys.length} row`}</Text>
            ) : (
              <Text strong>{`${selectedKeys.length} rows`}</Text>
            )}
          </div>
          <div style={{ flex: 1 }} />
          <Button icon={<DeleteOutlined />} onClick={onDeleteMany}>
            Delete Installments
          </Button>
        </div>
      )}
    </>
  );
}

export default SelectedInstallmentRows;
