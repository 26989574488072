import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Input, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

const { Text } = Typography;

function PayoutNoteColumn({ record, savePayoutRow }) {
  const ref = useRef();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);

  const handleFinish = useCallback(
    async (values) => {
      setEditing(false);
      if (record.notes !== values.notes) {
        await savePayoutRow({ _id: record._id, notes: values.notes });
      }
    },
    [record, savePayoutRow],
  );

  const handleClick = useCallback(() => {
    setEditing(true);
    setTimeout(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, 100);
  }, []);

  return (
    <>
      {!editing ? (
        <map onClick={handleClick} style={{ width: '100%' }}>
          {record.notes && <Text>{record.notes}</Text>}
          <div>
            <Text style={{ color: sharedSettings.colors.textGray }}>
              {record.notes ? 'Click to edit ...' : 'Click to add a note ...'}
            </Text>
          </div>
        </map>
      ) : (
        <Form
          form={form}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              form.submit();
            }
          }}
          onFinish={handleFinish}
        >
          <Form.Item name="notes" noStyle initialValue={record.notes}>
            <Input ref={ref} onBlur={() => form.submit()} />
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default PayoutNoteColumn;
