import { HomeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createContactAction,
  updateContactAction,
} from '../../redux-store/contacts-store';
import { generateUuid } from '../../shared/utils';
import { addContactToHouseholdMutation } from '../households/constants';
import { ContactTypes, createContactMutation } from './constants';

function CreateHouseholdButton({ selected, handleSuccess }) {
  const [createContact] = useMutation(createContactMutation);
  const [addContactToHousehold] = useMutation(addContactToHouseholdMutation);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const createHoushold = useCallback(async () => {
    if (selected && selected.length) {
      try {
        const firstNames = [];
        selected.forEach((c) => {
          firstNames.push(c.firstName);
        });
        const lastName = selected[0].lastName;
        const primaryContactId = selected[0]._id;

        const householdId = generateUuid();
        const result = await createContact({
          variables: {
            contact: {
              _id: householdId,
              contactType: ContactTypes.HOUSEHOLD.key,
              firstName: firstNames.join(' & '),
              lastName,
              primaryContactId,
            },
          },
        });
        dispatch(createContactAction(result.data.createContact.contact));
        notification.success({
          message: 'Saved',
          description: 'Contact created successfully',
        });

        let updatedHousehold;
        const updatedContacts = [];
        for (let i = 0; i < selected.length; i += 1) {
          const member = selected[i];
          const response = await addContactToHousehold({
            variables: {
              member: {
                contactId: member._id,
                householdId,
              },
            },
          });
          const updatedContact =
            response.data &&
            response.data.addContactToHousehold &&
            response.data.addContactToHousehold.contact;
          updatedContacts.push(updatedContact);
          updatedHousehold =
            response.data &&
            response.data.addContactToHousehold &&
            response.data.addContactToHousehold.household;
          notification.success({
            message: 'Success',
            description: 'Contact added to household',
          });
        }

        updatedContacts.forEach((c) => {
          dispatch(updateContactAction(c));
        });
        if (updatedHousehold) {
          dispatch(updateContactAction(updatedHousehold));
        }

        if (handleSuccess) {
          handleSuccess(householdId);
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
        message.error('Error creating household');
      }
      setLoading(false);
    }
  }, [selected, createContact, handleSuccess, addContactToHousehold, dispatch]);
  return (
    <Button
      loading={loading}
      type="primary"
      style={{ borderRadius: 8 }}
      icon={<HomeOutlined />}
      onClick={createHoushold}
    >
      Create Household
    </Button>
  );
}

export default CreateHouseholdButton;
