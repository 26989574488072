import { Typography } from 'antd';
import React from 'react';
import CSPage from './CSPage';
import CSPageHeader from './CSPageHeader';

const { Paragraph } = Typography;

function Error404() {
  return (
    <CSPage title="AIMS.org | 404">
      <CSPageHeader title="404 Page Not Found" />
      <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
        <Paragraph style={{ textAlign: 'center' }}>
          This page is unreached. Keep praying.
        </Paragraph>
      </div>
    </CSPage>
  );
}

export default Error404;
