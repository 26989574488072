import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowRightOutlined,
  DeleteOutlined,
  ExportOutlined,
  LogoutOutlined,
  MoreOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Menu,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { PayoutStates } from '../constants';
import useQuarterLabel from '../use-quarter-label';
import ViewEditPayoutNameV2 from './ViewEditPayoutNameV2';
import ViewEditPayoutNotesV2 from './ViewEditPayoutNotesV2';

const { Text, Title } = Typography;

function PayoutV2Title({
  payout,
  handleDelete,
  handleStartPayout,
  handleRefreshPayout,
  handleCompletePayout,
  handleExportCsv,
  savePayout,
  loading,
}) {
  const quarterLabel = useQuarterLabel(payout);

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <Text
          style={{
            color: sharedSettings.colors.textGray,
            fontSize: 14,
          }}
        >
          Edit Payout
        </Text>
      </div>
      <ViewEditPayoutNameV2 payout={payout} savePayout={savePayout} />
      {quarterLabel && (
        <div style={{ marginBottom: 8 }}>
          <Text style={{ color: sharedSettings.colors.textGray, fontSize: 14 }}>
            {quarterLabel}
          </Text>
        </div>
      )}
      {!payout && loading && <Skeleton avatar active />}
      {payout && (
        <ViewEditPayoutNotesV2 payout={payout} savePayout={savePayout} />
      )}
      {payout && (
        <div style={{ position: 'absolute', top: 0, right: 16 }}>
          <Space>
            <div>
              <Tag
                style={{
                  fontSize: 16,
                  lineHeight: 1.8,
                  padding: '0px 12px',
                }}
                color={
                  PayoutStates[payout.state] && PayoutStates[payout.state].color
                }
              >
                {PayoutStates[payout.state] && PayoutStates[payout.state].label}
              </Tag>
            </div>
            {[PayoutStates.DRAFT.key, PayoutStates.NEW.key].includes(
              payout.state,
            ) && (
              <Tooltip title="Refresh payout ...">
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => handleRefreshPayout(payout)}
                >
                  Refresh
                </Button>
              </Tooltip>
            )}
            {payout.state === PayoutStates.DRAFT.key && (
              <Tooltip title="Start payout ...">
                <Button
                  icon={<ArrowRightOutlined />}
                  type="primary"
                  onClick={() => handleStartPayout(payout)}
                >
                  Start payout
                </Button>
              </Tooltip>
            )}
            {payout.state === PayoutStates.IN_PROGRESS.key && (
              <Tooltip title="Close payout ...">
                <Button
                  icon={<LogoutOutlined />}
                  type="primary"
                  onClick={() => handleCompletePayout(payout)}
                >
                  Close payout
                </Button>
              </Tooltip>
            )}
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    icon={<ExportOutlined />}
                    onClick={handleExportCsv}
                  >
                    Export CSV
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(payout)}
                  >
                    Delete Payout
                  </Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button size="large" icon={<MoreOutlined />} />
            </Dropdown>
          </Space>
        </div>
      )}
    </div>
  );
}

export default PayoutV2Title;
