import { getFileIcon } from '@aims/shared/shared/icons';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Empty,
  List,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import { titleCase } from '../../../../shared/utils';
import useCanEditTraining from '../use-can-edit-training';

const { Text, Title, Paragraph } = Typography;

const FileCard = ({ file, onEdit, onDelete, showCreate }) => {
  const [toggleDesc, setToggle] = useState(false);

  const icon = useMemo(() => {
    return getFileIcon(file?.file?.filename, {
      fontSize: 18,
    });
  }, [file]);

  const handleDownload = () => {
    if (file?.file) {
      const link = document.createElement('a');
      link.download = file.file.filename;
      link.href = file.file.url;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <List.Item>
      <Card className="fileCard">
        <div style={{ marginBottom: 4 }}>
          <Text style={{ color: sharedSettings.colors.textGray }}>
            {titleCase(file.language)}
          </Text>
        </div>
        <div className="fileInfo">
          <Space>
            {icon}
            <Title level={5}>{file.fileName}</Title>
          </Space>
        </div>
        {!file.file && (
          <div>
            <Text type="danger">File Missing</Text>
          </div>
        )}
        {!toggleDesc && file && file.desc && file.desc.length > 50 && (
          <Text>
            {ellipsis(file.desc, 50)}{' '}
            <span
              className="toggle"
              onClick={() => {
                setToggle(!toggleDesc);
              }}
            >
              Read more
            </span>
          </Text>
        )}
        {toggleDesc && file && file.desc && file.desc.length > 50 && (
          <Text>
            {file.desc}{' '}
            <span
              className="toggle"
              onClick={() => {
                setToggle(!toggleDesc);
              }}
            >
              Read Less
            </span>
          </Text>
        )}
        {file && file.desc && file.desc.length < 50 && <Text>{file.desc}</Text>}
        <div className="actions">
          <Tooltip title="Download">
            <Button
              onClick={handleDownload}
              icon={<DownloadOutlined />}
            ></Button>
          </Tooltip>
          {showCreate && (
            <Tooltip title="Edit">
              <Button
                onClick={() =>
                  onEdit({
                    trainingFile: file,
                  })
                }
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
          )}
          {showCreate && (
            <Tooltip title="Delete">
              <Button
                style={{ color: 'red' }}
                onClick={() => onDelete(file)}
                icon={<DeleteOutlined />}
              ></Button>
            </Tooltip>
          )}
        </div>
        <style jsx>{`
          .fileCard {
            display: flex;
          }
          .fileInfo {
            display: flex;
          }
          .actions {
            display: flex;
            // flex-direction: column;
            justify-content: space-around;
            padding-top: 16px;
          }
          .toggle {
            cursor: pointer;
            font-weight: 600;
          }
        `}</style>
      </Card>
    </List.Item>
  );
};

const ModuleItem = ({ module, files, loading, onEditFile, onDeleteFile }) => {
  const moduleFiles = useMemo(() => {
    return files.filter((f) => {
      if (f.moduleId === module._id) return f;
    });
  }, [files, module]);

  const { _id } = useParams();

  const showCreate = useCanEditTraining();

  return (
    <div>
      <Divider>
        <Title level={3}>Module: {module.name}</Title>
      </Divider>
      {module.desc && (
        <Paragraph style={{ marginBottom: 24 }}>{module.desc}</Paragraph>
      )}
      {moduleFiles && (
        <List
          dataSource={moduleFiles}
          renderItem={(item) => (
            <FileCard
              file={item}
              onEdit={onEditFile}
              onDelete={onDeleteFile}
              showCreate={showCreate}
            />
          )}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Files"
              />
            ),
          }}
          grid={{ column: 3, gutter: 16 }}
          // grid={{ column: 3, sm: 1, lg: 3, gutter: 16 }}
        />
      )}
      {showCreate && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            key="add"
            type="dashed"
            onClick={() =>
              onEditFile({
                fieldsToSet: { courseId: _id, moduleId: module._id },
              })
            }
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add File
          </Button>
        </div>
      )}
    </div>
  );
};

export default ModuleItem;
