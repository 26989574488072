import { gql } from '@apollo/client';

export const MouRoles = {
  TRAINER: {
    key: 'TRAINER',
    label: 'Trainer',
  },
  FIELD_WORKER: {
    key: 'FIELD_WORKER',
    label: 'Field Worker',
  },
  REGIONAL_COORDINATOR: {
    key: 'REGIONAL_COORDINATOR',
    label: 'Regional Coordinator',
  },
};

export const MouTemplateDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    name
    isTemplate
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    role
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const mouTemplateListAttributes = gql`
  {
    _id
    agreementType
    status
    name
    isTemplate
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    role
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createMouTemplateMutation = gql`
  mutation CreateMouTemplate(
    $mou: MouCreateInput!
  ) {
    createMou(mou: $mou) {
      mou ${MouTemplateDetailedAttributes}
    }
  }
`;

export const updateMouTemplateMutation = gql`
  mutation UpdateMouTemplate($mou: MouUpdateInput!) {
    updateMou(mou: $mou) {
      mou {
        _id
      }
    }
  }
`;

export const allMouTemplatesForAdminQuery = gql`
  query AllMouTemplatesForAdmin(
    $first: Int
    $after: String
    $filters: MouFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allMousForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${mouTemplateListAttributes}
      }
    }
  }
`;

export const extractMousForAdmin = (data) => data.allMousForAdmin;

export const mouTemplateQuery = gql`
  query MouTemplate($_id: ID!) {
    mouForAdmin(_id: $_id) ${MouTemplateDetailedAttributes}
  }
`;

export const extractMouForAdmin = (data) => data && data.mouForAdmin;

export const deleteMouMutation = gql`
  mutation DeleteMou($_id: ID!) {
    deleteMou(_id: $_id) {
      deletedId
    }
  }
`;

export const mouListAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    sections {
      _id
      title
    }
    parties {
      _id
      ref
      name
      date
      contactName
    }
    attachments {
      _id
      ref
      name
      fileId
    }
    role
    peopleGroupId
    peopleGroupName
    contactId
    contactName
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allMousForAdminQuery = gql`
  query AllMousForAdmin(
    $first: Int
    $after: String
    $filters: MouFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allMousForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${mouListAttributes}
      }
    }
  }
`;

const MouContactDetails = gql`
  {
    _id
    fullName
    birthday
    primaryEmail
    primaryPhoneNumber
    primaryAddress {
      _id
      label
      address1
      address2
      city
      state
      zipCode
      country
    }
    primaryThumbnailUrl
    regionCode
  }
`;

const MouPeopleGroupDetails = gql`
  {
    _id
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    jpUrl
    latlon
    primaryPhotoId
    primaryThumbnailUrl
  }
`;

export const MouDetailedAttributes = gql`
  {
    _id
    agreementType
    status
    version
    name
    isTemplate
    parties {
      _id
      ref
      name
      contactId
      contactName
      signature
      date
      fontName
    }
    signeeIds
    sections {
      _id
      title
      text
      initials {
        partyId
        initials
        fontName
        date
      }
    }
    attachments {
      _id
      ref
      name
      fileId
      file {
        _id
        size
        filename
        contentType
        url
        uploaded
        processed
      }
    }
    finalizedDate
    privateNotes
    role
    peopleGroupId
    peopleGroupName
    peopleGroup ${MouPeopleGroupDetails}
    contactId
    contactName
    contact ${MouContactDetails}
    frozenPeopleGroupDetails ${MouPeopleGroupDetails}
    frozenContactDetails ${MouContactDetails}
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const createMouFromTemplateMutation = gql`
  mutation CreateMouFromTemplate(
    $templateId: ID!, $contactId: ID!, $peopleGroupId: ID!, $newId: ID!, $versionNo: Int!,
  ) {
    createMouFromTemplate(
      templateId: $templateId,
      contactId: $contactId,
      peopleGroupId: $peopleGroupId,
      newId: $newId,
      versionNo: $versionNo
    ) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const mouForAdminQuery = gql`
  query MouForAdmin($_id: ID!) {
    mouForAdmin(_id: $_id) ${MouDetailedAttributes}
  }
`;

export const MouUpdateAttributes = gql`
{
  crud
  id
  new ${MouDetailedAttributes}
}
`;

export const mouUpdatesForAdminSubscription = gql`
  subscription MouUpdatesForAdmin($_id: ID!) {
    mouUpdatesForAdmin(_id: $_id) ${MouUpdateAttributes}
  }
`;

export const MouTemplateUpdateAttributes = gql`
{
  crud
  id
  new ${MouTemplateDetailedAttributes}
}
`;

export const mouTemplateUpdatesForAdminSubscription = gql`
  subscription MouUpdatesForAdmin($_id: ID!) {
    mouUpdatesForAdmin(_id: $_id) ${MouUpdateAttributes}
  }
`;

export const updateMouMutation = gql`
  mutation UpdateMou($mou: MouUpdateInput!) {
    updateMou(mou: $mou) {
      mou {
        _id
      }
    }
  }
`;

export const previewMouMutation = gql`
  mutation PreviewMou(
    $mouId: ID!
  ) {
    previewMou(mouId: $mouId) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const prepareMouMutation = gql`
  mutation PrepareMou(
    $mouId: ID!
  ) {
    prepareMou(mouId: $mouId) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const cancelMouMutation = gql`
  mutation CancelMou(
    $mouId: ID!
  ) {
    cancelMou(mouId: $mouId) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const markMouSignedMutation = gql`
  mutation MarkMouSigned(
    $mouId: ID!
  ) {
    markMouSigned(mouId: $mouId) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const sendMouSignatureReminderMutation = gql`
  mutation SendMouSignatureReminder($mouId: ID!) {
    sendMouSignatureReminder(mouId: $mouId)
  }
`;

export const sendMouMutation = gql`
  mutation SendMou(
    $mouId: ID!
  ) {
    sendMou(mouId: $mouId) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const signMouMutation = gql`
  mutation SignMou(
    $signature: MouSignInput!
  ) {
    signMou(signature: $signature) {
      mou ${MouDetailedAttributes}
    }
  }
`;

export const initialMouSectionMutation = gql`
  mutation InitialMouSection(
    $initials: MouInitialsInput!
  ) {
    initialMouSection(initials: $initials) {
      mou ${MouDetailedAttributes}
    }
  }
`;
