import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Button, Card, Empty, List, Skeleton, Typography } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import settings from '../../../settings';
import { allAdoptionsQuery, extractAdoptions } from '../constants';
import ListAdoptionsFiltersA10 from './ListAdoptionsFiltersA10';
import SelectAdoptionListCardA10 from './SelectAdoptionListCardA10';

const { Text } = Typography;

function SelectAdoptionListA10({ onAdoptionSelected, extraFilter }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: _adoptions,
    search: adoptionSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allAdoptionsQuery,
    extract: extractAdoptions,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'selectAdoptionListA10',
  });

  const adoptions = useMemo(
    () => _adoptions.filter(extraFilter),
    [_adoptions, extraFilter],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        adoptionSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [adoptionSearch, refetch, sortedBy, sortOrder],
  );

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ListAdoptionsFiltersA10 onFiltersChanged={onFiltersChanged} />
      </div>
      {loading && !adoptions.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            dataSource={adoptions.slice(0, pageSize)}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(record) => (
              <SelectAdoptionListCardA10
                record={record}
                onSelect={(selected) => {
                  if (onAdoptionSelected) {
                    onAdoptionSelected(selected);
                  }
                }}
              />
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No sponsorships"
                />
              ),
            }}
          />
          <div className="bottom-actions">
            {adoptions && adoptions.length <= pageSize && hasNextPage && (
              <Button onClick={next}>Load More</Button>
            )}
            {adoptions && adoptions.adoption > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SelectAdoptionListA10;
