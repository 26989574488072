import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../SelectContact';
import { ContactTypes } from '../../constants';
import ContactSomethingForm from './ContactSomethingForm';

const { Text, Paragraph, Title } = Typography;

export const addContactToHouseholdMutation = gql`
  mutation AddContactToHousehold($contactId: ID!, $householdId: ID!) {
    addContactToHousehold(contactId: $contactId, householdId: $householdId)
  }
`;

function AddHouseholdBtnAndModal({ contact }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [addContactToHousehold] = useMutation(addContactToHouseholdMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { householdId } = values;
        await addContactToHousehold({
          variables: {
            contactId: contact._id,
            householdId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Contact successfully added to household',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error adding contact to household',
        });
      }
      setSaving(false);
    },
    [contact, addContactToHousehold, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [editing, form]);

  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{ body: { paddingTop: 8 } }}
      >
        <ContactSomethingForm
          title="Add Contact to a Household"
          form={form}
          onFinish={handleFinish}
          onCancel={onCancel}
          saving={saving}
        >
          <Form.Item
            label="Household"
            name="householdId"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <SelectContact
              loading={saving}
              disabled={saving}
              filters={{
                contactType: ContactTypes.HOUSEHOLD.key,
              }}
              placeholder="Select a household ..."
            />
          </Form.Item>
        </ContactSomethingForm>
      </Modal>
      <Button
        onClick={handleEdit}
        icon={<PlusOutlined />}
        type="text"
        style={{ marginBottom: 16, marginLeft: -16 }}
      >
        Add Household
      </Button>
    </>
  );
}

export default AddHouseholdBtnAndModal;
