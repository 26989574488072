import { Space, Form, Input } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { generateUuid, toBase64 } from '../shared/utils';
import AttachmentCards from './AttachmentCards';

/**
 * @typedef {Object} Props
 * @property {boolean=} private
 * @property {unknown[]} existingAttachments
 * @property {string} parentId
 * @property {string} parentType
 * @property {string=} parentSubType
 * @property {string} name
 * @property {import('rc-field-form').FormInstance} form
 * @property {React.MutableRefObject<unknown>=} retrieveAttachments
 * @property {boolean=} readOnly
 * @property {boolean=} disabled
 */

/** @param {Props} props **/
function AttachmentsFormItem({
  existingAttachments,
  parentId,
  parentType,
  name,
  form,
  ...attachmentCardsProps
}) {
  const _fileList = useRef([]);
  const [fileList, _setFileList] = useState(_fileList.current);
  const setFileList = useCallback(
    (value) => {
      _fileList.current = value;
      _setFileList(value);
      form.setFieldsValue({
        [name]: _fileList.current.map((f) => f._id),
      });
    },
    [form, name],
  );
  useEffect(() => {
    setFileList(existingAttachments || []);
  }, [setFileList, existingAttachments]);

  /** @returns {Promise<[string, string]>} **/
  const doUploadInner = async (obj) => {
    const attachmentId = generateUuid();
    const s3Key = `${parentType}:${parentId}:attachment:${attachmentId}`;

    let base64Url;
    if (obj.file.type.startsWith('image')) {
      base64Url = await toBase64(obj.file);
    }

    const newFileList = [
      ..._fileList.current,
      {
        _id: attachmentId,
        s3Key,
        size: obj.file.size,
        filename: obj.file.name,
        contentType: obj.file.type,
        base64Url,
      },
    ];
    setFileList(newFileList);

    return [attachmentId, s3Key];
  };

  const onUploadDelete = useCallback(
    (file) => () => {
      const fileIndex = _fileList.current.findIndex((f) => f._id === file._id);
      if (fileIndex >= 0) {
        setFileList([
          ..._fileList.current.slice(0, fileIndex),
          ..._fileList.current.slice(fileIndex + 1),
        ]);
      }
    },
    [setFileList],
  );

  return (
    <Space direction="vertical" style={{ marginRight: 8 }}>
      <Form.List name={name}>
        {(fields) => (
          <>
            {fields.map((field) => {
              return (
                <Form.Item key={field.key} noStyle>
                  <Input type="hidden" />
                </Form.Item>
              );
            })}
          </>
        )}
      </Form.List>
      <AttachmentCards
        parentId={parentId}
        parentType={parentType}
        fileList={fileList}
        onUploadDelete={onUploadDelete}
        {...attachmentCardsProps}
        doUploadInner={doUploadInner}
      />
    </Space>
  );
}

export default AttachmentsFormItem;
