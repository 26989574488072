import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { displayMoney } from '@aims/shared/shared/utils';
import { EyeOutlined, GiftOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../../settings';
import useQueryParams from '../../../../shared/use-query-params';
import AssociateDonationsModalA10 from '../../a10-associate-donations/AssociateDonationsModalA10';
import ViewDonationModalA10 from '../../donations/ListDonations/ViewDonationModalA10';
import SelectFund from '../../funds/SelectFund';
import {
  PpRecurringPaymentStatuses,
  allPpRecurringPaymentsQuery,
  extractAllRecurringPayments,
} from '../constants';

const { Title, Text } = Typography;

function AdminListRecurringPayments({ contactId }) {
  const [form] = Form.useForm();
  const [numShown, setNumShown] = useState(settings.pageSize);

  const filters = useRef({ contactId });
  const sortBy = useRef([
    { key: 'ppCreatedOn', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: recurringPayments,
    search: recurringPaymentSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPpRecurringPaymentsQuery,
    extract: extractAllRecurringPayments,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'pushpayRecurringPayments',
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search !== undefined) {
        setNumShown(settings.pageSize);
        recurringPaymentSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [recurringPaymentSearch, refetch],
  );

  const query = useQueryParams();
  const once = useRef(false);
  useEffect(() => {
    const { _id } = query;
    if (_id && !once.current) {
      once.current = true;
      form.setFieldsValue({ search: _id });
      recurringPaymentSearch(_id);
    }
  }, [query, form, recurringPaymentSearch]);

  const handleLoadMore = useCallback(() => {
    setNumShown(numShown + settings.pageSize);
    if (numShown + settings.pageSize > recurringPayments.length) {
      next();
    }
  }, [next, numShown, recurringPayments]);

  const [assoc, setAssoc] = useState();
  const showAssocModal = useCallback((payment) => {
    setAssoc({ payment, isRecurring: true });
  }, []);
  const handleCancelAssoc = useCallback(() => {
    setAssoc(undefined);
  }, []);
  const handleAssocFinish = useCallback(() => {
    setAssoc(undefined);
    refetch();
  }, [refetch]);

  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  });

  const [view, setView] = useState();
  const showViewModal = useCallback((payment) => {
    setView({ payment });
  }, []);
  const handleCancelView = useCallback(() => {
    setView(undefined);
  }, []);

  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form layout="inline" form={form} onValuesChange={onFiltersChanged}>
          <Form.Item name="search">
            <Input
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ maxWidth: 500, minWidth: 300 }}
            />
          </Form.Item>
          <Form.Item name="fundId">
            <SelectFund
              style={{ maxWidth: 500, minWidth: 200 }}
              placeholder="Select a fund ..."
            />
          </Form.Item>
        </Form>
        <Button onClick={() => refetch()}>Refresh</Button>
      </div>
      <Table
        dataSource={recurringPayments.slice(0, numShown)}
        loading={!recurringPayments.length && loading}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {
              try {
                console.log({
                  record,
                  notes: record.notes && JSON.parse(record.notes),
                });
              } catch (err) {
                console.log({ record });
              }
            }, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      >
        <Column title="Pushpay ID" dataIndex="_id" key="_id" />
        <Column
          title="Payer"
          dataIndex="payer"
          render={(text, record) => {
            return record.payerName;
          }}
        />
        <Column
          title="Status"
          dataIndex="status"
          render={(text, record) => {
            return (
              record.status &&
              PpRecurringPaymentStatuses[record.status] &&
              PpRecurringPaymentStatuses[record.status].label
            );
          }}
        />
        <Column
          title="Start Date"
          dataIndex="startDate"
          render={(text, record) => {
            return (
              record.startDate && dateFormat.format(new Date(record.startDate))
            );
          }}
        />

        <Column
          title="Fund"
          dataIndex="fund"
          render={(text, record) => {
            return record.fundName;
          }}
        />
        <Column
          title="Allocations"
          dataIndex="adoption"
          render={(text, record) => {
            if (record.adoptions && record.adoptions.length > 0) {
              return (
                <Tag color="green">{`${
                  record.adoptions.length
                } UPG Sponsorship${
                  record.adoptions.length > 1 ? 's' : ''
                }`}</Tag>
              );
            }
            if (record.sponsorships && record.sponsorships.length > 0) {
              return (
                <Tag color="green">{`${
                  record.sponsorships.length
                } Holistic Project${
                  record.sponsorships.length > 1 ? 's' : ''
                }`}</Tag>
              );
            }
            return null;
          }}
        />
        <Column
          title="Amount"
          dataIndex="usdCentsAmount"
          render={(text, record) => {
            if (record.amount) {
              return displayMoney(record.amount / 10000);
            }
            return null;
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Button
                  onClick={() => showViewModal(record)}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
              {record.fundId === settings.sponsorANativeMissionaryFund && (
                <Tooltip title="Edit">
                  <Button
                    onClick={() => showAssocModal(record)}
                    icon={<GiftOutlined />}
                  />
                </Tooltip>
              )}
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {(numShown < recurringPayments.length || hasNextPage) && (
          <Button
            style={{ minWidth: 200 }}
            onClick={handleLoadMore}
            loading={loading}
          >
            Load More
          </Button>
        )}
      </div>
      <AssociateDonationsModalA10
        visible={assoc}
        onCancel={handleCancelAssoc}
        onDone={handleAssocFinish}
      />
      <ViewDonationModalA10
        visible={view}
        onCancel={handleCancelView}
        isRecurring
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
      `}</style>
    </>
  );
}

export default AdminListRecurringPayments;
