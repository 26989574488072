import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';

export const updateMiracleMutation = gql`
  mutation UpdateMiracleForAdmin($miracle: MiracleUpdateInput!) {
    updateMiracleForAdmin(miracle: $miracle) {
      miracle {
        _id
      }
    }
  }
`;

const UpdateMiracleModalJ15 = ({ visible, onFinish, onCancel }) => {
  const { miracle } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updateMiracle] = useMutation(updateMiracleMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await updateMiracle({
          variables: {
            miracle: {
              _id: miracle._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Testimony updated successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your testimony',
        });
      }
      setSaving(false);
    },
    [miracle, updateMiracle, onFinish],
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (miracle) {
      form.setFieldsValue(miracle);
    }
  }, [form, miracle]);

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={onCancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Testimony"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item
          name="desc"
          label="Testimony"
          rules={[
            {
              required: true,
              message: 'Please enter the testimony description',
            },
          ]}
        >
          <Input.TextArea rows={4} disabled={saving} />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default UpdateMiracleModalJ15;
