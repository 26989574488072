import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Form, Input, InputNumber, notification } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContentEditableInput, {
  serializeText,
} from '../../components/ContentEditableInput';
import DateFormItem from '@aims/shared/components/DateFormItem';
import SelectContact from '../contacts/SelectContact';
import { createEventMutation } from './constants';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';

function CreateEventForm({
  _id,
  handleCancel,
  handleSuccess,
  loading,
  setLoading,
  fieldsToSet = {},
}) {
  const [createEvent] = useMutation(createEventMutation);

  const [form] = Form.useForm();

  useEffect(() => {
    const startTime = new Date().toISOString();
    const contacts = (fieldsToSet.contactIds || []).map((c) => ({
      contactId: c,
    }));
    form.setFieldsValue({
      startTime,
      contacts,
    });
  });

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { contacts = [], startTime, duration, desc, ...values } = _values;
        const contactIds = contacts.map((c) => c.contactId);

        const endTime = new Date(
          new Date(startTime).getTime() + duration * 60 * 1000,
        ).toISOString();
        await createEvent({
          variables: {
            event: {
              _id,
              contactIds,
              startTime,
              endTime,
              desc,
              descText: desc && serializeText(JSON.parse(desc)),
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Event saved successfully',
        });
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your event',
        });
      }
      setLoading(false);
    },
    [_id, createEvent, setLoading, handleSuccess],
  );

  return (
    <TaskSomethingForm
      form={form}
      onFinish={onSubmit}
      onCancel={handleCancel}
      saving={loading}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: 'Please enter an event title' }]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item name="allDay" valuePropName="checked">
        <Checkbox>All day event</Checkbox>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.allDay !== curValues.allDay
        }
      >
        {({ getFieldValue }) => (
          <>
            <DateFormItem
              name="startTime"
              rules={[{ required: true, message: 'This field is required' }]}
              showTime={!getFieldValue('allDay')}
            />
            {!getFieldValue('allDay') && (
              <Form.Item
                label="Duration (min)"
                name="duration"
                rules={[{ required: true, message: 'Please enter a duration' }]}
                initialValue={60}
              >
                <InputNumber disabled={loading} min={0} step={15} />
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
      <Form.Item label="Location" name="location">
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item label="Description" name="desc">
        <ContentEditableInput disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Add contacts, households, or organizations"
        style={{ marginBottom: 0 }}
      >
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'contactId']}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a contact',
                        },
                      ]}
                      noStyle
                    >
                      <SelectContact disabled={loading} filters={{}} />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add contact, household, or organization
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default CreateEventForm;
