export const READ_CONTACTS = 'READ_CONTACTS';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

export const readContactsAction = (
  // eslint-disable-next-line no-shadow
  contacts,
  queryId = 'default',
) => ({
  type: READ_CONTACTS,
  payload: {
    contacts,
    queryId,
  },
});

export const createContactAction = (contact, queryId = 'default') => ({
  type: CREATE_CONTACT,
  payload: {
    contact,
    queryId,
  },
});

export const updateContactAction = (contact, queryId = 'default') => ({
  type: UPDATE_CONTACT,
  payload: {
    contact,
    queryId,
  },
});

export const deleteContactAction = (contactId, queryId = 'default') => ({
  type: DELETE_CONTACT,
  payload: {
    contactId,
    queryId,
  },
});

const initialContacts = { default: {} };

export function contacts(state = initialContacts, action) {
  switch (action.type) {
    case READ_CONTACTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.contacts.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_CONTACT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.contact._id]: action.payload.contact,
        },
      };
    case UPDATE_CONTACT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.contact._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.contact._id]: {
            ...current,
            ...action.payload.contact,
          },
        },
      };
    }
    case DELETE_CONTACT: {
      const { contactId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [contactId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
