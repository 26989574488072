import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { useMutation } from '@apollo/client';
import { Form, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { updateHolisticProjectMutation } from '../../../constants';

const { Paragraph, Title } = Typography;

function MoveProjectStageModal({ project, visible, onFinish, onCancel }) {
  const { stage } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (values) => {
      setError(undefined);
      setLoading(true);
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              stage: stage.key,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project stage updated.',
        });
        onFinish(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setLoading(false);
    },
    [updateHolisticProject, project, onFinish, stage],
  );

  return (
    <YesNoModalJ10
      title="Change Project Stage"
      yesText="Yes"
      noText="Cancel"
      onYes={handleFinish}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph
        style={{ marginBottom: 8 }}
      >{`Are you sure you want to move this project to the`}</Paragraph>
      <Paragraph
        style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
      >{`${stage?.label}`}</Paragraph>
      <Paragraph>{`stage?`}</Paragraph>
    </YesNoModalJ10>
  );
}

export default MoveProjectStageModal;
