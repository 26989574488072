import { gql } from '@apollo/client';

export const TaskFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      contactType
      fullName
      contactType
      primaryThumbnailUrl
      primaryEmail
      primaryPhoneNumber
    }
    peopleGroupId
    peopleGroup {
      _id
      region
      regionCode
      countryCode
      country
      nameAcrossCountries
      primaryThumbnailUrl
      updatedAt
    }
    holisticProjectId
    holisticProject {
      _id
      projectNo
      peopleGroupIds
      projectName
      typeOfProject
      stage
      primaryThumbnailUrl
    }
    eventId
    status
    importance
    title
    desc
    windowStart
    windowStop
    reminderId
    assigneeIds
    assignees {
      _id
      name
      primaryThumbnailUrl
    }
    tags {
      key
      color
    }
    googleId
    createdBy
    updatedBy
    createdAt
    updatedAt
    deletedAt
    deletedBy
  }
`;

const TaskUpdateAttributes = gql`
{
  crud
  id
  new ${TaskFullAttributes}
}
`;

export const taskUpdatesForAdminSubscription = gql`
  subscription TaskUpdatesForAdmin($filters: TaskUpdateFiltersForAdmin!) {
    taskUpdatesForAdmin(filters: $filters) ${TaskUpdateAttributes}
  }
`;

export const allTasksForAdminQuery = gql`
  query AllTasksForAdmin($first: Int, $after: String, $filters: TaskFiltersForAdmin, $sortBy: [SortBy]) {
    allTasksForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${TaskFullAttributes}
      }
    }
  }
`;

export const extractAllTasksForAdmin = (data) => data.allTasksForAdmin;

export const taskForAdminQuery = gql`
  query TaskForAdmin($_id: ID!) {
    taskForAdmin(_id: $_id) ${TaskFullAttributes}
  }
`;

export const extractTaskForAdmin = (data) => data.taskForAdmin;
