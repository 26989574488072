import useOneM1 from '@aims/shared/shared/use-one-m1';
import { gql } from '@apollo/client';
import { updateTaskAction } from '../../../redux-store/tasks-store';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { TaskFullAttributes } from '../constants';

export const taskLongQuery = gql`
  query TaskLong($_id: ID!) {
    taskForAdmin(_id: $_id) ${TaskFullAttributes}
  }
`;

const extractTask = (data) => data.task;

export default function useTask(
  _id,
  queryId = 'use-long-task',
  showError = true,
) {
  const fetchResult = useOneM1({
    variables: { _id },
    skip: !_id,
    query: taskLongQuery,
    extract: extractTask,
    fetchPolicy: 'network-only',
    updateAction: updateTaskAction,
    queryId,
    showError,
  });
  const task = useSingleSomethingA10('tasks', _id, queryId);
  const { loading, error } = fetchResult;

  return {
    task,
    loading,
    error,
  };
}
