import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../SelectContact';
import { ContactTypes } from '../../constants';
import ContactSomethingForm from './ContactSomethingForm';

const { Text, Paragraph, Title } = Typography;

export const addContactToOrganizationMutation = gql`
  mutation AddContactToOrganization(
    $contactId: ID!
    $orgId: ID!
    $role: String!
  ) {
    addContactToOrganization(
      contactId: $contactId
      orgId: $orgId
      role: $role
    ) {
      contactXOrg {
        _id
      }
    }
  }
`;

function AddContactToOrgModal({ org, adding, onDone, onCancel }) {
  const [saving, setSaving] = useState(false);
  const [addContactToOrganization] = useMutation(
    addContactToOrganizationMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { contactId, role } = values;
        await addContactToOrganization({
          variables: {
            contactId: contactId,
            orgId: org._id,
            role,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Contact successfully added to org',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error adding contact to org',
        });
      }
      setSaving(false);
    },
    [org, addContactToOrganization, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [adding, form]);

  return (
    <Modal
      open={adding}
      closable={false}
      destroyOnClose={true}
      maskClosable
      footer={null}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8 } }}
    >
      <ContactSomethingForm
        title="Add to Organization"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item
          label="Contact"
          name="contactId"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <SelectContact
            loading={saving}
            disabled={saving}
            filters={{
              contactType: ContactTypes.CONTACT.key,
            }}
            placeholder="Select a contact ..."
          />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
          extra="e.g., CEO, Founder, Sales ..."
        >
          <Input disabled={saving} />
        </Form.Item>
      </ContactSomethingForm>
    </Modal>
  );
}

export default AddContactToOrgModal;
