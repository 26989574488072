import { gql, useMutation } from '@apollo/client';
import { Tag, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';

const { Paragraph } = Typography;

const deleteSimpleListItemQuery = gql`
  mutation DeleteTagForAdmin($name: String!, $_id: ID!) {
    deleteSimpleListItemForAdmin(name: $name, _id: $_id)
  }
`;

function DeleteTagModal({ visible, onCancel, onFinish }) {
  const { tag } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteTag] = useMutation(deleteSimpleListItemQuery);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteTag({
        variables: {
          name: 'tags',
          _id: tag._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Tag successfully deleted.',
      });
      onFinish();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'There was an error deleting your tag.',
      });
    }
    setLoading(false);
  }, [deleteTag, tag, onFinish]);

  return (
    <YesNoModal
      title="Delete Tag"
      contents={
        <div>
          <Paragraph>{'Are you sure you want to delete this tag?'}</Paragraph>
          <div>
            <Tag color={tag?.color}>{tag?.key}</Tag>
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={!!visible}
      loading={loading}
    />
  );
}

export default DeleteTagModal;
