import { gql } from '@apollo/client';

export const userFullAttributes = gql`
  {
    _id
    username
    roleIds
    roles {
      _id
      name
      label
    }
    roleLevel
    email
    emailVerified
    name
    phone
    primaryThumbnailUrl
    canSignInAs
    contactId
    contact {
      _id
      fullName
    }
    lastSignedInAt
    regionCode
    regionName
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const editUserForAdminQuery = gql`
  query EditUserForAdmin($_id: ID!) {
    userForAdmin(_id: $_id) ${userFullAttributes}
  }
`;

export const userListAttributes = gql`
  {
    _id
    username
    roleIds
    roles {
      _id
      name
      label
    }
    roleLevel
    email
    name
    phone
    primaryThumbnailUrl
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    lastSignedInAt
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allUsersForAdminQuery = gql`
  query AllUsersForAdmin($first: Int, $after: String, $filters: UserFiltersForAdmin, $sortBy: [SortBy]) {
    allUsersForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${userListAttributes}
      }
    }
  }
`;

export const extractUsersForAdmin = (data) => data && data.allUsersForAdmin;

export const userForAdminQuery = gql`
  query UserForAdmin($_id: ID!) {
    userForAdmin(_id: $_id) ${userFullAttributes}
  }
`;

export const extractUserForAdmin = (data) => data && data.userForAdmin;

export const createUserMutation = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      user ${userFullAttributes}
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($user: UserUpdateInput!) {
    updateUser(user: $user) {
      user {
        _id
      }
    }
  }
`;

export const editProfileMutation = gql`
  mutation EditProfileForAdmin($profile: EditProfileInputForAdmin!) {
    editMyProfileForAdmin(profile: $profile)
  }
`;

export const assignUserToContactMutation = gql`
  mutation AssignUserToContact($userId: ID!, $contactId: ID!) {
    assignUserToContact(userId: $userId, contactId: $contactId)
  }
`;

export const unassignUserFromContactMutation = gql`
  mutation UnassignUserFromContact($userId: ID!, $contactId: ID!) {
    unassignUserFromContact(userId: $userId, contactId: $contactId)
  }
`;

export const userProfileAttributes = gql`
  {
    _id
    username
    permissions
    email
    name
    emailVerified
    roleLevel
    regionCode
    phone
    primaryThumbnailUrl
    contactId
    subscribedTo
    createdAt
    updatedAt
  }
`;
