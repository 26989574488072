import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, notification } from 'antd';
import { deleteUpgPrayerAdoptionAction } from '../../redux-store/upg-prayer-adoptions-store';

export const deleteUpgPrayerAdoptionMutation = gql`
  mutation DeleteUpgPrayerAdoption($_id: ID!) {
    deleteUpgPrayerAdoption(_id: $_id) {
      deletedId
    }
  }
`;

const { Paragraph } = Typography;

function DeleteUpgPrayerAdoptionModal({ visible, onFinish, onCancel }) {
  const { upgPrayerAdoption, queryId } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteUpgPrayerAdoption] = useMutation(
    deleteUpgPrayerAdoptionMutation,
  );
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteUpgPrayerAdoption({
        variables: {
          _id: upgPrayerAdoption._id,
        },
      });
      dispatch(deleteUpgPrayerAdoptionAction(upgPrayerAdoption._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'UPG Prayer Adoption deleted successfully',
      });
      setTimeout(() => {
        onFinish();
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [upgPrayerAdoption, deleteUpgPrayerAdoption, dispatch, onFinish, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete UPG Prayer Adoption"
      contents={
        <>
          <Paragraph>{`Are you sure you want to delete this UPG prayer adoption?`}</Paragraph>
          <Paragraph style={{ fontWeight: 600 }}>
            {`${upgPrayerAdoption?.contact?.fullName}`}
            <br />
            {`${upgPrayerAdoption?.peopleGroup?.nameAcrossCountries} - ${upgPrayerAdoption?.peopleGroup?.country} (${upgPrayerAdoption?.peopleGroupId})`}
          </Paragraph>
        </>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!visible}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteUpgPrayerAdoptionModal;
