import {
  formatPhoneNumberForStorage,
  generateUuid,
} from '@aims/shared/shared/utils';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactAddPhoneInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.resetFields();
    }
  }, [contact, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingPhoneNumbers = (contact.phoneNumbers || []).reduce(
        (prev, curr) => {
          const { phone, _id, label } = curr;
          prev.push({ phone, _id, label });
          return prev;
        },
        [],
      );
      const { phone, label, makePrimary } = values;
      const existingPrimaryPhoneId = contact.primaryPhoneNumberId;
      const newId = generateUuid();
      await handleFinish({
        phoneNumbers: [
          ...existingPhoneNumbers,
          {
            _id: newId,
            phone: formatPhoneNumberForStorage(phone),
            label,
          },
        ].sort((a, b) => a._id.localeCompare(b._id)),
        primaryPhoneNumberId: makePrimary ? newId : existingPrimaryPhoneId,
      });
    },
    [handleFinish, contact],
  );
  return (
    <ContactSomethingForm
      title="Add Phone Number"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="phone"
        label="Phone Number"
        extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
        rules={[
          { required: true, message: 'Please enter a phone number' },
          {
            pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            message: 'Please enter a valid phone number (ex. 5124026225).',
          },
        ]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
        extra="e.g., Work, Home ..."
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactAddPhoneInnerModalA10;
