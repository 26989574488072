import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import {
  reviewTrainingEventReportMutation,
  updateTrainingEventReportForAdminMutation,
} from '../constants';
import SelectContact from '../../../contacts/SelectContact';

const { Title, Paragraph } = Typography;

const ApproveTrainEventBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewTrainingEventReport] = useMutation(
    reviewTrainingEventReportMutation,
  );
  const [updateReport] = useMutation(updateTrainingEventReportForAdminMutation);

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await reviewTrainingEventReport({
          variables: {
            reportId: report._id,
            status: 'APPROVED',
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Report Approved',
        });
        const { contactId } = values;
        {
          await updateReport({
            variables: {
              report: {
                _id: report._id,
                contactId: contactId,
                reportType: report.reportType,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Report updated successfully',
          });
        }
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [reviewTrainingEventReport, report, onSuccess, updateReport],
  );

  return (
    <>
      <Button
        loading={loading}
        icon={<CheckOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Approve Report
      </Button>

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Approve Report
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item
            style={{ marginTop: 32 }}
            label="Please select the contact that submitted this report"
            name="contactId"
            rules={[{ required: true, message: 'Please select a contact' }]}
            initialValue={report && report.contactId}
          >
            <SelectContact disabled={loading} filters={{ isFWOrRC: true }} />
          </Form.Item>
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              {`Are you sure you want to approve this training event report?  The submitter will be informed that his report has been approved.`}
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Approve Report
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveTrainEventBtn;
