import { Tooltip } from 'antd';
import React from 'react';

export function getPhotoUrl(photo, sizeCode = 'SM') {
  return photo?.find((i) => i.sizeCode === sizeCode)?.url;
}

function PhotoCircle({
  photoUrl,
  name,
  styles = { container: {}, placeholder: {}, img: {} },
}) {
  return (
    <Tooltip title={name}>
      <div
        className="container"
        style={{ position: 'relative', ...styles.container }}
      >
        <div
          className="photo-placeholder"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            ...styles.placeholder,
          }}
        >
          {name && name[0].toUpperCase()}
        </div>
        {photoUrl && (
          <img
            className="profile-photo"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              borderRadius: '50%',
              overflow: 'hidden',
              ...styles.img,
            }}
            src={photoUrl}
          />
        )}
      </div>
    </Tooltip>
  );
}

export default PhotoCircle;
