import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Divider, Skeleton, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import triangleLogo from '../../assets/white-square-logo.png';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import {
  createMouAction,
  deleteMouAction,
  updateMouAction,
} from '../../redux-store/mous-store';
import useQueryParams from '../../shared/use-query-params';
import { SignatureFontsStyle } from '../loan-agreements/signature-fonts';
import ViewMou from './ViewMou';
import {
  extractMouForAdmin,
  mouForAdminQuery,
  mouUpdatesForAdminSubscription,
} from './constants';

const { Title, Paragraph, Text } = Typography;

const queryId = 'viewMouPage';

function ViewMouPage() {
  const { _id } = useParams();
  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return '/mous';
    }
  }, [query]);

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: mouUpdatesForAdminSubscription,
    createAction: createMouAction,
    updateAction: updateMouAction,
    deleteAction: deleteMouAction,
    extractSomething: (resp) => resp?.data?.mouUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, error } = useOneM1({
    variables: { _id },
    query: mouForAdminQuery,
    extract: extractMouForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateMouAction,
    queryId,
  });
  const mou = useSingleSomethingA10('mous', _id, queryId);

  return (
    <>
      <CSPage title="Partnership Commitment Form">
        <CSPageHeader
          title=""
          titleComponent={() => null}
          backActions={[
            <Link key="back" to={backLink}>
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>,
          ]}
        />
        {!mou && loading && (
          <div
            id="mou"
            style={{
              backgroundColor: 'white',
              padding: '32px 16px',
              boxShadow: `0px 0px 4px ${sharedSettings.colors.primary}`,
              borderRadius: 2,
            }}
          >
            <div style={{ display: 'flex', marginBottom: 32 }}>
              <div
                style={{
                  background: sharedSettings.colors.primary,
                  width: 180,
                  marginRight: 32,
                  marginLeft: -16,
                  marginTop: -32,
                  padding: 16,
                }}
              >
                <img
                  style={{ width: '100%' }}
                  src={triangleLogo}
                  alt="Triangle Logo"
                />
              </div>
              <Title>Partnership Agreement Form</Title>
            </div>
            <Skeleton avatar paragraph active />
            <Divider />
            <Skeleton paragraph active />
          </div>
        )}
        {mou && <ViewMou mou={mou} backTo={backLink} queryId={queryId} />}
      </CSPage>
      <SignatureFontsStyle />
    </>
  );
}

export default ViewMouPage;
