import { generateUuid } from '@aims/shared/shared/utils';
import { Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useMemo, useState } from 'react';
import useTask from './EditTask/use-task';
import EditTaskForm from './EditTask/EditTaskForm';

const { Text } = Typography;

const CreateTaskModal = ({ visible, setVisible, fieldsToSet = {} }) => {
  const [loading, setLoading] = useState(false);

  const taskId = useMemo(() => {
    if (visible === '__new__') {
      return generateUuid();
    }
    return visible;
  }, [visible]);

  const queryId = useMemo(() => {
    if (visible) {
      return Math.round(Math.random() * 10000);
    }
  }, [visible]);

  const taskResult = useTask(taskId, queryId, false);

  const { title, okText } = useMemo(() => {
    if (visible === '__new__') {
      return {
        title: 'Create Task',
        okText: 'Save',
      };
    }
    if (taskResult.loading) {
      return {
        title: 'Edit Task',
        okText: 'Save',
      };
    }
    return {
      title:
        taskResult.task && taskResult.task.title
          ? taskResult.task.title
          : 'Edit Task',
      okText: 'Save',
    };
  }, [visible, taskResult]);

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <div style={{ textAlign: 'left' }}>
          {visible && (
            <EditTaskForm
              _id={taskId}
              handleCancel={() => setVisible(false)}
              handleSuccess={() => setVisible(false)}
              taskResult={taskResult}
              loading={taskResult.loading || loading}
              setLoading={setLoading}
              fieldsToSet={fieldsToSet}
              queryId={queryId}
            />
          )}
        </div>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreateTaskModal;
