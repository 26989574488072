import {
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Space, Table, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import { notNullOrUndefined } from '../../shared/utils';
import AddTagModal from './AddTagModal';
import DeleteTagModal from './DeleteTagModal';
import useTags from './use-tags';
import EditTagModal from './EditTagModal';

function ManageTagsPage() {
  const { tags, loading, refetch, search } = useTags();
  const [deleting, setDeleting] = useState();
  const onDelete = (tag) => setDeleting({ tag });
  const onFinishDelete = () => {
    refetch();
    setDeleting(undefined);
  };
  const onCancelDelete = () => setDeleting(undefined);

  const [editing, setEditing] = useState();
  const onEdit = (tag) => setEditing({ tag });
  const onFinishEdit = () => {
    refetch();
    setEditing(undefined);
  };
  const onCancelEdit = () => setEditing(undefined);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Tag color={record.color} key={record.key}>
          {record.key}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '200px',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              onClick={() => onEdit(record)}
              icon={<EditOutlined />}
              type="text"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              onClick={() => onDelete(record)}
              icon={<DeleteOutlined />}
              type="text"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();
  const onFiltersChanged = (changed) => {
    if (notNullOrUndefined(changed.search)) {
      search(changed.search);
    }
  };
  return (
    <CSPage title="Manage Tags" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="Manage Tags"
        topActions={[<AddTagModal key="new" onFinish={() => refetch()} />]}
      />
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300, marginRight: 'auto' }}
          onValuesChange={onFiltersChanged}
        >
          <Form.Item name="search" style={{ marginBottom: 0 }}>
            <Input
              size="large"
              placeholder="Search"
              disabled={loading}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        </Form>
        <Button icon={<RedoOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        size="middle"
        columns={columns}
        dataSource={tags}
        pagination={{ defaultPageSize: settings.pageSize, size: 'default' }}
      />
      <DeleteTagModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
      <EditTagModal
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
    </CSPage>
  );
}

export default ManageTagsPage;
