import useOneM1 from '@aims/shared/shared/use-one-m1';
import { accountQuery, extractAccount } from './constants';
import { updateAccountAction } from '../../../redux-store/accounts-store';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';

function useAccount(_id, queryId) {
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: accountQuery,
    extract: extractAccount,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateAccountAction,
    skip: !_id,
    showError: true,
  });
  const account = useSingleSomethingA10('accounts', _id, queryId);
  return {
    loading,
    account,
    refetch,
  };
}

export default useAccount;
