import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import { displayMoney } from '@aims/shared/shared/utils';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import { getQuarterLabel } from '@aims/shared/reports/quarters';

const { Text } = Typography;

function TrainingQuarterlyReportsTable({ reports, loading }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((pages, _, sorters) => {
    setPagination({
      ...pages,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <Table
      dataSource={reports}
      loading={!reports.length && loading}
      pagination={{ ...pagination, total: reports.length }}
      onChange={handleTableChange}
      // style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 1200 }}
    >
      <Table.Column
        title="Quarter"
        key="quarter"
        render={(text, record) => {
          return getQuarterLabel(record.quarter);
        }}
      />
      <Table.Column
        title="Training Events"
        key="events"
        render={(text, record) => {
          return record.events?.length || 0;
        }}
      />
      <Table.Column
        title="Testimonies"
        key="miracles"
        render={(text, record) => {
          return record.miracles?.length || 0;
        }}
      />
      <Table.Column
        title="Prayer Requests"
        key="prayerRequests"
        render={(text, record) => {
          return record.prayerRequests?.length || 0;
        }}
      />
      <Table.Column
        title="Trainer"
        key="contactId"
        render={(text, record) => {
          return (
            record.contact && (
              <Link to={`contact/view/${record.contact._id}`}>
                {record.contact.fullName}
              </Link>
            )
          );
        }}
      />
      <Table.Column
        title="Status"
        key="status"
        width={140}
        render={(text, record) => {
          const status = ReportApprovalStatuses[record.status];
          return (
            status && (
              <Text
                style={{
                  color: status.color,
                  fontWeight: 600,
                }}
              >
                {status.label}
              </Text>
            )
          );
        }}
      />
      <Table.Column
        title="Last Update"
        key="lastUpdate"
        width={200}
        render={(text, record) => {
          if (
            record.status === ReportApprovalStatuses.APPROVED.key &&
            record.approval
          ) {
            return (
              <>
                {`Approved on ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(record.approval.when))}`}
                {record.approval.approver && (
                  <>
                    <br />
                    {`by ${record.approval.approver.name}`}
                  </>
                )}
              </>
            );
          }
          if (record.status === ReportApprovalStatuses.DRAFT.key) {
            return (
              <>
                {`Updated on ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(record.updatedAt))}`}
                {record.updatedByUser && (
                  <>
                    <br />
                    {`by ${record.updatedByUser.name}`}
                  </>
                )}
              </>
            );
          }
          if (
            record.status === ReportApprovalStatuses.REJECTED.key &&
            record.approval
          ) {
            return (
              <>
                {`Rejected on ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(record.approval.when))}`}
                {record.approval.approver && (
                  <>
                    <br />
                    {`by ${record.approval.approver.name}`}
                  </>
                )}
              </>
            );
          }
          if (record.status === ReportApprovalStatuses.PENDING.key) {
            return (
              <>
                {`Submitted on ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(record.submittedAt))}`}
                {record.submittedByUser && (
                  <>
                    <br />
                    {`by ${record.submittedByUser.name}`}
                  </>
                )}
              </>
            );
          }
          if (
            record.status === ReportApprovalStatuses.HOLD.key &&
            record.approval
          ) {
            return (
              <>
                {`Put on hold ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'medium',
                }).format(new Date(record.approval.when))}`}
                {record.createdByUser && (
                  <>
                    <br />
                    {`by ${record.createdByUser.name}`}
                  </>
                )}
              </>
            );
          }
          return null;
        }}
      />
      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="View">
              <Link
                to={`/training-quarterly-reports/review/${record._id}?from=${location.pathname}`}
              >
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            <Tooltip title="Edit">
              <Link
                to={`/training-quarterly-reports/edit/${record._id}?from=${location.pathname}`}
              >
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        )}
      />
    </Table>
  );
}

export default TrainingQuarterlyReportsTable;
