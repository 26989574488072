import { Typography } from 'antd';
import React, { useState } from 'react';
import ellipsis from 'text-ellipsis';

const { Text } = Typography;

function MiracleDescLine({ desc }) {
  const [toggleDesc, setToggle] = useState(false);
  return (
    <>
      {!toggleDesc && desc.length > 200 && (
        <Text>
          {ellipsis(desc, 200)}{' '}
          <Text
            style={{ cursor: 'pointer', fontWeight: 600 }}
            onClick={() => {
              setToggle(!toggleDesc);
            }}
          >
            Read more
          </Text>
        </Text>
      )}
      {toggleDesc && desc.length > 200 && (
        <Text>
          {desc}{' '}
          <Text
            className="toggle"
            onClick={() => {
              setToggle(!toggleDesc);
            }}
          >
            Read Less
          </Text>
        </Text>
      )}
      {desc.length < 200 && <Text>{desc}</Text>}
    </>
  );
}

export default MiracleDescLine;
