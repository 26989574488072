import React from 'react';
import {
  CommentOutlined,
  FormOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  RocketOutlined,
  SkypeOutlined,
  TeamOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import { ActivityAttributes } from '../constants';

export const createActivityMutation = gql`
  mutation createActivity($activity: ActivityCreateInput!) {
    createActivity(activity: $activity) {
      activity ${ActivityAttributes}
    }
  }
`;

export const updateActivityMutation = gql`
  mutation updateActivity($activity: ActivityUpdateInput!) {
    updateActivity(activity: $activity) {
      activity ${ActivityAttributes}
    }
  }
`;

export const ActivityTypes = [
  { label: 'Note', value: 'NOTE' },
  { label: 'Phone Call', value: 'PHONE_CALL' },
  { label: 'Email', value: 'EMAIL' },
  { label: 'Text Message', value: 'TEXT' },
  { label: 'Zoom Call', value: 'ZOOM_CALL' },
  { label: 'In-Person Meeting', value: 'IN_PERSON_MEETING' },
  { label: 'Physical Mail', value: 'REAL_MAIL' },
  { label: 'Anything Else', value: 'OTHER' },
];

export const ActivityTypeIcons = {
  NOTE: {
    label: 'Note',
    icon: <FormOutlined />,
  },
  PHONE_CALL: {
    label: 'Phone Call',
    icon: <PhoneOutlined />,
  },
  EMAIL: {
    label: 'Email',
    icon: <MailOutlined />,
  },
  TEXT: {
    label: 'Text Message',
    icon: <CommentOutlined />,
  },
  ZOOM_CALL: {
    label: 'Zoom Call',
    icon: <VideoCameraOutlined />,
  },
  IN_PERSON_MEETING: {
    label: 'In-Person Meeting',
    icon: <TeamOutlined />,
  },
  REAL_MAIL: {
    label: 'Physical Mail',
    icon: <HomeOutlined />,
  },
  OTHER: {
    label: 'Anything Else',
    icon: <RocketOutlined />,
  },
};

export const deleteActivityMutation = gql`
  mutation deleteActivity($_id: ID!) {
    deleteActivity(_id: $_id) {
      deletedId
    }
  }
`;
