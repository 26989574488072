import { Select } from 'antd';
import React from 'react';
import { AdoptionStatuses } from '../constants';

function SelectAdoptionStatus({
  value,
  onChange,
  filter = () => true,
  ...props
}) {
  return (
    <Select value={value} onChange={onChange} {...props}>
      {Object.values(AdoptionStatuses)
        .filter(filter)
        .map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectAdoptionStatus;
