import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import { getLoanSummary } from '@aims/shared/holistic-projects/utils';
import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Skeleton, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import DeleteThingDropdown from '../../../components/DeleteThingDropdown';
import PhotoCircle from '../../../components/PhotoCircle';
import DeleteHolisticProjectModal from '../DeleteHolisticProjectModal';

const { Title, Text, Link: TextLink } = Typography;

function HolisticProjectTitle({ project }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const loanStatus = project && getLoanSummary(locale, project);

  const detailStyle = { marginTop: 4, marginBottom: 4 };

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const onDelete = () => setDeleting({ holisticProject: project });
  const onCancelDelete = () => setDeleting(undefined);
  const onFinishDelete = () => {
    setDeleting(undefined);
    history.push('/holistic-projects');
  };

  return (
    <div style={{ paddingTop: 16 }}>
      {project ? (
        <div className="container">
          <PhotoCircle
            photoUrl={project.primaryThumbnailUrl}
            name={project.projectName}
            customSize={160}
            style={{
              height: 160,
              width: 160,
              padding: 'unset',
              marginRight: 24,
            }}
          />
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              {HolisticProjectTypes[project.typeOfProject].label}
            </Title>
            <Title
              level={1}
              style={{
                marginTop: 0,
                marginBottom: 8,
              }}
            >
              {project.projectName}
            </Title>
            {project.projectNo && (
              <div>
                <Text style={{ fontWeight: 600 }}>
                  {`Project #${project.projectNo}`}
                </Text>
              </div>
            )}
            {project.region && (
              <div style={detailStyle}>
                <Text>{project.region}</Text>
              </div>
            )}
            {project.peopleGroups && (
              <div style={detailStyle}>
                {project.peopleGroups.map((pg) => (
                  <Link
                    key={pg._id}
                    style={{ marginRight: 8 }}
                    to={`/people-group/view/${pg._id}/info`}
                  >
                    {getPgDisplayName(pg)}
                  </Link>
                ))}
              </div>
            )}
            <div style={detailStyle}>
              <Text>{loanStatus}</Text>
            </div>
            {project.stage && (
              <div style={{ marginTop: 6, marginBottom: 6 }}>
                <Tag
                  color={sharedSettings.colors.primary}
                  style={{
                    fontSize: 14,
                  }}
                >
                  {HolisticProjectStages[project.stage]?.label}
                </Tag>
              </div>
            )}
          </div>
          <div className="right">
            <DeleteThingDropdown
              thing={project}
              label="Delete Holistic Project"
              handleDelete={onDelete}
            />
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <DeleteHolisticProjectModal
        visible={deleting}
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
      />
      <style jsx>{`
        .container {
          display: flex;
        }
        .right {
          margin-left: auto;
          margin-right: -18px;
        }
      `}</style>
    </div>
  );
}

export default HolisticProjectTitle;
