import Fuse from 'fuse.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from './autocomplete';

function useManySomethingsM20({
  filterFunc,
  table,
  sortByFunc,
  searchKeys,
  queryId = 'default',
}) {
  const term$ = useRef();
  const [search, setSearch] = useState('__init__');
  const data = useSelector((store) => {
    let _data = Object.values(store[table]?.[queryId] || []).filter((b) => b);
    if (filterFunc) {
      _data = _data.filter(filterFunc);
    }
    if (search && search !== '__init__') {
      const fuse = new Fuse(_data, {
        includeScore: true,
        keys: searchKeys,
      });
      _data = fuse
        .search(search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return _data;
    }
    if (sortByFunc) {
      return _data.sort(sortByFunc);
    }
    return _data;
  }, shallowEqual);

  useEffect(() => {
    term$.current = new BehaviorSubject('__init__');
    term$.current
      .pipe(
        autocomplete(100, (term) => {
          if (term !== '__init__') {
            return of(setSearch(term));
          }
          return of();
        }),
      )
      .subscribe();
  }, []);

  const _search = useCallback((term) => {
    if (term !== undefined) {
      term$.current?.next(term);
    }
  }, []);

  return {
    data,
    search: _search,
  };
}

export default useManySomethingsM20;
