import sharedSettings from '@aims/shared/sharedSettings';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function TaskAssigneeDot({ assignee, style }) {
  return (
    <div
      style={{
        height: 40,
        width: 40,
        borderRadius: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        backgroundColor: sharedSettings.colors.contactColor,
        ...style,
      }}
    >
      {assignee?.primaryThumbnailUrl && (
        <Tooltip title={assignee.name}>
          <img
            src={assignee.primaryThumbnailUrl}
            style={{ height: 40, width: 40, objectPosition: '50% 50%' }}
          />
        </Tooltip>
      )}
      {assignee && !assignee.primaryThumbnailUrl && (
        <Tooltip title={assignee.name}>
          <Text style={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
            {assignee.name[0].toUpperCase()}
          </Text>
        </Tooltip>
      )}
      {!assignee && (
        <Text style={{ color: 'white', fontSize: 16, fontWeight: 300 }}>
          <LoadingOutlined />
        </Text>
      )}
    </div>
  );
}

export default TaskAssigneeDot;
