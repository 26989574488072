import { ReportTypes } from '@aims/shared/reports/constants';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, Space, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MiracleDescLine from './MiracleDescLine';
import MiracleHolisticProjectLine from './MiracleHolisticProjectLine';
import MiraclePeopleGroupLine from './MiraclePeopleGroupLine';
import usePrayerReportLink from '../prayer-requests/use-pr-report-link';

const { Text, Title } = Typography;

function DeleteDropdown({ miracle, handleDelete }) {
  const items = [
    {
      key: 'delete',
      label: 'Delete Miracle',
      icon: <DeleteOutlined />,
    },
  ];
  function onClick({ key }) {
    switch (key) {
      case 'delete':
        handleDelete(miracle);
        break;
      default:
        break;
    }
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger="click">
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
}

function MiracleCardJ15({ miracle, onEditClick, onDeleteClick }) {
  const locale = useSelector((store) => store.locale, shallowEqual);

  const link = usePrayerReportLink(miracle);

  return (
    <Card bordered={false}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 16,
        }}
      >
        <div>
          <div style={{ marginBottom: 4 }}>
            <Text
              style={{ color: sharedSettings.colors.textGray }}
            >{`Created ${new Intl.DateTimeFormat(locale, {
              dateStyle: 'medium',
            }).format(new Date(miracle.createdAt))}`}</Text>
          </div>
          <div style={{ marginBottom: 4 }}>
            {miracle.peopleGroup && (
              <MiraclePeopleGroupLine peopleGroup={miracle.peopleGroup} />
            )}
            {miracle.holisticProject && (
              <MiracleHolisticProjectLine
                holisticProject={miracle.holisticProject}
              />
            )}
          </div>
        </div>
        <div className="actions">
          <Space>
            <Tooltip title="Edit Request">
              <Button
                onClick={() => onEditClick(miracle)}
                icon={<EditOutlined />}
              />
            </Tooltip>
            {link && (
              <Tooltip title="View Report">
                <Link to={link}>
                  <Button icon={<BarChartOutlined />} />
                </Link>
              </Tooltip>
            )}
            <DeleteDropdown miracle={miracle} handleDelete={onDeleteClick} />
          </Space>
        </div>
      </div>
      <MiracleDescLine desc={miracle.desc} />
    </Card>
  );
}

export default MiracleCardJ15;
