import { gql } from '@apollo/client';

export const ProjectSponsorshipViewAttributes = gql`
  {
    _id
    contactId
    sponsorName
    contact {
      _id
      fullName
      contactType
      primaryThumbnailUrl
      primaryEmailId
      primaryPhoneNumberId
      phoneNumbers {
        _id
        label
        phone
      }
      emails {
        _id
        label
        email
      }
    }
    projectName
    projectId
    project {
      _id
      primaryThumbnailUrl
      regionalCoordinator {
        _id
        fullName
      }
      projectNo
      region
      peopleGroupIds
      projectName
      typeOfProject
      stage
    }
    amount
    recurring
    tags {
      key
      color
    }
    notes
    startDate
    isOnline
    accountId
    accountBalance
    recurringPaymentId
    paymentId
    paymentHistory {
      desc
      paymentId
      recurringPaymentId
      createdAt
    }
    history {
      desc
      createdAt
    }
    lastPaymentDate
    createdAt
    updatedAt
    deletedAt
  }
`;

const ProjectSponsorshipUpdateAttributes = gql`
{
  crud
  id
  new ${ProjectSponsorshipViewAttributes}
}
`;

export const projectSponsorshipUpdatesSubscription = gql`
  subscription ProjectSponsorshipUpdatesForAdmin($_id: ID!) {
    projectSponsorshipUpdatesForAdmin(_id: $_id) ${ProjectSponsorshipUpdateAttributes}
  }
`;

export const createProjectSponsorshipMutation = gql`
  mutation CreateProjectSponsorship($projectSponsorship: ProjectSponsorshipCreateInput!) {
    createProjectSponsorship(projectSponsorship: $projectSponsorship) {
      projectSponsorship ${ProjectSponsorshipViewAttributes}
    }
  }
`;

export const updateProjectSponsorshipMutation = gql`
  mutation UpdateProjectSponsorship($projectSponsorship: ProjectSponsorshipUpdateInput!) {
    updateProjectSponsorship(projectSponsorship: $projectSponsorship) {
      projectSponsorship ${ProjectSponsorshipViewAttributes}
    }
  }
`;

export const deleteProjectSponsorshipMutation = gql`
  mutation DeleteProjectSponsorship($_id: ID!) {
    deleteProjectSponsorship(_id: $_id) {
      deletedId
    }
  }
`;

export const allProjectSponsorshipsQuery = gql`
  query AllProjectSponsorships(
    $first: Int
    $after: String
    $filters: ProjectSponsorshipFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allProjectSponsorshipsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          sponsorName
          projectId
          projectName
          regionCode
          amount
          recurring
          tags {
            key
          }
          startDate
          isOnline
          accountId
          accountBalance
          lastPaymentDate
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const extractProjectSponsorships = (data) =>
  data && data.allProjectSponsorshipsForAdmin;
