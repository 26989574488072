import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { useRef, useState, useMemo } from 'react';
import settings from '../../settings';
import {
  historyV2UpdatesForAdminSubscription,
  allHistoryV2ForAdminQuery,
  extractAllHistoryV2ForAdmin,
} from './constants';
import {
  createHistoryV2Action,
  deleteHistoryV2Action,
  readHistoryV2Action,
  updateHistoryV2Action,
} from '../../redux-store/history-v2-store';

function useHistoryV2({
  filters,
  skip,
  sortByFunc,
  filterFunc,
  queryId = 'default',
}) {
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters },
    subscription: historyV2UpdatesForAdminSubscription,
    createAction: createHistoryV2Action,
    updateAction: updateHistoryV2Action,
    deleteAction: deleteHistoryV2Action,
    extractSomething: (resp) => resp?.data?.historyV2UpdatesForAdmin,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allHistoryV2ForAdminQuery,
    extract: extractAllHistoryV2ForAdmin,
    readAction: readHistoryV2Action,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, [sortByFunc]);
  const { data, search } = useManySomethingsM20({
    table: 'historyV2',
    sortByFunc: defaultSort,
    searchKeys: ['descText', '_id'],
    filterFunc,
    queryId,
  });
  return {
    loading,
    search,
    data,
  };
}

export default useHistoryV2;
