import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import YesNoModal from '../../components/YesNoModal';

export const deleteUpgQuarterlyReportForAdminMutation = gql`
  mutation DeleteUpgQuarterlyReportForAdmin($reportId: ID!) {
    deleteUpgQuarterlyReportForAdmin(reportId: $reportId) {
      deletedId
    }
  }
`;

function DeleteQuarterlyUpgReportModal({ report, setReport }) {
  const [loading, setLoading] = useState(false);
  const [deleteReport] = useMutation(deleteUpgQuarterlyReportForAdminMutation);
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteReport({
        variables: {
          reportId: report._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Report deleted successfully',
      });
      setTimeout(() => {
        setReport(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'There was an error deleting your report',
      });
      setLoading(false);
    }
  }, [report, deleteReport, setReport, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setReport(null);
    }
  }, [setReport, loading]);

  return (
    <YesNoModal
      title="Delete Report"
      question={`Are you sure you want to delete this quarterly UPG report?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!report}
      loading={loading}
    />
  );
}

export default DeleteQuarterlyUpgReportModal;
