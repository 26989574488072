import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';

function AimsStatusFilter({ value, onChange, defaultEnabled = false, style }) {
  const [checkVal, setCheckVal] = useState(defaultEnabled);
  const [switchVal, setSwitchVal] = useState(value);
  const onCheckChanged = useCallback(
    (val) => {
      setCheckVal(val.target.checked);
      onChange(val.target.checked ? switchVal : null);
    },
    [onChange, switchVal],
  );
  const onSwitchChanged = useCallback(
    (val) => {
      setSwitchVal(val);
      onChange(checkVal ? val : null);
    },
    [onChange, checkVal],
  );

  return (
    <div style={{ display: 'flex', width: 200, ...style }}>
      <Checkbox checked={checkVal} onChange={onCheckChanged}>
        Enabled
      </Checkbox>
      <Switch
        disabled={!checkVal}
        checked={switchVal}
        onChange={onSwitchChanged}
      />
    </div>
  );
}

export default AimsStatusFilter;
