import {
  DashboardOutlined,
  LoginOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Dropdown, message } from 'antd';
import copy from 'copy-to-clipboard';
import React, { useCallback, useState } from 'react';

export const getSignIntoAdminAsLinkMutation = gql`
  mutation GetSignIntoAdminAsLink($userId: ID!) {
    getSignIntoAdminAsLink(userId: $userId)
  }
`;

export const getSignIntoUserAsLinkMutation = gql`
  mutation GetSignIntoUserAsLink($userId: ID!) {
    getSignIntoUserAsLink(userId: $userId)
  }
`;

function SignInAsMenu({ userId, moreItems, moreCases, btnProps }) {
  const [copying, setCopying] = useState(false);
  const [getSignIntoAdminAsLink] = useMutation(getSignIntoAdminAsLinkMutation);
  const [getSignIntoUserAsLink] = useMutation(getSignIntoUserAsLinkMutation);
  const handleSignIntoAdminAs = useCallback(async () => {
    setCopying(true);
    try {
      const result = await getSignIntoAdminAsLink({
        variables: {
          userId,
        },
      });
      if (result?.data?.getSignIntoAdminAsLink) {
        copy(result.data.getSignIntoAdminAsLink);
        message.success('Link Copied!');
      } else {
        throw new Error('Failed!');
      }
    } catch (err) {
      console.error(err);
      message.error(err.message);
    }
    setCopying(false);
  }, [getSignIntoAdminAsLink, userId]);

  const handleSignIntoUserAs = useCallback(async () => {
    setCopying(true);
    try {
      const result = await getSignIntoUserAsLink({
        variables: {
          userId,
        },
      });
      if (result?.data?.getSignIntoUserAsLink) {
        copy(result.data.getSignIntoUserAsLink);
        message.success('Link Copied!');
      } else {
        throw new Error('Failed!');
      }
    } catch (err) {
      console.error(err);
      message.error(err.message);
    }
    setCopying(false);
  }, [getSignIntoUserAsLink, userId]);

  const items = [
    ...(moreItems || []),
    {
      key: 'admin',
      label: 'Copy Sign into Admin UI Link',
      icon: <DashboardOutlined />,
    },
    {
      key: 'user',
      label: 'Copy Sign into User UI Link',
      icon: <ShopOutlined />,
    },
  ];
  function onClick({ key }) {
    switch (key) {
      case 'admin':
        handleSignIntoAdminAs();
        break;
      case 'user':
        handleSignIntoUserAs();
        break;
      default:
        moreCases?.(key);
        break;
    }
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger="click">
      <Button icon={<LoginOutlined />} size="large" type="text" {...btnProps} />
    </Dropdown>
  );
}

export default SignInAsMenu;
