import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { updateHolisticProjectAction } from '../../../redux-store/holistic-projects-store';
import useIsCreating from '../../../shared/use-is-creating';
import useQueryParams from '../../../shared/use-query-params';
import {
  extractHolisticProjectForAdmin,
  holisticProjectForAdminQuery,
} from '../constants';
import EditHolisticProjectForm from './EditHolisticProjectForm';

const queryId = 'editHolisticProject';

function EditHolisticProject() {
  const history = useHistory();
  const location = useLocation();
  const [saving, setSaving] = useState(false);

  const { _id } = useParams();

  const { loading: loading } = useOneM1({
    variables: { _id },
    query: holisticProjectForAdminQuery,
    extract: extractHolisticProjectForAdmin,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateHolisticProjectAction,
    skip: !_id,
    showError: false,
  });
  const holisticProject = useSingleSomethingA10(
    'holisticProjects',
    _id,
    queryId,
  );

  const query = useQueryParams();
  const handleBack = useCallback(() => {
    if (query && query.from) {
      history.push(query.from);
    } else {
      history.push('/holistic-projects');
    }
  }, [query, history]);

  const { title, showDelete } = useIsCreating(
    location,
    holisticProject,
    loading,
    'Create a Holistic Project',
    'Edit Holistic Project',
  );

  return (
    <>
      <CSPage title="Edit Holistic Project">
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Holistic Projects
            </Button>,
          ]}
        />
        <EditHolisticProjectForm
          _id={_id}
          handleCancel={handleBack}
          handleSuccess={() => {
            if (query && query.from) {
              history.push(query.from);
            } else {
              history.push(`/holistic-project/view/${_id}/details`);
            }
          }}
          holisticProject={holisticProject}
          saving={saving}
          setSaving={setSaving}
          formStyle={{ width: 500 }}
        />
      </CSPage>
    </>
  );
}

export default EditHolisticProject;
