import { FormOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import useProfile from '../../shared/use-profile';
import { generateUuid } from '../../shared/utils';
import UpgQuarterlyReportTabs from './UpgQuarterlyReportTabs';

const { Title } = Typography;

function UpgQuarterlyReportsPage() {
  const createId = useMemo(() => {
    return generateUuid();
  }, []);

  const profile = useProfile();
  const permissions = profile?.permissions;
  const canCreateReport = useMemo(() => {
    return permissions?.find(
      (p) => p.prefix === 'create-admin-upg-quarterly-report',
    );
  }, [permissions]);

  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="UPG Quarterly Reports"
      >
        <CSPageHeader
          titleComponent={
            <Title>
              <FormOutlined style={{ marginRight: 16 }} />
              {'UPG Quartery Reports'}
            </Title>
          }
          topActions={
            canCreateReport && [
              <Link key="add" to={`/upg-quarterly-reports/edit/${createId}`}>
                <Button type="link" icon={<PlusCircleOutlined />} size="large">
                  Create a new report
                </Button>
              </Link>,
            ]
          }
          style={{ marginBottom: 16 }}
        />
        <UpgQuarterlyReportTabs />
        <div style={{ minHeight: 300 }} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default UpgQuarterlyReportsPage;
