import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import EmailFormItem from './EmailFormItem';

/**
 * @typedef {Object} Email
 * @property {string} _id
 * @property {string} label
 * @property {string} email
 */

function EmailsFormItem({ form, contact, loading, onSubmit }) {
  const [primary, setPrimary] = useState(0);

  useEffect(() => {
    if (contact) {
      if (contact.primaryEmailId) {
        const emailIndex = (contact.emails || []).findIndex(
          (e) => e._id === contact.primaryEmailId,
        );
        setPrimary(emailIndex);
      }
    }
  }, [contact, form]);

  onSubmit.current = useCallback(
    /**
     * @param {Email[]} emails
     * @returns {{ primaryEmailId: string|null, emailsToSave: Email[] }} }
     **/
    (emails) => {
      let primaryEmailId = null;
      let emailsToSave = [];
      if (emails && emails.length) {
        let primaryIndex = primary;
        if (primaryIndex >= emails.length) {
          primaryIndex = 0;
        }
        primaryEmailId = emails[primaryIndex]._id;
        emailsToSave = emails.map((e) => ({
          _id: e._id,
          label: e.label,
          email: e.email,
        }));
      }
      return {
        primaryEmailId,
        emailsToSave,
      };
    },
    [primary],
  );
  return (
    <Form.Item>
      <Form.List name="emails">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <EmailFormItem
                    field={field}
                    loading={loading}
                    primary={primary}
                    setPrimary={setPrimary}
                  />
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      if (primary === index) {
                        setPrimary(0);
                      }
                      remove(field.name);
                    }}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              </Form.Item>
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add email
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
}

export default EmailsFormItem;
