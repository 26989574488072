import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Space, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateLoanAgreementAction } from '../../../redux-store/loan-agreements-store';
import { AgreementStatuses, prepareLoanAgreementMutation } from '../constants';

const { Title, Text, Paragraph } = Typography;

const PrintLoanAgreementBtn = () => {
  return (
    <>
      <Button icon={<PrinterOutlined />} onClick={() => window.print()}>
        Print Agreement
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PrintLoanAgreementBtn;
