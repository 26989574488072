import { gql } from '@apollo/client';

export const EventAttributes = gql`
  {
    _id
    contactIds
    invitedContactIds
    userIds
    title
    desc
    descText
    startTime
    endTime
    allDay
    location
    googleId {
      googleCalId
      googleUrl
    }
    reminderId
    isSubscribed
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const createEventMutation = gql`
  mutation createEvent($event: EventCreateInput!) {
    createEvent(event: $event) {
      event ${EventAttributes}
    }
  }
`;

export const updateEventMutation = gql`
  mutation updateEvent($event: EventUpdateInput!) {
    updateEvent(event: $event) {
      event ${EventAttributes}
    }
  }
`;

export const EventUpdateAttributes = gql`
{
  crud
  id
  new ${EventAttributes}
}
`;

export const eventUpdatesForAdminSubscription = gql`
  subscription EventUpdatesForAdmin($filters: EventUpdateFiltersForAdmin!) {
    eventUpdatesForAdmin(filters: $filters) ${EventUpdateAttributes}
  }
`;

export const allEventsForAdminQuery = gql`
  query AllEventsForAdmin($first: Int, $after: String, $filters: EventFiltersForAdmin, $sortBy: [SortBy]) {
    allEventsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${EventAttributes}
      }
    }
  }
`;

export const extractAllEventsForAdmin = (data) => data.allEventsForAdmin;

export const eventForAdminQuery = gql`
  query EventForAdmin($_id: ID!) {
    eventForAdmin(_id: $_id) ${EventAttributes}
  }
`;

export const extractEventForAdmin = (data) => data.eventForAdmin;
