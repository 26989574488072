import { PlusCircleOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import SelectTagColor from './SelectTagColor';
import { generateUuid } from '@aims/shared/shared/utils';
import TagForm from './TagForm';

export const upsertSimpleListItemForAdminMutation = gql`
  mutation AddTagForAdmin($name: String!, $item: SimpleListItemInput!) {
    upsertSimpleListItemForAdmin(name: $name, item: $item)
  }
`;

const { Title, Text } = Typography;

function AddTagModal({ onFinish }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [addTag] = useMutation(upsertSimpleListItemForAdminMutation);

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { key, color } = _values;
        await addTag({
          variables: {
            name: 'tags',
            item: {
              _id: generateUuid(),
              key: key,
              content: JSON.stringify({ color }),
            },
          },
        });
        onFinish();
        notification.success({
          message: 'Success',
          description: 'Tag saved successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your tag',
        });
      }
      setSaving(false);
    },
    [addTag, onDone, onFinish],
  );
  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
      >
        <Title level={3} style={{ marginTop: 8 }}>
          Create Tag
        </Title>
        <TagForm
          form={form}
          handleFinish={handleFinish}
          saving={saving}
          onCancel={onCancel}
        />
      </Modal>
      <Button
        type="link"
        size="large"
        icon={<PlusCircleOutlined />}
        onClick={handleEdit}
      >
        Add a Tag
      </Button>
    </>
  );
}

export default AddTagModal;
