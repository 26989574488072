import { Typography } from 'antd';
import React from 'react';
import { PgQuarterlyReportQuestions } from './questions';

const { Title } = Typography;

function ViewPgQuarterlyReport({ report, isAdmin = false, adminSection }) {
  return (
    <div id="upgQuarterlyReport" className="container">
      <PgQuarterlyReportQuestions.title.Display
        report={report}
        isAdmin={isAdmin}
      />
      <PgQuarterlyReportQuestions.submittedBy.Display report={report} />
      <PgQuarterlyReportQuestions.approvedBy.Display report={report} />
      <PgQuarterlyReportQuestions.peopleGroup.Display report={report} />
      {adminSection && (
        <>
          <div style={{ height: 8 }} />
          {adminSection}
        </>
      )}
      <Title level={2}>Finances</Title>
      <PgQuarterlyReportQuestions.receivedFinancialSupport.Display
        report={report}
      />
      <PgQuarterlyReportQuestions.receivedForHimself.Display report={report} />
      <PgQuarterlyReportQuestions.receivedForHolistic.Display report={report} />
      <PgQuarterlyReportQuestions.useOfHolisticFundsDesc.Display
        report={report}
      />
      <PgQuarterlyReportQuestions.receivedTotal.Display report={report} />
      <Title level={2} style={{ marginTop: 32 }}>
        Results
      </Title>
      <PgQuarterlyReportQuestions.churchesPlanted.Display report={report} />
      <PgQuarterlyReportQuestions.resultNumbers.Display report={report} />
      <PgQuarterlyReportQuestions.miracles.Display report={report} />
      <PgQuarterlyReportQuestions.prayerRequests.Display report={report} />
      <PgQuarterlyReportQuestions.publicFiles.Display report={report} />
      <PgQuarterlyReportQuestions.privateFiles.Display report={report} />
      <PgQuarterlyReportQuestions.notes.Display report={report} />
    </div>
  );
}

export default ViewPgQuarterlyReport;
