import React from 'react';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

function MiracleHolisticProjectLine({ holisticProject }) {
  return (
    <div>
      <Link to={`holistic-project/view/${holisticProject._id}/details`}>
        <div>
          <Text style={{ fontWeight: 600 }}>Holistic Project: </Text>
          <Text>
            {`${holisticProject.projectName} - ${
              HolisticProjectTypes[holisticProject.typeOfProject]?.label
            }${
              holisticProject.peopleGroupIds?.length > 0
                ? ` (${holisticProject.peopleGroupIds?.join(', ')})`
                : ''
            }`}
          </Text>
        </div>
      </Link>
    </div>
  );
}

export default MiracleHolisticProjectLine;
