export const READ_ADOPTION_X_CONTACTS = 'READ_ADOPTION_X_CONTACTS';
export const CREATE_ADOPTION_X_CONTACT = 'CREATE_ADOPTION_X_CONTACT';
export const UPDATE_ADOPTION_X_CONTACT = 'UPDATE_ADOPTION_X_CONTACT';
export const DELETE_ADOPTION_X_CONTACT = 'DELETE_ADOPTION_X_CONTACT';

export const readAdoptionXContactsAction = (
  // eslint-disable-next-line no-shadow
  adoptionXContacts,
  queryId = 'default',
) => ({
  type: READ_ADOPTION_X_CONTACTS,
  payload: {
    adoptionXContacts,
    queryId,
  },
});

export const createAdoptionXContactAction = (
  adoptionXContact,
  queryId = 'default',
) => ({
  type: CREATE_ADOPTION_X_CONTACT,
  payload: {
    adoptionXContact,
    queryId,
  },
});

export const updateAdoptionXContactAction = (
  adoptionXContact,
  queryId = 'default',
) => ({
  type: UPDATE_ADOPTION_X_CONTACT,
  payload: {
    adoptionXContact,
    queryId,
  },
});

export const deleteAdoptionXContactAction = (
  adoptionXContactId,
  queryId = 'default',
) => ({
  type: DELETE_ADOPTION_X_CONTACT,
  payload: {
    adoptionXContactId,
    queryId,
  },
});

const initialAdoptionXContacts = { default: {} };

export function adoptionXContacts(state = initialAdoptionXContacts, action) {
  switch (action.type) {
    case READ_ADOPTION_X_CONTACTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.adoptionXContacts.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_ADOPTION_X_CONTACT:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.adoptionXContact._id]:
            action.payload.adoptionXContact,
        },
      };
    case UPDATE_ADOPTION_X_CONTACT: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.adoptionXContact._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.adoptionXContact._id]: {
            ...current,
            ...action.payload.adoptionXContact,
          },
        },
      };
    }
    case DELETE_ADOPTION_X_CONTACT: {
      const { adoptionXContactId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [adoptionXContactId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
