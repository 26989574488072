import { gql, useMutation } from '@apollo/client';

export const updateGoogleEventMutation = gql`
  mutation UpdateGoogleEvent($eventId: ID!) {
    updateGoogleEvent(eventId: $eventId) {
      event {
        _id
        contactIds
        invitedContactIds
        userIds
        title
        desc
        descText
        startTime
        endTime
        allDay
        location
        googleId {
          googleCalId
          googleUrl
        }
        reminderId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

function useUpdateGoogleEvent() {
  const [updateGoogleEvent] = useMutation(updateGoogleEventMutation);
  return updateGoogleEvent;
}

export default useUpdateGoogleEvent;
