import { Empty, List } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import EventCard from './EventCard';
import ViewEventModal from './ViewEventModal';

function EventsList({ gridColumns, events, loading, refetch, queryId }) {
  const [showViewEventModal, setShowViewEventModal] = useState(null);
  const params = useParams();
  useEffect(() => {
    const { eventId } = params;
    if (eventId) {
      setShowViewEventModal(eventId);
    }
  }, [params]);

  const closeViewEventModal = useCallback(() => {
    setShowViewEventModal(null);
  }, []);
  return (
    <>
      <List
        dataSource={events}
        renderItem={(item) => (
          <EventCard event={item} setShowEventModal={setShowViewEventModal} />
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Events"
            />
          ),
        }}
        grid={gridColumns || { column: 4, gutter: 16 }}
      />
      <ViewEventModal
        visible={showViewEventModal}
        closeModal={closeViewEventModal}
        refetch={refetch}
        queryId={queryId}
      />
    </>
  );
}

export default EventsList;
