import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authStateChangedAction } from '../../redux-store/auth-store';
import { updateEventAction } from '../../redux-store/events-store';

export const subscribeToMutation = gql`
  mutation SubscribeToEvent($eventId: ID!) {
    subscribeToEvent(eventId: $eventId)
  }
`;

function useSubscribeToEvent() {
  const [subscribeTo] = useMutation(subscribeToMutation);
  const dispatch = useDispatch();
  const doSubscribe = useCallback(
    async (eventId, queryId) => {
      const response = await subscribeTo({
        variables: {
          eventId,
        },
      });
      const newProfile = response.data.subscribeToEvent.user;
      const event = response.data.subscribeToEvent.event;
      dispatch(authStateChangedAction(newProfile));
      dispatch(
        updateEventAction({
          queryId,
          ...event,
        }),
      );
      notification.success({
        message: 'Success',
        description: 'Successfully Subscribed',
      });
    },
    [subscribeTo, dispatch],
  );

  return doSubscribe;
}

export default useSubscribeToEvent;
