import React from 'react';
import HolisticProjectPhotos from './HolisticProjectPhotos';
import { FileTypes } from '@aims/shared/files/constants';
import { Typography } from 'antd';

const { Title } = Typography;

function HpPhotosTab({ project }) {
  return (
    <>
      <Title level={3} style={{ marginBottom: 0 }}>
        Photos
      </Title>
      <HolisticProjectPhotos
        project={project}
        fileType={FileTypes.H_PHOTO.key}
        aspect={1}
      />
      <div style={{ height: 32 }} />
      <Title level={3} style={{ marginBottom: 0 }}>
        Banner Images
      </Title>
      <HolisticProjectPhotos
        project={project}
        fileType={FileTypes.H_BANNER.key}
        aspect={7.0 / 2.0}
        singular="Banner Image"
        plural="Banner Images"
        primaryName="primaryBannerId"
      />
      <div style={{ minHeight: 300 }} />
    </>
  );
}

export default HpPhotosTab;
