import { Button, Space, Spin, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const { Title, Text } = Typography;

const YesNoModal = ({
  title,
  question,
  contents = null,
  yesText,
  yesIcon,
  noText,
  onYes,
  onNo,
  visible,
  loading = false,
  error = null,
  closable = true,
  destroyOnClose = true,
  maskClosable = true,
  textAlign = 'center',
}) => {
  return (
    <>
      <Modal
        title={title}
        open={visible}
        closable={closable}
        destroyOnClose={destroyOnClose}
        maskClosable={maskClosable}
        footer={null}
      >
        <Spin spinning={loading}>
          <Space
            direction="vertical"
            align={{ left: 'start', center: 'center', right: 'end' }[textAlign]}
            style={{ textAlign, width: '100%' }}
          >
            {question && <Text>{question}</Text>}
            {contents}
            {error && (
              <div
                className="server-error ant-form-item-has-error"
                style={{ marginTop: 16 }}
              >
                <Text type="danger">{error}</Text>
              </div>
            )}
          </Space>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 24,
            }}
          >
            {noText && (
              <Button
                onClick={onNo}
                disabled={loading}
                style={{ marginRight: 16 }}
              >
                {noText}
              </Button>
            )}
            {yesText && (
              <Button
                type="primary"
                loading={loading}
                onClick={onYes}
                icon={yesIcon}
              >
                {yesText}
              </Button>
            )}
          </div>
        </Spin>
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default YesNoModal;
