import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { HolisticChecklistItems } from './ViewHolisticProject/tabs/HolisticStatusTab/use-project-checklist-items';
import sharedSettings from '@aims/shared/sharedSettings';

const { Text } = Typography;

function getChecklist(record) {
  const details = (record.auditDetails || []).filter(
    (a) => a.stage === record.stage,
  );

  return (
    <>
      {details.map((a) => {
        const label = HolisticChecklistItems[a.name]?.label;
        const dot = a.isComplete ? (
          <CheckCircleFilled />
        ) : (
          <CloseCircleFilled />
        );
        const color = a.isComplete
          ? sharedSettings.colors.green
          : sharedSettings.colors.red;
        return (
          <div key={a.name}>
            <Text style={{ color, marginRight: 8 }}>{dot}</Text>
            <Text>{label}</Text>
          </div>
        );
      })}
    </>
  );
}

export default getChecklist;
