export const READ_TASKS = 'READ_TASKS';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';

export const readTasksAction = (
  // eslint-disable-next-line no-shadow
  tasks,
  queryId = 'default',
) => ({
  type: READ_TASKS,
  payload: {
    tasks,
    queryId,
  },
});

export const createTaskAction = (task, queryId = 'default') => ({
  type: CREATE_TASK,
  payload: {
    task,
    queryId,
  },
});

export const updateTaskAction = (task, queryId = 'default') => ({
  type: UPDATE_TASK,
  payload: {
    task,
    queryId,
  },
});

export const deleteTaskAction = (taskId, queryId = 'default') => ({
  type: DELETE_TASK,
  payload: {
    taskId,
    queryId,
  },
});

const initialTasks = { default: {} };

export function tasks(state = initialTasks, action) {
  switch (action.type) {
    case READ_TASKS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.tasks.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_TASK:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.task._id]: action.payload.task,
        },
      };
    case UPDATE_TASK: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.task._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.task._id]: {
            ...current,
            ...action.payload.task,
          },
        },
      };
    }
    case DELETE_TASK: {
      const { taskId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [taskId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
