import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import TrainingEventReportTabs from './TrainingEventReportTabs';
import { generateUuid } from '@aims/shared/shared/utils';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';

function TrainingEventReportsPage() {
  const newReportId = useMemo(() => {
    return generateUuid();
  }, []);
  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Training Event Reports"
      >
        <CSPageHeader
          title="Training Event Reports"
          topActions={[
            <Link
              key="add"
              to={{
                pathname: `/training-event-reports/edit/${newReportId}`,
                state: {
                  mode: 'create',
                },
              }}
            >
              <Button type="link" icon={<PlusCircleOutlined />} size="large">
                Create a Training Event Report
              </Button>
            </Link>,
          ]}
        />
        <TrainingEventReportTabs />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default TrainingEventReportsPage;
