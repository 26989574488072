import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import DisplayAttachments from '../../../../components/AnyAttachments/DisplayAttachments';
import ContentEditableInput from '../../../../components/ContentEditableInput';
import EditNote from './EditNote';

const { Paragraph } = Typography;

function ExistingNote({ task, activity, locale, profile }) {
  const [editing, setEditing] = useState(false);

  return (
    <div style={{ marginBottom: 16 }}>
      {editing && (
        <EditNote task={task} activity={activity} setEditing={setEditing} />
      )}
      {!editing && (
        <>
          <div style={{ display: 'flex', marginBottom: 8 }}>
            <div>
              {activity.createdAt && activity.createdByUser && (
                <Paragraph
                  style={{
                    marginBottom: 8,
                    color: sharedSettings.colors.primary,
                  }}
                >{`${activity.createdByUser.name}, ${new Intl.DateTimeFormat(
                  locale,
                  {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  },
                ).format(new Date(activity.createdAt))}`}</Paragraph>
              )}
              {activity.desc && (
                <ContentEditableInput readOnly value={activity.desc} />
              )}
              {!activity.desc && <Paragraph>Click to add</Paragraph>}
            </div>
            {`user:${profile._id}` === activity.createdBy && (
              <Tooltip title="Edit Note">
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => setEditing(true)}
                  style={{ marginLeft: 'auto' }}
                />
              </Tooltip>
            )}
          </div>
          <DisplayAttachments attachments={activity?.attachments} />
        </>
      )}
    </div>
  );
}

export default ExistingNote;
