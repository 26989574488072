import { Button, message, notification } from 'antd';
import { ExportToCsv } from 'export-to-csv';
import { displayMoney } from '@aims/shared/shared/utils';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import { AccountTypesJ25 } from '../constants';

export function exportTransfersToCsv(transfers, locale, title) {
  const data = transfers.map((t) => {
    return {
      _id: t._id,
      description: t.description,
      amount: displayMoney((t.amount || 0) / 10000),
      originalDonationId: t.originalDonationId,
      originalDate: t.originalDate,
      transactionId: t.originalDonation && t.originalDonation.transactionId,
      settlementId: t.originalDonation && t.originalDonation.settlementId,
      estimatedDepositDate:
        t.originalDonation &&
        t.originalDonation.estimatedDepositDate &&
        new Intl.DateTimeFormat(locale, {
          dateStyle: 'medium',
        }).format(new Date(t.originalDonation.estimatedDepositDate)),
      payerId: t.originalDonation && t.originalDonation.payerId,
      payerName: t.originalDonation && t.originalDonation.payerName,
      ppReceivedOn: t.originalDonation && t.originalDonation.ppReceivedOn,
      fromAccountId: t.fromAccountId,
      fromAccountName: t.fromAccount && t.fromAccount.name,
      fromAccountType:
        t.fromAccount &&
        AccountTypesJ25[t.fromAccount.type] &&
        AccountTypesJ25[t.fromAccount.type].label,
      toAccountId: t.toAccountId,
      toAccountName: t.toAccount && t.toAccount.name,
      toAccountType:
        t.toAccount &&
        AccountTypesJ25[t.toAccount.type] &&
        AccountTypesJ25[t.toAccount.type].label,
      toContactId: t.toContactId,
      toContactName: t.toContact && t.toContact.fullName,
      adoptionId: t.adoptionId,
      sponsorshipId: t.sponsorshipId,
      payoutId: t.payoutId,
      createdAt: new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(t.createdAt)),
      updatedAt: new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(t.updatedAt)),
    };
  });
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title,
    useTextFile: false,
    useBom: true,
    filename: title,
    headers: [
      'Transfer ID',
      'Description',
      'Amount',
      'Original Pushpay Payment Token',
      'Original Pushpay Date',
      'Pushpay Transaction ID',
      'Pushpay Settlement ID',
      'Pushpay Estimated Deposit Date',
      'Pushpay Payer Token',
      'Pushpay Payer Name',
      'Pushpay Payment Received On Date',
      'From Account ID',
      'From Account Name',
      'From Account Type',
      'To Account ID',
      'To Account Name',
      'To Account Type',
      'To Contact ID',
      'To Contact Name',
      'Sponsorship ID',
      'Adoption ID',
      'Payout ID',
      'Created',
      'Updated',
    ],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

function ExportTransfersBtn({ transfers }) {
  const [exporting, setExporting] = useState(false);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleExportCsv = useCallback(() => {
    message.info('Exporting ...');
    setExporting(true);
    try {
      const title = `Transfers ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date())}`.replace(',', ' ');
      exportTransfersToCsv(transfers, locale, title);
      message.success('Success');
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setExporting(false);
  }, [locale, transfers]);
  return (
    <Button
      icon={<ExportOutlined />}
      onClick={handleExportCsv}
      loading={exporting}
      style={{ marginRight: 8 }}
    >
      Export CSV
    </Button>
  );
}

export default ExportTransfersBtn;
