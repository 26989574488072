import { gql, useMutation } from '@apollo/client';

export const updateGoogleCredsMutation = gql`
  mutation updateGoogleCreds(
    $code: String!
    $scopes: [String!]!
    $origin: String!
  ) {
    updateGoogleCreds(code: $code, scopes: $scopes, origin: $origin)
  }
`;

function useUpdateGoogleCredentials() {
  const [updateGoogleCreds] = useMutation(updateGoogleCredsMutation);
  return updateGoogleCreds;
}

export default useUpdateGoogleCredentials;
