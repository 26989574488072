import { gql, useMutation } from '@apollo/client';
import { Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import LanguageForm from './LanguageForm';

export const upsertSimpleListItemForAdminMutation = gql`
  mutation EditLanguageForAdmin($name: String!, $item: SimpleListItemInput!) {
    upsertSimpleListItemForAdmin(name: $name, item: $item)
  }
`;

const { Title, Text } = Typography;

function EditLanguageModal({ visible, onCancel, onFinish }) {
  const { language } = visible || {};

  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editLanguage] = useMutation(upsertSimpleListItemForAdminMutation);

  useEffect(() => {
    if (language) {
      form.setFieldsValue({
        key: language.key,
      });
    } else {
      form.resetFields();
    }
  }, [language, form]);

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { key, color } = _values;
        await editLanguage({
          variables: {
            name: 'tr-language',
            item: {
              _id: language._id,
              key: key,
              content: {},
            },
          },
        });
        onFinish();
        notification.success({
          message: 'Success',
          description: 'Language saved successfully',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [editLanguage, onFinish, language],
  );
  return (
    <>
      <Modal
        open={visible}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
      >
        <Title level={3} style={{ marginTop: 8 }}>
          Edit Language
        </Title>
        <LanguageForm
          form={form}
          handleFinish={handleFinish}
          saving={saving}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
}

export default EditLanguageModal;
