import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  InputNumber,
  Modal,
  notification,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createLoanAgreementAction } from '../../../../../redux-store/loan-agreements-store';
import {
  AgreementStatuses,
  createLoanAgreementFromTemplateMutation,
} from '../../../../loan-agreements/constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Text, Title, Paragraph } = Typography;

function LoanAgreementToClone({ agreement }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const updated = `Updated ${new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
  }).format(new Date(agreement.updatedAt))}`;
  const status = AgreementStatuses[agreement.status];
  return (
    <Card>
      <div
        style={{
          fontWeight: 600,
          color: sharedSettings.colors.primary,
          fontSize: 14,
        }}
      >
        {agreement.name}
      </div>
      <div>{updated}</div>
      <div>
        {agreement.parties &&
          agreement.parties.map((p) => (
            <div style={{ fontSize: 12 }} key={p._id}>{`${p.name}`}</div>
          ))}
      </div>
    </Card>
  );
}

function SelectTemplateForm({
  agreementToClone,
  loading,
  handleSave,
  defaultVersionNo,
}) {
  const [form] = Form.useForm();
  return (
    <>
      <Title level={4}>Cloning</Title>
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={handleSave}
      >
        <Form.Item>
          <LoanAgreementToClone agreement={agreementToClone} />
        </Form.Item>
        <Form.Item
          label="Version"
          name="versionNo"
          initialValue={defaultVersionNo}
        >
          <InputNumber disabled={loading} min={0} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
              loading={loading}
            >
              Clone
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

const CloneLoanAgreementModal = ({ visible, onFinish, onCancel }) => {
  const { project, createId, defaultVersionNo, agreementToClone } =
    visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [createLoanAgreementFromTemplate] = useMutation(
    createLoanAgreementFromTemplateMutation,
  );

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const { versionNo } = values;
        const result = await createLoanAgreementFromTemplate({
          variables: {
            templateId: agreementToClone._id,
            projectId: project._id,
            newId: createId,
            versionNo,
          },
        });
        dispatch(
          createLoanAgreementAction(
            result.data.createLoanAgreementFromTemplate.loanAgreement,
            'h-project',
          ),
        );
        notification.success({
          message: 'Saved',
          description: 'Loan agreement created successfully',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, project, createId, createLoanAgreementFromTemplate, onFinish],
  );

  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          Clone a Loan Agreement
        </Title>
        {visible && (
          <SelectTemplateForm
            handleSave={handleSave}
            loading={loading}
            defaultVersionNo={defaultVersionNo}
            agreementToClone={agreementToClone}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CloneLoanAgreementModal;
