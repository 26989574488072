import React from 'react';
import LinkedPushpayAccounts from '../../pushpay/LinkedPushpayAccounts';

function ContactPushpayTab({ contact, queryId }) {
  if (!contact) {
    return null;
  }

  return (
    <>
      <LinkedPushpayAccounts contact={contact} queryId={queryId} />
    </>
  );
}

export default ContactPushpayTab;
