import { ContactsOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { unassignUserFromContactMutation } from '../constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Title, Text, Paragraph } = Typography;

function UnassignUserFromContactModal({ user, onDone: onDone1 }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => {
    setEditing(false);
    onDone1();
  }, [onDone1]);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [unassignUserFromContact] = useMutation(
    unassignUserFromContactMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await unassignUserFromContact({
          variables: {
            userId: user._id,
            contactId: user.contactId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'User unassigned from contact successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error unassigning user from contact',
        });
      }
      setSaving(false);
    },
    [user, unassignUserFromContact, onDone],
  );

  const [form] = Form.useForm();

  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 24,
            paddingBottom: 16,
          },
        }}
      >
        <Title level={3} style={{ textAlign: 'center' }}>
          Unassign User from Contact
        </Title>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Paragraph>{`Are you sure you want to unassign ${user?.name} from the following contact:`}</Paragraph>
          <Paragraph
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              marginBottom: 24,
            }}
          >{`${user?.contact?.fullName}`}</Paragraph>
          <ShowErrorsFormItem />
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={onCancel}
                htmlType="button"
                disabled={saving}
                style={{ marginRight: 16, width: 100 }}
              >
                No
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={saving}
                style={{ width: 100 }}
              >
                Yes
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Button type="dashed" icon={<ContactsOutlined />} onClick={handleEdit}>
        Unassign User from Contact
      </Button>
    </>
  );
}

export default UnassignUserFromContactModal;
