import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateMouAction } from '../../../redux-store/mous-store';
import { AgreementStatuses } from '../../loan-agreements/constants';
import { prepareMouMutation } from '../constants';

const MarkMouPrepareBtn = ({ mou, setError, error, queryId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [prepareMou] = useMutation(prepareMouMutation);
  const dispatch = useDispatch();

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await prepareMou({
        variables: {
          mouId: mou._id,
          status: AgreementStatuses.PREVIEW.key,
        },
      });
      dispatch(updateMouAction(result.data.prepareMou.mou, queryId));
      notification.success({
        message: 'Saved',
        description: 'Partnership Commitment Form updated successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [setError, dispatch, queryId, prepareMou, mou, onSuccess]);

  return (
    <>
      <Button loading={loading} icon={<EditOutlined />} onClick={handleFinish}>
        Edit
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default MarkMouPrepareBtn;
