import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Card, Form, Input, List, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { generateUuid } from '../../shared/utils';
import { AgreementStatuses } from './constants';
import { Fonts } from './signature-fonts';

const { Title, Paragraph, Text } = Typography;

function SingleSection({
  showMoveDown,
  showMoveUp,
  editing,
  section,
  doSave,
  loading,
  doEdit,
  doCancel,
  doDelete,
  doMoveUp,
  doMoveDown,
  parties,
  attachments,
  backgroundColor,
  showEditButtons,
  previewInitials,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      _id: section._id,
      title: section.title,
      text: section.text,
    });
  }, [section, form]);
  return (
    <>
      {editing ? (
        <Card>
          <Form form={form} layout="vertical" onFinish={doSave}>
            <Form.Item name="_id" initialValue={section._id} noStyle>
              <Input type="hidden" disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Section Title"
              name="title"
              extra="ex: AIMS Partnership agreement, Purpose ..."
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Text"
              name="text"
              extra={`Use "{{partyRef}}" to reference a party's name in the text`}
            >
              <Input.TextArea disabled={loading} rows={4} />
            </Form.Item>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  htmlType="button"
                  onClick={() => doCancel(section._id)}
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      ) : (
        <>
          <div className="line-heading">
            <Title level={3}>{section.title || 'No Title'}</Title>
            {!editing && showEditButtons && (
              <div>
                {showMoveUp && (
                  <Button
                    onClick={() => doMoveUp(section._id)}
                    icon={<ArrowUpOutlined />}
                    type="text"
                  >
                    Move Up
                  </Button>
                )}
                {showMoveDown && (
                  <Button
                    onClick={() => doMoveDown(section._id)}
                    icon={<ArrowDownOutlined />}
                    type="text"
                  >
                    Move Down
                  </Button>
                )}
                <Button
                  onClick={() => doEdit(section._id)}
                  icon={<EditOutlined />}
                  type="text"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => doDelete(section._id)}
                  icon={<DeleteOutlined />}
                  type="text"
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
          <div
            className="line-answer"
            style={{ marginBottom: previewInitials ? 12 : 24 }}
          >
            {!section.text && 'No Text'}
            {section.text &&
              section.text.split('\n').map((p, index) => {
                let edited = p;
                const refs = p.matchAll(/{{(.*)}}/g);
                let ref = refs.next();
                while (!ref.done) {
                  const party =
                    parties && parties.find((a) => a.ref === ref.value[1]);
                  if (party) {
                    if (party.contactName) {
                      edited = p.replace(
                        ref.value[0],
                        `Party: ${party.contactName}`,
                      );
                    } else {
                      edited = p.replace(
                        ref.value[0],
                        `<Party: ${party.name}>`,
                      );
                    }
                  }
                  const attachment =
                    attachments &&
                    attachments.find((a) => a.ref === ref.value[1]);
                  if (attachment) {
                    edited = p.replace(
                      ref.value[0],
                      `Attachment: ${attachment.name}`,
                    );
                  }
                  ref = refs.next();
                }
                return (
                  <Paragraph className="line-answer-p" key={index}>
                    {edited}
                  </Paragraph>
                );
              })}
          </div>
          {previewInitials && (
            <>
              <div
                style={{
                  color: sharedSettings.colors.primary,
                  fontWeight: 600,
                  marginBottom: 8,
                }}
              >
                Initials
              </div>
              <div
                style={{ fontSize: 16, color: sharedSettings.colors.textGray }}
              >
                {(!section.initials || section.initials.length === 0) &&
                  'Initials will go here'}
                {section.initials &&
                  section.initials.length > 0 &&
                  section.initials.map((i, index) => (
                    <>
                      <Text
                        key={i.partyId}
                        style={{
                          fontFamily:
                            Fonts[i.fontName] && Fonts[i.fontName].fontFamily,
                          fontSize: 24,
                          marginRight: 16,
                        }}
                      >
                        {i.initials}
                      </Text>
                    </>
                  ))}
              </div>
            </>
          )}
        </>
      )}
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: left;
          background-color: ${backgroundColor};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
        }
      `}</style>
    </>
  );
}

function SectionsElement({ loanAgreement, handleSave, queryId }) {
  const [editing, setEditing] = useState({});
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [sections, setSections] = useState([]);
  const doSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const index = (
          (loanAgreement && loanAgreement.sections) ||
          []
        ).findIndex((p) => p._id === values._id);
        const _sections = [
          ...((loanAgreement && loanAgreement.sections) || []),
        ].map((p) => ({
          _id: p._id,
          title: p.title,
          text: p.text,
        }));
        if (index < 0) {
          _sections.push(values);
        } else {
          _sections[index] = values;
        }
        handleSave({
          sections: _sections,
        });
        setSections(_sections);
        setEditing({});
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  useEffect(() => {
    setSections((loanAgreement && loanAgreement.sections) || []);
  }, [loanAgreement, form]);

  const handleAddSection = useCallback(() => {
    const sectionId = generateUuid();
    setEditing({ ...editing, [sectionId]: true });
    setSections([...sections, { _id: sectionId }]);
  }, [sections, editing]);

  const handleEdit = useCallback(
    (sectionId) => {
      setEditing({ ...editing, [sectionId]: true });
    },
    [editing],
  );
  const handleCancelEdit = useCallback(
    (sectionId) => {
      setEditing({ ...editing, [sectionId]: false });
    },
    [editing],
  );
  const doDelete = useCallback(
    async (sectionId) => {
      setLoading(true);
      setError(undefined);
      const _sections = (loanAgreement.sections || [])
        .filter((p) => p._id !== sectionId)
        .map((p) => ({
          _id: p._id,
          title: p.title,
          text: p.text,
        }));
      handleSave({
        sections: _sections,
      });
      setSections(_sections);
      setEditing({});
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );
  const doMoveUp = useCallback(
    async (sectionId) => {
      setLoading(true);
      setError(undefined);
      const oldIndex = loanAgreement.sections.findIndex(
        (s) => s._id === sectionId,
      );
      const section = loanAgreement.sections[oldIndex];
      let _sections = (loanAgreement.sections || []).filter(
        (p) => p._id !== sectionId,
      );
      if (oldIndex > 0) {
        const newIndex = oldIndex - 1;
        _sections = [
          ..._sections.slice(0, newIndex),
          section,
          ..._sections.slice(newIndex),
        ];
        _sections = _sections.map((p) => ({
          _id: p._id,
          title: p.title,
          text: p.text,
        }));
        handleSave({
          sections: _sections,
        });
        setSections(_sections);
      }
      setEditing({});
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );
  const doMoveDown = useCallback(
    async (sectionId) => {
      setLoading(true);
      setError(undefined);
      const oldIndex = loanAgreement.sections.findIndex(
        (s) => s._id === sectionId,
      );
      const section = loanAgreement.sections[oldIndex];
      let _sections = (loanAgreement.sections || []).filter(
        (p) => p._id !== sectionId,
      );
      if (oldIndex < loanAgreement.sections.length - 1) {
        const newIndex = oldIndex + 1;
        _sections = [
          ..._sections.slice(0, newIndex),
          section,
          ..._sections.slice(newIndex),
        ];
        _sections = _sections.map((p) => ({
          _id: p._id,
          title: p.title,
          text: p.text,
        }));
        handleSave({
          sections: _sections,
        });
        setSections(_sections);
      }
      setEditing({});
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  return (
    <>
      <div className="line-heading">
        <Title level={2}>Sections</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={sections}
        renderItem={(section, index) => {
          return (
            <List.Item>
              <SingleSection
                showMoveUp={index > 0}
                showMoveDown={index < sections.length - 1}
                section={section}
                editing={editing[section._id]}
                doSave={doSave}
                loading={loading}
                doEdit={handleEdit}
                doCancel={handleCancelEdit}
                doDelete={doDelete}
                doMoveUp={doMoveUp}
                doMoveDown={doMoveDown}
                parties={loanAgreement && loanAgreement.parties}
                attachments={loanAgreement && loanAgreement.attachments}
                backgroundColor={
                  !loanAgreement || loanAgreement.isTemplate
                    ? sharedSettings.colors.borderGray
                    : sharedSettings.colors.ghost
                }
                showEditButtons={
                  !loanAgreement ||
                  loanAgreement.status === AgreementStatuses.PREPARING.key ||
                  loanAgreement.isTemplate
                }
                previewInitials={
                  loanAgreement &&
                  loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                  !loanAgreement.isTemplate
                }
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Sections contain the body of this agreement.`}
              </Paragraph>
              <div>
                <Button type="primary" onClick={handleAddSection}>
                  Add Section
                </Button>
              </div>
            </>
          ),
        }}
        rowKey="_id"
      />
      {sections &&
        sections.length > 0 &&
        (!loanAgreement ||
          loanAgreement.status === AgreementStatuses.PREPARING.key) && (
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={handleAddSection}>
              Add Another Section
            </Button>
          </div>
        )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default SectionsElement;
