import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import ContactChangeSomethingModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactChangeSomethingModalA10';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  StarFilled,
} from '@ant-design/icons';
import ContactAddEmailInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactAddEmailInnerModalA10';
import ContactUpdateEmailInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactUpdateEmailInnerModalA10';
import ContactDeleteEmailInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactDeleteEmailInnerModalA10';

const { Title, Text } = Typography;

function AnyEmails({ parent }) {
  const [updating, setUpdating] = useState();
  const handleUpdate = (email) => setUpdating({ email });
  const onFinishUpdate = () => setUpdating(undefined);
  const onCancelUpdate = () => setUpdating(undefined);

  const [deleting, setDeleting] = useState();
  const handleDelete = (email) => setDeleting({ email });
  const onFinishDelete = () => setDeleting(undefined);
  const onCancelDelete = () => setDeleting(undefined);
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Primary?',
      key: 'primary',
      render: (text, record) =>
        record._id === parent.primaryEmailId ? (
          <Typography>
            <StarFilled style={{ marginRight: 4 }} />
            Yes
          </Typography>
        ) : (
          'No'
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              onClick={() => handleUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Remove">
            <Button
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="container">
      <Title level={2} style={{ marginBottom: 0 }}>
        Emails
      </Title>
      <ContactChangeSomethingModalA10
        contact={parent}
        btnProps={{
          type: 'text',
          icon: <PlusOutlined />,
          style: { marginBottom: 16, marginLeft: -16 },
        }}
        btnText="Add an Email"
      >
        <ContactAddEmailInnerModalA10 />
      </ContactChangeSomethingModalA10>
      <Table
        columns={columns}
        dataSource={parent.emails}
        pagination={false}
        rowKey="_id"
      />
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      >
        <ContactUpdateEmailInnerModalA10 email={updating?.email} />
      </ContactChangeSomethingModalA10>
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={deleting}
        onDone={onFinishDelete}
        onCancel={onCancelDelete}
      >
        <ContactDeleteEmailInnerModalA10 email={deleting?.email} />
      </ContactChangeSomethingModalA10>
      <style jsx>{`
        .container {
          margin-bottom: 32px;
        }
      `}</style>
    </div>
  );
}

export default AnyEmails;
