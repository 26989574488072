import { Form, Typography } from 'antd';
import React from 'react';
import SaveSomethingButtons from '../../../../components/SaveSomethingButtons';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Title, Text } = Typography;

function ContactSomethingForm({
  title,
  form,
  onFinish,
  onCancel,
  saving,
  children,
}) {
  return (
    <>
      <Title level={3} style={{ marginTop: 0 }}>
        {title}
      </Title>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        {children}
        <ShowErrorsFormItem />
        <Form.Item>
          <SaveSomethingButtons onCancel={onCancel} saving={saving} />
        </Form.Item>
      </Form>
    </>
  );
}

export default ContactSomethingForm;
