import { generateUuid } from '@aims/shared/shared/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import CreateTrainingCourseModal from '../EditCourse/CreateTrainingCourseModal';
import AllTrainingCourses from './AllTrainingCourses';
import CSPage from '../../../../components/CSPage';
import CSPageHeader from '../../../../components/CSPageHeader';
import useProfile from '../../../../shared/use-profile';
import useCanEditTraining from '../use-can-edit-training';

function AllTrainingCoursesPage() {
  const refetchRef = useRef();
  const [creating, setCreating] = useState();
  const onCreate = () => setCreating({ _id: generateUuid() });
  const onCancelCreate = () => setCreating(undefined);
  const onFinishCreate = () => {
    refetchRef.current();
    setCreating(undefined);
  };

  const showCreate = useCanEditTraining();
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Training Courses">
        <CSPageHeader
          title="Training Courses"
          topActions={[
            showCreate && (
              <Button
                key="add"
                type="link"
                icon={<PlusCircleOutlined />}
                size="large"
                onClick={onCreate}
              >
                Create a Training Course
              </Button>
            ),
          ].filter((a) => a)}
        />
        <AllTrainingCourses refetchRef={refetchRef} />
        <CreateTrainingCourseModal
          visible={creating}
          onFinish={onFinishCreate}
          onCancel={onCancelCreate}
        />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default AllTrainingCoursesPage;
