import { Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ProfilePhotoFormItemJ24 from '../../../profile-photo/ProfilePhotoFormItemJ24';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactChangePhotoModal({
  contact,
  saving,
  handleFinish,
  onCancel,
  setSaving,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        regionCode: contact.regionCode,
      });
    }
  }, [contact, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { regionCode, ...values } = _values;
      handleFinish({
        regionCode,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Change Profile Photo"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <ProfilePhotoFormItemJ24
        name="profilePhotoId"
        existing={
          contact && {
            _id: contact.profilePhotoId,
            thumbnailUrl: contact.primaryThumbnailUrl,
          }
        }
        styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
        parentType="contact"
        parentId={contact?._id}
        tags={['Contact', 'Profile Photo']}
        saving={saving}
        setSaving={setSaving}
      />
    </ContactSomethingForm>
  );
}

export default ContactChangePhotoModal;
