export const READ_SAVED_IMAGES = 'READ_SAVED_IMAGES';
export const CREATE_SAVED_IMAGE = 'CREATE_SAVED_IMAGE';
export const UPDATE_SAVED_IMAGE = 'UPDATE_SAVED_IMAGE';
export const DELETE_SAVED_IMAGE = 'DELETE_SAVED_IMAGE';

// eslint-disable-next-line no-shadow
export const readSavedImagesAction = (activities, queryId = 'default') => ({
  type: READ_SAVED_IMAGES,
  payload: {
    activities,
    queryId,
  },
});

export const createSavedImageAction = (savedImage, queryId = 'default') => ({
  type: CREATE_SAVED_IMAGE,
  payload: {
    savedImage,
    queryId,
  },
});

export const updateSavedImageAction = (savedImage, queryId = 'default') => ({
  type: UPDATE_SAVED_IMAGE,
  payload: {
    savedImage,
    queryId,
  },
});

export const deleteSavedImageAction = (savedImageId, queryId = 'default') => ({
  type: DELETE_SAVED_IMAGE,
  payload: {
    savedImageId,
    queryId,
  },
});

const initialSavedImages = { default: {} };

export function savedImages(state = initialSavedImages, action) {
  switch (action.type) {
    case READ_SAVED_IMAGES: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.activities.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_SAVED_IMAGE:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.savedImage._id]: action.payload.savedImage,
        },
      };
    case UPDATE_SAVED_IMAGE: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.savedImage._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.savedImage._id]: {
            ...current,
            ...action.payload.savedImage,
          },
        },
      };
    }
    case DELETE_SAVED_IMAGE: {
      const { savedImageId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [savedImageId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
