import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import SelectHolisticProject from '../../holistic-projects/SelectHolisticProject';
import TaskSomethingForm from './TaskSomethingForm';

const { Paragraph, Title } = Typography;

export function DisplayHolisticProjectShort({ holisticProject }) {
  const typeLabel = HolisticProjectTypes[holisticProject.typeOfProject]?.label;
  let nameLabel;
  if (holisticProject.projectNo) {
    nameLabel = `${holisticProject.projectName} (#${holisticProject.projectNo})`;
  } else {
    nameLabel = holisticProject.projectName;
  }
  const upgs = holisticProject.peopleGroupIds.join(', ');
  return (
    <div style={{ display: 'flex' }}>
      <PhotoCircle
        photoUrl={holisticProject.primaryThumbnailUrl}
        name={holisticProject.projectName}
        style={{
          height: 60,
          width: 60,
          paddingBottom: 'unset',
          marginRight: 16,
        }}
        color={sharedSettings.colors.contactColor}
      />
      <div>
        <Paragraph style={{ marginBottom: 0 }}>{nameLabel}</Paragraph>
        <Paragraph
          style={{ color: sharedSettings.colors.textGray, marginBottom: 0 }}
        >
          {typeLabel}
        </Paragraph>
        <Paragraph style={{ marginBottom: 0 }}>{upgs}</Paragraph>
        <div>
          <Link to={`/holistic-project/view/${holisticProject._id}/details`}>
            Project Page
          </Link>
        </div>
      </div>
    </div>
  );
}

const EditHolisticProject = ({ onSubmit, task }) => {
  const [editing, setEditing] = useState(false);
  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onFinishEdit = () => setEditing(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const { ...values } = task;
    form.setFieldsValue({
      ...values,
    });
  }, [task, form]);

  const onFinish = useCallback(
    async ({ holisticProjectId }) => {
      setSaving(true);
      try {
        await onSubmit({ holisticProjectId: holisticProjectId || null });
        onFinishEdit();
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'There was an error saving your task.',
        });
      }
      setSaving(false);
    },
    [onSubmit],
  );

  return (
    <div style={{ marginBottom: 16 }}>
      {!editing ? (
        <map onClick={onEdit} style={{ width: '100%', cursor: 'pointer' }}>
          <Title level={4}>Holistic Project</Title>
          {task?.holisticProject ? (
            <DisplayHolisticProjectShort
              holisticProject={task.holisticProject}
            />
          ) : (
            <Paragraph style={{ minHeight: '36px' }}>
              Click to add holistic project
            </Paragraph>
          )}
        </map>
      ) : (
        <TaskSomethingForm
          title="Holistic Project"
          form={form}
          onFinish={onFinish}
          onCancel={onCancelEdit}
          saving={saving}
        >
          <Form.Item
            label="Holistic Project"
            extra="Associate this task with a holistic project"
            name="holisticProjectId"
          >
            <SelectHolisticProject disabled={saving} />
          </Form.Item>
        </TaskSomethingForm>
      )}
    </div>
  );
};

export default EditHolisticProject;
