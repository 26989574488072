import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import { HolisticProjectRequestItems } from '@aims/shared/reports';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import useProfile from '../../shared/use-profile';
import useQueryParams from '../../shared/use-query-params';
import DeleteHolisticProposalModal from './DeleteHolisticProposalModal';
import {
  createHolisticProposalForAdminMutation,
  extractHolisticProjectProposal,
  holisticProposalForAdminQuery,
  submitHolisticProposalForAdminMutation,
  unsubmitHolisticProposalForAdminMutation,
  updateHolisticProposalForAdminMutation,
} from './constants';
import usePgConnections from '../upg-x-contacts/use-pg-connections';

const { Title, Text } = Typography;

const queryId = 'editHolisticProposal';

function EditProposal() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);

  const { reportId } = useParams();

  const { loading: reportLoading } = useOneM1({
    variables: { _id: reportId },
    query: holisticProposalForAdminQuery,
    extract: extractHolisticProjectProposal,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !reportId,
    showError: false,
  });
  const report = useSingleSomethingA10('reports', reportId, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/holistic-proposals`;
    }
  }, [query]);

  const [form] = Form.useForm();
  const finishSubForm = useRef();
  const shouldSubmitReport = useRef(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateReport] = useMutation(updateHolisticProposalForAdminMutation);
  const [createReport] = useMutation(createHolisticProposalForAdminMutation);
  const [submitReport] = useMutation(submitHolisticProposalForAdminMutation);
  const [unsubmitReport] = useMutation(
    unsubmitHolisticProposalForAdminMutation,
  );
  const profile = useProfile();

  const onSubmit = useCallback(
    async (__values) => {
      let msg;
      if (shouldSubmitReport.current === 'submit') {
        msg = 'Submitting Proposal ...';
      } else if (shouldSubmitReport.current === 'unsubmit') {
        msg = 'Unsubmitting Proposal ...';
      } else {
        msg = 'Saving Draft ...';
      }
      setSaveLoading(msg);
      setError(null);
      const { reportType, notes, ..._values } = __values;
      try {
        const finished = finishSubForm.current(_values);
        if (report && shouldSubmitReport.current === 'unsubmit') {
          await unsubmitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Proposal unsubmitted successfully',
          });
        }
        if (report) {
          await updateReport({
            variables: {
              report: {
                _id: reportId,
                reportType: 'HOLISTIC_REQ',
                notes,
                ...finished,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Proposal updated successfully',
          });
        } else {
          await createReport({
            variables: {
              report: {
                _id: reportId,
                contactId: profile.contactId,
                reportType: 'HOLISTIC_REQ',
                notes,
                ...finished,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Proposal created successfully',
          });
        }
        if (shouldSubmitReport.current === 'submit') {
          await submitReport({
            variables: {
              reportId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Proposal submitted successfully',
          });
        }
        history.push(
          `/holistic-proposals/review/${reportId}?from=${location.pathname}`,
        );
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error has occurred.',
        });
      }
      setSaveLoading(false);
    },
    [
      reportId,
      createReport,
      history,
      profile,
      report,
      submitReport,
      unsubmitReport,
      updateReport,
    ],
  );

  const saveDraft = useCallback(async () => {
    const __values = form.getFieldsValue();
    setSaveLoading('Saving Draft ...');
    setError(null);
    const { reportType, notes, ..._values } = __values;
    try {
      const finished = finishSubForm.current(_values);
      if (report) {
        await updateReport({
          variables: {
            report: {
              _id: reportId,
              reportType: 'HOLISTIC_REQ',
              notes,
              ...finished,
              peopleGroupIds: finished.peopleGroupIds.filter((p) => p),
              responsiblePersons: JSON.parse(
                JSON.stringify(finished),
              ).responsiblePersons.filter((p) => Object.keys(p).length > 0),
              supportedFieldWorkers: JSON.parse(
                JSON.stringify(finished.supportedFieldWorkers),
              ).filter((p) => Object.keys(p).length > 0),
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Proposal updated successfully',
        });
      } else {
        await createReport({
          variables: {
            report: {
              _id: reportId,
              contactId: profile.contactId,
              reportType: 'HOLISTIC_REQ',
              notes,
              ...finished,
              peopleGroupIds: finished.peopleGroupIds.filter((p) => p),
              responsiblePersons: JSON.parse(
                JSON.stringify(finished.responsiblePersons),
              ).filter((p) => Object.keys(p).length > 0),
              supportedFieldWorkers: JSON.parse(
                JSON.stringify(finished.supportedFieldWorkers),
              ).filter((p) => Object.keys(p).length > 0),
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Proposal created successfully',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error has occurred.',
      });
    }
    setSaveLoading(false);
  }, [reportId, createReport, profile, report, updateReport, form]);

  const title = useMemo(() => {
    if (reportLoading) {
      return (
        <Title style={{ marginBottom: 0 }}>Holistic Project Proposal</Title>
      );
    }
    if (report) {
      const status = ReportApprovalStatuses[report.status];
      return (
        <>
          <Title style={{ marginBottom: 16 }}>
            Edit Holistic Project Proposal
          </Title>
          {status && (
            <div style={{ color: status.color, fontWeight: 600, fontSize: 18 }}>
              {status.label}
            </div>
          )}
        </>
      );
    }
    return (
      <Title style={{ marginBottom: 0 }}>
        Create Holistic Project Proposal
      </Title>
    );
  }, [reportLoading, report]);

  const loading = reportLoading || saveLoading;
  const showDelete = report && !report.submittedAt;

  const pgConnections = usePgConnections(report?.contactId);

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Edit Proposal"
      >
        <CSPageHeader
          titleComponent={
            <div style={{ textAlign: 'center', marginBottom: 32 }}>{title}</div>
          }
          backActions={[
            <Link to={backLink} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Go back
              </Button>
            </Link>,
          ]}
        />
        <Form
          layout="vertical"
          onFinish={onSubmit}
          form={form}
          style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
        >
          {reportLoading && (
            <>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
              <Form.Item>
                <Input disabled />
              </Form.Item>
              <Skeleton active title paragraph />
            </>
          )}
          {!reportLoading && (
            <HolisticProjectRequestItems
              _id={reportId}
              report={report}
              loading={loading}
              form={form}
              finishSubForm={finishSubForm}
              colors={sharedSettings.colors}
              pgConnections={pgConnections}
              regionalCoordId={report?.contactId}
              isAdmin
            />
          )}
          <ShowErrorsFormItem />
          <div style={{ height: 16 }} />
          <Form.Item>
            <Space
              style={{
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {(!report || (report && !report.submittedAt)) && (
                <Button
                  key="draft"
                  type="dashed"
                  loading={loading}
                  htmlType="button"
                  onClick={saveDraft}
                >
                  Save Draft
                </Button>
              )}
              {(!report || (report && !report.submittedAt)) && (
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="button"
                  onClick={() => {
                    shouldSubmitReport.current = 'submit';
                    form.submit();
                  }}
                >
                  Submit Proposal
                </Button>
              )}
              {report &&
                report.submittedAt &&
                (!report.approval ||
                  (report.approval &&
                    report.approval.status === 'PENDING')) && (
                  <Button
                    key="unsubmit"
                    type="dashed"
                    loading={loading}
                    htmlType="button"
                    onClick={() => {
                      shouldSubmitReport.current = 'unsubmit';
                      form.submit();
                    }}
                  >
                    Unsubmit Proposal
                  </Button>
                )}
            </Space>
          </Form.Item>
          {error && (
            <Text type="danger" style={{ marginTop: 16 }}>
              {error}
            </Text>
          )}
        </Form>
        {showDelete && (
          <div>
            <Divider />
            <div className="delete-box">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => setDeleting(report)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete Proposal
                </Button>
              </div>
            </div>
          </div>
        )}
        <Modal open={saveLoading} footer={null} closable={false} width={300}>
          <div style={{ textAlign: 'center', padding: 24 }}>
            <LoadingOutlined style={{ fontSize: 32, marginBottom: 16 }} />
            <Title level={4}>{saveLoading}</Title>
          </div>
        </Modal>
        <DeleteHolisticProposalModal
          report={deleting}
          setReport={setDeleting}
        />
      </CSPage>
      <style jsx>{`
        .title {
          display: flex;
          padding-top: 32px;
        }
        .left {
          padding-right: 32px;
        }
        .subtitle {
          margin-bottom: 16px;
        }
      `}</style>
      <style jsx global>{`
        .church {
          border: 1px solid ${sharedSettings.colors.borderGray};
        }
      `}</style>
    </>
  );
}

export default EditProposal;
