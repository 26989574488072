import { Switch, Tag, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text } = Typography;

function PayoutWillRecieveColumn({ record, editable, savePayoutRow }) {
  const [checked, setChecked] = useState(record.willReceiveFunds);

  const handleChange = useCallback(
    async (value) => {
      setChecked(value);
      await savePayoutRow({ _id: record._id, willReceiveFunds: value });
    },
    [record, savePayoutRow],
  );
  return (
    <>
      {!editable && record.willReceiveFunds && <Tag color="green">YES</Tag>}
      {!editable && !record.willReceiveFunds && <Tag color="red">NO</Tag>}
      {editable && (
        <Switch
          disabled={
            !['YES', 'MAYBE'].includes(
              record.completable && record.completable.completable,
            )
          }
          checked={checked}
          onChange={handleChange}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      )}
    </>
  );
}

export default PayoutWillRecieveColumn;
