import { useMutation } from '@apollo/client';
import { Typography, message } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import YesNoModal from '../../../../components/YesNoModal';
import { updatePayoutV2Action } from '../../../../redux-store/payouts-v2-store';
import {
  PayoutStates,
  startPayoutV2Mutation,
  completePayoutV2Mutation,
} from '../constants';

const { Text, Paragraph } = Typography;

function ConfirmTransitionModalV2({ confirming, onCancel, onFinish, queryId }) {
  const { payout, payoutRows, newState } = confirming || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startPayout] = useMutation(startPayoutV2Mutation);
  const [completePayout] = useMutation(completePayoutV2Mutation);

  const onYes = useCallback(
    async (savable) => {
      setLoading(true);
      message.info('Saving ...');
      try {
        if (newState === PayoutStates.IN_PROGRESS.key) {
          const result = await startPayout({
            variables: {
              payoutId: payout._id,
            },
          });
        }
        if (newState === PayoutStates.COMPLETE.key) {
          const result = await completePayout({
            variables: {
              payoutId: payout._id,
            },
          });
        }
        message.success('Saved');
        onFinish();
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [startPayout, completePayout, payout, newState, onFinish],
  );

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  const { text, title, yesText, noText } = useMemo(() => {
    if (newState === PayoutStates.IN_PROGRESS.key) {
      return {
        title: 'Start Payout',
        text: (
          <>
            <Paragraph>
              Once you begin a payout, the field workers and regional
              coordinators scheduled to receive funds are fixed and all other
              field workers etc. will be hidden from view.
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Start Payout',
        noText: 'Cancel',
      };
    }
    if (newState === PayoutStates.COMPLETE.key) {
      const incompleteRows = payoutRows?.reduce((prev, curr) => {
        if (curr.willReceiveFunds && curr.state !== PayoutStates.COMPLETE.key) {
          return prev + 1;
        }
        return prev;
      }, 0);
      if (incompleteRows > 0) {
        return {
          title: 'Close Payout',
          text: (
            <>
              <Paragraph>
                {`This payout still has ${incompleteRows} pending row(s).
                You must mark these rows complete before closing this payout.`}
              </Paragraph>
            </>
          ),
          yesText: null,
          noText: 'OK',
        };
      }
      return {
        title: 'Close Payout',
        text: (
          <>
            <Paragraph>
              Once you close a payout, it will become read-only. You will no
              longer be able to edit it.
            </Paragraph>
            <Paragraph>Are you sure you want to continue?</Paragraph>
          </>
        ),
        yesText: 'Close Payout',
        noText: 'Cancel',
      };
    }
    return {};
  }, [payoutRows, newState]);

  return (
    <YesNoModal
      title={title}
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>{text}</div>
        </div>
      }
      yesText={yesText}
      noText={noText}
      onYes={onYes}
      onNo={onNo}
      visible={!!confirming}
      loading={loading}
      error={error}
    />
  );
}

export default ConfirmTransitionModalV2;
