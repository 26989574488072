import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { reviewHolisticQuarterlyReportMutation } from '../constants';

const { Title, Text } = Typography;

const ApproveReportBtn = ({ report, holisticProject, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewReport] = useMutation(reviewHolisticQuarterlyReportMutation);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await reviewReport({
        variables: {
          reportId: report._id,
          status: 'APPROVED',
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Progress report approved.',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your report.',
      });
    }
    setLoading(false);
  }, [report, reviewReport, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<CheckOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Approve Report
      </Button>
      <Modal
        footer={null}
        open={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Approve Holistic Project Progress Report
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <div style={{ fontWeight: 500, fontSize: 14, textAlign: 'center' }}>
              <Text>{`Are you sure you want to approve the ${new Intl.DateTimeFormat(
                locale,
                {
                  month: 'long',
                  year: 'numeric',
                },
              ).format(
                new Date(report.submittedAt),
              )} progress report for the holistic project: ${
                holisticProject && holisticProject.projectName
              }.`}</Text>
            </div>
          </Form.Item>
          <div
            style={{
              marginTop: 32,
              marginBottom: 8,
              marginRight: -8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => setShowingModal(null)}
              htmlType="button"
              disabled={loading}
              style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
            >
              Approve
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveReportBtn;
