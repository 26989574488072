export const READ_ROLES = 'READ_ROLES';
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

// eslint-disable-next-line no-shadow
export const readRolesAction = (roles) => ({
  type: READ_ROLES,
  payload: {
    roles,
  },
});

export const createRoleAction = (role) => ({
  type: CREATE_ROLE,
  payload: {
    role,
  },
});

export const updateRoleAction = (role) => ({
  type: UPDATE_ROLE,
  payload: {
    role,
  },
});

export const deleteRoleAction = (roleId) => ({
  type: DELETE_ROLE,
  payload: {
    roleId,
  },
});

const initialRoles = [];

export function roles(state = initialRoles, action) {
  switch (action.type) {
    case READ_ROLES: {
      const newState = { ...state };
      action.payload.roles.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_ROLE:
      return {
        ...state,
        [action.payload.role._id]: action.payload.role,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        [action.payload.role._id]: action.payload.role,
      };
    case DELETE_ROLE: {
      const { roleId } = action.payload;
      return { ...state, [roleId]: undefined };
    }
    default:
      return state;
  }
}
