import { Form, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import DateFormItem from '@aims/shared/components/DateFormItem';
import ContactSomethingForm from './ContactSomethingForm';

function ContactChangeBdayInnerModal({
  contact,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        birthday: contact.birthday ? moment(contact.birthday) : undefined,
      });
    }
  }, [contact, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { birthday, ...values } = _values;
      handleFinish({
        birthday: birthday ? moment(birthday) : null,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <ContactSomethingForm
      title="Change Birthday"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <DateFormItem
        name="birthday"
        label="Birthday"
        rules={[{ required: 'This field is required' }]}
        disabled={saving}
      />
    </ContactSomethingForm>
  );
}

export default ContactChangeBdayInnerModal;
