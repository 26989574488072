import DateFormItem from '@aims/shared/components/DateFormItem';
import {
  FilterOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import SelectAccount from './components/SelectAccount';
import SelectTransferType from './components/SelectTransferType';

function TransferFilters({
  filtersRef,
  refetch,
  search,
  sortByRef,
  parentFilters,
  sortedBy,
  sortOrder,
  more,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const filterChangeTimeout = useRef();

  const onFiltersChanged = useCallback(
    (_changed, values) => {
      const { search: searchTerm, ...changed } = _changed;
      if (searchTerm != undefined) {
        sortByRef.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        search(searchTerm.toLowerCase());
      } else {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
          ...parentFilters,
        };
        sortByRef.current = [
          {
            key: sortedBy,
            order: sortOrder,
          },
          { key: '_score', order: 'DESC' },
        ];
        clearTimeout(filterChangeTimeout.current);
        filterChangeTimeout.current = setTimeout(() => {
          refetch?.();
        }, 500);
      }
    },
    [
      search,
      refetch,
      sortedBy,
      sortOrder,
      filtersRef,
      sortByRef,
      parentFilters,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        style={{ marginBottom: 16 }}
        onValuesChange={onFiltersChanged}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 16,
          }}
        >
          <Form.Item
            name="search"
            style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
          >
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
          {showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setShowFilters(false);
              }}
            >
              Hide Filters
            </Button>
          )}
          {!showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(true)}
            >
              Show Filters
            </Button>
          )}
          <div style={{ flex: 1 }} />
          {more}
          {refetch && (
            <Button icon={<RedoOutlined />} onClick={refetch}>
              Refresh
            </Button>
          )}
        </div>
        {showFilters && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label="Transaction Type"
                name="type"
                style={{ flex: 1, minWidth: 200, marginRight: 16 }}
              >
                <SelectTransferType allowClear />
              </Form.Item>
              <Form.Item
                label="Region"
                name="regionCode"
                style={{ flex: 1, minWidth: 200, marginRight: 16 }}
              >
                <SelectRegionForAdmin />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label="From Account"
                name="fromAccountId"
                style={{ flex: 1, minWidth: 200, marginRight: 16 }}
              >
                <SelectAccount allowClear />
              </Form.Item>
              <Form.Item
                label="To Account"
                name="toAccountId"
                style={{ flex: 1, minWidth: 200, marginRight: 16 }}
              >
                <SelectAccount allowClear />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <DateFormItem name="from" label="From" showTime allowClear />
              <DateFormItem name="until" label="Until" showTime allowClear />
            </div>
          </>
        )}
      </Form>
    </>
  );
}

export default TransferFilters;
