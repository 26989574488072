import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React from 'react';

function DeleteThingDropdown({
  thing,
  label,
  handleDelete,
  moreItems,
  moreCases,
  btnProps,
}) {
  const items = [
    ...(moreItems || []),
    {
      key: 'delete',
      label,
      icon: <DeleteOutlined />,
    },
  ];
  function onClick({ key }) {
    switch (key) {
      case 'delete':
        handleDelete(thing);
        break;
      default:
        moreCases?.(key);
        break;
    }
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger="click">
      <Button icon={<MoreOutlined />} size="large" type="text" {...btnProps} />
    </Dropdown>
  );
}

export default DeleteThingDropdown;
