import { Divider, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { TrainingEventReportQuestions } from './questions';

const { Title, Text } = Typography;

function TrainingEventReportItems({
  _id,
  report,
  loading,
  form,
  finishSubForm,
  showTitle,
  fieldsToSet,
}) {
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        ...report,
        ...TrainingEventReportQuestions.regionCode.before(report),
        ...TrainingEventReportQuestions.itsmModulesTaught.before(report),
      });
    }
  }, [report, form]);

  finishSubForm.current = useCallback(
    (values) => {
      const modified = { ...values };
      Object.keys(TrainingEventReportQuestions).forEach((k) => {
        if (TrainingEventReportQuestions[k].finish) {
          const result = TrainingEventReportQuestions[k].finish(values);
          Object.entries(result).map(([key, value]) => {
            modified[key] = value;
          });
        }
      });
      return {
        ...modified,
        ...fieldsToSet,
      };
    },
    [fieldsToSet],
  );
  return (
    <>
      {showTitle && (
        <TrainingEventReportQuestions.title.Display
          loading={loading}
          fieldsToSet={fieldsToSet}
        />
      )}
      {report && report.isSubmitted && (
        <TrainingEventReportQuestions.submittedBy.Display report={report} />
      )}
      {report && report.isApproved && (
        <TrainingEventReportQuestions.approvedBy.Display report={report} />
      )}
      <Divider>Event Details</Divider>
      <TrainingEventReportQuestions.regionCode.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.eventDate.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.hostName.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.hostWebsite.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.eventAddress.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <Divider>Training</Divider>
      <TrainingEventReportQuestions.numPeopleTrained.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.itsmModulesTaught.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.trainersTrained.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.newPartnerships.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.numUpgAdoptions.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.newAdoptions.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.miracles.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingEventReportQuestions.publicFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.privateFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingEventReportQuestions.notes.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
    </>
  );
}

export default TrainingEventReportItems;
