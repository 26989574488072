import { Select } from 'antd';
import React from 'react';
import { AdoptionLevels } from '../constants';

function SelectAdoptionLevelA10({ value, onChange, ...props }) {
  return (
    <Select value={value} onChange={onChange} {...props}>
      {Object.values(AdoptionLevels)
        .filter((a) => a.key !== AdoptionLevels.ADOPTER_PRAY.key)
        .map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectAdoptionLevelA10;
