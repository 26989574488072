import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import YesNoModal from '../../../../../components/YesNoModal';

const deleteFileForAdminMutation = gql`
  mutation DeleteFileForAdmin($_id: ID!) {
    deleteFileForAdmin(_id: $_id)
  }
`;

function DeletePhotoModal({ photo, setPhoto, singular }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteFile] = useMutation(deleteFileForAdminMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteFile({
        variables: {
          _id: photo._id,
        },
      });

      notification.success({
        message: 'Deleted',
        description: `${singular} deleted successfully`,
      });
      setTimeout(() => {
        setLoading(false);
        setPhoto(null);
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [photo, deleteFile, setPhoto, singular]);

  const onNo = useCallback(() => {
    if (!loading) {
      setPhoto(null);
    }
  }, [setPhoto, loading]);

  return (
    <YesNoModal
      title={`Delete ${singular}`}
      question={`Are you sure you want to delete this ${singular.toLowerCase()}?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!photo}
      loading={loading}
      error={error}
    />
  );
}

export default DeletePhotoModal;
