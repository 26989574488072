import { Tabs } from 'antd';
import React from 'react';
import UpgQuarterlyReportTypeTab from './UpgQuarterlyReportTypeTab';
import { useParams, useHistory } from 'react-router-dom';

function UpgQuarterlyReportTabs({
  peopleGroupId,
  baseUrl = '/upg-quarterly-reports',
}) {
  const { reportCategory } = useParams();
  const history = useHistory();
  return (
    <Tabs
      defaultActiveKey="approved"
      activeKey={reportCategory}
      onChange={(key) => history.push(`${baseUrl}/${key}`)}
    >
      <Tabs.TabPane tab="Pending" key="pending">
        <UpgQuarterlyReportTypeTab
          reportStatus="PENDING"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Approved" key="approved">
        <UpgQuarterlyReportTypeTab
          reportStatus="APPROVED"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="On Hold" key="hold">
        <UpgQuarterlyReportTypeTab
          reportStatus="HOLD"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Drafts" key="draft">
        <UpgQuarterlyReportTypeTab
          reportStatus="DRAFT"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rejected" key="rejected">
        <UpgQuarterlyReportTypeTab
          reportStatus="REJECTED"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default UpgQuarterlyReportTabs;
