import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import AnyActivity from '../../../components/AnyActivity';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createContactAction,
  deleteContactAction,
  updateContactAction,
} from '../../../redux-store/contacts-store';
import ContactEvents from '../../events/ContactEvents';
import AdminListDonations from '../../pushpay/donations/ListDonations/AdminListDonations';
import AdminListRecurringPayments from '../../pushpay/recurring-payments/RecurringPayments/AdminListRecurringPayments';
import AnyTasks from '../../tasks/AnyTasks';
import DeleteContactModal from '../DeleteContactModal';
import { ContactTypes } from '../constants';
import ContactInfo from './ContactInfo/ContactInfo';
import ContactPushpayTab from './ContactPushpayTab';
import ContactTitle from './ContactTitle';
import FieldWorkers from './FieldWorkers';
import ProjectSponsorshipsTab from './ProjectSponsorshipsTab';
import UpgConnectionsTab from './UpgConnectionsTab';
import UpgPrayerAdoptionsTab from './UpgPrayerAdoptionsTab';
import UpgSponsorshipsTab from './UpgSponsorshipsTab';
import UserTab from './UserTab';
import {
  contactForAdminQuery,
  contactUpdatesForAdminSubscription,
  extractContactForAdmin,
} from './constants';

function ViewContact() {
  const history = useHistory();
  const [deleting, setDeleting] = useState(null);

  const { _id, tab } = useParams();

  useEffect(() => {
    if (!tab) {
      history.replace(`/contact/view/${_id}/dashboard`);
    }
  }, [_id, tab, history]);

  const queryId = 'view-contact';

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: contactUpdatesForAdminSubscription,
    createAction: createContactAction,
    updateAction: updateContactAction,
    deleteAction: deleteContactAction,
    extractSomething: (resp) => resp?.data?.contactUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, error, refetch } = useOneM1({
    variables: { _id },
    query: contactForAdminQuery,
    extract: extractContactForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateContactAction,
    queryId,
  });
  const contact = useSingleSomethingA10('contacts', _id, queryId);

  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  const filters = useMemo(() => {
    return { contactId: _id };
  }, [_id]);

  return (
    <>
      <CSPage title="View Contact" containerStyle={{ maxWidth: 'unset' }}>
        <CSPageHeader
          titleComponent={<ContactTitle contact={contact} queryId={queryId} />}
          backActions={[
            <Link to="/people" key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back to People
              </Button>
            </Link>,
          ]}
          style={{ marginBottom: 16 }}
        />
        <Tabs
          defaultActiveKey="dashboard"
          activeKey={tab}
          onChange={(key) => history.push(`/contact/view/${_id}/${key}`)}
        >
          <Tabs.TabPane tab="Dashboard" key="dashboard">
            <AnyTasks filters={filters} fieldsToSet={filters} title={'Tasks'} />
            <div style={{ height: 32 }} />
            <ContactEvents contactId={_id} title={'Events'} />
            <div style={{ height: 32 }} />
            <AnyActivity filters={filters} fieldsToSet={filters} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Info" key="info">
            <ContactInfo contact={contact} />
          </Tabs.TabPane>
          {contact && contact.isRegionalCoord && (
            <Tabs.TabPane tab="Field Workers" key="field-workers">
              <FieldWorkers contact={contact} queryId={queryId} />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="UPG Connections" key="upg-connections">
            {contact && <UpgConnectionsTab contact={contact} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="UPG Prayer Adoptions" key="upg-adoptions">
            <UpgPrayerAdoptionsTab contactId={_id} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="UPG Sponsorships" key="upg-sponsorships">
            <UpgSponsorshipsTab contactId={_id} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Holistic Project Sponsorships"
            key="holistic-sponsorships"
          >
            <ProjectSponsorshipsTab contactId={_id} />
          </Tabs.TabPane>
          {contact?.isDonor && (
            <Tabs.TabPane tab="Pushpay" key="pushpay">
              <ContactPushpayTab contact={contact} queryId={queryId} />
            </Tabs.TabPane>
          )}
          {contact &&
            contact.pushPayPayerKeys &&
            contact.pushPayPayerKeys.length > 0 && (
              <Tabs.TabPane tab="Donation History" key="donation-history">
                <AdminListDonations contactId={_id} />
              </Tabs.TabPane>
            )}
          {contact &&
            contact.pushPayPayerKeys &&
            contact.pushPayPayerKeys.length > 0 && (
              <Tabs.TabPane tab="Recurring Donations" key="recurring">
                <AdminListRecurringPayments contactId={_id} />
              </Tabs.TabPane>
            )}
          {contact?.contactType === ContactTypes.CONTACT.key && (
            <Tabs.TabPane tab="User" key="user">
              <UserTab contact={contact} queryId={queryId} refetch={refetch} />
            </Tabs.TabPane>
          )}
        </Tabs>
        <div style={{ minHeight: 300 }} />
        <DeleteContactModal setContact={setDeleting} contact={deleting} />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default ViewContact;
