import { useEffect, useState } from 'react';

const useScript = (url, onLoad) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const script = document.createElement('script');

    script.onload = () => {
      if (onLoad) {
        onLoad();
      }
      setLoading(false);
    };
    script.onerror = (err) => {
      console.error(err);
      setError(err.message);
    };
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
  return [error, loading];
};

export default useScript;
