import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Empty, List, Skeleton, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { updatePayerAction } from '../../redux-store/payers-store';
import UnlinkPushpayAccountModal from './UnlinkPushpayAccountModal';
import { extractPayer, ppPayerQuery } from './payers/constants';

const { Title, Text } = Typography;

const queryId = 'linkedAccountItem';

function LinkedAccountItem({ payerId, handleDelete }) {
  const { loading, error } = useOneM1({
    variables: { _id: payerId },
    query: ppPayerQuery,
    extract: extractPayer,
    fetchPolicy: 'network-only',
    updateAction: updatePayerAction,
    queryId,
  });
  const payer = useSingleSomethingA10('payers', payerId, queryId);

  const addressLastLine =
    payer &&
    `${payer.address.city}, ${payer.address.state} ${payer.address.zipOrPostCode}`;
  return (
    <List.Item>
      <Card loading={loading} styles={{ body: { position: 'relative' } }}>
        {payer && (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {payer.fullName && (
                <div>
                  <Text
                    strong
                    style={{ color: sharedSettings.colors.primary, width: 200 }}
                    ellipsis={{ tooltip: payer.fullName }}
                  >
                    {payer.fullName}
                  </Text>
                </div>
              )}
              {payer.emailAddress && (
                <div>
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{ tooltip: payer.emailAddress }}
                  >
                    {payer.emailAddress}
                  </Text>
                </div>
              )}
              {payer.mobileNumber && (
                <div>
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{ tooltip: payer.mobileNumber }}
                  >
                    {payer.mobileNumber}
                  </Text>
                </div>
              )}
              {payer.address && (
                <div>
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{ tooltip: payer.address.line1 }}
                  >
                    {payer.address.line1}
                  </Text>
                  <br />
                  {payer.address.line2 && (
                    <>
                      <Text
                        style={{ width: 200 }}
                        ellipsis={{ tooltip: payer.address.line2 }}
                      >
                        {payer.address.line2}
                      </Text>
                      <br />
                    </>
                  )}
                  {payer.address.city &&
                    payer.address.state &&
                    payer.address.zipOrPostCode && (
                      <Text
                        style={{ width: 200 }}
                        ellipsis={{ tooltip: addressLastLine }}
                      >
                        {addressLastLine}
                      </Text>
                    )}
                </div>
              )}
            </div>
            <div style={{ position: 'absolute', top: 4, right: 4 }}>
              <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => handleDelete(payer)}
              />
            </div>
          </div>
        )}
      </Card>
    </List.Item>
  );
}

function LinkedPushpayAccounts({ contact }) {
  const [deleting, setDeleting] = useState();
  const handleDelete = useCallback(
    async (payer) => {
      setDeleting({
        payer,
        contact,
        queryId,
      });
    },
    [contact],
  );
  return (
    <>
      <Title level={2} style={{ marginBottom: 8 }}>
        Linked Pushpay Accounts
      </Title>
      {!contact ? (
        <List
          dataSource={[0, 0, 0, 0]}
          grid={{ gutter: 16, xs: 1, sm: 1, column: 2 }}
          renderItem={(item) => (
            <List.Item>
              <Card>
                <Skeleton active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <List
          rowKey={(item) => item}
          grid={{ gutter: 16, xs: 1, sm: 1, column: 2 }}
          dataSource={(contact || {}).pushPayPayerKeys || []}
          renderItem={(payerId) => (
            <LinkedAccountItem payerId={payerId} handleDelete={handleDelete} />
          )}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Linked Pushpay Accounts"
              />
            ),
          }}
        />
      )}
      <div style={{ marginBottom: 32 }} />
      <UnlinkPushpayAccountModal
        deleting={deleting}
        setDeleting={setDeleting}
      />
      <style jsx>{`
        .top-actions {
          margin-left: -15px;
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
}

export default LinkedPushpayAccounts;
