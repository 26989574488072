import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import SelectAdoptionListA10 from '../../adoptions/a10-adoptions/SelectAdoptionListA10';
import SelectHolisticProjectListO8 from '../../holistic-projects/ViewHolisticProject/SelectProjectListO8';
import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';

const { Text, Title } = Typography;

function AdmSponsorshipPageA10({
  setPage,
  payment,
  payer,
  contact,
  recurringPayment,
  isRecurring,
  adoptionItems,
  onAddAdoptionItem,
  fundType,
}) {
  const onAdoptionSelected = useCallback((adoption) => {
    onAddAdoptionItem(adoption, 'adoption');
    setPage('summary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onProjectSelected = useCallback((project) => {
    onAddAdoptionItem(project, 'project');
    setPage('summary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adoptionIds = useMemo(
    () => adoptionItems.map((a) => a._id),
    [adoptionItems],
  );
  const extraFilter = useCallback(
    (a) => !adoptionIds.includes(a._id),
    [adoptionIds],
  );

  return (
    <>
      <Title level={3} style={{ marginBottom: 0 }}>
        Associate Donation with Sponsorship(s)
      </Title>
      <Title
        level={5}
        style={{
          marginBottom: 4,
          marginTop: 0,
          color: sharedSettings.colors.text,
        }}
      >
        Select a Sponsorship
      </Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: -16,
          marginLeft: -16,
          marginBottom: 4,
        }}
      >
        <Button
          key="back"
          type="text"
          onClick={() => setPage('contact')}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
        {(isRecurring || adoptionItems.length > 0) && (
          <Button
            key="forward"
            type="text"
            onClick={() => setPage('summary')}
            icon={<ArrowRightOutlined />}
          >
            Forward
          </Button>
        )}
      </div>
      {fundType === 'upg' ? (
        <SelectAdoptionListA10
          onAdoptionSelected={onAdoptionSelected}
          extraFilter={extraFilter}
        />
      ) : (
        <SelectHolisticProjectListO8
          onSelect={onProjectSelected}
          extraFilter={extraFilter}
          parentFilters={{ stage: HolisticProjectStages.FUNDING.key }}
        />
      )}
    </>
  );
}

export default AdmSponsorshipPageA10;
