import { gql } from '@apollo/client';
import { peopleGroupShortAttributes } from '../people-groups/constants';

export const allPrayerRequestsQuery = gql`
  query AllPrayerRequests($first: Int, $after: String, $filters: PrayerRequestFilters) {
    allPrayerRequests(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          desc
          miracleIds
          reportId
          reportType
          peopleGroupId
          peopleGroup ${peopleGroupShortAttributes}
          holisticProjectId
          result
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const PrayerRequestResults = {
  UNKNOWN: {
    key: 'UNKNOWN',
    label: 'Unknown',
    color: 'gray',
  },
  ANSWERED: {
    key: 'ANSWERED',
    label: 'Answered',
    color: 'green',
  },
  PARTIAL: {
    key: 'PARTIAL',
    label: 'Partially Answered',
    color: 'yellow',
  },
};
