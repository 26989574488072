import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Form,
  Input,
  Typography,
  Radio,
  InputNumber,
  List,
  Card,
  Select,
  Divider,
  Space,
} from 'antd';
import React, { useMemo, useState } from 'react';
import AttachmentsFormItem from '../../components/AttachmentsFormItem';
import { generateUuid } from '../../shared/utils';
import useSubmittedBy from '../use-submitted-by';
import useApprovedBy from '../use-approved-by';
import { CommonFiles } from '../ViewCommonItems';
import ReportInfoItem from './ReportInfoItem';
import { ChurchIcon } from '../../shared/icons';
import { YearlyQuarters } from '../quarters';
import SelectContact from '../../contacts/SelectContactForUser';
import sharedSettings from '../../sharedSettings';

const { Title, Text, Paragraph } = Typography;

const FOURTEEN_DAYS = 1000 * 60 * 60 * 24 * 14;

export function ReportLineItem({ question, desc, answer, style }) {
  return (
    <>
      <div className="line" style={style}>
        <div className="line-question" style={{ marginBottom: desc ? 0 : 16 }}>
          <Text style={{ fontWeight: 600, color: 'black' }}>{question}</Text>
        </div>
        {desc && (
          <Paragraph
            style={{ color: sharedSettings.colors.textGray, marginBottom: 16 }}
          >
            {desc}
          </Paragraph>
        )}
        <div className="line-answer">
          <Text>{answer}</Text>
        </div>
      </div>
      <style jsx>{`
        .container {
        }
        .line {
          margin-bottom: 16px;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.backgroundGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          border: 1px solid ${sharedSettings.colors.backgroundGray};
        }
      `}</style>
    </>
  );
}

export function ReportDescriptionAnswer({ question, desc, answer }) {
  return (
    <>
      <div className="line">
        <div className="line-question" style={{ marginBottom: desc ? 0 : 16 }}>
          <Text style={{ fontWeight: 600, color: 'black' }}>{question}</Text>
        </div>
        {desc && (
          <Paragraph
            style={{ color: sharedSettings.colors.textGray, marginBottom: 16 }}
          >
            {desc}
          </Paragraph>
        )}
        <div className="line-answer">{answer}</div>
      </div>
      <style jsx>{`
        .container {
        }
        .line {
          margin-bottom: 16px;
        }
        .line-answer {
          background-color: ${sharedSettings.colors.backgroundGray};
          border-radius: 8px;
          padding: 16px;
          font-size: 14px;
          border: 1px solid ${sharedSettings.colors.backgroundGray};
        }
      `}</style>
    </>
  );
}

export const reportNotes = {
  FormItem: function FormItem({ loading }) {
    return (
      <>
        {/* <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => console.log('form', getFieldsValue())}
        </Form.Item> */}
        <Form.Item
          label="Any other notes, stories or testimonies you'd like to share"
          name="notes"
        >
          <Input.TextArea rows={4} disabled={loading} />
        </Form.Item>
      </>
    );
  },
  before: (report) => {
    return {
      notes: report && report.notes,
    };
  },
  Display: function Display({ report }) {
    return (
      report.notes && (
        <ReportLineItem
          question="Any other notes, stories or testimonies you'd like to share"
          answer={report.notes}
        />
      )
    );
  },
  text: () => "Any other notes, stories or testimonies you'd like to share",
  getAnswer: (report) => report.notes,
};

export const reportPrivateFiles = {
  FormItem: function FormItem({
    loading,
    reportId,
    report,
    form,
    reportType = null,
  }) {
    const existingAttachments = report ? report.privateAttachments : [];
    if (!reportType && report) {
      reportType = report.reportType;
    }
    return (
      <Form.Item
        label="Any photos or documents you'd like to share AIMS only"
        extra="These files will only be shared with AIMS staff"
      >
        <AttachmentsFormItem
          private
          parentType="report"
          parentSubType={reportType}
          parentId={reportId}
          disabled={loading}
          name="privateAttachmentIds"
          form={form}
          existingAttachments={existingAttachments}
        />
      </Form.Item>
    );
  },
  Display: function Display({ report }) {
    return (
      <>
        {report.privateAttachments && !!report.privateAttachments.length && (
          <>
            <Title level={2}>Private Attachments</Title>
            <CommonFiles
              attachments={report.privateAttachments}
              photoTitle="Private Photos"
              fileTitle="Other Private Files"
            />
          </>
        )}
      </>
    );
  },
  text: () => "Any photos or documents you'd like to share AIMS only",
  extra: () => 'These files will only be shared with AIMS staff',
  getAnswer: (report) => (report ? report.privateAttachments : []),
};

export const reportPublicFiles = {
  FormItem: function FormItem({
    loading,
    reportId,
    report,
    form,
    reportType = null,
  }) {
    const existingAttachments = report ? report.publicAttachments : [];
    if (!reportType && report) {
      reportType = report.reportType;
    }
    return (
      <Form.Item label="Any photos or documents you'd like to share with your supporters">
        <AttachmentsFormItem
          parentType="report"
          parentSubType={reportType}
          parentId={reportId}
          disabled={loading}
          name="publicAttachmentIds"
          form={form}
          existingAttachments={existingAttachments}
        />
      </Form.Item>
    );
  },
  Display: function Display({ report }) {
    return (
      <>
        {report.publicAttachments && !!report.publicAttachments.length && (
          <>
            <Title level={2}>Public Attachments</Title>
            <CommonFiles
              attachments={report.publicAttachments}
              photoTitle="Public Photos"
              fileTitle="Other Public Files"
            />
          </>
        )}
      </>
    );
  },
  text: () =>
    "Any photos or documents you'd like to share with your supporters",
  getAnswer: (report) => (report ? report.publicAttachments : []),
};

export const reportPrayerRequests = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item label="Prayer Requests">
        <Form.List name="prayerRequests">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} noStyle>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Form.Item name="_id" noStyle>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      label={`Prayer Request ${index + 1}`}
                      name={[field.name, 'desc']}
                      style={{ width: '100%' }}
                    >
                      <Input.TextArea disabled={loading} />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add prayer request
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    );
  },
  finish: (values) => {
    return {
      prayerRequests: values.prayerRequests
        ? values.prayerRequests
            .filter((m) => m.desc)
            .map((c) => {
              return {
                _id: c._id || generateUuid(),
                desc: c.desc,
              };
            })
        : [],
    };
  },
  text: () => 'Prayer Requests',
  getAnswer: () => null,
  Display: function Display({ report }) {
    return (
      <>
        <Title level={2} style={{ marginTop: 32 }}>
          Prayer Requests
        </Title>
        {report.prayerRequests &&
          report.prayerRequests.map((prayerRequest, index) => (
            <div key={index} style={{ marginBottom: 24 }}>
              <div
                style={{
                  textAlign: 'center',
                  borderRadius: 8,
                  padding: 12,
                  fontSize: 14,
                  backgroundColor: sharedSettings.colors.borderGray,
                }}
              >
                {prayerRequest.desc}
              </div>
            </div>
          ))}
        {(!report.prayerRequests || !report.prayerRequests.length) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No prayer requests recorded in the Last 3 Months"
          />
        )}
      </>
    );
  },
};

export const reportMiracles = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item label="Any miracles or testimonies that occurred during the last 3 months">
        <Form.List name="miracles">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} noStyle>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Form.Item name="_id" noStyle>
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      label={`Testimony ${index + 1}`}
                      name={[field.name, 'desc']}
                      style={{ width: '100%' }}
                    >
                      <Input.TextArea disabled={loading} />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add testimony
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    );
  },
  finish: (values) => {
    return {
      miracles: values.miracles
        ? values.miracles
            .filter((m) => m.desc)
            .map((c) => {
              return {
                _id: c._id || generateUuid(),
                desc: c.desc,
              };
            })
        : [],
    };
  },
  Display: function Display({ report }) {
    return (
      <>
        <Title level={2} style={{ marginTop: 32 }}>
          Miracles &amp; Testimonies
        </Title>
        {report.miracles &&
          report.miracles.map((miracle, index) => (
            <div key={index} style={{ marginBottom: 24 }}>
              <div
                style={{
                  textAlign: 'center',
                  borderRadius: 8,
                  padding: 12,
                  fontSize: 14,
                  backgroundColor: sharedSettings.colors.borderGray,
                }}
              >
                {miracle.desc}
              </div>
            </div>
          ))}
        {(!report.miracles || !report.miracles.length) && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No testimonies recorded in the Last 3 Months"
          />
        )}
      </>
    );
  },
};

export const reportSubmittedBy = {
  Display: function Display({ report }) {
    const submittedBy = useSubmittedBy(report);
    return (
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <Text style={{ fontSize: 15 }}>{submittedBy}</Text>
      </div>
    );
  },
};

export const reportApprovedBy = {
  Display: function Display({ report }) {
    const approvedBy = useApprovedBy(report);
    return (
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <Text style={{ fontSize: 15 }}>{approvedBy}</Text>
      </div>
    );
  },
};

export const reportOnBehalfOf = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item
        label="Are you filling this out on behalf of someone else?"
        name="onBehalfOf"
        rules={[{ required: true, message: 'Please answer yes or no' }]}
      >
        <Radio.Group disabled={loading}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
    );
  },
  before: (report) => {
    return {
      onBehalfOf: !!report.onBehalfOfId,
    };
  },
};

export const reportOnBehalfOfName = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item
        shouldUpdate={(prev, curr) => prev.onBehalfOf !== curr.onBehalfOf}
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('onBehalfOf') && (
            <>
              <Form.Item
                label="What is their name?"
                name="onBehalfOfName"
                rules={[{ required: true, message: 'Please enter a name' }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    );
  },
  finish: (values) => {
    return {
      onBehalfOf: undefined,
      onBehalfOfName: values.onBehalfOf ? values.onBehalfOfName : undefined,
    };
  },
  text: () => 'What is their name?',
  getAnswer: (report) => report.onBehalfOfName,
};

export const reportOnBehalfOfContact = {
  FormItem: function FormItem({ loading, fieldsToSet }) {
    const { peopleGroupId } = fieldsToSet;
    return (
      <Form.Item
        shouldUpdate={(prev, curr) => prev.onBehalfOf !== curr.onBehalfOf}
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('onBehalfOf') && (
            <>
              <Form.Item
                label="Submitting on behalf of"
                name="onBehalfOfContactId"
                rules={[
                  { required: true, message: 'Please select a field worker' },
                ]}
              >
                <SelectContact
                  disabled={loading}
                  filters={{
                    isFieldWorker: true,
                    contactType: 'CONTACT',
                    peopleGroupId,
                  }}
                />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    );
  },
  finish: (values) => {
    return {
      onBehalfOf: undefined,
      contactId: values.onBehalfOf ? values.onBehalfOfContactId : undefined,
      onBehalfOfId: values.onBehalfOf ? values.onBehalfOfContactId : undefined,
      onBehalfOfContactId: undefined,
    };
  },
  before: (report) => {
    return {
      onBehalfOfContactId: report.onBehalfOfId,
    };
  },
  text: () => 'Field Worker',
  getAnswer: (report) => report.contact.fullName,
};

export const reportChurchesPlanted = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item label="New churches planted in the last 3 months">
        <Form.List name="churchesPlanted">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} noStyle>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 4,
                        flex: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Form.Item
                        label="Village Name"
                        name={[field.name, 'villageName']}
                        style={{ marginRight: 16, flex: 3, minWidth: 200 }}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input disabled={loading} />
                      </Form.Item>
                      <Form.Item
                        label="Country"
                        name={[field.name, 'country']}
                        style={{ marginRight: 16, flex: 3, minWidth: 200 }}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input disabled={loading} />
                      </Form.Item>
                      <Form.Item
                        label="Number of Churches"
                        name={[field.name, 'numChurches']}
                        style={{ marginRight: 16, flex: 2, minWidth: 200 }}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <InputNumber
                          disabled={loading}
                          min={0}
                          step={1}
                          style={{ width: '100%', maxWidth: 200 }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Number of Believers"
                        name={[field.name, 'numBelievers']}
                        style={{ flex: 2, minWidth: 200 }}
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <InputNumber
                          disabled={loading}
                          min={0}
                          step={1}
                          style={{ width: '100%', maxWidth: 200 }}
                        />
                      </Form.Item>
                    </div>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                      style={{ marginLeft: 8 }}
                    />
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={loading}
                >
                  Add churches
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    );
  },
  finish: (values) => {
    return {
      numChurchesPlanted: values.churchesPlanted
        ? values.churchesPlanted.reduce((prev, curr) => {
            return prev + curr.numChurches;
          }, 0)
        : 0,
      churchesPlanted: values.churchesPlanted
        ? values.churchesPlanted.map((c) => {
            const { __typename, ...p } = c;
            return p;
          })
        : [],
    };
  },
  text: () => 'Churches Planted',
  getAnswer: () => null,
  Display: function Display({ report }) {
    return (
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 16 }}>
          <Title level={5}>Churches Planted</Title>
        </div>
        <List
          dataSource={report.churchesPlanted || []}
          grid={{ column: 3, sm: 1, lg: 2, gutter: 20 }}
          renderItem={(item) => (
            <List.Item>
              <Card style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      fontSize: 48,
                      marginRight: 24,
                      alignSelf: 'center',
                    }}
                  >
                    <ChurchIcon />
                  </div>
                  <div style={{ flex: 1 }}>
                    <ReportInfoItem label="Country" value={item.country} />
                    <ReportInfoItem label="Village" value={item.villageName} />
                    <ReportInfoItem label="Churches" value={item.numChurches} />
                    <ReportInfoItem
                      label="Believers"
                      value={item.numBelievers}
                    />
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Churches Planted in the Last 3 Months"
              />
            ),
          }}
        />
      </div>
    );
  },
};

export const reportNumHeardForFirstTime = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item
        label="Number of people who heard the gospel for the first time in the last 3 months"
        name="numHeardForFirstTime"
        style={{ flex: 2, minWidth: 100 }}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber disabled={loading} min={0} step={1} />
      </Form.Item>
    );
  },
  text: () =>
    'Number of people who heard the gospel for the first time in the last 3 months',
  getAnswer: (report) => report.numHeardForFirstTime,
  Display: function Display({ report }) {
    return (
      <ReportLineItem
        question="Number of people who heard the gospel for the first time in the last 3 months"
        answer={report.numHeardForFirstTime}
      />
    );
  },
};

export const reportNumSalvations = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item
        label="Number of salvations during the last 3 months"
        name="numSalvations"
        style={{ flex: 2, minWidth: 100 }}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber disabled={loading} min={0} step={1} />
      </Form.Item>
    );
  },
  Display: function Display({ report }) {
    return (
      <ReportLineItem
        question="Number of salvations during the last 3 months"
        answer={report.numSalvations}
      />
    );
  },
};

export const reportNumWaterBaptisms = {
  FormItem: function FormItem({ loading }) {
    return (
      <Form.Item
        label="Number of water baptisms during the last 3 months"
        name="numWaterBaptisms"
        style={{ flex: 2, minWidth: 100 }}
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <InputNumber disabled={loading} min={0} step={1} />
      </Form.Item>
    );
  },
  Display: function Display({ report }) {
    return (
      <ReportLineItem
        question="Number of water baptisms during the last 3 months"
        answer={report.numWaterBaptisms}
      />
    );
  },
};

export const reportResultNumbers = {
  Display: function Display({ report }) {
    return (
      <div className="line" style={{}}>
        <ReportInfoItem
          label="Heard the gospel for the first time"
          value={report.numHeardForFirstTime}
        />
        <ReportInfoItem label="Salvations" value={report.numSalvations} />
        <ReportInfoItem
          label="Water Baptisms"
          value={report.numWaterBaptisms}
        />
      </div>
    );
  },
};

export function thisQuarter(date) {
  const newDate = new Date(date.getTime() - FOURTEEN_DAYS);
  const thisMonth = newDate.getMonth();
  return ['Q1', 'Q2', 'Q3', 'Q4'][Math.floor(thisMonth / 3)];
}

export const reportQuarter = {
  FormItem: function FormItem({ loading }) {
    const [numShown, setNumShown] = useState(4);

    const [thisYear, quarters] = useMemo(() => {
      const _thisYear = new Date().getFullYear();
      const years = [];
      for (let i = -2; i < 1; i += 1) {
        years.push(_thisYear + i);
      }
      const _quarters = [];
      years.forEach((year) => {
        ['Q1', 'Q2', 'Q3', 'Q4'].forEach((qx) => {
          _quarters.push({
            key: `${year}.${qx}`,
            label: `${YearlyQuarters[qx].label} ${year}`,
          });
        });
      });
      return [_thisYear, _quarters];
    }, []);

    const itemsShown = useMemo(() => {
      return Object.values(quarters)
        .slice(-numShown)
        .map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ));
    }, [quarters, numShown]);

    const date = new Date();

    const showMore = () => {
      setNumShown(numShown + 2);
    };
    return (
      <Form.Item
        label="For which quarter are you submitting this report?"
        name="quarter"
        rules={[{ required: true, message: 'Please select a quarter' }]}
        initialValue={`${thisYear}.${thisQuarter(date)}`}
      >
        <Select
          disabled={loading}
          dropdownRender={(menu) => (
            <>
              {numShown < quarters.length && (
                <Space align="center" style={{ padding: '0 8px 4px' }}>
                  <Typography.Link
                    onClick={showMore}
                    style={{ minWidth: '100%', whiteSpace: 'nowrap' }}
                  >
                    Show More
                  </Typography.Link>
                  <Divider style={{ margin: '8px 0' }} />
                </Space>
              )}
              {menu}
            </>
          )}
        >
          {itemsShown}
        </Select>
      </Form.Item>
    );
  },
  Display: function Display({ report }) {
    const { quarter } = report;
    const thisYear = new Date().getFullYear();
    const [year, qx] = quarter ? quarter.split('.') : [thisYear, 'Q1'];
    return (
      <ReportLineItem
        question="For which quarter are you submitting this report?"
        answer={`${YearlyQuarters[qx].label} ${year}`}
      />
    );
  },
};

export function ExchangeRateInput({
  value,
  onChanged,
  localCurrency,
  ...props
}) {
  return (
    <div>
      <Text>{`$1 = `}</Text>
      <InputNumber
        value={value}
        onChanged={onChanged}
        min={0}
        step={0.1}
        style={{ width: 200 }}
        {...props}
      />
      <Text style={{ marginLeft: 8 }}>{`${
        localCurrency || 'Local Currency'
      }`}</Text>
    </div>
  );
}
