import { gql, useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../SelectContact';
import { ContactTypes } from '../../constants';
import ContactSomethingForm from './ContactSomethingForm';

const { Text, Paragraph, Title } = Typography;

export const addContactToHouseholdMutation = gql`
  mutation AddContactToHousehold($contactId: ID!, $householdId: ID!) {
    addContactToHousehold(contactId: $contactId, householdId: $householdId)
  }
`;

function AddContactToHouseholdModal({ household, adding, onDone, onCancel }) {
  const [saving, setSaving] = useState(false);
  const [addContactToHousehold] = useMutation(addContactToHouseholdMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { contactId } = values;
        await addContactToHousehold({
          variables: {
            contactId: contactId,
            householdId: household._id,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Contact successfully added to household',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error adding contact to household',
        });
      }
      setSaving(false);
    },
    [household, addContactToHousehold, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [adding, form]);

  return (
    <Modal
      open={adding}
      closable={false}
      destroyOnClose={true}
      maskClosable
      footer={null}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 8 } }}
    >
      <ContactSomethingForm
        title="Add Household Member"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item
          label="Contact"
          name="contactId"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <SelectContact
            loading={saving}
            disabled={saving}
            filters={{
              contactType: ContactTypes.CONTACT.key,
            }}
            placeholder="Select a contact ..."
          />
        </Form.Item>
      </ContactSomethingForm>
    </Modal>
  );
}

export default AddContactToHouseholdModal;
