export const FileTypes = {
  H_PHOTO: {
    key: 'H_PHOTO',
    label: 'Holistic Project Photo',
  },
  H_BANNER: {
    key: 'H_BANNER',
    label: 'Holistic Project Banner Image',
  },
  U_PROFILE: {
    key: 'U_PROFILE',
    label: 'User Profile',
  },
};
