import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, List, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../contacts/SelectContact';
import AssociatedPeopleInfo from '../../../components/AssociatedPeopleInfo';

const { Text, Title } = Typography;

function EditContacts({ onSubmit, event }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    let contacts = [];
    if (event.contactIds) {
      contacts = event.contactIds.map((c) => ({
        contactId: c,
      }));
    }
    form.setFieldsValue({
      contacts,
    });
  }, [form, event]);

  const onFinish = useCallback(
    async (_values) => {
      setError(null);
      setLoading(true);
      try {
        const { contacts: _contacts } = _values;
        await onSubmit({
          contactIds: _contacts.map((c) => c.contactId),
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <>
      <map
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
          }}
        >
          <Title level={4} style={{ marginRight: 16 }}>
            People
          </Title>
          <Text>Click to edit</Text>
        </div>
        {!editing && event.contactIds && event.contactIds.length > 0 && (
          <List
            className="associatedList"
            dataSource={event.contactIds.map((contactId) => ({
              id: contactId,
            }))}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(item) => <AssociatedPeopleInfo item={item} />}
          />
        )}
      </map>
      {editing && (
        <Form onFinish={onFinish} form={form} layout="vertical">
          <Form.Item>
            <Form.List name="contacts">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item key={field.key}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'contactId']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a contact',
                            },
                          ]}
                          noStyle
                        >
                          <SelectContact disabled={loading} />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                          style={{ marginLeft: 8 }}
                        />
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add contact, household or organization
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <div style={{ height: 24 }} />
      <style jsx>{`
        .tags {
          min-height: 36px;
        }
        .contact {
          margin-bottom: 8px;
        }
      `}</style>
    </>
  );
}

export default EditContacts;
