import React, { useMemo } from 'react';
import { PieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { AimsStatusColors, AimsStatuses } from './constants';

function arrayRotate(arr, reverse) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
}

function PeopleGroupPieChart({ peopleGroup, style, labelStyle }) {
  const pieChartData = useMemo(() => {
    if (peopleGroup) {
      const aimsStatues = [];
      [
        'aimsStatusAdopted',
        'aimsStatusCoordinator',
        'aimsStatusFieldWorker',
        'aimsStatusFunding',
        'aimsStatusHolisticProject',
        'aimsStatusSalvations',
        'aimsStatusChurches',
        'aimsStatusMovements',
      ].forEach((prop) => {
        aimsStatues.push({
          ...AimsStatuses[prop],
          sort: peopleGroup[prop]
            ? AimsStatuses[prop].sort
            : AimsStatuses[prop].sort + 100,
          color: peopleGroup[prop] ? AimsStatuses[prop].color : '#eaeaea',
        });
      });

      const result = Object.values(aimsStatues)
        .sort((a, b) => a.sort - b.sort)
        .map((s) => ({
          title: s.label,
          color: s.color,
          value: 100 / aimsStatues.length,
        }));
      for (let i = 0; i < result.length; i += 1) {
        if (result[i].color != '#eaeaea') {
          result[i].color = AimsStatusColors[i];
        }
      }
      arrayRotate(result);
      arrayRotate(result);
      return result;
    }
    return [];
  }, [peopleGroup]);

  return (
    <PieChart
      style={{
        height: 400,
        marginBottom: 32,
        ...style,
      }}
      data={pieChartData}
      radius={pieChartDefaultProps.radius - 8}
      lineWidth={80}
      segmentsStyle={{
        transition: 'stroke .3s',
        cursor: 'pointer',
      }}
      segmentsShift={(index) => 2}
      animate
      label={({ dataEntry }) => dataEntry.title}
      labelPosition={112}
      labelStyle={(index) => ({
        fontSize: '4px',
        fill: `#333333`,
        fontStyle: 'italic',
        ...labelStyle,
      })}
    />
  );
}

export default PeopleGroupPieChart;
