export const READ_PEOPLE_GROUPS = 'READ_PEOPLE_GROUPS';
export const CREATE_PEOPLE_GROUP = 'CREATE_PEOPLE_GROUP';
export const UPDATE_PEOPLE_GROUP = 'UPDATE_PEOPLE_GROUP';
export const DELETE_PEOPLE_GROUP = 'DELETE_PEOPLE_GROUP';

// eslint-disable-next-line no-shadow
export const readPeopleGroupsAction = (peopleGroups, queryId = 'default') => ({
  type: READ_PEOPLE_GROUPS,
  payload: {
    peopleGroups,
    queryId,
  },
});

export const createPeopleGroupAction = (peopleGroup, queryId = 'default') => ({
  type: CREATE_PEOPLE_GROUP,
  payload: {
    peopleGroup,
    queryId,
  },
});

export const updatePeopleGroupAction = (peopleGroup, queryId = 'default') => ({
  type: UPDATE_PEOPLE_GROUP,
  payload: {
    peopleGroup,
    queryId,
  },
});

export const deletePeopleGroupAction = (
  peopleGroupId,
  queryId = 'default',
) => ({
  type: DELETE_PEOPLE_GROUP,
  payload: {
    peopleGroupId,
    queryId,
  },
});

const initialPeopleGroups = { default: {} };

export function peopleGroups(state = initialPeopleGroups, action) {
  switch (action.type) {
    case READ_PEOPLE_GROUPS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.peopleGroups.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_PEOPLE_GROUP:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.peopleGroup._id]: action.payload.peopleGroup,
        },
      };
    case UPDATE_PEOPLE_GROUP: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.peopleGroup._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.peopleGroup._id]: {
            ...current,
            ...action.payload.peopleGroup,
          },
        },
      };
    }
    case DELETE_PEOPLE_GROUP: {
      const { peopleGroupId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [peopleGroupId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
