import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteTaskAction } from '../../redux-store/tasks-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteTaskMutation = gql`
  mutation deleteTask($_id: ID!) {
    deleteTask(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteTaskModal({ task, setTask, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteTask] = useMutation(deleteTaskMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteTask({
        variables: {
          _id: task._id,
        },
      });
      dispatch(deleteTaskAction(task._id));
      notification.success({
        message: 'Deleted',
        description: 'Task deleted successfully',
      });
      setTimeout(() => {
        setTask(null);
        if (onComplete) {
          onComplete();
        } else {
          history.goBack();
        }
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [task, deleteTask, setTask, dispatch, history, onComplete]);

  const onNo = useCallback(() => {
    if (!loading) {
      setTask(null);
    }
  }, [setTask, loading]);

  return (
    <YesNoModal
      title="Delete Task"
      question={`Are you sure you want to delete this task (${
        task && task.title
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!task}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteTaskModal;
