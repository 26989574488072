import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  DeleteOutlined,
  GoogleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Dropdown,
  Menu,
  Skeleton,
  Typography,
  notification,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { updateTaskAction } from '../../../redux-store/tasks-store';
import AddTaskToGoogleModal from '../AddTaskToGoogleModal';
import DeleteTaskModal from '../DeleteTaskModal';
import {
  getHumanTimeLeft,
  getHumanTimeUntil,
  getUrgencyColor,
  updateTaskMutation,
} from '../EditTask/constants';
import SubscribeToTaskButton from '../SubscribeToTaskButton';
import { extractTaskForAdmin, taskForAdminQuery } from '../constants';
import AssociatedPeople from './AssociatedPeople';
import AssociatedPeopleGroup from './AssociatedPeopleGroup';
import EditHolisticProject from './EditHolisticProject';
import EditTaskAssignees from './EditTaskAssignees';
import EditTaskDescription from './EditTaskDescription';
import EditTaskImportance from './EditTaskImportance';
import EditTaskNotes from './EditTaskNotes/EditTaskNotes';
import EditTaskSchedule from './EditTaskSchedule';
import EditTaskStatus from './EditTaskStatus';
import EditTaskTags from './EditTaskTags';
import EditTaskTitle from './EditTaskTitle';

const { Title } = Typography;

const ViewTaskModal = ({ visible, closeModal, queryId, refetch }) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const handleAddToGoogle = useRef();
  const handleUpdateInGoogle = useRef();

  const _id = visible;
  const fetchResult = useOneM1({
    variables: { _id },
    query: taskForAdminQuery,
    extract: extractTaskForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateTaskAction,
    queryId,
    skip: !_id || !refetch,
  });
  const { loading, error, refetch: refetch2 } = fetchResult;
  const task = useSingleSomethingA10('tasks', _id, queryId);

  const [updateTask] = useMutation(updateTaskMutation);

  const saveTask = useCallback(
    async (values) => {
      try {
        setSaving(true);
        await updateTask({
          variables: {
            task: {
              _id: _id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Task updated successfully',
        });
        if (refetch) {
          refetch();
          refetch2();
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
      setSaving(false);
    },
    [_id, updateTask, refetch, refetch2],
  );

  const timeLeft = useMemo(() => {
    if (task && task.windowStop) {
      const timeLeftMs = new Date(task.windowStop) - new Date();
      return {
        timeLeft: getHumanTimeLeft(timeLeftMs),
        color: getUrgencyColor(timeLeftMs),
      };
    }
    return null;
  }, [task]);
  const timeUntil = useMemo(() => {
    if (task && task.windowStop) {
      const timeUntilMs = new Date(task.windowStart) - new Date();
      return {
        timeUntil: getHumanTimeUntil(timeUntilMs),
        color: sharedSettings.colors.textGray,
      };
    }
    return null;
  }, [task]);

  return (
    <>
      <Modal
        visible={visible}
        closable={false}
        destroyOnClose={true}
        maskClosable={!saving}
        onCancel={closeModal}
        footer={null}
        width={600}
      >
        <div style={{ height: 36 }} />
        {task ? (
          <>
            <EditTaskTitle task={task} onSubmit={saveTask} />
            <div className="status-row">
              <div className="status-row-item">
                <EditTaskStatus onSubmit={saveTask} task={task} />
              </div>
              <div className="status-row-item">
                <EditTaskImportance onSubmit={saveTask} task={task} />
              </div>
              {timeLeft && (
                <div className="status-row-item">
                  <div className="status-row-title">
                    <Title level={4}>Urgency</Title>
                  </div>
                  <div
                    style={{
                      padding: '8px 16px',
                      border: '1px solid',
                      borderRadius: 4,
                      display: 'flex',
                      justifyContent: 'center',
                      color: timeLeft.color,
                      borderColor: timeLeft.color,
                    }}
                  >
                    {timeLeft.timeLeft}
                  </div>
                </div>
              )}
            </div>
            <EditTaskSchedule
              onSubmit={saveTask}
              task={task}
              timeLeft={timeLeft}
              timeUntil={timeUntil}
            />
            <div className="tags-row">
              <div className="tags-left">
                <EditTaskTags onSubmit={saveTask} task={task} />
              </div>
              <div className="tags-right">
                <EditTaskAssignees onSubmit={saveTask} task={task} />
              </div>
            </div>
            <div>
              <AssociatedPeople onSubmit={saveTask} task={task} />
              <AssociatedPeopleGroup onSubmit={saveTask} task={task} />
            </div>
            <EditHolisticProject onSubmit={saveTask} task={task} />
            <EditTaskDescription task={task} onSubmit={saveTask} />
            <EditTaskNotes task={task} />
          </>
        ) : (
          <Skeleton avatar active />
        )}
        <div className="extra-opts">
          <SubscribeToTaskButton taskId={_id} queryId={queryId} />
          <Dropdown
            overlay={
              <Menu>
                {task && !task.googleId && (
                  <Menu.Item
                    icon={<GoogleOutlined />}
                    onClick={() => handleAddToGoogle.current(_id)}
                  >
                    Add to Google Calendar
                  </Menu.Item>
                )}
                {task && task.googleId && (
                  <Menu.Item
                    icon={<GoogleOutlined />}
                    onClick={() => handleUpdateInGoogle.current(_id)}
                  >
                    Update Google Task
                  </Menu.Item>
                )}
                <Menu.Divider />
                <Menu.Item
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleting(task)}
                >
                  Delete Task
                </Menu.Item>
              </Menu>
            }
            trigger="click"
          >
            <Button size="large" type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </Modal>
      <DeleteTaskModal
        setTask={setDeleting}
        task={deleting}
        onComplete={() => {
          setDeleting(null);
          closeModal();
        }}
      />
      <AddTaskToGoogleModal
        handleAddToGoogle={handleAddToGoogle}
        handleUpdateInGoogle={handleUpdateInGoogle}
        queryId={queryId}
      />
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
        .status-row {
          display: flex;
          justify-content: space-between;
          margin-left: -8px;
          margin-right: -8px;
          margin-bottom: 32px;
          margin-top: 24px;
        }
        .status-row-item {
          text-align: center;
          margin-left: 8px;
          margin-right: 8px;
          width: 33%;
        }
        .tags-row {
          display: flex;
          margin-left: -16px;
          margin-right: -16px;
          margin-bottom: 16px;
        }
        .tags-left {
          flex: 1;
          margin-left: 16px;
          margin-right: 16px;
        }
        .tags-right {
          flex: 1;
          margin-left: 16px;
          margin-right: 16px;
        }
        .desc-row {
          margin-bottom: 32px;
        }
        .extra-opts {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ViewTaskModal;
