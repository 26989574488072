import PhotoCircle from '@aims/shared/components/PhotoCircle';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { formatPhoneNumberForDisplay } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import settings from '../../settings';
import AllUsersFilters from './AllUsersFilters';
import { allUsersForAdminQuery, extractUsersForAdmin } from './constants';

const { Text } = Typography;

function AllUsersTable({ filters: parentFilters }) {
  const [sortedBy, setSortedBy] = useState('name.keyword');
  const [sortOrder, setSortOrder] = useState('ASC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    ...parentFilters,
  });

  const {
    error,
    loading,
    data: users,
    search: userSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUsersForAdminQuery,
    extract: extractUsersForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'AllUsersTable',
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  return (
    <>
      <AllUsersFilters
        filters={filters}
        refetch={refetch}
        userSearch={userSearch}
        loading={loading}
        sortBy={sortBy}
      />
      <Table
        dataSource={users}
        loading={!users.length && loading}
        pagination={{ ...pagination, total: users.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
      >
        <Column
          title="Name"
          dataIndex="name"
          width={300}
          render={(text, record) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PhotoCircle
                  photoUrl={record.primaryThumbnailUrl}
                  styles={{
                    container: {
                      height: 48,
                      width: 48,
                      marginRight: 12,
                      flexShrink: 0,
                    },
                    placeholder: {
                      backgroundColor: sharedSettings.colors.yellow,
                    },
                  }}
                  name={record.name}
                />
                <Text style={{ whiteSpace: 'nowrap' }}>{record.name}</Text>
              </div>
            );
          }}
        />
        <Column
          title="Roles"
          dataIndex="roles"
          width={300}
          render={(text, record) => {
            return (
              <>
                {record.roles.map((role) => (
                  <Tag
                    color={sharedSettings.colors.primary}
                    key={role._id}
                    style={{ marginBottom: 4 }}
                  >
                    {role.label}
                  </Tag>
                ))}
              </>
            );
          }}
        />
        <Column
          title="Region"
          dataIndex="regionName"
          key="regionName"
          width={200}
        />
        <Column title="Username" dataIndex="username" key="username" />
        <Column title="Email" dataIndex="email" key="email" />
        <Column
          title="Phone"
          dataIndex="phone"
          width={200}
          render={(text, record) => {
            return record.phone && formatPhoneNumberForDisplay(record.phone);
          }}
        />
        <Column
          title="Contact"
          dataIndex="contactId"
          width={300}
          render={(text, record) => {
            return (
              record.contactId && (
                <Link to={`/contact/view/${record.contact?._id}/dashboard`}>
                  {record.contact?.fullName}
                </Link>
              )
            );
          }}
        />
        <Column
          title="Last Sign-In"
          dataIndex="createdAt"
          width={200}
          render={(text, record) => {
            return (
              record.lastSignedInAt &&
              dateFormat.format(new Date(record.lastSignedInAt))
            );
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link to={`/user/view/${record._id}/info`}>
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
    </>
  );
}

export default AllUsersTable;
