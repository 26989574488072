import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Empty, List, Tooltip, Typography, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { updatePeopleGroupAction } from '../../../redux-store/people-groups-store';
import settings from '../../../settings';
import {
  extractPeopleGroupForAdmin,
  updatePeopleGroupPhotosMutation,
} from '../constants';
import DeletePeopleGroupPhotoModal from './DeletePeopleGroupPhotoModal';
import UploadPhotoButton from './UploadPhotoButton';

const upgPhotosQuery = gql`
  query PrivatePeopleGroupPhotos($_id: ID!) {
    peopleGroupForAdmin(_id: $_id) {
      _id
      photoIds
      primaryPhotoId
      primaryThumbnailUrl
      photos {
        _id
        desc
        s3Key
        size
        filename
        contentType
        tags
        url
        uploaded
        processed
        thumbnail {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        images {
          s3Key
          size
          filename
          contentType
          url
          height
          width
          sizeCode
        }
        attachedTo
        createdAt
        updatedAt
      }
    }
  }
`;

const { Text } = Typography;

function PhotoCard({ photo, makePrimary, setDeleting, loading }) {
  return (
    <div>
      <div className="actions">
        {!photo.isJp ? (
          <Tooltip title="Delete Photo">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => setDeleting(photo)}
              style={{ marginRight: -8, marginBottom: 0 }}
            />
          </Tooltip>
        ) : (
          <div style={{ height: 48 }} />
        )}
      </div>
      <div className="container">
        <div className="card-default">
          <div className="card-default-letter">
            {photo.name && photo.name[0].toUpperCase()}
          </div>
        </div>
        <div
          className="card-image"
          style={{ backgroundImage: `url("${photo.photoUrl}")` }}
        />
      </div>
      <div className="primary">
        {photo.isPrimary ? (
          <Text style={{ color: sharedSettings.colors.success }}>
            <CheckOutlined style={{ marginRight: 8 }} />
            Primary
          </Text>
        ) : (
          <Button
            loading={loading}
            onClick={() => {
              if (!photo.isJp) {
                makePrimary(photo._id);
              } else {
                makePrimary(null);
              }
            }}
          >
            Make Primary
          </Button>
        )}
      </div>
      <style jsx>{`
        .actions {
          display: flex;
          justify-content: flex-end;
        }
        .container {
          display: flex;
          justify-content: center;
          position: relative;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${sharedSettings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
        .primary {
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
      `}</style>
    </div>
  );
}

function PeopleGroupPhotos({ peopleGroupId, queryId }) {
  const [deleting, setDeleting] = useState(null);

  const { refetch, loading, error } = useOneM1({
    variables: { _id: peopleGroupId },
    query: upgPhotosQuery,
    extract: extractPeopleGroupForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updatePeopleGroupAction,
    queryId,
  });
  const peopleGroup = useSingleSomethingA10(
    'peopleGroups',
    peopleGroupId,
    queryId,
  );

  const photos = useMemo(() => {
    if (peopleGroup) {
      const _photos = [];
      if (peopleGroup.photos) {
        peopleGroup.photos.forEach((p) => {
          const photo = p.images && p.images.find((i) => i.sizeCode === 'SM');
          let url = photo && photo.url;
          if (!url) {
            url = p && p.thumbnail && p.thumbnail.url;
          }
          if (!url) {
            url = p && p.url;
          }
          _photos.push({
            _id: p._id,
            isJp: false,
            isPrimary: peopleGroup.primaryPhotoId === p._id,
            photoUrl: url,
            name: peopleGroup.nameAcrossCountries,
          });
        });
      }
      return _photos;
    }
    return [];
  }, [peopleGroup]);

  const [updatePeopleGroup] = useMutation(updatePeopleGroupPhotosMutation);
  const [savingPrimary, setSavingPrimary] = useState({});

  const handleMakePrimary = useCallback(
    async (photoId) => {
      setSavingPrimary({ ...loading, [photoId]: true });
      try {
        await updatePeopleGroup({
          variables: {
            peopleGroup: {
              _id: peopleGroup._id,
              primaryPhotoId: photoId,
            },
          },
        });
        refetch();
        notification.success({
          message: 'Saved',
          description: 'Photo uploaded successfully',
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your people group.',
        });
      }
      setSavingPrimary({ ...loading, [photoId]: false });
    },
    [updatePeopleGroup, loading, peopleGroup, refetch],
  );

  return (
    <div className="container">
      {error && (
        <div style={{ marginBottom: 16 }}>
          <Text type="danger">{error}</Text>
        </div>
      )}
      <List
        dataSource={photos}
        renderItem={(item) => (
          <List.Item>
            <PhotoCard
              photo={item}
              makePrimary={handleMakePrimary}
              loading={savingPrimary[item._id]}
              setDeleting={setDeleting}
            />
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Photos"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 4, xl: 4, xxl: 5, gutter: 16 }}
      />
      <div className="top-actions">
        <UploadPhotoButton
          peopleGroup={peopleGroup}
          queryId={queryId}
          refetch={refetch}
        />
      </div>
      <div style={{ minHeight: 300 }} />
      <DeletePeopleGroupPhotoModal
        peopleGroup={peopleGroup}
        setPhoto={setDeleting}
        photo={deleting}
        queryId={queryId}
        onComplete={() => {
          refetch();
        }}
      />
      <style jsx>{`
        .top-actions {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </div>
  );
}

export default PeopleGroupPhotos;
