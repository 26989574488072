export const READ_PAYOUT_V2S = 'READ_PAYOUT_V2S';
export const CREATE_PAYOUT_V2 = 'CREATE_PAYOUT_V2';
export const UPDATE_PAYOUT_V2 = 'UPDATE_PAYOUT_V2';
export const DELETE_PAYOUT_V2 = 'DELETE_PAYOUT_V2';

export const readPayoutV2sAction = (
  // eslint-disable-next-line no-shadow
  payoutV2s,
  queryId = 'default',
) => ({
  type: READ_PAYOUT_V2S,
  payload: {
    payoutV2s,
    queryId,
  },
});

export const createPayoutV2Action = (payoutV2, queryId = 'default') => ({
  type: CREATE_PAYOUT_V2,
  payload: {
    payoutV2,
    queryId,
  },
});

export const updatePayoutV2Action = (payoutV2, queryId = 'default') => ({
  type: UPDATE_PAYOUT_V2,
  payload: {
    payoutV2,
    queryId,
  },
});

export const deletePayoutV2Action = (payoutV2Id, queryId = 'default') => ({
  type: DELETE_PAYOUT_V2,
  payload: {
    payoutV2Id,
    queryId,
  },
});

const initialPayoutV2s = { default: {} };

export function payoutV2s(state = initialPayoutV2s, action) {
  switch (action.type) {
    case READ_PAYOUT_V2S: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.payoutV2s.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_PAYOUT_V2:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payoutV2._id]: action.payload.payoutV2,
        },
      };
    case UPDATE_PAYOUT_V2: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.payoutV2._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.payoutV2._id]: {
            ...current,
            ...action.payload.payoutV2,
          },
        },
      };
    }
    case DELETE_PAYOUT_V2: {
      const { payoutV2Id } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [payoutV2Id]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
