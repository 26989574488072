import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import sharedSettings from '@aims/shared/sharedSettings';
import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function DisplayProjectA10({ record }) {
  const typeLabel =
    HolisticProjectTypes[record.typeOfProject] &&
    HolisticProjectTypes[record.typeOfProject].label;
  let nameLabel;
  if (record.projectNo) {
    nameLabel = `${record.projectName} (#${record.projectNo})`;
  } else {
    nameLabel = record.projectName;
  }
  const stageLabel =
    HolisticProjectStages[record.stage] &&
    HolisticProjectStages[record.stage].label;
  return (
    <div>
      {/* <div style={{ fontSize: 11, color: sharedSettings.colors.primary }}>
        {typeLabel}
      </div> */}
      <div style={{ marginBottom: 0, fontSize: 12 }}>{nameLabel}</div>
      {/* <div style={{ fontSize: 11, color: sharedSettings.colors.textGray }}>
        {stageLabel}
      </div> */}

      {record.regionalCoordinator && (
        <div style={{ fontSize: 11 }}>
          {record.regionalCoordinator.fullName}
        </div>
      )}
      {record.region && <div style={{ fontSize: 11 }}>{record.region}</div>}
      {record.peopleGroupIds && (
        <div style={{ fontSize: 11 }}>{record.peopleGroupIds?.join(', ')}</div>
      )}
    </div>
  );
}

export default DisplayProjectA10;
