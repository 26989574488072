import React from 'react';
import { Select, Form } from 'antd';
import { YearlyQuarters } from '@aims/shared/reports/quarters';

export function buildQuarterOptions() {
  const thisYear = new Date().getFullYear();
  const years = [];
  for (let i = -2; i < 1; i += 1) {
    years.push(thisYear + i);
  }
  const quarters = {};
  years.reverse().forEach((year) => {
    ['Q1', 'Q2', 'Q3', 'Q4'].forEach((qx) => {
      const key = `${year}.${qx}`;
      quarters[key] = {
        key,
        label: `${YearlyQuarters[qx].label} ${year}`,
      };
    });
  });
  return quarters;
}

function SelectQuarter({
  loading,
  formItemAttrs,
  selectAttrs,
  required = true,
}) {
  const quarters = buildQuarterOptions();
  return (
    <Form.Item
      name="quarter"
      rules={
        required
          ? [{ required: true, message: 'Please select a quarter' }]
          : undefined
      }
      {...formItemAttrs}
    >
      <Select
        disabled={loading}
        placeholder="Select a quarter ..."
        {...selectAttrs}
      >
        {Object.values(quarters).map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectQuarter;
