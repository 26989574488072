import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

const PrintReportBtn = () => {
  return (
    <>
      <Button icon={<PrinterOutlined />} onClick={() => window.print()}>
        Print Report
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PrintReportBtn;
