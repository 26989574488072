import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import React, { useCallback, useRef, useState } from 'react';
import settings from '../../../settings';
import ListUpgPrayerAdoptions from '../../upg-prayer-adoptions/ListUpgPrayerAdoptions';
import {
  allUpgPrayerAdoptionsQuery,
  extractUpgPrayerAdoptions,
} from '../../upg-prayer-adoptions/constants';

function UpgPrayerAdoptionsTab({ contactId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    contactId,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: upgPrayerAdoptions,
    search: upgPrayerAdoptionsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgPrayerAdoptionsQuery,
    extract: extractUpgPrayerAdoptions,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'view-contact',
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        upgPrayerAdoptionsSearch(search);
      } else {
        filters.current = {
          isTemplate: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [upgPrayerAdoptionsSearch, refetch, sortedBy, sortOrder],
  );

  return (
    <ListUpgPrayerAdoptions
      loading={loading}
      error={error}
      upgPrayerAdoptions={upgPrayerAdoptions}
      next={next}
      hasNextPage={hasNextPage}
      refetch={refetch}
      isTemplate={false}
      onFiltersChanged={onFiltersChanged}
      onSortedByChange={onSortedByChange}
      onSortOrderChange={onSortOrderChange}
    />
  );
}

export default UpgPrayerAdoptionsTab;
