import {
  Button,
  Divider,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import DateFormItem from '../../components/DateFormItem';
import DollarX4InputNumber from '../../components/DollarX4InputNumber';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { displayMoney, orderAndFilterFormList } from '../../shared/utils';
import sharedSettings from '../../sharedSettings';
import { shallowEqual, useSelector } from 'react-redux';
import { ReportLineItem } from '../common/ReportItems';

const { Title, Paragraph, Text } = Typography;

function calcRemaining({ getFieldValue }) {
  const paymentPlan = getFieldValue('paymentPlan') || [];
  const included = paymentPlan.reduce((prev, curr) => {
    return prev + (curr?.amount || 0);
  }, 0);
  const loanTotal = getFieldValue('loanTotalUsd') || 0;
  const remaining = loanTotal - included / 10000;
  return { loanTotal, remaining };
}

function HolisticPaymentPlanFormItem({ disabled }) {
  return (
    <>
      <Divider>Loan Repayment Plan</Divider>
      <Paragraph>
        This is your loan repayment plan. When will you make your first payment?
        How often will you make payments? How much will you pay each time?
      </Paragraph>
      <Form.Item
        shouldUpdate={(prev, curr) =>
          prev.paymentPlan !== curr.paymentPlan ||
          prev.loanTotalUsd !== curr.loanTotalUsd
        }
        noStyle
      >
        {({ getFieldValue, setFieldsValue }) => {
          const { loanTotal, remaining } = calcRemaining({ getFieldValue });
          return (
            <div style={{ display: 'flex', marginBottom: 16 }}>
              <div style={{ marginRight: 16 }}>
                <Text style={{ fontSize: 16 }}>{`Loan Total: ${displayMoney(
                  loanTotal,
                )}`}</Text>
              </div>
              <div>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color:
                      remaining > 0 ? sharedSettings.colors.red : undefined,
                  }}
                >{`Remaining: ${displayMoney(remaining)}`}</Text>
              </div>
            </div>
          );
        }}
      </Form.Item>
      <Form.List
        name="paymentPlan"
        initialValue={[]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const { loanTotal, remaining } = calcRemaining({ getFieldValue });
              if (remaining > 0) {
                return Promise.reject(
                  'Loan repayment remaining amount must be $0.00',
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <DateFormItem
                  name={[name, 'dueAt']}
                  label={index === 0 ? 'Due On' : undefined}
                  allowClear
                  style={{ marginRight: 16, width: 200 }}
                  rules={[
                    { required: true, message: 'Thie field is required' },
                  ]}
                />
                <Form.Item
                  label={index === 0 ? 'Amount (USD)' : undefined}
                  name={[name, 'amount']}
                  initialValue={0}
                  rules={[
                    { required: true, message: 'Thie field is required' },
                  ]}
                >
                  <DollarX4InputNumber
                    disabled={disabled}
                    style={{ marginRight: 16, width: 120 }}
                  />
                </Form.Item>
                <Tooltip title="Remove Installment">
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    style={{ marginBottom: 12 }}
                  />
                </Tooltip>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ width: 200 }}
              >
                Add Installment
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <div style={{ height: 24 }} />
      <Form.Item
        label="Payment Method (How will you send the money to AIMS?)"
        extra="WesternUnion, Money Transfer..."
        name="paymentMethod"
        rules={[{ required: true, message: 'Thie field is required' }]}
      >
        <Input
          disabled={disabled}
          placeholder="WesternUnion, Money Transfer..."
        />
      </Form.Item>
      <Form.Item label="Any other loan repayment details..." name="loanDetails">
        <Input.TextArea rows={4} disabled={disabled} />
      </Form.Item>
      <Divider />
    </>
  );
}

function DisplayHolisticPaymentPlan({ report }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormatter = new Intl.DateTimeFormat(locale, { dateStyle: 'long' });
  return (
    <>
      <Title level={4}>Loan Repayment Plan</Title>
      <Table
        dataSource={report.paymentPlan}
        pagination={false}
        style={{ marginBottom: 16 }}
      >
        <Table.Column
          key="dueAt"
          label="Due On"
          render={(value, record) =>
            record.dueAt && dateFormatter.format(new Date(record.dueAt))
          }
        />
        <Table.Column
          key="amount"
          label="Amount (USD)"
          render={(value, record) => displayMoney((record.amount || 0) / 10000)}
        />
      </Table>
      <ReportLineItem
        question="Payment Method"
        desc="How will you send the money to AIMS?"
        answer={report.paymentMethod}
      />
      <ReportLineItem
        question="Any other loan repayment details..."
        answer={report.loanDetails}
      />
    </>
  );
}

const holisticPaymentPlan = {
  FormItem: HolisticPaymentPlanFormItem,
  finish: (values) => {
    const paymentPlan = orderAndFilterFormList(
      values.paymentPlan,
      (a, b) => {
        return a.dueAt.localeCompare(b.dueAt);
      },
      'dueAt',
      ['dueAt', 'amount'],
    );
    return {
      paymentPlan,
    };
  },
  Display: DisplayHolisticPaymentPlan,
};

export default holisticPaymentPlan;
