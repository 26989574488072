import { Form } from 'antd';
import React, { useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';
import ContactFormItems from '../../EditContact/form-items';

function ContactTagsInnerModalA10({ contact, saving, handleFinish, onCancel }) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        ...ContactFormItems.tags.before(contact),
      });
    }
  }, [contact, form]);
  return (
    <ContactSomethingForm
      title="Edit Tags"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <ContactFormItems.tags.FormItem loading={saving} />
    </ContactSomethingForm>
  );
}

export default ContactTagsInnerModalA10;
