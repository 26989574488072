import { formatPhoneNumberForDisplay } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../components/PhotoCircle';
import { ContactTypes } from '../contacts/constants';

const { Title, Text, Paragraph } = Typography;

export function AdoptionContactCard({
  title,
  contactDetails,
  loading,
  notSpecified,
}) {
  return (
    <div>
      <Title level={3}>{title}</Title>
      <Card styles={{ body: { display: 'flex' } }} loading={loading}>
        {!loading &&
          !contactDetails &&
          (notSpecified ? notSpecified : <div>Not Specified</div>)}
        {contactDetails && (
          <div style={{ marginRight: 16 }}>
            <PhotoCircle
              photoUrl={contactDetails && contactDetails.primaryThumbnailUrl}
              name={contactDetails && contactDetails.fullName}
              style={{ height: 80, width: 80, paddingBottom: 'unset' }}
              color={sharedSettings.colors.contactColor}
            />
          </div>
        )}
        {contactDetails && (
          <div>
            <div
              style={{
                fontSize: 14,
                color: sharedSettings.colors.primary,
                fontWeight: 600,
              }}
            >
              {contactDetails.fullName}
            </div>
            <div style={{ color: sharedSettings.colors.textGray }}>
              {contactDetails.contactType &&
                ContactTypes[contactDetails.contactType] &&
                ContactTypes[contactDetails.contactType].label}
            </div>
            <div>{contactDetails.primaryEmail}</div>
            <div>
              {formatPhoneNumberForDisplay(contactDetails.primaryPhoneNumber)}
            </div>
            <div>
              <Link to={`/contact/view/${contactDetails._id}/dashboard`}>
                Contact Page
              </Link>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default AdoptionContactCard;
