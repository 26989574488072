import sharedSettings from '@aims/shared/sharedSettings';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

const Sizes = {
  sm: 36,
  md: 48,
  mid: 80,
  mdlg: 100,
  lg: 200,
};

const FontSizes = {
  sm: 16,
  md: 18,
  lg: 36,
};

function PhotoCircle({
  photo,
  photoUrl,
  name,
  size,
  style = {},
  color = sharedSettings.colors.contactColor,
  customSize,
}) {
  const [loaded, setLoaded] = useState(false);
  const imageUrl = useMemo(() => {
    let url;
    if (photo) {
      if (size === 'lg') {
        const _photo =
          photo.images && photo.images.find((i) => i.sizeCode === 'SM');
        url = _photo && _photo.url;
      }
      if (!url) {
        url = photo && photo.thumbnail && photo.thumbnail.url;
      }
    } else if (photoUrl) {
      return photoUrl;
    }
    return url;
  }, [photo, photoUrl, size]);
  const pixels = customSize || Sizes[size];

  useEffect(() => {
    if (!imageUrl) {
      setLoaded(true);
    }
  }, [imageUrl]);
  return (
    <div className="container" style={{ width: pixels, ...style }}>
      <Tooltip title={name}>
        {!loaded ||
          (!imageUrl && (
            <div
              className="photo-placeholder"
              style={{
                width: pixels,
                height: pixels,
                backgroundColor: color,
                fontSize: FontSizes[size],
              }}
            >
              {!name && <LoadingOutlined />}
              {name && name[0].toUpperCase()}
            </div>
          ))}
        {imageUrl && (
          <img
            className="photo-url"
            style={{
              backgroundImage: `url("${imageUrl}"`,
              width: pixels,
              height: pixels,
              opacity: loaded ? '100%' : '0%',
            }}
            src={imageUrl}
            onLoad={() => setLoaded(true)}
          />
        )}
        {photoUrl && (
          <div
            className="photo-url"
            style={{
              backgroundImage: `url("${imageUrl}"`,
              width: pixels,
              height: pixels,
            }}
          />
        )}
      </Tooltip>
      <style jsx>{`
        .container {
          position: relative;
          max-width: 100%;
          padding-bottom: 100%;
        }
        .photo-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 16px;
          font-weight: 300;
          border-radius: 100%;
          overflow: hidden;
          height: 100%;
        }
        .photo-url {
          max-height: 100%;
          max-width: 100%;
          background-size: cover;
          background-position: top;
          border-radius: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
    </div>
  );
}

export default PhotoCircle;
