import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import { CopyOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, List, Typography } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import settings from '../../../../../settings';
import { generateUuid } from '../../../../../shared/utils';
import {
  AgreementStatuses,
  allProjectLoanAgreementQuery,
  extractLoanAgreements,
} from '../../../../loan-agreements/constants';
import CloneLoanAgreementModal from './CloneLoanAgreementModal';
import CreateLoanAgreementFromTemplateModal from './CreateLoanAgreementFromTemplateModal';

const { Paragraph, Title } = Typography;

function SingleLoanAgreement({ loanAgreement, locale, handleClone }) {
  const updated = `Updated ${new Intl.DateTimeFormat(locale, {
    dateStyle: 'full',
  }).format(new Date(loanAgreement.updatedAt))}`;
  const status = AgreementStatuses[loanAgreement.status];
  let signDate =
    loanAgreement.parties &&
    loanAgreement.parties.reduce((prev, curr) => {
      if (curr.date && (prev === undefined || prev < new Date(curr.date))) {
        return new Date(curr.date);
      }
      return prev;
    }, undefined);
  signDate =
    signDate &&
    `Signed ${new Intl.DateTimeFormat(locale, {
      dateStyle: 'full',
    }).format(signDate)}`;
  return (
    <Card styles={{ body: { position: 'relative' } }}>
      <Title level={4} style={{ margin: 0 }}>
        {`Template: ${loanAgreement.name}`}
      </Title>
      <div
        style={{
          fontSize: 14,
          color: sharedSettings.colors.textGray,
          marginBottom: 4,
        }}
      >{`Version ${loanAgreement.version}`}</div>
      <div
        style={{
          marginBottom: 4,
          fontSize: 16,
          fontWeight: 600,
          color: status && sharedSettings.colors[status.color],
        }}
      >
        {status && status.label}
      </div>
      {signDate ? (
        <div style={{ marginBottom: 8 }}>{signDate}</div>
      ) : (
        <div style={{ marginBottom: 8 }}>{updated}</div>
      )}
      <Title level={5} style={{ marginBottom: 4 }}>
        Signed By
      </Title>
      <div>
        {loanAgreement.parties &&
          loanAgreement.parties.map((p) => (
            <div style={{ fontSize: 12 }} key={p._id}>{`${p.name} - ${
              p.contactName || 'Not Signed'
            }`}</div>
          ))}
      </div>
      <div style={{ position: 'absolute', top: 16, right: 16 }}>
        <Link
          to={`/holistic-project/view/${loanAgreement.projectId}/loan-agreement/${loanAgreement._id}?from=/holistic-project/view/${loanAgreement.projectId}/loan-agreement`}
          style={{ marginRight: 8 }}
        >
          <Button icon={<EyeOutlined />}>View</Button>
        </Link>
        <Button
          icon={<CopyOutlined />}
          onClick={() => handleClone(loanAgreement)}
        >
          Clone
        </Button>
      </div>
    </Card>
  );
}

function LoanAgreementsTab({ project }) {
  const createId = useMemo(() => {
    return generateUuid();
  }, []);

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    isTemplate: false,
    projectId: project._id,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: loanAgreements,
    search: loanAgreementsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allProjectLoanAgreementQuery,
    extract: extractLoanAgreements,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'loanAgreementsTab',
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        loanAgreementsSearch(search);
      } else {
        filters.current = {
          isTemplate: true,
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [loanAgreementsSearch, refetch, sortedBy, sortOrder],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);

  const history = useHistory();
  const [creating, setCreating] = useState();
  const handleCreate = useCallback(() => {
    setCreating({
      project,
      createId,
      defaultVersionNo: loanAgreements.length + 1,
    });
  }, [project, createId, loanAgreements]);
  const handleCancelCreate = useCallback(() => {
    setCreating(false);
  }, []);
  const handleCreateFinish = useCallback(() => {
    history.push(
      `/holistic-project/view/${project._id}/loan-agreement/${createId}`,
    );
  }, [history, createId, project]);

  const [cloning, setCloning] = useState();
  const handleClone = useCallback(
    (agreement) => {
      setCloning({
        project,
        createId,
        defaultVersionNo: loanAgreements.length + 1,
        agreementToClone: agreement,
      });
    },
    [project, createId, loanAgreements],
  );
  const handleCancelClone = useCallback(() => {
    setCloning(false);
  }, []);
  const handleCloneFinish = useCallback(() => {
    history.push(
      `/holistic-project/view/${project._id}/loan-agreement/${createId}`,
    );
  }, [history, createId, project]);

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 16,
      }}
    >
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={loanAgreements}
        renderItem={(item) => {
          return (
            <List.Item>
              <SingleLoanAgreement
                loanAgreement={item}
                locale={locale}
                handleClone={handleClone}
              />
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 24 }}>
                {`This project doesn't have any loan agreements yet.`}
              </Paragraph>
              <div>
                <Button type="primary" onClick={handleCreate}>
                  Add a Loan Agreement
                </Button>
              </div>
            </>
          ),
        }}
        rowKey="_id"
      />
      <div style={{ textAlign: 'center' }}>
        {loanAgreements && loanAgreements.length > 0 && (
          <Button icon={<PlusOutlined />} type="primary" onClick={handleCreate}>
            Add another Loan Agreement
          </Button>
        )}
      </div>
      <CreateLoanAgreementFromTemplateModal
        visible={creating}
        onFinish={handleCreateFinish}
        onCancel={handleCancelCreate}
      />
      <CloneLoanAgreementModal
        visible={cloning}
        onFinish={handleCloneFinish}
        onCancel={handleCancelClone}
      />
    </div>
  );
}

export default LoanAgreementsTab;
