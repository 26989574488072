import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import YesNoModal from '../../../../components/YesNoModal';
import { deletePayoutV2Action } from '../../../../redux-store/payouts-v2-store';
import { deletePayoutV2Mutation } from '../constants';

const { Text } = Typography;

function DeletePayoutModalV2({ deleting, onCancel, onFinish }) {
  const { payout } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deletePayout] = useMutation(deletePayoutV2Mutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await deletePayout({
        variables: {
          _id: payout._id,
        },
      });
      dispatch(deletePayoutV2Action(payout._id));
      notification.success({
        message: 'Deleted',
        description: 'Payout deleted successfully',
      });
      onFinish();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [deletePayout, dispatch, payout, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete Payout"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text>{'Are you sure you want to delete this payout?'}</Text>
          </div>
          <div>
            {payout && payout.name && (
              <>
                <Text>{`${payout.name}`}</Text>
              </>
            )}
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!deleting}
      loading={loading}
      error={error}
    />
  );
}

export default DeletePayoutModalV2;
