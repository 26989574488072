import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';

export const updateHpInstallmentMutation = gql`
  mutation UpdateHpInstallment($hpInstallment: HpInstallmentUpdateInput!) {
    updateHpInstallment(hpInstallment: $hpInstallment)
  }
`;

const EditInstallmentNotesModal = ({ visible, onCancel, onFinish }) => {
  const { hpInstallment } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updateHpInstallment] = useMutation(updateHpInstallmentMutation);
  const [form] = Form.useForm();

  useEffect(() => {
    if (hpInstallment) {
      form.setFieldsValue({
        notes: hpInstallment.notes,
      });
    } else {
      form.resetFields();
    }
  }, [form, visible, hpInstallment]);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateHpInstallment({
          variables: {
            hpInstallment: {
              _id: hpInstallment._id,
              ...values,
            },
          },
        });

        notification.success({
          message: 'Saved',
          description: 'Notes saved successfully',
        });
        onFinish();
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [updateHpInstallment, hpInstallment, onFinish],
  );

  return (
    <Modal
      header={null}
      footer={null}
      open={visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={!saving}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 16 } }}
    >
      <TaskSomethingForm
        title="Installment Notes"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
        saveText="Save"
      >
        <Form.Item name="notes">
          <Input.TextArea />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default EditInstallmentNotesModal;
