import { useMutation } from '@apollo/client';
import { Form, Input, message, notification, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserAction } from '../../redux-store/users-store';
import { updateUserMutation } from './constants';
import UserQuestions from '@aims/shared/users/questions';

const { Title, Text, Paragraph } = Typography;

const ChangePasswordModal = ({ user, setUser }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [updateUser] = useMutation(updateUserMutation);

  const handleFinish = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const { confirm, password } = _values;
      try {
        const result = await updateUser({
          variables: {
            user: {
              _id: user._id,
              password,
            },
          },
        });
        dispatch(updateUserAction(result.data.updateUser.user));
        notification.success({
          message: 'Saved',
          description: 'Password updated successfully',
        });
        setUser(null);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [user, setUser, updateUser, dispatch],
  );

  const onValidateFailed = useCallback((values, errorFields) => {
    message.error(`Please correct the errors highlighted in red`);
  }, []);

  return (
    <>
      <Modal
        title={`Change password for ${user && user.name}`}
        visible={!!user}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        cancelText="Cancel"
        okText="Change Password"
        onOk={() => form.submit()}
        onCancel={() => setUser(undefined)}
        cancelButtonProps={{ loading }}
        okButtonProps={{ loading }}
        onFinishFailed={onValidateFailed}
      >
        <Form form={form} onFinish={handleFinish}>
          <UserQuestions.password.FormItem
            loading={loading}
            fieldsToSet={{}}
            user={user}
            userId={user && user._id}
            form={form}
          />
          <UserQuestions.confirmPassword.FormItem
            loading={loading}
            fieldsToSet={{}}
            user={user}
            userId={user && user._id}
            form={form}
          />
          {error && (
            <div
              className="server-error ant-form-item-has-error"
              style={{ marginTop: 16 }}
            >
              <div className="ant-form-item-explain">{error}</div>
            </div>
          )}
        </Form>
      </Modal>
      <style jsx>{`
        .key {
          display: flex;
          align-items: center;
          margin-top: 16px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ChangePasswordModal;
