export const YearlyQuarters = {
  Q1: {
    index: 0,
    key: 'Q1',
    label: 'Q1: January - March',
  },
  Q2: {
    index: 1,
    key: 'Q2',
    label: 'Q2: April - June',
  },
  Q3: {
    index: 2,
    key: 'Q3',
    label: 'Q3: July - September',
  },
  Q4: {
    index: 3,
    key: 'Q4',
    label: 'Q4: October - December',
  },
};

function zeroOutTime(d) {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
}

export function getQuarterStartDate(qx, year) {
  const qIndex = YearlyQuarters[qx].index;
  const startMonth = (qIndex % 4) * 3;
  const qStart = new Date();
  qStart.setMonth(startMonth);
  qStart.setDate(1);
  zeroOutTime(qStart);
  qStart.setFullYear(year);
  return qStart;
}

export function getQuarterEndDate(qx, year) {
  const qIndex = YearlyQuarters[qx].index;
  const startMonth = (qIndex % 4) * 3;
  const qEnd = new Date();
  qEnd.setFullYear(year);
  qEnd.setMonth(startMonth + 3);
  qEnd.setDate(1);
  zeroOutTime(qEnd);
  return qEnd;
}

export function getQuarterLabel(quarter) {
  const thisYear = new Date().getFullYear();
  const [year, qx] = quarter ? quarter.split('.') : [thisYear, 'Q1'];
  const quarterLabel = `${YearlyQuarters[qx].label} ${year}`;
  return quarterLabel;
}
