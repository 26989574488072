import { Form, Input, Select } from 'antd';
import Fuse from 'fuse.js';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import React, { useEffect, useMemo, useState } from 'react';

import { useCountries } from '../shared/use-countries';
import { useCountryRegions } from '../shared/use-country-regions';
import useSettings from '../shared/use-settings';
import sharedSettings from '../sharedSettings';

const countrySearchOptions = {
  includeScore: true,
  keys: ['shortCode', 'name'],
};

const countryRegionsSearchOptions = {
  includeScore: true,
  keys: ['shortCode', 'name'],
};

/**
 * @typedef {Object} Args
 * @property {string} name
 * @property {boolean} loading
 * @property {string} labelName
 * @property {Object} form
 * @property {Record<string, boolean>} required
 * @param {Args} args
 */
function AddressFormItem({ name, loading, labelName, form, required = {} }) {
  const {
    loading: countriesLoading,
    error: countriesError,
    countries,
  } = useCountries();
  const [countrySearch, setCountrySearch] = useState('');
  const settings = useSettings();

  const countryOptions = useMemo(() => {
    if (countrySearch) {
      // console.log('countrySearch', countrySearch);
      const fuse = new Fuse(countries, countrySearchOptions);
      const listItems = fuse
        .search(countrySearch)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      // console.log('listItems', listItems, fuse.search(countrySearch));
      return listItems;
    } else {
      return countries || [];
    }
  }, [countrySearch, countries]);

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  useEffect(() => {
    const address = form.getFieldValue(name) || {};
    setSelectedCountryCode(address.country);
  }, [form, name]);

  const {
    loading: countryRegionsLoading,
    error: countryRegionsError,
    countryRegions,
  } = useCountryRegions(selectedCountryCode);
  const [countryRegionsSearch, setCountryRegionsSearch] = useState('');

  const countryRegionsOptions = useMemo(() => {
    if (countryRegionsSearch) {
      const fuse = new Fuse(countryRegions, countryRegionsSearchOptions);
      const listItems = fuse
        .search(countryRegionsSearch)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return listItems;
    } else {
      return countryRegions;
    }
  }, [countryRegionsSearch, countryRegions]);

  return (
    <div className="address">
      <Form.Item
        label={labelName}
        name={[name, 'label']}
        rules={[
          { required: required.label, message: 'This field is required' },
        ]}
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item label="Address Line 1" name={[name, 'address1']}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item label="Address Line 2" name={[name, 'address2']} rules={[]}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Country"
          name={[name, 'country']}
          rules={[
            { required: required.country, message: 'Please select a country' },
          ]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <Select
            showSearch
            onSearch={(value) => {
              setCountrySearch(value);
            }}
            onSelect={(value) => {
              setCountrySearch('');
            }}
            optionFilterProp="children"
            autoComplete="chrome-off"
            disabled={loading}
            loading={countriesLoading}
            onChange={(value) => {
              setSelectedCountryCode(value);
              form.validateFields();
            }}
          >
            {countryOptions.map((country) => (
              <Select.Option key={country.shortCode} value={country.shortCode}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="State"
          name={[name, 'state']}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            marginLeft: 16,
          }}
        >
          <Select
            showSearch
            onSearch={(value) => {
              setCountryRegionsSearch(value);
            }}
            onSelect={(value) => {
              setCountryRegionsSearch('');
            }}
            optionFilterProp="children"
            autoComplete="chrome-off"
            disabled={loading}
            loading={countryRegionsLoading}
          >
            {countryRegionsOptions.map((state) => (
              <Select.Option key={state.shortCode} value={state.shortCode}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item label="City" name={[name, 'city']}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Zip Code"
        name={[name, 'zipCode']}
        rules={[
          {
            validator: (_, value) => {
              if (
                value &&
                postcodeValidatorExistsForCountry(selectedCountryCode) &&
                !postcodeValidator(value, selectedCountryCode)
              ) {
                return Promise.reject('Please enter a valid zip code.');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
      >
        <Input disabled={loading} />
      </Form.Item>
      <style jsx>{`
        .address {
          border: 1px solid ${sharedSettings.colors.borderGray};
          border-radius: 4px;
          padding 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default AddressFormItem;
