import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Popover, Tag, Typography } from 'antd';
import React from 'react';
import { PayoutRowStates } from '../../constants';

const { Title, Text, Link: TextLink } = Typography;

function PayoutDetailsColumn({ record }) {
  return (
    <>
      <Popover
        content={
          <>
            {record.fromPool ? (
              <Tag color="purple" style={{ marginBottom: 4 }}>
                From Pool
              </Tag>
            ) : (
              <Tag color="blue" style={{ marginBottom: 4 }}>
                From Sponsorship Account
              </Tag>
            )}
            {!!record.details.toRegionalCoord !== undefined && (
              <>
                <div className="row1">
                  <Text
                    style={{
                      fontWeight: 'bold',
                      color: sharedSettings.colors.primary,
                    }}
                  >
                    To Regional Coordinator
                  </Text>
                </div>
                <div className="right">
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    {`${displayMoney(record.details.toRegionalCoord / 10000)}`}
                  </Text>
                </div>
              </>
            )}
            {!!record.details.toFieldWorker !== undefined && (
              <>
                <div className="row1">
                  <Title
                    level={5}
                    style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                  >
                    To Field Worker
                  </Title>
                </div>
                <div className="right">
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    {`${displayMoney(record.details.toFieldWorker / 10000)}`}
                  </Text>
                </div>
              </>
            )}
            {!!record.details.toHolistic && (
              <>
                <div className="row1">
                  <Title
                    level={5}
                    style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                  >
                    To Holistic
                  </Title>
                </div>
                <div className="right">
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    {`${displayMoney(record.details.toHolistic / 10000)}`}
                  </Text>
                </div>
              </>
            )}
            {!!record.details.toTraining && (
              <>
                <div className="row1">
                  <Title
                    level={5}
                    style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                  >
                    To Training
                  </Title>
                </div>
                <div className="right">
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    {`${displayMoney(record.details.toTraining / 10000)}`}
                  </Text>
                </div>
              </>
            )}
            {!!record.details.toAnd && (
              <>
                <div className="row1">
                  <Title
                    level={5}
                    style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                  >
                    {`To A&D`}
                  </Title>
                </div>
                <div className="right">
                  <Text style={{ whiteSpace: 'nowrap' }}>
                    {`${displayMoney(record.details.toAnd / 10000)}`}
                  </Text>
                </div>
              </>
            )}
          </>
        }
        trigger="click"
      >
        <div className="right">
          {record.state === PayoutRowStates.COMPLETE.key ? (
            <Text style={{ whiteSpace: 'nowrap' }}>
              {`${displayMoney(record.details.totalAmount / 10000)}`}
            </Text>
          ) : (
            <Text style={{ whiteSpace: 'nowrap' }}>
              {`${displayMoney(
                record.details.totalAmount / 10000,
              )} / ${displayMoney(record.details.balance / 10000)}`}
            </Text>
          )}
        </div>
        <div>
          <TextLink>Details</TextLink>
        </div>
      </Popover>
    </>
  );
}

export default PayoutDetailsColumn;
