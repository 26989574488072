import { gql } from '@apollo/client';

export const allMiraclesForAdminQuery = gql`
  query AllMiraclesForAdmin(
    $first: Int
    $after: String
    $filters: MiracleFilters
  ) {
    allMiraclesForAdmin(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          desc
          reportId
          reportType
          peopleGroupId
          peopleGroup {
            _id
            country
            regionCode
            nameAcrossCountries
            primaryThumbnailUrl
          }
          holisticProjectId
          holisticProject {
            _id
            projectName
            typeOfProject
            peopleGroupIds
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const extractMiraclesForAdmin = (data) => data.allMiraclesForAdmin;
