import DateFormItem from '@aims/shared/components/DateFormItem';
import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  DeleteOutlined,
  DownloadOutlined,
  FilterOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  List,
  Radio,
  Select,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import DeleteSavedImageModel from './DeleteSavedImageModel';
import ViewPhotoModal from './ViewPhotoModal';
import {
  allSavedImagesQuery,
  extractAllSavedImagesFromQuery,
} from './constants';

const { Text } = Typography;

function SavedImageCard({ image, onDelete, setShowViewPhotoModel }) {
  return (
    <List.Item style={{ maxWidth: 256 }}>
      <div className="actions">
        {image.file && (
          <Tooltip title="Download image">
            <Button
              type="text"
              icon={<DownloadOutlined />}
              target="_blank"
              download={image.file.filename}
              href={image.file.url}
              style={{ marginLeft: -8, marginBottom: 0 }}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete image">
          <Button
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => onDelete(image)}
            style={{ marginRight: -8, marginBottom: 0 }}
          />
        </Tooltip>
      </div>
      <map
        className="container"
        onClick={() => setShowViewPhotoModel(image.file)}
      >
        <div className="card-default">
          <div className="card-default-letter">I</div>
        </div>
        <div
          className="card-image"
          style={{
            backgroundImage:
              image.file &&
              image.file.thumbnail &&
              `url("${image.file.thumbnail.url}")`,
          }}
        />
      </map>
      <div className="desc">
        <Text>{image.desc}</Text>
      </div>
      {image.holisticProject && (
        <div style={{ marginBottom: 8 }}>
          <Text style={{ fontWeight: 600 }}>Holistic Project</Text>
          <br />
          <Text>{image.holisticProject.projectName}</Text>
        </div>
      )}
      {image.peopleGroup && (
        <div style={{ marginBottom: 8 }}>
          <Text style={{ fontWeight: 600 }}>People Group</Text>
          <br />
          <Text>{getPgDisplayName(image.peopleGroup)}</Text>
        </div>
      )}
      {image.tags && (
        <div className="tags">
          {image.tags.map((tag, index) => (
            <Tag
              key={index}
              color={sharedSettings.colors.darkerGray}
              style={{ marginBottom: 4 }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      )}
      <style jsx>{`
        .actions {
          display: flex;
          justify-content: space-between;
        }
        .container {
          display: flex;
          justify-content: center;
          position: relative;
          margin-bottom: 8px;
        }
        .card-default {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          background-color: ${sharedSettings.colors.darkGray};
          display: flex;
          justify-content: center;
        }
        .card-default-letter {
          height: 100%;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          color: white;
        }
        .card-image {
          padding-bottom: 100%;
          width: 100%;
          background-position: top;
          background-size: cover;
          border-radius: 4px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
        }
        .tags {
          display: flex;
          flex-wrap: wrap;
          max-width: 256px;
        }
        .desc {
          margin-bottom: 8px;
        }
      `}</style>
    </List.Item>
  );
}

const queryId = 'savedImagesPage';

function SavedImagesPage() {
  const [form] = Form.useForm();

  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const [showFilters, setShowFilters] = useState(false);
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: sortOrder },
  ]);

  const {
    error,
    loading,
    data: images,
    search: imageSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allSavedImagesQuery,
    extract: extractAllSavedImagesFromQuery,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );
  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        imageSearch(search.toLowerCase());
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [imageSearch, refetch, sortedBy, sortOrder],
  );

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  const [showViewPhotoModel, setShowViewPhotoModel] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const handleOnDelete = (image) => {
    setDeleting({
      image,
    });
  };
  const onFinishDelete = () => {
    setDeleting(undefined);
    refetch();
  };
  const onCancelDelete = () => setDeleting(undefined);

  return (
    <CSPage title="Saved Images" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader title="Saved Images" topActions={[]} />
      <div className="top-actions">
        <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="search"
              style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
            >
              <Input placeholder="Search" suffix={<SearchOutlined />} />
            </Form.Item>
            <Button
              onClick={() => setShowFilters(!showFilters)}
              icon={<FilterOutlined />}
            >
              {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
            <div style={{ flex: 1 }} />
            <Button
              icon={<ReloadOutlined />}
              onClick={async () => {
                await reset();
              }}
            >
              Refresh
            </Button>
          </div>
          {showFilters && (
            <>
              <div style={{ display: 'flex', marginBottom: 16 }}>
                <div className="ant-form-item" style={{ marginRight: 16 }}>
                  <div className="ant-col ant-form-item-label">
                    <label style={{ marginRight: 5 }}>Sort by</label>
                  </div>
                  <Select value={sortedBy} onChange={onSortedByChange}>
                    <Select.Option value="updatedAt">Updated At</Select.Option>
                    <Select.Option value="createdAt">Created At</Select.Option>
                  </Select>
                </div>
                <div className="ant-form-item">
                  <div className="ant-col ant-form-item-label">
                    <label style={{ marginRight: 5 }}>Sort Order</label>
                  </div>
                  <Select value={sortOrder} onChange={onSortOrderChange}>
                    {['firstName.keyword', 'lastName.keyword'].includes(
                      sortedBy,
                    ) && (
                      <>
                        <Select.Option value="ASC">A-Z</Select.Option>
                        <Select.Option value="DESC">Z-A</Select.Option>
                      </>
                    )}
                    {['updatedAt'].includes(sortedBy) && (
                      <>
                        <Select.Option value="ASC">
                          Most Recent Last
                        </Select.Option>
                        <Select.Option value="DESC">
                          Most Recent First
                        </Select.Option>
                      </>
                    )}
                  </Select>
                </div>
              </div>
              <Form.Item name="reportType">
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={undefined}>Any</Radio.Button>
                  <Radio.Button value="PG_QUARTERLY">
                    Quarterly People Group Report
                  </Radio.Button>
                  <Radio.Button value="HOLISTIC_REQ">
                    Holistic Project Proposal
                  </Radio.Button>
                  <Radio.Button value="HOLISTIC_PROGRESS">
                    Quarterly Holistic Project Report
                  </Radio.Button>
                  <Radio.Button value="TRAIN_EVENT">
                    Training Event Report
                  </Radio.Button>
                  <Radio.Button value="TRAIN_QUARTERLY">
                    Quarterly Training Report
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <DateFormItem
                name="onOrAfter"
                label="From on or after"
                rules={[{ required: true, message: 'This field is required' }]}
                disabled={loading}
              />
              <DateFormItem
                name="before"
                label="Until before"
                rules={[{ required: true, message: 'This field is required' }]}
                disabled={loading}
              />
            </>
          )}
        </Form>
      </div>
      {loading && !images.length ? (
        <List
          itemLayout="vertical"
          grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6, gutter: 16 }}
          dataSource={[0, 0, 0, 0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            className="contactsList"
            dataSource={images.slice(0, pageSize)}
            grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6, gutter: 16 }}
            rowKey="_id"
            renderItem={(item) =>
              item && (
                <SavedImageCard
                  image={item}
                  setShowViewPhotoModel={setShowViewPhotoModel}
                  onDelete={handleOnDelete}
                />
              )
            }
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Saved Images"
                />
              ),
            }}
          />
          <div className="bottom-actions">
            {images && images.length <= pageSize && hasNextPage && (
              <Button onClick={next}>Load More</Button>
            )}
            {images && images.length > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
      <ViewPhotoModal
        visible={showViewPhotoModel}
        setVisible={setShowViewPhotoModel}
      />
      <DeleteSavedImageModel
        onFinish={onFinishDelete}
        onCancel={onCancelDelete}
        visible={deleting}
      />
    </CSPage>
  );
}

export default SavedImagesPage;
