import { gql } from '@apollo/client';

export const PayoutV2ListAttributes = gql`
  {
    _id
    name
    quarter
    state
    notes
    regionalCoordEmailsSent
    createdBy
    updatedBy
    initiatedBy
    completedBy
    createdAt
    updatedAt
    initiatedAt
    completedAt
  }
`;

export const PayoutStates = {
  NEW: {
    key: 'NEW',
    label: 'New',
    color: 'blue',
  },
  DRAFT: {
    key: 'DRAFT',
    label: 'Draft',
    color: 'orange',
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    label: 'In Progress',
    color: 'green',
  },
  COMPLETE: {
    key: 'COMPLETE',
    label: 'Complete',
    color: 'blue',
  },
};

export const PayoutRowStates = {
  NOT_READY: {
    key: 'NOT_READY',
    label: 'Not Ready',
  },
  READY: {
    key: 'READY',
    label: 'Ready',
  },
  PENDING: {
    key: 'PENDING',
    label: 'Sent',
  },
  COMPLETE: {
    key: 'COMPLETE',
    label: 'Received',
  },
};

export const CrudTypes = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
};

export const allPayoutV2sQuery = gql`
  query AllPayoutV2s($first: Int, $after: String, $filters: PayoutV2Filters) {
    allPayoutV2s(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PayoutV2ListAttributes}
      }
    }
  }
`;

export const extractPayoutV2s = (data) => data && data.allPayoutV2s;

export const createPayoutV2Mutation = gql`
  mutation CreatePayoutV2($payout: PayoutV2CreateInput!) {
    createPayoutV2(payout: $payout) {
      payout ${PayoutV2ListAttributes}
    }
  }
`;

export const updatePayoutV2Mutation = gql`
  mutation UpdatePayoutV2($payout: PayoutV2UpdateInput!) {
    updatePayoutV2(payout: $payout) {
      payout ${PayoutV2ListAttributes}
    }
  }
`;

export const deletePayoutV2Mutation = gql`
  mutation DeletePayoutV2($_id: ID!) {
    deletePayoutV2(_id: $_id) {
      deletedId
    }
  }
`;

export const startPayoutV2Mutation = gql`
  mutation StartPayoutV2($payoutId: ID!) {
    startPayoutV2(payoutId: $payoutId) {
      payout ${PayoutV2ListAttributes}
    }
  }
`;

export const completePayoutV2Mutation = gql`
  mutation CompletePayoutV2($payoutId: ID!) {
    completePayoutV2(payoutId: $payoutId) {
      payout ${PayoutV2ListAttributes}
    }
  }
`;

export const payoutV2UpdatesSubscription = gql`
  subscription PayoutV2Updates($authHeader: String!, $payoutId: ID!) {
    payoutV2Updates(authHeader: $authHeader, payoutId: $payoutId) {
      crud
      id
      old ${PayoutV2ListAttributes}
      new ${PayoutV2ListAttributes}
    }
  }
`;

export const PayoutV2RowAttributes = gql`
  {
    _id
    payoutId
    adoptionId
    adoptionCopy {
      _id
      contactId
      peopleGroupId
      peopleGroup {
        _id
        region
        regionCode
        country
        nameAcrossCountries
        primaryThumbnailUrl
      }
      regionCode
      adopterName
      level
      fieldWorkerId
      fieldWorkerName
      regionalCoordId
      regionalCoordName
      amount
      accountId
      status
    }
    fromPool
    fromAccountId
    details {
      totalAmount
      balance
      toRegionalCoord
      toFieldWorker
      toHolistic
      toTraining
      toAnd
    }
    completable {
      completable
      reason
    }
    hasReport
    willReceiveFunds
    amount
    state
    transferIds
    notes
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const payoutV2RowUpdatesSubscription = gql`
  subscription PayoutV2RowUpdates($authHeader: String!, $payoutId: ID!) {
    payoutV2RowUpdates(authHeader: $authHeader, payoutId: $payoutId) {
      crud
      id
      old ${PayoutV2RowAttributes}
      new ${PayoutV2RowAttributes}
    }
  }
`;

export const buildPayoutV2RowsMutation = gql`
  mutation BuildPayoutV2Rows($payoutId: ID!) {
    buildPayoutV2Rows(payoutId: $payoutId)
  }
`;

export const updatePayoutV2RowMutation = gql`
  mutation UpdatePayoutV2Row($payoutRow: UpdatePayoutV2RowInput!) {
    updatePayoutV2Row(payoutRow: $payoutRow) {
      payoutRow {
        _id
      }
    }
  }
`;

export const initiatePayoutV2RowsMutation = gql`
  mutation InitiatePayoutV2Rows($rowIds: [ID]!) {
    initiatePayoutV2Rows(rowIds: $rowIds)
  }
`;

export const completePayoutV2RowsMutation = gql`
  mutation CompletePayoutV2Rows($payoutId: ID!, $rowIds: [ID]!) {
    completePayoutV2Rows(payoutId: $payoutId, rowIds: $rowIds)
  }
`;

export const cancelPayoutV2RowsMutation = gql`
  mutation CancelPayoutV2Rows($rowIds: [ID]!) {
    cancelPayoutV2Rows(rowIds: $rowIds)
  }
`;

export const makePayoutV2RowsFromPoolMutation = gql`
  mutation MakePayoutV2RowsFromPool($rowIds: [ID!]!) {
    makePayoutV2RowsFromPool(rowIds: $rowIds)
  }
`;

export const buildSinglePayoutV2RowsMutation = gql`
  mutation BuildSinglePayoutV2Rows($rowIds: [ID!]!) {
    buildSinglePayoutV2Rows(rowIds: $rowIds)
  }
`;

export const makePayoutV2RowsNotFromPoolMutation = gql`
  mutation MakePayoutV2RowsNotFromPool($rowIds: [ID!]!) {
    makePayoutV2RowsNotFromPool(rowIds: $rowIds)
  }
`;

export const sendRegionalCoordPayoutEmailV2Mutation = gql`
  mutation SendRegionalCoordPayoutEmailV2(
    $request: SendRegionalCoordPayoutEmailRequestV2!
  ) {
    sendRegionalCoordPayoutEmailV2(request: $request)
  }
`;

export const allPayoutV2RowsQuery = gql`
  query AllPayoutV2Rows($first: Int, $after: String, $filters: PayoutV2RowFilters) {
    allPayoutV2Rows(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PayoutV2RowAttributes}
      }
    }
  }
`;
