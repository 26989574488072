import React, { useCallback, useRef, useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import RemoteTaskTabs from './RemoteTaskTabs';
import TaskFilterBar from './TaskFilterBar';
import useProfile from '../../shared/use-profile';

const sortedBy = 'windowStop';
const sortOrder = 'ASC';

function ListTasks() {
  const [showMyTasks, setShowMyTasks] = useState(false);
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const profile = useProfile();
  const onShowMyTasksChange = useCallback(
    (value) => {
      setShowMyTasks(value);
      if (value) {
        filters.current = {
          ...filters.current,
          assigneeId: profile._id,
        };
      } else {
        filters.current = {
          ...filters.current,
          assigneeId: undefined,
        };
      }
    },
    [profile],
  );
  const searchRef = useRef();
  const refetchRef = useRef();
  return (
    <CSPage title="Tasks">
      <CSPageHeader title="Tasks" />
      <TaskFilterBar
        showMyTasksBtn={true}
        showMyTasks={showMyTasks}
        setShowMyTasks={onShowMyTasksChange}
        filtersRef={filters}
        sortByRef={sortBy}
        sortedBy={sortedBy}
        sortOrder={sortOrder}
        fieldsToSet={{}}
        searchRef={searchRef}
        refetchRef={refetchRef}
      />
      <RemoteTaskTabs
        filters={filters.current}
        searchRef={searchRef}
        refetchRef={refetchRef}
      />
    </CSPage>
  );
}

export default ListTasks;
