import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import AimsStatusFilter from '../../people_groups/ListPeopleGroups/AimsStatusFilter';

function ListAdoptionsFiltersA10({ onFiltersChanged, hideFilters }) {
  const [form] = Form.useForm();

  const [showFilters, setShowFilters] = useState(false);
  return (
    <Form
      layout="vertical"
      form={form}
      style={{ maxWidth: 750, minWidth: 300 }}
      onValuesChange={onFiltersChanged}
    >
      <div style={{ display: 'flex' }}>
        <Form.Item name="search" style={{ minWidth: 300, marginRight: 16 }}>
          <Input placeholder="Search" suffix={<SearchOutlined />} />
        </Form.Item>
        {!hideFilters && (
          <Button
            onClick={() => setShowFilters(!showFilters)}
            icon={<FilterOutlined />}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        )}
      </div>
      {showFilters && (
        <div>
          <div style={{ display: 'flex' }}>
            <Form.Item
              shouldUpdate={(prev, curr) => prev.isOnline != curr.isOnline}
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="isOnline"
                  label={
                    getFieldValue('isOnline')
                      ? 'Fullfilled Online'
                      : 'Not Fulfilled Online'
                  }
                  initialValue={false}
                  style={{ marginRight: 16, marginBottom: 0 }}
                >
                  <AimsStatusFilter style={{ width: undefined }} />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) =>
                prev.hasRegionalCoord != curr.hasRegionalCoord
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="hasRegionalCoord"
                  label={
                    getFieldValue('hasRegionalCoord')
                      ? 'Has Coordinator'
                      : 'Does Not Have Coordinator'
                  }
                  initialValue={false}
                  style={{ marginRight: 16, marginBottom: 0 }}
                >
                  <AimsStatusFilter style={{ width: undefined }} />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) =>
                prev.hasFieldWorker != curr.hasFieldWorker
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="hasFieldWorker"
                  label={
                    getFieldValue('hasFieldWorker')
                      ? 'Has Field Worker'
                      : 'Does Not Have Field Worker'
                  }
                  initialValue={false}
                  style={{ marginRight: 16, marginBottom: 0 }}
                >
                  <AimsStatusFilter style={{ width: undefined }} />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) => prev.hasAdopter != curr.hasAdopter}
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item
                  name="hasAdopter"
                  label={
                    getFieldValue('hasAdopter')
                      ? 'Has Adopter'
                      : 'Does Not Have Adopter'
                  }
                  initialValue={false}
                  style={{ marginRight: 16, marginBottom: 0 }}
                >
                  <AimsStatusFilter style={{ width: undefined }} />
                </Form.Item>
              )}
            </Form.Item>
          </div>
          <Form.Item name="status" label={'Filter by status'}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={undefined}>All</Radio.Button>
              <Radio.Button value="NEW">New</Radio.Button>
              <Radio.Button value="ACTIVE">Active</Radio.Button>
              <Radio.Button value="ON_HOLD">On Hold</Radio.Button>
              <Radio.Button value="PAID_OUT">Paid Out</Radio.Button>
              <Radio.Button value="INCOMPLETE">Incomplete</Radio.Button>
              <Radio.Button value="REASSIGNED">Reassigned</Radio.Button>
              <Radio.Button value="UNFUNDED">Unfunded</Radio.Button>
              <Radio.Button value="NOT_YET_ASSIGNED">
                Not Yet Assigned
              </Radio.Button>
              <Radio.Button value="NEED_TO_REASSIGN">
                Need to Reassign
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="level" label={'Filter by level'}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={undefined}>All</Radio.Button>
              <Radio.Button value="ADOPTER_PRAY">Prayer</Radio.Button>
              <Radio.Button value="ADOPTER_LV1">Level 1</Radio.Button>
              <Radio.Button value="ADOPTER_LV2">Level 2</Radio.Button>
              <Radio.Button value="ADOPTER_LV3">Level 3</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      )}
    </Form>
  );
}

export default ListAdoptionsFiltersA10;
