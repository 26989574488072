import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Typography, message, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import {
  createMouAction,
  deleteMouAction,
  updateMouAction,
} from '../../redux-store/mous-store';
import useQueryParams from '../../shared/use-query-params';
import AttachmentsElement from '../loan-agreements/AttachmentsElement';
import EditableOneLineElement from '../loan-agreements/EditableOneLineElement';
import EditableTextElement from '../loan-agreements/EditableTextElement';
import PartiesElement from '../loan-agreements/PartiesElement';
import SectionsElement from '../loan-agreements/SectionsElement';
import { AgreementStatuses } from '../loan-agreements/constants';
import DeleteMouTemplateModal from './DeleteMouTemplateModal';
import SelectMouRole from './SelectMouRole';
import {
  MouRoles,
  createMouTemplateMutation,
  extractMouForAdmin,
  mouTemplateQuery,
  mouTemplateUpdatesForAdminSubscription,
  updateMouTemplateMutation,
} from './constants';

const queryId = 'viewMouTemplate';

const { Title, Paragraph, Text } = Typography;

function ViewMouTemplate() {
  const { _id } = useParams();
  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return '/mou-templates';
    }
  }, [query]);

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: mouTemplateUpdatesForAdminSubscription,
    createAction: createMouAction,
    updateAction: updateMouAction,
    deleteAction: deleteMouAction,
    extractSomething: (resp) => resp?.data?.mouUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, error } = useOneM1({
    variables: { _id },
    query: mouTemplateQuery,
    extract: extractMouForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateMouAction,
    queryId,
    showError: false,
  });
  const mou = useSingleSomethingA10('mous', _id, queryId);

  const [updateMou] = useMutation(updateMouTemplateMutation);
  const [createMou] = useMutation(createMouTemplateMutation);

  const saveQueue$ = useRef();
  const handleSave = useCallback((values) => {
    saveQueue$.current.next(values);
  }, []);

  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = mou;
  }, [mou]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function doSave(values) {
      if (values) {
        try {
          if (currentAgreement.current) {
            await updateMou({
              variables: {
                mou: {
                  _id,
                  status: 'PREPARING',
                  ...values,
                },
              },
            });
            notification.success({
              message: 'Success',
              description: 'Partnership Commitment Form Template Updated',
            });
          } else {
            const result = await createMou({
              variables: {
                mou: {
                  _id,
                  agreementType: 'MOU',
                  isTemplate: true,
                  status: AgreementStatuses.PREPARING.key,
                  ...values,
                  name: values.name || 'No Template Name',
                },
              },
            });
            dispatch(createMouAction(result.data.createMou.mou, queryId));
            notification.success({
              message: 'Success',
              description: 'Partnership Commitment Form Template Created',
            });
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [_id, updateMou, createMou, dispatch]);

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const handleDeleteClick = useCallback(() => {
    setDeleting(mou);
  }, [mou]);
  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleDeleteFinish = useCallback(() => {
    setDeleting(undefined);
    history.push(backLink);
  }, [backLink, history]);

  return (
    <>
      <CSPage title="Partnership Commitment Form Template">
        <CSPageHeader
          title=""
          titleComponent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title level={1}>Partnership Commitment Form Template</Title>
              {!!mou && (
                <Button
                  onClick={handleDeleteClick}
                  icon={<DeleteOutlined />}
                  type="text"
                >
                  Delete
                </Button>
              )}
            </div>
          }
          backActions={[
            <Link key="back" to={backLink}>
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>,
          ]}
        />
        <EditableTextElement
          name="name"
          label="Template Name"
          noLabel="No Template Name"
          text={mou && mou.name}
          handleSave={handleSave}
          parties={mou && mou.parties}
          attachments={mou && mou.attachments}
        />
        <Title level={2}>Template Properties</Title>
        <EditableOneLineElement
          name="role"
          label="Role"
          noLabel="No Role"
          text={mou && mou.role}
          initialValues={{ role: mou && mou.role }}
          handleSave={handleSave}
          parties={mou && mou.parties}
          attachments={mou && mou.attachments}
          InputElement={SelectMouRole}
          inputProps={{ style: { minWidth: 300 } }}
          readOnly={mou && !mou.isTemplate}
          getDisplayText={(text) => MouRoles[text] && MouRoles[text].label}
        />
        <div style={{ marginBottom: 16 }} />
        <PartiesElement loanAgreement={mou} handleSave={handleSave} />
        <SectionsElement loanAgreement={mou} handleSave={handleSave} />
        <AttachmentsElement
          loanAgreementId={_id}
          loanAgreement={mou}
          handleSave={handleSave}
        />
      </CSPage>
      {mou && (
        <DeleteMouTemplateModal
          mouTemplate={deleting}
          onCancel={handleCancelDelete}
          onFinish={handleDeleteFinish}
        />
      )}
    </>
  );
}

export default ViewMouTemplate;
