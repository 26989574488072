import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import AddressFormItem from './AddressFormItem';

/**
 * @typedef {Object} Address
 * @property {string=} __typename
 * @property {string} _id
 */

function AddressesFormItem({ form, contact, loading, onSubmit }) {
  const [primary, setPrimary] = useState(0);

  useEffect(() => {
    if (contact) {
      if (contact.primaryAddressId) {
        const addressIndex = (contact.addresses || []).findIndex(
          (e) => e._id === contact.primaryAddressId,
        );
        setPrimary(addressIndex);
      }
    }
  }, [contact, form]);

  onSubmit.current = useCallback(
    /**
     * @param {Address[]} addresses
     * @returns {{ primaryAddressId: string|null, addressesToSave: Address[] }} }
     **/
    (addresses) => {
      let primaryAddressId = null;
      let addressesToSave = [];
      if (addresses && addresses.length) {
        let primaryIndex = primary;
        if (primaryIndex >= addresses.length) {
          primaryIndex = 0;
        }
        primaryAddressId = addresses[primaryIndex]._id;
        addressesToSave = addresses.map((a) => {
          const { __typename, ...other } = a;
          return other;
        });
      }
      return {
        primaryAddressId,
        addressesToSave,
      };
    },
    [primary],
  );
  return (
    <Form.Item>
      <Form.List name="addresses">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <AddressFormItem
                    field={field}
                    loading={loading}
                    primary={primary}
                    setPrimary={setPrimary}
                    form={form}
                  />
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      if (primary === index) {
                        setPrimary(0);
                      }
                      remove(field.name);
                    }}
                    style={{ marginLeft: 8 }}
                  />
                </div>
              </Form.Item>
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                Add address
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  );
}

export default AddressesFormItem;
