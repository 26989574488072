import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React from 'react';

function MiracleSearchBar({ onFiltersChanged, hideSearch, refetch }) {
  const [form] = Form.useForm();
  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={onFiltersChanged}
      style={{ marginBottom: 16 }}
    >
      <div style={{ display: 'flex' }}>
        {hideSearch ? (
          <div style={{ flex: 1, height: 64 }} />
        ) : (
          <div style={{ display: 'flex' }}>
            <Form.Item name="search" style={{ minWidth: 300, marginRight: 16 }}>
              <Input placeholder="Search" suffix={<SearchOutlined />} />
            </Form.Item>
          </div>
        )}
        <div style={{ flex: 1 }} />
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
    </Form>
  );
}

export default MiracleSearchBar;
