import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { reviewUpgQuarterlyReportMutation } from '../constants';

const { Title, Text, Paragraph } = Typography;

const RejectUpgQuarterlyReportBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewPgQuarterlyReport] = useMutation(
    reviewUpgQuarterlyReportMutation,
  );

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await reviewPgQuarterlyReport({
        variables: {
          reportId: report._id,
          status: 'REJECTED',
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Report Rejected',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your report',
      });
    }
    setLoading(false);
  }, [reviewPgQuarterlyReport, report, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Reject Report
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Reject UPG Quarterly Report
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              {`You're about to reject the ${getPgDisplayName(
                report.peopleGroup,
              )} quarterly report for ${getQuarterLabel(
                report.quarter,
              )}. It will not contribute to this people group's cumulative stats or trigger an email to be sent to supporters.`}
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Reject Report
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default RejectUpgQuarterlyReportBtn;
