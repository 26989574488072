import { Tabs } from 'antd';
import React from 'react';
import HolisticProposalsList from './HolisticProposalsList';

function HolisticProposalTabs({ peopleGroupId = null }) {
  return (
    <Tabs defaultActiveKey="pending">
      <Tabs.TabPane tab="Pending" key="pending">
        <HolisticProposalsList
          reportStatus="PENDING"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Approved" key="approved">
        <HolisticProposalsList
          reportStatus="APPROVED"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="On Hold" key="hold">
        <HolisticProposalsList
          reportStatus="HOLD"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Drafts" key="draft">
        <HolisticProposalsList
          reportStatus="DRAFT"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rejected" key="rejected">
        <HolisticProposalsList
          reportStatus="REJECTED"
          peopleGroupId={peopleGroupId}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default HolisticProposalTabs;
