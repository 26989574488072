export const READ_SIMPLE_LISTS = 'READ_SIMPLE_LISTS';
export const CREATE_SIMPLE_LIST = 'CREATE_SIMPLE_LIST';
export const UPDATE_SIMPLE_LIST = 'UPDATE_SIMPLE_LIST';
export const DELETE_SIMPLE_LIST = 'DELETE_SIMPLE_LIST';

export const readSimpleListsAction = (
  // eslint-disable-next-line no-shadow
  simpleLists,
  queryId = 'default',
) => ({
  type: READ_SIMPLE_LISTS,
  payload: {
    simpleLists,
    queryId,
  },
});

export const createSimpleListAction = (simpleList, queryId = 'default') => ({
  type: CREATE_SIMPLE_LIST,
  payload: {
    simpleList,
    queryId,
  },
});

export const updateSimpleListAction = (simpleList, queryId = 'default') => ({
  type: UPDATE_SIMPLE_LIST,
  payload: {
    simpleList,
    queryId,
  },
});

export const deleteSimpleListAction = (simpleListId, queryId = 'default') => ({
  type: DELETE_SIMPLE_LIST,
  payload: {
    simpleListId,
    queryId,
  },
});

const initialSimpleLists = { default: {} };

export function simpleLists(state = initialSimpleLists, action) {
  switch (action.type) {
    case READ_SIMPLE_LISTS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.simpleLists.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_SIMPLE_LIST:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.simpleList._id]: action.payload.simpleList,
        },
      };
    case UPDATE_SIMPLE_LIST: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.simpleList._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.simpleList._id]: {
            ...current,
            ...action.payload.simpleList,
          },
        },
      };
    }
    case DELETE_SIMPLE_LIST: {
      const { simpleListId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [simpleListId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
