import sharedSettings from '@aims/shared/sharedSettings';
import { Empty, List, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function DetailsList({ details, loading, style = {} }) {
  return (
    <List
      dataSource={details}
      renderItem={(item) => (
        <List.Item>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div>
              <Text
                style={{
                  color: sharedSettings.colors.primary,
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {item.title}
              </Text>
              <br />
              {item.component ? item.component : <Text>{item.value}</Text>}
            </div>
            {item.edit && item.edit}
          </div>
        </List.Item>
      )}
      loading={loading}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Details"
          />
        ),
      }}
      grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3, gutter: 8 }}
    />
  );
}

export default DetailsList;
