import { useCountries } from '@aims/shared/shared/use-countries';
import { Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { ContactTypes } from '../../contacts/constants';

const { Title, Text } = Typography;

function DisplayContactA10({ contact }) {
  const { countries } = useCountries();

  const getCountry = useCallback(
    (shortCode) => {
      if (countries) {
        const country = countries.find((c) => c.shortCode === shortCode);
        return country && country.name;
      }
      return '';
    },
    [countries],
  );
  const address = useMemo(() => {
    if (contact && contact.primaryAddressId) {
      return (
        contact.addresses &&
        contact.addresses.find((c) => c._id === contact.primaryAddressId)
      );
    }
    return undefined;
  }, [contact]);
  const phoneNumber = useMemo(() => {
    if (contact && contact.primaryPhoneNumberId) {
      return (
        contact.phoneNumbers &&
        contact.phoneNumbers.find((c) => c._id === contact.primaryPhoneNumberId)
      );
    }
    return undefined;
  }, [contact]);
  const email = useMemo(() => {
    if (contact && contact.primaryEmailId) {
      return (
        contact.emails &&
        contact.emails.find((c) => c._id === contact.primaryEmailId)
      );
    }
    return undefined;
  }, [contact]);
  const extraAddressData =
    address &&
    [address.city, address.state, address.zipCode].filter((i) => !!i);
  return (
    <>
      <Text style={{ fontWeight: 600, fontSize: 18 }}>
        {`${contact.fullName} (${
          ContactTypes[contact.contactType] &&
          ContactTypes[contact.contactType].label
        })`}
      </Text>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
      >
        <div style={{ marginBottom: 8, marginRight: 16 }}>
          <Title level={5} style={{ margin: 0 }}>
            Primary Address
          </Title>
          {address ? (
            <>
              {address.address1 && (
                <div>
                  <Text>{address.address1}</Text>
                </div>
              )}
              {address.address2 && (
                <div>
                  <Text>{address.address2}</Text>
                </div>
              )}
              {extraAddressData.length ? (
                <div>
                  <Text>{extraAddressData.join(', ')}</Text>
                </div>
              ) : null}
              <div style={{ marginBottom: 8 }}>
                <Text>{getCountry(address.country)}</Text>
              </div>
            </>
          ) : (
            <div>
              <Text>Not Specified</Text>
            </div>
          )}
        </div>
        <div style={{ marginBottom: 8, marginRight: 16 }}>
          <Title level={5} style={{ margin: 0 }}>
            Primary Phone Number
          </Title>
          <div>
            <Text>{phoneNumber ? phoneNumber.phone : 'Not Specified'}</Text>
          </div>
        </div>
        <div style={{ marginBottom: 8 }}>
          <Title level={5} style={{ margin: 0 }}>
            Primary Email
          </Title>
          <div>
            <Text>{email ? email.email : 'Not Specified'}</Text>
          </div>
        </div>
      </div>
    </>
  );
}

export default DisplayContactA10;
