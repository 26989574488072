import { gql } from '@apollo/client';

export const getAttachmentUploadUrlMutation = gql`
  mutation GetAttachmentUploadUrlForAdmin(
    $attachment: GetAttachmentUploadUrlRequest!
  ) {
    getAttachmentUploadUrlForAdmin(attachment: $attachment) {
      url
      fields
    }
  }
`;

export const extractGetAttachmentUploadUrl = (data) =>
  data.getAttachmentUploadUrlForAdmin;

export const saveProfilePhotoMutation = gql`
  mutation SaveProfilePhotoForAdmin($profilePhoto: SaveProfilePhotoInput!) {
    saveProfilePhotoForAdmin(profilePhoto: $profilePhoto) {
      _id
      desc
      s3Key
      size
      filename
      contentType
      tags
      url
      uploaded
      processed
      profilePhotoFor
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
