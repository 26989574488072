import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

const { Paragraph } = Typography;

const deleteTransferMutation = gql`
  mutation DeleteTransfer($_id: ID!) {
    deleteTransfer(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteTransferModal({ visible, onCancel, onFinish }) {
  const transfer = visible;
  const [loading, setLoading] = useState(false);
  const [deleteTransfer] = useMutation(deleteTransferMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteTransfer({
        variables: {
          _id: transfer._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Transfer deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [transfer, deleteTransfer, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Transfer"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>Are you sure you want to delete this transfer?</Paragraph>
      <Paragraph style={{ fontWeight: 600 }}>
        {transfer?.description}
        <br />
        {displayMoney(getNumberValue(transfer?.amount) / 10000)}
      </Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteTransferModal;
