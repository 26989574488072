import sharedSettings from '@aims/shared/sharedSettings';
import { Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

function PayoutPeopleGroupColumn({ record }) {
  const pg = record.adoptionCopy && record.adoptionCopy.peopleGroup;
  return (
    pg && (
      <>
        <div className="row1">
          <Title
            level={5}
            style={{ marginBottom: 0 }}
          >{`${pg.nameAcrossCountries} / ${pg.country}`}</Title>
        </div>
        <div className="right">
          <Text style={{ color: sharedSettings.colors.textGray }}>
            {pg._id}
          </Text>
        </div>
      </>
    )
  );
}

export default PayoutPeopleGroupColumn;
