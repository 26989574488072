import { gql, useMutation } from '@apollo/client';

export const updateGoogleContactMutation = gql`
  mutation UpdateGoogleContact($contactId: ID!) {
    updateGoogleContact(contactId: $contactId) {
      contact {
        _id
      }
    }
  }
`;

function useUpdateGoogleContact() {
  const [updateGoogleContact] = useMutation(updateGoogleContactMutation);
  return updateGoogleContact;
}

export default useUpdateGoogleContact;
