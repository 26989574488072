import { Button, Card, Empty, List, Typography } from 'antd';
import React, { useState } from 'react';
import HistoryV2Card from '../pages/historyV2/HistoryV2Card';
import useHistoryV2 from '../pages/historyV2/use-history-v2';
import settings from '../settings';

const { Title } = Typography;

const queryId = 'anyHistory';

function AnyHistory({ filters, filterFunc, title }) {
  const [numShown, setNumShown] = useState(settings.smallPage);
  const { loading, data: history } = useHistoryV2({
    filters,
    queryId,
    sortByFunc: (a, b) => {
      return b.changedAt?.localeCompare(a.changedAt);
    },
    filterFunc,
  });

  return (
    <div>
      <Title level={2}>{title}</Title>
      {loading && !history.length ? (
        <List
          dataSource={[0, 0, 0, 0]}
          renderItem={() => (
            <List.Item style={{ border: 'none' }}>
              <Card
                loading={true}
                styles={{
                  body: {
                    textAlign: 'left',
                    minWidth: 300,
                  },
                }}
              />
            </List.Item>
          )}
        />
      ) : (
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={history.slice(0, numShown)}
          renderItem={(item) => (
            <List.Item
              key={item._id}
              style={{ borderBottom: 'none', display: 'flex' }}
            >
              <HistoryV2Card history={item} />
              <div style={{ flex: 1 }} />
            </List.Item>
          )}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Activity"
              />
            ),
          }}
          loadMore={
            history.length > numShown && (
              <div className="load-more">
                <Button
                  onClick={() => setNumShown(numShown + settings.smallPage)}
                >
                  Show More
                </Button>
              </div>
            )
          }
        />
      )}
    </div>
  );
}

export default AnyHistory;
