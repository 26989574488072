import sharedSettings from '@aims/shared/sharedSettings';
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../../components/PhotoCircle';
import AddContactToHouseholdModal from './AddContactToHouseholdModal';
import ContactChangeSomethingModalA10 from './ContactChangeSomethingModalA10';
import ContactMakePrimaryModalA10 from './ContactMakePrimaryModalA10';
import RemoveFromHouseholdModalModal from './RemoveFromHouseholdModalModal';

const { Title, Text } = Typography;

function HouseholdMembers({ parent }) {
  const [updating, setUpdating] = useState();
  const handleUpdate = (contact) => setUpdating(contact);
  const onFinishUpdate = () => setUpdating(undefined);
  const onCancelUpdate = () => setUpdating(undefined);

  const [removing, setRemoving] = useState();
  const handleRemove = (contact) => setRemoving({ household: parent, contact });
  const onFinishRemove = () => setRemoving(undefined);
  const onCancelRemove = () => setRemoving(undefined);

  const [adding, setAdding] = useState();
  const handleAdd = () => setAdding(true);
  const onAddDone = () => setAdding(false);
  const onAddCancel = () => setAdding(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 40, marginRight: 8 }}>
            <PhotoCircle
              photoUrl={record.primaryThumbnailUrl}
              name={record.fullName}
              customSize={40}
              color={sharedSettings.colors.householdColor}
              style={{
                padding: 0,
                height: 40,
                width: 40,
              }}
            />
          </div>
          <Text>{record.fullName}</Text>
        </div>
      ),
    },
    {
      title: 'Primary?',
      key: 'primary',
      render: (text, record) =>
        record._id === parent.primaryContactId ? (
          <Typography>
            <StarFilled style={{ marginRight: 4 }} />
            Yes
          </Typography>
        ) : (
          'No'
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {parent.primaryContactId !== record._id && (
            <Tooltip title="Make Primary Contact">
              <Button
                onClick={() => handleUpdate(record)}
                icon={<StarOutlined />}
              />
            </Tooltip>
          )}
          <Tooltip title="View Contact Page">
            <Link to={`/contact/view/${record._id}/dashboard`}>
              <Button icon={<EyeOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Remove from Household">
            <Button
              onClick={() => handleRemove(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="container">
      <Title level={2} style={{ marginBottom: 0 }}>
        Household Members
      </Title>
      <Button
        type="text"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        style={{ marginBottom: 16, marginLeft: -16 }}
      >
        Add a Household Member
      </Button>
      <Table
        columns={columns}
        dataSource={parent?.householdMembers}
        pagination={false}
        rowKey="_id"
      />
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      >
        <ContactMakePrimaryModalA10 child={updating} />
      </ContactChangeSomethingModalA10>
      <RemoveFromHouseholdModalModal
        visible={removing}
        onDone={onFinishRemove}
        onCancel={onCancelRemove}
      />
      <AddContactToHouseholdModal
        household={parent}
        adding={adding}
        onDone={onAddDone}
        onCancel={onAddCancel}
      />
      <style jsx>{`
        .container {
          margin-bottom: 32px;
        }
      `}</style>
    </div>
  );
}

export default HouseholdMembers;
