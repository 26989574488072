import { Select, Typography } from 'antd';
import React from 'react';
import { AccountTypesJ25 } from '../constants';

const { Text } = Typography;

function SelectAccountType({ value, onChange, filterFunc, ...props }) {
  return (
    <Select value={value} onChange={onChange} {...props}>
      {Object.values(AccountTypesJ25)
        .filter(filterFunc || (() => true))
        .map(({ key, label }) => (
          <Select.Option key={key} value={key} title={label}>
            {label}
          </Select.Option>
        ))}
    </Select>
  );
}

export default SelectAccountType;
