import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import CSPage from '../components/CSPage';
import CSPageHeader from '../components/CSPageHeader';
import SelectContact from '../pages/contacts/SelectContact';
import SelectHolisticProject from '../pages/holistic-projects/SelectHolisticProject';
import settings from '../settings';
import ProjectSponsorshipsTable from './ProjectSponsorshipsTable';
import {
  allProjectSponsorshipsQuery,
  extractProjectSponsorships,
} from './constants';

const keyMap = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  startDate: 'startDate',
  sponsorName: 'sponsorName.keyword',
  projectName: 'projectName.keyword',
  amount: 'amount',
};

const projectId = undefined;

function AllProjectSponsorshipsPage() {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const [showFilters, setShowFilters] = useState(false);
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({
    projectId,
  });

  const {
    error,
    loading,
    data: sponsorships,
    search: sponsorshipsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allProjectSponsorshipsQuery,
    extract: extractProjectSponsorships,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'all-project-sponsorships',
  });

  const handleSortByChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[value], order: sortOrder },
        { key: '_score', order: 'DESC' },
      ];
      setSortedBy(value);
      refetch();
    },
    [sortOrder, refetch],
  );
  const handleSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[sortedBy], order: value },
        { key: '_score', order: 'DESC' },
      ];
      setSortOrder(value);
      refetch();
    },
    [sortedBy, refetch],
  );
  const handleSearch = useCallback(
    (event) => {
      sortBy.current = [
        { key: '_score', order: 'DESC' },
        { key: keyMap[sortedBy], order: sortOrder },
      ];
      sponsorshipsSearch(event.currentTarget.value);
    },
    [sponsorshipsSearch, sortOrder, sortedBy],
  );
  const handleProjectChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        projectId: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleRecurringChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        recurring: value,
      };
      refetch();
    },
    [refetch],
  );
  const handleSponsorChange = useCallback(
    (value) => {
      filters.current = {
        ...filters.current,
        contactId: value,
      };
      refetch();
    },
    [refetch],
  );

  return (
    <>
      <CSPage
        containerStyle={{ maxWidth: 'unset' }}
        title="Holistic Project Sponsorships"
      >
        <CSPageHeader
          title="Holistic Project Sponsorships"
          // topActions={[
          //   <Button
          //     key="add"
          //     type="link"
          //     icon={<PlusCircleOutlined />}
          //     size="large"
          //   >
          //     Create a project sponsorship
          //   </Button>,
          // ]}
        />
        <div>
          <Form layout="vertical">
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: 16,
              }}
            >
              {showFilters && (
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => {
                    setShowFilters(false);
                  }}
                >
                  Hide Filters
                </Button>
              )}
              {!showFilters && (
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => setShowFilters(true)}
                >
                  Show Filters
                </Button>
              )}
              <Button icon={<RedoOutlined />} onClick={refetch}>
                Refresh
              </Button>
            </div>
            {showFilters && (
              <div style={{ marginBottom: 16 }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Form.Item
                    label="Search"
                    style={{ flex: 3, marginRight: 16, minWidth: 200 }}
                  >
                    <Input onChange={handleSearch} />
                  </Form.Item>
                  <Form.Item
                    label="Sort By"
                    style={{ flex: 2, marginRight: 16, minWidth: 200 }}
                  >
                    <Select
                      placeholder="Select ..."
                      value={sortedBy}
                      onChange={handleSortByChange}
                      disabled={loading}
                    >
                      <Select.Option value="updatedAt">
                        Last Updated
                      </Select.Option>
                      <Select.Option value="createdAt">
                        Created Date
                      </Select.Option>
                      <Select.Option value="startDate">
                        Start Date
                      </Select.Option>
                      <Select.Option value="sponsorName">
                        Sponsor Name
                      </Select.Option>
                      <Select.Option value="projectName">
                        Project Name
                      </Select.Option>
                      <Select.Option value="amount">Amount</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Sort Order"
                    style={{ flex: 1, minWidth: 100 }}
                  >
                    <Select
                      placeholder="Select ..."
                      value={sortOrder}
                      onChange={handleSortOrderChange}
                      disabled={loading}
                    >
                      <Select.Option value="ASC">Ascending</Select.Option>
                      <Select.Option value="DESC">Descending</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {!projectId && (
                    <Form.Item
                      label="Holistic Project"
                      style={{ flex: 2, minWidth: 200, marginRight: 16 }}
                    >
                      <SelectHolisticProject
                        disabled={loading}
                        onChange={handleProjectChange}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Sponsor"
                    style={{ flex: 2, minWidth: 200, marginRight: 16 }}
                  >
                    <SelectContact
                      disabled={loading}
                      onChange={handleSponsorChange}
                      filters={{
                        isRegionalCoord: true,
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Donation Type"
                    style={{ flex: 1, minWidth: 200 }}
                  >
                    <Select
                      placeholder="Select ..."
                      onChange={handleRecurringChange}
                      disabled={loading}
                      allowClear
                    >
                      <Select.Option value={true}>
                        Monthly Recurring
                      </Select.Option>
                      <Select.Option value={false}>One-Time</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
          </Form>
        </div>
        <ProjectSponsorshipsTable
          sponsorships={sponsorships}
          loading={loading}
          hasNextPage={hasNextPage}
          next={next}
        />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default AllProjectSponsorshipsPage;
