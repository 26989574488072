import { Select, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import { gql, useQuery } from '@apollo/client';

const { Text } = Typography;

const selectContactQuery = gql`
  query SelectContactQuery(
    $first: Int
    $after: String
    $filters: ContactFiltersForUser
    $sortBy: [SortBy]
  ) {
    allContactsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactType
          fullName
        }
      }
    }
  }
`;

const fwsearchOptions = {
  includeScore: true,
  keys: ['fullName', '_id'],
};

function SelectFromMyFieldWorkers({
  value,
  onChange,
  regionalCoordId,
  ...props
}) {
  const response = useQuery(selectContactQuery, {
    variables: {
      first: 1000,
      after: null,
      filters: {
        contactType: 'CONTACT',
        isFieldWorker: true,
        regionalCoordId,
      },
    },
    fetchPolicy: 'cache-first',
  });
  const allFws = useMemo(
    () => response?.data?.allContactsForUser?.edges?.map((e) => e.node),
    [response],
  );
  const [fws, setFws] = useState([]);
  useEffect(() => {
    if (allFws) {
      setFws(allFws);
    } else {
      setFws([]);
    }
  }, [allFws]);

  const search = useCallback(
    (v) => {
      if (v) {
        const fuse = new Fuse(allFws, fwsearchOptions);
        let _data = fuse
          .search(v)
          .sort((a, b) => a.score - b.score)
          .map((i) => i.item);
        setFws(_data);
      } else {
        setFws(allFws);
      }
    },
    [allFws],
  );

  return (
    <Select
      showSearch
      filterOption={(input, option) => true}
      onSearch={search}
      autoComplete="chrome-off"
      onChange={onChange}
      placeholder="Select a field worker"
      value={value}
      allowClear
      {...props}
    >
      {fws.map((fw) => (
        <Select.Option key={fw._id} value={fw._id} title={fw.fullName}>
          <div>
            <Text>{fw.fullName}</Text>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectFromMyFieldWorkers;
