import { useCountries } from '@aims/shared/shared/use-countries';
import { Typography } from 'antd';
import React, { useCallback } from 'react';

const { Title, Text } = Typography;

function DisplayPayerA10({ payer }) {
  const { countries } = useCountries();

  const getCountry = useCallback(
    (shortCode) => {
      if (countries) {
        const country = countries.find((c) => c.shortCode === shortCode);
        return country && country.name;
      }
      return '';
    },
    [countries],
  );
  const payerAddressExtra = payer?.address
    ? [
        payer.address.city,
        payer.address.state,
        payer.address.zipOrPostCode,
      ].filter((a) => a)
    : [];
  return (
    <>
      <Title level={5} style={{ marginBottom: 0 }}>
        Payer
      </Title>
      <Text style={{ fontWeight: 600 }}>{payer?.fullName}</Text>
      <br />
      <Text>{payer?.emailAddress}</Text>
      <br />
      <Text>{payer?.phoneNumber}</Text>
      <br />
      {payer?.address && (
        <>
          {payer.address.line1 && (
            <>
              <Text>{payer.address.line1}</Text>
              <br />
            </>
          )}
          {payer.address.line2 && (
            <>
              <Text>{payer.address.line2}</Text>
              <br />
            </>
          )}
          {payerAddressExtra.length ? (
            <div>
              <Text>{payerAddressExtra.join(', ')}</Text>
            </div>
          ) : null}
          <div style={{ marginBottom: 8 }}>
            <Text>{getCountry(payer.address.country)}</Text>
          </div>
        </>
      )}
    </>
  );
}

export default DisplayPayerA10;
