import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../SelectContact';
import { ContactTypes } from '../../constants';
import ContactSomethingForm from './ContactSomethingForm';

const { Text, Paragraph, Title } = Typography;

export const addContactToOrganizationMutation = gql`
  mutation AddContactToOrganization(
    $contactId: ID!
    $orgId: ID!
    $role: String!
  ) {
    addContactToOrganization(
      contactId: $contactId
      orgId: $orgId
      role: $role
    ) {
      contactXOrg {
        _id
      }
    }
  }
`;

function AddOrganizationBtnAndModal({ contact }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [addContactToOrganization] = useMutation(
    addContactToOrganizationMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { orgId, role } = values;
        await addContactToOrganization({
          variables: {
            contactId: contact._id,
            orgId,
            role,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Contact successfully added to organization',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error adding contact to organization',
        });
      }
      setSaving(false);
    },
    [contact, addContactToOrganization, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [editing, form]);

  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{ body: { paddingTop: 8 } }}
      >
        <ContactSomethingForm
          title="Add Contact to an Organization"
          form={form}
          onFinish={handleFinish}
          onCancel={onCancel}
          saving={saving}
        >
          <Form.Item
            label="Organization"
            name="orgId"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <SelectContact
              loading={saving}
              disabled={saving}
              filters={{
                contactType: ContactTypes.ORG.key,
              }}
              placeholder="Select an organization ..."
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            extra="e.g., CEO, Founder, Sales ..."
          >
            <Input disabled={saving} />
          </Form.Item>
        </ContactSomethingForm>
      </Modal>
      <Button
        onClick={handleEdit}
        icon={<PlusOutlined />}
        type="text"
        style={{ marginBottom: 16, marginLeft: -16 }}
      >
        Add Organization
      </Button>
    </>
  );
}

export default AddOrganizationBtnAndModal;
