import {
  DeleteOutlined,
  DownloadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Button, Progress, Space, Typography } from 'antd';
import filesize from 'filesize';
import React from 'react';
import ellipsis from 'text-ellipsis';

const { Text } = Typography;

const AttachmentCard = ({
  thumbnailUrl,
  fileName,
  fileSize,
  onUploadDelete,
  deleted = false,
  progress,
  url,
  style = {},
}) => {
  return (
    <Button
      style={{
        ...style,
      }}
      target={(!deleted && !onUploadDelete && '_blank') || undefined}
      download={(!deleted && !onUploadDelete && fileName) || undefined}
      href={(!deleted && !onUploadDelete && url) || undefined}
      onClick={(!deleted && onUploadDelete) || undefined}
    >
      <Space>
        {progress !== undefined && (
          <Progress type="circle" percent={progress.toFixed(0)} width={48} />
        )}
        {progress === undefined && (
          <>
            {thumbnailUrl ? (
              <img style={{ height: 48 }} src={thumbnailUrl} />
            ) : (
              <PaperClipOutlined style={{ fontSize: 18 }} />
            )}
          </>
        )}
        <div>
          {`${ellipsis(fileName, 20)} (${filesize(fileSize, {
            round: 1,
          })})`}
        </div>
        {!deleted && onUploadDelete && (
          <div>
            <DeleteOutlined />
          </div>
        )}
        {deleted && (
          <div style={{ marginRight: 8 }}>
            <Text type="danger">Will be Deleted</Text>
          </div>
        )}
        {!deleted && !onUploadDelete && url && (
          <div>
            <DownloadOutlined />
          </div>
        )}
      </Space>
    </Button>
  );
};

export default AttachmentCard;
