import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import { Space } from 'antd';
import React from 'react';
import ApproveTrainQuarterlyBtn from './ApproveTrainQuarterlyBtn';
import EditTrainQuarterlyBtn from './EditTrainQuarterlyBtn';
import MarkTrainQuarterlyPendingBtn from './MarkTrainQuarterlyPendingBtn';
import PutTrainQuarterlyOnHoldBtn from './PutTrainQuarterlyOnHoldBtn';
import RejectTrainQuarterlyBtn from './RejectTrainQuarterlyBtn';
import useProfile from '../../../../shared/use-profile';

const ReviewButtons = ({ report, onSuccess }) => {
  const profile = useProfile();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {report.status === ReportApprovalStatuses.DRAFT.key && (
            <EditTrainQuarterlyBtn report={report} />
          )}
          {report.status === ReportApprovalStatuses.PENDING.key && (
            <>
              <ApproveTrainQuarterlyBtn report={report} onSuccess={onSuccess} />
              <PutTrainQuarterlyOnHoldBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectTrainQuarterlyBtn report={report} onSuccess={onSuccess} />
            </>
          )}
          {report.status === ReportApprovalStatuses.REJECTED.key &&
            profile.username === 'superadmin' && (
              <MarkTrainQuarterlyPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.APPROVED.key &&
            profile.username === 'superadmin' && (
              <MarkTrainQuarterlyPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.HOLD.key && (
            <>
              <ApproveTrainQuarterlyBtn report={report} onSuccess={onSuccess} />
              <MarkTrainQuarterlyPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectTrainQuarterlyBtn report={report} onSuccess={onSuccess} />
            </>
          )}
          {/* <PrintReportBtn /> */}
        </Space>
      </div>
    </>
  );
};

export default ReviewButtons;
