import DateFormItem from '@aims/shared/components/DateFormItem';
import { displayMoney, generateUuid } from '@aims/shared/shared/utils';
import { gql, useMutation } from '@apollo/client';
import { Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';

export const createHpInstallmentPaymentMutation = gql`
  mutation CreateHpInstallment($payment: HpInstallmentPaymentCreateInput!) {
    createHpInstallmentPayment(payment: $payment)
  }
`;

const { Paragraph } = Typography;

const AddPaymentModal = ({ visible, onCancel, onFinish }) => {
  const { hpInstallment } = visible || {};
  const [saving, setSaving] = useState(false);
  const [createPayment] = useMutation(createHpInstallmentPaymentMutation);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [form, visible]);

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      const { paidAmount, paidAt } = _values;
      try {
        await createPayment({
          variables: {
            payment: {
              _id: generateUuid(),
              hpInstallmentId: hpInstallment._id,
              paidAmount,
              paidAt,
            },
          },
        });

        notification.success({
          message: 'Saved',
          description: 'Payment added successfully',
        });
        onFinish(hpInstallment._id);
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [createPayment, hpInstallment, onFinish],
  );

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateformat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
  });
  const dueOnText =
    hpInstallment && dateformat.format(new Date(hpInstallment.dueAt));
  const remaining =
    hpInstallment && (hpInstallment.amount - hpInstallment.paidAmount) / 10000;

  return (
    <Modal
      header={null}
      footer={null}
      open={visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={!saving}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 16 } }}
    >
      <TaskSomethingForm
        title="Add Installment Payment"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
        saveText="Yes"
      >
        {hpInstallment && (
          <Paragraph>{`${displayMoney(
            remaining,
          )} remaining for the installment due on ${dueOnText}`}</Paragraph>
        )}
        <Form.Item
          label="How much was paid (USD)?"
          name="paidAmount"
          initialValue={0}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DollarX4InputNumber disabled={saving} />
        </Form.Item>
        <DateFormItem
          name="paidAt"
          label="When was it paid?"
          rules={[{ required: true, message: 'This field is required' }]}
          disabled={saving}
        />
      </TaskSomethingForm>
    </Modal>
  );
};

export default AddPaymentModal;
