import sharedSettings from '@aims/shared/sharedSettings';
import { gql, useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import useProfile from '../../shared/use-profile';

export const subscribeToMutation = gql`
  mutation SubscribeToUser($userId: ID!) {
    subscribeToUser(userId: $userId)
  }
`;

export const unsubscribeFromMutation = gql`
  mutation UnsubscribeFromUser($userId: ID!) {
    unsubscribeFromUser(userId: $userId)
  }
`;

function SubscribeToUserButton({ userId }) {
  const [subscribeTo] = useMutation(subscribeToMutation);
  const [unsubscribeFrom] = useMutation(unsubscribeFromMutation);
  const profile = useProfile();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const subscribed = useMemo(() => {
    if (profile.subscribedTo) {
      return profile.subscribedTo.includes(`user:${userId}`);
    }
  }, [profile, userId]);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      if (subscribed) {
        await unsubscribeFrom({
          variables: {
            userId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Unsubscribed',
        });
      } else {
        await subscribeTo({
          variables: {
            userId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Successfully Subscribed',
        });
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [userId, unsubscribeFrom, subscribeTo, subscribed]);

  return (
    <Button
      danger={!!error}
      type="text"
      onClick={handleClick}
      style={{ color: !error ? sharedSettings.colors.textGray : undefined }}
      loading={loading}
    >
      {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  );
}

export default SubscribeToUserButton;
