import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import ContactAddPhoneInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactAddPhoneInnerModalA10';
import ContactChangeSomethingModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactChangeSomethingModalA10';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  StarFilled,
} from '@ant-design/icons';
import ContactUpdatePhoneInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactUpdatePhoneInnerModalA10';
import ContactDeletePhoneInnerModalA10 from '../../pages/contacts/ViewContact/ContactInfo/ContactDeletePhoneInnerModalA10';

const { Title, Text } = Typography;

function AnyPhoneNumbers({ parent }) {
  const [updating, setUpdating] = useState();
  const handleUpdate = (phone) => setUpdating({ phone });
  const onFinishUpdate = () => setUpdating(undefined);
  const onCancelUpdate = () => setUpdating(undefined);

  const [deleting, setDeleting] = useState();
  const handleDelete = (phone) => setDeleting({ phone });
  const onFinishDelete = () => setDeleting(undefined);
  const onCancelDelete = () => setDeleting(undefined);
  const columns = [
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Primary?',
      key: 'primary',
      render: (text, record) =>
        record._id === parent.primaryPhoneNumberId ? (
          <Typography>
            <StarFilled style={{ marginRight: 4 }} />
            Yes
          </Typography>
        ) : (
          'No'
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              onClick={() => handleUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Remove">
            <Button
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="container">
      <Title level={2} style={{ marginBottom: 0 }}>
        Phone Numbers
      </Title>
      <ContactChangeSomethingModalA10
        contact={parent}
        btnProps={{
          type: 'text',
          icon: <PlusOutlined />,
          style: { marginBottom: 16, marginLeft: -16 },
        }}
        btnText="Add a Phone Number"
      >
        <ContactAddPhoneInnerModalA10 />
      </ContactChangeSomethingModalA10>
      <Table
        columns={columns}
        dataSource={parent.phoneNumbers}
        pagination={false}
        rowKey="_id"
      />
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      >
        <ContactUpdatePhoneInnerModalA10 phone={updating?.phone} />
      </ContactChangeSomethingModalA10>
      <ContactChangeSomethingModalA10
        contact={parent}
        noBtn
        editing={deleting}
        onDone={onFinishDelete}
        onCancel={onCancelDelete}
      >
        <ContactDeletePhoneInnerModalA10 phone={deleting?.phone} />
      </ContactChangeSomethingModalA10>
      <style jsx>{`
        .container {
          margin-bottom: 32px;
        }
      `}</style>
    </div>
  );
}

export default AnyPhoneNumbers;
