import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Typography } from 'antd';
import React, { useMemo } from 'react';

const { Text } = Typography;
const dateFormat = new Intl.DateTimeFormat('en-US', {
  timeStyle: 'short',
  dateStyle: 'short',
});

function HistoryV2Card({ history }) {
  const date = useMemo(
    () => dateFormat.format(new Date(history.changedAt)),
    [history],
  );
  return (
    <Card bordered={false}>
      <div style={{ display: 'flex', marginBottom: 8 }}>
        <div>
          <div>
            <Text style={{ color: sharedSettings.colors.contactColor }}>
              Change History
            </Text>
          </div>
          <Text>{history.changedByName}</Text>
        </div>
        <div style={{ flex: 1, minWidth: 16 }} />
        <div>
          <Text>{date}</Text>
        </div>
      </div>

      <div>
        <Text style={{ fontWeight: 'bold' }}>{`${history.typename}`}</Text>
      </div>
      {history.changes?.length === 0 && (
        <div>
          <Text>No changes.</Text>
        </div>
      )}
      {history.changes.map((change, index) => (
        <div key={index}>
          <Text>{change}</Text>
        </div>
      ))}
    </Card>
  );
}

export default HistoryV2Card;
