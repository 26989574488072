import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import React from 'react';
import { AccountTypesJ25 } from '../../accounting/accounts-j25/constants';

function DisplayAccountA10({ record }) {
  const accountType = record.type && AccountTypesJ25[record.type]?.label;
  const balance = displayMoney(record?.balance?.balance);
  const fund = record?.fund?.name;
  return (
    <div>
      <div style={{ fontSize: 11 }}>{accountType}</div>
      <div
        style={{
          fontSize: 12,
          color: sharedSettings.colors.primary,
        }}
      >
        {`${record.name} Account`}
      </div>
      <div style={{ fontSize: 12, color: sharedSettings.colors.textGray }}>
        {fund}
      </div>
      {balance && <div style={{ fontSize: 11 }}>{balance}</div>}
    </div>
  );
}

export default DisplayAccountA10;
