import React, { useCallback } from 'react';
import YesNoModal from '../../../../components/YesNoModal';

function ContactDeleteEmailInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  email: oldEmail,
}) {
  const _handleFinish = useCallback(async () => {
    const existingEmails = (contact.emails || [])
      .filter((e) => e._id !== oldEmail._id)
      .reduce((prev, curr) => {
        const { email, _id, label } = curr;
        prev.push({ email, _id, label });
        return prev;
      }, []);
    const existingPrimaryEmailid = contact.primaryEmailId;
    const primaryEmail = existingEmails.find(
      (e) => e._id === existingPrimaryEmailid,
    );
    let newPrimaryId = null;
    if (primaryEmail) {
      newPrimaryId = primaryEmail._id;
    } else if (!primaryEmail && existingEmails.length > 0) {
      newPrimaryId = existingEmails[0]._id;
    }
    handleFinish({
      emails: existingEmails,
      primaryEmailId: newPrimaryId,
    });
  }, [handleFinish, contact, oldEmail]);
  return (
    <YesNoModal
      title="Delete Email from Contact"
      question={`Are you sure you want to delete this email ${oldEmail?.email}?`}
      yesText="Delete"
      noText="Cancel"
      onYes={_handleFinish}
      onNo={onCancel}
      visible={!!contact}
      loading={saving}
    />
  );
}

export default ContactDeleteEmailInnerModalA10;
