import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

const { Text } = Typography;

export const allJPPeopleClustersQuery = gql`
  query AllJPPeopleClusters {
    allJPPeopleClusters {
      _id
      name
      affinityBlocId
    }
  }
`;

function SelectpeopleCluster({ disabled, value, onChange, filters }) {
  const { loading, error, data } = useQuery(allJPPeopleClustersQuery);
  const peopleClusters = useMemo(() => {
    let _data = [];
    if (data) {
      _data = data.allJPPeopleClusters;
      if (filters && filters.affinityBlocId) {
        _data = _data.filter(
          (d) => d.affinityBlocId === filters.affinityBlocId,
        );
      }
    }
    return _data;
  }, [data, filters]);
  return (
    <Select
      showSearch
      // optionFilterProp="children"
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a people cluster"
      value={value}
      allowClear
      loading={loading}
    >
      {peopleClusters.map((peopleCluster) => (
        <Select.Option
          key={peopleCluster._id}
          value={peopleCluster._id}
          title={peopleCluster.name}
        >
          <div className="peopleCluster-option">
            <div>
              <Text>{peopleCluster.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectpeopleCluster;
