import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import TaskSomethingForm from './TaskSomethingForm';

const { Title, Text, Paragraph } = Typography;

function DisplayPgInfoShort({ peopleGroup }) {
  return (
    <div style={{ display: 'flex' }}>
      <PhotoCircle
        photoUrl={peopleGroup.primaryThumbnailUrl}
        name={peopleGroup.nameAcrossCountries}
        style={{
          height: 60,
          width: 60,
          paddingBottom: 'unset',
          marginRight: 16,
        }}
        color={sharedSettings.colors.contactColor}
      />
      <div>
        <Paragraph
          style={{ marginBottom: 0 }}
        >{`${peopleGroup.nameAcrossCountries} / ${peopleGroup.country}`}</Paragraph>
        <div>
          <Text style={{ color: sharedSettings.colors.textGray }}>
            {peopleGroup._id}
          </Text>
        </div>
        <div>
          <Link to={`/people-group/view/${peopleGroup._id}/info`}>
            People Group Page
          </Link>
        </div>
      </div>
    </div>
  );
}

function AssociatedPeopleGroup({ task, onSubmit }) {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onFinishEdit = () => setEditing(false);

  const onFinish = useCallback(
    async ({ peopleGroupId }) => {
      setSaving(true);
      try {
        onSubmit({ peopleGroupId: peopleGroupId || null });
        onFinishEdit();
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'There was an error saving your task.',
        });
      }
      setSaving(false);
    },
    [onSubmit],
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (task.peopleGroupId) {
      form.setFieldsValue({ peopleGroupId: task.peopleGroupId });
    }
  }, [task, form]);

  return (
    <div style={{ marginBottom: 16 }}>
      {!editing ? (
        <map onClick={onEdit} style={{ width: '100%', cursor: 'pointer' }}>
          <Title level={4}>Unreached People Group</Title>
          {task?.peopleGroup ? (
            <DisplayPgInfoShort peopleGroup={task.peopleGroup} />
          ) : (
            <Paragraph style={{ minHeight: '36px' }}>
              Click to add people group
            </Paragraph>
          )}
        </map>
      ) : (
        <TaskSomethingForm
          title="Unreached People Group"
          form={form}
          onFinish={onFinish}
          onCancel={onCancelEdit}
          saving={saving}
        >
          <Form.Item
            label="PeopleGroup"
            extra="Associate this task with a people group"
            name="peopleGroupId"
          >
            <SelectPeopleGroup disabled={saving} />
          </Form.Item>
        </TaskSomethingForm>
      )}
    </div>
  );
}

export default AssociatedPeopleGroup;
