import React, { useContext } from 'react';
import { Typography, Button, Dropdown, Menu, notification } from 'antd';
import useProfile from '../shared/use-profile';
import {
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import PhotoCircle from './PhotoCircle';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../shared/auth-context';

const { Title } = Typography;

function CSPageHeader({
  title,
  topActions = null,
  backActions = null,
  titleComponent = null,
  style = null,
}) {
  const profile = useProfile();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  return (
    <div className="header" style={style}>
      {profile && (
        <div className="profile-menu">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="edit-profile" icon={<UserOutlined />}>
                  <Link to="/edit-profile">Profile</Link>
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />}>
                  <Link to="/settings">Settings</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="sign-out"
                  icon={<LogoutOutlined />}
                  onClick={() => {
                    authContext.signOut();
                    history.push('/sign-in');
                    notification.open({
                      message: 'Success',
                      description: `Successfully signed out!`,
                    });
                  }}
                >
                  Sign Out
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              type="text"
              style={{ display: 'flex', alignItems: 'center' }}
              icon={
                <PhotoCircle
                  photoUrl={profile.primaryThumbnailUrl}
                  size="sm"
                  name={profile.name}
                  style={{
                    height: 36,
                    paddingBottom: 'unset',
                    marginRight: 8,
                  }}
                />
              }
            >
              {profile.name}
            </Button>
          </Dropdown>
        </div>
      )}
      <div className="back-actions">{backActions}</div>
      {titleComponent}
      {!titleComponent && (
        <Title
          className="cs-header-title"
          style={{ marginBottom: 8, marginTop: 0 }}
        >
          {title}
        </Title>
      )}
      <div className="top-actions">{topActions}</div>
      <style jsx>{`
        .profile-menu {
          position: absolute;
          top: 24px;
          right: 32px;
        }
        .header {
          margin-bottom: 32px;
        }
        .back-actions {
          height: 50px;
          margin-left: -15px;
          display: flex;
        }
        .top-actions {
          margin-left: -15px;
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
      <style jsx global>{`
        .cs-header-title {
        }
        @media screen and (max-width: 992px) {
          .page {
          }
        }
        @media screen and (max-width: 600px) {
          .page {
          }
        }
      `}</style>
    </div>
  );
}

export default CSPageHeader;
