import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Button, Card, Empty, List, Skeleton, Typography } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import settings from '../../../settings';
import {
  allHolisticProjectsForAdminQuery,
  extractHolisticProjectsForAdmin,
} from '../constants';
import FilterHolisticProjects from '../FilterHolisticProjects';
import SelectProjectListCardO8 from './SelectProjectListCardO8';

const { Paragraph } = Typography;

function SelectHolisticProjectListO8({ onSelect, parentFilters, extraFilter }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    humanReadableId: -1,
    ...parentFilters,
  });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: _projects,
    search: projectSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allHolisticProjectsForAdminQuery,
    extract: extractHolisticProjectsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'holisticProjectsList',
  });

  const projects = useMemo(
    () => _projects.filter(extraFilter),
    [_projects, extraFilter],
  );

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        projectSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [projectSearch, refetch, sortedBy, sortOrder],
  );

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  return (
    <>
      <FilterHolisticProjects
        filters={filters}
        refetch={refetch}
        reportSearch={projectSearch}
        loading={loading}
        sortBy={sortBy}
        type={'holisticProjectList'}
        style={{ marginBottom: 0 }}
      />
      <Paragraph style={{ marginBottom: 24 }}>
        Only showing holistic projects that are in the Funding stage
      </Paragraph>

      {loading && !projects.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={() => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            dataSource={projects.slice(0, pageSize)}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(record) => (
              <SelectProjectListCardO8
                record={record}
                onSelect={(selected) => {
                  if (onSelect) {
                    onSelect(selected);
                  }
                }}
              />
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No projects"
                />
              ),
            }}
          />
          <div className="bottom-actions">
            {projects && projects.length <= pageSize && hasNextPage && (
              <Button onClick={next}>Load More</Button>
            )}
            {projects && projects.project > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default SelectHolisticProjectListO8;
