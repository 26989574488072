import sharedSettings from '@aims/shared/sharedSettings';
import {
  CheckCircleOutlined,
  EditOutlined,
  EyeOutlined,
  IdcardOutlined,
  LoginOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Card, Space, Tag, Tooltip, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PhotoCircle from '@aims/shared/components/PhotoCircle';
import useUser from '../../users/use-user';
import UserInvites from './UserInvites';

const { Title, Text } = Typography;

function UserCard({ contact }) {
  let userId;
  if (contact && contact.userId) {
    userId = contact.userId;
  }
  const { user } = useUser(userId);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const loading = !user;
  return (
    <>
      <Card styles={{ body: { display: 'flex' } }} loading={loading}>
        {!loading && !user && <div>Not Specified</div>}
        {user && (
          <div style={{ marginRight: 32 }}>
            <PhotoCircle
              photoUrl={user?.primaryThumbnailUrl}
              name={user?.name}
              styles={{
                container: { height: 120, width: 120 },
                placeholder: {
                  backgroundColor: sharedSettings.colors.contactColor,
                },
              }}
            />
          </div>
        )}
        {user && (
          <div>
            <div
              style={{
                fontSize: 18,
                color: sharedSettings.colors.primary,
                fontWeight: 600,
                marginBottom: 8,
              }}
            >
              {user.name}
            </div>
            {user.roles && (
              <div>
                {user.roles.map((role) => (
                  <Tag
                    color={sharedSettings.colors.primary}
                    key={role._id}
                    style={{ marginBottom: 4 }}
                  >
                    {role.label}
                  </Tag>
                ))}
              </div>
            )}
            <div>
              <UserOutlined style={{ marginRight: 8 }} />
              {user.username}
            </div>
            {user.email && (
              <div>
                <MailOutlined style={{ marginRight: 8 }} />
                {user.email}
                {user.emailVerified && (
                  <CheckCircleOutlined style={{ marginLeft: 8 }} />
                )}
              </div>
            )}
            {user.phone && (
              <div>
                <PhoneOutlined style={{ marginRight: 8 }} />
                {user.phone}
              </div>
            )}
            {user.lastSignedInAt && (
              <div>
                <LoginOutlined style={{ marginRight: 8 }} />
                {`Last sign-in: ${new Intl.DateTimeFormat(locale, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }).format(new Date(user.lastSignedInAt))}`}
              </div>
            )}
          </div>
        )}
        <div className="right">
          <Space>
            <Tooltip title="Edit">
              <Link
                to={`/user/edit/${contact.userId}?from=/contact/view/${contact._id}/user`}
              >
                <Button type="text" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
            <Tooltip title="View">
              <Link to={`/user/view/${contact.userId}/dashboard`}>
                <Button type="text" icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            {
              // <Dropdown
              //   overlay={
              //     <Menu>
              //       {!selected && (
              //         <Menu.Item
              //           icon={<HomeOutlined />}
              //           onClick={() => toggleSelected(contact)}
              //         >
              //           Add to household
              //         </Menu.Item>
              //       )}
              //       {selected && (
              //         <Menu.Item onClick={() => toggleSelected(contact)}>
              //           Unselect
              //         </Menu.Item>
              //       )}
              //     </Menu>
              //   }
              //   trigger="click"
              // >
              //   <Button icon={<MoreOutlined />} />
              // </Dropdown>
            }
          </Space>
        </div>
      </Card>
      <style jsx>{`
        .right {
          position: absolute;
          top: 8px;
          right: 16px;
        }
      `}</style>
    </>
  );
}

function UserTab({ contact, queryId, refetch }) {
  return (
    <>
      <Title level={2}>User Account</Title>
      {contact && (
        <>
          {contact.userId ? (
            <UserCard contact={contact} />
          ) : (
            <UserInvites
              contact={contact}
              queryId={queryId}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  );
}

export default UserTab;
