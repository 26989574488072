import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import React, { useCallback, useRef, useState } from 'react';
import { allMiraclesForAdminQuery, extractMiraclesForAdmin } from './constants';
import settings from '../../settings';
import { Button, Card, Empty, Form, Input, List, Skeleton } from 'antd';
import MiracleCardJ15 from './MiracleCardJ15';
import UpdateMiracleModalJ15 from './UpdateMiracleModalJ15';
import DeleteMiracleModal from './DeleteMiracleModal';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import MiracleSearchBar from './MiracleSearchBar';

function MiracleListJ15({
  filtersRef,
  refetchRef,
  hideSearch,
  queryId = 'default',
}) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: miracles,
    search: miracleSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allMiraclesForAdminQuery,
    extract: extractMiraclesForAdmin,
    first: settings.querySize,
    filters: filtersRef.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  if (refetchRef) {
    refetchRef.current = refetch;
  }

  const [pageSize, setPageSize] = useState(settings.pageSize);
  const handleShowMore = useCallback(() => {
    setPageSize(pageSize + settings.pageSize);
  }, [pageSize]);

  const [editing, setEditing] = useState();
  const onEditClick = (miracle) => setEditing({ miracle });
  const onFinishEdit = () => {
    setEditing(undefined);
    refetch();
  };
  const onCancelEdit = () => setEditing(undefined);

  const [deleting, setDeleting] = useState();
  const onDeleteClick = (miracle) => setDeleting({ miracle });
  const onFinishDelete = () => {
    setDeleting(undefined);
    refetch();
  };
  const onCancelDelete = () => setDeleting(undefined);

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        miracleSearch(search);
      } else {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [miracleSearch, filtersRef, refetch, sortedBy, sortOrder],
  );

  return (
    <>
      <MiracleSearchBar onFiltersChanged={onFiltersChanged} refetch={refetch} />
      {loading && !miracles.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <>
          <List
            className="miraclesList"
            dataSource={miracles.slice(0, pageSize)}
            grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
            rowKey="_id"
            renderItem={(item) => (
              <List.Item style={{ border: 'none' }}>
                <MiracleCardJ15
                  miracle={item}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              </List.Item>
            )}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Contacts"
                />
              ),
            }}
          />
          <div style={{ textAlign: 'center' }}>
            {miracles && miracles.length <= pageSize && hasNextPage && (
              <Button onClick={next}>Load More</Button>
            )}
            {miracles && miracles.length > pageSize && (
              <Button onClick={handleShowMore}>Show More</Button>
            )}
          </div>
        </>
      )}
      <UpdateMiracleModalJ15
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeleteMiracleModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </>
  );
}

export default MiracleListJ15;
