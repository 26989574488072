import React, { useRef, useState } from 'react';
import ProjectSponsorshipsTable from '../../../project-sponsorships/ProjectSponsorshipsTable';
import {
  allProjectSponsorshipsQuery,
  extractProjectSponsorships,
} from '../../../project-sponsorships/constants';
import settings from '../../../settings';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';

function ProjectSponsorshipsTab({ contactId, projectId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({
    contactId,
    projectId,
  });

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: sponsorships,
    search: sponsorshipsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allProjectSponsorshipsQuery,
    extract: extractProjectSponsorships,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'view-contact',
  });

  return (
    <ProjectSponsorshipsTable
      sponsorships={sponsorships}
      loading={loading}
      hasNextPage={hasNextPage}
      next={next}
      filters={filters.current}
    />
  );
}

export default ProjectSponsorshipsTab;
