import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AdoptionLevels, deleteAdoptionMutation } from './constants';
import YesNoModal from '../../components/YesNoModal';
import { deleteAdoptionAction } from '../../redux-store/adoptions-store';

const { Text } = Typography;

function DeleteAdoptionModal({ deleting, setDeleting }) {
  const { adoption, queryId } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteAdoption] = useMutation(deleteAdoptionMutation);
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await deleteAdoption({
        variables: {
          _id: adoption._id,
        },
      });
      dispatch(deleteAdoptionAction(adoption._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Sponsorship deleted successfully',
      });
      setDeleting(undefined);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [deleteAdoption, dispatch, adoption, setDeleting, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      setDeleting(undefined);
    }
  }, [setDeleting, loading]);

  return (
    <YesNoModal
      title="Delete Sponsorship"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text>{'Are you sure you want to delete this sponsorship?'}</Text>
          </div>
          <div>
            {adoption &&
              adoption.peopleGroup &&
              adoption.level &&
              AdoptionLevels[adoption.level] && (
                <>
                  <Text>{`${adoption.peopleGroup.nameAcrossCountries} / ${
                    adoption.peopleGroup.country
                  } - ${AdoptionLevels[adoption.level].label}`}</Text>
                  <br />
                </>
              )}
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!deleting}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteAdoptionModal;
