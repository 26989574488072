import { Form, Input, Radio, Select } from 'antd';
import Fuse from 'fuse.js';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';
import React, { useEffect, useMemo, useState } from 'react';
import sharedSettings from '@aims/shared/sharedSettings';
import { useCountries } from '@aims/shared/shared/use-countries';
import { useCountryRegions } from '@aims/shared/shared/use-country-regions';

import settings from '../settings';
import { generateUuid } from '../shared/utils';

const countrySearchOptions = {
  includeScore: true,
  keys: ['shortCode', 'name'],
};

const countryRegionsSearchOptions = {
  includeScore: true,
  keys: ['shortCode', 'name'],
};

function AddressFormItem({ field, loading, primary, setPrimary, form }) {
  const {
    loading: countriesLoading,
    error: countriesError,
    countries,
  } = useCountries();
  const [countrySearch, setCountrySearch] = useState('');

  const countryOptions = useMemo(() => {
    if (countrySearch) {
      // console.log('countrySearch', countrySearch);
      const fuse = new Fuse(countries, countrySearchOptions);
      const listItems = fuse
        .search(countrySearch)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      // console.log('listItems', listItems, fuse.search(countrySearch));
      return listItems;
    } else {
      return countries || [];
    }
  }, [countrySearch, countries]);

  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  useEffect(() => {
    const addresses = form.getFieldValue('addresses');
    setSelectedCountryCode(
      addresses && addresses[field.name] && addresses[field.name].country,
    );
  }, [form, field]);

  const {
    loading: countryRegionsLoading,
    error: countryRegionsError,
    countryRegions,
  } = useCountryRegions(selectedCountryCode);
  const [countryRegionsSearch, setCountryRegionsSearch] = useState('');

  const countryRegionsOptions = useMemo(() => {
    if (countryRegionsSearch) {
      const fuse = new Fuse(countryRegions, countryRegionsSearchOptions);
      const listItems = fuse
        .search(countryRegionsSearch)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
      return listItems;
    } else {
      return countryRegions;
    }
  }, [countryRegionsSearch, countryRegions]);

  return (
    <div className="address">
      <Form.Item
        name={[field.name, '_id']}
        initialValue={generateUuid()}
        noStyle
      >
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Label"
        name={[field.name, 'label']}
        initialValue={`Address ${field.name + 1}`}
        rules={[
          { required: true, message: 'Please enter a label for this address' },
        ]}
        extra="e.g., My house, office ..."
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Address Line 1"
        name={[field.name, 'address1']}
        extra="Start typing and select an address"
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Address Line 2"
        name={[field.name, 'address2']}
        rules={[]}
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Form.Item
          label="Country"
          name={[field.name, 'country']}
          rules={[{ required: true, message: 'Please select a country' }]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <Select
            showSearch
            onSearch={(value) => {
              setCountrySearch(value);
            }}
            onSelect={(value) => {
              setCountrySearch('');
            }}
            optionFilterProp="children"
            autoComplete="chrome-off"
            disabled={loading}
            loading={countriesLoading}
            onChange={(value) => {
              setSelectedCountryCode(value);
              form.validateFields();
            }}
          >
            {countryOptions.map((country) => (
              <Select.Option key={country.shortCode} value={country.shortCode}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="State / Region"
          name={[field.name, 'state']}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            marginLeft: 16,
          }}
        >
          <Select
            showSearch
            onSearch={(value) => {
              setCountryRegionsSearch(value);
            }}
            onSelect={(value) => {
              setCountryRegionsSearch('');
            }}
            optionFilterProp="children"
            autoComplete="chrome-off"
            disabled={loading}
            loading={countryRegionsLoading}
          >
            {countryRegionsOptions.map((state) => (
              <Select.Option key={state.shortCode} value={state.shortCode}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item label="City" name={[field.name, 'city']}>
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        label="Zip Code / Postal Code"
        name={[field.name, 'zipCode']}
        rules={[
          {
            validator: (_, value) => {
              if (
                value &&
                postcodeValidatorExistsForCountry(selectedCountryCode) &&
                !postcodeValidator(value, selectedCountryCode)
              ) {
                return Promise.reject('Please enter a valid zip code.');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
      >
        <Input disabled={loading} />
      </Form.Item>
      <div>
        <Radio.Group value={primary} onChange={(e) => setPrimary(field.key)}>
          <Radio
            disabled={loading}
            value={field.key}
            style={{ marginBottom: 16 }}
          >
            Primary Address
          </Radio>
        </Radio.Group>
      </div>
      <style jsx>{`
        .address {
          border: 1px solid ${sharedSettings.colors.borderGray};
          border-radius: 4px;
          padding 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default AddressFormItem;
