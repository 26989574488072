import { RedoOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { buildSinglePayoutV2RowsMutation } from '../../constants';

function RefreshRowColumn({ record }) {
  const [buildSinglePayoutV2Rows] = useMutation(
    buildSinglePayoutV2RowsMutation,
  );

  const [saving, setSaving] = useState(false);
  const handleFinish = useCallback(
    async (value) => {
      setSaving(true);
      try {
        if (value) {
          await buildSinglePayoutV2Rows({
            variables: {
              rowIds: [record._id],
            },
          });
        }
      } catch (err) {
        console.error(err);
        message.error(err.message);
      }
      setSaving(false);
    },
    [record, buildSinglePayoutV2Rows],
  );

  return (
    <Button
      type="default"
      icon={<RedoOutlined />}
      onClick={handleFinish}
      loading={saving}
    />
  );
}

export default RefreshRowColumn;
