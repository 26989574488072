import DateFormItem from '@aims/shared/components/DateFormItem';
import { Checkbox, Divider, Form, Input } from 'antd';
import React from 'react';
import AddressesFormItem from '../../../components/AddressesFormItem';
import ContentEditableInput, {
  serializeText,
} from '../../../components/ContentEditableInput';
import EmailsFormItem from '../../../components/EmailsFormItem';
import PhoneNumbersFormItem from '../../../components/PhoneNumbersFormItem';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import TagsFormItem, { tagsBeforeProcessing } from '../../tags/TagsFormItem';
import AddToHousehold from '../AddToHousehold';
import AddToOrganization from '../AddToOrganization';
import SelectContact from '../SelectContact';
import { ContactTypes } from '../constants';

const ContactFormItems = {
  firstName: {
    FormItem: function FormItem({ contactType, loading, ref }) {
      const label = {
        [ContactTypes.CONTACT.key]: 'First Name',
        [ContactTypes.HOUSEHOLD.key]: 'First Names',
        [ContactTypes.ORG.key]: 'Organization Name',
      }[contactType];
      const extra = {
        [ContactTypes.CONTACT.key]: undefined,
        [ContactTypes.HOUSEHOLD.key]: 'ex.: John & Jane, Özcan & Meral ...',
        [ContactTypes.ORG.key]: undefined,
      }[contactType];
      return (
        <Form.Item
          label={label}
          name="firstName"
          rules={[{ required: true, message: 'This field is required' }]}
          extra={extra}
        >
          <Input ref={ref} disabled={loading} />
        </Form.Item>
      );
    },
  },
  middleNames: {
    FormItem: function FormItem({ contactType, loading, ref }) {
      const label = {
        [ContactTypes.CONTACT.key]: 'Middle Name(s)',
        [ContactTypes.HOUSEHOLD.key]: undefined,
        [ContactTypes.ORG.key]: undefined,
      }[contactType];
      if (contactType == ContactTypes.CONTACT.key) {
        return (
          <Form.Item label={label} name="middleNames">
            <Input ref={ref} disabled={loading} />
          </Form.Item>
        );
      }
      return null;
    },
  },
  lastName: {
    FormItem: function FormItem({ contactType, loading, ref }) {
      const label = {
        [ContactTypes.CONTACT.key]: 'Last Name',
        [ContactTypes.HOUSEHOLD.key]: 'Last Name',
        [ContactTypes.ORG.key]: undefined,
      }[contactType];
      if (
        contactType == ContactTypes.CONTACT.key ||
        contactType == ContactTypes.HOUSEHOLD.key
      ) {
        return (
          <Form.Item label={label} name="lastName">
            <Input ref={ref} disabled={loading} />
          </Form.Item>
        );
      }
      return null;
    },
  },
  birthday: {
    FormItem: function FormItem({ contactType, loading }) {
      if (contactType == ContactTypes.CONTACT.key) {
        return (
          <DateFormItem label="Birthday" name="birthday" disabled={loading} />
        );
      }
      return null;
    },
    before: (contact) => {
      if (contact && contact.birthday) {
        return {
          birthday: contact.birthday,
        };
      }
      return undefined;
    },
  },
  tags: {
    before: (contact) => {
      if (contact) {
        return {
          tags: tagsBeforeProcessing(contact.tags),
        };
      }
    },
    FormItem: function FormItem({ loading }) {
      return <TagsFormItem name="tags" label="Tags" saving={loading} />;
    },
  },
  emails: {
    FormItem: function FormItem({ loading, contact, finish, form }) {
      return (
        <>
          <Divider>Emails</Divider>
          <EmailsFormItem
            loading={loading}
            form={form}
            contact={contact}
            onSubmit={finish}
          />
        </>
      );
    },
    finish: (values, finish) => {
      const { primaryEmailId, emailsToSave } = finish.current(values.emails);
      return {
        primaryEmailId,
        emails: emailsToSave,
      };
    },
  },
  phoneNumbers: {
    FormItem: function FormItem({ loading, contact, finish, form }) {
      return (
        <>
          <Divider>Phone Numbers</Divider>
          <PhoneNumbersFormItem
            loading={loading}
            form={form}
            contact={contact}
            onSubmit={finish}
          />
        </>
      );
    },
    finish: (values, finish) => {
      const { primaryPhoneNumberId, phoneNumbersToSave } = finish.current(
        values.phoneNumbers,
      );
      return {
        primaryPhoneNumberId,
        phoneNumbers: phoneNumbersToSave,
      };
    },
  },
  addresses: {
    FormItem: function FormItem({ loading, contact, finish, form }) {
      return (
        <>
          <Divider>Addresses</Divider>
          <AddressesFormItem
            loading={loading}
            form={form}
            contact={contact}
            onSubmit={finish}
          />
        </>
      );
    },
    finish: (values, finish) => {
      const { primaryAddressId, addressesToSave } = finish.current(
        values.addresses,
      );
      return {
        primaryAddressId,
        addresses: addressesToSave,
      };
    },
  },
  households: {
    before: (contact) => {
      if (contact && contact.household) {
        return {
          households: [contact.household],
        };
      }
      return undefined;
    },
    FormItem: function FormItem({ loading, contact, finish, contactType }) {
      if (contactType === ContactTypes.CONTACT.key) {
        return (
          <AddToHousehold loading={loading} contact={contact} finish={finish} />
        );
      }
      return null;
    },
    extraFinish: async (contactId, queryId, contactType, values, finish) => {
      if (contactType === ContactTypes.CONTACT.key) {
        await finish.current(contactId, queryId, values.households || []);
      }
    },
  },
  organizations: {
    FormItem: function FormItem({ loading, contact, finish, contactType }) {
      if (contactType === ContactTypes.CONTACT.key) {
        return (
          <AddToOrganization
            loading={loading}
            contact={contact}
            finish={finish}
          />
        );
      }
      return null;
    },
    extraFinish: async (contactId, queryId, contactType, values, finish) => {
      if (contactType === ContactTypes.CONTACT.key) {
        await finish.current(contactId, queryId, values.organizations || []);
      }
    },
  },
  otherDetails: {
    finish: (values) => {
      const { otherDetails } = values;
      return {
        otherDetails,
        otherDetailsText:
          otherDetails && serializeText(JSON.parse(otherDetails)),
      };
    },
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item name="otherDetails" label="Other Details">
          <ContentEditableInput disabled={loading} />
        </Form.Item>
      );
    },
  },
};

export default ContactFormItems;
