import sharedSettings from '@aims/shared/sharedSettings';
import {
  EyeOutlined,
  GiftOutlined,
  GlobalOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  SelectOutlined,
  TagsOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import { Button, Card, List, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import settings from '../../../settings';
import { ContactTypes } from '../constants';
import { getContactRoleLabels } from '../contact-roles';

const { Text, Title } = Typography;

const ContactCard = ({ contact, selected, toggleSelected, hideSelect }) => {
  const history = useHistory();
  const primaryPhoneNumber = useMemo(() => {
    if (contact && contact.primaryPhoneNumberId) {
      const phone = contact.phoneNumbers.find(
        (p) => p._id === contact.primaryPhoneNumberId,
      );
      return phone && phone.phone;
    }
    return null;
  }, [contact]);

  const primaryEmailAddress = useMemo(() => {
    if (contact && contact.primaryEmailId) {
      const email = contact.emails.find(
        (p) => p._id === contact.primaryEmailId,
      );
      return email && email.email;
    }
    return null;
  }, [contact]);

  return (
    <List.Item style={{ width: '100%' }}>
      <Card
        bordered={false}
        style={{
          border: selected ? `4px solid #c6cfd3` : undefined,
        }}
      >
        <div className="body">
          <div className="container">
            <div className="left">
              <div>
                <PhotoCircle
                  photoUrl={contact.primaryThumbnailUrl}
                  size="mid"
                  name={contact.lastName || contact.firstName || contact.name}
                  style={{
                    padding: 0,
                    height: 80,
                    width: 80,
                  }}
                />
              </div>
              <div className="info">
                {contact.contactType && ContactTypes[contact.contactType] && (
                  <div>
                    <Text style={{ color: sharedSettings.colors.textGray }}>
                      {ContactTypes[contact.contactType].label}
                    </Text>
                  </div>
                )}
                <Title
                  level={4}
                  style={{
                    whiteSpace: 'break-spaces',
                    marginBottom: contact.relationship ? 0 : 4,
                    marginTop: 0,
                  }}
                >
                  {contact.fullName}
                </Title>

                <div>
                  <Text>
                    <TagsOutlined style={{ marginRight: 4 }} />
                    {getContactRoleLabels(contact).join(', ')}
                  </Text>
                </div>

                {contact.peopleGroupIds && contact.peopleGroupIds.length > 0 && (
                  <div className="contact">
                    <Text>
                      <TranslationOutlined style={{ marginRight: 8 }} />
                      {contact.peopleGroupIds.join(', ')}
                    </Text>
                  </div>
                )}
                {contact.isRegionalCoord && contact.regionName && (
                  <div className="contact">
                    <Text>
                      <GlobalOutlined style={{ marginRight: 8 }} />
                      {contact.regionName}
                    </Text>
                  </div>
                )}
                {(primaryPhoneNumber || primaryEmailAddress) && (
                  <div className="contact">
                    <Space
                      size="middle"
                      align="start"
                      style={{
                        flexWrap: 'wrap',
                        maxWidth: 'calc(100 % -100px)',
                      }}
                    >
                      {primaryPhoneNumber && (
                        <Text>
                          <PhoneOutlined style={{ marginRight: 8 }} />
                          {primaryPhoneNumber}
                        </Text>
                      )}
                      {primaryEmailAddress && (
                        <Text>
                          <MailOutlined
                            style={{ marginRight: 8, whiteSpace: 'no-wrap' }}
                          />
                          {primaryEmailAddress}
                        </Text>
                      )}
                    </Space>
                  </div>
                )}
                {contact.tags && (
                  <div className="tags">
                    {contact.tags.map((tag) => (
                      <Tag
                        key={tag.key}
                        color={sharedSettings.colors.primary}
                        style={{ marginTop: 10 }}
                      >
                        {tag.key}
                      </Tag>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="right">
              <Space>
                <Tooltip title="View">
                  <Link to={`/contact/view/${contact._id}/dashboard`}>
                    <Button icon={<EyeOutlined />} />
                  </Link>
                </Tooltip>
                {!hideSelect &&
                  contact.contactType === ContactTypes.CONTACT.key && (
                    <Tooltip title="Select">
                      <Button
                        icon={<SelectOutlined />}
                        onClick={() => toggleSelected(contact)}
                      />
                    </Tooltip>
                  )}
              </Space>
            </div>
          </div>
        </div>
      </Card>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: space-between;
        }
        .left {
          display: flex;
        }
        .info {
          padding-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: left;
        }
        .node {
          margin-left: 4px;
          margin-right: 4px;
          margin-bottom: 8px;
        }
        .contact {
          margin-bottom: 4px;
        }
      `}</style>
    </List.Item>
  );
};

export default ContactCard;
