import { EditOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Form, Typography, Input, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUpgQuarterlyReportForAdminMutation } from './constants';

const { Paragraph } = Typography;

function UpgQuarterlyReportAdminSection({ report, queryId }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState();
  const [updateReport] = useMutation(updateUpgQuarterlyReportForAdminMutation);
  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await updateReport({
          variables: {
            report: {
              _id: report._id,
              reportType: 'PG_QUARTERLY',
              adminNotes: values.adminNotes,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Report updated successfully',
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report',
        });
      }
      setLoading(false);
    },
    [updateReport, report],
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        adminNotes: report.adminNotes,
      });
    }
  }, [report, form]);
  return (
    <>
      <Divider>Admin Section</Divider>
      {editing ? (
        <Form onFinish={handleFinish} layout="vertical" form={form}>
          <Form.Item label="Admin Notes" name="adminNotes">
            <Input.TextArea rows={6} disabled={loading} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button
              htmlType="button"
              onClick={() => setEditing(false)}
              style={{ marginRight: 16 }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div style={{ background: 'white', padding: 24, position: 'relative' }}>
          <div>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setEditing(true)}
              style={{ position: 'absolute', top: 4, right: 16 }}
            />
          </div>
          {report.adminNotes &&
            report.adminNotes
              .split('\n')
              .map((p, index) => <Paragraph key={index}>{p}</Paragraph>)}
          {!report.adminNotes && <Paragraph>No Notes</Paragraph>}
        </div>
      )}
      <Divider />
    </>
  );
}

export default UpgQuarterlyReportAdminSection;
