import { FormOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import settings from '../../../settings';

const SignMouBtn = ({ mou, setError, error, queryId, onSuccess }) => {
  return (
    <>
      <Button
        icon={<FormOutlined />}
        href={`${settings.userAppUrl}/mou/sign/${mou._id}`}
      >
        Sign
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default SignMouBtn;
