import Modal from 'antd/lib/modal/Modal';
import React, { useState, useEffect } from 'react';
import EditEventForm from './CreateEventForm';
import { generateUuid } from '../../shared/utils';

const CreateEventModal = ({
  visible,
  onFinish,
  onCancel,
  fieldsToSet = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState(false);

  useEffect(() => {
    setEventId(generateUuid());
  }, []);

  const title = 'Create Event';

  return (
    <Modal
      title={title}
      open={visible}
      closable={!loading}
      destroyOnClose={true}
      maskClosable={!loading}
      onCancel={onCancel}
      footer={null}
    >
      <div style={{ textAlign: 'left' }}>
        {visible && (
          <EditEventForm
            _id={eventId}
            handleCancel={onCancel}
            handleSuccess={() => {
              onFinish();
              setEventId(generateUuid());
            }}
            loading={loading}
            setLoading={setLoading}
            fieldsToSet={fieldsToSet}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreateEventModal;
