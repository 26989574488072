import React from 'react';
import DisplayAttachmentCard from './DisplayAttachmentCard';

function DisplayAttachments({ attachments }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {attachments?.map((attachment) => (
        <DisplayAttachmentCard
          key={attachment._id}
          fileName={attachment.filename}
          fileSize={attachment.size}
          thumbnailUrl={
            (attachment.thumbnail && attachment.thumbnail.url) ||
            attachment.base64Url
          }
          url={attachment.url}
        />
      ))}
    </div>
  );
}

export default DisplayAttachments;
