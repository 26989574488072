import { gql } from '@apollo/client';

export const PeopleGroupRoles = {
  ADOPTER_PRAY: 'Prayer adopter',
  ADOPTER_LV1: 'Level 1 sponsor',
  ADOPTER_LV2: 'Level 2 sponsor',
  ADOPTER_LV3: 'Level 3 sponsor',
  FIELD_WORKER: 'Field worker',
  PROJECT_SPONSOR: 'Project Sponsor',
  REGIONAL_COORDINATOR: 'Regional coordinator',
};

export const UpgXContactRoles = {
  ADOPTER_PRAY: {
    key: 'ADOPTER_PRAY',
    label: 'Prayer adopter',
  },
  ADOPTER_LV1: {
    key: 'ADOPTER_LV1',
    label: 'Level 1 sponsor',
  },
  ADOPTER_LV2: {
    key: 'ADOPTER_LV2',
    label: 'Level 2 sponsor',
  },
  ADOPTER_LV3: {
    key: 'ADOPTER_LV3',
    label: 'Level 3 sponsor',
  },
  FIELD_WORKER: {
    key: 'FIELD_WORKER',
    label: 'Field worker',
  },
  PROJECT_SPONSOR: {
    key: 'PROJECT_SPONSOR',
    label: 'Project Sponsor',
  },
  REGIONAL_COORDINATOR: {
    key: 'REGIONAL_COORDINATOR',
    label: 'Regional coordinator',
  },
};

export const peopleGroupShortAttributes = gql`
  {
    _id
    country
    regionCode
    nameAcrossCountries
    primaryThumbnailUrl
  }
`;

export const peopleGroupListAttributes = gql`
  {
    _id
    population
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    affinityBloc
    affinityBlocId
    peopleCluster
    peopleClusterId
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    primaryLanguageId
    officialLanguage
    officialLanguageId
    bibleStatus
    primaryReligion
    primaryReligionId
    religionSubdivision
    religionSubdivisionId
    jpScaleId
    jpScale
    jpUrl
    latlon
    summaryText
    focusLevelNo
    aimsStatusAdopted
    aimsStatusCoordinator
    aimsStatusFieldWorker
    aimsStatusFunding
    aimsStatusHolisticProject
    aimsStatusSalvations
    aimsStatusChurches
    aimsStatusMovements
    aimsStatusPercent
    numAdopters
    numAdoptionsAvailable
    numRegionalCoords
    numFieldWorkers
    numHolisticProjects
    suggestedAdoptionLevel
    primaryPhotoId
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const peopleGroupFullAttributes = gql`
  {
    _id
    population
    continentCode
    continent
    region
    regionCode
    countryCode
    country
    affinityBloc
    affinityBlocId
    peopleCluster
    peopleClusterId
    peopleGroupId
    nameInCountry
    nameAcrossCountries
    primaryLanguage
    primaryLanguageId
    officialLanguage
    officialLanguageId
    bibleStatus
    primaryReligion
    primaryReligionId
    religionSubdivision
    religionSubdivisionId
    jpScaleId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    latlon
    summary
    summaryText
    primaryThumbnailUrl
    primaryPhotoId
    photoIds
    photos {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    focusLevelNo
    aimsStatusAdopted
    aimsStatusCoordinator
    aimsStatusFieldWorker
    aimsStatusFunding
    aimsStatusHolisticProject
    aimsStatusSalvations
    aimsStatusChurches
    aimsStatusMovements
    aimsStatusPercent
    cumulativeStats {
      receivedForHimself
      receivedForHolistic
      receivedTotal
      numChurchesPlanted
      numHeardForFirstTime
      numSalvations
      numWaterBaptisms
      numMiraclesReported
    }
    numAdopters
    numAdoptionsAvailable
    numFieldWorkers
    numHolisticProjects
    numRegionalCoords
    suggestedAdoptionLevel
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allPeopleGroupsQuery = gql`
  query AllPeopleGroups(
    $first: Int
    $after: String
    $filters: PeopleGroupFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allPeopleGroups(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${peopleGroupListAttributes}
      }
    }
  }
`;

export const publicPeopleGroupAttributes = gql`
  {
    _id
    nameAcrossCountries
    country
    population
    region
    peopleGroupId
    primaryReligion
    primaryReligionId
    jpScale
    jpUrl
    jpPhotoUrl
    jpSummary
    primaryPhotoId
    primaryThumbnailUrl
    numAdoptionsAvailable
    suggestedAdoptionLevel
    createdAt
    updatedAt
    deletedAt
  }
`;

export const allPublicPeopleGroupsQuery = gql`
  query AllPublicPeopleGroups(
    $first: Int
    $after: String
    $filters: PeopleGroupFiltersForPublic
    $sortBy: [SortBy]
  ) {
    allPeopleGroupsForPublic(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${publicPeopleGroupAttributes}
      }
    }
  }
`;

export const peopleGroupQuery = gql`
  query PeopleGroup($_id: ID!) {
    peopleGroup(_id: $_id) ${peopleGroupFullAttributes}
  }
`;

export const extractPublicPeopleGroups = (data) =>
  data && data.allPeopleGroupsForPublic;

export const BibleStatusLabels = {
  0: {
    label: 'Questionable',
  },
  1: {
    label: 'No Tranlations',
  },
  2: {
    label: 'Portions of the Bible Translated',
  },
  3: {
    label: 'New Testament Translated',
  },
  4: {
    label: 'Whole Bible Translated',
  },
  5: {
    label: 'Whole Bible Translated',
  },
};

export const AimsStatusColors = [
  '#dcc9a7',
  '#eaca91',
  '#ebbe6f',
  '#d59c49',
  '#e2a232',
  '#e29919',
  '#e1a131',
  '#cf8c2a',
];

export const AimsStatuses = {
  aimsStatusAdopted: {
    sort: 1,
    pieOrder: 7,
    label: 'Adopted',
    color: '#dcc9a7',
    desc: 'Has this people group been adopted?',
    textColor: 'white',
  },
  aimsStatusCoordinator: {
    sort: 2,
    pieOrder: 8,
    label: 'Has Coordinator',
    color: '#eaca91',
    desc: 'Is there a coordinator?',
    textColor: 'white',
  },
  aimsStatusFieldWorker: {
    sort: 3,
    pieOrder: 1,
    label: 'Has Field Worker',
    color: '#ebbe6f',
    desc: 'Is there a field worker?',
    textColor: 'black',
  },
  aimsStatusFunding: {
    sort: 4,
    pieOrder: 2,
    label: 'Has Adoption Funding',
    color: '#d59c49',
    desc: 'Has AIMS received funding for this people group adoption?',
    textColor: 'white',
  },
  aimsStatusHolisticProject: {
    sort: 5,
    pieOrder: 3,
    label: 'Has Holistic Project',
    color: '#e1a131',
    desc: 'Has a holistic project been approved for this people group?',
    textColor: 'white',
  },
  aimsStatusSalvations: {
    sort: 6,
    pieOrder: 4,
    label: 'Salvations & Baptisms',
    color: '#e2a232',
    desc: 'Have there been salvations and baptisms among this people group?',
    textColor: 'black',
  },
  aimsStatusChurches: {
    sort: 7,
    pieOrder: 5,
    label: 'Churches Planted',
    color: '#e29919',
    desc: 'Have there been churches planted among this people group?',
    textColor: 'black',
  },
  aimsStatusMovements: {
    sort: 8,
    pieOrder: 6,
    label: 'Movements',
    color: '#cf8c2a',
    desc: "Have greater than 2% of this people group's population been born again?",
    textColor: 'black',
  },
};
