import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import {
  createAccountAction,
  deleteAccountAction,
  updateAccountAction,
} from '../../../redux-store/accounts-store';
import useQueryParams from '../../../shared/use-query-params';
import AccountTitle from './AccountTitle';
import BalanceDisplay from './BalanceDisplay';
import TransfersTabLocal from './TransfersTabLocal';
import {
  accountQuery,
  accountUpdatesSubscription,
  extractAccount,
} from './constants';

const { Title } = Typography;

const queryId = 'viewAccountPage';

function ViewAccountPage() {
  const history = useHistory();
  const { _id } = useParams();

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: accountUpdatesSubscription,
    createAction: createAccountAction,
    updateAction: updateAccountAction,
    deleteAction: deleteAccountAction,
    extractSomething: (resp) => resp?.data?.accountUpdates,
    queryId,
    skip: !_id,
  });
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: accountQuery,
    extract: extractAccount,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateAccountAction,
    skip: !_id,
    showError: true,
  });
  const account = useSingleSomethingA10('accounts', _id, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/accounting/accounts`;
    }
  }, [query]);

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }} title="View Account">
      <CSPageHeader
        titleComponent={<AccountTitle account={account} />}
        backActions={[
          <Link to={backLink} key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Go Back
            </Button>
          </Link>,
        ]}
      />
      <BalanceDisplay account={account} />
      <TransfersTabLocal filters={{ accountId: _id }} />
    </CSPage>
  );
}

export default ViewAccountPage;
