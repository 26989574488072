import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../reports/constants';

export const extractAllHolisticQuarterlyReportsForAdmin = (data) =>
  data.allHolisticQuarterlyReportsForAdmin;

const HolisticQuarterlyReportFullAttributes = gql`
  {
    _id
    quarter
    regionCode
    regionName
    contactId
    contact {
      _id
      fullName
    }
    reportType
    status
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    isSubmitted
    isApproved
    submittedAt
    submittedBy
    submittedByUser ${ReportUserShortAttributes}
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    notes
    createdBy
    createdByUser ${ReportUserShortAttributes}
    updatedBy
    updatedByUser ${ReportUserShortAttributes}
    createdAt
    updatedAt
    deletedBy
    deletedAt
    adminNotes
    holisticProjectId
    holisticProject {
      _id
      projectName
      typeOfProject
    }
    projectCoordId
    projectCoord {
      _id
      fullName
    }
    currency
    exchangeRate
    projectState
    hasReceivedFunds
    loadPaymentsThisQuarterUsd
    loanPaymentsTotalUsd
    loanTotal
    loanTerm
    expensesThisQuarterForeign
    expensesThisQuarterDesc
    revenueThisQuarterForeign
    revenueThisQuarterDesc
    supportedFieldWorkers {
      amountForeign
      contactId
      contact {
        _id
        fullName
      }
      name
    }
    progressDesc
    problemsDesc
    miracles {
      _id
      desc
    }
    prayerRequests {
      _id
      desc
    }
  }
`;

export const holisticQuarterlyReportForAdminQuery = gql`
  query HolisticQuarterlyReportForAdmin($_id: ID!) {
    holisticQuarterlyReportForAdmin(_id: $_id) ${HolisticQuarterlyReportFullAttributes}
  }
`;

export const extractHolisticQuarterlyReportForAdmin = (data) =>
  data && data.holisticQuarterlyReportForAdmin;

const HolisticQuarterlyReportUpdateAttributes = gql`
{
  crud
  id
  new ${HolisticQuarterlyReportFullAttributes}
}
`;

export const holisticQuarterlyReportUpdatesSubscription = gql`
  subscription HolisticQuarterlyReportUpdatesForAdmin($_id: ID!) {
    holisticQuarterlyReportUpdatesForAdmin(_id: $_id) ${HolisticQuarterlyReportUpdateAttributes}
  }
`;

export const createHolisticQuarterlyReportForAdminMutation = gql`
  mutation CreateHolisticQuarterlyReportForAdmin(
    $report: HolisticQuarterlyReportCreateInputForAdmin!
  ) {
    createHolisticQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateHolisticQuarterlyReportForAdminMutation = gql`
  mutation UpdateHolisticQuarterlyReportForAdmin(
    $report: HolisticQuarterlyReportUpdateInputForAdmin!
  ) {
    updateHolisticQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitHolisticQuarterlyReportForAdminMutation = gql`
  mutation SubmitHolisticQuarterlyReportForAdmin($reportId: ID!) {
    submitHolisticQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const unsubmitHolisticQuarterlyReportForAdminMutation = gql`
  mutation UnsubmitHolisticQuarterlyReportForAdmin($reportId: ID!) {
    unsubmitHolisticQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const reviewHolisticQuarterlyReportMutation = gql`
  mutation ReviewHolisticQuarterlyReport(
    $reportId: ID!
    $status: ReportApprovalStatus!
  ) {
    reviewHolisticQuarterlyReport(reportId: $reportId, status: $status)
  }
`;

export const sendHolisticQuarterlyReportFeedbackMutation = gql`
  mutation SendHolisticQuarterlyReportFeedback(
    $reportId: ID!
    $feedback: String!
  ) {
    sendHolisticQuarterlyReportFeedback(
      reportId: $reportId
      feedback: $feedback
    )
  }
`;
