import { useApolloClient } from '@apollo/client';
import { notification } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  incrementQueryAction,
  nextQueryAction,
  updateQueryAction,
} from '../redux-store/queries-store';
import { hashCode } from './utils';

function useManyLocalM20({
  query,
  extract,
  readAction,
  first,
  filters,
  sortBy,
  fetchPolicy = 'no-cache',
  queryId = 'default',
  skip,
}) {
  const [loading, setLoading] = useState(false);
  const nextAfter = useRef();
  const hasNextPage = useRef();

  const queryCode = useMemo(() => {
    const inputs = JSON.stringify({
      query,
      first,
      filters,
      sortBy,
    });
    const code = hashCode(inputs);
    return code;
  }, [query, first, filters, sortBy]);

  const { refetches, after } = useSelector((store) => {
    const update = store.queries[queryId]?.[queryCode] || {
      _id: queryCode,
      refetches: 0,
      after: undefined,
    };
    return update;
  }, shallowEqual);

  const search = useRef();
  const apolloClient = useApolloClient();
  const abort = useRef({});
  const last = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateQueryAction(
        {
          _id: queryCode,
          refetches,
          after,
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      if (abort.current[query]) {
        abort.current[query].abort();
      }
      abort.current[query] = new AbortController();
      try {
        let _sortBy;
        if (search.current) {
          _sortBy = [{ key: '_score', order: 'DESC' }];
        } else {
          _sortBy = sortBy;
        }
        const result = await apolloClient.query({
          query,
          variables: {
            first,
            after,
            filters: {
              ...filters,
              search: search.current,
            },
            sortBy: _sortBy,
          },
          fetchPolicy,
          context: {
            fetchOptions: {
              signal: abort.current.signal,
            },
          },
        });
        const results = result && extract(result.data);
        if (results) {
          hasNextPage.current = results.pageInfo.hasNextPage;
          nextAfter.current = results.pageInfo.endCursor;
          dispatch(
            readAction(
              results.edges.map((edge) => edge.node),
              queryId,
            ),
          );
        }
      } catch (err) {
        if (err.message.includes('abort')) {
          console.log('Fetch aborted.');
        } else {
          console.error(err);
          notification.error({
            message: 'Error',
            description: err.message,
          });
        }
      } finally {
        abort.current[query] = undefined;
        setLoading(false);
      }
    }

    const inputs = JSON.stringify({
      refetches,
      after,
      queryCode,
    });
    if (inputs !== last.current && !skip) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    filters,
    first,
    refetches,
    apolloClient,
    query,
    sortBy,
    extract,
    fetchPolicy,
    queryCode,
    after,
    dispatch,
    queryId,
    readAction,
    skip,
  ]);

  const refetch = useCallback(() => {
    dispatch(incrementQueryAction(queryCode, queryId));
  }, [dispatch, queryCode, queryId]);

  return {
    hasNextPage: hasNextPage.current,
    loading,
    next: () => {
      if (hasNextPage.current && nextAfter.current) {
        dispatch(nextQueryAction(queryCode, nextAfter.current, queryId));
      }
    },
    refetch,
    reset: async () => {
      await apolloClient.resetStore();
      dispatch(incrementQueryAction(queryCode, queryId));
    },
  };
}

export default useManyLocalM20;
