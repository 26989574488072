import {
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Table,
  Typography,
} from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  HolisticProjectStates,
  HolisticProjectTypes,
} from '../../holistic-projects/constants';
import { getLoanSummary } from '../../holistic-projects/utils';
import { displayMoney, getNumberValue } from '../../shared/utils';
import sharedSettings from '../../sharedSettings';
import {
  ExchangeRateInput,
  ReportDescriptionAnswer,
  ReportLineItem,
  reportApprovedBy,
  reportMiracles,
  reportNotes,
  reportPrayerRequests,
  reportPrivateFiles,
  reportPublicFiles,
  reportQuarter,
  reportSubmittedBy,
} from '../common/ReportItems';
import { ReportApprovalStatuses } from '../constants';
import { getQuarterLabel } from '../quarters';
import LoanStatusSummary from './LoanStatusSummary';
import ProjectAnalysisSummary from './ProjectAnalysisSummary';
import SelectHolisticProject from '../../holistic-projects/SelectHolisticProject';

const { Title, Text, Paragraph } = Typography;

export const HolisticQuarterlyReportQuestions = {
  title: {
    Display: function Display({ report, holisticProject }) {
      const status = report && ReportApprovalStatuses[report.status];
      const title = useMemo(() => {
        let _title = (
          <div style={{ textAlign: 'center', marginBottom: 32 }}>
            <Title level={2} style={{ marginBottom: 0 }}>
              Holistic Project Quarterly Report
            </Title>
          </div>
        );
        const quarterLabel = getQuarterLabel(report && report.quarter);
        if (report && report.holisticProject) {
          const projectType =
            HolisticProjectTypes[report.holisticProject.typeOfProject]?.label;
          _title = (
            <div style={{ textAlign: 'center', marginBottom: 32 }}>
              <Title level={2} style={{ marginBottom: 0 }}>
                Holistic Project Progress Report
              </Title>
              <Title level={1} style={{ margin: 0 }}>
                {report.holisticProject.projectName}
              </Title>
              <div>
                <Text
                  style={{
                    color: sharedSettings.colors.textGray,
                    fontSize: 16,
                  }}
                >
                  {projectType}
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 24 }}>{quarterLabel}</Text>
              </div>
              {status && (
                <div
                  style={{
                    color: status.color,
                    fontWeight: 600,
                    fontSize: 18,
                  }}
                >
                  {status.label}
                </div>
              )}
            </div>
          );
        } else if (holisticProject) {
          const projectType =
            HolisticProjectTypes[holisticProject.typeOfProject]?.label;
          _title = (
            <div style={{ textAlign: 'center', marginBottom: 32 }}>
              <Title level={2} style={{ marginBottom: 0 }}>
                Holistic Project Progress Report
              </Title>
              <Title level={1} style={{ margin: 0 }}>
                {holisticProject.projectName}
              </Title>
              <div>
                <Text
                  style={{
                    color: sharedSettings.colors.textGray,
                    fontSize: 16,
                  }}
                >
                  {projectType}
                </Text>
              </div>
            </div>
          );
        }
        return _title;
      }, [report, holisticProject, status]);
      return title;
    },
  },
  submittedBy: reportSubmittedBy,
  approvedBy: reportApprovedBy,
  quarter: reportQuarter,
  holisticProject: {
    FormItem: function FormItem({ loading, fieldsToSet }) {
      return (
        <>
          {!fieldsToSet?.holisticProjectId && (
            <Form.Item
              label="Holistic Project"
              name="holisticProjectId"
              extra="Temporary workaround: You must save and reload this page after choosing a holistic project in order to complete the supported field workers section."
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <SelectHolisticProject disabled={loading} />
            </Form.Item>
          )}
        </>
      );
    },
    Display: function Display({ report, holisticProject }) {
      const typeLabel =
        HolisticProjectTypes[holisticProject.typeOfProject] &&
        HolisticProjectTypes[holisticProject.typeOfProject].label;
      let nameLabel;
      if (holisticProject.projectNo) {
        nameLabel = `${holisticProject.projectName} (#${holisticProject.projectNo})`;
      } else {
        nameLabel = holisticProject.projectName;
      }
      const upgs = holisticProject.peopleGroupIds.join(', ');
      return (
        <div>
          <div
            style={{ color: sharedSettings.colors.primary, fontWeight: 600 }}
          >
            {nameLabel}
          </div>
          {typeLabel && (
            <div style={{ color: sharedSettings.colors.textGray }}>
              {typeLabel}
            </div>
          )}
          <div>{upgs}</div>
        </div>
      );
    },
  },
  projectState: {
    FormItem: function FormItem({ loading, fieldsToSet }) {
      return (
        <Form.Item
          label="What is the current status of this project?"
          name="projectState"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select style={{ width: 200 }} disabled={loading}>
            {Object.entries(HolisticProjectStates).map(([key, obj]) => (
              <Select.Option key={obj.key} value={obj.key}>
                {obj.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      const answer =
        report.projectState &&
        HolisticProjectStates[report.projectState] &&
        HolisticProjectStates[report.projectState].label;
      return (
        <ReportLineItem
          question="Please select the project's current state."
          answer={answer}
        />
      );
    },
  },
  progressDesc: {
    FormItem: function FormItem({ loading }) {
      return (
        <>
          <Form.Item
            label="How is the project going?  Please describe the current situation and progress this quarter."
            name="progressDesc"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea disabled={loading} rows={4} />
          </Form.Item>

          <Form.Item
            label="Is the project facing any problems?  What are they and how can they be overcome?"
            name="problemsDesc"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea disabled={loading} rows={4} />
          </Form.Item>
        </>
      );
    },
    Display: function Display({ report }) {
      return (
        <>
          <ReportLineItem
            question="How is the project going?  Please describe the current situation and progress this quarter."
            answer={report.progressDesc}
          />

          <ReportLineItem
            question="Is the project facing any problems?  What are they and how can they be overcome?"
            answer={report.problemsDesc}
          />
        </>
      );
    },
  },
  projectAnalysis: {
    before: (report, holisticProject) => {
      return {
        supportedFieldWorkers: holisticProject?.supportedFieldWorkers?.map(
          (fw) => {
            const existing = report.supportedFieldWorkers.find(
              (c) => c.contactId === fw.contactId,
            );
            const amountForeign = existing?.amountForeign;
            return {
              name: fw.contact?.fullName,
              contactId: fw.contactId,
              amountForeign,
            };
          },
        ),
      };
    },
    FormItem: function FormItem({ loading, holisticProject }) {
      const locale = useSelector((store) => store.locale, shallowEqual);
      return (
        <>
          <Divider>Financial Update</Divider>

          <Form.Item
            name="holisticProjectId"
            noStyle
            initialValue={holisticProject?._id}
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="projectCoordId"
            noStyle
            initialValue={holisticProject?.regionalCoordId}
          >
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            name="loanTotal"
            noStyle
            initialValue={holisticProject?.loanTotal}
          >
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="loanTerm"
            noStyle
            initialValue={holisticProject?.loanTerm}
          >
            <Input type="hidden" />
          </Form.Item>

          {holisticProject && (
            <div style={{ textAlign: 'center', marginBottom: 16 }}>
              <div>
                <Text style={{ color: sharedSettings.colors.primary }}>
                  Loan Summary
                </Text>
              </div>
              <div>
                <Text style={{ fontSize: 15 }}>
                  {getLoanSummary(locale, holisticProject)}
                </Text>
              </div>
            </div>
          )}

          <Form.Item
            label="Have you received this funding?"
            name="hasReceivedFunds"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="How much have you received this quarter in loan repayments? (in USD)"
            name="loadPaymentsThisQuarterUsd"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <InputNumber
              addonBefore="$"
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            label="How much of this loan has been repaid in total? (in USD)"
            name="loanPaymentsTotalUsd"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <InputNumber
              addonBefore="$"
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <div style={{ height: 16 }} />
          <div
            style={{
              marginBottom: 24,
              backgroundColor: sharedSettings.colors.borderGray,
              borderRadius: 8,
              padding: 16,
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            The following questions will help us understand how the project is
            doing financially. Please fill out the following in your local
            currency.
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              name="currency"
              label="Local Currency"
              rules={[{ required: true, message: 'This field is required' }]}
              style={{ marginBottom: 16, marginRight: 16 }}
            >
              <Input style={{ width: 300 }} disabled={loading} />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) => prev.currency !== curr.currency}
              noStyle
            >
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  label="Exchange Rate from U.S. Dollars"
                  name="exchangeRate"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                  style={{ marginBottom: 16, marginRight: 32 }}
                >
                  <ExchangeRateInput
                    disabled={loading}
                    localCurrency={getFieldValue('currency')}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </div>

          <Form.Item
            label="Expenses This Quarter (In Local Currency)"
            name="expensesThisQuarterForeign"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginRight: 32, marginBottom: 16 }}
            extra="This should NOT include field worker support (Specified below)."
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            name="expensesThisQuarterDesc"
            label="Please list and describe in detail your expenses this quarter."
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>

          <div style={{ height: 16 }} />
          <Title level={3}>Supported Field Workers</Title>
          <Form.Item
            label="Which Field Worker received support from this project this quarter and how much did they receive?"
            style={{ marginBottom: 16 }}
          >
            {holisticProject?.supportedFieldWorkers?.map((fw, index) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: 16,
                }}
                key={fw.contactId}
              >
                <div>
                  <label
                    htmlFor={`supportedFieldWorkers_${index}_amountReceived`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div style={{ marginRight: 4 }}>
                      <Text type="danger">*</Text>
                    </div>
                    <div>
                      <Text style={{ color: sharedSettings.colors.primary }}>
                        Amount Received (In Local Currency)
                      </Text>
                    </div>
                  </label>
                  <div style={{ marginRight: 16, fontSize: 16, width: 300 }}>
                    {fw.contact && fw.contact.fullName}
                  </div>
                </div>
                <Form.Item
                  name={['supportedFieldWorkers', index, 'name']}
                  noStyle
                  initialValue={fw.contact && fw.contact.fullName}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  name={['supportedFieldWorkers', index, 'contactId']}
                  noStyle
                  initialValue={fw.contactId}
                >
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  name={['supportedFieldWorkers', index, 'amountForeign']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter amount received',
                    },
                  ]}
                >
                  <InputNumber
                    disabled={loading}
                    min={0}
                    step={10}
                    width={200}
                  />
                </Form.Item>
              </div>
            ))}
          </Form.Item>

          <Form.Item
            name="revenueThisQuarterForeign"
            label="Revenue This Quarter (In Local Currency)"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginRight: 32, marginBottom: 16 }}
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            name="revenueThisQuarterDesc"
            label="Please list and describe in detail your sources of revenue this quarter."
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    Display: function Display({ colors, report }) {
      const expensesThisQuarterDesc = report.expensesThisQuarterDesc || '';
      const expensesThisQuarterForeign = getNumberValue(
        report.expensesThisQuarterForeign,
      );
      const revenueThisQuarterDesc = report.revenueThisQuarterDesc || '';
      const revenueThisQuarterForeign = getNumberValue(
        report.revenueThisQuarterForeign,
      );

      let expensesThisQuarterUsd;
      let revenueThisQuarterUsd;
      const exchangeRate = getNumberValue(report.exchangeRate);
      if (exchangeRate > 0) {
        expensesThisQuarterUsd = `(${displayMoney(
          getNumberValue(expensesThisQuarterForeign / exchangeRate),
        )})`;
        revenueThisQuarterUsd = `(${displayMoney(
          getNumberValue(revenueThisQuarterForeign / exchangeRate),
        )})`;
      }
      return (
        <>
          <Divider>Financial Update</Divider>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ReportLineItem
              question="Local Currency"
              answer={report.currency}
              style={{ width: 300, marginRight: 16 }}
            />
            <ReportLineItem
              question="Exchange Rate from USD"
              answer={`${report.exchangeRate} ${report.currency} to $1 USD`}
              style={{ width: 300, marginRight: 16 }}
            />
          </div>
          <ReportDescriptionAnswer
            colors={colors}
            question="Expenses This Quarter"
            desc="Please list and describe in detail your expenses this quarter."
            answer={
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ color: colors.primary, fontWeight: 'bold' }}
                  >{`Total: `}</Text>
                  <Text>{`${expensesThisQuarterForeign} ${report.currency} ${expensesThisQuarterUsd}`}</Text>
                </div>
                {expensesThisQuarterDesc.split('\n').map((p, index) => (
                  <Paragraph key={index}>{p}</Paragraph>
                ))}
              </>
            }
          />
          <ReportDescriptionAnswer
            colors={colors}
            question="Revenue This Quarter"
            desc="Please list and describe in detail your sources of revenue this quarter."
            answer={
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ color: colors.primary, fontWeight: 'bold' }}
                  >{`Total: `}</Text>
                  <Text>{`${revenueThisQuarterForeign} ${report.currency} ${revenueThisQuarterUsd}`}</Text>
                </div>
                {revenueThisQuarterDesc.split('\n').map((p, index) => (
                  <Paragraph key={index}>{p}</Paragraph>
                ))}
              </>
            }
          />
          <div style={{ marginBottom: 16, paddingTop: 16 }}>
            <Title level={4}>Supported Field Workers</Title>
            {report && (
              <Table
                dataSource={report.supportedFieldWorkers || []}
                pagination={false}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No supported field workers"
                    />
                  ),
                }}
              >
                <Table.Column
                  title="Field Worker Name"
                  dataIndex="name"
                  key="name"
                />
                <Table.Column
                  title="Amount"
                  dataIndex="amountForeign"
                  render={(text, record) => {
                    return getNumberValue(record.amountForeign);
                  }}
                />
                <Table.Column
                  title="USD"
                  dataIndex="usd"
                  render={(text, record) => {
                    if (exchangeRate > 0) {
                      return displayMoney(
                        getNumberValue(record.amountForeign / exchangeRate),
                      );
                    }
                    return null;
                  }}
                />
              </Table>
            )}
            <div style={{ height: 16 }} />
          </div>
        </>
      );
    },
  },
  projectAnalysisSummary: {
    FormItem: function FormItem({ loading, holisticProject }) {
      return (
        <>
          <Divider>Summary</Divider>
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.expensesThisQuarterForeign !==
                curr.expensesThisQuarterForeign ||
              prev.revenueThisQuarterForeign !==
                curr.revenueThisQuarterForeign ||
              prev.exchangeRate !== curr.exchangeRate ||
              prev.supportedFieldWorkers !== curr.supportedFieldWorkers ||
              prev.loadPaymentsThisQuarterUsd !==
                curr.loadPaymentsThisQuarterUsd
            }
            noStyle
          >
            {({ getFieldValue, setFieldsValue, getFieldsValue }) => {
              const exchangeRate = getNumberValue(
                getFieldValue('exchangeRate'),
              );
              const expensesForeign = getNumberValue(
                getFieldValue('expensesThisQuarterForeign'),
              );
              const profitsForeign = getNumberValue(
                getFieldValue('revenueThisQuarterForeign'),
              );
              const loadPaymentsThisQuarterUsd = getNumberValue(
                getFieldValue('loadPaymentsThisQuarterUsd'),
              );
              const supportToFieldWorkers = (
                getFieldValue('supportedFieldWorkers') || []
              ).reduce((prev, curr) => {
                return prev + getNumberValue(curr && curr.amountForeign);
              }, 0);
              return (
                <ProjectAnalysisSummary
                  exchangeRate={exchangeRate}
                  expensesForeign={expensesForeign}
                  toSupportedFieldWorkersForeign={supportToFieldWorkers}
                  profitsForeign={profitsForeign}
                  loanPaymentsThisQuarterUsd={loadPaymentsThisQuarterUsd}
                />
              );
            }}
          </Form.Item>
          <div style={{ height: 32 }} />
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.holisticProjectId !== curr.holisticProjectId ||
              prev.loanPaymentsTotalUsd !== curr.loanPaymentsTotalUsd
            }
            noStyle
          >
            {({ getFieldValue, setFieldsValue }) => {
              const prev = getNumberValue(
                getFieldValue('loanPaymentsTotalUsd'),
              );

              const loanTotal = getNumberValue(holisticProject?.loanTotal);
              const loanTerm = holisticProject?.loanTerm;

              return (
                <LoanStatusSummary
                  loanPaymentsTotalUsd={prev}
                  loanTotal={loanTotal}
                  loanTerm={loanTerm}
                />
              );
            }}
          </Form.Item>
          <Divider />
        </>
      );
    },
    Display: function Display({ report, colors }) {
      const exchangeRate = getNumberValue(report.exchangeRate);
      const expensesThisQuarterForeign = getNumberValue(
        report.expensesThisQuarterForeign,
      );
      const revenueThisQuarterForeign = getNumberValue(
        report.revenueThisQuarterForeign,
      );
      const loadPaymentsThisQuarterUsd = getNumberValue(
        report.loadPaymentsThisQuarterUsd,
      );
      const loanPaymentsTotalUsd = getNumberValue(report.loanPaymentsTotalUsd);
      const loanTotal = getNumberValue(report.loanTotal);
      const loanTerm = getNumberValue(report.loanTerm);
      const supportToFieldWorkers = (report.supportedFieldWorkers || []).reduce(
        (prev, curr) => {
          return prev + getNumberValue(curr && curr.amountForeign);
        },
        0,
      );
      return (
        <>
          <ProjectAnalysisSummary
            exchangeRate={exchangeRate}
            expensesForeign={expensesThisQuarterForeign}
            toSupportedFieldWorkersForeign={supportToFieldWorkers}
            profitsForeign={revenueThisQuarterForeign}
            loanPaymentsThisQuarterUsd={loadPaymentsThisQuarterUsd}
          />
          <div style={{ height: 32 }} />
          <LoanStatusSummary
            loanPaymentsTotalUsd={loanPaymentsTotalUsd}
            loanTotal={loanTotal}
            loanTerm={loanTerm}
          />
          <Divider />
        </>
      );
    },
  },
  miracles: reportMiracles,
  prayerRequests: reportPrayerRequests,
  publicFiles: {
    ...reportPublicFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'HOLISTIC_PROGRESS',
      });
    },
  },
  privateFiles: {
    ...reportPrivateFiles,
    FormItem: function FormItem(props) {
      return reportPublicFiles.FormItem({
        ...props,
        reportType: 'HOLISTIC_PROGRESS',
      });
    },
  },
  notes: reportNotes,
};
