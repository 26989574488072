import { Select, Tag } from 'antd';
import React, { useMemo } from 'react';
import { TagColors } from './constants';

function SelectTagColor({ value, onChange, disabled, style }) {
  const options = useMemo(() => {
    return TagColors.map((item, index) => {
      return {
        value: item,
        label: `Color ${index + 1}`,
      };
    });
  }, []);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a color"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      disabled={disabled}
      labelRender={(option) => (
        <Tag color={option.value} style={{ width: 'calc(100% - 8px)' }}>
          {option.label}
        </Tag>
      )}
      optionRender={(option) => (
        <Tag color={option.data.value} style={{ width: '100%' }}>
          {option.data.label}
        </Tag>
      )}
    />
  );
}

export default SelectTagColor;
