import { ContactsOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectContact from '../../contacts/SelectContact';
import { assignUserToContactMutation } from '../constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

const { Title, Text } = Typography;

function AssignUserToContactModal({ user, onDone: onDone1 }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => {
    setEditing(false);
    onDone1();
  }, [onDone1]);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [assignUserToContact] = useMutation(assignUserToContactMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        const { contactId } = values;
        await assignUserToContact({
          variables: {
            userId: user._id,
            contactId,
          },
        });
        notification.success({
          message: 'Success',
          description: 'User assigned to contact successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error assigning user to contact',
        });
      }
      setSaving(false);
    },
    [user, assignUserToContact, onDone],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        contactId: user.contactId,
      });
    }
  }, [user, form]);

  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <TaskSomethingForm
          title="Assign User to a Contact"
          form={form}
          onFinish={handleFinish}
          onCancel={onCancel}
          saving={saving}
        >
          <Form.Item
            name="contactId"
            label="Select a contact"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <SelectContact
              disabled={saving}
              filters={{
                search: user?.contactId,
              }}
            />
          </Form.Item>
        </TaskSomethingForm>
      </Modal>
      <Button type="primary" icon={<ContactsOutlined />} onClick={handleEdit}>
        Assign User to a Contact
      </Button>
    </>
  );
}

export default AssignUserToContactModal;
