import { ArrowLeftOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useUser from '../use-user';
import UserTitle from './UserTitle';
import AssignUserToContactModal from './AssignUserToContactModal';
import UnassignUserFromContactModal from './UnassignUserFromContactModal';

const { Title, Paragraph } = Typography;

function ViewUser() {
  const history = useHistory();

  const { _id, tab } = useParams();

  const { user, refetch } = useUser(_id);

  const handleBack = useCallback(() => history.push(`/users`), [history]);

  useEffect(() => {
    if (!tab) {
      history.replace(`/user/view/${_id}/info`);
    }
  }, [tab, history, _id]);

  return (
    <>
      <CSPage title="View User">
        <CSPageHeader
          titleComponent={<UserTitle user={user} />}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Users
            </Button>,
          ]}
          style={{ marginBottom: 32 }}
        />
        <Space direction="vertical">
          <div>
            <Title level={4}>Contact</Title>
            <Paragraph>{user?.contact?.fullName}</Paragraph>
          </div>
          {!user?.contactId && (
            <AssignUserToContactModal user={user} onDone={() => refetch()} />
          )}
          {user?.contactId && (
            <Link to={`/contact/view/${user.contactId}/dashboard`}>
              <Button icon={<EyeOutlined />}>Go to Contact Page</Button>
            </Link>
          )}
          {user?.contactId && (
            <UnassignUserFromContactModal
              user={user}
              onDone={() => refetch()}
            />
          )}
        </Space>
        {/* <Tabs
          activeKey={tab}
          onChange={(key) => history.push(`/user/view/${_id}/${key}`)}
        >
          {user && (
            <>
              <Tabs.TabPane tab="Info" key="info"></Tabs.TabPane>
            </>
          )}
        </Tabs> */}
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default ViewUser;
