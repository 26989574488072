import { Form, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from './TaskSomethingForm';
import ContentEditableInput, {
  DefaultContentEditableValue,
  serializeText,
} from '../../../components/ContentEditableInput';

const { Text, Title, Paragraph } = Typography;

function EditTaskDescription({ onSubmit, task }) {
  const [editing, setEditing] = useState(false);
  const onEdit = () => setEditing(true);
  const onCancelEdit = () => setEditing(false);
  const onFinishEdit = () => setEditing(false);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const { ...values } = task;
    form.setFieldsValue({
      ...values,
    });
  }, [task, form]);

  const onFinish = useCallback(
    async ({ desc }) => {
      setSaving(true);
      try {
        await onSubmit({
          desc,
          descText: desc && serializeText(JSON.parse(desc)),
        });
        onFinishEdit();
      } catch (err) {
        notification.error({
          message: 'Error',
          description: 'There was an error saving your task.',
        });
      }
      setSaving(false);
    },
    [onSubmit],
  );

  return (
    <div style={{ marginBottom: 16 }}>
      {!editing ? (
        <map onClick={onEdit} style={{ width: '100%', cursor: 'pointer' }}>
          <Title level={4}>Description</Title>
          {task?.desc && task.desc !== DefaultContentEditableValue ? (
            <ContentEditableInput readOnly value={task.desc} />
          ) : (
            <Paragraph style={{ minHeight: '36px' }}>
              Click to add a description
            </Paragraph>
          )}
        </map>
      ) : (
        <TaskSomethingForm
          title="Description"
          form={form}
          onFinish={onFinish}
          onCancel={onCancelEdit}
          saving={saving}
        >
          <Form.Item name="desc">
            <ContentEditableInput disabled={saving} />
          </Form.Item>
        </TaskSomethingForm>
      )}
    </div>
  );
}

export default EditTaskDescription;
