import UserQuestions from '@aims/shared/users/questions';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Form, Space, Typography, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import SelectRolesV2 from '../../roles-v2/SelectRolesV2';
import { createUserMutation, updateUserMutation } from '../constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import ProfilePhotoFormItemJ24 from '../../profile-photo/ProfilePhotoFormItemJ24';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';

const { Text } = Typography;

function EditUserForm({
  _id,
  handleCancel,
  handleSuccess,
  user,
  saving,
  setSaving,
  style,
  fieldsToSet = {},
}) {
  const [updateUser] = useMutation(updateUserMutation);
  const [createUser] = useMutation(createUserMutation);

  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (user) {
      const { profilePhoto, ...otherFields } = user;
      form.setFieldsValue(otherFields);
    }
  }, [user, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      const { confirm, ...values } = _values;
      try {
        if (user) {
          await updateUser({
            variables: {
              user: {
                _id,
                ...values,
                ...UserQuestions.phone.finish(values),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'User updated successfully',
          });
        } else {
          await createUser({
            variables: {
              user: {
                _id,
                ...values,
                ...UserQuestions.phone.finish(values),
                ...UserQuestions.confirmPassword.finish(values),
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'User created successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [user, _id, createUser, updateUser, fieldsToSet, setSaving, handleSuccess],
  );

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      id="editUser"
      form={form}
      style={style}
    >
      <ProfilePhotoFormItemJ24
        name="profilePhotoId"
        existing={
          user && {
            _id: user.profilePhotoId,
            thumbnailUrl: user.primaryThumbnailUrl,
          }
        }
        styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
        parentType="user"
        parentId={user?._id}
        tags={['User', 'Profile Photo']}
        saving={saving}
        setSaving={setSaving}
      />
      <UserQuestions.username.FormItem
        loading={saving}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <Form.Item
        label="Role"
        name="roleIds"
        rules={[{ required: true, message: 'Please select a role' }]}
      >
        <SelectRolesV2 disabled={saving} />
      </Form.Item>
      {!user && (
        <>
          <UserQuestions.password.FormItem
            loading={saving}
            fieldsToSet={fieldsToSet}
            user={user}
            userId={_id}
            form={form}
          />
          <UserQuestions.confirmPassword.FormItem
            loading={saving}
            fieldsToSet={fieldsToSet}
            user={user}
            userId={_id}
            form={form}
          />
        </>
      )}
      <UserQuestions.name.FormItem
        loading={saving}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <UserQuestions.email.FormItem
        loading={saving}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <UserQuestions.phone.FormItem
        loading={saving}
        fieldsToSet={fieldsToSet}
        user={user}
        userId={_id}
        form={form}
      />
      <Form.Item name="emailVerified" valuePropName="checked">
        <Checkbox>Email has been verified</Checkbox>
      </Form.Item>
      <Form.Item
        label="Region"
        name="regionCode"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <SelectRegionForAdmin />
      </Form.Item>
      <ShowErrorsFormItem />
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            htmlType="button"
            type="text"
            size="small"
            disabled={saving}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={saving} htmlType="submit">
            Save User
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default EditUserForm;
