import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import YesNoModal from '../../../components/YesNoModal';
import { reviewHolisticQuarterlyReportMutation } from '../constants';

const RejectReportBtn = ({ report, holisticProject, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewReport] = useMutation(reviewHolisticQuarterlyReportMutation);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await reviewReport({
        variables: {
          reportId: report._id,
          status: 'REJECTED',
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Progress report rejected.',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error saving your report.',
      });
    }
    setLoading(false);
  }, [reviewReport, report, onSuccess]);

  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <Button
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Reject Report
      </Button>

      <YesNoModal
        title="Reject Progress Report"
        question={`Are you sure you want to reject the ${new Intl.DateTimeFormat(
          locale,
          {
            month: 'long',
            year: 'numeric',
          },
        ).format(
          new Date(report.submittedAt),
        )} progress report for the holistic project: ${
          holisticProject && holisticProject.projectName
        }.`}
        yesText="Reject"
        noText="Cancel"
        onYes={handleFinish}
        onNo={() => setShowingModal(null)}
        visible={showingModal}
        loading={loading}
        closable={!loading}
      />
    </>
  );
};

export default RejectReportBtn;
