import { gql } from '@apollo/client';
import { useCallback } from 'react';
import apolloClient from '../apollo-client';

const checkGoogleCredsQuery = gql`
  query CheckGoogleCreds($scope: GoogleScope!) {
    checkGoogleCreds(scope: $scope) {
      success
      error {
        code
        message
      }
      requiredScopes
    }
  }
`;

function useCheckGoogleCreds() {
  const checkGoogleCreds = useCallback(async (scope) => {
    const result = await apolloClient.query({
      query: checkGoogleCredsQuery,
      variables: {
        scope,
      },
      fetchPolicy: 'no-cache',
    });
    return result.data && result.data.checkGoogleCreds;
  }, []);
  return checkGoogleCreds;
}

export default useCheckGoogleCreds;
