import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { generateUuid } from '../../shared/utils';
import AllUsersTable from './AllUsersTable';

function AllUsersPage() {
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Users">
        <CSPageHeader
          title="Users"
          topActions={[
            <Link
              key="add"
              to={{
                pathname: `/user/edit/${generateUuid()}`,
                state: {
                  mode: 'create',
                },
              }}
            >
              <Button type="link" icon={<PlusCircleOutlined />} size="large">
                New User
              </Button>
            </Link>,
          ]}
        />
        <AllUsersTable />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default AllUsersPage;
