import { Tag } from 'antd';
import React from 'react';
import { AdoptionStatuses } from '../../../../adoptions/constants';

function PayoutAdoptionStatusColumn({ record }) {
  const status = record.adoptionCopy && record.adoptionCopy.status;
  const statusObj = status && AdoptionStatuses[status];
  if (statusObj) {
    return <Tag color={statusObj.color}>{statusObj.label}</Tag>;
  }
  return null;
}

export default PayoutAdoptionStatusColumn;
