import { gql, useMutation } from '@apollo/client';

export const updateGoogleTaskMutation = gql`
  mutation UpdateGoogleTask($taskId: ID!) {
    updateGoogleTask(taskId: $taskId) {
      task {
        _id
        contactId
        eventId
        status
        importance
        title
        desc
        windowStart
        windowStop
        assigneeIds
        reminderId
        tags {
          key
          color
        }
        googleId
        createdBy
        updatedBy
        createdAt
        updatedAt
        deletedBy
        deletedAt
      }
    }
  }
`;

function useUpdateGoogleTask() {
  const [updateGoogleTask] = useMutation(updateGoogleTaskMutation);
  return updateGoogleTask;
}

export default useUpdateGoogleTask;
