import { Card, Empty, List, Skeleton } from 'antd';
import React from 'react';
import settings from '../../../../settings';
import CourseCard from './CourseCard';

function TrainingCoursesList({ courses, loading }) {
  return (
    <>
      {loading && !courses.length ? (
        <List
          itemLayout="vertical"
          grid={{ column: 1, sm: 1, lg: 1, gutter: 15 }}
          dataSource={[0, 0, 0, 0]}
          renderItem={(item) => (
            <List.Item>
              <Card bordered={false}>
                <Skeleton loading="true" avatar active />
              </Card>
            </List.Item>
          )}
        />
      ) : (
        <List
          className="courseList"
          dataSource={courses}
          grid={{ column: 1, sm: 1, lg: 1, gutter: 20 }}
          rowKey="_id"
          pagination={
            courses.length > settings.pageSize
              ? {
                  defaultCurrent: 1,
                  pageSize: settings.pageSize,
                  total: courses.length,
                }
              : false
          }
          renderItem={(course) => <CourseCard course={course} />}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No courses"
              />
            ),
          }}
        />
      )}
    </>
  );
}

export default TrainingCoursesList;
