import sharedSettings from '@aims/shared/sharedSettings';
import { GlobalOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { List, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ContactTypes } from '../pages/contacts/constants';
import useShortContact from '../pages/contacts/use-short-contact';
import PhotoCircle from './PhotoCircle';

const { Text, Title } = Typography;

const AssociatedPeopleInfo = ({ item }) => {
  const { id } = item;

  const { contact, loading } = useShortContact(id);

  const primaryPhoneNumber = useMemo(() => {
    if (contact && contact.primaryPhoneNumberId) {
      const phone = contact.phoneNumbers.find(
        (p) => p._id === contact.primaryPhoneNumberId,
      );
      return phone && phone.phone;
    }
    return null;
  }, [contact]);
  const primaryEmail = useMemo(() => {
    if (contact && contact.primaryEmailId) {
      const email = contact.emails.find(
        (p) => p._id === contact.primaryEmailId,
      );
      return email && email.email;
    }
    return null;
  }, [contact]);
  const profilePhoto = useMemo(() => {
    if (contact && contact.profilePhoto) {
      return contact.profilePhoto;
    }
    return null;
  }, [contact]);

  return (
    <List.Item>
      {!loading && contact && (
        <div className="container">
          <Title
            level={5}
            style={{ marginBottom: 4, color: sharedSettings.colors.darkGray }}
          >
            {ContactTypes[contact.contactType].label}
          </Title>
          <div className="info">
            <PhotoCircle
              photo={profilePhoto}
              size="sm"
              name={contact.fullName}
              style={{ height: 36, paddingBottom: 'unset', marginRight: 8 }}
              color={ContactTypes[contact.contactType].color}
            />
            <div className="details">
              <Link to={`/contact/view/${contact._id}/dashboard`}>
                <Title
                  level={4}
                  style={{
                    margin: 0,
                    marginBottom: 4,
                    color: ContactTypes[contact.contactType].color,
                  }}
                >
                  {contact.fullName}
                </Title>
              </Link>
              {contact.regionName && (
                <Text>
                  <GlobalOutlined style={{ marginRight: 8 }} />
                  {contact.regionName}
                </Text>
              )}
              {primaryPhoneNumber && (
                <Text>
                  <PhoneOutlined style={{ marginRight: 8 }} />
                  {primaryPhoneNumber}
                </Text>
              )}
              {primaryEmail && (
                <Text>
                  <MailOutlined style={{ marginRight: 8 }} />
                  {primaryEmail}
                </Text>
              )}
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .info {
          display: flex;
        }
        .container {
          display: flex;
          flex-direction: column;
        }
        .details {
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </List.Item>
  );
};

export default AssociatedPeopleInfo;
