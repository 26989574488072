import { Form } from 'antd';
import React, { useEffect } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import SelectAdoptionStatus from './SelectAdoptionStatusA10';

function ChangeStatusInnerModalA10({
  adoption,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (adoption) {
      form.setFieldsValue({
        status: adoption.status,
      });
    }
  }, [adoption, form]);
  return (
    <TaskSomethingForm
      title="Change Sponsorship Status"
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="status"
        label="Sponsorship Status"
        rules={[{ required: true, message: 'Please select a status' }]}
      >
        <SelectAdoptionStatus placeholder="Select ..." disabled={saving} />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default ChangeStatusInnerModalA10;
