import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useState } from 'react';
import ShowInCatalogModal from './ShowInCatalogModal';
import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';

const { Title, Text, Paragraph } = Typography;

function ProjectCatalogStatus({ project }) {
  const [showCatalogModal, setShowCatalogModal] = useState();

  return (
    <>
      {project.stage === HolisticProjectStages.FUNDING.key && (
        <>
          <Title level={3}>Catalog</Title>
          <div style={{ textAlign: 'center' }}>
            {project.showInCatalog ? (
              <>
                <Paragraph style={{ fontSize: 14 }}>
                  This project is currently{' '}
                  <Text>
                    <EyeOutlined /> VISIBLE
                  </Text>{' '}
                  in the public catalog.
                </Paragraph>
                <Paragraph>
                  <Button onClick={() => setShowCatalogModal(true)}>
                    Remove from Catalog
                  </Button>
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph style={{ fontSize: 14, marginBottom: 4 }}>
                  This project is currently{' '}
                  <Text>
                    <EyeInvisibleOutlined /> NOT VISIBLE
                  </Text>{' '}
                  in the public catalog.
                </Paragraph>
                {(!project.primaryPhotoId || !project.primaryBannerId) && (
                  <Paragraph
                    style={{ fontSize: 14, marginBottom: 4, fontWeight: 600 }}
                  >
                    You must select a primary and banner photo for this project
                    first.
                  </Paragraph>
                )}
                <Paragraph>
                  <Button
                    type="primary"
                    onClick={() => setShowCatalogModal(true)}
                    disabled={
                      !project.primaryPhotoId || !project.primaryBannerId
                    }
                    style={{ marginTop: 16 }}
                  >
                    Show in Catalog
                  </Button>
                </Paragraph>
              </>
            )}
          </div>
          <ShowInCatalogModal
            showingModal={showCatalogModal}
            setShowingModal={setShowCatalogModal}
            project={project}
            queryIds={[]}
          />
        </>
      )}
    </>
  );
}

export default ProjectCatalogStatus;
