import { useApolloClient } from '@apollo/client';
import { notification } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  incrementQueryAction,
  updateQueryAction,
} from '../redux-store/queries-store';
import { hashCode } from './utils';

function useOneM1({
  variables,
  query,
  queryId,
  extract,
  fetchPolicy = 'network-only',
  updateAction,
  skip,
  showError = true,
}) {
  const [loading, setLoading] = useState(false);
  const queryCode = useMemo(() => {
    const code = hashCode(JSON.stringify(variables));
    return code;
  }, [variables]);

  const { refetches, after } = useSelector((store) => {
    return (
      store.queries[queryId]?.[queryCode] || {
        refetches: 0,
        after: undefined,
      }
    );
  }, shallowEqual);

  const apolloClient = useApolloClient();
  const abort = useRef({});
  const last = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateQueryAction(
        {
          _id: queryCode,
          refetches,
          after: '',
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await apolloClient.query({
          query,
          variables,
          fetchPolicy,
          context: {
            fetchOptions: {
              signal: abort.current.signal,
            },
          },
        });
        const results = result && extract(result.data);
        if (results) {
          dispatch(updateAction(results, queryId));
        }
      } catch (err) {
        if (showError) {
          console.error(err);
          notification.error({
            message: 'Error',
            description: err.message,
          });
        } else {
          console.log(err);
        }
      } finally {
        setLoading(false);
      }
    }

    const inputs = JSON.stringify({
      refetches,
      queryCode,
    });
    if (!skip && inputs !== last.current) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    apolloClient,
    dispatch,
    extract,
    fetchPolicy,
    query,
    queryCode,
    queryId,
    refetches,
    updateAction,
    variables,
    skip,
    showError,
  ]);
  return {
    queryCode,
    loading,
    refetch: () => dispatch(incrementQueryAction(queryCode, queryId)),
  };
}

export default useOneM1;
