import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { Select } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import settings from '../../../settings';
import {
  allUpgXContactsQuery,
  extractUpgXContacts,
} from '../../upg-x-contacts/constants';

const queryId = 'SelectFromContactPeopleGroupsA10';
const pgName = (pg) => `${pg.nameAcrossCountries} - ${pg.country} (${pg._id})`;

function SelectFromContactPeopleGroupsA10({
  value,
  onChange,
  contactId,
  disabled,
  style,
}) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ contactId });
  const {
    error,
    loading,
    data: upgXContacts,
    search: upgXContactsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgXContactsQuery,
    extract: extractUpgXContacts,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const options = useMemo(() => {
    if (upgXContacts) {
      return upgXContacts.map((item) => {
        const title = pgName(item.peopleGroup);
        return {
          value: item.peopleGroup._id,
          label: title,
          title,
        };
      });
    }
    return [];
  }, [upgXContacts]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a project"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => upgXContactsSearch(v)}
      loading={loading}
      disabled={disabled}
    />
  );
}

export default SelectFromContactPeopleGroupsA10;
