import { gql, useMutation } from '@apollo/client';
import { Button, Form, notification, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import {
  accountQuery,
  extractAccount,
} from '../accounting/accounts-j25/constants';
import { UpgSponsorshipPoolAccountId } from '../pushpay/a10-associate-donations/constants';
import { updateAccountAction } from '../../redux-store/accounts-store';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { displayMoney } from '@aims/shared/shared/utils';

const allocateToAdoptionFromPoolMutation = gql`
  mutation AllocateToAdoptionFromPool($adoptionId: ID!, $amount: BigInt!) {
    allocateToAdoptionFromPool(adoptionId: $adoptionId, amount: $amount)
  }
`;

const { Paragraph } = Typography;

const queryId = 'AllocateFromPoolModal';

function AllocateFromPoolModal({ visible, onFinish, onCancel }) {
  const { adoption } = visible || {};
  const [saving, setSaving] = useState(false);
  const [allocateToAdoptionFromPool] = useMutation(
    allocateToAdoptionFromPoolMutation,
  );
  const [form] = Form.useForm();

  const { loading, refetch } = useOneM1({
    variables: { _id: UpgSponsorshipPoolAccountId },
    query: accountQuery,
    extract: extractAccount,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateAccountAction,
    skip: !visible,
    showError: true,
  });
  const account = useSingleSomethingA10(
    'accounts',
    UpgSponsorshipPoolAccountId,
    queryId,
  );

  const once = useRef();
  useEffect(() => {
    if (!visible) {
      form.resetFields();
      once.current = false;
    } else if (!once.current) {
      once.current = true;
      refetch();
    }
  }, [form, visible, refetch]);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await allocateToAdoptionFromPool({
          variables: {
            adoptionId: adoption._id,
            amount: values.amount,
          },
        });
        notification.success({
          message: 'Success',
          description: 'Tranfer created successfully',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize, an error occurred.',
        });
      }
      setSaving(false);
    },
    [adoption, allocateToAdoptionFromPool, onFinish],
  );
  return (
    <Modal
      open={visible}
      closable={!saving}
      destroyOnClose
      maskClosable
      onCancel={onCancel}
      footer={null}
      header={null}
      // styles={{ body: { textAlign: 'center' } }}
    >
      <TaskSomethingForm
        title="Allocate from UPG Sponsorship Pool"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
        saveText="Allocate"
      >
        <Paragraph>
          {`This will tranfer money from the UPG Sponsorship Pool to this UPG
          sponsorship's account`}
        </Paragraph>
        <Paragraph>
          {`Upg Sponsorship Pool Balance: ${displayMoney(
            account?.balance?.balance ? account.balance.balance / 10000 : 0,
          )}`}
        </Paragraph>
        <Form.Item
          label="Amount (USD)"
          name="amount"
          initialValue={0}
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DollarX4InputNumber disabled={saving} />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
}

export default AllocateFromPoolModal;
