import React, { useRef, useState } from 'react';
import TransfersTabInner from './TransfersTabInner';
import useLocalTransfers from './use-local-transfers';

const queryId = 'transactionsTabLocal';

function TransfersTabLocal({ filters: parentFilters, hideActions }) {
  const [sortedBy, setSortedBy] = useState('originalDate');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({ ...parentFilters });
  const {
    loading,
    search: transferSearch,
    data: transfers,
    refetch,
  } = useLocalTransfers({
    filters: filters.current,
    queryId,
  });

  return (
    <TransfersTabInner
      parentFilters={parentFilters}
      hideActions={hideActions}
      filters={filters}
      transferSearch={transferSearch}
      sortBy={sortBy}
      sortedBy={sortedBy}
      sortOrder={sortOrder}
      transfers={transfers}
      loading={loading}
    />
  );
}

export default TransfersTabLocal;
