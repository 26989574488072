import { useApolloClient, gql, useMutation } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { constantContactCheckCredentialsQuery } from './constants';

const constantContactAuth1Mutation = gql`
  mutation ConstantContactAuth1 {
    constantContactAuth1
  }
`;

function ConstantContactSettings() {
  const client = useApolloClient();
  const [valid, setValid] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    async function doStuff() {
      try {
        const response = await client.query({
          query: constantContactCheckCredentialsQuery,
          variables: {},
          fetchPolicy: 'network-only',
        });
        setValid(
          response &&
            response.data &&
            response.data.constantContactCheckCredentials,
        );
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    }
    doStuff();
  }, [client]);

  const [constantContactAuth1] = useMutation(constantContactAuth1Mutation);
  const doCCSignIn = useCallback(async () => {
    try {
      const response = await constantContactAuth1({
        variables: {},
        fetchPolicy: 'no-cache',
      });
      const url =
        response && response.data && response.data.constantContactAuth1;
      if (url) {
        location.href = url;
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
  }, [constantContactAuth1]);

  return (
    <Card title="Constant Contact">
      {!!valid && (
        <div style={{ marginBottom: 16 }}>Credentials are Up-to-Date</div>
      )}
      <div>
        <Button type={valid ? 'default' : 'primary'} onClick={doCCSignIn}>
          Sign into Constant Contact
        </Button>
      </div>
    </Card>
  );
}

export default ConstantContactSettings;
