import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import settings from '../../settings';
import useProfile from '../../shared/use-profile';
import EventsList from './EventsList';
import { allEventsForAdminQuery, extractAllEventsForAdmin } from './constants';
import CreateEventModal from './CreateEventModal';

const queryId = 'events-page';

function ListEventsPage() {
  const [sortedBy, setSortedBy] = useState('startTime');
  const [sortOrder, setSortOrder] = useState('DESC');

  const startsAfter = useMemo(() => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    d.setMilliseconds(0);
    d.setSeconds(0);
    d = d.toISOString();
    return d;
  }, []);
  const filters = useRef({ startsAfter });
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: events,
    search: eventSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allEventsForAdminQuery,
    extract: extractAllEventsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const [creating, setCreating] = useState();
  const onAdd = () => setCreating(true);
  const onFinishAdd = () => {
    setCreating(false);
    refetch();
  };
  const onCancelAdd = () => setCreating(false);

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        eventSearch(search.toLowerCase());
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [eventSearch, refetch, sortedBy, sortOrder],
  );
  const profile = useProfile();
  const [onlyMyEvents, setOnlyMyEvents] = useState();
  const onShowMyEvents = useCallback(() => {
    if (onlyMyEvents) {
      setOnlyMyEvents(false);
      filters.current = {
        ...filters.current,
        subscriberId: undefined,
      };
      refetch();
    } else {
      setOnlyMyEvents(true);
      filters.current = {
        ...filters.current,
        subscriberId: profile._id,
      };
      refetch();
    }
  }, [onlyMyEvents, refetch, profile]);
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const onShowPrevEvents = useCallback(() => {
    if (showPreviousEvents) {
      setShowPreviousEvents(false);
      filters.current = {
        ...filters.current,
        startsAfter,
      };
      refetch();
    } else {
      setShowPreviousEvents(true);
      filters.current = {
        ...filters.current,
        startsAfter: undefined,
      };
      refetch();
    }
  }, [showPreviousEvents, refetch, startsAfter]);

  return (
    <CSPage title="Events" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="Events"
        topActions={[
          <Button
            key="add"
            type="link"
            onClick={onAdd}
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add a new event
          </Button>,
        ]}
      />
      <div style={{ marginBottom: 16 }}>
        <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="search"
              style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
            >
              <Input placeholder="Search" suffix={<SearchOutlined />} />
            </Form.Item>
            <div style={{ flex: 1 }} />
            <Button
              onClick={onShowPrevEvents}
              icon={
                showPreviousEvents ? <EyeInvisibleOutlined /> : <EyeOutlined />
              }
              style={{ marginRight: 8 }}
            >
              {showPreviousEvents
                ? 'Hide Previous Events'
                : 'Show Previous Events'}
            </Button>
            <Button
              onClick={onShowMyEvents}
              style={{ marginRight: 8 }}
              icon={onlyMyEvents ? <TeamOutlined /> : <UserOutlined />}
            >
              {onlyMyEvents ? 'Show All Events' : 'Show Only My Events'}
            </Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={async () => {
                await reset();
              }}
            >
              Refresh
            </Button>
          </div>
        </Form>
      </div>
      <EventsList
        events={events}
        loading={loading}
        gridColumns={{ xs: 1, sm: 1, md: 3, lg: 4, xl: 5, xxl: 6, gutter: 16 }}
        queryId={queryId}
        refetch={refetch}
      />
      <CreateEventModal
        visible={creating}
        onFinish={onFinishAdd}
        onCancel={onCancelAdd}
        refetch={refetch}
        fieldsToSet={{}}
      />
    </CSPage>
  );
}

export default ListEventsPage;
