import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import YesNoModal from '../../components/YesNoModal';
import { deleteActivityMutation } from './EditActivity/constants';

function DeleteActivityModal({ activity, setActivity, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteActivity] = useMutation(deleteActivityMutation);
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteActivity({
        variables: {
          _id: activity._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Activity deleted successfully',
      });
      setTimeout(() => {
        setActivity(null);
        if (onComplete) {
          onComplete();
        } else {
          history.goBack();
        }
      }, 1000);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [activity, deleteActivity, setActivity, history, onComplete]);

  const onNo = useCallback(() => {
    if (!loading) {
      setActivity(null);
    }
  }, [setActivity, loading]);

  useEffect(() => {
    if (!activity) {
      setLoading(false);
      setError(false);
    }
  }, [activity]);

  return (
    <YesNoModal
      title="Delete Activity"
      question={`Are you sure you want to delete this activity (${
        activity && activity.type
      }: ${activity && ellipsis(activity.descText)})?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!activity}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteActivityModal;
