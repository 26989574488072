import React from 'react';
import ProjectFundingStatus from '../AccountingTab/ProjectFundingStatus';
import ProjectPaybackStatus from '../AccountingTab/ProjectPaybackStatus';
import InitiateLoanSection from '../HolisticStatusTab/InitiateLoanSection';

function AccountsSummaryTab({ project }) {
  return (
    project && (
      <>
        <ProjectFundingStatus project={project} showIt />
        <div style={{ height: 32 }} />
        <InitiateLoanSection project={project} showIt />
        <div style={{ height: 32 }} />
        <ProjectPaybackStatus project={project} showIt />
      </>
    )
  );
}

export default AccountsSummaryTab;
