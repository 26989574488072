import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';

export const updateHolisticProjectMutation = gql`
  mutation HProjectChangeSomethingModal(
    $holisticProject: HolisticProjectUpdateInput!
  ) {
    updateHolisticProject(holisticProject: $holisticProject) {
      holisticProject {
        _id
      }
    }
  }
`;

function HProjectChangeSomethingModal({
  holisticProject,
  children,
  btnProps,
  btnText,
  noBtn,
  editing: editing1,
  onDone: onDone1,
  onCancel: onCancel1,
}) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: holisticProject._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Holistic project updated successfully',
        });
        if (noBtn) {
          onDone1();
        } else {
          onDone();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your holistic project',
        });
      }
      setSaving(false);
    },
    [holisticProject, updateHolisticProject, onDone, noBtn, onDone1],
  );
  return (
    <>
      <Modal
        open={noBtn ? editing1 : editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={noBtn ? onCancel1 : onCancel}
        styles={{ body: { paddingTop: 8 } }}
      >
        {React.cloneElement(children, {
          holisticProject,
          thing: holisticProject,
          handleFinish,
          saving,
          onCancel: noBtn ? onCancel1 : onCancel,
        })}
      </Modal>
      {!noBtn && (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={handleEdit}
          {...btnProps}
        >
          {btnText}
        </Button>
      )}
    </>
  );
}

export default HProjectChangeSomethingModal;
