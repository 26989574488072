import sharedSettings from '@aims/shared/sharedSettings';
import {
  EditOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button, Skeleton, Space, Tag, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../components/PhotoCircle';
import SubscribeToUserButton from '../SubscribeToUserButton';
import SignInAsMenu from './SignInAsMenu';

const { Title, Text } = Typography;

function UserTitle({ user }) {
  return (
    <div className="outer-container">
      {user ? (
        <div className="container">
          <PhotoCircle
            photoUrl={user.primaryThumbnailUrl}
            size="lg"
            name={user.name}
            customSize={148}
            style={{
              width: 148,
              height: 148,
              padding: 'unset',
              marginRight: 32,
            }}
          />
          <Space direction="vertical" style={{ flex: 1 }}>
            <Title style={{ marginBottom: 0 }}>{user.name}</Title>
            <div>
              {user.roles?.map((role) => (
                <Tag
                  color={sharedSettings.colors.primary}
                  key={role._id}
                  style={{ marginBottom: 4 }}
                >
                  {role.label}
                </Tag>
              ))}
            </div>
            {user.phone && (
              <div>
                <Text>
                  <PhoneOutlined style={{ marginRight: 8 }} />
                  {user.phone}
                </Text>
              </div>
            )}
            {user.email && (
              <div>
                <Text>
                  <MailOutlined style={{ marginRight: 8 }} />
                  {user.email}
                </Text>
              </div>
            )}
            {user.regionName && (
              <div>
                <Text>
                  <GlobalOutlined style={{ marginRight: 8 }} />
                  {user.regionName}
                </Text>
              </div>
            )}
          </Space>
          <div className="right">
            <SubscribeToUserButton userId={user._id} />
            <Tooltip title="Edit user">
              <Link to={`/user/edit/${user._id}`}>
                <Button type="link" size="large" icon={<EditOutlined />} />
              </Link>
            </Tooltip>
            {user.canSignInAs && <SignInAsMenu userId={user._id} />}
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <style jsx>{`
        .left {
          width: 180px;
        }
        .outer-container {
          padding-top: 16px;
        }
        .container {
          display: flex;
        }
        .left {
          padding-right: 32px;
        }
        .photo-placeholder {
          height: 128px;
          width: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${sharedSettings.colors.userColor};
          color: white;
          font-size: 48px;
          border-radius: 100%;
          overflow: hidden;
        }
        .profile-photo {
          height: 128px;
          width: 128px;
          border-radius: 100%;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
}

export default UserTitle;
