import { gql, useMutation } from '@apollo/client';

export const addEventToGoogleMutation = gql`
  mutation addEventToGoogle($eventId: ID!) {
    addEventToGoogle(eventId: $eventId) {
      event {
        _id
        contactIds
        invitedContactIds
        userIds
        title
        desc
        descText
        startTime
        endTime
        allDay
        location
        googleId {
          googleCalId
          googleUrl
        }
        reminderId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

function useAddEventsToGoogle() {
  const [addEventToGoogle] = useMutation(addEventToGoogleMutation);
  return addEventToGoogle;
}

export default useAddEventsToGoogle;
