import sharedSettings from '@aims/shared/sharedSettings';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Empty, Space, Table, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../../../components/PhotoCircle';
import AddOrganizationBtnAndModal from './AddOrganizationBtnAndModal';
import DeleteContactXOrgModal from './DeleteContactXOrgModal';
import UpdateContactXOrgModal from './UpdateContactXOrgModal';

const { Title, Text } = Typography;

function ContactOrganizations({ contact }) {
  const [updating, setUpdating] = useState();
  const handleUpdate = (contactXOrg) => setUpdating({ contactXOrg });
  const onFinishUpdate = () => setUpdating(undefined);
  const onCancelUpdate = () => setUpdating(undefined);

  const [deleting, setDeleting] = useState();
  const handleDelete = (contactXOrg) =>
    setDeleting({ contact, org: contactXOrg.org, contactXOrg });
  const onFinishDelete = () => setDeleting(undefined);
  const onCancelDelete = () => setDeleting(undefined);

  const columns = [
    {
      title: 'Organization',
      dataIndex: 'org.fullName',
      key: 'org.fullName',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 48, marginRight: 8 }}>
            <PhotoCircle
              photoUrl={record.org?.primaryThumbnailUrl}
              name={record.org?.fullName}
              customSize={48}
              color={sharedSettings.colors.organizationColor}
              style={{
                padding: 0,
                height: 48,
                width: 48,
              }}
            />
          </div>
          <Text>{record.org?.fullName}</Text>
        </div>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Link to={`/contact/view/${record.org?._id}/info`}>
              <Button icon={<EyeOutlined />} />
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              onClick={() => handleUpdate(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Remove">
            <Button
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ marginBottom: 32 }}>
      <Title level={2} style={{ marginBottom: 0 }}>
        Organizations
      </Title>
      <AddOrganizationBtnAndModal contact={contact} />
      <Table
        columns={columns}
        dataSource={contact.organizations}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Organizations"
            />
          ),
        }}
      />
      <UpdateContactXOrgModal
        visible={updating}
        onDone={onFinishUpdate}
        onCancel={onCancelUpdate}
      />
      <DeleteContactXOrgModal
        visible={deleting}
        onDone={onFinishDelete}
        onCancel={onCancelDelete}
      />
    </div>
  );
}

export default ContactOrganizations;
