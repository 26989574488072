import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { FormOutlined, HighlightOutlined } from '@ant-design/icons';
import { Button, Empty, Space, Table, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import CommentReportAuditModal from './CommentReportAuditModal';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

function getRowStatus(record) {
  if (record.isApproved) {
    return <Tag color="green">Approved</Tag>;
  }
  if (record.isApproved) {
    return <Tag color="yellow">Submitted</Tag>;
  }
  if (record.isExcused) {
    return <Tag color="blue">Excused</Tag>;
  }
  return <Tag color="red">Not Submitted</Tag>;
}

function ProjectReportStatus({ project }) {
  const [editing, setEditing] = useState(undefined);
  const onEdit = (record) => setEditing({ reportAudit: record });
  const onCancelEdit = () => setEditing(undefined);
  const onFinishEdit = () => setEditing(undefined);
  return (
    <>
      {[
        HolisticProjectStages.ACTIVE.key,
        HolisticProjectStages.COMPLETE.key,
        HolisticProjectStages.FAILED.key,
      ].includes(project.stage) && (
        <>
          <Title level={3}>Reporting</Title>
          <Table
            dataSource={project.reportAudit}
            pagination={false}
            style={{ width: '100%' }}
            rowKey="quarter"
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Report Audit Available"
                />
              ),
            }}
          >
            <Table.Column
              title="Quarter"
              dataIndex="quarter"
              render={(text, record) => {
                return getQuarterLabel(record.quarter);
              }}
            />
            <Table.Column
              title="Status"
              dataIndex="status"
              render={(text, record) => {
                return getRowStatus(record);
              }}
            />
            <Table.Column
              title="Notes"
              dataIndex="notes"
              width={300}
              render={(text, record) => {
                return record.notes && <Text>{record.notes}</Text>;
              }}
            />
            <Table.Column
              title="Action"
              key="action"
              width={100}
              render={(text, record) => (
                <Space size="middle">
                  {project.payoutAccountId && project.payoutAccountId && (
                    <Tooltip title="Make Comment">
                      <Button
                        onClick={() => onEdit(record)}
                        icon={<HighlightOutlined />}
                      />
                    </Tooltip>
                  )}
                </Space>
              )}
            />
          </Table>
          <div style={{ marginTop: 16 }}>
            <Link to={`/holistic-project/view/${project._id}/reports`}>
              <Button icon={<FormOutlined />}>View Project Reports</Button>
            </Link>
          </div>
          <CommentReportAuditModal
            project={project}
            visible={editing}
            onCancel={onCancelEdit}
            onFinish={onFinishEdit}
          />
        </>
      )}
    </>
  );
}

export default ProjectReportStatus;
