import React, { useCallback } from 'react';
import YesNoModal from '../../../../components/YesNoModal';

function ContactMakePrimaryModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  child,
}) {
  const onYes = useCallback(async () => {
    await handleFinish({
      primaryContactId: child._id,
    });
  }, [handleFinish, child]);
  return (
    <YesNoModal
      title="Make Primary Contact"
      question={`Would you like to make ${child?.fullName} the primary contact for ${contact?.fullName}?`}
      yesText="Yes"
      noText="No"
      onYes={onYes}
      onNo={onCancel}
      visible={!!child}
      loading={saving}
    />
  );
}

export default ContactMakePrimaryModalA10;
