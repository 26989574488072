import { getFileIcon } from '@aims/shared/shared/icons';
import sharedSettings from '@aims/shared/sharedSettings';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip, Typography } from 'antd';
import filesize from 'filesize';
import React, { useMemo } from 'react';
import ellipsis from 'text-ellipsis';

const { Text } = Typography;

function DisplayAttachmentCard({ fileName, fileSize, thumbnailUrl, url }) {
  const icon = useMemo(() => {
    return getFileIcon(fileName, { fontSize: 18 });
  }, [fileName]);
  return (
    <Card
      styles={{
        body: { padding: '8px 16px' },
      }}
      style={{ marginBottom: 8, borderColor: sharedSettings.colors.textGray }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 16 }}>
          {thumbnailUrl ? (
            <img style={{ height: 48, borderRadius: 8 }} src={thumbnailUrl} />
          ) : (
            icon
          )}
        </div>
        <div style={{ marginRight: 8 }}>
          <Text>
            {`${ellipsis(fileName, 20)} (${filesize(fileSize, {
              round: 1,
            })})`}
          </Text>
        </div>
        <Tooltip title="Delete">
          <Button
            type="dashed"
            icon={<DownloadOutlined />}
            target="_blank"
            download={fileName}
            href={url}
          />
        </Tooltip>
      </div>
    </Card>
  );
}

export default DisplayAttachmentCard;
