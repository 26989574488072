import { gql } from '@apollo/client';

export const allRolesV2ForAdminQuery = gql`
  query AllRolesV2ForAdmin {
    allRolesV2ForAdmin {
      _id
      name
      label
      desc
      permissions {
        prefix
        isAll
        attrs
      }
    }
  }
`;

export const extractRolesV2ForAdmin = (data) => data && data.allRolesV2ForAdmin;
