export const READ_COUNTRIES = 'READ_COUNTRIES';
export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';

// eslint-disable-next-line no-shadow
export const readCountriesAction = (countries) => ({
  type: READ_COUNTRIES,
  payload: {
    countries,
  },
});

const initialCountries = [];

export function countries(state = initialCountries, action) {
  switch (action.type) {
    case READ_COUNTRIES: {
      return action.payload.countries;
    }
    default:
      return state;
  }
}
