import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';

const { Paragraph } = Typography;

const deleteSimpleListItemQuery = gql`
  mutation DeleteLanguageForAdmin($name: String!, $_id: ID!) {
    deleteSimpleListItemForAdmin(name: $name, _id: $_id)
  }
`;

function DeleteLanguageModal({ visible, onCancel, onFinish }) {
  const { language } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteLanguage] = useMutation(deleteSimpleListItemQuery);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteLanguage({
        variables: {
          name: 'tr-language',
          _id: language._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Language successfully deleted.',
      });
      onFinish();
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [deleteLanguage, language, onFinish]);

  return (
    <YesNoModal
      title="Delete Language"
      contents={
        <div>
          <Paragraph>
            {'Are you sure you want to delete this language?'}
          </Paragraph>
          <Paragraph>{language?.key}</Paragraph>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={!!visible}
      loading={loading}
    />
  );
}

export default DeleteLanguageModal;
