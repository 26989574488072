import sharedSettings from '@aims/shared/sharedSettings';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Menu,
  Modal,
  Space,
  Typography,
  message,
  notification,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';
import { generateUuid } from '../../../shared/utils';
import SelectRolesV2 from '../../roles-v2/SelectRolesV2';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';

const { Title, Text, Paragraph } = Typography;

export const createUserInviteMutation = gql`
  mutation CreateUserInvite($userInvite: UserInviteCreateInput!) {
    createUserInvite(userInvite: $userInvite) {
      userInvite {
        _id
      }
    }
  }
`;

export const updateUserInviteMutation = gql`
  mutation UpdateUserInvite($userInvite: UserInviteUpdateInput!) {
    updateUserInvite(userInvite: $userInvite) {
      userInvite {
        _id
      }
    }
  }
`;

export const resendUserInviteMutation = gql`
  mutation ResendUserInvite($userInviteId: ID!) {
    resendUserInvite(userInviteId: $userInviteId) {
      userInvite {
        _id
      }
    }
  }
`;

const deleteUserInviteMutation = gql`
  mutation deleteUserInvite($_id: ID!) {
    deleteUserInvite(_id: $_id) {
      deletedId
    }
  }
`;

function UserInvites({ contact, queryId, refetch }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [inviting, setInviting] = useState();
  const [deleting, setDeleting] = useState();
  const [editing, setEditing] = useState();
  const [createUserInvite] = useMutation(createUserInviteMutation);
  const [updateUserInvite] = useMutation(updateUserInviteMutation);
  const [deleteUserInvite] = useMutation(deleteUserInviteMutation);
  const [resendUserInvite] = useMutation(resendUserInviteMutation);

  const [updateForm] = Form.useForm();
  useEffect(() => {
    if (contact?.userInvite) {
      updateForm.setFieldsValue({
        ...contact.userInvite,
      });
    }
  }, [contact, updateForm]);

  const handleInvite = useCallback(() => {
    setInviting(true);
  }, []);
  const handleCancelInvite = useCallback(() => {
    setInviting(false);
  }, []);
  const handleFinishInvite = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await createUserInvite({
          variables: {
            userInvite: {
              _id: generateUuid(),
              contactId: contact._id,
              ...values,
            },
          },
        });
        refetch();
        notification.success({
          message: 'Success',
          description: 'User invite sent',
        });
        setInviting(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occured.',
        });
      }
      setLoading(false);
    },
    [createUserInvite, contact, refetch],
  );
  useEffect(() => {
    const email = (contact.emails || []).reduce((prev, curr) => {
      if (curr._id === contact.primaryEmailId) {
        return curr.email;
      }
      return prev;
    }, undefined);
    if (!email) {
      setError('Contact must have an email in order to invite them');
    }
  }, [contact]);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const handleEdit = useCallback(() => {
    setEditing(true);
  }, []);
  const handleCancelEdit = useCallback(() => {
    setEditing(false);
  }, []);

  const handleFinishEdit = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await updateUserInvite({
          variables: {
            userInvite: {
              _id: contact.userInvite._id,
              ...values,
            },
          },
        });
        refetch();
        notification.success({
          message: 'Success',
          description: 'User invite updated',
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occured.',
        });
      }
      setLoading(false);
    },
    [updateUserInvite, contact, refetch],
  );

  const handleDelete = useCallback(() => {
    setDeleting(true);
  }, []);
  const handleCancelDelete = useCallback(() => {
    setDeleting(false);
  }, []);

  const handleFinishDelete = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await deleteUserInvite({
          variables: {
            _id: contact.userInvite._id,
          },
        });
        refetch();
        notification.success({
          message: 'Success',
          description: 'User invite deleted',
        });
        setDeleting(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occured.',
        });
      }
      setLoading(false);
    },
    [deleteUserInvite, contact, refetch],
  );

  const handleResendInvite = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await resendUserInvite({
          variables: {
            userInviteId: contact.userInvite._id,
          },
        });
        refetch();
        notification.success({
          message: 'Success',
          description: 'User invite resent',
        });
        setDeleting(false);
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occured.',
        });
      }
      setLoading(false);
    },
    [resendUserInvite, contact, refetch],
  );
  return (
    <>
      {!contact.userInvite && (
        <>
          <div style={{ textAlign: 'center' }}>
            <Paragraph
              style={{ fontSize: 15 }}
            >{`${contact.fullName} does not have a user account.`}</Paragraph>
            <Paragraph style={{ fontSize: 15, marginBottom: 24 }}>
              Would you like to invite them to create one?
            </Paragraph>
            <Button
              onClick={handleInvite}
              icon={<SendOutlined />}
              type="primary"
            >
              Invite User
            </Button>
          </div>
          <Modal
            header={null}
            footer={null}
            open={!!inviting}
            closable={!loading}
            destroyOnClose={true}
            maskClosable={!loading}
            onCancel={handleCancelInvite}
            styles={{ body: { paddingTop: 48 } }}
          >
            <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
              Invite User
            </Title>
            <Form onFinish={handleFinishInvite} layout="vertical">
              <Form.Item
                name="roleIds"
                label="User Roles"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectRolesV2 disabled={loading} />
              </Form.Item>
              <Form.Item
                name="regionCode"
                label="Region"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectRegionForAdmin disabled={loading} />
              </Form.Item>
              <Paragraph style={{ textAlign: 'center', marginBottom: 24 }}>
                {`This will send ${contact.fullName} an email inviting them to create a user account.`}
              </Paragraph>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    marginRight: -8,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={handleCancelInvite}
                    htmlType="button"
                    disabled={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Send Invite
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {error && (
              <div style={{ textAlign: 'center' }}>
                <Text type="danger" style={{ marginTop: 16 }}>
                  {error}
                </Text>
              </div>
            )}
          </Modal>
        </>
      )}
      {contact.userInvite && (
        <>
          <Card>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <Title level={3} style={{ margin: 0 }}>
                  Invite Sent
                </Title>
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  {`Sent ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(contact.userInvite.createdAt))}`}
                </Text>
              </div>
              <Space>
                <Button
                  loading={loading}
                  icon={<SendOutlined />}
                  onClick={handleResendInvite}
                >
                  Resend
                </Button>
                <CopyToClipboard
                  text={`${settings.userAppUrl}/sign-up?i=${contact.userInvite._id}`}
                  onCopy={() => message.success('Copied!')}
                >
                  <Button icon={<CopyOutlined />}>Invite Link</Button>
                </CopyToClipboard>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item icon={<EditOutlined />} onClick={handleEdit}>
                        Change Invite
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        danger
                        icon={<DeleteOutlined />}
                        onClick={handleDelete}
                      >
                        Delete Invite
                      </Menu.Item>
                    </Menu>
                  }
                  trigger="click"
                >
                  <Button icon={<MoreOutlined />} />
                </Dropdown>
              </Space>
            </div>
          </Card>
          <Modal
            header={null}
            footer={null}
            open={editing}
            closable={!loading}
            destroyOnClose={true}
            maskClosable={!loading}
            onCancel={handleCancelEdit}
            styles={{ body: { paddingTop: 48 } }}
          >
            <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
              Edit Invite
            </Title>
            <Form
              onFinish={handleFinishEdit}
              layout="vertical"
              form={updateForm}
            >
              <Form.Item
                name="roleIds"
                label="User Roles"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectRolesV2 disabled={loading} />
              </Form.Item>
              <Form.Item
                name="regionCode"
                label="Region"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SelectRegionForAdmin disabled={loading} />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    marginRight: -8,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={handleCancelEdit}
                    htmlType="button"
                    disabled={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Update Invite
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {error && (
              <div style={{ textAlign: 'center' }}>
                <Text type="danger" style={{ marginTop: 16 }}>
                  {error}
                </Text>
              </div>
            )}
          </Modal>
          <Modal
            header={null}
            footer={null}
            visible={deleting}
            closable={!loading}
            destroyOnClose={true}
            maskClosable={!loading}
            onCancel={handleCancelDelete}
            styles={{ body: { paddingTop: 48 } }}
          >
            <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
              Delete Invite
            </Title>
            <Form onFinish={handleFinishDelete} layout="vertical">
              <Paragraph style={{ textAlign: 'center', marginBottom: 24 }}>
                {`Are you sure you want to delete this invite?`}
              </Paragraph>
              <Form.Item style={{ marginBottom: 0 }}>
                <div
                  style={{
                    marginRight: -8,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={handleCancelDelete}
                    htmlType="button"
                    disabled={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="danger"
                    loading={loading}
                    style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                  >
                    Delete Invite
                  </Button>
                </div>
              </Form.Item>
            </Form>
            {error && (
              <div style={{ textAlign: 'center' }}>
                <Text type="danger" style={{ marginTop: 16 }}>
                  {error}
                </Text>
              </div>
            )}
          </Modal>
        </>
      )}
    </>
  );
}

export default UserInvites;
