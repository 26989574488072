import { gql } from '@apollo/client';

export const PublicFormAttributes = gql`
  {
    _id
    formType
    formJson
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allPublicFormsQuery = gql`
  query AllPublicForms($first: Int, $after: String, $filters: PublicFormFilters, $sortBy: [SortBy]) {
    allPublicForms(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${PublicFormAttributes}
      }
    }
  }
`;

export const extractAllPublicForms = (data) => {
  const edges = data.allPublicForms.edges.map((edge) => {
    let formData;
    try {
      formData = edge.node.formJson && JSON.parse(edge.node.formJson);
    } catch (err) {
      formData = undefined;
    }
    return {
      ...edge,
      node: {
        ...edge.node,
        ...formData,
      },
    };
  });
  return {
    ...data.allPublicForms,
    edges,
  };
};

export const publicFormQuery = gql`
  query PublicForm($_id: ID!) {
    publicForm(_id: $_id) ${PublicFormAttributes}
  }
`;

export const extractPublicForm = (data) => {
  let formData;
  try {
    formData = data.publicForm.formJson && JSON.parse(data.publicForm.formJson);
  } catch (err) {
    formData = undefined;
  }
  return {
    ...data.publicForm,
    ...formData,
  };
};

export const deletePublicFormMutation = gql`
  mutation DeletePublicForm($_id: ID!) {
    deletePublicForm(_id: $_id) {
      deletedId
    }
  }
`;

export const PublicFormTypes = {
  'contact-us': {
    key: 'contact-us',
    label: 'Contact Us Form',
  },
  'partnership-app': {
    key: 'partnership-app',
    label: 'Partnership Application',
  },
  'coaching-req': {
    key: 'coaching-req',
    label: 'Coaching Request Form',
  },
  'live-training-app': {
    key: 'live-training-app',
    label: 'Live ITSM Seminar Registration Form ',
  },
  'strategy-workshop-app': {
    key: 'strategy-workshop-app',
    label: 'Missions Strategy Workshop Registration Form',
  },
  'online-course-req': {
    key: 'online-course-req',
    label: 'Online Training Request Form',
  },
  'adoption-registration-form': {
    key: 'adoption-registration-form',
    label: 'Adoption Registration Form',
  },
};
