import { Divider, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { TrainingQuarterlyReportQuestions } from './questions';

const { Title, Text } = Typography;

function TrainingQuarterlyReportItems({
  _id,
  report,
  loading,
  form,
  finishSubForm,
  showTitle,
  fieldsToSet,
}) {
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        ...report,
        ...TrainingQuarterlyReportQuestions.events.before(report),
      });
    } else {
      form.setFieldsValue({
        events: [{}],
      });
    }
  }, [report, form]);

  finishSubForm.current = useCallback(
    (values) => {
      const modified = { ...values };
      Object.keys(TrainingQuarterlyReportQuestions).forEach((k) => {
        if (TrainingQuarterlyReportQuestions[k].finish) {
          const result = TrainingQuarterlyReportQuestions[k].finish(values);
          Object.entries(result).map(([key, value]) => {
            modified[key] = value;
          });
        }
      });
      return {
        ...modified,
        ...fieldsToSet,
      };
    },
    [fieldsToSet],
  );
  return (
    <>
      {showTitle && (
        <TrainingQuarterlyReportQuestions.title.Display
          loading={loading}
          fieldsToSet={fieldsToSet}
        />
      )}
      {report && report.isSubmitted && (
        <TrainingQuarterlyReportQuestions.submittedBy.Display report={report} />
      )}
      {report && report.isApproved && (
        <TrainingQuarterlyReportQuestions.approvedBy.Display report={report} />
      )}
      <TrainingQuarterlyReportQuestions.quarter.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingQuarterlyReportQuestions.events.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingQuarterlyReportQuestions.prayerRequests.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingQuarterlyReportQuestions.miracles.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
      <TrainingQuarterlyReportQuestions.publicFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingQuarterlyReportQuestions.privateFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        fieldsToSet={fieldsToSet}
        form={form}
      />
      <TrainingQuarterlyReportQuestions.notes.FormItem
        loading={loading}
        fieldsToSet={fieldsToSet}
      />
    </>
  );
}

export default TrainingQuarterlyReportItems;
