import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import DeleteThingDropdown from '../../../../../components/DeleteThingDropdown';

const { Paragraph } = Typography;

const undoInitiateHolisticProjectPayoutMutation = gql`
  mutation UndoInitiateHolisticProjectPayout($_id: ID!) {
    undoInitiateHolisticProjectPayout(_id: $_id)
  }
`;

function UndoInitiatePayoutBtn({ project }) {
  const [saving, setSaving] = useState(false);
  const [undoPayout] = useMutation(undoInitiateHolisticProjectPayoutMutation);
  const [deleting, setDeleting] = useState(false);
  const onCancel = () => setDeleting(false);
  const onFinish = useCallback(() => setDeleting(false), []);
  const onDelete = () => setDeleting(true);

  const onSubmit = useCallback(async () => {
    setSaving(true);
    try {
      await undoPayout({
        variables: {
          _id: project._id,
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Transactions deleted successfully',
      });
      onFinish();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setSaving(false);
  }, [undoPayout, onFinish, project]);
  return (
    <>
      <DeleteThingDropdown
        thing={undefined}
        label="Undo Initiate Payout"
        handleDelete={onDelete}
        moreItems={[]}
        moreCases={() => {}}
      />
      <YesNoModalJ10
        title="Undo Initiate Payout"
        yesText="Yes"
        noText="Cancel"
        onYes={onSubmit}
        onNo={onCancel}
        visible={deleting}
        loading={saving}
      >
        <Paragraph
          style={{ marginBottom: 8 }}
        >{`This will delete every transaction created by initiating this project's payout.`}</Paragraph>
        <Paragraph style={{ marginBottom: 8, fontWeight: 600 }}>
          Are you sure you want to continue?
        </Paragraph>
      </YesNoModalJ10>
    </>
  );
}

export default UndoInitiatePayoutBtn;
