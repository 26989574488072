import { gql, useMutation } from '@apollo/client';

export const addTaskToGoogleMutation = gql`
  mutation addTaskToGoogle($taskId: ID!) {
    addTaskToGoogle(taskId: $taskId) {
      task {
        _id
        contactId
        eventId
        status
        importance
        title
        desc
        windowStart
        windowStop
        assigneeIds
        reminderId
        tags {
          key
          color
        }
        googleId
        createdBy
        updatedBy
        createdAt
        updatedAt
        deletedBy
        deletedAt
      }
    }
  }
`;

function useAddTasksToGoogle() {
  const [addTaskToGoogle] = useMutation(addTaskToGoogleMutation);
  return addTaskToGoogle;
}

export default useAddTasksToGoogle;
