import {
  DeleteOutlined,
  GoogleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Dropdown,
  Menu,
  notification,
  Skeleton,
  Typography,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateEventAction } from '../../../redux-store/events-store';
import AddEventToGoogleModal from '../AddEventToGoogleModal';
import DeleteEventModal from '../DeleteEventModal';
import {
  eventForAdminQuery,
  extractEventForAdmin,
  updateEventMutation,
} from '../constants';
import EditContacts from './EditContacts';
import EditEventDescription from './EditEventDescription';
import EditEventLocation from './EditEventLocation';
import EditEventSchedule from './EditEventSchedule';
import EditEventTitle from './EditEventTitle';
import SubscribeToEventButton from '../SubscribeToEventButton';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useOneM1 from '@aims/shared/shared/use-one-m1';

function EventDropdown({
  event,
  handleAddToGoogle,
  handleUpdateInGoogle,
  setDeleting,
}) {
  const items = [
    !event?.googleId && {
      key: 'addToGoogle',
      label: 'Add to Google Calendar',
      icon: <GoogleOutlined />,
    },
    event?.googleId && {
      key: 'updateInGoogle',
      label: 'Update Google Event',
      icon: <GoogleOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: 'delete',
      label: 'Delete Event',
      icon: <DeleteOutlined />,
    },
  ];
  function onClick({ key }) {
    switch (key) {
      case 'addToGoogle':
        handleAddToGoogle.current(event._id);
        break;
      case 'updateInGoogle':
        handleUpdateInGoogle.current(event._id);
        break;
      case 'delete':
        setDeleting(event);
        break;
      default:
        break;
    }
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger="click">
      <Button size="large" type="text" icon={<MoreOutlined />} />
    </Dropdown>
  );
}

const { Text } = Typography;

const ViewEventModal = ({ visible, closeModal, queryId, refetch }) => {
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const handleAddToGoogle = useRef();
  const handleUpdateInGoogle = useRef();
  const _id = visible;

  const fetchResult = useOneM1({
    variables: { _id },
    query: eventForAdminQuery,
    extract: extractEventForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateEventAction,
    queryId,
    skip: !_id || !refetch,
  });
  const { loading, error, refetch: refetch2 } = fetchResult;
  const event = useSingleSomethingA10('events', _id, queryId);

  const [updateEvent] = useMutation(updateEventMutation);

  const dispatch = useDispatch();

  const saveEvent = useCallback(
    async (values) => {
      try {
        setSaving(true);
        const result = await updateEvent({
          variables: {
            event: {
              _id: _id,
              ...values,
            },
          },
        });
        dispatch(
          updateEventAction({ queryId, ...result.data.updateEvent.event }),
        );
        notification.success({
          message: 'Saved',
          description: 'Event updated successfully',
        });
        if (refetch) {
          refetch();
          refetch2();
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
      setSaving(false);
    },
    [dispatch, updateEvent, _id, queryId, refetch, refetch2],
  );

  return (
    <>
      <Modal
        open={!!visible}
        closable={false}
        destroyOnClose={true}
        maskClosable={!saving}
        onCancel={closeModal}
        footer={null}
      >
        <div style={{ height: 36 }} />
        {event ? (
          <>
            <EditEventTitle event={event} onSubmit={saveEvent} />
            <EditEventSchedule event={event} onSubmit={saveEvent} />
            <EditEventLocation event={event} onSubmit={saveEvent} />
            <EditEventDescription event={event} onSubmit={saveEvent} />
            <EditContacts event={event} onSubmit={saveEvent} />
          </>
        ) : (
          <Skeleton avatar active />
        )}
        <div className="extra-opts">
          <SubscribeToEventButton eventId={_id} queryId={queryId} />
          <EventDropdown
            event={event}
            handleAddToGoogle={handleAddToGoogle}
            handleUpdateInGoogle={handleUpdateInGoogle}
            setDeleting={setDeleting}
          />
        </div>
      </Modal>
      <DeleteEventModal
        setEvent={setDeleting}
        event={deleting}
        onComplete={() => {
          closeModal();
          if (refetch) {
            refetch();
          }
        }}
      />
      <AddEventToGoogleModal
        handleAddToGoogle={handleAddToGoogle}
        handleUpdateInGoogle={handleUpdateInGoogle}
        queryId={queryId}
      />
      <style jsx>{`
        .extra-opts {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ViewEventModal;
