import { DeleteOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteActivityModal from '../../pages/activity/DeleteActivityModal';
import EditActivityForm from '../../pages/activity/EditActivity/EditActivityForm';
import { generateUuid } from '../../shared/utils';

const CreateActivityModal = ({
  visible,
  setVisible,
  queryId,
  fieldsToSet = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const activityId = useMemo(() => {
    if (visible === '__new__') {
      return generateUuid();
    }
    return visible;
  }, [visible]);

  const activity = useSelector(
    (store) =>
      store.activities &&
      store.activities[queryId] &&
      store.activities[queryId][activityId],
  );

  const { title, okText } = useMemo(() => {
    if (!activity) {
      return {
        title: 'Create Activity Log',
        okText: 'Save',
      };
    }
    return {
      title: activity && activity.title ? activity.title : 'Edit Activity Log',
      okText: 'Save',
    };
  }, [activity]);

  return (
    <>
      <Modal
        title={title}
        open={visible}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <div style={{ textAlign: 'left' }}>
          {visible && (
            <EditActivityForm
              _id={activityId}
              handleCancel={() => setVisible(false)}
              handleSuccess={() => setVisible(false)}
              activity={activity}
              loading={loading}
              setLoading={setLoading}
              fieldsToSet={fieldsToSet}
            />
          )}
          {!!activity && (
            <>
              <Divider />
              <div className="delete-box">
                <Button
                  onClick={() => setDeleting(activity)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete Activity
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
      <DeleteActivityModal
        setActivity={setDeleting}
        activity={deleting}
        onComplete={() => setVisible(false)}
      />
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
          width: 100%;
        }
      `}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreateActivityModal;
