import { useMutation } from '@apollo/client';
import { Switch, Typography, message } from 'antd';
import React, { useCallback, useState } from 'react';
import {
  makePayoutV2RowsFromPoolMutation,
  makePayoutV2RowsNotFromPoolMutation,
} from '../../constants';

const { Text } = Typography;

function PayoutFromPoolColumn({ record, editable }) {
  const [checked, setChecked] = useState(record.fromPool);
  const [makePayoutV2RowsFromPool] = useMutation(
    makePayoutV2RowsFromPoolMutation,
  );
  const [makePayoutV2RowsNotFromPool] = useMutation(
    makePayoutV2RowsNotFromPoolMutation,
  );

  const handleFinish = useCallback(
    async (value) => {
      setChecked(value);
      const hide = message.info('Saving ...');
      try {
        if (value) {
          await makePayoutV2RowsFromPool({
            variables: {
              rowIds: [record._id],
            },
          });
        } else {
          await makePayoutV2RowsNotFromPool({
            variables: {
              rowIds: [record._id],
            },
          });
        }
        hide();
        message.success('Done');
      } catch (err) {
        console.error(err);
        message.error(err.message);
      }
    },
    [record, makePayoutV2RowsFromPool, makePayoutV2RowsNotFromPool],
  );

  return (
    <Switch
      checked={checked}
      onChange={handleFinish}
      checkedChildren="Yes"
      unCheckedChildren="No"
    />
  );
}

export default PayoutFromPoolColumn;
