import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import useLanguages from './use-languages';
import { titleCase } from '@aims/shared/shared/utils';

function SelectLanguage({
  value: parentValue,
  onChange: parentOnChange,
  disabled,
  style,
}) {
  const { languages, loading, refetch, search } = useLanguages();

  const value = useMemo(() => {
    if (parentValue && languages) {
      return languages.find((t) => t.key === parentValue);
    }
    return null;
  }, [parentValue, languages]);

  const onChange = useCallback(
    (vs) => {
      const language = languages.find((t) => t.key === vs);
      parentOnChange(language?.key);
    },
    [parentOnChange, languages],
  );

  const options = useMemo(() => {
    if (languages) {
      return languages.map((language, index) => {
        return {
          value: language.key,
          label: titleCase(language.key),
        };
      });
    }
    return [];
  }, [languages]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select language"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      showSearch
      onSearch={(term) => search(term)}
      filterOption={false}
      disabled={disabled}
      loading={loading}
      optionRender={(option) => option.data.label}
    />
  );
}

export default SelectLanguage;
