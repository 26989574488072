import { Select, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Fuse from 'fuse.js';

const { Text } = Typography;

const pgName = (pg) => `${pg.nameAcrossCountries} - ${pg.country} (${pg._id})`;

const pgSearchOptions = {
  includeScore: true,
  keys: ['nameAcrossCountries', 'country', '_id'],
};

function SelectFromMyPeopleGroups({
  value,
  onChange,
  pgConnections,
  ...props
}) {
  const allPgs = useMemo(() => {
    return (pgConnections || []).map((c) => c.peopleGroup);
  }, [pgConnections]);
  const [pgs, setPgs] = useState([]);
  useEffect(() => {
    if (allPgs) {
      setPgs(allPgs);
    } else {
      setPgs([]);
    }
  }, [allPgs]);

  const search = useCallback(
    (v) => {
      if (v) {
        const fuse = new Fuse(allPgs, pgSearchOptions);
        let _data = fuse
          .search(v)
          .sort((a, b) => a.score - b.score)
          .map((i) => i.item);
        setPgs(_data);
      } else {
        setPgs(allPgs);
      }
    },
    [allPgs],
  );

  return (
    <Select
      showSearch
      filterOption={(input, option) => true}
      onSearch={search}
      autoComplete="chrome-off"
      onChange={onChange}
      placeholder="Select a people group"
      value={value}
      allowClear
      {...props}
    >
      {pgs.map((peopleGroup) => (
        <Select.Option
          key={peopleGroup._id}
          value={peopleGroup._id}
          title={pgName(peopleGroup)}
        >
          <div className="peopleGroup-option">
            <div>
              <Text>{pgName(peopleGroup)}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectFromMyPeopleGroups;
