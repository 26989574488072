import React from 'react';
import { Select, Form } from 'antd';
import { AdopterEmailStatuses } from './constants';

function SelectEmailStatus({ loading, formItemAttrs, selectAttrs }) {
  return (
    <Form.Item name="adopterEmailStatus" {...formItemAttrs}>
      <Select
        disabled={loading}
        placeholder="Select a email status ..."
        {...selectAttrs}
      >
        {Object.values(AdopterEmailStatuses).map(({ key, label }) => (
          <Select.Option key={key} value={key}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SelectEmailStatus;
