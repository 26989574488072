import { createStore, combineReducers, applyMiddleware } from 'redux';
import enUS from 'antd/es/locale/en_US';
import { authState, profile, SIGN_OUT } from './auth-store';
import { addresses } from './addresses-store';
import { windowDimensions, responsiveMode } from './window-store';
import { roles } from './roles-store';
import { users } from './users-store';
import { activities } from './activity-v2-store';
import { adoptions } from './adoptions-store';
import { payouts } from './payouts-store';
import { allocations } from './allocations-store';
import { historyV2 } from './history-v2-store';
import { contacts } from './contacts-store';
import { queries } from '@aims/shared/redux-store/queries-store';
import { peopleGroups } from './people-groups-store';
import { simpleLists } from './simple-lists-store';
import { countries } from '@aims/shared/redux-store/countries-store';
import { countryRegions } from '@aims/shared/redux-store/country-regions-store';
import { events } from './events-store';
import { tasks } from './tasks-store';
import { holisticProjects } from './holistic-projects-store';
import { trainingCourses } from './training-courses-store';
import { trainingFiles } from './training-files-store';
import { reports } from '@aims/shared/redux-store/reports-store';
import { donations } from './donations-store';
import { payers } from './payers-store';
import { funds } from './funds-store';
import { recurringPayments } from './recurring-payments-store';
import { accounts } from './accounts-store';
import { transfers } from './transfers-store';
import { prayerRequests } from './prayer-requests-store';
import { loanAgreements } from './loan-agreements-store';
import { mous } from './mous-store';
import { projectSponsorships } from './project-sponsorships-store';
import { miracles } from './miracles-store';
import { payoutV2s } from './payouts-v2-store';
import { payoutV2Rows } from './payout-v2-rows-store';
import { savedImages } from './saved-images-store';
import { upgPrayerAdoptions } from './upg-prayer-adoptions-store';
import { adoptionXContacts } from './adoption-x-contacts-store';
import { remoteQueries } from './remote-queries-store';
import thunk from 'redux-thunk';
import settings from '../settings';
import client from '../apollo-client';

const appReducer = combineReducers({
  addresses,
  authState,
  profile,
  roles,
  users,
  contacts,
  adoptions,
  payouts,
  allocations,
  historyV2,
  queries,
  remoteQueries,
  simpleLists,
  countries,
  countryRegions,
  peopleGroups,
  events,
  tasks,
  reports,
  projectSponsorships,
  activities,
  savedImages,
  holisticProjects,
  loanAgreements,
  mous,
  upgPrayerAdoptions,
  adoptionXContacts,
  payoutV2Rows,
  payoutV2s,
  donations,
  payers,
  funds,
  recurringPayments,
  accounts,
  transfers,
  windowDimensions,
  responsiveMode,
  trainingCourses,
  trainingFiles,
  prayerRequests,
  miracles,
  locale: () => enUS,
  settings: () => settings,
});

const rootReducer = (state, action) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
    console.log('action', action);
  }
  let newState;
  if (action.type === SIGN_OUT) {
    newState = appReducer(undefined, action);
    localStorage.clear();
    client.resetStore();
    client.close();
  } else {
    newState = appReducer(state, action);
  }
  if (process.env.NODE_ENV === 'development') {
    console.log('new state', newState);
  }
  return newState;
};

export default createStore(rootReducer, applyMiddleware(thunk));
