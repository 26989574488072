import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createPayoutV2Action } from '../../../../redux-store/payouts-v2-store';
import { generateUuid } from '../../../../shared/utils';
import SelectQuarter, {
  buildQuarterOptions,
} from '../../../adoption-stats/SelectQuarter';
import { createPayoutV2Mutation, PayoutStates } from '../constants';

const { Title, Text } = Typography;

function PayoutOptions({ saving, handleSave }) {
  const [form] = Form.useForm();

  const quarters = buildQuarterOptions();
  const onChanged = useCallback(
    (changed, values) => {
      if (changed.quarter !== undefined) {
        form.setFieldsValue({
          name: quarters[changed.quarter].label,
        });
      }
    },
    [quarters, form],
  );

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Text>Select a quarter and a name to begin building this payout.</Text>
      </div>
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={handleSave}
        onValuesChange={onChanged}
      >
        <SelectQuarter
          loading={saving}
          required={true}
          selectAttrs={{ allowClear: true }}
        />
        <Form.Item name="name" label="Name">
          <Input disabled={saving} />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea disabled={saving} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
              loading={saving}
            >
              Start Payout
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

const CreatePayoutV2Modal = ({ visible, onFinish, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const scrollToMe = useRef();
  const [createPayout] = useMutation(createPayoutV2Mutation);

  const handleSave = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { quarter, ...values } = _values;
        const payoutId = generateUuid();
        const result = await createPayout({
          variables: {
            payout: {
              _id: payoutId,
              ...values,
              quarter,
              state: PayoutStates.NEW.key,
            },
          },
        });
        dispatch(
          createPayoutV2Action({
            ...result.data.createPayoutV2.payout,
          }),
        );
        notification.success({
          message: 'Saved',
          description: 'Payout created successfully',
        });
        onFinish(payoutId);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
      }
    },
    [createPayout, dispatch, onFinish],
  );

  return (
    <>
      <Modal
        header={null}
        visible={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <div ref={scrollToMe} />
        <Title level={3} style={{ marginBottom: 4 }}>
          Start a Payout
        </Title>
        {visible && <PayoutOptions handleSave={handleSave} saving={loading} />}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreatePayoutV2Modal;
