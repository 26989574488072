import { Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SelectContact from '../../contacts/SelectContact';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function HProjectChangeCoordinatorInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Project Coordinator',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        regionalCoordId: holisticProject.regionalCoordId,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { regionalCoordId, ...values } = _values;
      handleFinish({
        regionalCoordId,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Project Coordinator"
        name="regionalCoordId"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <SelectContact
          disabled={saving}
          filters={{ isRegionalCoord: true }}
          allowClear
        />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeCoordinatorInner;
