import { generateUuid } from '@aims/shared/shared/utils';
import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import SelectHolisticProject from '../holistic-projects/SelectHolisticProject';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';

export const createMiracleMutation = gql`
  mutation CreateMiracleForAdmin($miracle: MiracleCreateInput!) {
    createMiracleForAdmin(miracle: $miracle) {
      miracle {
        _id
      }
    }
  }
`;

const CreateMiracleModalJ15 = ({ visible, onFinish, onCancel }) => {
  const [saving, setSaving] = useState(false);
  const [createMiracle] = useMutation(createMiracleMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await createMiracle({
          variables: {
            miracle: {
              _id: generateUuid(),
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Testimony saved successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your testimony',
        });
      }
      setSaving(false);
    },
    [createMiracle, onFinish],
  );

  const [form] = Form.useForm();

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={oncancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Testimony"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item name="peopleGroupId" label="People Group" rules={[]}>
          <SelectPeopleGroup disabled={saving} />
        </Form.Item>
        <Form.Item name="holisticProjectId" label="Holistic Project" rules={[]}>
          <SelectHolisticProject disabled={saving} />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Testimony"
          rules={[
            {
              required: true,
              message: 'Please enter the testimony description',
            },
          ]}
        >
          <Input.TextArea rows={4} disabled={saving} />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default CreateMiracleModalJ15;
