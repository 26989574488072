import { Divider, Form, Table, Typography } from 'antd';
import React from 'react';
import useSettings from '../../shared/use-settings';
import { displayMoney, getNumberValue } from '../../shared/utils';

const { Text } = Typography;

function ProjectAnalysisSummary({
  exchangeRate,
  expensesForeign,
  toSupportedFieldWorkersForeign,
  profitsForeign,
  loanPaymentsThisQuarterUsd,
}) {
  const settings = useSettings();
  let expensesUsd;
  let toSupportedFieldWorkersUsd;
  let profitsUsd;
  let loanPaymentsThisQuarterForeign;
  let netProfitForeign;
  let netProfitUsd;
  let subProfitForeign;
  let subProfitUsd;
  if (exchangeRate > 0) {
    expensesUsd = expensesForeign / exchangeRate;
    toSupportedFieldWorkersUsd = toSupportedFieldWorkersForeign / exchangeRate;
    profitsUsd = profitsForeign / exchangeRate;
    loanPaymentsThisQuarterForeign = loanPaymentsThisQuarterUsd * exchangeRate;
    subProfitForeign =
      profitsForeign - expensesForeign - toSupportedFieldWorkersForeign;
    subProfitUsd = subProfitForeign / exchangeRate;
    netProfitForeign =
      profitsForeign -
      expensesForeign -
      toSupportedFieldWorkersForeign -
      loanPaymentsThisQuarterForeign;
    netProfitUsd = netProfitForeign / exchangeRate;
  }
  return (
    <>
      <Table
        bordered
        dataSource={[
          {
            row: 'A',
            label: 'Revenue This Quarter',
            value: profitsForeign,
            usd: displayMoney(getNumberValue(profitsUsd)),
            color: undefined,
          },
          {
            row: 'B',
            label: 'Expenses This Quarter',
            value: expensesForeign,
            usd: displayMoney(getNumberValue(expensesUsd)),
            color: '#d4380d',
          },
          {
            row: 'C',
            label: 'Field Worker Support This Quarter',
            value: toSupportedFieldWorkersForeign,
            usd: displayMoney(getNumberValue(toSupportedFieldWorkersUsd)),
            color: '#d4380d',
          },
          {
            row: 'D',
            label: 'Profit before Loan Payments This Quarter (A - B - C)',
            value: getNumberValue(subProfitForeign),
            usd: displayMoney(getNumberValue(subProfitUsd)),
            color: getNumberValue(subProfitForeign) < 0 ? 'red' : undefined,
          },
          {
            row: 'E',
            label: 'Loan Payments This Quarter',
            value: getNumberValue(loanPaymentsThisQuarterForeign),
            usd: displayMoney(getNumberValue(loanPaymentsThisQuarterUsd)),
            color: '#d4380d',
          },
          {
            row: 'F',
            label: 'Net Profit This Quarter (A - B - C - E)',
            value: getNumberValue(netProfitForeign),
            usd: displayMoney(getNumberValue(netProfitUsd)),
            color: getNumberValue(netProfitForeign) < 0 ? 'red' : undefined,
          },
        ]}
        showHeader
        pagination={false}
      >
        <Table.Column
          dataIndex="row"
          key="row"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="label"
          key="label"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="Local Currency"
          dataIndex="value"
          key="value"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="USD"
          dataIndex="usd"
          key="usd"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
      </Table>
    </>
  );
}

export default ProjectAnalysisSummary;
