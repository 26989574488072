export const READ_RECURRING_PAYMENTS = 'READ_RECURRING_PAYMENTS';
export const CREATE_RECURRING_PAYMENT = 'CREATE_RECURRING_PAYMENT';
export const UPDATE_RECURRING_PAYMENT = 'UPDATE_RECURRING_PAYMENT';
export const DELETE_RECURRING_PAYMENT = 'DELETE_RECURRING_PAYMENT';

// eslint-disable-next-line no-shadow
export const readRecurringPaymentsAction = (recurringPayments) => ({
  type: READ_RECURRING_PAYMENTS,
  payload: {
    recurringPayments,
  },
});

export const createRecurringPaymentAction = (recurringPayment) => ({
  type: CREATE_RECURRING_PAYMENT,
  payload: {
    recurringPayment,
  },
});

export const updateRecurringPaymentAction = (recurringPayment) => ({
  type: UPDATE_RECURRING_PAYMENT,
  payload: {
    recurringPayment,
  },
});

export const deleteRecurringPaymentAction = (recurringPaymentId) => ({
  type: DELETE_RECURRING_PAYMENT,
  payload: {
    recurringPaymentId,
  },
});

const initialRecurringPayments = [];

export function recurringPayments(state = initialRecurringPayments, action) {
  switch (action.type) {
    case READ_RECURRING_PAYMENTS: {
      const newState = { ...state };
      action.payload.recurringPayments.forEach((p) => {
        newState[p._id] = p;
      });
      return newState;
    }
    case CREATE_RECURRING_PAYMENT:
      return {
        ...state,
        [action.payload.recurringPayment._id]: action.payload.recurringPayment,
      };
    case UPDATE_RECURRING_PAYMENT: {
      const current = state[action.payload.recurringPayment._id] || undefined;
      return {
        ...state,
        [action.payload.recurringPayment._id]: {
          ...current,
          ...action.payload.recurringPayment,
        },
      };
    }
    case DELETE_RECURRING_PAYMENT: {
      const { recurringPaymentId } = action.payload;
      return { ...state, [recurringPaymentId]: undefined };
    }
    default:
      return state;
  }
}
