import sharedSettings from '@aims/shared/sharedSettings';

const HpInstallmentStatuses = {
  PAID: {
    key: 'PAID',
    label: 'Paid',
    color: sharedSettings.colors.green,
  },
  LATE: {
    key: 'LATE',
    label: 'Late',
    color: sharedSettings.colors.red,
  },
  FUTURE: {
    key: 'FUTURE',
    label: 'Future Payment',
    color: sharedSettings.colors.blue,
  },
  PARTIAL: {
    key: 'PARTIAL',
    label: 'Partially Paid',
    color: sharedSettings.colors.yellow,
  },
};

function getHpInstallmentStatus(installment) {
  if (installment.paidAmount === installment.amount) {
    return HpInstallmentStatuses.PAID;
  }
  if (installment.paidAmount) {
    return HpInstallmentStatuses.PARTIAL;
  }
  const now = new Date().getTime();
  const dueAt = new Date(installment.dueAt).getTime();
  if (dueAt < now) {
    return HpInstallmentStatuses.LATE;
  }
  return HpInstallmentStatuses.FUTURE;
}

export default getHpInstallmentStatus;
