import { ReportLineItem } from '@aims/shared/reports/common/ReportItems';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Skeleton, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { titleCase } from '../../shared/utils';
import { PublicFormTypes } from './constants';
import usePublicForm from './use-public-form';

const { Title, Text } = Typography;

function ReviewPublicForm() {
  const { _id } = useParams();

  const { loading, error, publicForm } = usePublicForm(_id);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const data = useMemo(() => {
    if (publicForm) {
      const {
        formType,
        name,
        email,
        createdAt,
        _id: formId,
        token,
        ...values
      } = publicForm;
      const formTypeLabel =
        formType &&
        PublicFormTypes[publicForm.formType] &&
        PublicFormTypes[publicForm.formType].label;
      return {
        label: formTypeLabel,
        name,
        email,
        createdAt: new Intl.DateTimeFormat(locale, {
          dateStyle: 'full',
        }).format(new Date(createdAt)),
        values,
      };
    }
  }, [locale, publicForm]);

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }} title="Pushpay">
      <CSPageHeader
        titleComponent={
          data ? (
            <div style={{ marginBottom: 32, textAlign: 'center' }}>
              <Title style={{ marginBottom: 4 }}>
                <FormOutlined style={{ marginRight: 16 }} />
                {data.label}
              </Title>
              <div>
                <Text
                  style={{
                    fontSize: 16,
                    color: sharedSettings.colors.textGray,
                  }}
                >
                  {data.createdAt}
                </Text>
              </div>
            </div>
          ) : (
            <Title>
              <FormOutlined style={{ marginRight: 16 }} />
              Public Form
            </Title>
          )
        }
        backActions={[
          <Link key="back" to="/public-forms">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Public Forms
            </Button>
          </Link>,
        ]}
        style={{ marginBottom: 16 }}
      />
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      {loading && (
        <Space direction="vertical">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Space>
      )}
      {!loading && data && (
        <div
          style={{
            maxWidth: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
          }}
        >
          <ReportLineItem
            question="Name"
            answer={data.name}
            colors={sharedSettings.colors}
          />
          <ReportLineItem
            question="Email"
            answer={data.email}
            colors={sharedSettings.colors}
          />
          {Object.entries(data.values).map(([key, value]) => (
            <ReportLineItem
              key={key}
              question={titleCase(key)}
              answer={
                typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value
              }
              colors={sharedSettings.colors}
            />
          ))}
        </div>
      )}
    </CSPage>
  );
}

export default ReviewPublicForm;
