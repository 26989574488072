import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Space, Switch, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import AimsStatusFilter from './AimsStatusFilter';
import SelectAffinityBloc from './SelectAffinityBloc';
import SelectBibleStatus from './SelectBibleStatus';
import SelectContinent from './SelectContinent';
import SelectCountry from './SelectCountry';
import SelectJPScale from './SelectJPScale';
import SelectLanguage from './SelectLanguage';
import SelectpeopleCluster from './SelectPeopleCluster';
import SelectRegionForAdmin from './SelectRegionForAdmin';
import SelectReligion from './SelectReligion';
import SelectReligionSubdivision from './SelectReligionSubdivision';
import { AimsStatuses } from '@aims/shared/people-groups/constants';

const { Text } = Typography;

function PeopleGroupFilters({ peopleGroupSearch, filters, refetch, loading }) {
  const [searchForm] = Form.useForm();
  const [regionForm] = Form.useForm();
  const [peopleForm] = Form.useForm();
  const [religionForm] = Form.useForm();
  const [jpForm] = Form.useForm();

  const onSearchChange = useCallback(
    (changed) => {
      if (changed && changed.search !== undefined) {
        peopleGroupSearch(changed.search);
      }
      if (changed && changed.continentCode !== undefined) {
        filters.current = {
          ...filters.current,
          continentCode: changed.continentCode,
          regionCode: undefined,
          countryCode: undefined,
        };
        setTimeout(() => {
          regionForm.setFieldsValue({
            regionCode: undefined,
            countryCode: undefined,
          });
          refetch();
        }, 250);
      }
      if (changed && changed.regionCode !== undefined) {
        filters.current = {
          ...filters.current,
          regionCode: changed.regionCode,
          countryCode: undefined,
        };
        setTimeout(() => {
          regionForm.setFieldsValue({
            countryCode: undefined,
          });
          refetch();
        }, 250);
      }
      if (changed && changed.countryCode !== undefined) {
        filters.current = {
          ...filters.current,
          countryCode: changed.countryCode,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      if (changed && changed.affinityBlocId !== undefined) {
        filters.current = {
          ...filters.current,
          affinityBlocId: changed.affinityBlocId,
          peopleClusterId: undefined,
        };
        setTimeout(() => {
          peopleForm.setFieldsValue({
            peopleClusterId: undefined,
          });
          refetch();
        }, 250);
      }
      if (changed && changed.peopleClusterId !== undefined) {
        filters.current = {
          ...filters.current,
          peopleClusterId: changed.peopleClusterId,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      if (changed && changed.primaryReligionId !== undefined) {
        filters.current = {
          ...filters.current,
          primaryReligionId: changed.primaryReligionId,
          religionSubdivisionId: undefined,
        };
        setTimeout(() => {
          religionForm.setFieldsValue({
            religionSubdivisionId: undefined,
          });
          refetch();
        }, 250);
      }
      if (changed && changed.religionSubdivisionId !== undefined) {
        filters.current = {
          ...filters.current,
          religionSubdivisionId: changed.religionSubdivisionId,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      if (changed && changed.primaryLanguageId !== undefined) {
        filters.current = {
          ...filters.current,
          primaryLanguageId: changed.primaryLanguageId,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      if (changed && changed.jpScaleId !== undefined) {
        filters.current = {
          ...filters.current,
          jpScaleId: changed.jpScaleId,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      if (changed && changed.bibleStatus !== undefined) {
        filters.current = {
          ...filters.current,
          bibleStatus: changed.bibleStatus,
        };
        setTimeout(() => {
          refetch();
        }, 250);
      }
      [
        'aimsStatusAdopted',
        'aimsStatusCoordinator',
        'aimsStatusFieldWorker',
        'aimsStatusFunding',
        'aimsStatusHolisticProject',
        'aimsStatusSalvations',
        'aimsStatusChurches',
        'aimsStatusMovements',
        'aimsStatusPercent',
      ].forEach((status) => {
        if (changed && changed[status] !== undefined) {
          filters.current = {
            ...filters.current,
            [status]: changed[status],
          };
          setTimeout(() => {
            refetch();
          }, 250);
        }
      });
    },
    [refetch, regionForm, peopleForm, religionForm, filters, peopleGroupSearch],
  );

  const handleClearFilters = useCallback(() => {
    filters.current = {};
    regionForm.resetFields();
    peopleForm.resetFields();
    religionForm.resetFields();
    jpForm.resetFields();
    searchForm.resetFields();
    setTimeout(() => {
      message.info('Filters cleared');
      refetch();
      peopleGroupSearch('');
    }, 0);
  }, [
    refetch,
    regionForm,
    peopleForm,
    religionForm,
    jpForm,
    searchForm,
    filters,
    peopleGroupSearch,
  ]);

  const [byRegion, setByRegion] = useState(false);
  const handleByRegionChange = useCallback(() => {
    if (byRegion) {
      setByRegion(false);
      filters.current = {
        ...filters.current,
        continentCode: undefined,
        regionCode: undefined,
        countryCode: undefined,
      };
      regionForm.resetFields();
      refetch();
    } else {
      setByRegion(true);
    }
  }, [byRegion, regionForm, refetch, filters]);
  const [byPeople, setByPeople] = useState(false);
  const handleByPeopleChange = useCallback(() => {
    if (byPeople) {
      setByPeople(false);
      filters.current = {
        ...filters.current,
        affinityBlocId: undefined,
        peopleClusterId: undefined,
        primaryLanguageId: undefined,
      };
      peopleForm.resetFields();
      refetch();
    } else {
      setByPeople(true);
    }
  }, [byPeople, peopleForm, refetch, filters]);
  const [byReligion, setByReligion] = useState(false);
  const handleByReligionChange = useCallback(() => {
    if (byReligion) {
      setByReligion(false);
      filters.current = {
        ...filters.current,
        primaryReligionId: undefined,
        religionSubdivisionId: undefined,
      };
      religionForm.resetFields();
      refetch();
    } else {
      setByReligion(true);
    }
  }, [byReligion, religionForm, refetch, filters]);
  const [byStats, setByStats] = useState(false);
  const handleByStatsChange = useCallback(() => {
    if (byStats) {
      setByStats(false);
      filters.current = {
        ...filters.current,
        bibleStatus: undefined,
        jpScaleId: undefined,
      };
      jpForm.resetFields();
      refetch();
    } else {
      setByStats(true);
    }
  }, [byStats, jpForm, refetch, filters]);
  const [byAimsStatus, setByAimsStatus] = useState(false);
  const handleByAimsStatusChange = useCallback(() => {
    if (byAimsStatus) {
      setByAimsStatus(false);
      filters.current = {
        ...filters.current,
        aimsStatusAdopted: undefined,
        aimsStatusCoordinator: undefined,
        aimsStatusFieldWorker: undefined,
        aimsStatusFunding: undefined,
        aimsStatusHolisticProject: undefined,
        aimsStatusSalvations: undefined,
        aimsStatusChurches: undefined,
        aimsStatusMovements: undefined,
        aimsStatusPercent: undefined,
      };
      jpForm.resetFields();
      refetch();
    } else {
      setByAimsStatus(true);
    }
  }, [byAimsStatus, jpForm, refetch, filters]);

  return (
    <>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={searchForm}
      >
        <Form.Item name="search">
          <Input placeholder="Search" suffix={<SearchOutlined />} />
        </Form.Item>
      </Form>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={regionForm}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Switch checked={byRegion} onChange={handleByRegionChange} />
            <Text>Filter by Region</Text>
          </Space>
        </Form.Item>
        {byRegion && (
          <>
            <Form.Item name="continentCode">
              <SelectContinent />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.continentCode !== curValues.continentCode
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item name="regionCode">
                  <SelectRegionForAdmin
                    filters={{
                      continentCode: getFieldValue('continentCode'),
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.continentCode !== curValues.continentCode ||
                prevValues.regionCode !== curValues.regionCode
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item name="countryCode">
                  <SelectCountry
                    filters={{
                      continentCode: getFieldValue('continentCode'),
                      regionCode: getFieldValue('regionCode'),
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
      </Form>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={peopleForm}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Switch checked={byPeople} onChange={handleByPeopleChange} />
            <Text>Filter by People Group</Text>
          </Space>
        </Form.Item>
        {byPeople && (
          <>
            <Form.Item name="affinityBlocId">
              <SelectAffinityBloc />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.affinityBlocId !== curValues.affinityBlocId
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item name="peopleClusterId">
                  <SelectpeopleCluster
                    filters={{
                      affinityBlocId: getFieldValue('affinityBlocId'),
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item name="primaryLanguageId">
              <SelectLanguage />
            </Form.Item>
          </>
        )}
      </Form>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={religionForm}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Switch checked={byReligion} onChange={handleByReligionChange} />
            <Text>Filter by Religion</Text>
          </Space>
        </Form.Item>
        {byReligion && (
          <>
            <Form.Item name="primaryReligionId">
              <SelectReligion />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.primaryReligionId !== curValues.primaryReligionId
              }
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item name="religionSubdivisionId">
                  <SelectReligionSubdivision
                    filters={{
                      primaryReligionId: getFieldValue('primaryReligionId'),
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
      </Form>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={jpForm}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Space>
            <Switch checked={byStats} onChange={handleByStatsChange} />
            <Text>Filter by Joshua Project Statistics</Text>
          </Space>
        </Form.Item>
        {byStats && (
          <>
            <Form.Item name="jpScaleId">
              <SelectJPScale />
            </Form.Item>
            <Form.Item name="bibleStatus">
              <SelectBibleStatus />
            </Form.Item>
          </>
        )}
      </Form>
      <Form
        layout="vertical"
        style={{ maxWidth: 500, minWidth: 300 }}
        onValuesChange={onSearchChange}
        form={jpForm}
      >
        <Form.Item>
          <Space>
            <Switch
              checked={byAimsStatus}
              onChange={handleByAimsStatusChange}
            />
            <Text>Filter by AIMS Status</Text>
          </Space>
        </Form.Item>
        {byAimsStatus && (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.entries(AimsStatuses).map(([key, stat]) => (
              <Form.Item
                key={key}
                name={key}
                label={stat.label}
                initialValue={false}
                style={{ marginRight: 16 }}
              >
                <AimsStatusFilter />
              </Form.Item>
            ))}
          </div>
        )}
      </Form>
      <Form.Item>
        <Space>
          <Button loading={loading} onClick={handleClearFilters}>
            Clear Filters
          </Button>
          {loading && (
            <div>
              <LoadingOutlined /> Loading ...
            </div>
          )}
        </Space>
      </Form.Item>
    </>
  );
}

export default PeopleGroupFilters;
