import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Button, Progress, Space, Tooltip, Upload } from 'antd';
import sharedSettings from '@aims/shared/sharedSettings';
import ImgCrop from 'antd-img-crop';

const size = 140;

function ProfilePhotoDisplayJ24({
  loading,
  progress,
  thumbnailUrl,
  doUpload,
  saving,
  doDelete,
}) {
  return (
    <>
      <div
        style={{
          height: size,
          width: size,
          backgroundColor: sharedSettings.colors.gray,
          borderRadius: size / 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
          overflow: 'hidden',
        }}
      >
        {progress !== undefined && (
          <Progress type="circle" percent={progress.toFixed(0)} size={160} />
        )}
        {progress === undefined && (
          <>
            {thumbnailUrl ? (
              <img style={{ height: size }} src={thumbnailUrl} />
            ) : (
              <div className="placeholder">
                <PictureOutlined style={{ fontSize: 24 }} />
              </div>
            )}
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ImgCrop>
          <Upload showUploadList={false} customRequest={doUpload}>
            <Tooltip title="Change Photo">
              <Button
                icon={<EditOutlined />}
                loading={loading}
                style={{ marginRight: 8 }}
                disabled={saving}
              />
            </Tooltip>
          </Upload>
        </ImgCrop>
        <Tooltip title="Delete Photo">
          <Button
            icon={<DeleteOutlined />}
            loading={loading}
            disabled={saving}
            onClick={doDelete}
          />
        </Tooltip>
      </div>
    </>
  );
}

export default ProfilePhotoDisplayJ24;
