import { useMutation } from '@apollo/client';
import { Form, Input, notification, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import YesNoModal from '../../../../components/YesNoModal';
import { sendRegionalCoordPayoutEmailV2Mutation } from '../constants';

const { Text, Paragraph } = Typography;

function ConfirmSendPayoutEmailModalV2({ confirming, onCancel, onFinish }) {
  const { regionalCoordName, request } = confirming || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();

  const [sendRegionalCoordPayoutEmail] = useMutation(
    sendRegionalCoordPayoutEmailV2Mutation,
  );
  const dispatch = useDispatch();
  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await sendRegionalCoordPayoutEmail({
        variables: {
          request: {
            ...request,
            note: form.getFieldValue('note'),
          },
        },
      });
      notification.success({
        message: 'Scheduled',
        description: 'Email scheduled to send shortly',
      });
      if (onFinish) onFinish();
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [sendRegionalCoordPayoutEmail, request, onFinish, form]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  const { text, title, yesText } = useMemo(() => {
    return {
      title: 'Send Regional Coordinator UPG Sponsorship Payout Email',
      text: (
        <>
          <Paragraph>
            {`This action will send ${regionalCoordName} an email including the second two tables
            below and inform him that funds are on the way.`}
          </Paragraph>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="note"
              label={`Extra notes for ${regionalCoordName} (optional)`}
            >
              <Input.TextArea disabled={loading} row={4} />
            </Form.Item>
          </Form>
        </>
      ),
      yesText: 'Send Email',
    };
  }, [regionalCoordName, loading, form]);

  return (
    <YesNoModal
      title={title}
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>{text}</div>
        </div>
      }
      yesText={yesText}
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!confirming}
      loading={loading}
      error={error}
    />
  );
}

export default ConfirmSendPayoutEmailModalV2;
