import { gql } from '@apollo/client';
import { ViewContactAttributes } from '../../contacts/ViewContact/constants';

export const associateContactWithPpPayerMutation = gql`
  mutation AssociateContactWithPpPayer($payerId: ID!, $contactId: ID!) {
    associateContactWithPpPayer(payerId: $payerId, contactId: $contactId) {
      contact ${ViewContactAttributes}
    }
  }
`;

export const createContactFromPpPayerMutation = gql`
  mutation CreateContactFromPpPayer($payerId: ID!) {
    createContactFromPpPayer(payerId: $payerId) {
      contact ${ViewContactAttributes}
    }
  }
`;

export const updateContactFromPpPayerMutation = gql`
  mutation UpdateContactFromPpPayer($contactId: ID!, $payerId: ID!) {
    updateContactFromPpPayer(contactId: $contactId, payerId: $payerId) {
      contact ${ViewContactAttributes}
    }
  }
`;

export const associateDonationWithAdoptionMutation = gql`
  mutation AssociateDonationWithAdoption($params: AssociateDonationInput!) {
    associateDonationWithAdoption(params: $params)
  }
`;

export const donationXHolisticSponsorshipMutation = gql`
  mutation DonationXHolisticSponsorship($params: DonationXHolisticInput!) {
    donationXHolisticSponsorship(params: $params)
  }
`;

export const UpgSponsorshipPoolAccountId = '975FE47746EF4F4DA7002B2B8976BB03';
export const HolisticPoolAccount = '73A83BD1B56B492EBCA27073C64DA751';

export const TableTitles = {
  holistic: 'Holistic Projects',
  upg: 'UPG Sponsorships',
};

export const ColumnNames = {
  holistic: 'Holistic Project',
  upg: 'UPG Sponsorship',
};

export const Titles = {
  holistic: 'Associate Donation with Holistic Project(s)',
  upg: 'Associate Donation with UPG Sponsorship(s)',
};
