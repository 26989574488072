import { MoreOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useState } from 'react';
import ChangeProjectStageModal from '../AccountingTab/ChangeProjectStageModal';

function ChangeStageDropdown({ project }) {
  const items = [
    {
      key: 'stage',
      label: 'Change Project Stage',
      icon: <ToolOutlined />,
    },
  ];

  const [stage, setStage] = useState();
  const onStage = () => setStage(true);
  const onCancelStage = () => setStage(undefined);
  const onFinishStage = () => setStage(undefined);

  function onClick({ key }) {
    switch (key) {
      case 'stage':
        onStage();
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Dropdown menu={{ items, onClick }} trigger="click">
        <Button icon={<MoreOutlined />} type="dashed" />
      </Dropdown>
      <ChangeProjectStageModal
        project={project}
        onCancel={onCancelStage}
        onFinish={onFinishStage}
        visible={stage}
      />
    </>
  );
}

export default ChangeStageDropdown;
