import React, { useCallback, useEffect } from 'react';
import { HolisticQuarterlyReportQuestions } from './questions';

function HolisticQuarterlyReportItems({
  _id,
  report,
  loading,
  form,
  finishSubForm,
  holisticProject,
  showTitle = true,
  fieldsToSet,
}) {
  useEffect(() => {
    if (report) {
      form.setFieldsValue({
        ...report,
        ...HolisticQuarterlyReportQuestions.notes.before(report),
        ...HolisticQuarterlyReportQuestions.projectAnalysis.before(
          report,
          holisticProject,
        ),
      });
    }
  }, [report, holisticProject, form]);

  finishSubForm.current = useCallback(
    (values) => {
      const modified = { ...values };
      Object.keys(HolisticQuarterlyReportQuestions).forEach((k) => {
        if (HolisticQuarterlyReportQuestions[k].finish) {
          const result = HolisticQuarterlyReportQuestions[k].finish(values);
          Object.entries(result).map(([key, value]) => {
            modified[key] = value;
          });
        }
      });
      return {
        ...modified,
        ...fieldsToSet,
      };
    },
    [fieldsToSet],
  );

  return (
    <>
      {showTitle && (
        <HolisticQuarterlyReportQuestions.title.Display
          loading={loading}
          report={report}
          holisticProject={holisticProject}
        />
      )}
      {report && report.isSubmitted && (
        <>
          <HolisticQuarterlyReportQuestions.submittedBy.Display
            report={report}
          />
          <div style={{ marginBottom: 8 }} />
        </>
      )}
      <HolisticQuarterlyReportQuestions.holisticProject.FormItem
        report={report}
        loading={loading}
        holisticProject={holisticProject}
        fieldsToSet={fieldsToSet}
      />
      <HolisticQuarterlyReportQuestions.quarter.FormItem
        report={report}
        loading={loading}
        holisticProject={holisticProject}
      />
      <HolisticQuarterlyReportQuestions.projectState.FormItem
        loading={loading}
        holisticProject={holisticProject}
      />
      <HolisticQuarterlyReportQuestions.projectAnalysis.FormItem
        loading={loading}
        holisticProject={holisticProject}
      />
      <HolisticQuarterlyReportQuestions.projectAnalysisSummary.FormItem
        loading={loading}
        holisticProject={holisticProject}
      />
      <HolisticQuarterlyReportQuestions.progressDesc.FormItem
        loading={loading}
        holisticProject={holisticProject}
      />
      <HolisticQuarterlyReportQuestions.miracles.FormItem loading={loading} />
      <HolisticQuarterlyReportQuestions.prayerRequests.FormItem
        loading={loading}
      />
      <HolisticQuarterlyReportQuestions.publicFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        holisticProject={holisticProject}
        form={form}
      />
      <HolisticQuarterlyReportQuestions.privateFiles.FormItem
        report={report}
        reportId={_id}
        loading={loading}
        holisticProject={holisticProject}
        form={form}
      />
      <HolisticQuarterlyReportQuestions.notes.FormItem
        loading={loading}
        holisticProject={holisticProject}
      />
      <style jsx>{``}</style>
    </>
  );
}

export default HolisticQuarterlyReportItems;
