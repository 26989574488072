import { deleteReportAction } from '@aims/shared/redux-store/reports-store';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import YesNoModal from '../../../components/YesNoModal';
import { deleteTrainingQuarterlyReportForAdminMutation } from './constants';

function DeleteQuarterlyTrainingReportModal({ report, setReport }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteReport] = useMutation(
    deleteTrainingQuarterlyReportForAdminMutation,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteReport({
        variables: {
          _id: report._id,
        },
      });
      dispatch(deleteReportAction(report._id));
      notification.success({
        message: 'Deleted',
        description: 'Report deleted successfully',
      });
      setTimeout(() => {
        setReport(null);
        history.goBack();
      }, 1000);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error has occurred.',
      });
    }
    setLoading(false);
  }, [report, deleteReport, setReport, dispatch, history]);

  const onNo = useCallback(() => {
    if (!loading) {
      setReport(null);
    }
  }, [setReport, loading]);

  return (
    <YesNoModal
      title="Delete Report"
      question={`Are you sure you want to delete this quarterly training report?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!report}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteQuarterlyTrainingReportModal;
