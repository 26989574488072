import {
  ArrowLeftOutlined,
  DeleteOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useIsCreating from '../../../shared/use-is-creating';
import useQueryParams from '../../../shared/use-query-params';
import ChangePasswordModal from '../ChangePasswordModal';
import DeleteUserModal from '../DeleteUserModal';
import EditUserForm from './EditUserForm';
import { editUserForAdminQuery, extractUserForAdmin } from '../constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import { updateUserAction } from '../../../redux-store/users-store';
import { shallowEqual, useSelector } from 'react-redux';

const queryId = 'edit-user-page';

function EditUser() {
  const history = useHistory();
  const location = useLocation();
  const [saving, setSaving] = useState(false);
  const [changingPassword, setChangingPassword] = useState(null);
  const [deleting, setDeleting] = useState(null);

  const { _id } = useParams();

  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: editUserForAdminQuery,
    extract: extractUserForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateUserAction,
    queryId,
    skip: !_id,
    showError: false,
  });
  const user = useSelector(
    (store) => store.users[queryId]?.[_id],
    shallowEqual,
  );

  const query = useQueryParams();

  const handleBack = useCallback(() => {
    if (query && query.from) {
      history.push(query.from);
    } else {
      history.goBack();
    }
  }, [query, history]);

  const { title, showDelete } = useIsCreating(
    location,
    user,
    loading,
    'Create a User',
    'Edit User',
  );

  return (
    <>
      <CSPage title="Edit User">
        <CSPageHeader
          title={title}
          backActions={[
            <Button
              key="back"
              type="text"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
            >
              Back to Users
            </Button>,
          ]}
        />
        <EditUserForm
          _id={_id}
          handleCancel={handleBack}
          handleSuccess={() => history.push(`/user/view/${_id}/info`)}
          user={user}
          saving={saving}
          setSaving={setSaving}
          style={{ maxWidth: 500 }}
          queryId={queryId}
        />
        {showDelete && (
          <div style={{ maxWidth: 500 }}>
            <Divider />
            <div className="delete-box">
              <Space align="center">
                <Button
                  onClick={() => setDeleting(user)}
                  icon={<DeleteOutlined />}
                  danger
                  ghost
                >
                  Delete User
                </Button>
                <Button
                  onClick={() => setChangingPassword(user)}
                  icon={<LockOutlined />}
                >
                  Change Password
                </Button>
              </Space>
            </div>
          </div>
        )}
        <div style={{ minHeight: 300 }} />
        <DeleteUserModal setUser={setDeleting} user={deleting} />
        <ChangePasswordModal
          user={changingPassword}
          setUser={setChangingPassword}
        />
      </CSPage>
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </>
  );
}

export default EditUser;
