import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { BankOutlined } from '@ant-design/icons';
import { Button, Progress, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useProfile from '../../../../../shared/use-profile';

const { Text, Title } = Typography;

function ProjectPaybackStatus({ project, showIt }) {
  const profile = useProfile();
  const permissions = profile?.permissions;
  const hasPermission = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'get-admin-account');
  }, [permissions]);
  return (
    <>
      {((project.paybackTotalX4 &&
        project.paybackBalance !== undefined &&
        [
          HolisticProjectStages.ACTIVE.key,
          HolisticProjectStages.COMPLETE.key,
          HolisticProjectStages.FAILED.key,
        ].includes(project.stage)) ||
        showIt) && (
        <>
          <Title level={3}>Loan Repayment</Title>
          <Progress percent={Math.round(project.paybackPercent / 100)} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            <div>
              <Text style={{ fontSize: 14, fontWeight: 600 }}>
                {displayMoney(getNumberValue(project.paybackBalance) / 10000)}
              </Text>
            </div>
            <div>
              <Text style={{ fontSize: 14, fontWeight: 600 }}>
                {displayMoney(getNumberValue(project.paybackTotalX4) / 10000)}
              </Text>
            </div>
          </div>
          {project.paybackAccountId && hasPermission && (
            <div style={{ fontSize: 18 }}>
              <Link
                to={`/accounting/accounts/view/${project.paybackAccountId}`}
              >
                <Button icon={<BankOutlined />}>
                  View Project Payback Account
                </Button>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ProjectPaybackStatus;
