import { Card } from 'antd';
import React from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import ConstantContactSettings from './ConstantContactSettings';

function SettingsPage() {
  return (
    <CSPage title="Settings">
      <CSPageHeader title="Settings" />
      <ConstantContactSettings />
    </CSPage>
  );
}

export default SettingsPage;
