import { Space } from 'antd';
import React from 'react';
import { AgreementStatuses } from '../../loan-agreements/constants';
import DeleteMouBtn from './DeleteMouBtn';
import MarkMouCancelledBtn from './MarkMouCancelledBtn';
import MarkMouPrepareBtn from './MarkMouPrepareBtn';
import MarkMouPreviewBtn from './MarkMouPreviewBtn';
import SendMouForSigningBtn from './SendMouForSigningBtn';
import PrintMouBtn from './PrintMouBtn';
import SendMouReminderBtn from './SendMouReminderBtn';
import MarkMouSignBtn from './SignMouBtn';
import MarkMouSignedBtn from './MarkMouSignedBtn';

const ReviewButtons = ({
  mou,
  setError,
  error,
  queryId,
  onSuccess,
  onDelete,
}) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {mou.status === AgreementStatuses.PREPARING.key && (
            <>
              <MarkMouPreviewBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <DeleteMouBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onDelete}
              />
            </>
          )}
          {mou.status === AgreementStatuses.PREVIEW.key && (
            <>
              <SendMouForSigningBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <MarkMouPrepareBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <DeleteMouBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onDelete}
              />
              <MarkMouSignedBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onDelete}
              />
            </>
          )}
          {mou.status === AgreementStatuses.SIGNING.key && (
            <>
              <MarkMouSignBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />

              <SendMouReminderBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
              <MarkMouCancelledBtn
                mou={mou}
                setError={setError}
                error={error}
                queryId={queryId}
                onSuccess={onSuccess}
              />
            </>
          )}
          <PrintMouBtn />
        </Space>
      </div>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ReviewButtons;
