import { Form, InputNumber } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function ChangeMontlyAmountInnerModalA10({
  adoption,
  saving,
  handleFinish,
  onCancel,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (adoption) {
      form.setFieldsValue({
        amount: adoption.amount ? adoption.amount / 1e4 : 0,
      });
    }
  }, [adoption, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { amount, ...values } = _values;
      handleFinish({
        amount: amount * 1e4,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title="Change Monthly Amount"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item label="Monthly Amount (USD)" name="amount">
        <InputNumber
          min={0}
          step={1}
          disabled={saving}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default ChangeMontlyAmountInnerModalA10;
