/* eslint-disable react/display-name */
import sharedSettings from '@aims/shared/sharedSettings';
import { Button, Card, List, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ellipsis from 'text-ellipsis';

const { Text, Title } = Typography;

const queryId = 'event-card';

function EventCard({ event, setShowEventModal }) {
  const locale = useSelector((store) => store.locale, shallowEqual);

  const timeRange = useMemo(() => {
    const start = new Date(event.startTime);
    const stop = new Date(event.endTime);
    if (
      start.getDate() === stop.getDate() &&
      start.getMonth() === stop.getMonth() &&
      start.getFullYear() === stop.getFullYear()
    ) {
      return `${new Intl.DateTimeFormat(locale, {
        timeStyle: 'short',
      }).format(start)} - ${new Intl.DateTimeFormat(locale, {
        timeStyle: 'short',
      }).format(stop)}`;
    }
    return `${new Intl.DateTimeFormat(locale, {
      timeStyle: 'short',
    }).format(start)} - ${new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(stop)} @ ${new Intl.DateTimeFormat(locale, {
      timeStyle: 'short',
    }).format(stop)}`;
  }, [event, locale]);
  const desc = useMemo(() => {
    return event.descText || '';
  }, [event]);
  return (
    <List.Item style={{ width: '100%' }}>
      <Button
        onClick={() => setShowEventModal(event._id)}
        style={{
          padding: 0,
          height: 'unset',
          cursor: 'default',
          width: '100%',
        }}
      >
        <Card
          bordered={false}
          styles={{
            body: {
              textAlign: 'left',
              position: 'relative',
            },
          }}
        >
          <div className="body">
            <Title level={4} className="month" style={{ margin: 0 }}>
              {new Intl.DateTimeFormat(locale, {
                month: 'long',
              }).format(new Date(event.startTime))}
            </Title>
            <Title level={1} className="day" style={{ margin: 0 }}>
              {new Intl.DateTimeFormat(locale, {
                day: '2-digit',
              }).format(new Date(event.startTime))}
            </Title>
            <div className="when">{timeRange}</div>
            <div className="title">{event.title}</div>
            {event.location && <div className="desc">{event.location}</div>}
            {event.desc && <div className="desc">{ellipsis(desc, 25)}</div>}
            <div style={{ height: 8 }} />
          </div>
        </Card>
      </Button>
      <style jsx>{`
        .body {
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
        }
        .node {
          margin-left: 4px;
          margin-right: 4px;
          margin-bottom: 8px;
        }
        .left {
          font-size: 36px;
          padding-right: 24px;
        }
        .when {
          color: ${sharedSettings.colors.timestampColor};
          margin-bottom: 4px;
          font-style: italic;
          font-size: 14px;
          width: 100%;
          white-space: normal;
        }
        .title {
          font-size: 16px;
          font-weight: 500;
          color: ${sharedSettings.colors.primary};
          margin-bottom: 4px;
          white-space: pre-wrap;
        }
        .edit-button {
          position: absolute;
          top: 16px;
          right: 16px;
        }
        .attachments {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 16px;
        }
      `}</style>
    </List.Item>
  );
}

export default EventCard;
