import { gql } from '@apollo/client';
import { contactFullAttributes } from '../contacts/constants';

export const addContactToOrganizationMutation = gql`
  mutation AddContactToOrganization($member: OrganizationMemberInput!) {
    addContactToOrganization(member: $member) {
      contact ${contactFullAttributes}
      organization ${contactFullAttributes}
    }
  }
`;

export const updateOrganizationMembershipMutation = gql`
  mutation UpdateOrganizationMembership($member: OrganizationMemberInput!) {
    updateOrganizationMembership(member: $member) {
      contact ${contactFullAttributes}
      organization ${contactFullAttributes}
    }
  }
`;

export const removeContactFromOrganizationMutation = gql`
  mutation RemoveContactFromOrganization($contactId: ID!, $orgId: ID!) {
    removeContactFromOrganization(contactId: $contactId, orgId: $orgId) {
      contact ${contactFullAttributes}
      organization ${contactFullAttributes}
    }
  }
`;
