import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

const { Text } = Typography;

export const allJPCountriesQuery = gql`
  query AllJPCountries {
    allJPCountries {
      _id
      name
      continentCode
      regionCode
    }
  }
`;

function SelectCountry({ disabled, value, onChange, filters }) {
  const { loading, error, data } = useQuery(allJPCountriesQuery);
  // const data = null;
  // const loading = false;
  // const error = null;
  const countries = useMemo(() => {
    let _data = [];
    if (data) {
      _data = data.allJPCountries;
      if (filters && filters.continentCode) {
        _data = _data.filter((d) => d.continentCode === filters.continentCode);
      }
      if (filters && filters.regionCode) {
        _data = _data.filter((d) => d.regionCode === filters.regionCode);
      }
    }
    return _data;
  }, [data, filters]);
  return (
    <Select
      showSearch
      // optionFilterProp="children"
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      autoComplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a country"
      value={value}
      allowClear
      loading={loading}
    >
      {countries.map((country) => (
        <Select.Option
          key={country._id}
          value={country._id}
          title={country.name}
        >
          <div className="country-option">
            <div>
              <Text>{country.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectCountry;
