import { gql, useMutation } from '@apollo/client';

export const addContactToGoogleMutation = gql`
  mutation addContactToGoogle($contactId: ID!) {
    addContactToGoogle(contactId: $contactId) {
      contact {
        _id
        firstName
        middleNames
        lastName
        birthday
      }
    }
  }
`;

function useAddContactsToGoogle() {
  const [addContactToGoogle] = useMutation(addContactToGoogleMutation);
  return addContactToGoogle;
}

export default useAddContactsToGoogle;
