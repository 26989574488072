import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../tasks/ViewTaskModal/TaskSomethingForm';
import SelectPrayerRequestResult from './SelectPrayerRequestResult';

export const updatePrayerRequestMutation = gql`
  mutation UpdatePrayerRequestForAdmin(
    $prayerRequest: PrayerRequestUpdateInput!
  ) {
    updatePrayerRequestForAdmin(prayerRequest: $prayerRequest) {
      prayerRequest {
        _id
      }
    }
  }
`;

const UpdatePrayerRequestModalJ15 = ({ visible, onFinish, onCancel }) => {
  const { prayerRequest } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updatePrayerRequest] = useMutation(updatePrayerRequestMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await updatePrayerRequest({
          variables: {
            prayerRequest: {
              _id: prayerRequest._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Prayer Request updated successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your prayer request',
        });
      }
      setSaving(false);
    },
    [prayerRequest, updatePrayerRequest, onFinish],
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (prayerRequest) {
      form.setFieldsValue(prayerRequest);
    }
  }, [form, prayerRequest]);

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={onCancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Prayer Request"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item
          name="desc"
          label="Prayer Request"
          rules={[
            {
              required: true,
              message: 'Please enter the prayer request description',
            },
          ]}
        >
          <Input.TextArea rows={4} disabled={saving} />
        </Form.Item>
        <Form.Item name="result" label="Result">
          <SelectPrayerRequestResult />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default UpdatePrayerRequestModalJ15;
