import {
  BarChartOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography;

function AccountsSelectToolbar({
  selected,
  hideUnselected,
  setSelected,
  setHideUnselected,
}) {
  return (
    <>
      {((selected && selected.length > 0) || hideUnselected) && (
        <div
          style={{
            backgroundColor: '#c6cfd3',
            marginBottom: 16,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 16,
            paddingBottom: 16,
            borderRadius: 8,
          }}
        >
          <Title level={5} style={{ margin: 0, marginBottom: 8 }}>
            {`${selected.length} Accounts Selected`}
          </Title>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              icon={<SelectOutlined />}
              type="dashed"
              onClick={() => {
                setSelected([]);
                setHideUnselected(false);
              }}
              style={{ marginRight: 16 }}
              shape="round"
            >
              Unselected All
            </Button>

            {hideUnselected ? (
              <Button
                icon={<EyeOutlined />}
                type="dashed"
                onClick={() => setHideUnselected(false)}
                style={{ marginRight: 16 }}
                shape="round"
              >
                Show Unselected
              </Button>
            ) : (
              <Button
                icon={<EyeInvisibleOutlined />}
                type="dashed"
                onClick={() => setHideUnselected(true)}
                style={{ marginRight: 16 }}
                shape="round"
              >
                Hide Unselected
              </Button>
            )}
            <div style={{ flex: 1 }} />
            <Link to={`/accounting/transfer-reporting`}>
              <Button
                icon={<BarChartOutlined />}
                type="primary"
                shape="round"
                style={{ marginRight: 16 }}
              >
                Generate Transaction Report
              </Button>
            </Link>
            <Link to={`/accounting/balance-reporting`}>
              <Button icon={<BarChartOutlined />} type="primary" shape="round">
                Generate Balance Report
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default AccountsSelectToolbar;
