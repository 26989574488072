import { v4 as uuidv4 } from 'uuid';
import { ajax } from 'rxjs/ajax';
import {
  tap,
  map,
  catchError,
  mergeAll,
  debounceTime,
  switchMap,
  takeUntil,
  skip,
} from 'rxjs/operators';
import { of, Subject, merge } from 'rxjs';
import { parsePhoneNumber } from 'awesome-phonenumber';

export function generateUuid() {
  return uuidv4().toUpperCase().replace(/-/g, '');
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const titleCase = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .split(/\s/)
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    console.log('file', file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hashCode = (s) =>
  Array.from(s).reduce((prev, curr) => {
    const hash = (prev << 5) - prev + curr.charCodeAt(0);
    return hash & hash;
  }, 0);

export function doPostRequest(
  url,
  body,
  headers = { 'Content-Type': 'application/json' },
  options = {},
) {
  return ajax({
    url,
    method: 'POST',
    headers,
    body,
    ...options,
  })
    .pipe(
      map((response) => response.response),
      catchError((error) => {
        console.error(error);
        return of(error);
      }),
    )
    .toPromise();
}

export function doFileUpload({ url, fields, file, onProgress }) {
  const progressSubscriber = new Subject();
  const body = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    body.append(k, v);
  });
  body.append('Content-Type', file.type);
  body.append('Content-Disposition', `attachment; filename="${file.name}"`);
  body.append('file', file);

  const request$ = ajax({
    url,
    method: 'post',
    body,
    progressSubscriber,
  });
  progressSubscriber.subscribe((event) => onProgress(event));
  return request$
    .pipe(
      tap((response) => console.log(response)),
      map((response) => {
        return {
          ...response,
          text: async () => JSON.stringify(response.response),
        };
      }),
    )
    .toPromise();
}

export function isValidJSON(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (err) {
    return false;
  }
}

export function orderAndFilterFormList(list, compFunc, keyAttr, keepAttrs) {
  let _list = (list || []).map((_p) => {
    if (keepAttrs !== undefined) {
      const obj = {};
      keepAttrs.forEach((a) => {
        obj[a] = _p[a];
      });
      return obj;
    }
    const { __typename, ...p } = _p;
    return p;
  });
  _list = Object.values(
    _list.reduce((prev, curr) => {
      prev[curr[keyAttr]] = curr;
      return prev;
    }, {}),
  );
  return _list.sort(compFunc);
}

export function displayMoney(dollars, decimals = 2) {
  return `${dollars < 0 ? '- ' : ''}$${Math.abs(dollars).toFixed(
    decimals,
  )}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args) =>
      selector(...args).pipe(takeUntil(source$.pipe(skip(1)))),
    ),
  );

export function getNumberValue(value) {
  let v = value || 0;
  v = Number(v);
  if (Number.isNaN(v)) {
    v = 0;
  }
  return v;
}

export function formatPhoneNumberForDisplay(ph) {
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.international;
  }
  return null;
}

export function formatPhoneNumberForStorage(ph) {
  if (ph) {
    let parsed;
    if (ph.startsWith('+')) {
      parsed = parsePhoneNumber(ph);
    } else {
      parsed = parsePhoneNumber(ph, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.e164;
  }
  return null;
}
