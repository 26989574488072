import { gql } from '@apollo/client';
import { AdoptionPeopleGroupAttributes } from '../adoptions/constants';

export const UpgXContactListAttributes = gql`
  {
    _id
    type
    peopleGroupId
    peopleGroup ${AdoptionPeopleGroupAttributes}
    contactId
    contact {
      _id
      contactType
      fullName
      primaryThumbnailUrl
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allUpgXContactsQuery = gql`
  query AllUpgXContacts($first: Int, $after: String, $filters: UpgXContactFiltersForAdmin, $sortBy: [SortBy]) {
    allUpgXContactsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${UpgXContactListAttributes}
      }
    }
  }
`;

export const extractUpgXContacts = (data) => data?.allUpgXContactsForAdmin;
