import { ArrowLeftOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, notification, Space, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  createContactAction,
  updateContactAction,
} from '../../../redux-store/contacts-store';
import {
  ContactTypes,
  createContactMutation,
  updateContactMutation,
} from '../constants';
import ContactFormItems from './form-items';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import ProfilePhotoFormItemJ24 from '../../profile-photo/ProfilePhotoFormItemJ24';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';

const { Text } = Typography;

function EditContactForm({
  _id,
  handleCancel,
  handleSuccess,
  contact,
  loading,
  setLoading,
  style,
  queryId,
  contactType,
  fieldsToSet = {},
}) {
  const [error, setError] = useState(null);
  const [updateContact] = useMutation(updateContactMutation);
  const [createContact] = useMutation(createContactMutation);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const labelInput = useRef(null);

  useLayoutEffect(() => {
    if (labelInput.current) {
      labelInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (contact) {
      const otherFields = Object.entries(contact).reduce((prev, [key, val]) => {
        if (
          ContactFormItems[key] &&
          !ContactFormItems[key].before &&
          !ContactFormItems[key].extraBefore
        ) {
          prev[key] = val;
        } else if (!ContactFormItems[key]) {
          prev[key] = val;
        }
        return prev;
      }, {});
      const values = {
        ...otherFields,
        ...ContactFormItems.tags.before(contact),
        ...ContactFormItems.birthday.before(contact),
      };
      form.setFieldsValue(values);
    }
  }, [contact, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      setError(null);
      const values = Object.entries(_values).reduce((prev, [key, val]) => {
        if (
          ContactFormItems[key] &&
          !ContactFormItems[key].finish &&
          !ContactFormItems[key].extraFinish
        ) {
          prev[key] = val;
        } else if (!ContactFormItems[key]) {
          prev[key] = val;
        }
        return prev;
      }, {});
      const otherDetails = ContactFormItems.otherDetails.finish(_values);
      try {
        if (contact) {
          const result = await updateContact({
            variables: {
              contact: {
                _id,
                ...values,
                ...otherDetails,
                ...fieldsToSet,
              },
            },
          });
          dispatch(
            updateContactAction({
              queryId,
              ...result.data.updateContact.contact,
            }),
          );
          notification.success({
            message: 'Saved',
            description: `${ContactTypes[contactType].label} updated successfully`,
          });
        } else {
          const result = await createContact({
            variables: {
              contact: {
                _id,
                contactType,
                ...values,
                ...otherDetails,
                ...fieldsToSet,
              },
            },
          });
          dispatch(
            createContactAction({
              queryId,
              ...result.data.createContact.contact,
            }),
          );
          notification.success({
            message: 'Saved',
            description: `${ContactTypes[contactType].label} created successfully`,
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [
      contact,
      _id,
      createContact,
      updateContact,
      dispatch,
      queryId,
      setLoading,
      handleSuccess,
      fieldsToSet,
      contactType,
    ],
  );

  let saveLabel;
  switch (contactType) {
    case ContactTypes.CONTACT.key:
      saveLabel = 'Save Contact';
      break;
    case ContactTypes.HOUSEHOLD.key:
      saveLabel = 'Save Household';
      break;
    case ContactTypes.ORG.key:
      saveLabel = 'Save Organization';
      break;
  }

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      id="editContact"
      form={form}
      style={style}
    >
      <ProfilePhotoFormItemJ24
        name="profilePhotoId"
        existing={
          contact && {
            _id: contact.profilePhotoId,
            thumbnailUrl: contact.primaryThumbnailUrl,
          }
        }
        styles={{ formItem: { display: 'flex', justifyContent: 'center' } }}
        parentType="contact"
        parentId={contact?._id}
        tags={['Contact', 'Profile Photo']}
        saving={loading}
        setSaving={setLoading}
      />
      <ContactFormItems.firstName.FormItem
        contactType={contactType}
        loading={loading}
        ref={labelInput}
      />
      <ContactFormItems.middleNames.FormItem
        contactType={contactType}
        loading={loading}
      />
      <ContactFormItems.lastName.FormItem
        contactType={contactType}
        loading={loading}
      />
      <ContactFormItems.birthday.FormItem
        contactType={contactType}
        loading={loading}
      />
      <ContactFormItems.tags.FormItem loading={loading} />
      <Form.Item
        label="Region"
        name="regionCode"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <SelectRegionForAdmin />
      </Form.Item>
      <ContactFormItems.otherDetails.FormItem loading={loading} />
      <ShowErrorsFormItem />
      <div style={{ height: 16 }} />
      <Form.Item>
        <Space
          style={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            htmlType="button"
            type="text"
            size="small"
            disabled={loading}
            icon={<ArrowLeftOutlined />}
            style={{ marginLeft: -7 }}
          >
            Cancel
          </Button>
          <Button key="send" type="primary" loading={loading} htmlType="submit">
            {saveLabel}
          </Button>
        </Space>
      </Form.Item>
      {error && (
        <Text type="danger" style={{ marginTop: 16 }}>
          {error}
        </Text>
      )}
      <style jsx global>{`
        .my-checkbox .ant-form-item-control-input {
          min-height: 24px;
        }
      `}</style>
    </Form>
  );
}

export default EditContactForm;
