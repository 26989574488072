import React from 'react';

export const Fonts = {
  Babylonica: {
    name: 'Babylonica',
    fontFamily: 'Babylonica, cursive',
  },
  'Herr Von Muellerhoff': {
    name: 'Herr Von Muellerhoff',
    fontFamily: 'Herr Von Muellerhoff, cursive',
  },
  'Send Flowers': {
    name: 'Send Flowers',
    fontFamily: 'Send Flowers, cursive',
  },
  'Square Peg': {
    name: 'Square Peg',
    fontFamily: 'Square Peg, cursive',
  },
  Tangerine: {
    name: 'Tangerine',
    fontFamily: 'Tangerine, cursive',
  },
  'Water Brush': {
    name: 'Water Brush',
    fontFamily: 'Water Brush, cursive',
  },
};

export function SignatureFontsStyle() {
  return (
    <style>
      {`@import url('https://fonts.googleapis.com/css2?family=Babylonica&family=Herr+Von+Muellerhoff&family=Send+Flowers&family=Square+Peg&family=Tangerine&family=Water+Brush&display=swap');`}
    </style>
  );
}
