import { Tabs } from 'antd';
import React from 'react';
import TrainingQuarterlyReportsList from './TrainingQuarterlyReportsList';

function TrainingQuarterlyReportTabs() {
  return (
    <Tabs defaultActiveKey="pending">
      <Tabs.TabPane tab="Pending" key="pending">
        <TrainingQuarterlyReportsList reportStatus="PENDING" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Approved" key="approved">
        <TrainingQuarterlyReportsList reportStatus="APPROVED" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="On Hold" key="hold">
        <TrainingQuarterlyReportsList reportStatus="HOLD" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Drafts" key="draft">
        <TrainingQuarterlyReportsList reportStatus="DRAFT" />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rejected" key="rejected">
        <TrainingQuarterlyReportsList reportStatus="REJECTED" />
      </Tabs.TabPane>
    </Tabs>
  );
}

export default TrainingQuarterlyReportTabs;
