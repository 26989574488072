import {
  FilterOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

const keyMap = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  courseName: 'courseName.keyword',
};

function FilterTrainingCourses({
  filters,
  refetch,
  courseSearch,
  loading,
  sortBy,
  parentFilters,
}) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [showFilters, setShowFilters] = useState(false);
  const filterChangeTimeout = useRef();

  const handleSortByChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[value], order: sortOrder },
        { key: '_score', order: 'DESC' },
      ];
      setSortedBy(value);
      refetch();
    },
    [sortOrder, refetch, sortBy],
  );

  const handleSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        { key: keyMap[sortedBy], order: value },
        { key: '_score', order: 'DESC' },
      ];
      setSortOrder(value);
      refetch();
    },
    [sortedBy, refetch, sortBy],
  );

  const onFiltersChanged = useCallback(
    (_changed, values) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: keyMap[sortedBy],
            order: sortOrder,
          },
        ];
        courseSearch(search.toLowerCase());
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v ? v : undefined;
            return prev;
          }, {}),
          ...parentFilters,
        };
        sortBy.current = [
          {
            key: keyMap[sortedBy],
            order: sortOrder,
          },
          { key: '_score', order: 'DESC' },
        ];
        clearTimeout(filterChangeTimeout.current);
        filterChangeTimeout.current = setTimeout(() => {
          refetch();
        }, 500);
      }
    },
    [
      courseSearch,
      refetch,
      sortedBy,
      sortOrder,
      filters,
      sortBy,
      parentFilters,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        style={{ marginBottom: 16 }}
        onValuesChange={onFiltersChanged}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 16,
          }}
        >
          <Form.Item
            name="search"
            style={{ maxWidth: 500, minWidth: 300, marginRight: 16 }}
          >
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
          {showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setShowFilters(false);
              }}
            >
              Hide Filters
            </Button>
          )}
          {!showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(true)}
            >
              Show Filters
            </Button>
          )}
          <div style={{ flex: 1 }} />
          <Button icon={<RedoOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </div>
        {showFilters && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label="Sort By"
                style={{ flex: 2, marginRight: 16, minWidth: 200 }}
              >
                <Select
                  placeholder="Select ..."
                  value={sortedBy}
                  onChange={handleSortByChange}
                  disabled={loading}
                >
                  <Select.Option value="updatedAt">Last Updated</Select.Option>
                  <Select.Option value="createdAt">Created Date</Select.Option>
                  <Select.Option value="courseName">Course Name</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Sort Order" style={{ minWidth: 100 }}>
                <Select
                  placeholder="Select ..."
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                  disabled={loading}
                >
                  <Select.Option value="ASC">Ascending</Select.Option>
                  <Select.Option value="DESC">Descending</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </>
  );
}

export default FilterTrainingCourses;
