import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Typography, message, notification } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { updateLoanAgreementAction } from '../../redux-store/loan-agreements-store';
import useQueryParams from '../../shared/use-query-params';
import AttachmentsElement from './AttachmentsElement';
import DeleteLoanAgreementTemplateModal from './DeleteLoanAgreementTemplateModal';
import EditableTextElement from './EditableTextElement';
import PartiesElement from './PartiesElement';
import SectionsElement from './SectionsElement';
import {
  AgreementStatuses,
  createLoanAgreementTemplateMutation,
  extractLoanAgreement,
  loanAgreementTemplateQuery,
  updateLoanAgreementTemplateMutation,
} from './constants';

const { Title, Paragraph, Text } = Typography;

function ViewLoanAgreementTemplate() {
  const { _id } = useParams();
  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return '/loan-agreement-templates';
    }
  }, [query]);

  const queryId = 'loan-agreement-template';
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: loanAgreementTemplateQuery,
    extract: extractLoanAgreement,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateLoanAgreementAction,
    skip: !_id,
    showError: false,
  });
  const loanAgreement = useSingleSomethingA10('loanAgreements', _id, queryId);

  const [updateLoanAgreement] = useMutation(
    updateLoanAgreementTemplateMutation,
  );
  const [createLoanAgreement] = useMutation(
    createLoanAgreementTemplateMutation,
  );

  const saveQueue$ = useRef();
  const handleSave = useCallback((values) => {
    saveQueue$.current.next(values);
  }, []);

  const running = useRef(true);
  const currentAgreement = useRef();
  useEffect(() => {
    currentAgreement.current = loanAgreement;
  }, [loanAgreement]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function doSave(values) {
      if (values) {
        try {
          if (currentAgreement.current) {
            await updateLoanAgreement({
              variables: {
                loanAgreement: {
                  _id,
                  status: 'PREPARING',
                  ...values,
                },
              },
            });
            refetch();
            notification.success({
              message: 'Success',
              description: 'Loan Agreement Template Updated',
            });
          } else {
            await createLoanAgreement({
              variables: {
                loanAgreement: {
                  _id,
                  agreementType: 'LOAN_AGREEMENT',
                  isTemplate: true,
                  status: AgreementStatuses.PREPARING.key,
                  ...values,
                  name: values.name || 'No Template Name',
                },
              },
            });
            refetch();
            notification.success({
              message: 'Success',
              description: 'Loan Agreement Template Created',
            });
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      }
    }
    saveQueue$.current = new BehaviorSubject();
    saveQueue$.current.pipe(concatMap((values) => doSave(values))).subscribe();
    return () => (running.current = false);
  }, [_id, updateLoanAgreement, createLoanAgreement, refetch]);

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const handleDeleteClick = useCallback(() => {
    setDeleting(loanAgreement);
  }, [loanAgreement]);
  const handleCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const handleDeleteFinish = useCallback(() => {
    setDeleting(undefined);
    history.push(backLink);
  }, [backLink, history]);

  return (
    <>
      <CSPage title="Loan Agreement Template">
        <CSPageHeader
          title=""
          titleComponent={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title level={1}>Loan Agreement Template</Title>
              {!!loanAgreement && (
                <Button
                  onClick={handleDeleteClick}
                  icon={<DeleteOutlined />}
                  type="text"
                >
                  Delete
                </Button>
              )}
            </div>
          }
          backActions={[
            <Link key="back" to={backLink}>
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>,
          ]}
        />
        <EditableTextElement
          name="name"
          label="Template Name"
          noLabel="No Template Name"
          text={loanAgreement && loanAgreement.name}
          handleSave={handleSave}
          parties={loanAgreement && loanAgreement.parties}
          attachments={loanAgreement && loanAgreement.attachments}
        />
        <PartiesElement loanAgreement={loanAgreement} handleSave={handleSave} />
        <SectionsElement
          loanAgreement={loanAgreement}
          handleSave={handleSave}
        />
        <AttachmentsElement
          loanAgreementId={_id}
          loanAgreement={loanAgreement}
          handleSave={handleSave}
        />
      </CSPage>
      {loanAgreement && (
        <DeleteLoanAgreementTemplateModal
          loanAgreementTemplate={deleting}
          onCancel={handleCancelDelete}
          onFinish={handleDeleteFinish}
        />
      )}
    </>
  );
}

export default ViewLoanAgreementTemplate;
