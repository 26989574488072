import React, { useCallback } from 'react';
import AnyTasks from '../../../tasks/AnyTasks';
import ProjectAdminNotes from '../ProjectAdminNotes';
import AnyHistory from '../../../../components/AnyHistory';
import AnyActivity from '../../../../components/AnyActivity/AnyActivity';

function HolisticActivityTab({ project }) {
  const historyFilterFunc = useCallback(
    (a) => {
      let ret = true;
      if (a.holisticProjectId !== project._id) {
        ret = false;
      }
      return ret;
    },
    [project],
  );
  return (
    <>
      <AnyTasks
        hideDone={false}
        title="Holistic Project Tasks"
        subtitle="Internal tasks related to this project"
        filters={{
          holisticProjectId: project._id,
        }}
        fieldsToSet={{
          holisticProjectId: project._id,
        }}
      />
      <AnyActivity
        filters={{
          holisticProjectId: project._id,
        }}
        fieldsToSet={{
          holisticProjectId: project._id,
        }}
      />
      <ProjectAdminNotes project={project} />
      <AnyHistory
        filterFunc={historyFilterFunc}
        filters={{ holisticProjectId: project?._id }}
        title="Change History"
      />
    </>
  );
}

export default HolisticActivityTab;
