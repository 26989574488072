import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../../reports/constants';

const reportFullAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    regionCode
    regionName
    reportType
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    notes
    submittedAt
    createdBy
    createdByUser ${ReportUserShortAttributes}
    isSubmitted
    submittedByUser ${ReportUserShortAttributes}
    isApproved
    status
    updatedBy
    createdAt
    updatedAt
    deletedBy
    deletedAt
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    adminNotes
    eventDate
    hostName
    hostWebsite
    eventAddress {
      label
      address1
      address2
      city
      state
      country
      zipCode
    }
    numPeopleTrained
    itsmModulesTaught
    trainersTrained {
      _id
      name
      email
      phone
      whatsApp
      country
      orgName
    }
    newPartnerships {
      _id
      orgName
      orgDesc
      orgWebsite
      contactInfo {
        _id
        name
        email
        phone
        whatsApp
        country
        orgName
      }
    }
    numUpgAdoptions
    newAdoptions {
      _id
      contactInfo  {
        _id
        name
        email
        phone
        whatsApp
        country
        orgName
      }
      peopleGroupId
      peopleGroupName
    }
    miracles {
      _id
      desc
    }
  }
`;

export const trainingEventReportQuery = gql`
  query TrainingEventReportForAdmin($_id: ID!) {
    trainingEventReportForAdmin(_id: $_id) ${reportFullAttributes}
  }
`;

export const extractTrainingEventReport = (data) =>
  data && data.trainingEventReportForAdmin;

const TrainingEventReportUpdateAttributes = gql`
{
  crud
  id
  new ${reportFullAttributes}
}
`;

export const trainingEventReportUpdatesSubscription = gql`
  subscription TrainingEventReportUpdatesForAdmin($_id: ID!) {
    trainingEventReportUpdatesForAdmin(_id: $_id) ${TrainingEventReportUpdateAttributes}
  }
`;

export const createTrainingEventReportForAdminMutation = gql`
  mutation CreateTrainingEventReportForAdmin(
    $report: TrainingEventReportCreateInputForAdmin!
  ) {
    createTrainingEventReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateTrainingEventReportForAdminMutation = gql`
  mutation UpdateTrainingEventReportForAdmin(
    $report: TrainingEventReportUpdateInputForAdmin!
  ) {
    updateTrainingEventReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitTrainingEventReportForAdminMutation = gql`
  mutation SubmitTrainingEventReportForAdmin($reportId: ID!) {
    submitTrainingEventReportForAdmin(reportId: $reportId)
  }
`;

export const unsubmitTrainingEventReportForAdminMutation = gql`
  mutation unsubmitTrainingEventReportForAdmin($reportId: ID!) {
    unsubmitTrainingEventReportForAdmin(reportId: $reportId)
  }
`;

export const reviewTrainingEventReportMutation = gql`
  mutation reviewTrainingEventReport(
    $reportId: ID!
    $status: ReportApprovalStatus!
  ) {
    reviewTrainingEventReport(reportId: $reportId, status: $status)
  }
`;

export const deleteTrainingEventReportForAdminMutation = gql`
  mutation DeleteTrainingEventReportForAdmin($_id: ID!) {
    deleteTrainingEventReportForAdmin(_id: $_id) {
      deletedId
    }
  }
`;
