import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Button, Form, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { ReportUserShortAttributes } from '../../reports/constants';
import FilterTrainingEventReports from './FilterTrainingEventReports';
import TrainingEventReportsTable from './TrainingEventReportsTable';
import settings from '../../../settings';

const allTrainingEventReportsQuery = gql`
  query AllTrainingEventReportsForAdmin(
    $first: Int
    $after: String
    $filters: TrainingEventReportFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allTrainingEventReportsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          regionCode
          regionName
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser ${ReportUserShortAttributes}
          submittedByUser ${ReportUserShortAttributes}
          updatedBy
          createdAt
          updatedAt
          updatedByUser ${ReportUserShortAttributes}
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          eventDate
          hostName
          hostWebsite
          eventAddress {
            label
            address1
            address2
            city
            state
            country
            zipCode
          }
          numPeopleTrained
          numUpgAdoptions
          newAdoptions {
            _id
            peopleGroupId
          }
          trainersTrained {
            _id
            name
          }
          newPartnerships {
            _id
            orgName
          }
        }
      }
    }
  }
`;

const extractAllTrainingEventReports = (data) =>
  data && data.allTrainingEventReportsForAdmin;

const { Text } = Typography;

const queryId = 'trainingEventReportsList';

function TrainingEventReportsList({ reportStatus, peopleGroupId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({
    status: reportStatus,
  });

  const {
    error,
    loading,
    data: reports,
    search: reportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTrainingEventReportsQuery,
    extract: extractAllTrainingEventReports,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  return (
    <>
      <div>
        <Form layout="vertical">
          <FilterTrainingEventReports
            filters={filters}
            refetch={refetch}
            reportSearch={reportSearch}
            loading={loading}
            sortBy={sortBy}
            type={'holisticProposal'}
          />
        </Form>
      </div>
      <TrainingEventReportsTable reports={reports} loading={loading} />
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && <Button onClick={next}>Load More</Button>}
      </div>
    </>
  );
}

export default TrainingEventReportsList;
