import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { PrayerRequestResults } from '@aims/shared/prayer-requests/constants';
import { ReportTypes } from '@aims/shared/reports/constants';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import usePrayerReportLink from './use-pr-report-link';

const { Text, Title } = Typography;

function DeleteDropdown({ prayerRequest, handleDelete }) {
  const items = [
    {
      key: 'delete',
      label: 'Delete Prayer Request',
      icon: <DeleteOutlined />,
    },
  ];
  function onClick({ key }) {
    switch (key) {
      case 'delete':
        handleDelete(prayerRequest);
        break;
      default:
        break;
    }
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger="click">
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
}

function PrayerRequestCardJ15({ prayerRequest, onEditClick, onDeleteClick }) {
  const [toggleDesc, setToggle] = useState(false);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const resultObj = PrayerRequestResults[prayerRequest.result];

  const link = usePrayerReportLink(prayerRequest);

  const displayPeopleGroup = useMemo(
    () =>
      prayerRequest.peopleGroup && (
        <div>
          <Link to={`people-group/view/${prayerRequest.peopleGroupId}/info`}>
            <div>
              <Text style={{ fontWeight: 600 }}>People Group: </Text>
              <Text>
                {prayerRequest.peopleGroup.nameAcrossCountries} -{' '}
                {prayerRequest.peopleGroup.country}
              </Text>
            </div>
          </Link>
        </div>
      ),
    [prayerRequest],
  );

  const displayHolisticProject = useMemo(
    () =>
      prayerRequest.holisticProject && (
        <div>
          <Link
            to={`holistic-project/view/${prayerRequest.holisticProjectId}/details`}
          >
            <div>
              <Text style={{ fontWeight: 600 }}>Holistic Project: </Text>
              <Text>
                {`${prayerRequest.holisticProject.projectName} - ${
                  HolisticProjectTypes[
                    prayerRequest.holisticProject.typeOfProject
                  ]?.label
                }${
                  prayerRequest.holisticProject.peopleGroupIds?.length > 0
                    ? ` (${prayerRequest.holisticProject.peopleGroupIds?.join(
                        ', ',
                      )})`
                    : ''
                }`}
              </Text>
            </div>
          </Link>
        </div>
      ),
    [prayerRequest],
  );

  return (
    <Card bordered={false}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: 16,
        }}
      >
        <div>
          <div style={{ marginBottom: 4 }}>
            <Text
              style={{ color: sharedSettings.colors.textGray }}
            >{`Created ${new Intl.DateTimeFormat(locale, {
              dateStyle: 'medium',
            }).format(new Date(prayerRequest.createdAt))}`}</Text>
          </div>
          <div style={{ marginBottom: 4 }}>
            {displayPeopleGroup}
            {displayHolisticProject}
          </div>
          {resultObj && (
            <div>
              <Text style={{ marginRight: 12 }}>Result:</Text>
              <Tag color={resultObj.color}>{resultObj.label}</Tag>
            </div>
          )}
        </div>
        <div className="actions">
          <Space>
            <Tooltip title="Edit Request">
              <Button
                onClick={() => onEditClick(prayerRequest)}
                icon={<EditOutlined />}
              />
            </Tooltip>
            {link && (
              <Tooltip title="View Report">
                <Link to={link}>
                  <Button icon={<BarChartOutlined />} />
                </Link>
              </Tooltip>
            )}
            <DeleteDropdown
              prayerRequest={prayerRequest}
              handleDelete={onDeleteClick}
            />
          </Space>
        </div>
      </div>
      {!toggleDesc && prayerRequest.desc.length > 200 && (
        <Text>
          {ellipsis(prayerRequest.desc, 200)}{' '}
          <Text
            style={{ cursor: 'pointer', fontWeight: 600 }}
            onClick={() => {
              setToggle(!toggleDesc);
            }}
          >
            Read more
          </Text>
        </Text>
      )}
      {toggleDesc && prayerRequest.desc.length > 200 && (
        <Text>
          {prayerRequest.desc}{' '}
          <Text
            className="toggle"
            onClick={() => {
              setToggle(!toggleDesc);
            }}
          >
            Read Less
          </Text>
        </Text>
      )}
      {prayerRequest.desc.length < 200 && <Text>{prayerRequest.desc}</Text>}
    </Card>
  );
}

export default PrayerRequestCardJ15;
