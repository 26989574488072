import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import SelectHolisticProject from '../../pages/holistic-projects/SelectHolisticProject';
import TagsFormItem from '../../pages/tags/TagsFormItem';
import { updateProjectSponsorshipMutation } from '../constants';
import TaskSomethingForm from '../../pages/tasks/ViewTaskModal/TaskSomethingForm';

const { Title, Text } = Typography;

const EditSponsorshipModal = ({ visible, onSuccess, onCancel }) => {
  const [saving, setSaving] = useState(false);
  const { sponsorship } = visible || {};
  const [updateProjectSponsorship] = useMutation(
    updateProjectSponsorshipMutation,
  );
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { projectId, ...values } = _values;
        await updateProjectSponsorship({
          variables: {
            projectSponsorship: {
              _id: sponsorship._id,
              projectId: projectId || null,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Project Sponsorship updated successfully',
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [updateProjectSponsorship, sponsorship, onSuccess],
  );

  useEffect(() => {
    if (sponsorship) {
      form.setFieldsValue({
        ...sponsorship,
        tags: sponsorship.tags?.map((t) => ({ key: t.key, color: t.color })),
      });
    } else {
      form.resetFields();
    }
  }, [form, sponsorship]);

  return (
    <Modal
      header={null}
      footer={null}
      open={visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={!saving}
      onCancel={onCancel}
      styles={{ body: { paddingTop: 48 } }}
    >
      <TaskSomethingForm
        title="Update Project Sponsorship"
        form={form}
        onFinish={handleFinish}
        onCancel={onCancel}
        saving={saving}
      >
        <Form.Item label="Project" name="projectId">
          <SelectHolisticProject disabled={saving} />
        </Form.Item>
        <TagsFormItem name="tags" label="Tags" saving={saving} />
        <Form.Item label="Notes" name="notes">
          <Input.TextArea disabled={saving} rows={4} />
        </Form.Item>
      </TaskSomethingForm>
    </Modal>
  );
};

export default EditSponsorshipModal;
