import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, Typography, notification } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import SelectContact from '../../contacts/SelectContact';
import {
  reviewUpgQuarterlyReportMutation,
  updateUpgQuarterlyReportForAdminMutation,
} from '../constants';

const { Title, Text, Paragraph } = Typography;

const ApproveUpgQuarterlyReportBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewPgQuarterlyReport] = useMutation(
    reviewUpgQuarterlyReportMutation,
  );
  const [updateReport] = useMutation(updateUpgQuarterlyReportForAdminMutation);

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await reviewPgQuarterlyReport({
          variables: {
            reportId: report._id,
            status: 'APPROVED',
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Report Approved',
        });
        const { fieldWorkerId } = values;
        await updateReport({
          variables: {
            report: {
              _id: report._id,
              contactId: fieldWorkerId,
              reportType: report.reportType,
              onBehalfOfId: fieldWorkerId,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Report updated successfully',
        });
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'There was an error saving your report',
        });
      }
      setLoading(false);
    },
    [reviewPgQuarterlyReport, report, onSuccess, updateReport],
  );

  const contactQuestion = useMemo(() => {
    if (report && report.onBehalfOfName) {
      return `Select the field worker associated with ${report.onBehalfOfName}`;
    }
    if (report && report.onBehalfOfName) {
      return `Select the field worker associated with ${report.onBehalfOfName}`;
    }
    if (report && report.submittedByUser) {
      return `Select the field worker associated with ${report.submittedByUser.name}`;
    }
    return 'Please select the field worker that submitted this report';
  }, [report]);

  return (
    <>
      <Button
        loading={loading}
        icon={<CheckOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Approve Report
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Approve UPG Quarterly Report
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              {`You're about to approve the ${getPgDisplayName(
                report.peopleGroup,
              )} quarterly report for${' '}
                ${getQuarterLabel(report.quarter)}${'. '}
                Once you do, this people group's cumulative stats will be
                updated and the submitter will be notified.`}
            </Paragraph>
          </Form.Item>
          <Form.Item
            style={{ marginTop: 32 }}
            label={contactQuestion}
            name="fieldWorkerId"
            rules={[{ required: true, message: 'Please select a contact' }]}
            initialValue={report && report.contactId}
          >
            <SelectContact
              disabled={loading}
              filters={{
                isFieldWorker: true,
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Approve Report
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ApproveUpgQuarterlyReportBtn;
