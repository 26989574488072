import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { Table, Typography } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text, Paragraph } = Typography;

function CumulativeProjectStats({ project }) {
  const loanTotal = getNumberValue(project.loanTotalX4) / 10000;
  const totalPayments = getNumberValue(project.paybackBalance) / 10000;
  const loanBalance = loanTotal - totalPayments;
  const loanTerm = getNumberValue(project.loanTerm);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const loanStartDate =
    project.loanStartDate &&
    new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(new Date(project.loanStartDate));

  let hasReceivedFunds = false;

  let revenueUsd = 0;
  let expensesUsd = 0;
  let toSupportedFieldWorkersUsd = 0;
  let netProfitUsd = 0;
  let numProgressReports = 0;
  const { cumulativeStats } = project;
  if (cumulativeStats) {
    numProgressReports = getNumberValue(cumulativeStats.numProgressReports);
    hasReceivedFunds = cumulativeStats.hasReceivedFunds;
    revenueUsd = getNumberValue(cumulativeStats.cumulativeRevenue);
    expensesUsd = getNumberValue(cumulativeStats.cumulativeExpenses);
    toSupportedFieldWorkersUsd =
      project.supportedFieldWorkers &&
      project.supportedFieldWorkers.reduce((prev, curr) => {
        return prev + getNumberValue(curr.totalReceived);
      }, 0);
    netProfitUsd = revenueUsd - expensesUsd - toSupportedFieldWorkersUsd;
  }

  return (
    <>
      <Title level={3}>Cumulative Project Stats</Title>
      <Paragraph style={{ textAlign: 'center', fontSize: 14 }}>
        {`These stats are based on the ${numProgressReports} quarterly progress reports submitted and
        approved so far.`}
      </Paragraph>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 16,
            marginRight: 32,
          }}
        >
          <div>{`Loan Amount: ${displayMoney(loanTotal)}`}</div>
          <div>{`Loan Term: ${loanTerm} Months`}</div>
          {loanStartDate ? (
            <div>{`Loan Start Date: ${loanStartDate}`}</div>
          ) : (
            <div>{`No Start Date`}</div>
          )}
        </div>
        <div
          style={{
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 16,
          }}
        >
          <div>{`Funds Received: ${hasReceivedFunds ? 'Yes' : 'No'}`}</div>
          <div>{`Total Payments: ${displayMoney(totalPayments)}`}</div>
          <div>{`Loan Balance: ${displayMoney(
            getNumberValue(loanBalance),
          )}`}</div>
        </div>
      </div>
      <div style={{ height: 16 }} />
      <Table
        bordered
        dataSource={[
          {
            row: 'A',
            label: 'Revenue So Far',
            usd: displayMoney(getNumberValue(revenueUsd)),
            color: undefined,
          },
          {
            row: 'B',
            label: 'Expenses So Far',
            usd: displayMoney(getNumberValue(expensesUsd)),
            color: undefined,
          },
          {
            row: 'C',
            label: 'Field Worker Support So Far',
            usd: displayMoney(getNumberValue(toSupportedFieldWorkersUsd)),
            color: undefined,
          },
          {
            row: 'D',
            label: 'Net Profit So Far (A - B - C)',
            usd: displayMoney(getNumberValue(netProfitUsd)),
            color: undefined,
          },
        ]}
        showHeader
        rowKey="row"
        pagination={false}
      >
        <Table.Column
          dataIndex="row"
          key="row"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="label"
          key="label"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="USD"
          dataIndex="usd"
          key="usd"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
      </Table>
      <div style={{ height: 24 }} />
    </>
  );
}

export default CumulativeProjectStats;
