import sharedSettings from '@aims/shared/sharedSettings';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, List, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { generateUuid } from '../../shared/utils';
import SelectContact from '../contacts/SelectContact';
import { ContactTypes } from '../contacts/constants';
import { AgreementStatuses } from './constants';
import { Fonts } from './signature-fonts';

const { Title, Paragraph, Text } = Typography;

function SingleParty({
  editing,
  party,
  doSave,
  loading,
  error,
  doEdit,
  doCancel,
  doDelete,
  showContact,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      _id: party._id,
      ref: party.ref,
      name: party.name,
      contactId: party.contactId,
    });
  }, [party, form]);
  return (
    <>
      {editing ? (
        <Card>
          <Form form={form} layout="vertical" onFinish={doSave}>
            <Form.Item name="_id" initialValue={party._id} noStyle>
              <Input type="hidden" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="ref"
              label="Party Reference"
              initialValue={party.ref}
              rules={[{ required: 'This field is required' }]}
              extra="This is the ID used to reference a parties name in the body of the agreement"
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Party Name"
              name="name"
              rules={[{ required: 'This field is required' }]}
              extra="ex: AIMS Party Responsible for Suppling Loan"
            >
              <Input disabled={loading} />
            </Form.Item>
            {showContact && (
              <Form.Item label="Contact" name="contactId">
                <SelectContact
                  disabled={loading}
                  filters={{ contactType: ContactTypes.CONTACT.key }}
                />
              </Form.Item>
            )}
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  htmlType="button"
                  onClick={() => doCancel(party._id)}
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Form.Item>
            {error && <Paragraph type="danger">{error}</Paragraph>}
          </Form>
        </Card>
      ) : (
        <Card
          styles={{
            body: { display: 'flex', justifyContent: 'space-between' },
          }}
        >
          <div style={{ flex: 1 }}>
            <div>
              <Text style={{ color: sharedSettings.colors.primary }}>Name</Text>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 14 }}>{party.name || 'No Name'}</Text>
            </div>
            <div>
              <Text style={{ color: sharedSettings.colors.primary }}>
                Reference
              </Text>
            </div>
            <div style={{ marginBottom: 8 }}>
              <Text style={{ fontSize: 14 }}>
                {party.ref ? `{{${party.ref}}}` : 'No Reference'}
              </Text>
            </div>
            {showContact && (
              <>
                <div>
                  <Text style={{ color: sharedSettings.colors.primary }}>
                    Contact
                  </Text>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text style={{ fontSize: 14 }}>
                    {party.contactName ? party.contactName : 'Not Specified'}
                  </Text>
                </div>
              </>
            )}
          </div>
          <div>
            <Button
              onClick={() => doEdit(party._id)}
              icon={<EditOutlined />}
              type="text"
            >
              Edit
            </Button>
            <Button
              onClick={() => doDelete(party._id)}
              icon={<DeleteOutlined />}
              type="text"
            >
              Delete
            </Button>
          </div>
        </Card>
      )}
    </>
  );
}

function ReadOnlyParty({ party }) {
  return (
    <Card>
      <div style={{ fontSize: 14, color: sharedSettings.colors.textGray }}>
        {party.name}
      </div>
      <div style={{ fontSize: 18, color: sharedSettings.colors.primary }}>
        {party.contactName ? party.contactName : 'Not Specified'}
      </div>
    </Card>
  );
}

function SigningParty({
  editing,
  party,
  doSave,
  loading,
  error,
  doCancel,
  showContact,
  locale,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      _id: party._id,
      ref: party.ref,
      name: party.name,
      contactId: party.contactId,
    });
  }, [party, form]);

  const signatureDate =
    party.date &&
    new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(new Date(party.date));

  return (
    <>
      {editing ? (
        <Card>
          <Form form={form} layout="vertical" onFinish={doSave}>
            <Form.Item name="_id" initialValue={party._id} noStyle>
              <Input type="hidden" disabled={loading} />
            </Form.Item>
            <Form.Item
              name="ref"
              label="Party Reference"
              initialValue={party.ref}
              rules={[{ required: 'This field is required' }]}
              extra="This is the ID used to reference a parties name in the body of the agreement"
            >
              <Input disabled={loading} />
            </Form.Item>
            <Form.Item
              label="Party Name"
              name="name"
              rules={[{ required: 'This field is required' }]}
              extra="ex: AIMS Party Responsible for Suppling Loan"
            >
              <Input disabled={loading} />
            </Form.Item>
            {showContact && (
              <Form.Item label="Contact" name="contactId">
                <SelectContact
                  disabled={loading}
                  filters={{ contactType: ContactTypes.CONTACT.key }}
                />
              </Form.Item>
            )}
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  htmlType="button"
                  onClick={() => doCancel(party._id)}
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </div>
            </Form.Item>
            {error && <Paragraph type="danger">{error}</Paragraph>}
          </Form>
        </Card>
      ) : (
        <Card styles={{ body: { position: 'relative' } }}>
          <div style={{ flex: 1 }}>
            <div
              style={{ fontSize: 14, color: sharedSettings.colors.textGray }}
            >
              {party.name}
            </div>
            <div style={{ fontSize: 18, color: sharedSettings.colors.primary }}>
              {party.contactName ? party.contactName : 'Not Specified'}
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  height: 60,
                  flex: 3,
                  borderBottom: `1px solid ${sharedSettings.colors.borderGray}`,
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 8,
                }}
              >
                <Text style={{ marginRight: 16 }}>Signature:</Text>
                {party.signature && (
                  <div
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily:
                          Fonts[party.fontName] &&
                          Fonts[party.fontName].fontFamily,
                        fontSize: 32,
                        marginRight: 16,
                        lineHeight: 1,
                      }}
                    >
                      {party.signature}
                    </Text>
                  </div>
                )}
              </div>
              <div
                style={{
                  height: 60,
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: `1px solid ${sharedSettings.colors.borderGray}`,
                }}
              >
                <Text style={{ marginRight: 16 }}>Date:</Text>
                {signatureDate && (
                  <div
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        marginRight: 16,
                        fontSize: 24,
                        fontFamily:
                          Fonts[party.fontName] &&
                          Fonts[party.fontName].fontFamily,
                        lineHeight: 1,
                      }}
                    >
                      {signatureDate}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
}

function PartiesElement({ loanAgreement, handleSave, signing }) {
  const [editing, setEditing] = useState({});
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [parties, setParties] = useState([]);
  const doSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const index = (
          (loanAgreement && loanAgreement.parties) ||
          []
        ).findIndex((p) => p._id === values._id);
        const _parties = [
          ...((loanAgreement && loanAgreement.parties) || []),
        ].map((p) => ({
          _id: p._id,
          ref: p.ref,
          name: p.name,
          contactId: p.contactId,
        }));
        if (index < 0) {
          _parties.push(values);
        } else {
          _parties[index] = values;
        }
        const unique = [...new Set(_parties.map((p) => p.ref))];
        if (unique.length !== _parties.length) {
          throw new Error('Party references must be unique.');
        }
        handleSave({
          parties: _parties,
        });
        setParties(_parties);
        setEditing({});
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  useEffect(() => {
    setParties((loanAgreement && loanAgreement.parties) || []);
  }, [loanAgreement, form]);

  const handleAddParty = useCallback(() => {
    const partyId = generateUuid();
    setEditing({ ...editing, [partyId]: true });
    setParties([
      ...parties,
      { _id: partyId, ref: `partyNo${parties.length + 1}` },
    ]);
  }, [parties, editing]);

  const handleEdit = useCallback(
    (partyId) => {
      setEditing({ ...editing, [partyId]: true });
    },
    [editing],
  );
  const handleCancelEdit = useCallback(
    (partyId) => {
      setEditing({ ...editing, [partyId]: false });
    },
    [editing],
  );
  const doDelete = useCallback(
    async (partyId) => {
      setLoading(true);
      setError(undefined);
      const _parties = (loanAgreement.parties || [])
        .filter((p) => p._id !== partyId)
        .map((p) => ({
          _id: p._id,
          ref: p.ref,
          name: p.name,
          contactId: p.contactId,
        }));
      handleSave({
        parties: _parties,
      });
      setParties(_parties);
      setEditing({});
      setLoading(false);
    },
    [handleSave, loanAgreement],
  );

  const title = signing ? 'Signatures' : 'Parties';
  const locale = useSelector((store) => store.locale, shallowEqual);

  return (
    <>
      <div className="line-heading">
        <Title level={2}>{title}</Title>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
          xxl: 1,
        }}
        dataSource={parties}
        renderItem={(party) => {
          return (
            <List.Item>
              {(!loanAgreement ||
                loanAgreement.status === AgreementStatuses.PREPARING.key ||
                loanAgreement.isTemplate) && (
                <SingleParty
                  party={party}
                  editing={editing[party._id]}
                  doSave={doSave}
                  loading={loading}
                  doEdit={handleEdit}
                  doCancel={handleCancelEdit}
                  doDelete={doDelete}
                  error={error}
                  showContact={loanAgreement && !loanAgreement.isTemplate}
                />
              )}
              {loanAgreement &&
                loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                !loanAgreement.isTemplate &&
                !signing && <ReadOnlyParty party={party} />}
              {loanAgreement &&
                loanAgreement.status !== AgreementStatuses.PREPARING.key &&
                signing && (
                  <SigningParty
                    party={party}
                    editing={editing[party._id]}
                    loading={loading}
                    doCancel={handleCancelEdit}
                    error={error}
                    locale={locale}
                  />
                )}
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <>
              <Paragraph style={{ fontSize: 14, marginBottom: 16 }}>
                {`Parties describe the signatures required by this agreement`}
              </Paragraph>
              <div>
                <Button type="primary" onClick={handleAddParty}>
                  Add Party
                </Button>
              </div>
            </>
          ),
        }}
        rowKey="_id"
      />
      {parties &&
        parties.length > 0 &&
        (!loanAgreement ||
          loanAgreement.status === AgreementStatuses.PREPARING.key) && (
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={handleAddParty}>
              Add Another Party
            </Button>
          </div>
        )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: space-between;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default PartiesElement;
