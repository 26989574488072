import { gql } from '@apollo/client';
import { updateSimpleListAction } from '../../redux-store/simple-lists-store';
import { shallowEqual, useSelector } from 'react-redux';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import { useMemo, useState } from 'react';
import Fuse from 'fuse.js';

const simpleListForAdminQuery = gql`
  query SimpleList($name: String) {
    simpleListForAdmin(name: $name) {
      name
      list {
        _id
        key
        content
      }
      updatedAt
      updatedBy
      createdAt
      createdBy
    }
  }
`;

const searchOptions = {
  includeScore: true,
  keys: ['key'],
};

const extractSimpleList = (data) =>
  data.simpleListForAdmin?.list && {
    _id: 'tags',
    list: data.simpleListForAdmin?.list?.map((t) => {
      let content;
      try {
        content = JSON.parse(t.content);
      } catch (err) {
        console.error(err);
      }
      return {
        _id: t._id,
        key: t.key,
        color: content?.color,
      };
    }),
  };
const queryId = 'use-tags';
const name = 'tags';

function useTags() {
  const { loading, refetch } = useOneM1({
    variables: { name: 'tags' },
    query: simpleListForAdminQuery,
    extract: extractSimpleList,
    fetchPolicy: 'network-only',
    updateAction: updateSimpleListAction,
    queryId,
  });
  const simpleList = useSelector(
    (store) => store.simpleLists[queryId]?.[name],
    shallowEqual,
  );
  const [search, setSearch] = useState();
  const tags = useMemo(() => {
    if (search) {
      const fuse = new Fuse(simpleList?.list, searchOptions);
      return fuse
        .search(search)
        .sort((a, b) => a.score - b.score)
        .map((i) => i.item);
    }
    return simpleList?.list?.sort((a, b) => a?.key?.localeCompare(b?.key));
  }, [simpleList, search]);
  return {
    tags,
    loading,
    refetch,
    search: (term) => setSearch(term),
  };
}

export default useTags;
