import { useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { deleteTrainingCourseMutation } from '../constants';

const { Paragraph } = Typography;

function DeleteTrainingCourseModal({ visible, onCancel, onFinish }) {
  const trainingCourse = visible;
  const [loading, setLoading] = useState(false);
  const [deleteCourse] = useMutation(deleteTrainingCourseMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteCourse({
        variables: {
          _id: trainingCourse._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Course deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [trainingCourse, deleteCourse, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Training Course"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>
        Are you sure you want to delete this training course?
      </Paragraph>
      <Paragraph style={{ fontWeight: 600 }}>
        {trainingCourse?.courseName}
      </Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteTrainingCourseModal;
