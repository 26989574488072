import { gql, useMutation } from '@apollo/client';
import { Form, Modal, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import EditAccountFormItems from './components/EditAccountFormItems';

export const updateAccountMutation = gql`
  mutation UpdateAccount($account: AccountUpdateInput!) {
    updateAccount(account: $account) {
      account {
        _id
      }
    }
  }
`;

const AccountUpdateModal = ({ visible, onFinish, onCancel }) => {
  const { account } = visible || {};
  const [saving, setSaving] = useState(false);
  const [updateAccount] = useMutation(updateAccountMutation);

  const onSubmit = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { name, description, tags, ...values } = _values;
        await updateAccount({
          variables: {
            account: {
              _id: account._id,
              name,
              description,
              tags,
            },
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Account updated successfully',
        });
        if (onFinish) {
          onFinish();
        }
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setSaving(false);
    },
    [updateAccount, onFinish, account],
  );

  const [form] = Form.useForm();
  useEffect(() => {
    if (account) {
      form.setFieldsValue({ ...account });
    } else {
      form.resetFields();
    }
  }, [form, account]);

  return (
    <Modal
      header={null}
      open={!!visible}
      closable={!saving}
      destroyOnClose={true}
      maskClosable={true}
      onCancel={oncancel}
      footer={null}
    >
      <TaskSomethingForm
        title="Update Account"
        form={form}
        onFinish={onSubmit}
        onCancel={onCancel}
        saving={saving}
      >
        <EditAccountFormItems account={account} />
      </TaskSomethingForm>
    </Modal>
  );
};

export default AccountUpdateModal;
