import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { displayMoney } from '@aims/shared/shared/utils';
import {
  EyeOutlined,
  FormatPainterOutlined,
  SearchOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../../settings';
import useQueryParams from '../../../../shared/use-query-params';
import AssociateDonationsModalA10 from '../../a10-associate-donations/AssociateDonationsModalA10';
import { UpgSponsorshipPoolAccountId } from '../../a10-associate-donations/constants';
import SelectFund from '../../funds/SelectFund';
import { allPpPaymentsQuery, extractAllPayments } from '../constants';
import ViewDonationModalA10 from './ViewDonationModalA10';

const { Text } = Typography;

function AdminListDonations({ contactId }) {
  const [numShown, setNumShown] = useState(settings.pageSize);

  const filters = useRef({
    contactId,
  });
  const sortBy = useRef([
    { key: 'ppReceivedOn', order: 'DESC' },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: donations,
    search: donationSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allPpPaymentsQuery,
    extract: extractAllPayments,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'pushpayDonations',
  });

  const locale = useSelector((store) => store.locale, shallowEqual);

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: 'ppReceivedOn', order: 'DESC' },
          { key: '_score', order: 'DESC' },
        ];
        donationSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [donationSearch, refetch],
  );

  const query = useQueryParams();
  const once = useRef(false);
  useEffect(() => {
    const { _id } = query;
    if (_id && !once.current) {
      once.current = true;
      form.setFieldsValue({ search: _id });
      donationSearch(_id);
    }
  }, [query, form, donationSearch]);

  const handleLoadMore = useCallback(() => {
    setNumShown(numShown + settings.pageSize);
    if (numShown + settings.pageSize > donations.length) {
      next();
    }
  }, [next, numShown, donations]);

  const [assoc, setAssoc] = useState();
  const showAssocModal = useCallback((payment) => {
    setAssoc({ payment, isRecurring: false });
  }, []);
  const handleCancelAssoc = useCallback(() => {
    setAssoc(undefined);
  }, []);
  const handleAssocFinish = useCallback(() => {
    setAssoc(undefined);
    refetch();
  }, [refetch]);

  const [view, setView] = useState();
  const showViewModal = useCallback((payment) => {
    setView({ payment });
  }, []);
  const handleCancelView = useCallback(() => {
    setView(undefined);
  }, []);

  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form layout="inline" form={form} onValuesChange={onFiltersChanged}>
          <Form.Item name="search">
            <Input
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ maxWidth: 500, minWidth: 300 }}
            />
          </Form.Item>
          <Form.Item name="fundId">
            <SelectFund
              style={{ maxWidth: 500, minWidth: 200 }}
              placeholder="Select a fund ..."
            />
          </Form.Item>
        </Form>
        <Button onClick={() => refetch()}>Refresh</Button>
      </div>
      <Table
        dataSource={donations.slice(0, numShown)}
        loading={!donations.length && loading}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {
              try {
                console.log({
                  record,
                  notes: record.notes && JSON.parse(record.notes),
                });
              } catch (err) {
                console.log({ record });
              }
            }, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }}
      >
        {/* <Column title="Pushpay ID" dataIndex="_id" key="_id" /> */}
        <Column
          title="Received On"
          dataIndex="ppReceivedOn"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.ppReceivedOn));
          }}
        />
        <Column title="Status" dataIndex="status" key="status" />
        <Column
          title="Amount"
          dataIndex="usdCentsAmount"
          render={(text, record) => {
            if (record.usdCentsAmount) {
              return displayMoney(record.usdCentsAmount / 100);
            }
            return null;
          }}
        />
        <Column title="Fund" dataIndex="fundName" key="fundName" />
        <Column title="Donor" dataIndex="payerName" key="payerName" />
        <Column
          title="Recurring Payment"
          dataIndex="recurringPaymentToken"
          render={(text, record) => {
            return record.recurringPaymentToken ? 'Yes' : 'No';
          }}
        />
        <Column
          title="Refund?"
          dataIndex="refundForId"
          render={(text, record) => {
            if (record.refundForId) {
              return <Tag color="blue">Refund</Tag>;
            }
            if (record.refundedById) {
              return <Tag color="red">Refunded</Tag>;
            }
            return null;
          }}
        />
        <Column
          title="Allocations"
          dataIndex="adoption"
          render={(text, record) => {
            const tags = [];
            if (record.adoptions && record.adoptions.length > 0) {
              tags.push(
                <Tag
                  color="green"
                  style={{ marginRight: 4, marginBottom: 4 }}
                  key="adoptions"
                >{`${record.adoptions.length} UPG Sponsorship${
                  record.adoptions.length > 1 ? 's' : ''
                }`}</Tag>,
              );
            }
            if (record.sponsorships && record.sponsorships.length > 0) {
              tags.push(
                <Tag
                  color="blue"
                  style={{ marginRight: 4, marginBottom: 4 }}
                  key="sponsorships"
                >{`${record.sponsorships.length} Holistic Project${
                  record.sponsorships.length > 1 ? 's' : ''
                }`}</Tag>,
              );
            }
            if (
              record.directAccounts &&
              record.directAccounts.length === 1 &&
              record.directAccounts[0].accountId === UpgSponsorshipPoolAccountId
            ) {
              tags.push(
                <Tag
                  key={UpgSponsorshipPoolAccountId}
                  color="purple"
                  style={{ marginRight: 4, marginBottom: 4 }}
                >
                  {record.directAccounts[0].account?.name}
                </Tag>,
              );
            } else if (
              record.directAccounts &&
              record.directAccounts.length > 1
            ) {
              tags.push(
                <Tag
                  color="purple"
                  key="accounts"
                  style={{ marginRight: 4, marginBottom: 4 }}
                >{`${record.directAccounts.length} Direct Accounts${
                  record.directAccounts.length > 1 ? 's' : ''
                }`}</Tag>,
              );
            }
            return tags;
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Button
                  onClick={() => showViewModal(record)}
                  icon={<EyeOutlined />}
                />
              </Tooltip>
              {!record.isAllocated && (
                <>
                  {/* <Tooltip title="Directly Allocate">
                    <Button
                      onClick={() => showAllocateModal(record)}
                      icon={<BankOutlined />}
                      disabled={record.status !== 'Success'}
                    />
                  </Tooltip> */}
                  {/* <Tooltip title="Associate with UPG Sponsorship">
                    <Button
                      onClick={() => showEditModal(record)}
                      icon={<GiftOutlined />}
                      disabled={record.status !== 'Success'}
                    />
                  </Tooltip> */}
                  {record.fundId === settings.holisticSustainabilityFund && (
                    <Tooltip title="Associate with Holistic Project">
                      <Button
                        onClick={() => showAssocModal(record)}
                        icon={<FormatPainterOutlined />}
                        disabled={
                          record.status !== 'Success' || record.isAllocated
                        }
                      />
                    </Tooltip>
                  )}
                  {record.fundId === settings.sponsorANativeMissionaryFund && (
                    <Tooltip title="Associate with UPG Sponsorship">
                      <Button
                        onClick={() => showAssocModal(record)}
                        icon={<TranslationOutlined />}
                        disabled={
                          record.status !== 'Success' || record.isAllocated
                        }
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {(numShown < donations.length || hasNextPage) && (
          <Button
            style={{ minWidth: 200 }}
            onClick={handleLoadMore}
            loading={loading}
          >
            Load More
          </Button>
        )}
      </div>
      <AssociateDonationsModalA10
        visible={assoc}
        onCancel={handleCancelAssoc}
        onDone={handleAssocFinish}
      />
      <ViewDonationModalA10
        visible={view}
        onCancel={handleCancelView}
        isRecurring={false}
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
        .bottom-actions {
          display: flex;
          justify-content: center;
          margin-top: 32px;
        }
      `}</style>
    </>
  );
}

export default AdminListDonations;
