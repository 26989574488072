import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { deleteUpgStatsRecordMutation } from './constants';

const { Text } = Typography;

function DeleteAdoptionStatsModal({ deleting, onCancel, onFinish }) {
  const { adoptionStatsRow } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteAdoptionStats] = useMutation(deleteUpgStatsRecordMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteAdoptionStats({
        variables: {
          _id: adoptionStatsRow._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Sponsorship stats deleted successfully',
      });
      onFinish();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [deleteAdoptionStats, adoptionStatsRow, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="Delete Sponsorship Stats"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            {adoptionStatsRow && (
              <Text>{`Are you sure you want to delete this row (${adoptionStatsRow.peopleGroupId} / ${adoptionStatsRow.quarter})?`}</Text>
            )}
          </div>
          <div>
            {adoptionStatsRow && adoptionStatsRow.name && (
              <>
                <Text>{`${adoptionStatsRow.name}`}</Text>
              </>
            )}
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!deleting}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteAdoptionStatsModal;
