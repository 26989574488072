import sharedSettings from '@aims/shared/sharedSettings';
import { Col, Empty, Row, Skeleton, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function DetailsGrid({ details, loading, gutter }) {
  return (
    <>
      {loading ? (
        <>
          {[
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
          ].map((row, index1) => (
            <Row key={index1} gutter={gutter}>
              {row.map((col, index2) => (
                <Col key={index2}>
                  <Skeleton active />
                </Col>
              ))}
            </Row>
          ))}
        </>
      ) : (
        <>
          {details && details.length > 0 ? (
            details.map((row, index1) => (
              <Row key={index1} gutter={gutter}>
                {row.map((col, index2) => (
                  <Col key={index2} span={col.span}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: sharedSettings.colors.primary,
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          {col.title}
                        </Text>
                        <br />
                        {col.component ? (
                          col.component
                        ) : (
                          <Text>{col.value}</Text>
                        )}
                      </div>
                      {col.edit && col.edit}
                    </div>
                  </Col>
                ))}
              </Row>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Details"
            />
          )}
        </>
      )}
    </>
  );
}

export default DetailsGrid;
