import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const allJPLanguagesQuery = gql`
  query AllJPLanguages {
    allJPLanguages {
      _id
      name
    }
  }
`;

function SelectLanguage({ disabled, value, onChange }) {
  const { loading, error, data } = useQuery(allJPLanguagesQuery);
  const languages = useMemo(() => {
    if (data) {
      return data.allJPLanguages;
    }
    return [];
  }, [data]);
  return (
    <Select
      showSearch
      // optionFilterProp="children"
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a language"
      value={value}
      allowClear
      loading={loading}
    >
      {languages.map((language) => (
        <Select.Option
          key={language._id}
          value={language._id}
          title={language.name}
        >
          <div className="language-option">
            <div>
              <Text>{language.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectLanguage;
