/** @typedef {React.MutableRefObject<(id: string) => void>} FunctionRef **/

import {
  EditOutlined,
  GiftOutlined,
  GlobalOutlined,
  GoogleOutlined,
  MailOutlined,
  PhoneOutlined,
  TagsOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Skeleton, Space, Tag, Typography } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import sharedSettings from '@aims/shared/sharedSettings';
import DeleteThingDropdown from '../../../components/DeleteThingDropdown';
import PhotoCircle from '../../../components/PhotoCircle';
import useUser from '../../users/use-user';
import AddContactToGoogleModal from '../AddContactToGoogleModal';
import DeleteContactModalA14 from '../DeleteContactModalA14';
import SubscribeToContactButton from '../SubscribeToContactButton';
import { ContactTypes } from '../constants';
import ContactChangePhotoModal from './ContactInfo/ContactChangePhotoModal';
import ContactChangeSomethingModalA10 from './ContactInfo/ContactChangeSomethingModalA10';
import ContactNameInnerModal from './ContactInfo/ContactNameInnerModal';
import { getContactRoleLabels } from '../contact-roles';

const { Title, Text } = Typography;

function ContactTitle({ contact, queryId }) {
  const handleAddToGoogle = useRef();
  const handleUpdateInGoogle = useRef();
  const { user } = useUser(contact?.userId);

  const primaryPhoneNumber = useMemo(() => {
    if (contact && contact.primaryPhoneNumberId) {
      const phone = contact.phoneNumbers.find(
        (p) => p._id === contact.primaryPhoneNumberId,
      );
      return phone && phone.phone;
    } else if (user && user.phone) {
      return user.phone;
    }
    return null;
  }, [contact, user]);
  const primaryEmail = useMemo(() => {
    if (contact && contact.primaryEmailId) {
      const email = contact.emails.find(
        (p) => p._id === contact.primaryEmailId,
      );
      return email && email.email;
    } else if (user && user.email) {
      return user.email;
    }
    return null;
  }, [contact, user]);

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const onDelete = () => setDeleting(contact);
  const onCancelDelete = () => setDeleting();
  const onFinishDelete = () => {
    setDeleting(undefined);
    history.push('/people');
  };

  return (
    <div className="outer-container">
      {contact ? (
        <div className="container">
          <div
            style={{
              marginRight: 32,
              display: 'flex',
              width: 148,
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <PhotoCircle
              photoUrl={contact.primaryThumbnailUrl}
              name={contact.fullName}
              style={{
                height: 148,
                width: 148,
                paddingBottom: 'unset',
                marginBottom: 16,
              }}
              color={ContactTypes[contact.contactType].color}
            />
            <ContactChangeSomethingModalA10
              contact={contact}
              btnProps={{
                icon: <EditOutlined />,
              }}
              btnText="Change Photo"
            >
              <ContactChangePhotoModal />
            </ContactChangeSomethingModalA10>
          </div>

          <Space direction="vertical" style={{ flex: 1 }}>
            <div>
              {contact.contactType && (
                <div className="contact">
                  <Text
                    style={{
                      color: ContactTypes[contact.contactType]?.color,
                      marginBottom: 8,
                    }}
                  >
                    {ContactTypes[contact.contactType].label}
                  </Text>
                </div>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title
                  style={{
                    marginBottom: 0,
                    marginRight: 8,
                  }}
                >
                  {contact.fullName}
                </Title>
                <ContactChangeSomethingModalA10 contact={contact}>
                  <ContactNameInnerModal />
                </ContactChangeSomethingModalA10>
              </div>
            </div>
            <div style={{ marginBottom: 4 }}>
              <Text>
                <TagsOutlined style={{ marginRight: 8 }} />
                {getContactRoleLabels(contact).join(', ')}
              </Text>
              {contact.peopleGroupIds.length > 0 && (
                <div className="contact">
                  <Text>
                    <TranslationOutlined style={{ marginRight: 8 }} />
                    {contact.peopleGroupIds.join(', ')}
                  </Text>
                </div>
              )}
              {contact.isFieldWorker &&
                contact.regionalCoordId &&
                contact.regionalCoordId !== contact._id && (
                  <div style={{ marginBottom: 2 }}>
                    <Text>
                      Regional Coordinator:{' '}
                      <Link
                        to={`/contact/view/${contact.regionalCoordId}/dashboard`}
                      >
                        {(contact.regionalCoord &&
                          contact.regionalCoord.fullName) ||
                          'Not Specified'}
                      </Link>
                    </Text>
                  </div>
                )}
              {contact.regionName && (
                <div className="contact">
                  <Text>
                    <GlobalOutlined style={{ marginRight: 8 }} />
                    {contact.regionName}
                  </Text>
                </div>
              )}
              {user && (
                <div>
                  <Text>
                    <UserOutlined style={{ marginRight: 8 }} />
                    {user.username}
                  </Text>
                </div>
              )}
              {primaryPhoneNumber && (
                <div>
                  <Text>
                    <PhoneOutlined style={{ marginRight: 8 }} />
                    {primaryPhoneNumber}
                  </Text>
                </div>
              )}
              {primaryEmail && (
                <div>
                  <Text>
                    <MailOutlined style={{ marginRight: 8 }} />
                    {primaryEmail}
                  </Text>
                </div>
              )}
            </div>
            {contact.aliases && contact.aliases.length > 0 && (
              <div>
                Aliases:{' '}
                {contact.aliases &&
                  contact.aliases.map((alias) => (
                    <Tag
                      key={alias}
                      color={sharedSettings.colors.secondary}
                      style={{ marginBottom: 8 }}
                    >
                      {alias}
                    </Tag>
                  ))}
              </div>
            )}
            {contact.tags && !!contact.tags.length && (
              <div>
                {contact.tags.map((tag) => (
                  <Tag
                    key={tag.key}
                    color={sharedSettings.colors.primary}
                    style={{ marginBottom: 8 }}
                  >
                    {tag.key}
                  </Tag>
                ))}
              </div>
            )}
          </Space>
          <div className="right">
            <SubscribeToContactButton
              contactId={contact._id}
              queryId={queryId}
            />
            <DeleteThingDropdown
              thing={contact}
              label="Delete Contact"
              handleDelete={onDelete}
              moreItems={[
                contact && !contact.googleId
                  ? {
                      key: 'add-google',
                      label: 'Add to Google Contacts',
                      icon: <GoogleOutlined />,
                    }
                  : undefined,
                contact?.googleId
                  ? {
                      key: 'update-google',
                      label: 'Update Google Contact',
                      icon: <GoogleOutlined />,
                    }
                  : undefined,
              ].filter((a) => a)}
              moreCases={(key) => {
                if (key === 'add-google') {
                  handleAddToGoogle.current(contact._id);
                }
                if (key === 'update-google') {
                  handleUpdateInGoogle.current(contact._id);
                }
              }}
            />
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}
      <AddContactToGoogleModal
        handleAddToGoogle={handleAddToGoogle}
        handleUpdateInGoogle={handleUpdateInGoogle}
        queryId={queryId}
      />
      <DeleteContactModalA14
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
      <style jsx>{`
        .left {
          width: 180px;
        }
        .outer-container {
          padding-top: 16px;
        }
        .container {
          display: flex;
        }
        .left {
          padding-right: 32px;
        }
        .profile-photo {
          height: 128px;
          width: 128px;
          border-radius: 100%;
          overflow: hidden;
        }
      `}</style>
    </div>
  );
}

export default ContactTitle;
