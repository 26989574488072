import { gql, useApolloClient } from '@apollo/client';
import { useEffect, useRef, useState, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { readCountriesAction } from '@aims/shared/redux-store/countries-store';

export const countriesQuery = gql`
  query Countries {
    countries {
      shortCode
      name
      hasStates
    }
  }
`;

export function useCountries(name) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetches, setRefetches] = useState(0);
  const _refetches = useRef(-1);

  const apolloClient = useApolloClient();

  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      try {
        const result = await apolloClient.query({
          query: countriesQuery,
          fetchPolicy: 'network-only',
        });
        if (result && result.data && result.data.countries) {
          // const bas = [];
          // for (let i = 0; i < 1000; i += 1) {
          //   const b = { ...result.data.country.edges[0].node, id: i };
          //   bas.push(b);
          // }
          dispatch(readCountriesAction(result.data.countries));
          // dispatch(readCountriesAction(bas));
        }
      } catch (err) {
        setError(err.message);
      }
      _refetches.current = refetches;
      setLoading(false);
    }
    if (_refetches.current !== refetches) {
      doAsyncStuff();
    }
  }, [dispatch, refetches, name, apolloClient]);

  const countries = useSelector((store) => store.countries, shallowEqual);

  return {
    countries,
    loading,
    error,
    refetch: () => setRefetches(refetches + 1),
  };
}
