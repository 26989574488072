import React from 'react';
import PhotoCircle from '../../../../../components/PhotoCircle';
import { Link } from 'react-router-dom';

function PayoutPhotoColumn({ record }) {
  const pg = record.adoptionCopy && record.adoptionCopy.peopleGroup;
  return (
    !!pg && (
      <Link to={`/adoption/view/${record.adoptionId}`}>
        <PhotoCircle
          photoUrl={pg.primaryThumbnailUrl}
          name={pg.nameAcrossCountries}
          customSize={48}
          style={{ padding: 0, height: 48, width: 48 }}
        />
      </Link>
    )
  );
}

export default PayoutPhotoColumn;
