import { Space, Typography } from 'antd';
import React from 'react';
import useProfile from '../../../shared/use-profile';
import ApproveUpgQuarterlyReportBtn from './ApproveUpgQuarterlyReportBtn';
import EditUpgQuarterlyReportBtn from './EditUpgQuarterlyReportBtn';
import MarkUpgQuarterlyReportPendingBtn from './MarkUpgQuarterlyReportPendingBtn';
import PrintReportBtn from './PrintReportBtn';
import PutUpgQuarterlyReportOnHoldBtn from './PutUpgQuarterlyReportOnHoldBtn';
import RejectUpgQuarterlyReportBtn from './RejectUpgQuarterlyReportBtn';
import SendUpgQuarterlyReportFeedbackBtn from './SendUpgQuarterlyReportFeedbackBtn';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

const { Title, Text, Paragraph } = Typography;

const ReviewButtons = ({ report, onSuccess }) => {
  const profile = useProfile();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {report.status === ReportApprovalStatuses.DRAFT.key && (
            <EditUpgQuarterlyReportBtn report={report} />
          )}
          {report.status === ReportApprovalStatuses.PENDING.key && (
            <>
              <ApproveUpgQuarterlyReportBtn
                report={report}
                onSuccess={onSuccess}
              />
              <PutUpgQuarterlyReportOnHoldBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectUpgQuarterlyReportBtn
                report={report}
                onSuccess={onSuccess}
              />
            </>
          )}
          {report.status === ReportApprovalStatuses.REJECTED.key &&
            profile.username === 'superadmin' && (
              <MarkUpgQuarterlyReportPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.APPROVED.key &&
            profile.username === 'superadmin' && (
              <MarkUpgQuarterlyReportPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.HOLD.key && (
            <>
              <SendUpgQuarterlyReportFeedbackBtn
                report={report}
                onSuccess={onSuccess}
              />
              <ApproveUpgQuarterlyReportBtn
                report={report}
                onSuccess={onSuccess}
              />
              <MarkUpgQuarterlyReportPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectUpgQuarterlyReportBtn
                report={report}
                onSuccess={onSuccess}
              />
            </>
          )}
          <PrintReportBtn />
        </Space>
      </div>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default ReviewButtons;
