import { Empty, List } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TaskListItem from './TaskListItem';
import ViewTaskModal from './ViewTaskModal/ViewTaskModal';

function TasksList({ tasks, loading, queryId, gridColumns, refetch }) {
  const [showEditTaskModel, setShowEditTaskModel] = useState(null);
  const closeViewTaskModal = useCallback(() => {
    setShowEditTaskModel(null);
  }, []);
  const params = useParams();
  useEffect(() => {
    const { taskId } = params;
    if (taskId) {
      setShowEditTaskModel(taskId);
    }
  }, [params]);
  return (
    <>
      <List
        grid={gridColumns}
        style={{ maxWidth: 1000 }}
        bordered={false}
        dataSource={tasks}
        renderItem={(task) => (
          <TaskListItem task={task} setShowTaskModel={setShowEditTaskModel} />
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Tasks"
            />
          ),
        }}
      />
      <ViewTaskModal
        visible={showEditTaskModel}
        setVisible={setShowEditTaskModel}
        closeModal={closeViewTaskModal}
        queryId={queryId}
        refetch={refetch}
      />
    </>
  );
}

export default TasksList;
