import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  notification,
  Typography,
} from 'antd';
import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';
import { useMutation } from '@apollo/client';
import { updateHolisticProjectMutation } from '../../../constants';
import { FormOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

function ProjectDebriefSection({ project }) {
  const [visible, setVisible] = useState();
  const onEdit = () => setVisible(true);
  const onCancel = () => setVisible(undefined);
  const onFinish = useCallback(() => setVisible(undefined), []);

  const [saving, setSaving] = useState(false);
  const [updateHolisticProject] = useMutation(updateHolisticProjectMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateHolisticProject({
          variables: {
            holisticProject: {
              _id: project._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Project debrief saved!',
        });
        onFinish();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred',
        });
      }
      setSaving(false);
    },
    [updateHolisticProject, project, onFinish],
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        debriefNotes: project.debriefNotes,
      });
    } else {
      form.resetFields();
    }
  }, [visible, project, form]);

  return (
    <>
      {[
        HolisticProjectStages.COMPLETE.key,
        HolisticProjectStages.FAILED.key,
      ].includes(project.stage) && (
        <>
          <Title level={3} style={{ marginBottom: 16 }}>
            Project Debrief
          </Title>
          <div style={{ marginBottom: 32 }}>
            {project.debriefNotes ? (
              <>
                <Card
                  styles={{ body: { paddingBottom: 8 } }}
                  style={{ marginBottom: 16 }}
                >
                  {project.debriefNotes.split('\n').map((p, index) => (
                    <Paragraph key={index}>{p}</Paragraph>
                  ))}
                </Card>
                <Button onClick={onEdit} icon={<FormOutlined />}>
                  Edit Project Debrief
                </Button>
              </>
            ) : (
              <Button onClick={onEdit} icon={<FormOutlined />}>
                Add Project Debrief
              </Button>
            )}
          </div>
          <Modal
            header={null}
            footer={null}
            open={visible}
            closable={!saving}
            destroyOnClose={true}
            maskClosable={!saving}
            onCancel={onCancel}
            styles={{ body: { paddingTop: 16 } }}
          >
            <TaskSomethingForm
              title="Project Debrief"
              form={form}
              onFinish={handleFinish}
              onCancel={onCancel}
              saving={saving}
              saveText="Save"
            >
              <Form.Item
                label="Debrief Notes"
                name="debriefNotes"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.TextArea disabled={saving} rows={4} />
              </Form.Item>
            </TaskSomethingForm>
          </Modal>
        </>
      )}
    </>
  );
}

export default ProjectDebriefSection;
