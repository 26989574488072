import useJpRegions from '@aims/shared/shared/use-jp-regions';
import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Form, Tag, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AnyAddresses from '../../../../components/AnyInfo/AnyAddresses';
import AnyEmails from '../../../../components/AnyInfo/AnyEmails';
import AnyPhoneNumbers from '../../../../components/AnyInfo/AnyPhoneNumbers';
import ContentEditableInput from '../../../../components/ContentEditableInput';
import DetailsList from '../../../../components/DetailsList';
import { ContactTypes } from '../../constants';
import { getContactRoleLabels } from '../../contact-roles';
import ContactAliasesInnerModal from './ContactAliasesInnerModal';
import ContactChangeBdayInnerModal from './ContactChangeBdayInnerModal';
import ContactChangeRegionInnerModal from './ContactChangeRegionInnerModal';
import ContactChangeSomethingModalA10 from './ContactChangeSomethingModalA10';
import ContactCorrespondenceInnerModalA10 from './ContactCorrespondenceInnerModalA10';
import ContactFWStatusInnerModalA10 from './ContactFWStatusInnerModalA10';
import ContactOtherDetailsInnerModalA10 from './ContactOtherDetailsInnerModalA10';
import ContactRegionalCoordInnerModalA10 from './ContactRegionalCoordInnerModalA10';
import ContactTagsInnerModalA10 from './ContactTagsInnerModalA10';
import HouseholdMembers from './HouseholdMembers';
import ContactHousehold from './Households';
import OrgMembers from './OrgMembers';
import ContactOrganizations from './Organizations';

const { Title, Text, Paragraph } = Typography;

function ContactInfo({ contact }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const jpRegions = useJpRegions();

  const [details, moreDetails, evenMore] = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'full',
    });
    if (contact) {
      return [
        [
          {
            title: 'Contact Roles',
            component: (
              <>
                <Text>{getContactRoleLabels(contact).join(', ')}</Text>
              </>
            ),
          },
          {
            title: 'Contact Region',
            value: jpRegions?.[contact.regionCode]?.name,
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactChangeRegionInnerModal />
              </ContactChangeSomethingModalA10>
            ),
          },
          contact.isFieldWorker && {
            title: 'Regional Coordinator',
            component: contact.regionalCoord ? (
              <Link to={`/contact/view/${contact.regionalCoordId}/dashboard`}>
                {contact.regionalCoord.fullName}
              </Link>
            ) : (
              <Text>Not Specified</Text>
            ),
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactRegionalCoordInnerModalA10 />
              </ContactChangeSomethingModalA10>
            ),
          },
          {
            title: 'Birthday',
            value: contact.birthday
              ? dateFormat.format(new Date(contact.birthday))
              : 'Not Specified',
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactChangeBdayInnerModal />
              </ContactChangeSomethingModalA10>
            ),
          },
          {
            title: 'Correspondence',
            component: (
              <>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Should receive email - ${
                    contact.shouldReceiveEmail ? 'Yes' : 'No'
                  }`}
                </Paragraph>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Should receive physical mail - ${
                    contact.shouldReceivePhysicalMail ? 'Yes' : 'No'
                  }`}
                </Paragraph>
              </>
            ),
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactCorrespondenceInnerModalA10 />
              </ContactChangeSomethingModalA10>
            ),
          },
          {
            title: 'Added to Contacts',
            value: dateFormat.format(new Date(contact.createdAt)),
          },
          contact.isFieldWorker && {
            title: 'Field Worker Status',
            component: (
              <>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Is Sponsored - ${contact.isAdopted ? 'Yes' : 'No'}`}
                </Paragraph>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Is Supported by Holistic Project - ${
                    contact.isSupportedByProject ? 'Yes' : 'No'
                  }`}
                </Paragraph>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Is Sustained by Holistic Project - ${
                    contact.isSustainedByProject ? 'Yes' : 'No'
                  }`}
                </Paragraph>
                <Paragraph style={{ marginBottom: 4 }}>
                  {`Is Sustained by Local Church - ${
                    contact.isSustainedByLocalChurch ? 'Yes' : 'No'
                  }`}
                </Paragraph>
              </>
            ),
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactFWStatusInnerModalA10 />
              </ContactChangeSomethingModalA10>
            ),
          },
        ].filter((f) => f),
        [
          {
            title: 'Other Details',
            component:
              contact.otherDetailsText &&
              contact.otherDetailsText.trim().length > 0 ? (
                <Form.Item noStyle>
                  <ContentEditableInput
                    readOnly
                    value={contact.otherDetails}
                    readOnlyBackground={false}
                  />
                </Form.Item>
              ) : (
                <Paragraph>No extra details provided</Paragraph>
              ),
            edit: (
              <ContactChangeSomethingModalA10 contact={contact}>
                <ContactOtherDetailsInnerModalA10 />
              </ContactChangeSomethingModalA10>
            ),
          },
        ],
        [
          {
            title: 'Tags',
            component: contact.tags?.map((tag) => (
              <Tag
                key={tag.key}
                color={sharedSettings.colors.primary}
                style={{ marginTop: 10 }}
              >
                {tag.key}
              </Tag>
            )),
            edit: (
              <ContactChangeSomethingModalA10
                contact={contact}
                btnText={
                  contact && contact.tags && contact.tags.length > 0
                    ? undefined
                    : 'Add Tags'
                }
              >
                <ContactTagsInnerModalA10 />
              </ContactChangeSomethingModalA10>
            ),
          },
          {
            title: 'Aliases',
            component: contact.aliases?.map((alias, index) => (
              <Tag
                key={index}
                color={sharedSettings.colors.secondary}
                style={{ marginTop: 10 }}
              >
                {alias}
              </Tag>
            )),
            edit: (
              <ContactChangeSomethingModalA10
                contact={contact}
                btnProps={{ style: { marginLeft: 16 } }}
                btnText={
                  contact && contact.aliases && contact.aliases.length > 0
                    ? undefined
                    : 'Add an Alias'
                }
              >
                <ContactAliasesInnerModal />
              </ContactChangeSomethingModalA10>
            ),
          },
        ],
      ];
    }
    return [], [], [];
  }, [contact, locale, jpRegions]);

  if (!contact) {
    return null;
  }

  return (
    <>
      <Card style={{ marginBottom: 32 }}>
        <DetailsList details={details} loading={!contact} />
        <DetailsList details={moreDetails} loading={!contact} />
        <DetailsList details={evenMore} loading={!contact} />
      </Card>
      {contact.contactType === ContactTypes.CONTACT.key && (
        <>
          <ContactOrganizations contact={contact} />
          <ContactHousehold contact={contact} />
        </>
      )}
      {contact.contactType === ContactTypes.ORG.key && (
        <OrgMembers parent={contact} />
      )}
      {contact.contactType === ContactTypes.HOUSEHOLD.key && (
        <HouseholdMembers parent={contact} />
      )}
      <AnyEmails parent={contact} />
      <AnyPhoneNumbers parent={contact} />
      <AnyAddresses parent={contact} />
      <style jsx>{`
        .info {
          background: white;
          border-radius: 4px;
          border: 1px solid ${sharedSettings.colors.borderGray};
          padding: 24px;
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;
          margin-left: -16px;
          margin-right: -16px;
        }
        .info-item {
          margin-bottom: 16px;
          margin-right: 16px;
          margin-left: 16px;
          min-width: calc(50% - 32px);
        }
      `}</style>
    </>
  );
}

export default ContactInfo;
