import { Space } from 'antd';
import React from 'react';
import ApproveHolisticProjectProposalBtn from './ApproveHolisticProjectProposalBtn';
import EditHolisticProjectProposalBtn from './EditHolisticProjectProposalBtn';
import MarkHolisticProjectProposalPendingBtn from './MarkHolisticProjectProposalPendingBtn';
import PrintReportBtn from './PrintReportBtn';
import PutHolisticProjectProposalOnHoldBtn from './PutHolisticProjectProposalOnHoldBtn';
import RejectHolisticProjectProposalBtn from './RejectHolisticProjectProposalBtn';
import SendHolisticProjectProposalFeedbackBtn from './SendHolisticProjectProposalFeedbackBtn';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';
import useProfile from '../../../shared/use-profile';

const ReviewButtons = ({ report, onSuccess }) => {
  const profile = useProfile();
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 100,
          right: 32,
          height: '100%',
        }}
      >
        <Space
          direction="vertical"
          style={{
            position: 'sticky',
            top: 32,
            zIndex: 1000,
          }}
        >
          {report.status === ReportApprovalStatuses.DRAFT.key && (
            <EditHolisticProjectProposalBtn report={report} />
          )}
          {report.status === ReportApprovalStatuses.PENDING.key && (
            <>
              <ApproveHolisticProjectProposalBtn
                report={report}
                onSuccess={onSuccess}
              />
              <PutHolisticProjectProposalOnHoldBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectHolisticProjectProposalBtn
                report={report}
                onSuccess={onSuccess}
              />
            </>
          )}
          {report.status === ReportApprovalStatuses.REJECTED.key &&
            profile.username === 'superadmin' && (
              <MarkHolisticProjectProposalPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.APPROVED.key &&
            profile.username === 'superadmin' && (
              <MarkHolisticProjectProposalPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
            )}
          {report.status === ReportApprovalStatuses.HOLD.key && (
            <>
              <SendHolisticProjectProposalFeedbackBtn
                report={report}
                onSuccess={onSuccess}
              />
              <ApproveHolisticProjectProposalBtn
                report={report}
                onSuccess={onSuccess}
              />
              <MarkHolisticProjectProposalPendingBtn
                report={report}
                onSuccess={onSuccess}
              />
              <RejectHolisticProjectProposalBtn
                report={report}
                onSuccess={onSuccess}
              />
            </>
          )}
          <PrintReportBtn />
        </Space>
      </div>
    </>
  );
};

export default ReviewButtons;
