import {
  HolisticProjectStages,
  HolisticProjectTypes,
} from '@aims/shared/holistic-projects/constants';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Card, Empty, List, Tag, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import AnyHistory from '../../components/AnyHistory';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import DetailsList from '../../components/DetailsList';
import PhotoCircle from '../../components/PhotoCircle';
import AdoptionContactCard from '../../pages/adoptions/AdoptionContactCard';
import { AdoptionHistory } from '../../pages/adoptions/ViewAdoption';
import useSimplifiedContact from '../../pages/adoptions/use-simplified-contact';
import {
  createProjectSponsorshipAction,
  deleteProjectSponsorshipAction,
  updateProjectSponsorshipAction,
} from '../../redux-store/project-sponsorships-store';
import useQueryParams from '../../shared/use-query-params';
import {
  ProjectSponsorshipViewAttributes,
  projectSponsorshipUpdatesSubscription,
} from '../constants';
import DeleteProjectSponsorshipModal from './DeleteSponsorshipModal';
import EditSponsorshipModal from './EditSponsorshipModal';

const { Title, Text, Paragraph } = Typography;

export const viewProjectSponsorshipQuery = gql`
  query ProjectSponsorshipForAdmin($_id: ID!) {
    projectSponsorshipForAdmin(_id: $_id) ${ProjectSponsorshipViewAttributes}
  }
`;

const extractProjectSponship = (data) =>
  data && data.projectSponsorshipForAdmin;

const queryId = 'viewSponsorshipPage';

function ProjectSponsorshipHistoryV2({ projectSponsorshipId }) {
  const historyFilterFunc = useCallback(
    (a) => {
      let ret = true;
      if (a.projectSponsorshipId !== projectSponsorshipId) {
        ret = false;
      }
      return ret;
    },
    [projectSponsorshipId],
  );
  return (
    <AnyHistory
      filterFunc={historyFilterFunc}
      filters={{ projectSponsorshipId }}
      title="Change History"
    />
  );
}

function ViewSponsorshipPage() {
  const history = useHistory();
  const { _id } = useParams();
  const query = useQueryParams();

  const handleBack = useCallback(() => {
    if (query && query.from) {
      history.push(query.from);
    } else {
      history.push('/project-sponsorships');
    }
  }, [query, history]);

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: projectSponsorshipUpdatesSubscription,
    createAction: createProjectSponsorshipAction,
    updateAction: updateProjectSponsorshipAction,
    deleteAction: deleteProjectSponsorshipAction,
    extractSomething: (resp) => resp?.data?.projectSponsorshipUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading } = useOneM1({
    variables: { _id },
    query: viewProjectSponsorshipQuery,
    extract: extractProjectSponship,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateProjectSponsorshipAction,
    skip: !_id,
    showError: true,
  });
  const sponsorship = useSingleSomethingA10(
    'projectSponsorships',
    _id,
    queryId,
  );

  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => {
    setEditing({ queryId, sponsorship });
  }, [sponsorship]);

  const [deleting, setDeleting] = useState();

  const locale = useSelector((store) => store.locale, shallowEqual);
  const details = useMemo(() => {
    if (sponsorship) {
      return [
        {
          title: 'Total',
          component: (
            <>
              <div>
                <Text style={{ marginRight: 16 }}>
                  {`${displayMoney(
                    getNumberValue(sponsorship.amount) / 10000,
                  )}`}
                </Text>
              </div>
            </>
          ),
        },
        {
          title: 'Start Date',
          value:
            sponsorship.startDate &&
            new Intl.DateTimeFormat(locale, {
              dateStyle: 'medium',
            }).format(new Date(sponsorship.startDate)),
        },
        {
          title: 'Created in Catalog',
          value: sponsorship.isOnline ? 'Yes' : 'No',
        },
        sponsorship.accountId
          ? {
              title: 'Account Balance',
              component: (
                <>
                  <Text style={{ marginRight: 8 }}>
                    {displayMoney(
                      getNumberValue(sponsorship.accountBalance) / 10000,
                    )}
                  </Text>
                  <Link
                    to={`/accounting/accounts/view/${sponsorship.accountId}`}
                  >
                    View Account
                  </Link>
                </>
              ),
            }
          : undefined,
        {
          title: 'Last Donation',
          component:
            sponsorship.lastPaymentDate &&
            new Intl.DateTimeFormat(locale, {
              dateStyle: 'medium',
            }).format(new Date(sponsorship.lastPaymentDate)),
        },
      ].filter((f) => f);
    }
    return [];
  }, [sponsorship, locale]);

  const contactDetails = useSimplifiedContact(
    sponsorship && sponsorship.contact,
  );

  const projectInfo = useMemo(() => {
    const project = sponsorship && sponsorship.project;
    if (project) {
      const typeLabel =
        HolisticProjectTypes[project.typeOfProject] &&
        HolisticProjectTypes[project.typeOfProject].label;
      let nameLabel;
      if (project.projectNo) {
        nameLabel = `${project.projectName} (#${project.projectNo})`;
      } else {
        nameLabel = project.projectName;
      }
      const stageLabel =
        HolisticProjectStages[project.stage] &&
        HolisticProjectStages[project.stage].label;
      return {
        ...project,
        typeLabel,
        nameLabel,
        stageLabel,
      };
    }
    return undefined;
  }, [sponsorship]);
  return (
    <CSPage title="Holistic Project Sponsorship">
      <CSPageHeader
        title="Holistic Project Sponsorship"
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          >
            Go Back
          </Button>,
        ]}
        topActions={[
          <Button
            key="edit"
            type="link"
            onClick={handleEdit}
            icon={<EditOutlined />}
          >
            Edit Project Sponsorship
          </Button>,
          // <Button
          //   key="delete"
          //   type="link"
          //   onClick={handleDelete}
          //   icon={<DeleteOutlined />}
          // >
          //   Delete Project Sponsorship
          // </Button>,
        ]}
      />
      <Title level={3}>Project Sponsorship Details</Title>
      <Card>
        <DetailsList details={details} loading={loading} />
        <div>
          {sponsorship && sponsorship.tags && (
            <div className="tags">
              {sponsorship.tags.map((tag) => (
                <Tag
                  key={tag.key}
                  color={sharedSettings.colors.primary}
                  style={{ marginTop: 10 }}
                >
                  {tag.key}
                </Tag>
              ))}
            </div>
          )}
        </div>
      </Card>
      <div style={{ height: 32 }} />
      {sponsorship && sponsorship.notes && (
        <>
          <Title level={3}>Notes</Title>
          <Card>
            {sponsorship.notes.split('\n').map((p, i) => (
              <Paragraph key={i}>{p.trim()}</Paragraph>
            ))}
          </Card>
          <div style={{ height: 32 }} />
        </>
      )}
      <List
        dataSource={[0, 1]}
        renderItem={(item) => (
          <List.Item>
            {item === 0 && (
              <AdoptionContactCard
                title="Sponsor"
                loading={loading}
                contactDetails={contactDetails}
              />
            )}
            {item === 1 && (
              <div>
                <Title level={3}>Project</Title>
                <Card styles={{ body: { display: 'flex' } }}>
                  {loading || (sponsorship && sponsorship.project) ? (
                    <>
                      <div style={{ marginRight: 16 }}>
                        <PhotoCircle
                          photoUrl={
                            projectInfo && projectInfo.primaryThumbnailUrl
                          }
                          name={projectInfo && projectInfo.projectName}
                          customSize={80}
                          color={sharedSettings.colors.contactColor}
                        />
                      </div>
                      {projectInfo && (
                        <div>
                          <div>
                            <Text
                              style={{ color: sharedSettings.colors.primary }}
                            >
                              {projectInfo.typeLabel}
                            </Text>
                          </div>
                          <Title level={5} style={{ marginBottom: 0 }}>
                            {projectInfo.nameLabel}
                          </Title>
                          <div>
                            <Text
                              style={{ color: sharedSettings.colors.textGray }}
                            >
                              {projectInfo.stageLabel}
                            </Text>
                          </div>

                          {projectInfo.regionalCoordinator && (
                            <div>
                              <Link
                                to={`/contact/view/${projectInfo.regionalCoordinator._id}/dashboard?from=${location.pathname}`}
                              >
                                {projectInfo.regionalCoordinator.fullName}
                              </Link>
                            </div>
                          )}
                          {projectInfo.region && (
                            <div>{projectInfo.region}</div>
                          )}
                          {projectInfo.peopleGroupIds && (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {projectInfo.peopleGroupIds.map((pgId) => (
                                <Link
                                  key={pgId}
                                  style={{ marginRight: 8 }}
                                  to={`/people-group/view/${pgId}/info`}
                                >
                                  {pgId}
                                </Link>
                              ))}
                            </div>
                          )}
                          <div>
                            <Link
                              to={`/holistic-project/view/${projectInfo._id}/details`}
                            >
                              Project Page
                            </Link>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>Not Specified</div>
                  )}
                </Card>
              </div>
            )}
          </List.Item>
        )}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Details"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2, gutter: 16 }}
      />
      <div style={{ height: 32 }} />
      <Title level={3}>Donation History</Title>
      {sponsorship &&
      sponsorship.paymentHistory &&
      sponsorship.paymentHistory.length > 0 ? (
        <AdoptionHistory adoptionHistory={sponsorship.paymentHistory} />
      ) : (
        <Card>No Donation History</Card>
      )}
      <div style={{ height: 32 }} />
      <Title level={3}>History</Title>
      {sponsorship && sponsorship.history && (
        <AdoptionHistory adoptionHistory={sponsorship.history} />
      )}
      {sponsorship && !sponsorship.history && <Card>No History</Card>}
      <div style={{ height: 32 }} />
      <ProjectSponsorshipHistoryV2 projectSponsorshipId={_id} />
      <EditSponsorshipModal
        visible={editing}
        onSuccess={() => setEditing(undefined)}
        onCancel={() => setEditing(undefined)}
      />
      <DeleteProjectSponsorshipModal
        deleting={deleting}
        setDeleting={setDeleting}
      />
    </CSPage>
  );
}

export default ViewSponsorshipPage;
