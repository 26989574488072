import { Form } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import SelectTags from '../../tags/SelectTags';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function ChangeTagsInnerModalA10({ adoption, saving, handleFinish, onCancel }) {
  const [form] = Form.useForm();
  const retrieveTagsOnSubmit = useRef();
  useEffect(() => {
    if (adoption) {
      form.setFieldsValue({
        tags: adoption.tags,
      });
    }
  }, [adoption, form]);

  const _handleFinish = useCallback(
    async (_values) => {
      const { selectedTags, ...values } = _values;
      const tags = await retrieveTagsOnSubmit.current(selectedTags);
      handleFinish({
        tags,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title="Edit Tags"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item name="tags">
        <SelectTags disabled={saving} />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default ChangeTagsInnerModalA10;
