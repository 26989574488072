import React, { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { peopleGroupFullAttributes } from '@aims/shared/people-groups/constants';
import { useDispatch } from 'react-redux';
import { updatePeopleGroupAction } from '../../../redux-store/people-groups-store';
import { Button, Dropdown, Menu, notification } from 'antd';
import { MoreOutlined, ReloadOutlined } from '@ant-design/icons';

export const updatePeopleGroupStatsMutation = gql`
  mutation UpdatePeopleGroupStats($_id: ID!) {
    updatePeopleGroupStats(_id: $_id) {
      peopleGroup ${peopleGroupFullAttributes}
    }
  }
`;

function UpdatePeopleGroupStatsButton({ pgId, queryId }) {
  const [updatePeopleGroupStats] = useMutation(updatePeopleGroupStatsMutation);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const handleUpdateStats = useCallback(async () => {
    setError(undefined);
    setLoading(true);
    try {
      const result = await updatePeopleGroupStats({
        variables: {
          _id: pgId,
        },
      });
      if (result && result.data && result.data.updatePeopleGroupStats) {
        dispatch(
          updatePeopleGroupAction(
            result.data.updatePeopleGroupStats.peopleGroup,
            queryId,
          ),
        );
        notification.success({
          message: 'Saved',
          description: 'People group updated successfully',
        });
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [pgId, dispatch, updatePeopleGroupStats, queryId]);

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            icon={<ReloadOutlined />}
            onClick={() => handleUpdateStats()}
            key="update"
          >
            Update People Group Statistics
          </Menu.Item>
        </Menu>
      }
      trigger="click"
    >
      <Button
        type={error ? 'danger' : 'default'}
        loading={loading}
        icon={<MoreOutlined />}
      />
    </Dropdown>
  );
}

export default UpdatePeopleGroupStatsButton;
