import { Button, Form, Input, Space, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text, Title } = Typography;

function EditEventTitle({ onSubmit, event }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const onFinish = useCallback(
    async (values) => {
      setError(null);
      setLoading(true);
      try {
        await onSubmit(values);
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  return (
    <>
      <map
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        {!editing && event.title && (
          <Title style={{ textAlign: 'center' }}>{event.title}</Title>
        )}
      </map>
      {editing && (
        <Form onFinish={onFinish}>
          <Title style={{ textAlign: 'center' }}>{event.title}</Title>
          <Form.Item name="title" initialValue={event.title}>
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
    </>
  );
}

export default EditEventTitle;
