import { Form, Input, InputNumber, Radio, Typography } from 'antd';
import React, { useMemo } from 'react';
import SelectPeopleGroup from '../../people-groups/SelectPeopleGroup';
import useSettings from '../../shared/use-settings';
import { displayMoney } from '../../shared/utils';
import {
  ReportLineItem,
  reportApprovedBy,
  reportChurchesPlanted,
  reportMiracles,
  reportNotes,
  reportNumHeardForFirstTime,
  reportNumSalvations,
  reportNumWaterBaptisms,
  reportOnBehalfOf,
  reportOnBehalfOfContact,
  reportOnBehalfOfName,
  reportPrayerRequests,
  reportPrivateFiles,
  reportPublicFiles,
  reportQuarter,
  reportResultNumbers,
  reportSubmittedBy,
} from '../common/ReportItems';
import { getQuarterLabel } from '../quarters';
import { ReportApprovalStatuses } from '../constants';

const { Title, Text } = Typography;

export const PgQuarterlyReportQuestions = {
  title: {
    Display: function Display({ report, isAdmin }) {
      const title = useMemo(() => {
        let _title = 'UPG Quarterly Report';
        const peopleGroup = report && report.peopleGroup;
        const quarterLabel = getQuarterLabel(report && report.quarter);
        const status = report && ReportApprovalStatuses[report.status];
        if (peopleGroup && report && report.submittedAt) {
          _title = (
            <>
              <span style={{ fontSize: 24, lineHeight: 1.5 }}>
                Quarterly Report
              </span>
              <br />
              {`${peopleGroup.country} - ${peopleGroup.nameAcrossCountries}`}
              <br />
              <span>{quarterLabel}</span>
              {isAdmin && status && (
                <>
                  <br />
                  <span style={{ fontSize: 18, color: status.color }}>
                    {status.label}
                  </span>
                </>
              )}
            </>
          );
        } else if (peopleGroup && report) {
          _title = (
            <>
              <span style={{ fontSize: 24, lineHeight: 1.5 }}>
                Quarterly Report
              </span>
              <br />
              {`${peopleGroup.country} - ${peopleGroup.nameAcrossCountries}`}
              <br />
              <span>{quarterLabel}</span>
              {isAdmin && status && (
                <>
                  <br />
                  <span style={{ fontSize: 18, color: status.color }}>
                    {status.label}
                  </span>
                </>
              )}
            </>
          );
        } else if (peopleGroup) {
          _title = (
            <>
              <span style={{ fontSize: 24, lineHeight: 1.5 }}>
                Quarterly Report
              </span>
              <br />
              {`${peopleGroup.country} - ${peopleGroup.nameAcrossCountries}`}
              <br />
              <span>{quarterLabel}</span>
              {isAdmin && status && (
                <>
                  <br />
                  <span style={{ fontSize: 18, color: status.color }}>
                    {status.label}
                  </span>
                </>
              )}
            </>
          );
        }
        return _title;
      }, [report, isAdmin]);
      return (
        <Title
          style={{
            textAlign: 'center',
            marginBottom: isAdmin && report.status ? 16 : 32,
          }}
        >
          {title}
        </Title>
      );
    },
  },
  submittedBy: reportSubmittedBy,
  approvedBy: reportApprovedBy,
  peopleGroup: {
    FormItem: function FormItem({ loading, fieldsToSet }) {
      const settings = useSettings();
      return (
        <>
          {!(fieldsToSet && fieldsToSet.peopleGroupId) && (
            <Form.Item
              label="People Group"
              name="peopleGroupId"
              rules={[
                {
                  required: true,
                  message: 'Please select a people group',
                },
              ]}
            >
              <SelectPeopleGroup settings={settings} disabled={loading} />
            </Form.Item>
          )}
        </>
      );
    },
    Display: function Display({ report }) {
      const pg = report.peopleGroup;
      const name = `${pg?.nameAcrossCountries} / ${pg?.country} (${pg?._id})`;
      return (
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <Text style={{ fontSize: 15 }}>{name}</Text>
        </div>
      );
    },
  },
  onBehalfOf: reportOnBehalfOf,
  onBehalfOfName: reportOnBehalfOfName,
  onBehalfOfContact: reportOnBehalfOfContact,
  quarter: reportQuarter,
  receivedFinancialSupport: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="Have you received any financial assistance from AIMS for this people group in the last 3 months?"
          name="receivedFinancialSupport"
          rules={[{ required: true, message: 'Please answer yes or no' }]}
        >
          <Radio.Group disabled={loading}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="Have you received any financial assistance from AIMS for this people group in the last 3 months?"
          answer={report.receivedFinancialSupport ? 'Yes' : 'No'}
        />
      );
    },
  },
  receivedForHimself: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          shouldUpdate={(prev, curr) =>
            prev.receivedFinancialSupport !== curr.receivedFinancialSupport
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('receivedFinancialSupport') && (
              <Form.Item
                label="How much money (in USD) have you received from AIMS in the last 3 months to support yourself?"
                name="receivedForHimself"
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <InputNumber
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  disabled={loading}
                  min={0}
                  step={0.01}
                  style={{ width: 200 }}
                />
              </Form.Item>
            )
          }
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      if (!report.receivedFinancialSupport) {
        return null;
      }
      return (
        <ReportLineItem
          question="How much money (in USD) have you received from AIMS in the last 3 months to support yourself?"
          answer={
            report.receivedForHimself != null
              ? displayMoney(report.receivedForHimself)
              : ''
          }
        />
      );
    },
  },
  receivedForHolistic: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          shouldUpdate={(prev, curr) =>
            prev.receivedFinancialSupport !== curr.receivedFinancialSupport
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('receivedFinancialSupport') && (
              <Form.Item
                label="How much money (in USD) have you received from AIMS in the last 3 months for holistic projects?"
                name="receivedForHolistic"
              >
                <InputNumber
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  disabled={loading}
                  min={0}
                  step={0.01}
                  style={{ width: 200 }}
                />
              </Form.Item>
            )
          }
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      if (!report.receivedFinancialSupport) {
        return null;
      }
      return (
        <ReportLineItem
          question="How much money (in USD) have you received from AIMS in the last 3 months for holistic projects?"
          answer={
            report.receivedForHolistic != null
              ? displayMoney(report.receivedForHolistic)
              : ''
          }
        />
      );
    },
  },
  useOfHolisticFundsDesc: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          shouldUpdate={(prev, curr) =>
            prev.receivedFinancialSupport !== curr.receivedFinancialSupport
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue('receivedFinancialSupport') && (
              <Form.Item
                shouldUpdate={(prev, curr) =>
                  prev.receivedForHolistic !== curr.receivedForHolistic
                }
                noStyle
              >
                {({ getFieldValue: _getFieldValue }) => {
                  const val = _getFieldValue('receivedForHolistic');
                  if (val && Number(val) > 0) {
                    return (
                      <Form.Item
                        label="Please explain how the finances designated for holistic projects will be or have been used."
                        name="useOfHolisticFundsDesc"
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                      >
                        <Input.TextArea rows={4} disabled={loading} />
                      </Form.Item>
                    );
                  }
                  return null;
                }}
              </Form.Item>
            )
          }
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      if (!report.receivedFinancialSupport) {
        return null;
      }
      return (
        <ReportLineItem
          question="Please explain how the finances designated for holistic projects will be or have been used."
          answer={report.useOfHolisticFundsDesc}
        />
      );
    },
  },
  receivedTotal: {
    finish: (values) => {
      return {
        receivedTotal:
          (values.receivedForHimself || 0) + (values.receivedForHolistic || 0),
      };
    },
    Display: function Display({ report }) {
      if (!report.receivedFinancialSupport) {
        return null;
      }
      return (
        <ReportLineItem
          question="Total money (in USD) received:"
          answer={
            report.receivedTotal != null
              ? displayMoney(report.receivedTotal)
              : ''
          }
        />
      );
    },
  },
  churchesPlanted: reportChurchesPlanted,
  numHeardForFirstTime: reportNumHeardForFirstTime,
  numSalvations: reportNumSalvations,
  numWaterBaptisms: reportNumWaterBaptisms,
  resultNumbers: reportResultNumbers,
  miracles: reportMiracles,
  prayerRequests: reportPrayerRequests,
  publicFiles: {
    ...reportPublicFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'PG_QUARTERLY',
      });
    },
  },
  privateFiles: {
    ...reportPrivateFiles,
    FormItem: function FormItem(props) {
      return reportPublicFiles.FormItem({
        ...props,
        reportType: 'PG_QUARTERLY',
      });
    },
  },
  notes: reportNotes,
};
