import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Divider, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useIsCreating from '../../../shared/use-is-creating';
import DeletePeopleGroupModal from '../DeletePeopleGroupModal';
import useLongUpg from '../ViewPeopleGroup/use-long-upg';
import EditPeopleGroupForm from './EditPeopleGroupForm';

const queryId = 'editPeopleGroup';

function EditPeopleGroup() {
  const history = useHistory();
  const location = useLocation();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(null);

  const { _id } = useParams();
  const { upg: peopleGroup, loading } = useLongUpg({
    _id,
    queryId,
  });

  const handleBack = useCallback(
    () =>
      history.push((location.state && location.state.from) || '/people-groups'),
    [history, location],
  );

  const { title, showDelete } = useIsCreating(
    location,
    peopleGroup,
    loading,
    'Create a People Group',
    'Edit People Group',
  );

  return (
    <CSPage title="Edit People Group">
      <CSPageHeader
        title={title}
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          >
            {location.state && location.state.from.includes('view')
              ? 'Back to People Group'
              : 'Back to People Groups'}
          </Button>,
        ]}
      />
      <EditPeopleGroupForm
        _id={_id}
        handleCancel={handleBack}
        handleSuccess={() => history.push(`/people-group/view/${_id}/info`)}
        peopleGroup={peopleGroup}
        saving={saving}
        setSaving={setSaving}
        style={{ maxWidth: 500 }}
        queryId={queryId}
      />
      {showDelete && (
        <div style={{ maxWidth: 500 }}>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => setDeleting(peopleGroup)}
              icon={<DeleteOutlined />}
              danger
              ghost
            >
              Delete People Group
            </Button>
          </div>
        </div>
      )}
      <div style={{ minHeight: 300 }} />
      <DeletePeopleGroupModal
        setPeopleGroup={setDeleting}
        peopleGroup={deleting}
      />
    </CSPage>
  );
}

export default EditPeopleGroup;
