import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';

const { Paragraph, Text } = Typography;

const deleteHpInstallmentMutation = gql`
  mutation DeleteHpInstallment($_id: ID!) {
    deleteHpInstallment(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteInstallmentsModal({ visible, onCancel, onFinish }) {
  const { installments } = visible || {};
  const [loading, setLoading] = useState(false);
  const [deleteHpInstallment] = useMutation(deleteHpInstallmentMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await Promise.all(
        installments.map(async (installment) => {
          await deleteHpInstallment({
            variables: {
              _id: installment._id,
            },
          });
        }),
      );

      notification.success({
        message: 'Deleted',
        description: 'Installment(s) deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [installments, deleteHpInstallment, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Installment(s)"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      {installments?.length === 1 ? (
        <Paragraph>
          Are you sure you want to delete this payment installment?
        </Paragraph>
      ) : (
        <Paragraph>{`Are you sure you want to delete these ${installments?.length} payment installments?`}</Paragraph>
      )}
    </YesNoModalJ10>
  );
}

export default DeleteInstallmentsModal;
