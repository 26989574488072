import React, { useCallback } from 'react';
import YesNoModal from '../../components/YesNoModal';
import { useMutation, gql } from '@apollo/client';
import { deleteEventAction } from '../../redux-store/events-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';

const deleteEventMutation = gql`
  mutation deleteEvent($_id: ID!) {
    deleteEvent(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteEventModal({ event, setEvent, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteEvent] = useMutation(deleteEventMutation);
  const dispatch = useDispatch();
  const history = useHistory();

  const onYes = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteEvent({
        variables: {
          _id: event._id,
        },
      });
      dispatch(deleteEventAction(event._id));
      notification.success({
        message: 'Deleted',
        description: 'Event deleted successfully',
      });
      setTimeout(() => {
        setEvent(null);
        if (onComplete) {
          onComplete();
        } else {
          history.goBack();
        }
      }, 1000);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [event, deleteEvent, setEvent, dispatch, history, onComplete]);

  const onNo = useCallback(() => {
    if (!loading) {
      setEvent(null);
    }
  }, [setEvent, loading]);

  return (
    <YesNoModal
      title="Delete Event"
      question={`Are you sure you want to delete this event (${
        event && event.title
      })?`}
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!event}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteEventModal;
