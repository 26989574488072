import { RedoOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  ConfigProvider,
  notification,
  Timeline,
  Typography,
} from 'antd';
import React, { useCallback, useState } from 'react';
import InitializeAccountingBtn from '../AccountingTab/InitializeAccountingBtn';

const { Title } = Typography;
const auditHolisticProjectMutation = gql`
  mutation AuditHolisticProject($_id: ID!) {
    auditHolisticProject(_id: $_id)
  }
`;

function ProjectChecklist({ project, next, onMoveTo, items, allDone }) {
  const [loading, setLoading] = useState(false);
  const [auditHolisticProject] = useMutation(auditHolisticProjectMutation);

  const onRefresh = useCallback(async () => {
    setLoading(true);
    try {
      await auditHolisticProject({
        variables: {
          _id: project._id,
        },
      });
      notification.success({
        message: 'Success',
        description: 'Holistic project updated.',
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setLoading(false);
  }, [auditHolisticProject, project]);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 16, alignItems: 'end' }}>
        <Title level={3} style={{ marginBottom: 0 }}>{`Checklist`}</Title>
        <div style={{ flex: 1 }} />
        <Button loading={loading} icon={<RedoOutlined />} onClick={onRefresh}>
          Audit Project
        </Button>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Timeline: {
              dotBg: 'none',
            },
          },
        }}
      >
        <Card
          style={{ marginBottom: 32 }}
          styles={{ body: { paddingBottom: 16 } }}
        >
          <Timeline items={items} />
        </Card>
      </ConfigProvider>
      <InitializeAccountingBtn project={project} />
      <style jsx global>{`
        .ant-timeline .ant-timeline-item-last > .ant-timeline-item-content {
          min-height: unset;
        }
      `}</style>
    </>
  );
}

export default ProjectChecklist;
