import { PauseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { reviewTrainingEventReportMutation } from '../constants';

const { Title, Paragraph } = Typography;

const PutTrainEventOnHoldBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewTrainingEventReport] = useMutation(
    reviewTrainingEventReportMutation,
  );

  const handleFinish = useCallback(async () => {
    setLoading(true);
    try {
      await reviewTrainingEventReport({
        variables: {
          reportId: report._id,
          status: 'HOLD',
        },
      });
      notification.success({
        message: 'Saved',
        description: 'Report placed on hold',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [reviewTrainingEventReport, report, onSuccess]);

  return (
    <>
      <Button
        loading={loading}
        icon={<PauseOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Hold Report
      </Button>

      <Modal
        header={null}
        footer={null}
        visible={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 48 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 32 }}>
          Put Report on Hold
        </Title>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item>
            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
              {`You're about to put this training event report on hold.  The submitter will not be notified.`}
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Put report on Hold
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default PutTrainEventOnHoldBtn;
