import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import DateFormItem from '@aims/shared/components/DateFormItem';
import SimpleSelectStringTagsA10 from '../../components/SimpleSelectStringTagsA10';
import { createUpgPrayerAdoptionAction } from '../../redux-store/upg-prayer-adoptions-store';
import SelectAdoption from '../adoptions/SelectAdoption';
import SelectContact from '../contacts/SelectContact';
import SelectPeopleGroup from '@aims/shared/people-groups/SelectPeopleGroup';
import { createUpgPrayerAdoptionMutation } from './constants';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';

const { Text, Title, Paragraph } = Typography;

function CreateUpgPrayerAdoptionForm({ loading, handleSave }) {
  const [form] = Form.useForm();
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        style={{ maxWidth: 500, minWidth: 300 }}
        onFinish={handleSave}
      >
        <Form.Item
          name="contactId"
          label="Contact"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectContact placeholder="Select ..." disabled={loading} />
        </Form.Item>
        <Form.Item
          name="peopleGroupId"
          label="People Group"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <SelectPeopleGroup placeholder="Select ..." disabled={loading} />
        </Form.Item>
        <Form.Item name="adoptionId" label="Adoption" rules={[]}>
          <SelectAdoption placeholder="Select ..." disabled={loading} />
        </Form.Item>
        <DateFormItem
          label="Start Date"
          name="startDate"
          rules={[
            {
              required: true,
              message: 'Please select a start date',
            },
          ]}
          disabled={loading}
        />
        <Form.Item label="Tags" name="tags">
          <SimpleSelectStringTagsA10 disabled={loading} />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea disabled={loading} rows={4} />
        </Form.Item>
        <ShowErrorsFormItem />
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              htmlType="submit"
              icon={<ArrowRightOutlined />}
              loading={loading}
            >
              Create UPG Prayer Adoption
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

const CreateUpgPrayerAdoptionModal = ({ visible, onFinish, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [createUpgPrayerAdoption] = useMutation(
    createUpgPrayerAdoptionMutation,
  );

  const handleSave = useCallback(
    async (values) => {
      setLoading(true);
      setError(undefined);
      try {
        const createId = `${values.contactId}.${values.peopleGroupId}`;
        const result = await createUpgPrayerAdoption({
          variables: {
            upgPrayerAdoption: {
              _id: createId,
              ...values,
            },
          },
        });
        dispatch(
          createUpgPrayerAdoptionAction(
            result.data.createUpgPrayerAdoption.upgPrayerAdoption,
            'view-upg-prayer-adoption',
          ),
        );
        notification.success({
          message: 'Saved',
          description: 'UPG Prayer Adoption created successfully',
        });
        onFinish(createId);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [dispatch, createUpgPrayerAdoption, onFinish],
  );

  return (
    <>
      <Modal
        header={null}
        open={visible}
        closable
        destroyOnClose={true}
        maskClosable
        footer={null}
        onCancel={onCancel}
        styles={{
          body: {
            paddingTop: 32,
          },
        }}
      >
        <Title level={3} style={{ marginBottom: 4 }}>
          Create a UPG Prayer Adoption
        </Title>
        {visible && (
          <CreateUpgPrayerAdoptionForm
            handleSave={handleSave}
            loading={loading}
          />
        )}
        {error && (
          <div className="errors">
            <Text type="danger">{error}</Text>
          </div>
        )}
      </Modal>
      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default CreateUpgPrayerAdoptionModal;
