import React, { useMemo } from 'react';
import useProfile from '../../shared/use-profile';
// import AnyEvents from '../events/AnyEvents';

const UserEvents = () => {
  const profile = useProfile();

  const currentUserId = profile._id;
  const startsAfter = useMemo(() => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  }, []);

  return (
    <div>
      <div className="top-actions">
        {/* <AnyEvents
          filters={{
            subscriberId: currentUserId,
            startsAfter,
          }}
          title={'My Upcoming Events'}
          subtitle="Any upcominig events that you're subscribed to."
          showPreviousButton={false}
          hideAddButton={true}
          gridColumns={{ column: 3, gutter: 16 }}
        /> */}
      </div>
    </div>
  );
};

export default UserEvents;
