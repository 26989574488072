import React from 'react';
import { Typography } from 'antd';
import Version from './Version';

const { Text } = Typography;

function Footer({ style = {} }) {
  return (
    <div className="footer" style={style}>
      <Text>AIMS ©2021</Text>
      <Version />
      <style jsx>{`
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 55px;
          padding-right: 55px;
          padding-top: 16px;
          padding-bottom: 16px;
        }
        @media screen and (max-width: 600px) {
          .footer {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      `}</style>
    </div>
  );
}

export default Footer;
