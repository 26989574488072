import React, { useCallback } from 'react';
import YesNoModal from '../../../../components/YesNoModal';

function ContactDeleteAddressInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  address: oldAddress,
}) {
  const _handleFinish = useCallback(async () => {
    const existingAddresses = (contact.addresses || [])
      .filter((e) => e._id !== oldAddress._id)
      .reduce((prev, curr) => {
        const {
          _id,
          label,
          address1,
          address2,
          city,
          state,
          zipCode,
          country,
        } = curr;
        prev.push({
          _id,
          label,
          address1,
          address2,
          city,
          state,
          zipCode,
          country,
        });
        return prev;
      }, []);
    const existingPrimaryId = contact.primaryAddressId;
    const primaryAddress = existingAddresses.find(
      (e) => e._id === existingPrimaryId,
    );
    let newPrimaryId = null;
    if (primaryAddress) {
      newPrimaryId = primaryAddress._id;
    } else if (!primaryAddress && existingAddresses.length > 0) {
      newPrimaryId = existingAddresses[0]._id;
    }
    handleFinish({
      addresses: existingAddresses,
      primaryAddressId: newPrimaryId,
    });
  }, [handleFinish, contact, oldAddress]);
  return (
    <YesNoModal
      title="Delete Address from Contact"
      question={`Are you sure you want to delete this address ${oldAddress?.label}?`}
      yesText="Delete"
      noText="Cancel"
      onYes={_handleFinish}
      onNo={onCancel}
      visible={!!contact}
      loading={saving}
    />
  );
}

export default ContactDeleteAddressInnerModalA10;
