import { displayMoney, getNumberValue } from '../shared/utils';

export function getLoanSummary(locale, project) {
  if (project.loanTotal && project.loanTerm && project.loanStartDate) {
    return `${displayMoney(
      getNumberValue(project.loanTotal),
    )} for ${getNumberValue(
      project.loanTerm,
    )} months starting ${new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
    }).format(new Date(project.loanStartDate))}`;
  }
  if (project.loanTotal && project.loanTerm && project.loanStartDate) {
    return `${displayMoney(
      getNumberValue(project.loanTotal),
    )} for ${getNumberValue(project.loanTerm)} months`;
  }
  return null;
}
