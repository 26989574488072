import { useCallback, useEffect, useRef, useState } from 'react';
import settings from '../settings';

function useMyGoogleLogin(onSuccess, onFailure) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const googleAuth = useRef();

  useEffect(() => {
    if (!window.gapi) {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    } else {
      setScriptLoaded(true);
    }
  }, []);

  const doLogin = useCallback(
    (scopes) => {
      const initClient = () => {
        gapi.client
          .init({
            apiKey: 'AIzaSyAH3miFgBTCWwplpKuA3wExUK6FD4Nwa5M',
            clientId: settings.googleOAuthClientId,
            scope: scopes,
          })
          .then(function () {
            googleAuth.current = gapi.auth2.getAuthInstance();
            googleAuth.current.isSignedIn.listen((response) => {
              console.log('isSignedIn', response);
            });
            googleAuth.current.grantOfflineAccess({}).then(
              (res) => onSuccess(res),
              (err) => onFailure(err),
            );
          });
      };
      gapi.load('client:auth2', initClient);
    },
    [onSuccess, onFailure],
  );
  return scriptLoaded ? doLogin : undefined;
}

export default useMyGoogleLogin;
