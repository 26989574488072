import PeopleGroupPieChart from '@aims/shared/people-groups/PeopleGroupPieChart';
import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Typography } from 'antd';
import React, { useMemo } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ContentEditableInput from '../../../components/ContentEditableInput';
import { BibleStatusLabels } from '../ListPeopleGroups/SelectBibleStatus';
import ConnectedUsers from './ConnectedUsers';

const { Title, Text } = Typography;

function PeopleGroupInfo({ peopleGroup }) {
  const [summary, jpSummary] = useMemo(() => {
    if (peopleGroup && peopleGroup.summaryText) {
      return [peopleGroup.summary, undefined];
    }
    return [undefined, peopleGroup && peopleGroup.jpSummary];
  }, [peopleGroup]);
  return (
    <div>
      {peopleGroup && (
        <>
          <div className="info">
            <div className="info-item">
              <Title level={5}>Primary Language</Title>
              {peopleGroup.primaryLanguage}
            </div>
            <div className="info-item">
              <Title level={5}>Official Language</Title>
              {peopleGroup.officialLanguage}
            </div>
            <div className="info-item">
              <Title level={5}>Primary Religion</Title>
              {peopleGroup.primaryReligion}
            </div>
            {peopleGroup.religionSubdivision && (
              <div className="info-item">
                <Title level={5}>Relgion Subdivision</Title>
                {peopleGroup.religionSubdivision}
              </div>
            )}
            <div className="info-item">
              <Title level={5}>Bible Status</Title>
              {BibleStatusLabels[peopleGroup.bibleStatus] &&
                BibleStatusLabels[peopleGroup.bibleStatus].label}
            </div>
          </div>
          <Title level={3}>People Group Status</Title>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) =>
              isVisible ? (
                <PeopleGroupPieChart peopleGroup={peopleGroup} />
              ) : (
                <div style={{ height: 400, marginBottom: 32 }} />
              )
            }
          </VisibilitySensor>
          {(summary || jpSummary) && (
            <div className="info">
              <div className="info-item">
                <Title level={5}>Summary</Title>
                {summary ? (
                  <Form>
                    <Form.Item noStyle>
                      <ContentEditableInput
                        readOnly
                        value={summary}
                        readOnlyBackground={false}
                      />
                    </Form.Item>
                  </Form>
                ) : (
                  jpSummary
                )}
              </div>
            </div>
          )}
          {peopleGroup.cumulativeStats && (
            <div className="info">
              <div className="info-item">
                <Title level={5}>Churches planted</Title>
                {peopleGroup.cumulativeStats.numChurchesPlanted}
              </div>
              <div className="info-item">
                <Title level={5}>Heard the gospel for the first time</Title>
                {peopleGroup.cumulativeStats.numHeardForFirstTime}
              </div>
              <div className="info-item">
                <Title level={5}>Salvations</Title>
                {peopleGroup.cumulativeStats.numSalvations}
              </div>
              <div className="info-item">
                <Title level={5}>Water Baptisms</Title>
                {peopleGroup.cumulativeStats.numWaterBaptisms}
              </div>
            </div>
          )}
          <Title level={3}>Connected Users</Title>
          <ConnectedUsers peopleGroupId={peopleGroup._id} />
        </>
      )}

      <style jsx>{`
        .info {
          background: white;
          border-radius: 4px;
          border: 1px solid ${sharedSettings.colors.borderGray};
          padding: 24px;
          margin-bottom: 32px;
          display: flex;
          flex-wrap: wrap;
        }
        .info-item {
          margin-bottom: 16px;
          margin-right: 16px;
          margin-left: 16px;
          min-width: calc(50% - 32px);
        }
      `}</style>
    </div>
  );
}

export default PeopleGroupInfo;
