import React, { useCallback, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import settings from '../../settings';
import { Button, Form, Space, Table, Tooltip, Typography } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { displayMoney } from '@aims/shared/shared/utils';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import FilterHolisticProjects from '../holistic-projects/FilterHolisticProjects';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { ReportApprovalStatuses } from '@aims/shared/reports/constants';

const allHolisticProjectProposalsQuery = gql`
  query AllHolisticProposalsForAdmin(
    $first: Int
    $after: String
    $filters: HolisticProposalFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allHolisticProposalsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          peopleGroupIds
          regionCode
          holisticProjectId
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser {
            _id
            name
          }
          submittedByUser {
            _id
            name
          }
          updatedBy
          createdAt
          updatedAt
          updatedByUser {
            _id
            name
          }
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          humanReadableId
          projectName
          typeOfProject
          loanTotalUsd
        }
      }
    }
  }
`;

const extractHolisticProjectProposals = (data) =>
  data && data.allHolisticProposalsForAdmin;

const { Text } = Typography;

const queryId = 'holisticProposalsTab';

function HolisticProposalsList({ reportStatus, peopleGroupId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const location = useLocation();

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({
    status: reportStatus,
    peopleGroupId,
    humanReadableId: -1,
  });

  const {
    error,
    loading,
    data: reports,
    search: reportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allHolisticProjectProposalsQuery,
    extract: extractHolisticProjectProposals,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((pages, _, sorters) => {
    setPagination({
      ...pages,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);
  return (
    <>
      <div>
        <Form layout="vertical">
          <FilterHolisticProjects
            filters={filters}
            refetch={refetch}
            reportSearch={reportSearch}
            loading={loading}
            sortBy={sortBy}
            type={'holisticProposal'}
          />
        </Form>
      </div>
      <Table
        dataSource={reports}
        loading={!reports.length && loading}
        pagination={{ ...pagination, total: reports.length }}
        onChange={handleTableChange}
        // style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
      >
        {reportStatus !== 'DRAFT' && (
          <Table.Column
            title="ID"
            key="humanReadableId"
            render={(text, record) => record.humanReadableId || 'N/A'}
          />
        )}
        <Table.Column
          title="Project Name"
          key="projectName"
          render={(text, record) => {
            return record.projectName;
          }}
        />
        <Table.Column
          title="Type of Project"
          key="typeOfProject"
          render={(text, record) => {
            return HolisticProjectTypes[record.typeOfProject]?.label;
          }}
        />
        <Table.Column
          title="Regional Coordinator"
          key="contactId"
          render={(text, record) => {
            return (
              record.contact && (
                <Link to={`contact/view/${record.contact._id}`}>
                  {record.contact.fullName}
                </Link>
              )
            );
          }}
        />
        <Table.Column
          title="People Groups"
          key="peopleGroupIds"
          render={(text, record) => {
            return (
              record.peopleGroupIds &&
              record.peopleGroupIds.map((pgId) => (
                <Link
                  key={pgId}
                  to={`/people-group/view/${pgId}/info`}
                  style={{ marginRight: 8 }}
                >
                  {pgId}
                </Link>
              ))
            );
          }}
        />
        <Table.Column
          title="Status"
          key="status"
          width={140}
          render={(text, record) => {
            const status = ReportApprovalStatuses[record.status];
            return (
              status && (
                <Text
                  style={{
                    color: status.color,
                    fontWeight: 600,
                  }}
                >
                  {status.label}
                </Text>
              )
            );
          }}
        />
        <Table.Column
          title="Last Update"
          key="lastUpdate"
          width={200}
          render={(text, record) => {
            if (
              record.status === ReportApprovalStatuses.APPROVED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Approved on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.DRAFT.key) {
              return (
                <>
                  {`Updated on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.updatedAt))}`}
                  {record.updatedByUser && (
                    <>
                      <br />
                      {`by ${record.updatedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.REJECTED.key &&
              record.approval
            ) {
              return (
                <>
                  {`Rejected on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.approval.approver && (
                    <>
                      <br />
                      {`by ${record.approval.approver.name}`}
                    </>
                  )}
                </>
              );
            }
            if (record.status === ReportApprovalStatuses.PENDING.key) {
              return (
                <>
                  {`Submitted on ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.submittedAt))}`}
                  {record.submittedByUser && (
                    <>
                      <br />
                      {`by ${record.submittedByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            if (
              record.status === ReportApprovalStatuses.HOLD.key &&
              record.approval
            ) {
              return (
                <>
                  {`Put on hold ${new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                  }).format(new Date(record.approval.when))}`}
                  {record.createdByUser && (
                    <>
                      <br />
                      {`by ${record.createdByUser.name}`}
                    </>
                  )}
                </>
              );
            }
            return null;
          }}
        />
        <Table.Column
          title="Loan Amount"
          key="loanTotalUsd"
          render={(text, record) => {
            return (
              record.loanTotalUsd !== undefined &&
              displayMoney(record.loanTotalUsd)
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={`/holistic-proposals/review/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              <Tooltip title="Edit">
                <Link
                  to={`/holistic-proposals/edit/${record._id}?from=${location.pathname}`}
                >
                  <Button icon={<EditOutlined />} />
                </Link>
              </Tooltip>
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <style jsx>{`
        .bottom-actions {
          text-align: center;
        }
      `}</style>
    </>
  );
}

export default HolisticProposalsList;
