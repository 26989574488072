import DateFormItem from '@aims/shared/components/DateFormItem';
import {
  displayMoney,
  generateUuid,
  getNumberValue,
} from '@aims/shared/shared/utils';
import { PlusOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  Typography,
} from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import TaskSomethingForm from '../../../../tasks/ViewTaskModal/TaskSomethingForm';

const { Text, Title, Paragraph } = Typography;

export const createHpInstallmentMutation = gql`
  mutation CreateHpInstallment($hpInstallment: HpInstallmentCreateInput!) {
    createHpInstallment(hpInstallment: $hpInstallment)
  }
`;

const AddInstallmentBtn = ({ project, uninstalled }) => {
  const [saving, setSaving] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [createHpInstallment] = useMutation(createHpInstallmentMutation);
  const [form] = Form.useForm();

  const handleFinish = useCallback(
    async (_values) => {
      setSaving(true);
      const { repeats, often, times, amount, dueAt } = _values;
      try {
        const installments = [
          {
            _id: generateUuid(),
            projectId: project._id,
            amount,
            dueAt,
          },
        ];
        if (repeats && times && times > 1) {
          let startDate = new Date(dueAt);
          for (let i = 0; i < times - 1; i += 1) {
            if (often === 'monthly') {
              startDate.setMonth(startDate.getMonth() + 1);
            } else if (often === 'yearly') {
              startDate.setFullYear(startDate.getFullYear() + 1);
            }
            installments.push({
              _id: generateUuid(),
              projectId: project._id,
              amount,
              dueAt: startDate.toISOString(),
            });
          }
        }

        await Promise.all(
          installments.map(async (installment) => {
            await createHpInstallment({
              variables: {
                hpInstallment: installment,
              },
            });
          }),
        );

        notification.success({
          message: 'Saved',
          description: 'Installment(s) created.',
        });
        setShowingModal(null);
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setSaving(false);
    },
    [createHpInstallment, project],
  );

  const amount = Form.useWatch('amount', form);
  const repeats = Form.useWatch('repeats', form);
  const times = Form.useWatch('times', form);
  const remaining = useMemo(() => {
    if (repeats && times && times > 1) {
      return uninstalled - (amount || 0) * times;
    }
    return uninstalled - (amount || 0);
  }, [repeats, times, amount, uninstalled]);

  return (
    <>
      {!project.paymentPlanLocked && (
        <>
          <Button
            loading={saving}
            icon={<PlusOutlined />}
            onClick={() => setShowingModal(true)}
          >
            Add Installment
          </Button>
        </>
      )}

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!saving}
        destroyOnClose={true}
        maskClosable={!saving}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 16 } }}
      >
        <TaskSomethingForm
          title="Create Payment Installment"
          form={form}
          onFinish={handleFinish}
          onCancel={() => setShowingModal(false)}
          saving={saving}
          saveText="Yes"
        >
          <Paragraph
            style={{ marginBottom: 0 }}
          >{`Payback total: ${displayMoney(
            getNumberValue(project.paybackTotalX4) / 10000,
          )}`}</Paragraph>
          <Paragraph>{`Remaining: ${displayMoney(
            remaining / 10000,
          )}`}</Paragraph>
          <Form.Item
            label="Amount (USD)"
            name="amount"
            initialValue={0}
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <DollarX4InputNumber disabled={saving} />
          </Form.Item>
          <DateFormItem
            name="dueAt"
            label="Due Date"
            rules={[{ required: true, message: 'This field is required' }]}
            disabled={saving}
          />
          <Form.Item
            name="repeats"
            label="Does this payment repeat?"
            initialValue={false}
          >
            <Radio.Group disabled={saving}>
              <Radio value={false}>No</Radio>
              <Radio value={true}>Yes</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.repeats !== curValues.repeats
            }
            noStyle
          >
            {({ getFieldValue }) =>
              getFieldValue('repeats') && (
                <>
                  <Form.Item
                    name="often"
                    label="How often?"
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Radio.Group disabled={saving}>
                      <Radio value="monthly">Monthly</Radio>
                      <Radio value="yearly">Yearly</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="times"
                    label="How many times?"
                    initialValue={1}
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <InputNumber min={0} step={1} disabled={saving} />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <Input.TextArea disabled={saving} />
          </Form.Item>
        </TaskSomethingForm>
      </Modal>
    </>
  );
};

export default AddInstallmentBtn;
