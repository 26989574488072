import { ExportOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, message, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import useProfile from '../../shared/use-profile';
import { exportContactsMutation } from './constants';
import useExportContactsResult from './use-export-contacts-result';

function ExportContactsBtn({ filters }) {
  const [listening, setListening] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportContacts] = useMutation(exportContactsMutation);
  const profile = useProfile();
  const result = useExportContactsResult(!profile || !listening);
  const handleExportContacts = useCallback(async () => {
    setLoading(true);
    try {
      await exportContacts({
        variables: {
          filters: filters.current,
        },
      });
      message.loading('Exporting ...', 0);
      setListening(true);
    } catch (err) {
      console.error(err);
      message.error('There was an error trying to export contacts');
    }
    setLoading(false);
  }, [filters, exportContacts]);

  useEffect(() => {
    if (result) {
      message.destroy();
      if (result.success) {
        notification.success({
          message: (
            <>
              <div>Your contacts have exported successfully</div>
              <a href={result.file.url}>Click here to download</a>
            </>
          ),
          duration: 0,
        });
      } else {
        message.error(result.error);
      }
      setListening(false);
    }
  }, [result]);
  return (
    <Button
      onClick={handleExportContacts}
      type="link"
      icon={<ExportOutlined />}
      size="large"
      loading={loading}
    >
      Export Contacts
    </Button>
  );
}

export default ExportContactsBtn;
