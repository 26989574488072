import { gql, useMutation } from '@apollo/client';
import { Typography, notification } from 'antd';
import React, { useCallback, useState } from 'react';
import { AccountTypesJ25 } from './constants';
import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';

const { Paragraph, Text } = Typography;

const deleteAccountMutation = gql`
  mutation DeleteAccount($_id: ID!) {
    deleteAccount(_id: $_id) {
      deletedId
    }
  }
`;

function DeleteAccountModal({ visible, onCancel, onFinish }) {
  const account = visible;
  const [loading, setLoading] = useState(false);
  const [deleteAccount] = useMutation(deleteAccountMutation);

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await deleteAccount({
        variables: {
          _id: account._id,
        },
      });
      notification.success({
        message: 'Deleted',
        description: 'Account deleted successfully',
      });
      onFinish?.();
    } catch (err) {
      console.error(err);
      if (err.message.includes('Account balance must be zero')) {
        notification.error({
          message: 'Error',
          description: err.message,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
    }
    setLoading(false);
  }, [account, deleteAccount, onFinish]);

  return (
    <YesNoModalJ10
      title="Delete Account"
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onCancel}
      visible={visible}
      loading={loading}
    >
      <Paragraph>Are you sure you want to delete this account?</Paragraph>
      <Paragraph>
        {AccountTypesJ25[account?.type]?.label}
        <br />
        <Text style={{ fontWeight: 600 }}>{account?.name}</Text>
        <br />
        {account?.description}
      </Paragraph>
    </YesNoModalJ10>
  );
}

export default DeleteAccountModal;
