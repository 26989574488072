import sharedSettings from '@aims/shared/sharedSettings';
import { Form, Input, Radio } from 'antd';
import React from 'react';
import { generateUuid } from '../shared/utils';

function EmailFormItem({ field, loading, primary, setPrimary }) {
  return (
    <div className="email">
      <Form.Item
        name={[field.name, '_id']}
        initialValue={generateUuid()}
        noStyle
      >
        <Input hidden />
      </Form.Item>
      <Form.Item
        name={[field.name, 'label']}
        label="Label"
        initialValue={`Email ${field.name + 1}`}
        rules={[
          { required: true, message: 'Please enter a label for this email' },
        ]}
      >
        <Input autoComplete="chrome-off" disabled={loading} />
      </Form.Item>
      <Form.Item
        name={[field.name, 'email']}
        label="Email"
        rules={[
          { required: true, message: 'Please enter an email address' },
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Radio.Group value={primary} onChange={(e) => setPrimary(field.key)}>
        <Radio
          disabled={loading}
          value={field.key}
          style={{ marginBottom: 16 }}
        >
          Primary Email
        </Radio>
      </Radio.Group>
      <style jsx>{`
        .email {
          border: 1px solid ${sharedSettings.colors.borderGray};
          border-radius: 4px;
          padding 16px;
          flex: 1;
        }
      `}</style>
    </div>
  );
}

export default EmailFormItem;
