import sharedSettings from '@aims/shared/sharedSettings';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button, Divider, Form, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import SelectContact from '../../contacts/SelectContact';
import { contactForAdminQuery } from '../../contacts/ViewContact/constants';
import DisplayContactA10 from './DisplayContactA10';
import DisplayPayerA10 from './DisplayPayerA10';
import {
  associateContactWithPpPayerMutation,
  createContactFromPpPayerMutation,
  updateContactFromPpPayerMutation,
} from './constants';

const { Text, Title } = Typography;

function AdmContactPageA10({
  setPage,
  payment,
  recurringPayment,
  payer,
  contact,
  setContact,
  adoptionItems,
  isRecurring,
}) {
  const client = useApolloClient();
  const [loading, setLoading] = useState();
  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      if (payer.contactId) {
        try {
          let resp = await client.query({
            query: contactForAdminQuery,
            variables: {
              _id: payer.contactId,
            },
          });
          if (resp?.data?.contactForAdmin) {
            setContact(resp.data.contactForAdmin);
          }
        } catch (err) {
          console.error(err);
          notification.error({
            message: 'Error',
            description: 'There was an error fetching contact',
          });
        }
      } else {
        setContact(undefined);
      }
      setLoading(false);
    }
    if (payer) {
      doAsyncStuff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, payment, payer]);

  const [form] = Form.useForm();
  const [saving, setSaving] = useState();

  const handleContinue = useCallback(() => {
    if (
      (!isRecurring &&
        payment?.recurringPaymentToken &&
        recurringPayment?.adoptions?.length > 0 &&
        adoptionItems?.length > 0) ||
      (!isRecurring &&
        payment.suggestedAllocations?.length > 0 &&
        adoptionItems?.length > 0)
    ) {
      setPage('existing');
    } else if (adoptionItems && adoptionItems.length > 0) {
      setPage('summary');
    } else {
      setPage('sponsorship');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adoptionItems]);

  const [associateContactWithPpPayer] = useMutation(
    associateContactWithPpPayerMutation,
  );
  const onAssociateContact = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { contactId } = _values;
        const resp = await associateContactWithPpPayer({
          variables: {
            payerId: payer._id,
            contactId,
          },
        });
        const _contact = resp?.data?.associateContactWithPpPayer?.contact;
        if (!_contact) {
          throw new Error('Missing contact in response');
        }
        setContact(_contact);
        setPage('sponsorship');
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error associating contact with payer',
        });
      }
      setSaving(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [associateContactWithPpPayer, payer],
  );

  const [createContactFromPpPayer] = useMutation(
    createContactFromPpPayerMutation,
  );
  const onCreateContact = useCallback(async () => {
    setSaving(true);
    try {
      const resp = await createContactFromPpPayer({
        variables: {
          payerId: payer._id,
        },
      });
      const _contact = resp?.data?.createContactFromPpPayer?.contact;
      if (!_contact) {
        throw new Error('Missing contact in response');
      }
      setContact(_contact);
      setPage('sponsorship');
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error creating contact from payer',
      });
    }
    setSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createContactFromPpPayer, payer]);

  const [updateContactFromPpPayer] = useMutation(
    updateContactFromPpPayerMutation,
  );
  const onUpdateContact = useCallback(async () => {
    setSaving(true);
    try {
      const resp = await updateContactFromPpPayer({
        variables: {
          contactId: contact._id,
          payerId: payer._id,
        },
      });
      const _contact = resp?.data?.updateContactFromPpPayer?.contact;
      if (!_contact) {
        throw new Error('Missing contact in response');
      }
      setContact(_contact);
      setPage('sponsorship');
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'There was an error updating contact from payer',
      });
    }
    setSaving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactFromPpPayer, payer, contact]);

  return (
    <div style={{ textAlign: 'center' }}>
      <Title level={3} style={{ marginBottom: 0 }}>
        Associate Donation with Sponsorship(s)
      </Title>
      <Title
        level={5}
        style={{
          marginBottom: 16,
          marginTop: 0,
          color: sharedSettings.colors.text,
        }}
      >
        Associate Payer with Contact
      </Title>
      <div style={{ marginBottom: 16 }}>
        <DisplayPayerA10 payer={payer} />
      </div>
      {contact ? (
        <>
          <Text>{`This payment's payer is already associated with the following contact:`}</Text>
          <div style={{ marginTop: 8, marginBottom: 0 }}>
            <Link to={`/contact/view/${contact._id}/dashboard`}>
              <DisplayContactA10 contact={contact} />
            </Link>
          </div>
          <div>
            <Button loading={saving} onClick={handleContinue} type="primary">
              {!payment && !recurringPayment ? 'Close' : 'Continue'}
            </Button>
          </div>
          <Divider>Or</Divider>
          <div style={{ marginBottom: 16 }}>
            <Text>{`Update contacts email, address & phone number from PushPay Payer details.`}</Text>
          </div>
          <div>
            <Button loading={saving} onClick={onUpdateContact}>
              {!payment && !recurringPayment ? 'Update' : 'Update & Continue'}
            </Button>
          </div>
          <Divider>Or</Divider>
          <div>
            <Button
              loading={saving}
              onClick={() => setContact(undefined)}
            >{`Associate ${payer?.fullName} with a Different Contact`}</Button>
          </div>
        </>
      ) : (
        <>
          <Form layout="vertical" onFinish={onAssociateContact} form={form}>
            <Form.Item
              label={`Please select a contact to associate with the payer ${payer?.fullName}`}
              name="contactId"
              rules={[
                {
                  required: true,
                  message: 'Please select a contact',
                },
              ]}
            >
              <SelectContact
                loading={loading}
                disabled={loading}
                filters={{}}
              />
            </Form.Item>
            <ShowErrorsFormItem />
            <Form.Item>
              <Button
                key="send"
                type="primary"
                loading={saving}
                htmlType="submit"
              >
                Save & Continue
              </Button>
            </Form.Item>
          </Form>
          <Divider>Or</Divider>
          <div>
            <Button
              loading={saving}
              onClick={onCreateContact}
            >{`Create a New Contact for ${payer?.fullName}`}</Button>
          </div>
        </>
      )}
    </div>
  );
}

export default AdmContactPageA10;
