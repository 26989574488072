import { EditOutlined } from '@ant-design/icons';
import { Button, Skeleton, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import PhotoCircle from '../../../../components/PhotoCircle';
import UpdateTrainingCourseModal from '../EditCourse/UpdateTrainingCourseModal';
import DeleteThingDropdown from '../../../../components/DeleteThingDropdown';
import DeleteTrainingCourseModal from '../EditCourse/DeleteTrainingCourseModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useCanEditTraining from '../use-can-edit-training';

const { Title, Text, Paragraph } = Typography;

function TrainingCourseTitle({ course }) {
  const [editing, setEditing] = useState();
  const onEdit = () => setEditing({ trainingCourse: course });
  const onCancelEdit = () => setEditing(undefined);
  const onFinishEdit = () => {
    setEditing(undefined);
  };

  const history = useHistory();
  const [deleting, setDeleting] = useState();
  const onDelete = (c) => setDeleting(c);
  const onCancelDelete = () => setDeleting(undefined);
  const onFinishDelete = () => {
    setDeleting(undefined);
    history.push('/training-courses');
  };

  const showCreate = useCanEditTraining();

  return (
    <div style={{ paddingTop: 16 }}>
      {course ? (
        <div style={{ display: 'flex', paddingBottom: 32 }}>
          <PhotoCircle
            photoUrl={course.primaryThumbnailUrl}
            size="lg"
            name={course.courseName}
            customSize={148}
            style={{
              height: 148,
              width: 148,
              padding: 'unset',
              marginRight: 32,
            }}
          />
          <div style={{ flex: 1 }}>
            <Text>Training Course</Text>
            <Title
              style={{
                marginTop: 0,
                marginBottom: 16,
              }}
            >
              {course.courseName}
            </Title>
            <Paragraph style={{ paddingTop: 0 }}>
              {course.desc || 'Add a description ...'}
            </Paragraph>
          </div>
          <div className="right">
            {showCreate && (
              <Tooltip title="Edit Training Course">
                <Button
                  type="text"
                  size="large"
                  onClick={onEdit}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {showCreate && (
              <DeleteThingDropdown
                thing={course}
                label="Delete Training Course"
                handleDelete={onDelete}
              />
            )}
          </div>
        </div>
      ) : (
        <Skeleton avatar active />
      )}

      <UpdateTrainingCourseModal
        visible={editing}
        onCancel={onCancelEdit}
        onFinish={onFinishEdit}
      />
      <DeleteTrainingCourseModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
    </div>
  );
}

export default TrainingCourseTitle;
