import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Button, Form, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { ReportUserShortAttributes } from '../../reports/constants';
import settings from '../../../settings';
import FilterTrainingQuarterlyReports from './FilterTrainingQuarterlyReports';
import TrainingQuarterlyReportsTable from './TrainingQuarterlyReportsTable';

const allTrainingQuarterlyReportsQuery = gql`
  query AllTrainingQuarterlyReportsForAdmin(
    $first: Int
    $after: String
    $filters: TrainingQuarterlyReportFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allTrainingQuarterlyReportsForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          contactId
          contact {
            _id
            fullName
          }
          regionCode
          regionName
          reportType
          isSubmitted
          isApproved
          submittedAt
          createdBy
          createdByUser ${ReportUserShortAttributes}
          submittedByUser ${ReportUserShortAttributes}
          updatedBy
          createdAt
          updatedAt
          updatedByUser ${ReportUserShortAttributes}
          status
          approval {
            status
            when
            approver {
              _id
              name
            }
          }
          events {
            _id
            city
            country
            host
            date
            numPeopleTrained
          }
          miracles {
            _id
          }
          prayerRequests {
            _id
          }
        }
      }
    }
  }
`;

const extractAllTrainingQuarterlyReports = (data) =>
  data && data.allTrainingQuarterlyReportsForAdmin;

const { Text } = Typography;

const queryId = 'trainingQuarterlyReportsList';

function TrainingQuarterlyReportsList({ reportStatus, peopleGroupId }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({
    status: reportStatus,
    peopleGroupId,
  });

  const {
    error,
    loading,
    data: reports,
    search: reportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTrainingQuarterlyReportsQuery,
    extract: extractAllTrainingQuarterlyReports,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  return (
    <>
      <FilterTrainingQuarterlyReports
        filters={filters}
        refetch={refetch}
        reportSearch={reportSearch}
        loading={loading}
        sortBy={sortBy}
        type={'holisticProposal'}
      />
      <TrainingQuarterlyReportsTable reports={reports} loading={loading} />
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && <Button onClick={next}>Load More</Button>}
      </div>
    </>
  );
}

export default TrainingQuarterlyReportsList;
