import { updateReportAction } from '@aims/shared/redux-store/reports-store';
import ViewHolisticProjectRequest from '@aims/shared/reports/holistic-proposal/ViewHolisticProjectRequest';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { Skeleton } from 'antd';
import React from 'react';
import ProposalAdminSection from '../../../../holistic-proposals/ProposalAdminSection';
import {
  extractHolisticProjectProposal,
  holisticProposalForAdminQuery,
} from '../../../../holistic-proposals/constants';

const queryId = 'viewProposalTab';

function ViewProposalTab({ proposalId }) {
  const { loading } = useOneM1({
    variables: { _id: proposalId },
    query: holisticProposalForAdminQuery,
    extract: extractHolisticProjectProposal,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateReportAction,
    skip: !proposalId,
    showError: true,
  });
  const report = useSingleSomethingA10('reports', proposalId, queryId);

  return (
    <div style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
      {report && (
        <ViewHolisticProjectRequest
          report={report}
          isAdmin
          adminSection={
            <ProposalAdminSection report={report} queryId={queryId} />
          }
        />
      )}
      {loading && (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </div>
  );
}

export default ViewProposalTab;
