import { Select, Spin, Typography } from 'antd';
import { useQuery, gql } from '@apollo/client';
import React, { useMemo } from 'react';

const { Text } = Typography;

export const allJPAffinityBlocsQuery = gql`
  query AllJPAffinityBlocs {
    allJPAffinityBlocs {
      _id
      name
    }
  }
`;

function SelectAffinityBloc({ disabled, value, onChange }) {
  const { loading, error, data } = useQuery(allJPAffinityBlocsQuery);
  const affinityblocs = useMemo(() => {
    if (data) {
      return data.allJPAffinityBlocs;
    }
    return [];
  }, [data]);
  return (
    <Select
      // showSearch
      // optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children
      //     .toLowerCase()
      //     .localeCompare(optionB.children.toLowerCase())
      // }
      // autocomplete="chrome-off"
      disabled={disabled}
      onChange={onChange}
      placeholder="Select an affinity bloc"
      value={value}
      allowClear
      loading={loading}
    >
      {affinityblocs.map((affinitybloc) => (
        <Select.Option
          key={affinitybloc._id}
          value={affinitybloc._id}
          title={affinitybloc.name}
        >
          <div className="affinitybloc-option">
            <div>
              <Text>{affinitybloc.name}</Text>
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export default SelectAffinityBloc;
