import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useMemo } from 'react';

const ViewPhotoModal = ({ visible, setVisible }) => {
  const photo = useMemo(() => {
    if (visible) {
      return visible;
    }
  }, [visible]);

  const photoUrl = useMemo(() => {
    return photo && photo.url;
  }, [photo]);

  return (
    <map
      className="black-box"
      style={{ display: visible ? 'block' : 'none' }}
      onClick={() => setVisible(undefined)}
    >
      <div className="loading">
        <LoadingOutlined style={{ color: 'white', fontSize: 48 }} />
      </div>
      {photo && (
        <div className="img-div">
          <img className="img" src={photoUrl} alt={photo.name} />
        </div>
      )}
      <div className="close">
        <Button
          type="text"
          color="white"
          size="large"
          // onClick={() => setVisible(undefined)}
          icon={
            <CloseCircleOutlined style={{ color: 'white', fontSize: 32 }} />
          }
        />
      </div>
      <style jsx>{`
        .black-box {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
        }
        .loading {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .img-div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          max-height: 100%;
          max-width: 100%;
        }
        .close {
          position: absolute;
          right: 24px;
          top: 24px;
        }
      `}</style>
      <style jsx global>{``}</style>
    </map>
  );
};

export default ViewPhotoModal;
