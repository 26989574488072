import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Modal, notification, Space, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateLoanAgreementAction } from '../../../redux-store/loan-agreements-store';
import { AgreementStatuses, prepareLoanAgreementMutation } from '../constants';

const { Title, Text, Paragraph } = Typography;

const MarkLoanAgreementPrepareBtn = ({
  loanAgreement,
  setError,
  error,
  queryId,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [prepareLoanAgreement] = useMutation(prepareLoanAgreementMutation);
  const dispatch = useDispatch();

  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleFinish = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const result = await prepareLoanAgreement({
        variables: {
          loanAgreementId: loanAgreement._id,
          status: AgreementStatuses.PREVIEW.key,
        },
      });
      dispatch(
        updateLoanAgreementAction(
          result.data.prepareLoanAgreement.loanAgreement,
          queryId,
        ),
      );
      notification.success({
        message: 'Saved',
        description: 'Agreement updated successfully',
      });
      setShowingModal(null);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
    setLoading(false);
  }, [
    setError,
    dispatch,
    queryId,
    prepareLoanAgreement,
    loanAgreement,
    onSuccess,
  ]);

  return (
    <>
      <Button loading={loading} icon={<EditOutlined />} onClick={handleFinish}>
        Edit Agreement
      </Button>

      <style jsx>{``}</style>
      <style jsx global>{``}</style>
    </>
  );
};

export default MarkLoanAgreementPrepareBtn;
