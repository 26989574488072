import { Form } from 'antd';
import React, { useEffect } from 'react';
import TagsFormItem, { tagsBeforeProcessing } from '../pages/tags/TagsFormItem';
import TaskSomethingForm from '../pages/tasks/ViewTaskModal/TaskSomethingForm';

function ThingChangeTagsInner({
  thing,
  saving,
  handleFinish,
  onCancel,
  title = 'Edit Tags',
  label = 'Tags',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (thing) {
      form.setFieldsValue({
        tags:
          thing.tags && thing.tags.length > 0
            ? tagsBeforeProcessing(thing.tags)
            : [],
      });
    }
  }, [thing, form]);
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <TagsFormItem name="tags" label={label} saving={saving} />
    </TaskSomethingForm>
  );
}

export default ThingChangeTagsInner;
