import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Button, Form, Table, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import settings from '../../../../settings';
import SelectContact from '../../../contacts/SelectContact';
import ConfirmSendPayoutEmailModalV2 from '../modals/ConfirmSendPayoutEmailModalV2';
import useQuarterLabel from '../use-quarter-label';
import { myLocaleCompare } from '../utils';
import PayoutRowsTable from './PayoutRowsTable';

const { Title, Text } = Typography;

function RegionalCoordTab({
  rows,
  payout,
  savePayoutRow,
  transitionRows,
  loading,
}) {
  const [form] = Form.useForm();
  const [regionalCoordId, setRegionalCoordId] = useState();

  const onFormChange = useCallback((changed, values) => {
    setRegionalCoordId(values.regionalCoordId);
  }, []);

  const filteredRows = useMemo(() => {
    if (regionalCoordId) {
      return rows.filter(
        (r) => r.adoptionCopy.regionalCoordId === regionalCoordId,
      );
    }
    return [];
  }, [rows, regionalCoordId]);

  const quarterLabel = useQuarterLabel(payout);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const [fwPgRows, fwTotalRows] = useMemo(() => {
    const _fwRowsObj = {};
    filteredRows.forEach((r) => {
      if (
        r.adoptionCopy &&
        r.adoptionCopy.fieldWorkerId &&
        r.willReceiveFunds
      ) {
        const fwId = r.adoptionCopy.fieldWorkerId;
        if (_fwRowsObj[fwId] === undefined) {
          _fwRowsObj[fwId] = {};
        }
        if (_fwRowsObj[fwId][r.adoptionCopy.peopleGroupId] === undefined) {
          _fwRowsObj[fwId][r.adoptionCopy.peopleGroupId] = [];
        }
        _fwRowsObj[fwId][r.adoptionCopy.peopleGroupId].push(r);
      }
    });

    const _fwRows = [];
    const _fwTotalRows = [];
    Object.entries(_fwRowsObj).forEach(([fwId, pgRowsObj]) => {
      let totalToFieldWorker = 0;
      let totalToRegionalCoord = 0;
      let fwName;
      Object.entries(pgRowsObj).forEach(([pgId, pgRows]) => {
        let toFieldWorker = 0;
        let toRegionalCoord = 0;
        pgRows.forEach((r) => {
          toFieldWorker += r.details.toFieldWorker;
          toRegionalCoord += r.details.toRegionalCoord;
        });
        const r = pgRows[0];
        const pg = r.adoptionCopy && r.adoptionCopy.peopleGroup;
        fwName = r.adoptionCopy && r.adoptionCopy.fieldWorkerName;
        _fwRows.push({
          _id: `${fwId}:${pgId}`,
          fwName,
          pg,
          toFieldWorker,
          toRegionalCoord,
          total: toFieldWorker + toRegionalCoord,
        });
        totalToFieldWorker += toFieldWorker;
        totalToRegionalCoord += toRegionalCoord;
      });
      _fwTotalRows.push({
        _id: fwId,
        fwName,
        toFieldWorker: totalToFieldWorker,
        toRegionalCoord: totalToRegionalCoord,
        total: totalToFieldWorker + totalToRegionalCoord,
      });
    });

    return [_fwRows, _fwTotalRows];
  }, [filteredRows]);

  const { regionalCoordName, emailSent } = useMemo(() => {
    if (filteredRows && filteredRows.length) {
      const r = filteredRows[0];
      const emailsSent = payout.regionalCoordEmailsSent || [];
      return {
        regionalCoordName: r.adoptionCopy && r.adoptionCopy.regionalCoordName,
        emailSent: emailsSent.includes(regionalCoordId),
      };
    }
    return {};
  }, [payout, regionalCoordId, filteredRows]);

  const [confirming, setConfirming] = useState();
  const handleSendPayoutEmail = useCallback(() => {
    let toRegionalCoordTotal = 0;
    let toFieldWorkerTotal = 0;
    let totalTotal = 0;

    fwPgRows.forEach((r) => {
      toRegionalCoordTotal += r.toRegionalCoord;
      toFieldWorkerTotal += r.toFieldWorker;
      totalTotal += r.total;
    });

    setConfirming({
      regionalCoordName,
      request: {
        payoutId: payout._id,
        regionalCoordId,
        grandTotal: displayMoney(totalTotal / 10000),
        quarterLabel,
        totalToRegionalCoord: displayMoney(toRegionalCoordTotal / 10000),
        totalToFieldWorker: displayMoney(toFieldWorkerTotal / 10000),
        pgRows: fwPgRows.map((r) => ({
          pgId: r.pg && r.pg._id,
          pgName: r.pg && `${r.pg.nameAcrossCountries} / ${r.pg.country}`,
          fieldWorkerName: r.fwName,
          toRegionalCoord: displayMoney(r.toRegionalCoord / 10000),
          toFieldWorker: displayMoney(r.toFieldWorker / 10000),
          total: displayMoney(r.total / 10000),
        })),
        fwRows: fwTotalRows.map((r) => ({
          fieldWorkerName: r.fwName,
          toFieldWorker: displayMoney(r.toFieldWorker / 10000),
        })),
      },
    });
  }, [
    fwPgRows,
    fwTotalRows,
    regionalCoordId,
    payout,
    regionalCoordName,
    quarterLabel,
  ]);

  const handleCancelSendPayoutEmail = useCallback(() => {
    setConfirming(undefined);
  }, []);

  return (
    <>
      <div className="top-actions">
        <Form
          layout="vertical"
          form={form}
          style={{ maxWidth: 500, minWidth: 300 }}
          onValuesChange={onFormChange}
        >
          <Form.Item name="regionalCoordId" label="Regional Coordinator">
            <SelectContact
              placeholder="Select ..."
              filters={{
                isRegionalCoord: true,
              }}
            />
          </Form.Item>
        </Form>
        {regionalCoordId && fwPgRows && fwPgRows.length > 0 && (
          <div style={{ marginTop: 32, display: 'flex', alignItems: 'center' }}>
            {emailSent && (
              <div style={{ marginRight: 16 }}>
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  Email Sent
                </Text>
              </div>
            )}
            <Button type="primary" onClick={handleSendPayoutEmail}>
              Send Payout Email
            </Button>
          </div>
        )}
      </div>
      {!regionalCoordId && (
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <Text
            style={{
              color: sharedSettings.colors.darkGray,
              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            Select a Regional Coordinator
          </Text>
        </div>
      )}
      {regionalCoordId && (
        <>
          <Title level={3}>UPG Sponsorships</Title>
          <PayoutRowsTable
            payout={payout}
            rows={filteredRows}
            savePayoutRow={savePayoutRow}
            transitionRows={transitionRows}
            hideSearch
          />
          <div style={{ marginBottom: 32 }} />
          <Title level={3}>Field Workers by People Group</Title>
          <Table
            loading={loading}
            dataSource={fwPgRows}
            pagination={{ ...pagination, total: fwPgRows.length }}
            onChange={handleTableChange}
            style={{ width: '100%' }}
            rowKey="_id"
            summary={(pageData) => {
              let toRegionalCoordTotal = 0;
              let toFieldWorkerTotal = 0;
              let totalTotal = 0;

              pageData.forEach((r) => {
                toRegionalCoordTotal += r.toRegionalCoord;
                toFieldWorkerTotal += r.toFieldWorker;
                totalTotal += r.total;
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>Totals</Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      <Text>{displayMoney(toRegionalCoordTotal / 10000)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{displayMoney(toFieldWorkerTotal / 10000)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{displayMoney(totalTotal / 10000)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          >
            <Table.Column
              title="Field Worker"
              dataIndex="fwName"
              key="fwName"
              sorter={{
                compare: (a, b) => {
                  return myLocaleCompare(a.fwName, b.fwName);
                },
                multiple: 1,
              }}
              sortDirections={['ascend', 'descend']}
            />
            <Table.Column
              title="People Group"
              dataIndex="peopleGroup"
              width={200}
              sorter={{
                compare: (a, b) => {
                  const aComp = a.pg && a.pg._id;
                  const bComp = b.pg && b.pg._id;
                  return myLocaleCompare(aComp, bComp);
                },
                multiple: 2,
              }}
              sortDirections={['ascend', 'descend']}
              render={(text, record) => {
                const pg = record.pg;
                return (
                  pg && (
                    <>
                      <div className="row1">
                        <Title
                          level={5}
                          style={{ marginBottom: 0 }}
                        >{`${pg.nameAcrossCountries} / ${pg.country}`}</Title>
                      </div>
                      <div className="right">
                        <Text style={{ color: sharedSettings.colors.textGray }}>
                          {pg._id}
                        </Text>
                      </div>
                    </>
                  )
                );
              }}
            />
            <Table.Column
              title="To Regional Coordinator"
              dataIndex="toRegionalCoord"
              render={(text, record) => {
                return `${displayMoney(record.toRegionalCoord / 10000)}`;
              }}
            />
            <Table.Column
              title="To Field Worker"
              dataIndex="toFieldWorker"
              render={(text, record) => {
                return `${displayMoney(record.toFieldWorker / 10000)}`;
              }}
            />
            <Table.Column
              title="Total"
              dataIndex="total"
              render={(text, record) => {
                return `${displayMoney(record.total / 10000)}`;
              }}
            />
          </Table>
          <div style={{ marginBottom: 32 }} />
          <Title level={3}>Field Workers by Total Received</Title>
          <Table
            dataSource={fwTotalRows}
            pagination={{ ...pagination, total: fwTotalRows.length }}
            onChange={handleTableChange}
            style={{ width: '100%' }}
            rowKey="_id"
          >
            <Table.Column
              title="Field Worker"
              dataIndex="fwName"
              key="fwName"
              sorter={{
                compare: (a, b) => {
                  return myLocaleCompare(a.fwName, b.fwName);
                },
                multiple: 1,
              }}
              sortDirections={['ascend', 'descend']}
            />
            <Table.Column
              title="To Field Worker"
              dataIndex="toFieldWorker"
              render={(text, record) => {
                return `${displayMoney(record.toFieldWorker / 10000)}`;
              }}
            />
          </Table>
        </>
      )}
      <ConfirmSendPayoutEmailModalV2
        confirming={confirming}
        onCancel={handleCancelSendPayoutEmail}
        onFinish={handleCancelSendPayoutEmail}
      />
      <style jsx>{`
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

export default RegionalCoordTab;
