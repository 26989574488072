import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Space, Upload, notification } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

import { doFileUpload } from '../shared/utils';
import AttachmentCard from './AttachmentCard';
import {
  getAttachmentUploadUrlMutation,
  saveAttachmentMutation,
} from './constants';

/**
 * @typedef {Object} Props
 * @property {boolean=} private
 * @property {string} parentId
 * @property {string} parentType
 * @property {string=} parentSubType
 * @property {any[]} fileList
 * @property {React.SetStateAction<any>} setFileList
 * @property {((value: any) => void)=} setFieldValue
 * @property {React.MutableRefObject<unknown>} retrieveAttachments
 * @property {boolean} readOnly
 * @property {boolean} disabled
 * @property {(obj: unknown) => Promise<[string, string]>} doUploadInner
 */

/** @param {Props} props **/
export default function AttachmentCards({
  parentId,
  parentType,
  parentSubType = null,
  fileList,
  onUploadDelete,
  readOnly = false,
  disabled = false,
  doUploadInner,
  ...props // 'private' is a reserved word in JS, so we can't use it here
}) {
  const [uploadLoading, setUploadLoading] = useState(false);
  const _uploadProgress = useRef({});
  const [uploadProgress, _setUploadProgress] = useState(
    _uploadProgress.current,
  );
  const setUploadProgress = useCallback((value) => {
    _uploadProgress.current = value;
    _setUploadProgress(value);
  }, []);
  const [getAttachmentUploadUrl] = useMutation(getAttachmentUploadUrlMutation);
  const [saveAttachment] = useMutation(saveAttachmentMutation);

  const doUpload = useCallback(
    async (obj) => {
      try {
        setUploadLoading(true);
        let [attachmentId, s3Key] = await doUploadInner(obj);

        let response = await getAttachmentUploadUrl({
          variables: {
            attachment: {
              _id: attachmentId,
              s3Key,
            },
          },
        });
        if (!response.data?.getAttachmentUploadUrlForUser) {
          throw new Error('Failed to get upload URL');
        }
        setUploadProgress({
          ..._uploadProgress.current,
          [attachmentId]: 0,
        });
        await doFileUpload({
          url: response.data.getAttachmentUploadUrlForUser.url,
          fields: response.data.getAttachmentUploadUrlForUser.fields,
          file: obj.file,
          onProgress: (event) => {
            setUploadProgress({
              ..._uploadProgress.current,
              [attachmentId]: (event.loaded / event.total) * 100,
            });
          },
        });
        setTimeout(() => {
          setUploadProgress({
            ..._uploadProgress.current,
            [attachmentId]: undefined,
          });
        }, 1000);

        const extraTags = [props.private ? 'private' : 'public'];
        if (parentSubType) {
          extraTags.push(`${parentType.toUpperCase()}_${parentSubType}`);
        }

        response = await saveAttachment({
          variables: {
            attachment: {
              _id: attachmentId,
              filename: obj.file.name,
              s3Key,
              attachedTo: parentId,
              tags: ['attachment', ...extraTags],
              uploaded: true,
            },
          },
        });
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error uploading your attachment.',
        });
      }
      setUploadLoading(false);
    },
    [
      doUploadInner,
      parentId,
      saveAttachment,
      setUploadProgress,
      getAttachmentUploadUrl,
      parentSubType,
      parentType,
      props.private,
    ],
  );

  return (
    <Space direction="vertical" style={{ marginRight: 8 }}>
      {fileList.map((f) => (
        <AttachmentCard
          key={f._id}
          thumbnailUrl={(f.thumbnail && f.thumbnail.url) || f.base64Url}
          fileName={f.filename}
          fileSize={f.size}
          onUploadDelete={!readOnly && onUploadDelete(f)}
          progress={uploadProgress[f._id]}
          style={{ height: readOnly ? undefined : 64 }}
          url={f.url}
        />
      ))}
      {!readOnly && (
        <Upload
          className="profile-pic-upload"
          showUploadList={false}
          listType="picture"
          customRequest={doUpload}
        >
          <Button
            disabled={disabled || uploadLoading}
            icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          >
            {uploadLoading ? 'Saving ...' : 'Attach'}
          </Button>
        </Upload>
      )}
    </Space>
  );
}
