import { gql } from '@apollo/client';

export const ActivityAttributes = gql`
  {
    _id
    desc
    descText
    contactId
    eventId
    taskId
    type
    details
    when
    attachmentIds
    attachments {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    createdBy
    createdByUser {
      _id
      name
    }
    updatedBy
    createdAt
    updatedAt
  }
`;

export const ActivityUpdateAttributes = gql`
{
  crud
  id
  new ${ActivityAttributes}
}
`;

export const activityUpdatesForAdminSubscription = gql`
  subscription ActivityUpdatesForAdmin($filters: ActivityUpdateFiltersForAdmin) {
    activityUpdatesForAdmin(filters: $filters) ${ActivityUpdateAttributes}
  }
`;

export const allActivitiesForAdminQuery = gql`
  query AllActivitiesForAdmin($first: Int, $after: String, $filters: ActivityFiltersForAdmin, $sortBy: [SortBy]) {
    allActivitiesForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${ActivityAttributes}
      }
    }
  }
`;

export const extractAllActivitiesForAdmin = (data) =>
  data.allActivitiesForAdmin;
