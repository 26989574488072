import { Button, Form, Space, Tag, Typography } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TagsFormItem from '../../tags/TagsFormItem';

const { Text, Title } = Typography;

function EditTaskTags({ onSubmit, task }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const retrieveTagsOnSubmit = useRef();

  const onFinish = useCallback(
    async (_values) => {
      setError(null);
      setLoading(true);
      try {
        const { selectedTags } = _values;
        const tags = await retrieveTagsOnSubmit.current(selectedTags);
        await onSubmit({
          tags,
        });
        setEditing(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
      setLoading(false);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (task.tags) {
      form.setFieldsValue({
        selectedTags: task.tags.map((t) => t.key),
      });
    }
  }, [task, form]);

  return (
    <>
      <map
        onClick={() => {
          if (!editing) {
            setEditing(true);
          }
        }}
        style={{ width: '100%', cursor: 'pointer' }}
      >
        <Title level={4}>Tags</Title>
        {!editing && (
          <div className="tags">
            {task.tags && task.tags.length ? (
              <>
                {task.tags.map((tag) => (
                  <Tag key={tag.key} color={tag.color}>
                    {tag.key}
                  </Tag>
                ))}
              </>
            ) : (
              <Text type="secondary">Click to add tags</Text>
            )}
          </div>
        )}
      </map>
      {editing && (
        <Form onFinish={onFinish} form={form} layout="vertical">
          <TagsFormItem
            loading={loading}
            retrieveTagsOnSubmit={retrieveTagsOnSubmit}
            form={form}
          />
          <Form.Item>
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <Button
                disabled={loading}
                htmlType="button"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
          {error && (
            <Form.Item>
              <Text type="danger">{error}</Text>
            </Form.Item>
          )}
        </Form>
      )}
      <style jsx>{`
        .tags {
          min-height: 36px;
        }
      `}</style>
    </>
  );
}

export default EditTaskTags;
