import { getQuarterLabel } from '@aims/shared/reports/quarters';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import ellipsis from 'text-ellipsis';
import { PayoutStates } from './constants';
import settings from '../../../settings';

function PayoutsV2Table({ payouts, loading }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  return (
    <Table
      dataSource={payouts}
      loading={!payouts?.length && loading}
      pagination={{ ...pagination, total: payouts?.length }}
      onChange={handleTableChange}
      style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 1200 }}
    >
      <Table.Column title="Name" dataIndex="name" key="name" widht={200} />
      <Table.Column
        title="Quarter"
        dataIndex="quarter"
        width={200}
        render={(text, record) => {
          const quarterLabel = getQuarterLabel(record.quarter);
          return quarterLabel;
        }}
      />
      <Table.Column
        title="State"
        dataIndex="state"
        render={(text, record) => {
          const stateObj = PayoutStates[record.state];
          return (
            <Tag
              style={{
                fontSize: 16,
                lineHeight: 1.8,
                padding: '0px 12px',
              }}
              color={stateObj.color}
            >
              {stateObj.label}
            </Tag>
          );
        }}
      />
      <Table.Column
        title="Notes"
        dataIndex="notes"
        width={300}
        render={(text, record) => {
          return record.notes && ellipsis(record.notes, 160);
        }}
      />

      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="Edit">
              <Link to={`/payout/edit/${record._id}/adoptions`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
          </Space>
        )}
      />
    </Table>
  );
}

export default PayoutsV2Table;
