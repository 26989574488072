import { Checkbox, Form, Input, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import ContactSomethingForm from './ContactSomethingForm';

const { Title, Text } = Typography;

function ContactUpdatePhoneInnerModalA10({
  contact,
  saving,
  handleFinish,
  onCancel,
  phone: oldPhone,
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (oldPhone) {
      form.setFieldsValue({
        phone: oldPhone.phone,
        label: oldPhone.label,
      });
    }
  }, [oldPhone, form]);

  const _handleFinish = useCallback(
    async (values) => {
      const existingPhoneNumbers = (contact.phoneNumbers || [])
        .filter((e) => e._id !== oldPhone._id)
        .reduce((prev, curr) => {
          const { phone, _id, label } = curr;
          prev.push({ phone, _id, label });
          return prev;
        }, []);
      const { phone, label, makePrimary } = values;
      const existingPrimaryPhoneId = contact.primaryPhoneNumberId;
      await handleFinish({
        phoneNumbers: [
          ...existingPhoneNumbers,
          {
            _id: oldPhone._id,
            phone,
            label,
          },
        ].sort((a, b) => a.phone.localeCompare(b.phone)),
        primaryPhoneNumberId: makePrimary
          ? oldPhone._id
          : existingPrimaryPhoneId,
      });
    },
    [handleFinish, contact, oldPhone],
  );
  return (
    <ContactSomethingForm
      title="Update Phone Number"
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        name="phone"
        label="Phone Number"
        extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
        rules={[
          { required: true, message: 'Please enter a phone number' },
          {
            pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            message: 'Please enter a valid phone number (ex. 5124026225).',
          },
        ]}
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
        extra="e.g., Work, Home ..."
      >
        <Input disabled={saving} />
      </Form.Item>
      <Form.Item name="makePrimary" valuePropName="checked" initialValue={true}>
        <Checkbox>Make Primary</Checkbox>
      </Form.Item>
    </ContactSomethingForm>
  );
}

export default ContactUpdatePhoneInnerModalA10;
