import { Card, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { useApolloClient, gql, useMutation } from '@apollo/client';
import useQueryParams from '../../shared/use-query-params';
import { useHistory } from 'react-router-dom';

const constantContactAuth2Mutation = gql`
  mutation ConstantContactAuth2($authCode: String!, $state: String!) {
    constantContactAuth2(authCode: $authCode, state: $state)
  }
`;

function ConstantContactAuthResultPage() {
  const [constantContactAuth2] = useMutation(constantContactAuth2Mutation);
  const params = useQueryParams();
  const history = useHistory();
  const [error, setError] = useState();
  const last = useRef();

  useEffect(() => {
    async function doStuff() {
      try {
        if (params.state && params.code) {
          const response = await constantContactAuth2({
            variables: {
              authCode: params.code,
              state: params.state,
            },
            fetchPolicy: 'no-cache',
          });
          if (response && response.data && response.data.constantContactAuth2) {
            history.push(`/settings`);
          }
        }
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    }
    const inputs = JSON.stringify(params);
    if (inputs != last.current) {
      last.current = inputs;
      doStuff();
    }
  }, [params, constantContactAuth2, history]);

  return (
    <CSPage title="Settings">
      <CSPageHeader title="Settings" />
      <div style={{ textAlign: 'center', marginTop: 32, marginBottom: 32 }}>
        <Spin spinning tip="One moment..." size="large" />
      </div>
    </CSPage>
  );
}

export default ConstantContactAuthResultPage;
