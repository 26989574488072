import {
  CopyOutlined,
  EyeOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Space, Table, Tooltip, Typography } from 'antd';
import Column from 'antd/lib/table/Column';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import settings from '../../settings';
import { MouRoles } from './constants';
import SelectMouRole from './SelectMouRole';
import { generateUuid } from '../../shared/utils';
import CloneMouModal from './CloneMouModal';
import { AgreementStatuses } from '../loan-agreements/constants';

const { Title, Text } = Typography;

function ListMous({
  loading,
  error,
  mous,
  next,
  hasNextPage,
  refetch,
  onFiltersChanged,
  isTemplate,
  onSortedByChange,
  onSortOrderChange,
}) {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const history = useHistory();
  const [cloning, setCloning] = useState();
  const handleClone = useCallback((agreement) => {
    setCloning({
      createId: generateUuid(),
      defaultVersionNo: agreement.version + 1,
      agreementToClone: agreement,
    });
  }, []);
  const handleCancelClone = useCallback(() => {
    setCloning(false);
  }, []);
  const handleCloneFinish = useCallback(
    (createId) => {
      history.push(`/mous/view/${createId}`);
    },
    [history],
  );

  return (
    <>
      {error && (
        <div className="errors">
          <Text type="danger">{error}</Text>
        </div>
      )}
      <div className="top-actions">
        <Form layout="inline" form={form} onValuesChange={onFiltersChanged}>
          <Form.Item name="search" style={{ maxWidth: 500, minWidth: 300 }}>
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
          <Form.Item name="role" style={{ maxWidth: 300, minWidth: 200 }}>
            <SelectMouRole allowClear={true} placeholder="Filter by role" />
          </Form.Item>
        </Form>
        <Button icon={<ReloadOutlined />} onClick={refetch}>
          Refresh
        </Button>
      </div>
      <Table
        dataSource={mous}
        loading={!mous.length && loading}
        pagination={{ ...pagination, total: mous.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: isTemplate ? 900 : 1200 }}
      >
        <Column title="Name" dataIndex="name" key="name" />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          render={(text, record) => {
            return MouRoles[record.role] && MouRoles[record.role].label;
          }}
        />
        {!isTemplate && (
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(text, record) => {
              return (
                <>
                  {record.status && AgreementStatuses[record.status] && (
                    <Text
                      style={{
                        color: AgreementStatuses[record.status].color,
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {AgreementStatuses[record.status].label}
                    </Text>
                  )}
                </>
              );
            }}
          />
        )}
        {!isTemplate && (
          <Column
            title="Contact Name"
            dataIndex="contactName"
            key="contactName"
          />
        )}
        {!isTemplate && (
          <Column
            title="People Group Name"
            dataIndex="peopleGroupName"
            key="peopleGroupName"
          />
        )}
        <Column
          title="Parties"
          dataIndex="parties"
          render={(text, record) => {
            return (
              <>
                {record.parties &&
                  record.parties.map((p) => (
                    <div key={p._id}>{`${p.name}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Sections"
          dataIndex="sections"
          render={(text, record) => {
            return (
              <>
                {record.sections &&
                  record.sections.map((s) => (
                    <div key={s._id}>{`${s.title}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Attachments"
          dataIndex="attachments"
          render={(text, record) => {
            return (
              <>
                {record.attachments &&
                  record.attachments.map((s) => (
                    <div key={s._id}>{`${s.name}`}</div>
                  ))}
              </>
            );
          }}
        />
        <Column
          title="Updated"
          dataIndex="updatedAt"
          render={(text, record) => {
            return new Intl.DateTimeFormat(locale, {
              dateStyle: 'full',
            }).format(new Date(record.updatedAt));
          }}
        />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="View">
                <Link
                  to={
                    isTemplate
                      ? `/mou-template/view/${record._id}`
                      : `/mous/view/${record._id}`
                  }
                >
                  <Button icon={<EyeOutlined />} />
                </Link>
              </Tooltip>
              {!isTemplate && (
                <Tooltip title="Clone">
                  <Button
                    onClick={() => handleClone(record)}
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              )}
              {/* <Tooltip title="Delete">
                <Button
                  onClick={() => setDeleting(record.serialNo)}
                  icon={<DeleteOutlined />}
                />
              </Tooltip> */}
            </Space>
          )}
        />
      </Table>
      <div className="bottom-actions">
        {mous && hasNextPage && <Button onClick={next}>Load More</Button>}
      </div>
      <CloneMouModal
        visible={cloning}
        onFinish={handleCloneFinish}
        onCancel={handleCancelClone}
      />
      <style jsx>{`
        .body {
          display: flex;
        }
        .bottom-actions {
          text-align: center;
        }
        .errors {
          margin-bottom: 16px;
          text-align: center;
        }
        .profile-photo {
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
        .top-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 16px;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
}

export default ListMous;
