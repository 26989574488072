import { Form, Select } from 'antd';
import React, { useCallback, useEffect } from 'react';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

function HProjectChangeLoanTermInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Loan Term',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        loanTerm: holisticProject.loanTerm,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { loanTerm, ...values } = _values;
      handleFinish({
        loanTerm,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Form.Item
        label="Loan Term"
        name="loanTerm"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <Select disabled={saving}>
          {[6, 12, 18, 24, 30, 36, 42, 48].map((months) => (
            <Select.Option key={months} value={months}>
              {`${months} Months`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangeLoanTermInner;
