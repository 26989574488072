export const READ_MOUS = 'READ_MOUS';
export const CREATE_MOU = 'CREATE_MOU';
export const UPDATE_MOU = 'UPDATE_MOU';
export const DELETE_MOU = 'DELETE_MOU';

export const readMousAction = (
  // eslint-disable-next-line no-shadow
  mous,
  queryId = 'default',
) => ({
  type: READ_MOUS,
  payload: {
    mous,
    queryId,
  },
});

export const createMouAction = (mou, queryId = 'default') => ({
  type: CREATE_MOU,
  payload: {
    mou,
    queryId,
  },
});

export const updateMouAction = (mou, queryId = 'default') => ({
  type: UPDATE_MOU,
  payload: {
    mou,
    queryId,
  },
});

export const deleteMouAction = (mouId, queryId = 'default') => ({
  type: DELETE_MOU,
  payload: {
    mouId,
    queryId,
  },
});

const initialMous = { default: {} };

export function mous(state = initialMous, action) {
  switch (action.type) {
    case READ_MOUS: {
      const newState = {
        ...state,
        [action.payload.queryId]: { ...state[action.payload.queryId] },
      };
      action.payload.mous.forEach((p) => {
        newState[action.payload.queryId][p._id] = p;
      });
      return newState;
    }
    case CREATE_MOU:
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.mou._id]: action.payload.mou,
        },
      };
    case UPDATE_MOU: {
      const current =
        (state[action.payload.queryId] &&
          state[action.payload.queryId][action.payload.mou._id]) ||
        undefined;
      return {
        ...state,
        [action.payload.queryId]: {
          ...state[action.payload.queryId],
          [action.payload.mou._id]: {
            ...current,
            ...action.payload.mou,
          },
        },
      };
    }
    case DELETE_MOU: {
      const { mouId } = action.payload;
      return Object.entries(state).reduce((prev, [key, val]) => {
        prev[key] = {
          ...val,
          [mouId]: undefined,
        };
        return prev;
      }, {});
    }
    default:
      return state;
  }
}
