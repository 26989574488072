import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { useRef, useState, useMemo } from 'react';
import settings from '../../settings';
import {
  eventUpdatesForAdminSubscription,
  allEventsForAdminQuery,
  extractAllEventsForAdmin,
} from './constants';
import {
  createEventAction,
  deleteEventAction,
  readEventsAction,
  updateEventAction,
} from '../../redux-store/events-store';

function useEvents({
  filters,
  skip,
  sortByFunc,
  filterFunc,
  queryId = 'default',
}) {
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters },
    subscription: eventUpdatesForAdminSubscription,
    createAction: createEventAction,
    updateAction: updateEventAction,
    deleteAction: deleteEventAction,
    extractSomething: (resp) => resp?.data?.eventUpdatesForAdmin,
    queryId,
    skip,
  });
  const [sortedBy, setSortedBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allEventsForAdminQuery,
    extract: extractAllEventsForAdmin,
    readAction: readEventsAction,
    first: settings.querySize,
    filters,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const defaultSort = useMemo(() => {
    if (sortByFunc) {
      return sortByFunc;
    }
    return (a, b) => {
      return b.createdAt?.localeCompare(a.createdAt);
    };
  }, [sortByFunc]);
  const { data, search } = useManySomethingsM20({
    table: 'events',
    sortByFunc: defaultSort,
    searchKeys: ['descText', '_id'],
    filterFunc,
    queryId,
  });
  return {
    loading,
    search,
    data,
  };
}

export default useEvents;
