import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteProjectSponsorshipMutation } from '../constants';
import YesNoModal from '../../components/YesNoModal';
import { deleteProjectSponsorshipAction } from '../../redux-store/project-sponsorships-store';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

const { Text } = Typography;

function DeleteProjectSponsorshipModal({ deleting, setDeleting }) {
  const { sponsorship, queryId } = deleting || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteProjectSponsorship] = useMutation(
    deleteProjectSponsorshipMutation,
  );
  const dispatch = useDispatch();

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      const result = await deleteProjectSponsorship({
        variables: {
          _id: sponsorship._id,
        },
      });
      dispatch(deleteProjectSponsorshipAction(sponsorship._id, queryId));
      notification.success({
        message: 'Deleted',
        description: 'Project Sponsorship deleted successfully',
      });
      setDeleting(undefined);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [deleteProjectSponsorship, dispatch, sponsorship, setDeleting, queryId]);

  const onNo = useCallback(() => {
    if (!loading) {
      setDeleting(undefined);
    }
  }, [setDeleting, loading]);

  return (
    <YesNoModal
      title="Delete Project Sponsorship"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            <Text>
              {'Are you sure you want to delete this project sponsorship?'}
            </Text>
          </div>
          <div>
            {sponsorship && (
              <>
                {sponsorship.sponsorName && (
                  <>
                    <Text>{sponsorship.sponsorName}</Text>
                    <br />
                  </>
                )}
                <Text>{`${
                  sponsorship.recurring ? 'Monthly Recurring' : 'One-Time'
                } ${displayMoney(
                  getNumberValue(sponsorship.amount) / 10000,
                )}`}</Text>
                <br />
                {sponsorship.projectName && (
                  <>
                    <Text>{sponsorship.projectName}</Text>
                    <br />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      }
      yesText="Delete"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!deleting}
      loading={loading}
      error={error}
    />
  );
}

export default DeleteProjectSponsorshipModal;
