import { Button } from 'antd';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import CreateMiracleModalJ15 from './CreateMiracleModalJ15';
import MiracleListJ15 from './MiracleListJ15';

function MiraclesPage() {
  const refetch = useRef();
  const [creating, setCreating] = useState();
  const onCreateClick = (miracle) => setCreating({ miracle });
  const onFinishEdit = () => {
    setCreating(undefined);
    refetch.current?.();
  };
  const onCancelEdit = () => setCreating(undefined);

  const filtersRef = useRef({});
  return (
    <CSPage title="Testimoneies">
      <CSPageHeader
        title="Testimoneies"
        topActions={[
          <Button
            key="add"
            type="link"
            onClick={onCreateClick}
            icon={<PlusCircleOutlined />}
            size="large"
          >
            Add a new testimony
          </Button>,
        ]}
      />
      <MiracleListJ15
        queryId="miraclesPage"
        filtersRef={filtersRef}
        refetchRef={refetch}
      />
      <CreateMiracleModalJ15
        visible={creating}
        onFinish={onFinishEdit}
        onCancel={onCancelEdit}
      />
    </CSPage>
  );
}

export default MiraclesPage;
