import sharedSettings from '@aims/shared/sharedSettings';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Divider, Form, Input, Tooltip, notification } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateContactAction } from '../../redux-store/contacts-store';
import {
  addContactToOrganizationMutation,
  removeContactFromOrganizationMutation,
  updateOrganizationMembershipMutation,
} from '../organizations/constants';
import SelectContact from './SelectContact';
import { ContactTypes } from './constants';

function AddToOrganization({ loading, contact, finish }) {
  const [addContactToOrganization] = useMutation(
    addContactToOrganizationMutation,
  );
  const [updateOrganizationMembership] = useMutation(
    updateOrganizationMembershipMutation,
  );
  const [removeContactFromOrganization] = useMutation(
    removeContactFromOrganizationMutation,
  );

  const dispatch = useDispatch();

  finish.current = useCallback(
    async (contactId, queryId, selectedOrgs) => {
      const orgsToRemove = [];
      const orgsToAdd = [];
      const orgsToUpdate = [];
      if (contact && contact.organizations) {
        selectedOrgs.forEach((org) => {
          const existing = contact.organizations.find(
            (o) => o.orgId === org.orgId,
          );
          if (existing) {
            if (existing.role !== org.role) {
              orgsToUpdate.push(org);
            }
          } else {
            orgsToAdd.push(org);
          }
        });
        contact.organizations.forEach((org) => {
          if (!selectedOrgs.find((o) => o.orgId === org.orgId)) {
            orgsToRemove.push(org);
          }
        });
      } else {
        selectedOrgs.forEach((org) => {
          orgsToAdd.push(org);
        });
      }
      let updatedContact;
      let updatedOrg;
      let response;
      for (let i = 0; i < orgsToAdd.length; i++) {
        const org = orgsToAdd[i];
        response = await addContactToOrganization({
          variables: {
            member: {
              contactId,
              orgId: org.orgId,
              role: org.role,
            },
          },
        });
        updatedContact =
          response.data &&
          response.data.addContactToOrganization &&
          response.data.addContactToOrganization.contact;
        updatedOrg =
          response.data &&
          response.data.addContactToOrganization &&
          response.data.addContactToOrganization.organization;
        notification.success({
          message: 'Success',
          description: 'Contact added to organization',
        });
      }
      for (let i = 0; i < orgsToRemove.length; i++) {
        const org = orgsToRemove[i];
        response = await removeContactFromOrganization({
          variables: {
            contactId,
            orgId: org.orgId,
          },
        });
        updatedContact =
          response.data &&
          response.data.removeContactFromOrganization &&
          response.data.removeContactFromOrganization.contact;
        updatedOrg =
          response.data &&
          response.data.removeContactFromOrganization &&
          response.data.removeContactFromOrganization.organization;
        notification.success({
          message: 'Success',
          description: 'Contact removed from organization',
        });
      }
      for (let i = 0; i < orgsToUpdate.length; i++) {
        const org = orgsToUpdate[i];
        response = await updateOrganizationMembership({
          variables: {
            member: {
              contactId,
              orgId: org.orgId,
              role: org.role,
            },
          },
        });
        updatedContact =
          response.data &&
          response.data.updateOrganizationMembership &&
          response.data.updateOrganizationMembership.contact;
        updatedOrg =
          response.data &&
          response.data.updateOrganizationMembership &&
          response.data.updateOrganizationMembership.organization;
        notification.success({
          message: 'Success',
          description: 'Organization membership updated',
        });
      }
      if (updatedContact) {
        dispatch(
          updateContactAction({
            queryId,
            ...updatedContact,
          }),
        );
      }
      if (updatedOrg) {
        dispatch(
          updateContactAction({
            queryId,
            ...updatedOrg,
          }),
        );
      }
    },
    [
      contact,
      addContactToOrganization,
      removeContactFromOrganization,
      updateOrganizationMembership,
      dispatch,
    ],
  );
  return (
    <>
      <Divider>Organization</Divider>
      <Form.Item>
        <Form.List name="organizations">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key} style={{ marginBottom: 16 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div className="org-member">
                      <Form.Item
                        label="Organization"
                        name={[field.name, 'orgId']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a organization',
                          },
                        ]}
                      >
                        <SelectContact
                          disabled={loading}
                          filters={{ contactType: ContactTypes.ORG.key }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Role"
                        name={[field.name, 'role']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a role',
                          },
                        ]}
                      >
                        <Input disabled={loading} />
                      </Form.Item>
                    </div>
                    <Tooltip title="Remove from organization">
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: 8 }}
                      />
                    </Tooltip>
                  </div>
                </Form.Item>
              ))}
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add an organization
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <style jsx>{`
        .org-member {
          border: 1px solid ${sharedSettings.colors.borderGray};
          border-radius: 4px;
          padding 16px;
          width: 100%;
        }
      `}</style>
    </>
  );
}

export default AddToOrganization;
