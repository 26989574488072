import AddRemoveAttachments from '@aims/shared/components/AddRemoveAttachments';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Space, Typography, notification } from 'antd';
import React, { useCallback, useEffect, useRef } from 'react';
import ShowErrorsFormItem from '@aims/shared/components/ShowErrorsFormItem';
import LanguagesFormItem from '../../training-languages/LanguagesFormItem';
import {
  createTrainingFileMutation,
  updateTrainingFileMutation,
} from './constants';

const { Text } = Typography;

function EditTrainingFileForm({
  _id,
  handleCancel,
  handleSuccess,
  trainingFile,
  loading,
  setLoading,
  fieldsToSet = {},
}) {
  const [updateTrainingFile] = useMutation(updateTrainingFileMutation);
  const [createTrainingFile] = useMutation(createTrainingFileMutation);
  const retrieveAttachments = useRef();

  const [form] = Form.useForm();

  useEffect(() => {
    if (trainingFile) {
      form.setFieldsValue({
        ...trainingFile,
      });
    }
  }, [trainingFile, form]);

  const onSubmit = useCallback(
    async (_values) => {
      setLoading(true);
      try {
        const { fileName, ...values } = _values;
        const attachments = retrieveAttachments.current();
        if (!attachments.length) {
          throw new Error('Please attach a file.');
        }
        if (trainingFile) {
          await updateTrainingFile({
            variables: {
              trainingFile: {
                _id,
                fileName: fileName,
                ...values,
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Training File updated successfully',
          });
        } else {
          await createTrainingFile({
            variables: {
              trainingFile: {
                _id,
                fileName: fileName,
                fileId: attachments[0]['_id'],
                ...values,
                ...fieldsToSet,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Training file created successfully',
          });
        }
        if (handleSuccess) {
          handleSuccess();
        }
      } catch (err) {
        console.error(err);
        if (err.message.includes('Please attach')) {
          notification.error({
            message: 'Error',
            description: err.message,
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'We apologize.  An error occurred.',
          });
        }
      }
      setLoading(false);
    },
    [
      _id,
      createTrainingFile,
      updateTrainingFile,
      setLoading,
      fieldsToSet,
      handleSuccess,
      trainingFile,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        id="editTrainingFile"
        form={form}
      >
        <Form.Item
          name="fileName"
          label="File Name"
          rules={[{ required: true, message: 'Please enter an file name' }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <LanguagesFormItem name="language" label="Language" saving={loading} />
        <Form.Item name="desc" label="Description">
          <Input.TextArea disabled={loading} />
        </Form.Item>
        {!loading && (
          <AddRemoveAttachments
            existingAttachments={
              trainingFile && trainingFile.file ? [trainingFile.file] : []
            }
            parentType="trainingFile"
            parentId={_id}
            retrieveAttachments={retrieveAttachments}
            readOnly={trainingFile && trainingFile.file ? true : false}
            uploadLimit={1}
          />
        )}
        <ShowErrorsFormItem />
        <div style={{ height: 16 }} />
        <Form.Item>
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              key="cancel"
              onClick={handleCancel}
              htmlType="button"
              type="text"
              size="small"
              disabled={loading}
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: -7 }}
            >
              Cancel
            </Button>
            <Button
              key="send"
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Save File
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditTrainingFileForm;
