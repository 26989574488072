import sharedSettings from '@aims/shared/sharedSettings';
import { Card, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PhotoCircle from '../../components/PhotoCircle';

const { Title, Text, Paragraph } = Typography;

function AdoptionPeopleGroupCard({ loading, peopleGroup }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const pgInfo = useMemo(() => {
    const pg = peopleGroup;
    if (pg) {
      const lang = pg && `${pg.primaryLanguage} / ${pg.primaryReligion}`;
      const pop =
        pg &&
        `${Math.round(pg.population).toLocaleString(locale)} / ${pg.jpScale}`;
      return {
        ...pg,
        lang,
        pop,
      };
    }
    return undefined;
  }, [peopleGroup, locale]);
  return (
    <div>
      <Title level={3}>People Group</Title>
      <Card styles={{ body: { display: 'flex' } }}>
        {loading || peopleGroup ? (
          <>
            <div style={{ marginRight: 16 }}>
              <PhotoCircle
                photoUrl={pgInfo && pgInfo.primaryThumbnailUrl}
                name={pgInfo && pgInfo.nameAcrossCountries}
                customSize={80}
                color={sharedSettings.colors.contactColor}
              />
            </div>
            {pgInfo && (
              <div>
                <Title
                  level={5}
                  style={{ marginBottom: 0 }}
                >{`${pgInfo.nameAcrossCountries} / ${pgInfo.country}`}</Title>
                <div className="right">
                  <Text style={{ color: sharedSettings.colors.textGray }}>
                    {pgInfo._id}
                  </Text>
                </div>
                <div>{pgInfo.lang}</div>
                <div>{pgInfo.pop}</div>
                <div>
                  <Link to={`/people-group/view/${pgInfo._id}/info`}>
                    People Group Page
                  </Link>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>Not Specified</div>
        )}
      </Card>
    </div>
  );
}

export default AdoptionPeopleGroupCard;
