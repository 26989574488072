import { generateUuid } from '@aims/shared/shared/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import AllTrainingFiles from './AllTrainingFiles';
import TrainingFileModal from './TrainingFileModal';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import useCanEditTraining from '../training_courses/use-can-edit-training';

function AllTrainingFilesPage() {
  const refetchRef = useRef();
  const [creating, setCreating] = useState();
  const onCreate = () =>
    setCreating({
      _id: generateUuid(),
      fieldsToSet: { courseId: 'unassigned', moduleId: 'unassigned' },
    });
  const onCancelCreate = () => setCreating(undefined);
  const onFinishCreate = () => {
    refetchRef.current();
    setCreating(undefined);
  };
  const showCreate = useCanEditTraining();
  return (
    <>
      <CSPage containerStyle={{ maxWidth: 'unset' }} title="Training Files">
        <CSPageHeader
          title="Training Files"
          topActions={[
            showCreate && (
              <Button
                key="add"
                type="link"
                icon={<PlusCircleOutlined />}
                size="large"
                onClick={onCreate}
              >
                Upload a New Training File
              </Button>
            ),
          ].filter((a) => a)}
        />
        <AllTrainingFiles refetchRef={refetchRef} />
        <TrainingFileModal
          visible={creating}
          onFinish={onFinishCreate}
          onCancel={onCancelCreate}
        />
      </CSPage>
      <style jsx>{``}</style>
    </>
  );
}

export default AllTrainingFilesPage;
