import { Form, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import DollarX4InputNumber from '@aims/shared/components/DollarX4InputNumber';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

const { Paragraph } = Typography;

function HProjectChangePaybackTotalInner({
  holisticProject,
  saving,
  handleFinish,
  onCancel,
  title = 'Change Payback Total',
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (holisticProject) {
      form.setFieldsValue({
        paybackTotalX4: holisticProject.paybackTotalX4,
      });
    }
  }, [holisticProject, form]);
  const _handleFinish = useCallback(
    (_values) => {
      const { paybackTotalX4, ...values } = _values;
      handleFinish({
        paybackTotalX4,
        ...values,
      });
    },
    [handleFinish],
  );
  return (
    <TaskSomethingForm
      title={title}
      form={form}
      onFinish={_handleFinish}
      onCancel={onCancel}
      saving={saving}
    >
      <Paragraph>{`Normally this is the same as the loan amount: ${displayMoney(
        getNumberValue(holisticProject?.loanTotalX4) / 10000,
      )}.`}</Paragraph>
      <Form.Item
        label="Payback Total (USD)"
        name="paybackTotalX4"
        initialValue={0}
        rules={[{ required: true, message: 'This field is required' }]}
        style={{ width: 200 }}
      >
        <DollarX4InputNumber disabled={saving} />
      </Form.Item>
    </TaskSomethingForm>
  );
}

export default HProjectChangePaybackTotalInner;
