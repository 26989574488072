import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Select, notification } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import sharedSettings from '../sharedSettings';

const selectPeopleGroupQuery = gql`
  query AllPeopleGroups(
    $first: Int
    $after: String
    $filters: PeopleGroupFiltersForUser
    $sortBy: [SortBy]
  ) {
    allPeopleGroupsForUser(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          region
          regionCode
          countryCode
          country
          nameAcrossCountries
          primaryThumbnailUrl
          updatedAt
        }
      }
    }
  }
`;

export const extractAllPeopleGroupsForUser = (data) =>
  data && data.allPeopleGroupsForUser;

function SelectPeopleGroup({ disabled, value, onChange, style }) {
  const filters = useRef({});
  const sortBy = useRef([
    { key: '_score', order: 'DESC' },
    { key: 'focusLevelNo', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: peopleGroups,
    search: peopleGroupSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: selectPeopleGroupQuery,
    extract: extractAllPeopleGroupsForUser,
    first: sharedSettings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'cache-first',
    queryId: 'select-people-group',
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  }, [error]);

  const options = useMemo(() => {
    if (peopleGroups) {
      return peopleGroups.map((item) => {
        const name = `${item.nameAcrossCountries} - ${item.country} (${item._id})`;
        return {
          value: item._id,
          label: name,
          title: name,
        };
      });
    }
    return [];
  }, [peopleGroups]);

  useEffect(() => {
    if (value) {
      peopleGroupSearch(value);
    }
  }, [value, peopleGroupSearch]);

  return (
    <Select
      style={{ width: '100%', ...style }}
      placeholder="Select a people group"
      onChange={onChange}
      value={value}
      options={options}
      allowClear
      filterOption={false}
      showSearch
      onSearch={(v) => peopleGroupSearch(v)}
      loading={loading}
      disabled={disabled}
    />
  );
}

export default SelectPeopleGroup;
