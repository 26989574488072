import { Form, Table, Typography } from 'antd';
import React from 'react';
import useSettings from '../../shared/use-settings';
import { displayMoney, getNumberValue } from '../../shared/utils';
import sharedSettings from '../../sharedSettings';

const { Text } = Typography;

function CostAnalysisSummary({
  exchangeRate,
  loanTotalUsd,
  loanTerm,
  recurringMonthlyExpensesForeign,
  recurringMonthlyRevenueForeign,
  supportToFieldWorkers,
  currency,
  isForm = false,
}) {
  const settings = useSettings();
  let netProfit1;
  let netProfit2;
  let netProfit3;
  let netProfit4;
  let loanTotalForeign;
  if (exchangeRate > 0 && loanTotalUsd > 0 && loanTerm > 0) {
    loanTotalForeign = loanTotalUsd * exchangeRate;
    netProfit1 =
      recurringMonthlyRevenueForeign - recurringMonthlyExpensesForeign;
    netProfit2 = netProfit1 - supportToFieldWorkers;
    netProfit3 = netProfit2 * loanTerm;
    netProfit4 = netProfit3 - loanTotalForeign;
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 16,
        }}
      >
        <div
          style={{ fontWeight: 'bold', marginRight: 16 }}
        >{`Loan Amount: ${getNumberValue(loanTotalForeign).toFixed(2)} ${
          currency || ''
        } (${displayMoney(getNumberValue(loanTotalUsd))})`}</div>
        <div
          style={{ fontWeight: 'bold' }}
        >{`Loan Term: ${loanTerm} months`}</div>
      </div>
      <Table
        bordered
        scroll={{ x: true }}
        dataSource={[
          {
            row: 'A',
            label: 'Recurring Monthly Revenue',
            value: getNumberValue(recurringMonthlyRevenueForeign).toFixed(2),
            valueTerm: getNumberValue(
              recurringMonthlyRevenueForeign * loanTerm,
            ).toFixed(2),
            usd:
              exchangeRate > 0
                ? displayMoney(recurringMonthlyRevenueForeign / exchangeRate)
                : '',
            usdTerm:
              exchangeRate > 0
                ? displayMoney(
                    (recurringMonthlyRevenueForeign * loanTerm) / exchangeRate,
                  )
                : '',
            color: undefined,
          },
          {
            row: 'B',
            label: 'Recurring Monthly Expenses',
            value: getNumberValue(recurringMonthlyExpensesForeign).toFixed(2),
            valueTerm: getNumberValue(
              recurringMonthlyExpensesForeign * loanTerm,
            ).toFixed(2),
            usd:
              exchangeRate > 0
                ? displayMoney(recurringMonthlyExpensesForeign / exchangeRate)
                : '',
            usdTerm:
              exchangeRate > 0
                ? displayMoney(
                    (recurringMonthlyExpensesForeign * loanTerm) / exchangeRate,
                  )
                : '',
            color: '#d4380d',
          },
          {
            row: 'C',
            label: 'Monthly Profit before Field Worker Support (A - B)',
            value: getNumberValue(netProfit1).toFixed(2),
            valueTerm: getNumberValue(netProfit1 * loanTerm).toFixed(2),
            usd:
              exchangeRate > 0 && netProfit1 !== undefined
                ? displayMoney(netProfit1 / exchangeRate)
                : '',
            usdTerm:
              exchangeRate > 0 && netProfit1 !== undefined > 0
                ? displayMoney((netProfit1 * loanTerm) / exchangeRate)
                : '',
            color: getNumberValue(netProfit1) < 0 ? 'red' : undefined,
          },
          {
            row: 'D',
            label: 'Field Worker Support',
            value: getNumberValue(supportToFieldWorkers).toFixed(2),
            valueTerm: getNumberValue(supportToFieldWorkers * loanTerm).toFixed(
              2,
            ),
            usd:
              exchangeRate > 0
                ? displayMoney(supportToFieldWorkers / exchangeRate)
                : '',
            usdTerm:
              exchangeRate > 0
                ? displayMoney(
                    (supportToFieldWorkers * loanTerm) / exchangeRate,
                  )
                : '',
            color: '#d4380d',
          },
          {
            row: 'E',
            label: 'Monthly Profit after Field Worker Support (C - D)',
            value: getNumberValue(netProfit2).toFixed(2),
            valueTerm: getNumberValue(netProfit2 * loanTerm).toFixed(2),
            usd:
              exchangeRate > 0 && netProfit2 !== undefined
                ? displayMoney(netProfit2 / exchangeRate)
                : '',
            usdTerm:
              exchangeRate > 0 && netProfit2 !== undefined > 0
                ? displayMoney((netProfit2 * loanTerm) / exchangeRate)
                : '',
            color: getNumberValue(netProfit2) < 0 ? 'red' : undefined,
          },
          {
            row: 'F',
            label: `Total Profit before Loan Payment (E)`,
            value: '',
            valueTerm: getNumberValue(netProfit3).toFixed(2),
            usd: '',
            usdTerm:
              exchangeRate > 0 && netProfit3 !== undefined > 0
                ? displayMoney(netProfit3 / exchangeRate)
                : '',
            color: getNumberValue(netProfit3) < 0 ? 'red' : undefined,
          },
          {
            row: 'G',
            label: 'Loan Payment',
            value: '',
            valueTerm: getNumberValue(loanTotalForeign).toFixed(2),
            usd: '',
            usdTerm:
              exchangeRate > 0
                ? displayMoney(loanTotalForeign / exchangeRate)
                : '',
            color: '#d4380d',
          },
          {
            row: 'H',
            label: `Total Net Profit (F - G)`,
            value: '',
            valueTerm: getNumberValue(netProfit4).toFixed(2),
            usd: '',
            usdTerm:
              exchangeRate > 0 && netProfit4 !== undefined > 0
                ? displayMoney(netProfit4 / exchangeRate)
                : '',
            color: getNumberValue(netProfit4) < 0 ? 'red' : undefined,
          },
        ]}
        showHeader
        pagination={false}
      >
        <Table.Column
          dataIndex="row"
          key="row"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          dataIndex="label"
          key="label"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'normal' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title={currency || 'Local Currency'}
          dataIndex="value"
          key="value"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title={
            <>
              At Term{' '}
              <Text
                style={{
                  whiteSpace: 'nowrap',
                  color: sharedSettings.colors.primary,
                }}
              >
                (x{loanTerm} months)
              </Text>
            </>
          }
          dataIndex="valueTerm"
          key="valueTerm"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title="USD"
          dataIndex="usd"
          key="usd"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
        <Table.Column
          title={
            <>
              At Term{' '}
              <Text
                style={{
                  whiteSpace: 'nowrap',
                  color: sharedSettings.colors.primary,
                }}
              >
                (x{loanTerm} months)
              </Text>
            </>
          }
          dataIndex="usdTerm"
          key="usdTerm"
          render={(text, record) => (
            <Text style={{ color: record.color, whiteSpace: 'nowrap' }}>
              {text}
            </Text>
          )}
        />
      </Table>
      {isForm && (
        <Form.Item
          name="profitable"
          className="form-item-no-input"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (netProfit4 < 0) {
                  return Promise.reject(
                    'Please re-evaluate the above entries.  In order to submit this proposal, your project must appear profitable.',
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        />
      )}
    </>
  );
}

export default CostAnalysisSummary;
