import { useMemo } from 'react';
import useProfile from '../../../shared/use-profile';

function useCanEditTraining() {
  const profile = useProfile();
  const permissions = profile?.permissions;
  const showCreate = useMemo(() => {
    return permissions?.find(
      (p) => p.prefix === 'create-admin-training-course',
    );
  }, [permissions]);
  return showCreate;
}

export default useCanEditTraining;
