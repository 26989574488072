import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Button, Form, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import FilterTrainingCourses from './FilterTrainingCourses';
import TrainingCoursesList from './TrainingCoursesList';
import settings from '../../../../settings';

const { Text } = Typography;

const queryId = 'allTrainingCourses';

const allTrainingCoursesForQuery = gql`
  query AllTrainingCoursesForAdmin(
    $first: Int
    $after: String
    $filters: TrainingCourseFiltersForAdmin
    $sortBy: [SortBy]
  ) {
    allTrainingCoursesForAdmin(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          _id
          courseName
          desc
          profilePhotoId
          primaryThumbnailUrl
          modules {
            _id
            name
            desc
          }
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

const extractAllTrainingCoursesForAdmin = (data) =>
  data && data.allTrainingCoursesForAdmin;

function AllTrainingCourses({ refetchRef }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');

  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const filters = useRef({});

  const {
    error,
    loading,
    data: courses,
    search: courseSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allTrainingCoursesForQuery,
    extract: extractAllTrainingCoursesForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  refetchRef.current = refetch;

  return (
    <>
      <FilterTrainingCourses
        filters={filters}
        refetch={refetch}
        courseSearch={courseSearch}
        loading={loading}
        sortBy={sortBy}
      />
      <TrainingCoursesList
        courses={courses}
        loading={loading}
        refetchRef={refetchRef}
      />
      <div style={{ textAlign: 'center' }}>
        {hasNextPage && <Button onClick={next}>Load More</Button>}
      </div>
    </>
  );
}

export default AllTrainingCourses;
