import { gql } from '@apollo/client';
import {
  ReportAttachmentAttributes,
  ReportPeopleGroupAttributes,
  ReportUserLongAttributes,
  ReportUserShortAttributes,
} from '../reports/constants';

const UpgQuarterlyReportFullAttributes = gql`
  {
    _id
    quarter
    regionCode
    regionName
    contactId
    contact {
      _id
      fullName
    }
    reportType
    status
    publicAttachments ${ReportAttachmentAttributes}
    privateAttachments ${ReportAttachmentAttributes}
    isSubmitted
    isApproved
    submittedAt
    submittedBy
    submittedByUser ${ReportUserShortAttributes}
    approval {
      status
      when
      approver ${ReportUserLongAttributes}
    }
    notes
    createdBy
    createdByUser ${ReportUserShortAttributes}
    updatedBy
    updatedByUser ${ReportUserShortAttributes}
    createdAt
    updatedAt
    deletedBy
    deletedAt
    adminNotes
    peopleGroupId
    peopleGroup ${ReportPeopleGroupAttributes}
    onBehalfOfName
    onBehalfOfId
    receivedFinancialSupport
    receivedForHimself
    receivedForHolistic
    useOfHolisticFundsDesc
    receivedTotal
    hProjects {
      projectId
      status
      results
    }
    churchesPlanted {
      villageName
      country
      numChurches
      numBelievers
    }
    numChurchesPlanted
    numHeardForFirstTime
    numSalvations
    numWaterBaptisms
    miracles {
      _id
      desc
    }
    prayerRequests {
      _id
      desc
    }
  }
`;

export const upgQuarterlyReportQuery = gql`
  query UpgQuarterlyReportForAdmin($_id: ID!) {
    upgQuarterlyReportForAdmin(_id: $_id) ${UpgQuarterlyReportFullAttributes}
  }
`;

export const extractUpgQuarterlyReport = (data) =>
  data && data.upgQuarterlyReportForAdmin;

const UpgQuarterlyReportUpdateAttributes = gql`
{
  crud
  id
  new ${UpgQuarterlyReportFullAttributes}
}
`;

export const upgQuarterlyReportUpdatesSubscription = gql`
  subscription UpgQuarterlyReportUpdatesForAdmin($_id: ID!) {
    upgQuarterlyReportUpdatesForAdmin(_id: $_id) ${UpgQuarterlyReportUpdateAttributes}
  }
`;

export const createUpgQuarterlyReportForAdminMutation = gql`
  mutation CreateUpgQuarterlyReportForAdmin(
    $report: UpgQuarterlyReportCreateInputForAdmin!
  ) {
    createUpgQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const updateUpgQuarterlyReportForAdminMutation = gql`
  mutation UpdateUpgQuarterlyReportForAdmin(
    $report: UpgQuarterlyReportUpdateInputForAdmin!
  ) {
    updateUpgQuarterlyReportForAdmin(report: $report) {
      report {
        _id
      }
    }
  }
`;

export const submitUpgQuarterlyReportForAdminMutation = gql`
  mutation SubmitUpgQuarterlyReportForAdmin($reportId: ID!) {
    submitUpgQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const unsubmitUpgQuarterlyReportForAdminMutation = gql`
  mutation UnsubmitUpgQuarterlyReportForAdmin($reportId: ID!) {
    unsubmitUpgQuarterlyReportForAdmin(reportId: $reportId)
  }
`;

export const reviewUpgQuarterlyReportMutation = gql`
  mutation ReviewUpgQuarterlyReport(
    $reportId: ID!
    $status: ReportApprovalStatus!
  ) {
    reviewUpgQuarterlyReport(reportId: $reportId, status: $status)
  }
`;

export const sendUpgQuarterlyReportFeedbackMutation = gql`
  mutation SendUpgQuarterlyReportFeedback($reportId: ID!, $feedback: String!) {
    sendUpgQuarterlyReportFeedback(reportId: $reportId, feedback: $feedback)
  }
`;
