import { displayMoney } from '@aims/shared/shared/utils';
import { Card, Typography } from 'antd';
import React from 'react';

const { Title, Text } = Typography;

function DisplayPaymentA10({ payment }) {
  return (
    <>
      <Title level={4}>Single Donation</Title>
      <Card>
        <Title level={5}>{payment.payerName}</Title>
        <div>
          <Text>{`Fund: ${payment.fundName}`}</Text>
        </div>
        <div>
          <Text>{`Amount: ${displayMoney(payment.usdCentsAmount / 100)}`}</Text>
        </div>
      </Card>
    </>
  );
}

export default DisplayPaymentA10;
